import { IoPersonCircleSharp } from 'react-icons/io5'
import { ReactComponent as Caret } from '../../assets/dropdown-caret.svg'
import { SlSettings } from 'react-icons/sl'
import { TfiPowerOff } from 'react-icons/tfi'
import { Link } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import { ReactComponent as Mail } from '../../assets/icons/mail.svg'
import { ReactComponent as Role } from '../../assets/icons/role.svg'
import { useContext } from 'react'
import { AuthLayoutContext } from '../../containers/AuthLayout.jsx'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../../slices/authSlice.js'

export default function UserNav () {
  const userData = useSelector(state => state.auth.user)

  const { userRole } = useContext(AuthLayoutContext)
  const dispatch = useDispatch()

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      dispatch(authActions.logout())
    }
  }

  return (
    <div>
      <div className='dropdown dropdown-end'>
        <div tabIndex={0} role='button'>
          <button className='flex items-center bg-g-300 hover:bg-g-500 rounded-lg p-1'>
            <IoPersonCircleSharp size={26} color='#AEA8A8' className='mr-2' />
            <span className='opacity-90 inline mr-2 text-sm'>{`${userData?.employee?.first_name} ${userData?.employee?.last_name}`}</span>
            <Caret className='w-3 h-3' />
          </button>
        </div>
        <div className='dropdown-content z-[1] menu p-2 mt-2 shadow bg-white rounded-box w-60'>
          <div className='mb-1 border-b border-g-100'>
            <div className='p-2 flex flex-row w-full items-center bg-white gap-2 text-sm'>
              <Mail className='w-4 h-4' />
              <p className='break-all'>{userData?.user?.email}</p>
            </div>
            <div className='p-2 flex flex-row w-full items-center bg-white gap-2 text-sm'>
              <Role className='w-4 h-4' />
              <p>{userRole?.name}</p>
            </div>
          </div>
          <ul tabIndex={0}>
            <li>
              <Link
                to={ROUTES.ACCOUNT_SETTINGS.path}
                className='p-2 flex flex-row w-full items-center bg-white hover:bg-base-100 transition-all gap-2 text-sm'
              >
                <SlSettings size={16} />
                <span>Account settings</span>
              </Link>
            </li>
            <li>
              <div
                className='p-2 flex flex-row w-full items-center bg-white hover:bg-[#dc2626]/20 hover:text-[#dc2626] transition-all gap-2 text-sm'
                onClick={handleLogout}
              >
                <TfiPowerOff size={16} />
                <span>Logout</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
