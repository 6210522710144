export default function Drafts ({ variant = 'secondary', ...restProps }) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.0968 4.03199L26.288 4.21247L26.4684 4.40368C28.0326 6.16021 27.9724 8.85385 26.288 10.5383L11.3276 25.4987C10.9815 25.8448 10.551 26.0946 10.0788 26.2234L3.68423 27.9673C2.98439 28.1582 2.34223 27.516 2.53309 26.8162L4.27708 20.4216C4.40586 19.9494 4.65564 19.519 5.00174 19.1729L19.9621 4.21247C21.6466 2.52802 24.3402 2.46786 26.0968 4.03199ZM18.7503 8.07609L6.32756 20.4987C6.2122 20.6141 6.12894 20.7575 6.08601 20.9149L4.7737 25.7267L9.5855 24.4144C9.7429 24.3715 9.88637 24.2882 10.0017 24.1729L22.424 11.7498L18.7503 8.07609ZM8.15631 14.2498L6.28131 16.1248L3.43756 16.1254C2.91979 16.1254 2.50006 15.7056 2.50006 15.1879C2.50006 14.6701 2.91979 14.2504 3.43756 14.2504L8.15631 14.2498ZM13.1563 9.24984L11.2813 11.1248L3.43756 11.1254C2.91979 11.1254 2.50006 10.7056 2.50006 10.1879C2.50006 9.67011 2.91979 9.25038 3.43756 9.25038L13.1563 9.24984ZM21.288 5.53829L20.0753 6.74984L23.749 10.4248L24.9621 9.21247C25.9767 8.19787 25.9767 6.55289 24.9621 5.53829C23.9475 4.52369 22.3026 4.52369 21.288 5.53829ZM18.1563 4.24984L16.2813 6.12484L3.43756 6.12538C2.91979 6.12538 2.50006 5.70564 2.50006 5.18788C2.50006 4.67011 2.91979 4.25038 3.43756 4.25038L18.1563 4.24984Z'
        fill={variant === 'secondary' ? '#333333' : '#fe6802'}
      />
    </svg>
  )
}
