export const reverseArr = (arr) => {
  let newArr = [...arr]
  for (let i = 0; i < Math.floor(newArr.length / 2); i++) {
    ;[newArr[i], newArr[newArr.length - 1 - i]] = [
      newArr[newArr.length - 1 - i],
      newArr[i]
    ]
  }
  return newArr
}
