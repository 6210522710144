import { AiOutlineMenu } from 'react-icons/ai'
import { getDateInWords } from '../../utils'
// import Notification from './Notification'
import UserNav from './UserNav'
import { useContext } from 'react'
import { StateContext } from '../../context/ContextProvider'

export default function TopNav () {
  const { setIsSidebarOpen } = useContext(StateContext)

  return (
    <div className='bg-g-300 px-4 py-1 z-1 border-b'>
      <div className='flex items-center justify-between w-full'>
        <div className='flex gap-3 items-centeFr'>
          <AiOutlineMenu
            className='lg:hidden cursor-pointer'
            color='#fe6802'
            size={24}
            onClick={() => setIsSidebarOpen(true)}
          />
          <span className='font-medium text-sm'>
            {getDateInWords(new Date())}
          </span>
        </div>

        <div className='flex justify-end items-center gap-5'>
          {/* <Notification /> */}
          <UserNav />
        </div>
      </div>
    </div>
  )
}
