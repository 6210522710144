import React, { useEffect, useState } from 'react'
// import { EmployeeTable, PartnersTable, UserTable } from '../components'
import accountsApi from '../api/accounts'
import customerApi from '../api/customer'
import Loader from '../components/loader/Loader'
import extraData from '../api/extraData'
import useToast from '../hooks/useToast'
import { parseError } from '../utils'
import { useSelector } from 'react-redux'

const IAMDashboard = () => {
  const [accounts, setAccounts] = useState([])
  const [users, setUsers] = useState([])
  const [specializations, setSpecializations] = useState([])
  const [systems, setSystems] = useState([])
  const [branches, setBranches] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('tab1')

  const userData = useSelector(state => state.auth.user)

  const toast = useToast()

  useEffect(() => {
    const getAccounts = async () => {
      const response = await accountsApi.listAccounts()
      if (!response.ok) {
        setLoading(false)
        const apiError = parseError(response)
        if (apiError) {
          toast('Unable to fetch accounts', 'error')
        }
        return
      }
      setAccounts(response.data.payload)

      const specializationsData = await extraData.listSpecializations()
      if (!specializationsData.ok) setSpecializations(specializationsData.data)

      const branchesData = await extraData.listBranches(
        userData.organisation.oid
      )

      if (!branchesData.ok) return setLoading(false)
      setBranches(branchesData.data.payload)

      const systemsData = await extraData.listSystems()

      if (!systemsData.ok) return setLoading(false)
      setSystems(systemsData.data)

      const users = await customerApi.getCustomers()

      if (!users.ok) {
        const apiError = parseError(response)
        if (apiError) {
          toast('Unable to get users', 'error')
        }
        return
      }
      setUsers(users.data)
      setLoading(false)
    }
    getAccounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {}, [])

  if (loading) return <Loader />

  return (
    <div className='px-8 pb-20 min-h-screen'>
      <h1 className='text-3xl mt-12 font-bold'>Dashboard</h1>

      <div className='flex gap-4 items-center my-10'>
        <button
          className={`btn ${
            activeTab === 'tab1' ? 'btn-primary text-white' : 'btn-ghost'
          }`}
          onClick={() => setActiveTab('tab1')}
        >
          Employees
        </button>
        <button
          className={`btn ${
            activeTab === 'tab2' ? 'btn-primary text-white' : 'btn-ghost'
          }`}
          onClick={() => setActiveTab('tab2')}
        >
          Customers
        </button>
        <button
          className={`btn ${
            activeTab === 'tab3' ? 'btn-primary text-white' : 'btn-ghost'
          }`}
          onClick={() => setActiveTab('tab3')}
        >
          Partners
        </button>
      </div>
      {/* 
      {activeTab === 'tab1' && (
        <EmployeeTable
          specializations={specializations}
          branches={branches}
          systems={systems}
          accounts={accounts}
        />
      )}

      {activeTab === 'tab2' && <UserTable users={users} />}

      {activeTab === 'tab3' && <PartnersTable users={users} />} */}
    </div>
  )
}

export default IAMDashboard
