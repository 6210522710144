const nigerianStates = [
  {
    name: 'Abia',
    isoCode: 'AB'
  },
  {
    name: 'Adamawa',
    isoCode: 'AD'
  },
  {
    name: 'Akwa Ibom',
    isoCode: 'AK'
  },
  {
    name: 'Anambra',
    isoCode: 'AN'
  },
  {
    name: 'Bauchi',
    isoCode: 'BA'
  },
  {
    name: 'Bayelsa',
    isoCode: 'BY'
  },
  {
    name: 'Benue',
    isoCode: 'BE'
  },
  {
    name: 'Borno',
    isoCode: 'BO'
  },
  {
    name: 'Cross River',
    isoCode: 'CR'
  },
  {
    name: 'Delta',
    isoCode: 'DE'
  },
  {
    name: 'Ebonyi',
    isoCode: 'EB'
  },
  {
    name: 'Edo',
    isoCode: 'ED'
  },
  {
    name: 'Ekiti',
    isoCode: 'EK'
  },
  {
    name: 'Enugu',
    isoCode: 'EN'
  },
  {
    name: 'FCT',
    isoCode: 'FC'
  },
  {
    name: 'Gombe',
    isoCode: 'GO'
  },
  {
    name: 'Imo',
    isoCode: 'IM'
  },
  {
    name: 'Jigawa',
    isoCode: 'JI'
  },
  {
    name: 'Kaduna',
    isoCode: 'KD'
  },
  {
    name: 'Kano',
    isoCode: 'KN'
  },
  {
    name: 'Kastina',
    isoCode: 'KT'
  },
  {
    name: 'Kebbi',
    isoCode: 'KE'
  },
  {
    name: 'kogi',
    isoCode: 'KO'
  },
  {
    name: 'Kwara',
    isoCode: 'KW'
  },
  {
    name: 'Lagos',
    isoCode: 'LA'
  },
  {
    name: 'Nassarawa',
    isoCode: 'NA'
  },
  {
    name: 'Niger',
    isoCode: 'NI'
  },
  {
    name: 'Ogun',
    isoCode: 'OG'
  },
  {
    name: 'Ondo',
    isoCode: 'ON'
  },
  {
    name: 'Osun',
    isoCode: 'OS'
  },
  {
    name: 'Oyo',
    isoCode: 'OY'
  },
  {
    name: 'Plateau',
    isoCode: 'PL'
  },
  {
    name: 'Rivers',
    isoCode: 'RI'
  },
  {
    name: 'Sokoto',
    isoCode: 'SO'
  },
  {
    name: 'Taraba',
    isoCode: 'TA'
  },
  {
    name: 'Yobe',
    isoCode: 'YO'
  },
  {
    name: 'Zamfara',
    isoCode: 'ZA'
  }
]

export default nigerianStates
