import React, { useState, useEffect } from 'react'
import { capitalizeFirstLetter } from '../../utils'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { FaPlus } from 'react-icons/fa6'
import { BiSearch } from 'react-icons/bi'
import { ReactComponent as User } from '../../assets/assign-user.svg'
import useToast from '../../hooks/useToast'
import { FaCheck } from 'react-icons/fa6'
import { ReactComponent as Times } from '../../assets/times.svg'
import { ReactComponent as Back } from '../../assets/goBack.svg'
import ProceedToGenerate from '../../components/manifests/ProceedToGenerate'
import { AiOutlineDelete } from 'react-icons/ai'
import manifestApi from '../../api/manifest'
import { fetchAllBags } from '../../slices/bagsSlice'
import { fetchManifest } from '../../slices/manifestSlice'
import Page from '../../containers/Page'
import FilterButton from '../../components/globals/filter/FilterButton'

const CreateDoorstep = ({ metaTitle }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useToast()
  const allHubs = useSelector(state => state?.hubs?.hubs)
  const allBags = useSelector(state => state?.allBags?.allBags)
  const bagEmpty = useSelector(state => state?.allBags?.isBag)
  const locate = useSelector(state => state?.locations?.data)

  const [allLocate, setAllLocate] = useState(
    !locate ? [] : [...locate]?.sort((a, b) => a.city.localeCompare(b.city))
  )
  const [listBags, setListBags] = useState(allBags)
  const [listShipments, setListShipments] = useState([])
  const [listShipmentsTwo, setListShipmentsTwo] = useState([])
  const [city, setCity] = useState([])
  const [province, setProvince] = useState([])
  // const [sortProvince, setSortProvince] = useState(province);
  const [provinceData, setProvinceData] = useState([])
  const [originHub, setOriginHub] = useState([])
  const [destination, setDestination] = useState('')
  const [destinationCounter, setDestinationCounter] = useState([])
  const [selectedTag, setSelectedTag] = useState([])
  const [selectedShipment, setSelectedShipment] = useState([])
  const [shipmentCount, setShipmentCount] = useState(0)
  const [proceed, setProceed] = useState(false)
  const [selectedList, setSelectedList] = useState([])
  const [create, setCreate] = useState(true)
  const [groupedShipment, setGroupedShipment] = useState({})
  const [generateGroupedShioment, setGenerateGroupedShipment] = useState([])
  // const [createDoorstep, setCreateDoorstep] = useState(false);
  const [isSent, setIsSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [isDone, setIsDone] = useState(false)

  useEffect(() => {
    const provinceClone = data?.filter(
      data => data?.destination?.address?.area_or_province === provinceData
    )
    setListShipments(provinceClone)
  }, [provinceData])

  useEffect(() => {
    if (bagEmpty === true) {
      setListBags(allBags)
      originHub.push(
        allBags !== null ? allBags[0]?.origin_hub : 'gbagada phase 1'
      )

      setOriginHub([...originHub])
      setDestinationCounter([...destinationCounter, destination])
      let listShipmentsClone = []
      allBags?.map(item => {
        listShipmentsClone.push(...item?.shipments)
        //setListShipments([...new Set(listShipments)]);
        //setListShipments([...listShipments]);
        setListShipmentsTwo([...new Set(listShipmentsClone)])
      })
    }
  }, [bagEmpty])

  let selectedShipmentArr = selectedShipment

  const handleChange = item => {
    setSelectedList([...selectedList, item])
    if (!selectedShipmentArr.includes(item)) {
      selectedShipmentArr.push(item)
      setSelectedShipment(selectedShipmentArr)
      setShipmentCount(shipmentCount + 1)
      setGroupedShipment({
        tracking_ids: selectedShipment
      })
    } else if (selectedShipment.includes(item)) {
      const removeSelected = selectedShipment?.filter(items => items !== item)
      setSelectedShipment(removeSelected)
      setSelectedList(removeSelected)
      setGroupedShipment({
        tracking_ids: removeSelected
      })
      setShipmentCount(selectedList?.length - 1)
    }
  }

  const generateManifest = () => {
    //setCreateDoorstep(true);
    setGenerateGroupedShipment([
      ...generateGroupedShioment,
      { shipment: selectedShipment, destination }
    ])
  }

  const handleChooseAll = () => {
    const chooseAll = listBags?.map(list => {
      return list?.tag
    })

    setSelectedTag([...chooseAll])
    setSelectedList(chooseAll)
    setShipmentCount(chooseAll?.length)
  }

  const getAllHubData = () => {}

  // const getAllHubData = (hubId) => {
  //   const filteredHub = allHub?.filter((hub) => hub?.name === hubId);
  //   setHub(filteredHub);
  //   setDestination(filteredHub[0]?.name);
  //   dispatch(fetchAllBags(`hubs/${filteredHub[0]?.id}/bags`));
  //   dispatch(fetchAllBags(`/bags/`));

  //   setShipmentCount(0);
  // };

  //this function calls the remove shipments api after manifest is created
  const removeShipmentApi = async (body, params) => {
    await manifestApi.removeShipmentFromBag(body, params)
  }

  // Function to filter allbags based on succesfull door step manifest created
  const findBagTags = (allbags, manifest) => {
    return allbags.map(bag => {
      manifest.forEach((item, index) => {
        if (bag?.shipments?.includes(item.tracking_id)) {
          removeShipmentApi({ tracking_ids: [item?.tracking_id] }, bag?.tag)
        }
      })
      setIsDone(true)
    })
  }

  const sendDataBody = async body => {
    const result = await manifestApi.createManifest(body)
    if (result.ok) {
      setIsSent(true)
      setCreate(false)
      setLoading(false)
    } else {
      toast(result?.data?.message, 'error')
      setCreate(true)
      setLoading(false)
    }
  }

  const generateBulkDelivery = async () => {
    setLoading(true)
    const result = await manifestApi.createDoorStep(groupedShipment)

    if (result.ok) {
      sendDataBody({
        type: 'DM',
        meta: {
          number_of_packages: shipmentCount,
          packages: result?.data?.payload
        }
      })

      findBagTags(allBags, result?.data?.payload)
    }
  }
  // useEffect(() => {
  //   if (data?.length !== 0) {
  //     setListShipments(data);
  //   }
  // }, [data]);

  const handleCreate = async () => {
    const result = await manifestApi.createDoorStep({
      // tracking_ids: ["9E6FE95F", "F18E2EBC", "1D3435C1", "3B408A18"],
      tracking_ids: listShipmentsTwo
    })
    setData(result?.data?.payload)
  }

  useEffect(() => {
    if (listShipmentsTwo?.length !== 0) {
      handleCreate()
    }
  }, [listShipmentsTwo])

  useEffect(() => {
    dispatch(fetchAllBags(`/bags/get_bags_destined_for_user_hub`))
  }, [dispatch])

  useEffect(() => {
    const filterLocateDataByCity = allLocate?.filter(data => data.city === city)
    setProvince(
      filterLocateDataByCity !== null && filterLocateDataByCity[0]?.provinces
    )
  }, [city])

  return (
    <Page metaTitle={metaTitle}>
      <Page.Body>
        {proceed === false ? (
          <div className='w-full py-8'>
            <div className='flex justify-start items-center space-x-2'>
              <Back
                onClick={() => navigate(`/manifest/ds`)}
                className='cursor-pointer'
              />
              <div className='font-bold text-3xl'>
                Create door-step manifest
              </div>
            </div>
            {/* second container */}
            <div className='w-full px-4 py-6 bg-white mt-12 space-y-4'>
              <div className='font-bold text-1xl'>Manifest details</div>
              <div className='w-full flex justify-between space-x-2 py-6'>
                {/* <div className="w-[50%] space-y-4">
                  <div className="text-sm font-medium text-[#3A3A3A]">Area</div>
                  <div className="border-[#BDBDBD] w-[100%] p-3 rounded-md border-[1px]">
                    {allBags?.length !== 0 ? originHub[0] : "Lagos hub"}
                  </div>
                </div> */}
                <div className='w-[50%] space-y-4'>
                  <div className='text-sm font-medium text-[#3A3A3A]'>City</div>

                  <select
                    className='border-[#BDBDBD] w-[100%] p-3 rounded-md border-[1px]'
                    onChange={e => {
                      let city = e.target.value
                      setCity(city)
                    }}
                  >
                    <option className='bg-white' value={0}>
                      {' '}
                      Select city
                    </option>
                    {allLocate?.map((data, id) => (
                      <option className='bg-white' value={data?.city} key={id}>
                        {capitalizeFirstLetter(data?.city)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='w-[50%] space-y-4'>
                  <div className='text-sm font-medium text-[#3A3A3A]'>
                    Province
                  </div>
                  <select
                    className='border-[#BDBDBD] w-[100%] p-3 rounded-md border-[1px]'
                    onChange={e => {
                      let province = e.target.value
                      setProvinceData(province)
                    }}
                  >
                    <option className='bg-white' value={0}>
                      {' '}
                      Select province
                    </option>
                    {_.sortBy(province, item => item.toLowerCase())?.map(
                      (data, id) => (
                        <option className='bg-white' value={data} key={id}>
                          {capitalizeFirstLetter(data)}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
            {/* Edn of first block */}
            {/* Start of second block */}
            <div className='w-full px-4 bg-white mt-12 space-y-8 pb-8'>
              {isSent === true ? (
                <div className='w-full flex justify-center'>
                  <div className='w-[50%] bg-[#F6FAF6] h-[60px] flex justify-center items-center space-x-3'>
                    <div className='opacity-100 text-[#246A28]'>
                      Door-step manifest ID generated successfully
                    </div>
                    <Times
                      className='w-[25px] h-[25px] text-[#246A28] cursor-pointer'
                      onClick={() => setIsSent(false)}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className='font-bold text-1xl'>Shipments</div>
              <div className='text-xs text-dark-primary opacity-80'>
                From the list of shipments below, select and add a shipment to a
                destination
              </div>
              {create ? (
                <div className='space-y-8'>
                  {/* start filter container */}
                  <div className='w-full'>
                    <div className='flex justisfy-between items-center space-x-2'>
                      <div className='flex w-[278px]'>
                        <input
                          type='text'
                          placeholder='Enter manifest ID'
                          className='w-[230px] txtinp bg-white border-[1px] border-[#E5E4E5] rounded-l-xl'
                        />
                        <span className='w-[68px] h-[40px] bg-[#E5E4E5] rounded-r-xl flex justify-center items-center'>
                          <BiSearch className='w-[24px] h-[24px]' />
                        </span>
                      </div>

                      <FilterButton onClick={() => {}} />
                      {/* <span className="flex space-x-2 justify-center items-center w-[122px] h-[40px] bg-white border-[1px] border-[#E5E4E5] rounded-md cursor-pointer">
                        <IoFilterOutline className="text-[#A6A2A2]" />
                        <p className="text-[#A6A2A2] text-base">Filter by</p>
                      </span> */}
                    </div>
                  </div>
                  {/* end filter container */}
                  {/* start of bags selected */}
                  <div className='w-full flex justify-start space-x-2'>
                    <div className='w-[50%] space-y-4'>
                      <div className='text-sm font-medium text-[#3A3A3A]'>
                        No of selected shipments
                      </div>
                      <div className='border-[#BDBDBD] w-[100%] p-3 rounded-md border-[1px]'>
                        {shipmentCount}
                      </div>
                    </div>
                  </div>
                  {/* start of bags selected */}
                  {/* start of choose all */}
                  <div className='flex justify-start text-main-primary'>
                    <div
                      className='font-semibold cursor-pointer'
                      onClick={handleChooseAll}
                    >
                      Choose All
                    </div>
                  </div>
                  {/* end of choose all */}

                  <div className='w-full flex justify-between space-x-3'>
                    {/* start of list of bags container */}
                    <div className='w-[50%]'>
                      <div className='h-[48px] bg-main-primary text-white flex flex-col justify-center pl-4'>
                        List Of Shipments
                      </div>
                      <div className='h-[342px] border-[#BDBDBD] border-[1px] overflow-y-auto'>
                        {listShipments?.length === 0 ? (
                          <div className='flex space-x-4 items-center p-3'>
                            No shipment to select
                          </div>
                        ) : (
                          listShipments?.map((shipment, id) => (
                            <div
                              className='flex space-x-4 items-center hover:bg-main-hover p-3 cursor-pointer'
                              key={id}
                              onClick={() => {
                                handleChange(shipment?.tracking_id)
                                setDestination(
                                  shipment?.destination?.address?.street_lines,
                                  shipment?.destination?.address?.city,
                                  shipment?.destination?.address?.country
                                )
                              }}
                            >
                              {selectedList?.includes(shipment?.tracking_id) ? (
                                <FaCheck className='text-green-500' />
                              ) : (
                                <FaPlus />
                              )}

                              <div className={`text-sm cursor-pointer"`}>
                                Receiver Name:{' '}
                                {shipment?.destination?.contact?.name}
                                <div>
                                  {' '}
                                  Tracking Id:{' '}
                                  {shipment?.tracking_id?.toUpperCase()},
                                </div>
                                <div className='flex'>
                                  {shipment?.packages?.map((item, id) => (
                                    <div key={id}>
                                      <div>
                                        {' '}
                                        Weight: {item?.actualWeight}kg, Amount:
                                        ₦{item?.packageDimension?.price}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {shipment?.destination?.address?.street_lines}
                                  , {shipment?.destination?.address?.city},{' '}
                                  {shipment?.destination?.address?.country}
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                  {/* start of add new stop and confirm bag */}
                  <div className='w-[50%] flex justify-start items-center space-x-4 mt-8'>
                    <button
                      className='w-[45%] flex justify-center space-x-2 items-center h-[50px] bg-main-primary text-white rounded-[100px] cursor-pointer font-semibold'
                      onClick={() => {
                        generateManifest()
                        generateBulkDelivery()
                      }}
                    >
                      {/* <AddStop /> */}
                      <p className='text-xs'>
                        {loading === true
                          ? 'Loading...'
                          : 'Generate Manifest ID'}
                      </p>
                    </button>
                  </div>
                </div>
              ) : (
                generateGroupedShioment?.map((group, id) => (
                  <div
                    className='w-full flex justify-between space-x-3 bg-[#F5F5F5] opacity-60 px-4 py-6 rounded-lg'
                    key={id}
                  >
                    <div className='flex space-x-3'>
                      <div className='font-semibold text-black'>
                        {group.shipment[0]?.toUpperCase()}
                      </div>{' '}
                      <div>{capitalizeFirstLetter(group.destination[0])} </div>{' '}
                      <span>|</span> <div>{group.shipment.length}</div>
                    </div>
                    <div className='flex space-x-2 items-center'>
                      <User />
                      <AiOutlineDelete />
                    </div>
                  </div>
                ))
              )}
              {/* start of add new stop and confirm bag */}
              {!create && (
                <div className='w-full flex justify-end items-center space-x-4'>
                  <div className='w-[50%] flex justify-end items-center space-x-4'>
                    <div
                      className='text-main-primary font-semibold text-sm cursor-pointer'
                      onClick={() => {
                        navigate(`/manifest/ds`)
                        dispatch(fetchManifest())
                      }}
                    >
                      Proceed to manifest list
                    </div>
                    <button
                      className='w-[45%] flex justify-center space-x-2 items-center h-[50px] bg-main-primary text-white rounded-[100px] cursor-pointer font-semibold'
                      onClick={() => {
                        setShipmentCount(0)
                        setCreate(true)
                      }}
                    >
                      {/* <AddStop /> */}
                      <p className='text-xs'>Create new manifest</p>
                    </button>
                  </div>
                </div>
              )}
            </div>
            {/* End of second block */}
          </div>
        ) : (
          <ProceedToGenerate setProceed={setProceed} />
        )}
      </Page.Body>
    </Page>
  )
}

export default CreateDoorstep
