import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { ReactComponent as Times } from '../../../assets/times.svg'
import { capitalizeFirstLetter } from '../../../utils'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)'
  },
  overlay: {
    zIndex: 60
  }
}

const AddNewDestinationModal = ({
  setOpenModal,
  openModal,
  allHubs,
  getAllHubData
}) => {
  const [allHub, setAllHub] = useState()

  useEffect(() => {
    setAllHub([...allHubs]?.sort((a, b) => a.name.localeCompare(b.name)))
  }, [])
  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={setOpenModal}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='w-[550px] px-8 py-2 pb-16'>
          <div className='w-[100%] font-bold text-xl'>
            Add new stop destination{' '}
          </div>
          <div className='text-xs text-dark-primary opacity-80 mt-3'>
            Add a new location to your route as a stopover point
          </div>
          <div className='text-sm font-medium text-[#3A3A3A] mt-8'>
            Stop-over Hub
          </div>
          <select
            className='border-[#BDBDBD] w-[100%] p-3 rounded-md border-[1px] mt-4'
            onChange={e => {
              let hubId = e.target.value
              if (hubId !== 0) {
                getAllHubData(hubId)
              }
            }}
          >
            <option className='bg-white' value={0}>
              {' '}
              Select destination
            </option>
            {allHub?.map((hub, id) => (
              <option className='bg-white' value={hub?.name} key={id}>
                {capitalizeFirstLetter(hub?.name)} Hub,{' '}
                {capitalizeFirstLetter(hub?.location)}
              </option>
            ))}
          </select>

          <div className='w-full flex justify-end items-center space-x-8 mt-12'>
            <span
              className='flex space-x-2 justify-center items-center h-[50px] text-main-primary rounded-[40px] cursor-pointer font-semibold'
              onClick={() => {
                setOpenModal(false)
              }}
            >
              <p className='text-xs'>Cancel</p>
            </span>
            <span
              className='flex space-x-2 justify-center items-center w-[168px] h-[50px] bg-main-primary text-white rounded-[40px] cursor-pointer font-semibold'
              onClick={() => {
                setOpenModal(false)
              }}
            >
              <p className='text-xs'>Continue</p>
            </span>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AddNewDestinationModal
