import orgClient from './clients/orgClient'

const endpoint = '/iam/accounts/'

const createAccount = async body => {
  const response = await orgClient.post(endpoint, body)
  return response
}

const listAccounts = async () => {
  const response = await orgClient.get(endpoint)
  return response
}

const retrieveAccount = async id => {
  const response = await orgClient.get(`${endpoint}${id}/`)
  return response
}

const updateAccount = async (id, body) => {
  const response = await orgClient.put(`${endpoint}${id}/`, body)
  return response
}

const deleteAccount = async id => {
  const response = await orgClient.delete(`${endpoint}${id}/`)
  return response
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createAccount,
  listAccounts,
  retrieveAccount,
  updateAccount,
  deleteAccount
}
