import bookingClient from "./clients/bookingClient";

const endpoint = "salesforce/";

const getSalesforce = async () => {
  const response = await bookingClient.get(`${endpoint}`);
  return response;
};

const getSingleSalesforce = async (id) => {
  const response = await bookingClient.get(`${endpoint}${id}`);
  return response;
};

const markAsPaid = async (id, body) => {
  const response = await bookingClient.post(
    `${endpoint}${id}/mark_commissions_as_paid/`,
    body
  );
  return response;
};

const getCommision = async (id, params) => {
  const response = await bookingClient.get(
    `${endpoint}${id}/commissions/?date_type=${params?.date_type}&start_date=${params?.start_date}&end_date=${params?.end_date}`
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getSalesforce,
  getSingleSalesforce,
  markAsPaid,
  getCommision,
};
