import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ordersApi from '../api/orders'
import { parseError, urlQueryStringToObject } from '../utils'

const initialState = {
  data: null,
  meta: {
    previous: 0,
    page: 1,
    next: 0,
    page_size: 50
  },
  drafts: {
    data: null,
    meta: {
      previous: 0,
      page: 1,
      next: 0,
      page_size: 50
    }
  }
}

export const fetchOrders = createAsyncThunk(
  'orders/fetch',
  async (params = {}) => {
    const query = Object.assign({ page_size: 50, page: 1 }, params)

    const response = await ordersApi.getOrders(query)

    if (!response.ok) {
      return parseError(response)
    }

    return { response_data: response.data, query_params: query }
  }
)

export const fetchDashboardOrders = createAsyncThunk(
  'orders/dashboardOrders',
  async (params = {}) => {
    const query = Object.assign({ page_size: 50, page: 1 }, params)

    const response = await ordersApi.getDashboardOrders(query)

    if (!response.ok) {
      return parseError(response)
    }

    return { response_data: response.data, query_params: query }
  }
)

export const fetchDrafts = createAsyncThunk('orders/drafts', async params => {
  const query = Object.assign({ page_size: 50, page: 1 }, params)

  const response = await ordersApi.getDrafts()

  if (!response.ok) {
    return parseError(response)
  }

  return { response_data: response.data, query_params: query }
})

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    filter (state, action) {
      state.filter = action.payload
    },
    cleanupFilter (state) {
      state.filter = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchOrders.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.meta = Object.assign({}, state.meta, meta)
        state.data = action.payload?.response_data?.payload
      })
      .addCase(fetchDashboardOrders.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.meta = Object.assign({}, state.meta, meta)
        state.data = action.payload?.response_data?.payload
      })
      .addCase(fetchDrafts.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.drafts.meta = Object.assign({}, state.drafts.meta, meta)
        state.drafts.data = action.payload?.response_data?.payload
      })
  }
})

export const ordersActions = ordersSlice.actions

export default ordersSlice.reducer
