import { ReactComponent as Times } from '../../assets/times.svg'
import ReactModal from 'react-modal'
import Pagination from '../globals/pagination/pagination'
import { useEffect, useState } from 'react'
import { formatAmount, getDate } from '../../utils'
import { Link } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import TransactionModal from './TransactionModal'
import { CircularProgress } from '@mui/material'

const style = {
  content: {
    maxWidth: '1024px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto',
    borderRadius: '20px'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function AgentCashTransactions ({
  isOpen,
  onClose,
  data,
  onRemit
}) {
  const [tableData, setTableData] = useState([])
  const [trxInFocus, setTrxInFocus] = useState()
  const [isRemitting, setIsRemitting] = useState({ state: false, ref: '' })

  const viewTransaction = trx => {
    setTrxInFocus(trx)
  }

  const handleCloseModal = () => {
    setTrxInFocus(null)
  }

  const handleRemit = trx => {
    setIsRemitting({ state: true, ref: trx.reference_number })

    onRemit([trx], () => setIsRemitting(false))
  }

  useEffect(() => {
    if (data && trxInFocus) {
      const trx = data.transactions.find(
        trx => trx.reference_number === trxInFocus.reference_number
      )
      setTrxInFocus(trx)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onClose} style={style}>
      {trxInFocus && (
        <TransactionModal
          isOpen={!!trxInFocus}
          onClose={handleCloseModal}
          trx={trxInFocus}
          isRemittance
          onRemit={onRemit}
        />
      )}
      <div>
        <div
          className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer'
          onClick={onClose}
        >
          <Times />
        </div>

        <div className='flex flex-col p-4 gap-6'>
          <h5 className='text-[#3A3A3A] text-xl font-bold'>
            {data.agent.name}
          </h5>
          <div className='my-4 w-full overflow-x-auto'>
            <table id='agent-unremitted-cash-transactions'>
              <thead className='border-b border-[#EFEFEF]'>
                <th>S/N</th>
                <th>Customer</th>
                <th>Date/Time</th>
                <th>Order</th>
                <th>Invoice</th>
                <th>Amount (₦)</th>
                <th></th>
              </thead>
              <tbody>
                {tableData.map((trx, id) => (
                  <tr key={id}>
                    <td>{id + 1}</td>
                    <td>{trx.customer_name}</td>
                    <td>{getDate(trx.date_time)}</td>
                    <td>
                      <Link
                        to={`${ROUTES.ORDERS.path}?id=${trx.order}`}
                        className='hover:underline hover:text-primary'
                      >
                        #{trx.order}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${ROUTES.INVOICES.path}/${trx.invoiceData?.id}`}
                        className='hover:underline hover:text-primary'
                      >
                        #{trx.invoice}
                      </Link>
                    </td>
                    <td>{formatAmount(trx.amount.total)}</td>
                    <td>
                      <div className='flex gap-1 items-center'>
                        {trx.remitted === false && (
                          <button
                            className='border-[0.2px] border-[#DE350B] bg-[#DE350B] text-white rounded-3xl px-2 py-1 flex items-cetner justify-center text-center text-sm transition-all whitespace-nowrap w-16'
                            disabled={
                              isRemitting.state &&
                              isRemitting.ref === trx.reference_number
                            }
                            onClick={() => handleRemit(trx)}
                          >
                            {isRemitting.state &&
                            isRemitting.ref === trx.reference_number ? (
                              <CircularProgress color='inherit' size={16} />
                            ) : (
                              'Remit'
                            )}
                          </button>
                        )}
                        <button
                          className='border-[0.2px] border-[#DE350B] bg-white text-[#DE350B] hover:bg-[#f5ccc2] rounded-3xl px-2 py-1 block text-center text-sm transition-all whitespace-nowrap w-fit'
                          onClick={() => viewTransaction(trx)}
                        >
                          View
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='w-fit ml-auto'>
            <Pagination
              tableId='agent-unremitted-cash-transactins'
              data={data.transactions}
              setTableData={setTableData}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
