import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AiOutlineDelete } from 'react-icons/ai'
import { ReactComponent as Back } from '../../assets/goBack.svg'
import manifestApi from '../../api/manifest'
import SuccessModal from './components/SuccessModal'
import useToast from '../../hooks/useToast'
import { fetchManifest } from '../../slices/manifestSlice'

const ProceedToGenerate = ({
  setProceed,
  groupedBag,
  mapGroupedBag,
  setDisabled
}) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [id, setId] = useState('')
  const [loading, setLoading] = useState(false)

  const handleCreate = async () => {
    setLoading(true)
    const result = await manifestApi.createManifest(groupedBag)
    if (result.ok) {
      setId(result.data.payload.id)
      setOpenModal(true)
      setLoading(false)
      setDisabled(false)
      dispatch(fetchManifest())
    }

    if (!result.ok) {
      toast(result?.data?.message, 'error')
      setLoading(false)
      setDisabled(false)
    }
  }

  const showBags = bag => {
    for (let i = 0; i <= bag; i++) {
      return i
    }
  }

  return (
    <div className='w-full py-8'>
      <div className='flex justify-start items-center space-x-2'>
        <Back
          className='cursor-pointer'
          onClick={() => {
            setProceed(false)
          }}
        />
        <div className='font-bold text-3xl'>Generate transfer manifest ID</div>
      </div>
      <div className='mt-4 text-dark-primary/70'>
        Here’s a summary of the stop destinations and group waybills you
        generated
      </div>

      {/* start of group waybill listing */}

      {mapGroupedBag?.map((item, id) => {
        return (
          <div className='w-full bg-white mt-6 pb-16' key={id}>
            <div className='w-full px-3 py-4 flex justify-between items-center'>
              <div className='text-main-primary font-semibold'>
                {item?.origin.toUpperCase()} TO{' '}
                {item?.destination.toUpperCase()} HUB
              </div>
              <div className='flex space-x-2 text-dark-primary text-opacity-60'>
                {/* <MdOutlineEdit /> */}
                <AiOutlineDelete />
              </div>
            </div>
            <hr />
            <div className='flex w-full justify-between items-center px-3 py-4'>
              <div className='space-y-3'>
                <p className='font-semibold text-sm'>Name of bag</p>
                {item?.waybill?.map((bill, id) => (
                  <p className='text-sm'>Bag {id + 1}</p>
                ))}
              </div>
              <div className='space-y-3'>
                <p className='font-semibold text-sm'>Group waybill</p>
                {item?.waybill?.map(bill => (
                  <p className='text-sm'>{bill}</p>
                ))}
              </div>
              <div className='space-y-3'>
                <p className='font-semibold text-sm'>Total No of shipments</p>
                {item?.shipments?.map(item => (
                  <p className='text-sm'>{item?.shipment}</p>
                ))}
              </div>
            </div>
          </div>
        )
      })}

      <div className='flex justify-end mt-4'>
        <span
          className='flex space-x-2 justify-center items-center w-[240px] h-[50px] bg-main-primary text-white rounded-[100px] cursor-pointer font-semibold'
          onClick={handleCreate}
        >
          <p className='text-xs'>
            {loading === true ? 'Loading...' : 'Generate transfer manifest ID'}
          </p>
        </span>
      </div>
      <SuccessModal setOpenModal={setOpenModal} openModal={openModal} id={id} />
    </div>
  )
}

export default ProceedToGenerate
