import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { useLocation } from 'react-router'
import { Navbar } from '../components'
import { StateContext } from '../context/ContextProvider'
import IdleTimerPrompt from '../components/auth/IdleTimerPrompt'
import ROLES from '../constants/roles'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAddons } from '../slices/addonsSlice'
import { fetchPackageDimensions } from '../slices/dimensionsSlice'
import { fetchCategories } from '../slices/categoriesSlice'
import { fetchDeliveryLocations } from '../slices/locationsSlice'
import { authActions } from '../slices/authSlice'

export const AuthLayoutContext = createContext()

export default function AuthLayout ({ children }) {
  const location = useLocation()

  const userData = useSelector(state => state.auth.user)

  const { isSidebarOpen, setIsSidebarOpen } = useContext(StateContext)

  const [layoutContainer, setLayoutCotainer] = useState(null)

  const closeSidebar = () => {
    setIsSidebarOpen(false)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAddons())
    dispatch(fetchPackageDimensions())
    dispatch(fetchCategories())
    dispatch(fetchDeliveryLocations())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (layoutContainer) {
      layoutContainer.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useLayoutEffect(() => {
    setIsSidebarOpen(false)
  }, [location.pathname, setIsSidebarOpen])

  const logout = () => {
    dispatch(authActions.logout())
  }

  const containerRef = useCallback(containerNode => {
    setLayoutCotainer(containerNode)
  }, [])

  const userRole = useMemo(() => {
    const role = userData?.user.roles.reduce((acc, curr_role) => {
      if (acc.domain?.index > ROLES[curr_role].domain.index) {
        return acc
      }

      return ROLES[curr_role]
    }, {})

    return role
  }, [userData?.user.roles])

  return (
    <div>
      <IdleTimerPrompt onLogout={logout} />
      <aside
        className={`fixed top-0 z-50 w-[250px] h-screen overflow-hidden transition-[left] duration-300 ${
          isSidebarOpen ? 'left-0' : '-left-[250px] lg:left-0'
        }`}
      >
        <Navbar domain={userRole?.domain} />
      </aside>
      {isSidebarOpen && (
        <div
          className='bg-black/20 w-screen h-screen fixed top-0 left-0 z-30 duration-300 transition-all lg:hidden'
          onClick={closeSidebar}
        ></div>
      )}
      <div
        className='relative h-screen overflow-y-auto w-full lg:w-[calc(100%-250px)] lg:ml-auto transition-[width] duration-300 bg-g-200'
        ref={containerRef}
      >
        <AuthLayoutContext.Provider value={{ layoutContainer, userRole }}>
          {children}
        </AuthLayoutContext.Provider>
      </div>
    </div>
  )
}
