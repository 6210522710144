import React, { useMemo, useState, useEffect, useContext } from 'react'
import PackageFormQuote from '../../components/quotes/PackageFormQuote.jsx'

import DocumentFormQuote from '../../components/quotes/DocumentFormQuote.jsx'
import Loader from '../../components/loader/Loader'
import { useSelector } from 'react-redux'
import Page from '../../containers/Page.jsx'
import ROUTES from '../../constants/routes.js'
import { QuoteType } from '../../components/index.jsx'
import CustomerTabs, {
  customerTabs
} from '../../components/customers/CustomerTabs.jsx'
import { AuthLayoutContext } from '../../containers/AuthLayout.jsx'
import ROLES from '../../constants/roles.js'

const GetQuote = ({ metaTitle }) => {
  const [quoteType, setQuoteType] = useState('Package')
  const addonsStore = useSelector(state => state.addons)
  const categoriesStore = useSelector(state => state.categories)
  const locationsStore = useSelector(state => state.locations)
  const [shipmentType, setShipmentType] = useState('')
  const [state, setState] = useState('set-type')
  const [activeTab, setActiveTab] = useState(customerTabs[0])

  const { userRole } = useContext(AuthLayoutContext)

  const isLoading = useMemo(() => {
    return !addonsStore.data || !categoriesStore.data || !locationsStore.data
  }, [addonsStore.data, categoriesStore.data, locationsStore.data])

  useEffect(() => {
    if (shipmentType === 'LC') {
      setQuoteType('Package')
    }
  }, [shipmentType])

  const onShipmentType = type => {
    setShipmentType(type)
    setState('form')
  }

  const onBack = () => {
    setState('set-type')
  }

  const canCreateOrder = useMemo(() => {
    if (userRole) {
      return ROLES[userRole.id].permissions?.orders?.create
    }
  }, [userRole])

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title='Get Quote' backRoute={ROUTES.QUOTES.path} />
      <Page.Body>
        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className='relative my-8 flex items-center justify-center w-full max-w-3xl mx-auto'>
                {state === 'form' && (
                  <button
                    className='btn btn-primary btn-sm absolute left-0 top-1/2 -translate-y-1/2'
                    onClick={onBack}
                  >
                    Back
                  </button>
                )}
                <ul className='steps  z-0'>
                  <li
                    className={`${
                      state === 'set-type' ? 'step step-primary' : 'step'
                    }`}
                  ></li>
                  <li
                    className={`${
                      state === 'form' ? 'step step-primary' : 'step'
                    }`}
                  ></li>
                </ul>
              </div>

              {state === 'set-type' && (
                <QuoteType onShipmentType={onShipmentType} />
              )}

              {state === 'form' && (
                <div className='rounded-lg border border-neutral w-full max-w-3xl pt-4 mt-10 mx-auto'>
                  <div className='px-4 md:px-8 flex flex-col items-center gap-5'>
                    <CustomerTabs
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                    <div className='flex w-full flex-wrap items-center justify-start md:justify-center md:gap-3 lg:gap-10'>
                      {shipmentType === 'IN' ? (
                        [
                          { name: 'Package', code: 'Package' },
                          { name: 'Document', code: 'Document' },
                          { name: 'Fish/Snail', code: 'Fish Shipment' }
                        ].map((tab, id) => (
                          <button
                            key={id}
                            onClick={() => setQuoteType(tab.code)}
                            className={
                              quoteType === tab.code
                                ? 'text-white flex items-center justify-center px-4 md:px-9 py-3 rounded-lg bg-[#FE6806]'
                                : 'text-black flex items-center justify-center px-4 md:px-9 py-3 rounded-lg hover:bg-[#FE6806]/20 transition-all'
                            }
                          >
                            {tab.name}
                          </button>
                        ))
                      ) : (
                        <h2 className='py-4 md:pt-0 font-bold text-2xl text-center'>
                          Shipment Info
                        </h2>
                      )}
                    </div>
                  </div>

                  {quoteType === 'Package' && (
                    <PackageFormQuote
                      addons={addonsStore.data}
                      categories={categoriesStore.data}
                      locations={locationsStore.data}
                      type={shipmentType}
                      customer={activeTab.name}
                      allowCreateOrder={canCreateOrder}
                    />
                  )}

                  {quoteType === 'Document' && (
                    <DocumentFormQuote
                      categories={categoriesStore.data}
                      customer={activeTab.name}
                      allowCreateOrder={canCreateOrder}
                    />
                  )}

                  {quoteType === 'Fish Shipment' && (
                    <PackageFormQuote
                      addons={addonsStore.data}
                      categories={categoriesStore.data}
                      fishShipment={true}
                      customer={activeTab.name}
                      allowCreateOrder={canCreateOrder}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </Page.Body>
    </Page>
  )
}

export default GetQuote
