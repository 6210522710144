export const routeGroups = {
  HOME: 'HOME',
  DASHBOARD: 'DASHBOARD',
  PUBLIC_QUOTE: 'PUBLIC_QUOTE',
  QUOTES: 'QUOTES',
  LOGIN: 'LOGIN',
  ORDERS: 'ORDERS',
  SHIPMENTS: 'SHIPMENTS',
  INVOICES: 'INVOICES',
  RECEIPTS: 'RECEIPTS',
  USERS: 'USERS',
  TRANSACTIONS: 'TRANSACTIONS',
  TRACK: 'TRACK',
  IAM_DASHBOARD: 'IAM_DASHBOARD',
  ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
  MANIFEST: 'MANIFEST',
  REPORTS: 'REPORTS',
  FLEET: 'FLEET',
  INVENTORY: 'INVENTORY',
  SALESFORCE: 'SALESFORCE'
}

const ROUTES = {
  [routeGroups.HOME]: {
    path: '/'
  },
  [routeGroups.DASHBOARD]: {
    path: '/dashboard',
    metaTitle: 'Dashboard'
  },
  [routeGroups.PUBLIC_QUOTE]: {
    path: '/quote',
    metaTitle: 'Get a Quote'
  },
  [routeGroups.LOGIN]: {
    path: '/login',
    metaTitle: 'Login'
  },
  [routeGroups.QUOTES]: {
    path: '/quotes',
    metaTitle: 'Quotes',
    GET_QUOTE: {
      path: '/quotes/get-quote',
      metaTitle: 'Get Quote'
    }
  },
  [routeGroups.ORDERS]: {
    path: '/orders/',
    metaTitle: 'Orders',
    CREATE_ORDER: {
      path: '/orders/create/',
      UPDATE_ORDER: {
        path: '/orders/create/:orderId/'
      },
      SHIPMENT_TYPE: {
        path: 'shipment-type',
        metaTitle: 'Shipment Type - Create Order'
      },
      CUSTOMER_INFO: {
        path: 'customer-info',
        metaTitle: 'Customer Info - Create Order'
      },
      PACKAGE_TYPE: {
        path: 'package-type',
        metaTitle: 'Package Type - Create Order'
      },
      PACKAGE_SECTION: {
        path: 'package-section',
        metaTitle: 'Package - Create Order'
      },
      FISH_SNAIL: {
        path: 'fish-snail',
        metaTitle: 'Fish & Snail - Create Order'
      },
      DOCUMENT: {
        path: 'document',
        metaTitle: 'Document - Create Order'
      },
      SHIPMENT_SECTION: {
        path: 'shipment-section',
        metaTitle: 'Shipment Section - Create Order'
      },
      ORDER_SUMMARY: {
        path: 'order-summary',
        metaTitle: 'Summary - Create Order'
      },
      PAYMENT: {
        path: 'payment',
        metaTitle: 'Payment - Create Order'
      }
    },
    DRAFTS: {
      path: '/orders/drafts',
      metaTitle: 'Drafts - Orders'
    }
  },
  [routeGroups.SHIPMENTS]: {
    path: '/shipments',
    metaTitle: 'Shipments'
  },
  [routeGroups.INVOICES]: {
    path: '/invoices',
    metaTitle: 'Invoices',
    _ID: {
      path: '/invoices/:id',
      metaTitle: 'Invoice'
    }
  },
  [routeGroups.RECEIPTS]: {
    path: '/receipts',
    metaTitle: 'Receipts',
    _ID: {
      path: '/receipts/:id',
      metaTitle: 'Receipt'
    }
  },
  [routeGroups.USERS]: {
    path: '/users',
    metaTitle: 'Users'
  },
  [routeGroups.TRANSACTIONS]: {
    path: '/transactions',
    metaTitle: 'Transactions',
    REMIT_CASH_TRANSACTIONS: {
      path: '/transactions/remit-cash-transactions',
      metaTitle: 'Remit Cash Transactions'
    }
  },
  [routeGroups.SALESFORCE]: {
    path: '/salesforce',
    pageTitle: 'Salesforce'
  },
  [routeGroups.MANIFEST]: {
    path: '/manifest',
    metaTitle: 'Manifest',
    CENTER_MANIFEST: {
      path: '/manifest/cm',
      metaTitle: 'Center Manifest'
    },
    PRINT_MANIFEST: {
      path: '/manifest/print',
      metaTitle: 'Print Manifest'
    },
    PRINT_CENTER_MANIFEST: {
      path: '/manifest/print_center',
      metaTitle: 'Print Center Manifest'
    },

    PRINT_INTERNATIONAL_MANIFEST: {
      path: '/manifest/print_international',
      metaTitle: 'Print International Manifest'
    },
    PRINT_DOOR_MANIFEST: {
      path: '/manifest/print_door',
      metaTitle: 'Print Doorstep Manifest'
    },
    TRANSFER_MANIFEST: {
      path: '/manifest/tm',
      metaTitle: 'Transfer Manifest'
    },
    DOORSTEP_MANIFEST: {
      path: '/manifest/ds',
      metaTitle: 'Doorstep Manifest'
    },
    INTERNATIONAL_MANIFEST: {
      path: '/manifest/im',
      metaTitle: 'International Manifest'
    },
    CREATE_MANIFEST: {
      path: '/manifest/create-manifest',
      metaTitle: 'Create Manifest'
    },
    CREATE_DOORSTEP: {
      path: '/manifest/create-doorstep',
      metaTitle: 'Create Doorstep Manifest'
    },
    SINGLE_CENTER_MANIFEST: {
      path: '/manifest/single-center-manifest/:id',
      metaTitle: 'Individual Manifest'
    },
    SINGLE_INTERNATIONAL_MANIFEST: {
      path: '/manifest/single-international-manifest/:id',
      metaTitle: 'Individual Manifest'
    },
    SINGLE_TRANSFER_MANIFEST: {
      path: '/manifest/single-transfer-manifest/:id',
      metaTitle: 'Individual Manifest'
    },
    SINGLE_DOORSTEP_MANIFEST: {
      path: '/manifest/single-doorstep-manifest/:id',
      metaTitle: 'Individual Manifest'
    }
  },
  [routeGroups.TRACK]: {
    path: '/track',
    metaTitle: 'Track shipment'
  },
  [routeGroups.IAM_DASHBOARD]: {
    path: '/iam-dashboard',
    metaTitle: 'IAM Dashboard'
  },
  [routeGroups.ACCOUNT_SETTINGS]: {
    path: '/account-settings',
    metaTitle: 'Account Settings'
  },
  [routeGroups.SALESFORCE]: {
    SALESFORCE: {
      path: '/salesforce',
      pageTitle: 'Salesforce'
    }
  },
  [routeGroups.REPORTS]: {
    path: '/reports',
    metaTitle: 'Reports'
  },
  [routeGroups.FLEET]: {
    path: '/fleet',
    metaTitle: 'Fleet Management'
  },
  [routeGroups.INVENTORY]: {
    path: '/inventory',
    metaTitle: 'Inventory & Warehouse'
  }
}

export default ROUTES
