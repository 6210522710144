import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import manifestApi from '../api/manifest'
import { parseError } from '../utils'

const initialState = {
  allCloseBags: [],
  isBag: false
}

export const fetchAllClosedBags = createAsyncThunk(
  'allCloseBags/fetch',
  async () => {
    const response = await manifestApi.getAllClosedBags()

    if (!response.ok) {
      return parseError(response)
    }

    return response.data
  }
)

const allCloseBagsSlice = createSlice({
  name: 'allCloseBags',
  initialState,
  reducers: {
    emptyCloseBags: state => {
      state.isBag = false
      state.allCloseBags = []
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAllClosedBags.fulfilled, (state, action) => {
      state.allCloseBags = action?.payload?.payload
      state.isBag = true
    })
  }
})
export const { emptyCloseBags } = allCloseBagsSlice.actions

export default allCloseBagsSlice.reducer
