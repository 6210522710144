import React, { createContext, useState } from 'react'

export const StateContext = createContext()

export const ContextProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  return (
    <StateContext.Provider
      value={{
        isSidebarOpen,
        setIsSidebarOpen
      }}
    >
      {children}
    </StateContext.Provider>
  )
}
