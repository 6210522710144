import ReactModal from "react-modal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Filter from "../globals/filter/Filter";
import {  useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Office } from "../../assets/office.svg";
import { ReactComponent as Calendar } from "../../assets/calendar.svg";
import { ReactComponent as Status } from "../../assets/status.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const styles = {
  content: {
    borderRadius: "20px",
    maxWidth: "550px",
    margin: "auto",
    height: "fit-content",
    maxHeight: "95%",
    overflow: "auto",
  },
  overlay: {
    backgroundColor: "#0000004f",
    zIndex: 60,
  },
};

export default function SalesforceFilter({
  isOpen,
  onClose,
  filter,
  
}) {

  const branches = useSelector((state) => state?.orgs?.branches);
  const [selectedFilter, setSelectedFilter] = useState(filter);


  const branch_options = useMemo(() => {
    return branches
      ? branches.map((branch) => ({ name: branch.name, value: branch.name }))
      : [];
  }, [branches]);

  

  const handleClose = () => {
    setSelectedFilter(filter);
    onClose();
  };

  
  return (
    <ReactModal style={styles} isOpen={isOpen} onRequestClose={handleClose}>
      <Filter onCancel={handleClose}>
        <div className="flex justify-start items-center space-x-4">
          <Office /> <div>Branch</div>{" "}
          <select name="period" className="h-[40px] rounded border-[0.5px] border-gray-200">
            <option className="bg-white" value={""}>
              {" "}
              Select branch
            </option>
            {branch_options?.map((hub, id) => (
              <option className="bg-white" value={hub?.valua} key={id}>
                {hub.name}
              </option>
            ))}
          </select>
        </div>
        <div className="font-semibold mt-4">
          Select year and/or range of months to apply to dashboard data
        </div>
        <div className="font-semibold mt-4">Date range</div>
        <div className="flex justify-between space-x-2">
          <div className="flex justify-start items-center space-x-2">
            <Calendar /> <div>Start</div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker openTo="day" views={["day"]} className=""/>
              </LocalizationProvider>
            </div>
          </div>
          <div className="flex justify-start items-center space-x-2">
            <Calendar /> <div>End</div>
            <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker openTo="day" views={["day"]} />
            </LocalizationProvider>
          </div> 
          </div>
        </div>
        <div className="font-semibold mt-4">Year range</div>

        <div className="flex justify-start items-center space-x-2">
          <Calendar /> <div>Year</div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker openTo="year" views={["year"]} />
            </LocalizationProvider>
          </div>
        </div>

        <div className="font-semibold mt-4">Month range</div>
        <div className="flex justify-between space-x-2">
          <div className="flex justify-start items-center space-x-2">
            <Calendar /> <div>Start</div>{" "}
            <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker openTo="month" views={["month"]} />
            </LocalizationProvider>
          </div>
          </div>

          <div className="flex justify-start items-center space-x-2">
            <Calendar /> <div>End</div>{" "}
            <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker openTo="month" views={["month"]}/>
            </LocalizationProvider>
          </div>
          </div>
        </div>
        <div className="flex justify-start items-center space-x-2 mt-4">
          <Status /> <div>Status</div>{" "}
          <select name="period" className="h-[40px] rounded border-[0.5px] border-gray-200">
            <option className="bg-white" value={""}>
              {" "}
              Select status
            </option>
            {[{ val: "paid" }, { val: "unpaid" }]?.map((hub, id) => (
              <option className="bg-white" value={hub?.val} key={id}>
                {hub?.val}
              </option>
            ))}
          </select>
        </div>
      </Filter>
    </ReactModal>
  );
}
