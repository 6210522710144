export default function CeoBookingCard ({
  title,
  amount,
  details,
  isExpanded,
  onToggle,
  dateRange,
  filter
}) {
  const displayedDetails = isExpanded ? details : details?.slice(0, 4)

  return (
    <div
      className={`py-4 px-4 shadow-md bg-white rounded-xl h-full flex flex-col ${
        isExpanded ? 'max-h-none' : 'max-h-[250px]'
      } transition-all duration-300`}
    >
      <h6 className='font-semibold text-base mb-3'>
        {title}{' '}
        {amount && <span className='text-xs text-[#333333B2]'>{amount}</span>}
      </h6>
      <hr className='mb-3' />
      <div className='flex-grow overflow-auto'>
        <div className='flex flex-col gap-4 text-sm text-black'>
          {displayedDetails?.map((detail, ind) => (
            <div
              key={ind}
              className='flex items-center justify-between text-[#333333B2]'
            >
              <span>{detail.title}</span>
              <span>{detail.value}</span>
            </div>
          ))}
        </div>
      </div>
      {details?.length > 4 && (
        <div className='mt-4 text-right'>
          <button
            className='text-[#FF4D00] underline text-sm'
            onClick={onToggle}
          >
            {isExpanded ? 'See less' : 'See more'}
          </button>
        </div>
      )}
    </div>
  )
}
