import React, { useEffect, useState } from 'react'
import Page from '../../containers/Page'
import TrackingIcon from '../../components/navicons/Tracking'
import noDataIcon from '../../assets/no-data.webp'
import Pagination from '../../components/globals/pagination/pagination'
import TrackingModal from '../../components/tracking/TrackingModal'
import { useSearchParams } from 'react-router-dom'

const trackingHistory = []

export default function Track ({ metaTitle }) {
  const [trackingModal, setTrackingModal] = useState(false)
  const [tableData, setTableData] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const trackingID = searchParams.get('track_id')

  useEffect(() => {
    if (trackingID) {
      setTrackingModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openTrackingModal = () => {
    setTrackingModal(true)
  }

  const closeTrackingModal = () => {
    setTrackingModal(false)
    setSearchParams({})
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Tracking'}></Page.Header>
      <Page.Body>
        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <h4 className='text-xl font-medium mb-4'>Tracking History</h4>
            <div className='w-fit ml-auto'>
              <button className='btn btn-primary' onClick={openTrackingModal}>
                <TrackingIcon variant={'#ffffff'} className='w-4 mr-1' />
                Track shipment
              </button>
            </div>
          </div>
        </div>
        {trackingModal && (
          <TrackingModal isOpen={trackingModal} onClose={closeTrackingModal} />
        )}
        <div>
          <div className='data-table-wrapper'>
            <table
              className='bg-white w-full z-0 text-left text-dark-primary'
              id='tracking-history-table'
            >
              <thead className='border-b border-[#EFEFEF]'>
                <tr>
                  <th>Tracking ID</th>
                  <th>Client's name</th>
                  <th>Date</th>
                  <th>Weight</th>
                  <th>Origin</th>
                  <th>Destination</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item, id) => (
                  <tr className='border-b border-[#EFEFEF] bg-white' key={id}>
                    <td>{item.tracking_id}</td>
                    <td>{item.name}</td>
                    <td>{item.date}</td>
                    <td>{item.weight}kg</td>
                    <td>{item.origin}</td>
                    <td>{item.destination}</td>
                    <td>
                      <span className='font-medium italic'>{item.status}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {tableData?.length === 0 && (
            <div className='pt-14 w-full flex flex-col items-center justify-center'>
              <img
                src={noDataIcon}
                className='w-40 h-40 object-contain'
                alt='no data icon'
              />
              <p className='text-gray-500 text-left my-2 font-semibold text-base md:text-xl'>
                No tracking history
              </p>
            </div>
          )}
          <div className='mt-10'>
            <Pagination
              tableId='tracking-history-table'
              data={trackingHistory}
              setTableData={setTableData}
            />
          </div>
        </div>
      </Page.Body>
    </Page>
  )
}
