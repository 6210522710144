import ReactModal from 'react-modal'
import { ReactComponent as Times } from '../../assets/times.svg'
import TrackingDetails from './TrackingDetails'
import TrackingError from './TrackingError'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import shipmentsApi from '../../api/shipments'
import manifestApi from '../../api/manifest'
import { parseError } from '../../utils'
import Loader from '../loader/Loader'

const style = {
  content: {
    maxWidth: '750px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto',
    padding: '0',
    borderRadius: '0'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function TrackingModal ({ isOpen, onClose }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [state, setState] = useState('input-tracking')
  const [searchParams] = useSearchParams()
  const [trackingID, setTrackingID] = useState(searchParams.get('track_id'))
  const [manifestID, setManifestID] = useState('')
  const [trackingDetails, setTrackDetails] = useState()
  const [inputState, setInputState] = useState('tracking')

  const handleSubmit = async e => {
    e.preventDefault()
    error && setError('')

    setLoading(true)
    if (inputState === 'tracking') {
      const response = await shipmentsApi.trackShipmentWithTrackingID(
        trackingID.replace('#', '')
      )

      if (!response.ok) {
        const apiError = parseError(response)
        if (apiError) {
          setError(apiError.data.detail.message)
        }
        setLoading(false)
        return
      }

      setLoading(false)
      setTrackDetails(response.data)
      setState('tracking-details')
    } else {
      const response = await manifestApi.bulkTracking(manifestID)
      if (!response.ok) {
        const apiError = parseError(response)
        if (apiError) {
          setError(apiError.data.detail.message)
        }
        setLoading(false)
        return
      }
    }
  }

  const handleInputChange = ({ target }) => {
    if (inputState === 'tracking') {
      setTrackingID(target.value)
    } else {
      setManifestID(target.value)
    }
  }

  const closeShipmentStatus = () => {
    setState('input-tracking')
    setTrackDetails(undefined)
    setTrackingID('')
  }

  return (
    <ReactModal isOpen={isOpen} style={style}>
      <div className='w-full min-h-[600px] overflow-y-auto tracking-modal__background text-dark-primary p-4 pb-12 rounded-[4px]'>
        <div
          className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center'
          onClick={onClose}
        >
          <Times />
        </div>
        {state === 'input-tracking' ? (
          <>
            <h4 className='mb-3 text-2xl font-bold'>
              Let's Track Your Shipment
            </h4>
            <p className='text-sm mb-4 font-light'>
              Find and track packages effortlessly
            </p>
            <form
              id='trackingID'
              className='bg-white p-2 rounded-[30px] text-[17px] w-[500px] flex items-center justify-between'
              onSubmit={handleSubmit}
            >
              <select
                className='border-none h-full focus:border-0 focus:shadow-none bg-white text-sm'
                onChange={e => setInputState(e.target.value)}
              >
                <option className='bg-white' value={'tracking'}>
                  Tracking
                </option>
                <option className='bg-white' value={'manifest'}>
                  Manifest
                </option>
              </select>
              <input
                placeholder={
                  inputState === 'tracking'
                    ? 'Input tracking ID'
                    : 'Input manifest ID'
                }
                className='border-none outline-0 w-full placeholder:text-dark-primary/40'
                required
                defaultValue={trackingID}
                onChange={handleInputChange}
              />
              <button
                className='btn btn-primary rounded-[26px]'
                form='trackingID'
                type='submit'
                disabled={loading}
              >
                <span className='px-2'>Track</span>
              </button>
            </form>
          </>
        ) : (
          <TrackingDetails
            trackingDetails={trackingDetails}
            trackingID={trackingID}
            onClose={closeShipmentStatus}
          />
        )}
        {loading && (
          <div className='pt-8'>
            <Loader page={false} />
          </div>
        )}
        {error && (
          <TrackingError onClose={() => setError('')} message={error} />
        )}
      </div>
    </ReactModal>
  )
}
