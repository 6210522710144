import ReactModal from 'react-modal'
import Filter from '../globals/filter/Filter'
import { useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import DateRangeModal from '../globals/filter/DateRangeModal'
import { AuthLayoutContext } from '../../containers/AuthLayout'

const styles = {
  content: {
    borderRadius: '20px',
    maxWidth: '450px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function TransactionsFilter ({
  isOpen,
  onClose,
  filter,
  setFilter,
  dateRange,
  setDateRange
}) {
  const { userRole } = useContext(AuthLayoutContext)

  const branches = useSelector(state => state?.orgs?.branches)
  const [isDateModalOpen, setIsDateModalOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(filter)

  const [selectedDateRange, setSelectedDateRange] = useState(dateRange)

  const branch_options = useMemo(() => {
    return branches
      ? branches.map(branch => ({ name: branch.name, value: branch.name }))
      : []
  }, [branches])

  const date_options = [
    { name: 'Today', value: 'today' },
    { name: 'Yesterday', value: 'yesterday' },
    { name: 'Last 7 days', value: 'last_7_days' },
    { name: 'Last 30 days', value: 'last_30_days' },
    { name: 'Range', value: 'range' }
  ]

  const payment_method_options = [
    { name: 'Transfer', value: 'TRF' },
    { name: 'POS', value: 'POS' },
    { name: 'CASH', value: 'CASH' },
    { name: 'Pay Later', value: 'PAY_LATER' }
  ]

  const status_options = [
    { name: 'Success', value: 2 },
    { name: 'Pending', value: 1 },
    { name: 'Failed', value: 3 },
    { name: 'Closed', value: 4 },
    // { name: 'Pay Later', value: 5 },
    { name: 'Voided', value: 6 }
  ]

  const onSelectBranch = value => {
    setSelectedFilter(state => ({ ...state, branch: value }))
  }

  const onSelectDate = value => {
    if (value === 'range') {
      setIsDateModalOpen(true)
    }
    setSelectedFilter(state => ({ ...state, date: value }))
  }
  const onRadioChange = ({ target }) => {
    setSelectedFilter(state => ({ ...state, [target.name]: target.value }))
  }

  const cancelDateRange = () => {
    setSelectedFilter(state => ({ ...state, date: '' }))
    setIsDateModalOpen(false)
  }

  const onSaveDateRange = dateRange => {
    setSelectedDateRange(dateRange)
    setIsDateModalOpen(false)
  }

  const applyFilters = () => {
    setFilter(selectedFilter)
    setDateRange(selectedDateRange)
    onClose()
  }

  const handleClose = () => {
    setSelectedFilter(filter)
    onClose()
  }

  const filterExists = useMemo(() => {
    return Object.values(selectedFilter).some(el => !!el)
  }, [selectedFilter])

  return (
    <ReactModal style={styles} isOpen={isOpen} onRequestClose={handleClose}>
      <Filter onCancel={handleClose}>
        <Filter.Radio
          title='Payment Method'
          name='payment_method'
          value={selectedFilter.payment_method}
          options={payment_method_options}
          onChange={onRadioChange}
        />
        <Filter.Radio
          title='Status'
          name='status'
          value={selectedFilter.status}
          options={status_options}
          onChange={onRadioChange}
        />
        {userRole?.domain.index > 0 && (
          <Filter.SelectDropdown
            title='Branch'
            value={selectedFilter.branch}
            placeholder='Select branch'
            options={branch_options}
            onChange={onSelectBranch}
          />
        )}
        <Filter.SelectDropdown
          title='Date'
          value={selectedFilter.date}
          placeholder='Select date'
          options={date_options}
          onChange={onSelectDate}
        />

        <Filter.Submit disabled={!filterExists} onSubmit={applyFilters} />

        <DateRangeModal
          isOpen={isDateModalOpen}
          initialDateRange={dateRange}
          onCancel={cancelDateRange}
          onSave={onSaveDateRange}
        />
      </Filter>
    </ReactModal>
  )
}
