import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ContextProvider } from './context/ContextProvider'
import { Provider } from 'react-redux'
import store from './store'
import './index.css'
import { BrowserRouter as Router } from 'react-router-dom'

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.debug = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ContextProvider>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </ContextProvider>
  </React.StrictMode>
)
// serviceWorker.register();
reportWebVitals()
