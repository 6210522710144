import { FieldArray, useFormikContext } from 'formik'
import { IoMdAdd } from 'react-icons/io'
import { useSelector } from 'react-redux'
import OrderAddOn from './OrderAddon'
import { scrollToTarget } from '../../utils'

export default function OrderAddons ({ isFish, container }) {
  const addonsStore = useSelector(state => state.addons)

  const { values } = useFormikContext()

  const handleAdd = pushAction => {
    const maxLength = !isFish
      ? addonsStore.data.filter(
          item => !item.name.toLowerCase().includes('fish')
        ).length
      : addonsStore.data.length

    if (values.addOns.length < maxLength) {
      const defaultSelected = addonsStore.data.find(item => {
        const isNotSelected = id =>
          values.addOns.every(addon => addon.id !== id)
        return !isFish
          ? !item.name.toLowerCase().includes('fish') && isNotSelected(item.id) // fish documents
          : isNotSelected(item.id)
      })

      pushAction({
        id: defaultSelected.id,
        quantity: ''
      })
    }

    scrollToTarget('.addons--last-item', container)
  }

  return (
    <section id='addons-section'>
      <FieldArray name='addOns'>
        {arrayHelpers => {
          const onAdd = () => handleAdd(arrayHelpers.push)

          return (
            <div>
              <div className='flex items-center justify-between bg-secondary px-4 md:px-8 py-4 w-full'>
                <h3 className='text-white'>Add Ons</h3>
                <button
                  onClick={onAdd}
                  type='button'
                  className='btn btn-sm btn-ghost text-white gap-1 items-center'
                >
                  <IoMdAdd size={16} /> Add
                </button>
              </div>
              <div className='px-4 md:px-8'>
                {values.addOns.map((addOn, index) => {
                  // const addonErrors = errors.addOns
                  //   ? errors.addOns[index]
                  //   : null

                  return (
                    <OrderAddOn
                      key={index}
                      index={index}
                      addOn={addOn}
                      isLast={index === values.addOns.length - 1}
                      onAdd={onAdd}
                      onRemove={() => arrayHelpers.remove(index)}
                      defaultOpen={true}
                      isFish={isFish}
                    />
                  )
                })}
              </div>
            </div>
          )
        }}
      </FieldArray>
    </section>
  )
}
