import { useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import Pill from '../../../globals/Pill'

const themes = {
  processing: {
    primary: '#FFC700',
    secondary: '#FFF6D799'
  },
  processed: {
    primary: '#61A966',
    secondary: '#d8f7da99'
  },
  error: {
    primary: '#DE350B',
    secondary: '#F5CCC299'
  }
}

const ShipmentCard = ({ data, isActive, onClick }) => {
  const { orderDetails, shipment } = data

  return (
    <div
      className={twMerge(
        'rounded-[4px] bg-white border min-h-24 w-fit lg:w-full hover:shadow-md cursor-pointer select-none lg:text-[13px]',
        isActive ? 'border-primary' : ''
      )}
      onClick={onClick}
    >
      <div className='border-b p-1'>
        <span className='font-medium'>#{orderDetails.order_id}</span>
      </div>
      <div className='py-1 px-1.5 space-y-1'>
        <div className='flex flex-col items-center gap-1'>
          <span>Status:</span>
          <Pill name={shipment.status} theme={themes[shipment.status]} />
        </div>
      </div>
    </div>
  )
}

export default function MultipieceShipments ({
  shipments,
  onSelectShipment,
  activeShipment
}) {
  const stickyHeader = useRef(null)

  return (
    <div className='bg-white lg:rounded-lg lg:border divide-y lg:text-sm'>
      <div className='p-1.5'>
        <h4 className='font-medium lg:text-center'>Multipiece shipment</h4>
      </div>
      <div className='lg:min-h-40 lg:max-h-[70vh] lg:overflow-y-auto'>
        <div
          className='lg:text-[13px] text-dark-primary flex flex-row items-center lg:items-start lg:flex-col gap-2 lg:gap-0 lg:sticky-header py-1 px-1.5 bg-white'
          ref={stickyHeader}
        >
          <p>Shipments</p>
        </div>
        <div className='py-2 lg:pt-1 lg:pb-4 px-1.5 space-x-1 flex w-full overflow-x-auto lg:space-y-1 lg:block lg:overflow-x-hidden'>
          {shipments.map((shipment, id) =>
            shipment.status !== 'listening' ? (
              <ShipmentCard
                key={id}
                data={shipment}
                onClick={() => onSelectShipment(shipment)}
                isActive={
                  shipment.orderDetails?.id === activeShipment.orderDetails?.id
                }
              />
            ) : null
          )}
        </div>
      </div>
    </div>
  )
}
