import ReactModal from "react-modal";
import confetti from "../../assets/confetti.svg";
import success from "../../assets/succes.png";
import { ReactComponent as Times } from "../../assets/times.svg";
import { formatAmount } from "../../utils";

const customStyles = {
  content: {
    borderRadius: "20px",
    maxWidth: "668px",
    height: "fit-content",
    maxHeight: "95%",
    margin: "auto",
  },
  overlay: {
    backgroundColor: "#0000004f",
    zIndex: 60,
  },
};

export default function MarkPaidSuccessful({
  openSuccessModal,
  setOpenSuccessModal,
  amount,
  salesForceAgent  
}) {
  return (
    <ReactModal style={customStyles} isOpen={openSuccessModal}>
      <div className="w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer">
        <Times  onClick={()=>setOpenSuccessModal(false)}/>
      </div>
      <div className="relative">
        <div className="flex flex-col items-center">
          <img
            src={confetti}
            className="absolute top-4 left-1/2 -translate-x-1/2"
            alt="bg-confetti"
          />

          <div className="pt-14 pb-10 flex flex-col items-center w-full text-center">
            <div className="mb-4">
              <img src={success} alt="success" className="w-40" />
            </div>

            <div className="mb-8">
              <h4 className="font-semibold mb-4">Successful</h4>
              <p className="text-[#3A3A3AB2] text-sm max-w-sm">
                You have successfully made a payment of ₦{formatAmount(amount?.toFixed(2))} for the
                commission earned by {salesForceAgent?.first_name} {salesForceAgent?.last_name}.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
