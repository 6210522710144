import React, { useRef, useState } from 'react'
import { Field, useFormikContext } from 'formik'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import useOnClickOutside from '../../../hooks/useOnClickOutside'

const Suggestions = ({ id, suggestions, onSelect }) => {
  return (
    <div
      id={id}
      className='z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700 absolute mt-24 max-h-64 overflow-y-auto'
    >
      {suggestions?.map((item, index) => (
        <div id='dropdownDivider' key={index}>
          <ul
            className='py-2 text-sm text-gray-700 dark:text-gray-200'
            aria-labelledby='dropdownDividerButton'
          >
            <li className='cursor-pointer' onClick={() => onSelect(item)}>
              <p className='block px-4 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
                {item.description}
              </p>
            </li>
          </ul>
        </div>
      ))}
    </div>
  )
}

const AppFormField = ({
  name,
  title,
  type,
  placeholder,
  auto,
  step = '0',
  showNaira = false,
  handleOnChange = () => {},
  required,
  phonecode,
  value,
  toggleVisibility,
  checked,
  withSuggestions,
  suggestions,
  onSelectSuggestion,
  onCloseSuggestions,
  ...restProps
}) => {
  const formikContext = useFormikContext()
  const [passwordVisible, setPasswordVisible] = useState(false)

  const fieldContainer = useRef(null)

  useOnClickOutside(fieldContainer, !!suggestions?.length, () => {
    onCloseSuggestions()
  })

  if (formikContext) {
    return (
      <div className='flex flex-col w-full gap-2 relative' ref={fieldContainer}>
        <div className='form-control w-full'>
          {type !== 'checkbox' && (
            <label className='label'>
              <span className='label-text'>{title}</span>
            </label>
          )}
          <Field name={name}>
            {({ field, form, meta }) => {
              return (
                <>
                  {phonecode ? (
                    <div className='flex input px-0 divide-x'>
                      <span className='h-full flex items-center justify-center min-w-[5rem] w-fit px-1'>
                        {phonecode}
                      </span>
                      <div>
                        <input
                          className='bg-transparent border-none rounded-r-lg outline-0'
                          step={step}
                          placeholder={placeholder || title}
                          {...field}
                          id={name}
                          type={type}
                          onChange={e => {
                            field.onChange(e)
                            handleOnChange(e.target.value, e.target.name)
                          }}
                          autoComplete={auto}
                          onWheel={e => e.target.blur()}
                          {...restProps}
                        />
                      </div>
                    </div>
                  ) : type === 'password' && toggleVisibility ? (
                    <div className='relative'>
                      <input
                        className='input w-full'
                        step={step}
                        placeholder={placeholder || title}
                        {...field}
                        id={name}
                        type={passwordVisible ? 'text' : 'password'}
                        autoComplete='current-password'
                        onChange={e => {
                          field.onChange(e)
                          handleOnChange(e.target.value, e.target.name)
                        }}
                        onWheel={e => e.target.blur()}
                        {...restProps}
                      />
                      <span
                        className='absolute top-1/2 -translate-y-1/2 right-6'
                        role='button'
                        onClick={() => setPasswordVisible(state => !state)}
                      >
                        {passwordVisible ? (
                          <BsEye size={22} />
                        ) : (
                          <BsEyeSlash size={22} />
                        )}
                      </span>
                    </div>
                  ) : type === 'checkbox' ? (
                    <label className='label cursor-pointer'>
                      <span className='label-text mr-4'>{title}</span>
                      <input
                        className='checkbox checkbox-primary'
                        {...field}
                        id={name}
                        type={type}
                        onChange={e => {
                          field.onChange(e)
                          handleOnChange(e.target.value, e.target.name)
                        }}
                        {...restProps}
                      />
                    </label>
                  ) : (
                    <input
                      className='input w-full'
                      step={step}
                      placeholder={placeholder || title}
                      {...field}
                      id={name}
                      type={type}
                      onChange={e => {
                        field.onChange(e)
                        handleOnChange(e.target.value, e.target.name)
                      }}
                      onWheel={e => e.target.blur()}
                      {...restProps}
                    />
                  )}

                  {meta.touched && meta.error && (
                    <p className='text-red-500 text-sm'>{meta.error}</p>
                  )}
                  {showNaira && (
                    <p className='text-primary self-end mt-1'>
                      {(field.value * 1 || 0).toLocaleString('en-NG', {
                        style: 'currency',
                        currency: 'NGN'
                      })}
                    </p>
                  )}
                </>
              )
            }}
          </Field>
        </div>
        {withSuggestions && (
          <Suggestions
            id={`${name}-suggestions-dropdown`}
            suggestions={suggestions}
            onSelect={onSelectSuggestion}
          />
        )}
      </div>
    )
  }

  return (
    <div className='flex flex-col w-full gap-2 relative' ref={fieldContainer}>
      <div className='form-control w-full'>
        {type !== 'checkbox' && (
          <label className='label'>
            <span className='label-text'>{title}</span>
          </label>
        )}

        {phonecode ? (
          <div className='flex input px-0 divide-x'>
            <span className='h-full flex items-center justify-center min-w-[5rem] w-fit px-1'>
              {phonecode}
            </span>
            <input
              placeholder={placeholder || title}
              onChange={e => {
                handleOnChange(e.target.value, e.target.name)
              }}
              id={name}
              name={name}
              type={type}
              value={value}
              autoComplete={auto}
              className='bg-transparent border-none rounded-r-lg outline-0 w-full'
              step={step}
              onWheel={e => e.target.blur()}
              required={required}
              {...restProps}
            />
          </div>
        ) : type === 'password' && toggleVisibility ? (
          <div className='relative'>
            <input
              placeholder={placeholder || title}
              onChange={e => {
                handleOnChange(e.target.value, e.target.name)
              }}
              id={name}
              name={name}
              type={passwordVisible ? 'text' : 'password'}
              value={value}
              autoComplete={auto}
              className='input w-full'
              step={step}
              onWheel={e => e.target.blur()}
              required={required}
              {...restProps}
            />
            <span
              className='absolute top-1/2 -translate-y-1/2 right-6'
              role='button'
              onClick={() => setPasswordVisible(state => !state)}
            >
              {passwordVisible ? <BsEye size={22} /> : <BsEyeSlash size={22} />}
            </span>
          </div>
        ) : type === 'checkbox' ? (
          <label className='label cursor-pointer'>
            <span className='label-text mr-4'>{title}</span>
            <input
              type='checkbox'
              checked={checked}
              value={value}
              className='checkbox checkbox-primary'
              name={name}
              onChange={e => {
                handleOnChange()
              }}
            />
          </label>
        ) : (
          <input
            placeholder={placeholder || title}
            onChange={e => {
              handleOnChange(e.target.value, e.target.name)
            }}
            id={name}
            name={name}
            type={type}
            value={value}
            autoComplete={auto}
            className='input'
            step={step}
            onWheel={e => e.target.blur()}
            required={required}
            {...restProps}
          />
        )}

        {showNaira && (
          <p className='text-primary self-end mt-1'>
            {(value * 1 || 0).toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })}
          </p>
        )}
      </div>

      {withSuggestions && (
        <Suggestions suggestions={suggestions} onSelect={onSelectSuggestion} />
      )}
    </div>
  )
}

export default AppFormField
