import ROUTES from '../../constants/routes'
import local from '../../assets/local-shipment.svg'
import international from '../../assets/international-shipment.svg'
import useNavigateWithParams from '../../hooks/useNavigateWithParams'
import { useSearchParams } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { CreateOrderContext } from '../../containers/CreateOrderLayout'
import { FiCheckCircle } from 'react-icons/fi'

const Item = ({ handleOnClick, image, text, selected }) => {
  return (
    <button
      className='bg-[#f2f2f2] px-10 py-16 gap-4 rounded-lg hover:shadow-lg hover:-translate-y-1 transition-all w-full max-w-64 flex flex-col items-center text-center relative'
      onClick={handleOnClick}
    >
      <img
        style={{ width: '128px', height: '128px', objectFit: 'contain' }}
        src={image}
        alt=''
        className='mb-4'
      />
      <p className='uppercase text-xl font-semibold'>{text}</p>
      {selected && (
        <span className='text-main-primary absolute bottom-4 right-4'>
          <FiCheckCircle size={18} />
        </span>
      )}
    </button>
  )
}

export default function ShipmentType ({ metaTitle }) {
  const [searchParams] = useSearchParams()

  const isMultipiece = searchParams.get('mpo')
  const billTo = searchParams.get('bill_to')

  const { updateActiveStep, order, updateOrder, resolvePathname } =
    useContext(CreateOrderContext)

  const navigateWithParams = useNavigateWithParams()

  useEffect(() => {
    updateActiveStep({
      stepId: 0,
      metaTitle,
      back: `${ROUTES.ORDERS.path}`
    })
  }, [metaTitle, updateActiveStep])

  const handleSelectType = type => {
    updateOrder({
      type
    })
    setTimeout(() => {
      navigateWithParams({
        pathname: resolvePathname(
          `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}`
        ),
        search: {
          ctm: isMultipiece
            ? billTo === 'sender'
              ? 'receiver'
              : 'sender'
            : 'sender'
        }
      })
    }, 500)
  }

  const types = [
    {
      name: 'International',
      id: 'IN',
      image: international
    },
    {
      name: 'Local',
      id: 'LC',
      image: local
    }
  ]

  return (
    <div>
      <h2 className='py-4 md:pt-0 font-bold text-2xl text-center'>
        Type of Shipment
      </h2>
      <div className='bg-white rounded-lg w-full p-4 lg:p-6 lg:pb-12 max-w-3xl mx-auto border mt-5 lg:mt-10 flex flex-row flex-wrap justify-center gap-5'>
        {types.map(type => (
          <Item
            key={type.id}
            handleOnClick={() => handleSelectType(type.id)}
            image={type.image}
            text={type.name}
            selected={order.payload.type === type.id}
          />
        ))}
      </div>
    </div>
  )
}
