import { useContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import useToast from '../../hooks/useToast'
import customerApi from '../../api/customer'
import { Formik } from 'formik'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import CustomerForm from './CustomerForm'
import { parseError } from '../../utils'
import useCountry from '../../hooks/useCountry'

export default function NewCustomer ({
  customerType,
  shipmentType,
  onNewCustomer,
  onCloseNewCustomer,
  customer,
  isEdit
}) {
  const [phonecode, setPhonecode] = useState('')

  const toast = useToast()
  const isLocal = shipmentType === 'LC'

  const { layoutContainer } = useContext(AuthLayoutContext)

  const Country = useCountry()

  useEffect(() => {
    layoutContainer?.scrollTo({ top: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialValues = (() => {
    if (customer) {
      const phonecode = Country.getCountryByCode(customer.code)?.phonecode
      customer.phone_number = customer.phone_number.replace(phonecode, '')
      customer.business_contact = customer.business_contact.replace(
        phonecode,
        ''
      )
    }

    const config = {
      full_name: customer?.full_name || '',
      address: customer?.address || '',
      alt_address: customer?.alt_address || '',
      alt_address_2: customer?.alt_address_2 || '',
      code: customer?.code || (isLocal ? 'NG' : ''),
      state: customer?.state || '',
      state_name: customer?.state_name || '',
      city: customer?.city || '',
      postcode: customer?.postcode || '',
      phone_number: customer?.phone_number || '',
      email: customer?.email || '',
      business_name: customer?.business_name || '',
      business_contact: customer?.business_contact || '',
      area_or_province: customer?.area_or_province || ''
    }

    return config
  })()

  const validationSchema = yup.object().shape(
    (() => {
      const config = {
        full_name: yup.string().required('Full name is required'),
        address: yup
          .string()
          .required('Address is required')
          .max(45, 'Must not exceed 45 characters'),
        alt_address: yup.string().max(45, 'Must not exceed 45 characters'),
        alt_address_2: yup.string().max(45, 'Must not exceed 45 characters'),
        code: yup.string().required('Country is required'),
        state_name: yup.string().required('State name is required'),
        state: yup.string().required('State code is required'),
        city: yup.string().required('City is required'),
        phone_number: yup
          .string()
          .required('Phone number is required')
          .min(6, 'Minimum of 6 numbers')
          .max(15, 'Maximum of 15 numbers'),
        email: yup
          .string()
          .required('Email is required')
          .email('Must be a valid email'),
        business_name: yup.string(),
        business_contact: yup
          .string()
          .min(6, 'Minimum  of 6 numbers')
          .max(15, 'Maximum of 15 numbers')
      }

      if (isLocal) {
        config.area_or_province = yup
          .string()
          .required('Province or Area is required')
      } else {
        config.postcode = yup
          .string()
          .required('Postcode is required')
          .min(4, 'Min. of 4 characters')
          .max(12, 'Max. of 12 characters')
        if (customerType === 'receiver') {
          config.business_name = yup
            .string()
            .required('Business name is required')
        }
      }

      return config
    })()
  )

  const handleOnSubmit = async (body, actions) => {
    if (customerType === 'sender' && body.code !== 'NG') {
      toast('Sender must be a Nigerian address', 'error')
      return
    }

    if (shipmentType === 'IN') {
      if (customerType === 'receiver' && body.code === 'NG') {
        toast('Receiver must not be a Nigerian address', 'error')
        return
      }
    } else {
      if (customerType === 'receiver' && body.code !== 'NG') {
        toast('Receiver must be a Nigerian address', 'error')
        return
      }
    }

    const country = Country.getCountryByCode(body.code)

    const payload = {
      ...body,
      phone_number: `${phonecode}${body.phone_number}`,
      business_contact: body.business_contact
        ? `${phonecode}${body.business_contact}`
        : '',
      country: country.name
    }

    let response

    if (isEdit) {
      response = await customerApi.updateCustomer(customer.id, payload)
    } else {
      response = await customerApi.createCustomer(payload)
    }

    actions.setSubmitting(false)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        if (response.data.status_code >= 500) {
          toast(response.data.message, 'error')
        } else {
          if (response.data.detail) {
            let error = ''
            if (Array.isArray(response.data.detail)) {
              error = response.data.detail[0].message
            } else {
              const errors = Object.entries(response.data.detail)
              const key = errors[0][0]
              const message = errors[0][1][0].message

              error = `${key.replace('_', ' ')}: ${message}`
            }

            toast(error, 'error')
          } else if (response.data.error) {
            toast(response.data.error, 'error')
          }
        }
      }

      return
    }

    if (isEdit) {
      toast('Updated customer successfully')
    } else {
      toast('Customer saved to address book')
    }

    onNewCustomer(response.data.payload)
  }

  return (
    <div className='pt-4'>
      <div className='flex w-full items-center relative justify-center'>
        <button
          onClick={onCloseNewCustomer}
          className='btn btn-sm btn-primary absolute left-0'
        >
          Back
        </button>
        <h1 className='text-[#FF4D00] text-xl mb-4'>
          {isEdit ? 'Edit Customer' : 'Create New Customer'}
        </h1>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <CustomerForm
            formik={formik}
            customerType={customerType}
            phonecode={phonecode}
            setPhonecode={setPhonecode}
            isLocal={isLocal}
          />
        )}
      </Formik>
    </div>
  )
}
