import React, { useState } from 'react'

import { FiArrowUpRight, FiArrowDownLeft } from 'react-icons/fi'
import Loader from '../loader/Loader'
import { AiOutlineClose } from 'react-icons/ai'
import { State } from 'country-state-city'
import { useDispatch, useSelector } from 'react-redux'
import { SiMinutemailer } from 'react-icons/si'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SendQuote from './SendQuote'
import { fetchQuotes } from '../../slices/quotesSlice'
import ROUTES from '../../constants/routes'

const TempQuote = ({
  data: quote,
  onClose,
  formData,
  allowCreateOrder,
  customer
}) => {
  const addonsStore = useSelector(state => state.addons)
  const [isSendQuote, setIsSendQuote] = useState(false)
  const [searchParams] = useSearchParams()
  const isIframe = searchParams.get('req') === 'iframe'

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onSendQuote = () => {
    setIsSendQuote(true)
  }

  const closeSendQuote = () => {
    setIsSendQuote(false)
  }

  const onProceedToOrder = () => {
    dispatch(fetchQuotes())
    const payload = {
      sender: null,
      receiver: null,
      partner: customer === 'Partners',
      shipa_or_ecommerce: customer === 'E-commerce',
      type: quote.sender_country === quote.receiver_country ? 'LC' : 'IN',
      package_insurance: '',
      packages: {
        type: quote.packages.type,
        itemsValue:
          quote.packages.itemsValue ?? formData?.packages.itemsValue ?? '',
        exportReason: '',
        items: quote.packages.items || [
          {
            name: '',
            quantity: '',
            price: '',
            hsCode: '',
            unitMeasurement: '',
            manufacturerCountry: '',
            weight: ''
          }
        ],
        addOns: quote.packages.addOns ?? formData?.packages.addOns,
        packages: quote.packages.packages.map(item => {
          if (item.predefinedDimension) {
            return {
              ...item,
              packageDimension: null
            }
          }
          return item
        })
      },
      tpl_service: quote.packages.tpl || formData?.tpl_service,
      description: '',
      served_by: '',
      branch_name: '',
      draft: false,
      category: formData.category,
      get_acknowledgement_copy: false
    }

    // window.sessionStorage.removeItem('orderPayload')
    window.sessionStorage.removeItem('order')
    window.sessionStorage.removeItem('multipiece')
    window.sessionStorage.removeItem('multipieceOrders')

    navigate(
      `${ROUTES.ORDERS.CREATE_ORDER.path}${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}?ord=${quote.order}&qt=${quote.id}`,
      {
        state: {
          payload
        }
      }
    )
  }

  const onViewOrder = () => {
    navigate(`${ROUTES.ORDERS.path}?id=${quote.order}`)
  }

  if (!addonsStore.data) {
    return <Loader />
  }

  return (
    <>
      <SendQuote isOpen={isSendQuote} onClose={closeSendQuote} id={quote.id} />
      <div className='flex flex-col items-center gap-10 justify-end'>
        <div className='gap-5 flex w-full justify-end pb-4 border-b border-gray-100'>
          <button
            className='btn btn-accent btn-sm md:btn-md'
            type='submit'
            onClick={onClose}
          >
            <p className='hidden md:block'>Close</p>
            <AiOutlineClose className='md:hidden block' />
          </button>
        </div>
        <div className='flex w-full bg-white pt-0 md:px-10 md:pb-10 gap-8 text-center flex-col rounded-lg'>
          <div className='pb-6 border-b border-[#E0E0E0]'>
            <h1 className='font-bold text-2xl md:text-4xl'>
              {quote.total.toLocaleString('en-NG', {
                style: 'currency',
                currency: 'NGN'
              })}
              <span className='text-sm text-[#FF4D00]'>NGN</span>
            </h1>
            <p className='text-dark-primary/70 mt-2'>
              {quote.weight}KG | #{quote.id} |{' '}
              {quote.rate === 'SR' ? 'Standard Rate' : 'Cargo Rate'}
              {quote.packages.tpl || formData?.tpl_service
                ? ` | ${quote.packages.tpl || formData?.tpl_service}`
                : ''}
            </p>
          </div>

          <div className='flex gap-4 flex-col'>
            <h1 className='text-left font-bold text-xl md:text-2xl'>Summary</h1>
            <div className='flex gap-4 flex-col px-3 bg-gray-50 rounded-lg lg:rounded-xl divide-y  divide-gray-100'>
              <div className='flex items-center gap-6 text-left w-full py-5'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <FiArrowUpRight size={18} color='#FF4D00' />
                </div>
                <div>
                  <h1 className='font-semibold'>
                    {formData &&
                      `${formData.sender_city}, ${
                        State.getStateByCodeAndCountry(
                          formData.sender_state,
                          formData.sender_country_code
                        )?.name || formData.sender_state
                      }, ${quote?.sender_country
                        .split(',')[0]
                        .trim()
                        .replace("('", '')} `}
                    {quote?.sender_country
                      .split(',')[0]
                      .trim()
                      .replace("('", '')}
                  </h1>
                </div>
              </div>
              <div className='flex items-center gap-6 text-left w-full py-5'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <FiArrowDownLeft size={18} color='#FF4D00' />
                </div>
                <div>
                  <h1 className='font-semibold'>
                    {formData &&
                      `${formData.receiver_city}, ${
                        State.getStateByCodeAndCountry(
                          formData.receiver_state,
                          formData.receiver_country_code
                        )?.name || formData.receiver_state
                      }, ${quote?.receiver_country
                        .split(',')[0]
                        .trim()
                        .replace("('", '')} `}
                    {quote?.receiver_country
                      .split(',')[0]
                      .trim()
                      .replace("('", '')}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-4 divide-y divide-g-100 border-dashed border-gray-200 border-b'>
            <div className='flex items-center justify-between'>
              <span>Weight</span>
              <span className='font-medium'>{quote.weight} KG</span>
            </div>
            <div className='flex items-center justify-between pt-4'>
              <span>Shipping fee</span>
              <span className='font-medium'>
                {quote.extra_charges.on_shipping_fee.toLocaleString('en-NG', {
                  style: 'currency',
                  currency: 'NGN'
                })}
              </span>
            </div>

            {/* ADDONS SECTION */}
            {quote.addons.addons.length > 0 && (
              <div className='flex flex-col gap-4 pt-2 text-left'>
                Add Ons
                <div className='flex flex-col gap-3'>
                  {quote.addons.addons.map(addon => (
                    <div
                      key={addon.id}
                      className='flex items-center justify-between bg-gray-100 p-2'
                    >
                      <span>
                        {`
                    ${
                      addonsStore.data.find(
                        addonItem => addonItem.id === addon.id
                      ).name
                    } (${addon.quantity})`}
                      </span>
                      <span className='font-medium text-lg'>
                        {addon.price.toLocaleString('en-NG', {
                          style: 'currency',
                          currency: 'NGN'
                        })}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {!(quote.insurance.type === 'FR') && (
              <div className=' flex items-center justify-between pt-4'>
                <span>
                  Insurance Fee (
                  {quote.insurance.type === 'FR'
                    ? 'Free'
                    : quote.insurance.type === 'PM'
                    ? 'Premium'
                    : quote.insurance.type === 'SD'
                    ? 'Standard'
                    : quote.insurance.type === 'EI'
                    ? 'Electronics Insurance'
                    : quote.insurance.type === 'NE'
                    ? 'Non-Electronics Insurance'
                    : quote.insurance.type === 'HI'
                    ? 'Haulage'
                    : null}
                  )
                </span>
                <span className='font-medium'>
                  {quote.insurance.fee.toLocaleString('en-NG', {
                    style: 'currency',
                    currency: 'NGN'
                  })}
                </span>
              </div>
            )}

            <div className='flex items-center justify-between pt-4'>
              <span>
                Fuel surcharge ({quote.extra_charges.fuel_surcharge.value}%)
              </span>
              <span className='font-medium'>
                {quote.extra_charges.fuel_surcharge.amount.toLocaleString(
                  'en-NG',
                  { style: 'currency', currency: 'NGN' }
                )}
              </span>
            </div>

            {quote.extra_charges.security_handling_fee && (
              <div className='flex items-center justify-between pt-4'>
                <span>Security handling fee</span>
                <span className='font-medium text-lg'>
                  {quote.extra_charges.security_handling_fee.amount.toLocaleString(
                    'en-NG',
                    {
                      style: 'currency',
                      currency: 'NGN'
                    }
                  )}
                </span>
              </div>
            )}
            {quote.extra_charges.packaging_fee && (
              <div className='flex items-center justify-between pt-4'>
                <span>Packaging fee</span>
                <span className='font-medium text-lg'>
                  {quote.extra_charges.packaging_fee.amount.toLocaleString(
                    'en-NG',
                    {
                      style: 'currency',
                      currency: 'NGN'
                    }
                  )}
                </span>
              </div>
            )}

            <div className=' flex items-center justify-between pt-4'>
              <span>Subtotal</span>
              <span className='font-medium'>
                {quote.sub_total.toLocaleString('en-NG', {
                  style: 'currency',
                  currency: 'NGN'
                })}
              </span>
            </div>

            <div className='flex items-center justify-between pt-4'>
              <span>Tax (VAT {quote.vat}%)</span>
              <span className='font-medium'>
                {quote.tax.toLocaleString('en-NG', {
                  style: 'currency',
                  currency: 'NGN'
                })}
              </span>
            </div>

            <div className='flex items-center justify-between pb-10 pt-4'>
              <span>Total</span>
              <span className='font-semibold text-lg'>
                {quote.total.toLocaleString('en-NG', {
                  style: 'currency',
                  currency: 'NGN'
                })}
              </span>
            </div>
          </div>

          <div
            className={`grid ${
              isIframe ? 'grid-cols-1' : 'grid-cols-2'
            } gap-2 md:gap-4`}
          >
            <button
              className='btn btn-secondary text-white w-full'
              onClick={onSendQuote}
            >
              Send to customer
              <SiMinutemailer size={18} className='ml-2' />
            </button>

            {!isIframe && (
              <button
                className='btn btn-primary text-white w-full'
                onClick={
                  quote.has_complete_order ? onViewOrder : onProceedToOrder
                }
                disabled={
                  true || quote.has_complete_order ? false : allowCreateOrder
                } // disable create flow until bug is fixed
              >
                {quote.has_complete_order
                  ? 'View Order'
                  : 'Proceed to create order'}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TempQuote
