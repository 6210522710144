import { useEffect, useMemo } from 'react'
import ROUTES from '../../constants/routes'
import { getDate, getStateOfCountry } from '../../utils'
import { Link, useNavigate } from 'react-router-dom'
import ordersApi from '../../api/orders'
import useApi from '../../hooks/useApi'
import { statuses } from '../../fixtures/orderStatus'
import Pill from '../globals/Pill'

export default function RecentOrders () {
  const navigate = useNavigate()

  const { data, request: loadOrders } = useApi(ordersApi.getOrders, {
    page: 1,
    page_size: 10
  })

  useEffect(() => {
    loadOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnClickOrder = id => {
    navigate(`${ROUTES.ORDERS.path}?id=${id}`)
  }

  const orders = useMemo(() => {
    if (data) return data.payload
    return []
  }, [data])

  return (
    <div>
      <div className='flex items-center justify-between w-full'>
        <h5 className='text-2xl leading-9 font-semibold mt-2 mb-4'>
          Recent Orders
        </h5>
        <Link
          to={ROUTES.ORDERS.path}
          className='text-primary text-sm font-medium hover:underline'
        >
          View all
        </Link>
      </div>
      <div className='py-4 px-4 shadow-md-right bg-white rounded-xl h-full overflow-x-auto'>
        <table id='recent-orders-table' className='table--hover table--striped'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Customer</th>
              <th>Contact</th>
              <th>Origin</th>
              <th>Destination</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, id) => (
              <tr key={id} onClick={() => handleOnClickOrder(order.id)}>
                <td>#{order.id}</td>
                <td>{getDate(order.order_date)}</td>
                <td>{order.sender.contact?.name}</td>
                <td>{order.sender.contact?.phone_number}</td>
                <td>
                  {order.sender.address.state_name ||
                    getStateOfCountry(
                      order.sender.address?.state_or_province_code,
                      order.sender.address?.country
                    )?.name ||
                    order.sender.address?.state_or_province_code}
                  , {order.sender.address?.country}
                </td>
                <td>
                  {order.receiver.address.state_name ||
                    getStateOfCountry(
                      order.receiver.address?.state_or_province_code,
                      order.receiver.address?.country
                    )?.name ||
                    order.receiver.address?.state_or_province_code}
                  , {order.receiver.address?.country}
                </td>
                <td>
                  <Pill
                    name={statuses[order.order_state].name}
                    theme={statuses[order.order_state].theme}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
