import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDrafts } from '../../slices/ordersSlice'
import Page from '../../containers/Page'
import Loader from '../../components/loader/Loader'
import { AiOutlineDelete } from 'react-icons/ai'
import Checkbox from '../../components/globals/Checkbox'
import Pagination from '../../components/globals/pagination/ServerPagination'
import { getDate, getStateOfCountry } from '../../utils'
import Search from '../../components/globals/Search/Search'
import { HiOutlinePencil } from 'react-icons/hi2'
import OrderDraftModal from '../../components/orders/drafts/OrderDraftModal'
import Unselect from '../../components/globals/Unselect'
import ConfirmDelete from '../../components/orders/confirmDelete'
import ordersApi from '../../api/orders'
import useToast from '../../hooks/useToast'

export default function OrderDrafts ({ metaTitle }) {
  const [checkedList, setCheckedList] = useState({})
  const [serializedData, setSerializedData] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [searchBy, setSearchBy] = useState('id')
  const [editModal, setEditModal] = useState({
    isOpen: false,
    order_id: null
  })
  const [deletePrompt, setDeletePrompt] = useState({
    isOpen: false,
    type: '',
    order_id: null
  })
  const [selectedAll, setSelectedAll] = useState(false)
  const [isDeleting, setDeleting] = useState(false)

  const dispatch = useDispatch()
  const toast = useToast()

  const draftsStore = useSelector(state => state.orders.drafts)

  const drafts = useMemo(() => {
    if (serializedData) {
      return serializedData
    } else return null
  }, [serializedData])

  const loadDrafts = useCallback(params => {
    dispatch(fetchDrafts(params))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadDrafts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchOptions = [
    {
      name: 'Order ID',
      value: 'id'
    },
    {
      name: 'Sender',
      value: 'sender_name'
    },
    {
      name: 'Receiver',
      value: 'receiver_name'
    }
  ]

  const toggleItemCheck = order_id => {
    setCheckedList(state => ({
      ...state,
      [order_id]:
        typeof checkedList[order_id] === 'boolean' ? !state[order_id] : true
    }))
  }

  const toggleSelectAll = () => {
    const newValue = !selectedAll
    setSelectedAll(newValue)
    if (newValue) {
      selectAll()
    } else {
      deSelectAll()
    }
  }

  const selectAll = () => {
    const list = drafts.reduce((acc, draft) => {
      return { ...acc, [draft.id]: true }
    }, {})
    setCheckedList(list)
  }

  const deSelectAll = () => {
    setCheckedList({})
  }

  const selectedDrafts = useMemo(() => {
    const list = []
    for (let order in checkedList) {
      if (checkedList[order]) {
        list.push(order)
      }
    }

    return list
  }, [checkedList])

  const onPage = params => {
    setSerializedData(null)
    loadDrafts(params)
  }

  const onSearchChange = ({ target }) => {
    setSearchValue(target.value)
  }

  const searchInputPlaceHolder = useMemo(() => {
    switch (searchBy) {
      case 'id':
        return 'Enter order ID'
      case 'sender_name':
        return 'Enter sender name'
      case 'receiver_name':
        return 'Enter receiver name'
      default:
        break
    }
  }, [searchBy])

  const handleOrderClick = order_id => {
    setEditModal({
      isOpen: true,
      order_id
    })
  }

  const handleCloseModal = () => {
    setEditModal({
      isOpen: false,
      order_id: null
    })
  }

  const handleEdit = order_id => {
    setEditModal({
      isOpen: true,
      order_id
    })
  }

  const handleDelete = async () => {
    const deleteOrder = async id => {
      const response = await ordersApi.deleteOrder(id)
      return response
    }

    const onComplete = type => {
      toast(`Draft${type === 'multiple' ? 's' : ''} deleted`, 'success')
      setDeleting(false)
      closeDeletePrompt()
      loadDrafts()
    }

    setDeleting(true)

    if (deletePrompt.type === 'single') {
      await deleteOrder(deletePrompt.orders[0])
      onComplete(deletePrompt.type)
    } else {
      let i = 0
      while (i < deletePrompt.orders.length) {
        await deleteOrder(deletePrompt.orders[i])
        i++
      }
      onComplete(deletePrompt.type)
    }
  }

  const openDeletePrompt = order_id => {
    if (order_id) {
      setDeletePrompt({
        isOpen: true,
        type: 'single',
        orders: [order_id]
      })
    } else {
      const orders = Object.entries(checkedList)
        .filter(([key, value]) => !!value)
        .map(([key, value]) => key)

      setDeletePrompt({
        isOpen: true,
        type: 'multiple',
        orders
      })
    }
  }

  const closeDeletePrompt = () => {
    setDeletePrompt({
      isOpen: false,
      type: '',
      orders: []
    })
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title='Draft Orders'>
        <Search
          value={searchValue}
          multiple={true}
          searchBy={searchBy}
          searchOptions={searchOptions}
          onSearchOptionChange={option => setSearchBy(option)}
          inputPlaceHolder={searchInputPlaceHolder}
          handleSearch={onSearchChange}
          allowServerSearch={false}
        />
      </Page.Header>
      <Page.Body>
        {editModal.isOpen && (
          <OrderDraftModal
            isOpen={editModal.isOpen}
            onClose={handleCloseModal}
            id={editModal.order_id}
          />
        )}
        {deletePrompt.isOpen && (
          <ConfirmDelete
            isOpen={deletePrompt.isOpen}
            text={`Are you sure you want to delete ${
              deletePrompt.type === 'single'
                ? 'this draft?'
                : 'the selected drafts?'
            }`}
            onConfirm={handleDelete}
            onCancel={closeDeletePrompt}
            isDeleting={isDeleting}
          />
        )}
        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <div className='text-sm'>
              {selectedDrafts.length ? (
                <div className='flex gap-2 items-center'>
                  <p>Selected {selectedDrafts.length} items</p>•
                  <button
                    className='btn-icon text-error hover:bg-error/20 hover:text-error'
                    onClick={openDeletePrompt}
                  >
                    <AiOutlineDelete size={16} />
                  </button>
                </div>
              ) : (
                <p>Pick up from where you left off</p>
              )}
            </div>
            <div className='flex items-center gap-3 ml-auto'>
              <Pagination
                tableId='draft-orders-table'
                totalCount={draftsStore.data?.length}
                data={draftsStore.data}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={draftsStore.meta?.page}
                pageSize={draftsStore.meta?.page_size}
              />
            </div>
          </div>
        </div>
        {!drafts ? (
          <Loader />
        ) : (
          <div>
            <div className='data-table-wrapper'>
              <table id='draft-orders-table' className='table--hover'>
                <thead>
                  <tr>
                    <th style={{ width: '5%' }}>
                      {selectedAll ? (
                        <Checkbox
                          checked={selectedAll}
                          onChange={toggleSelectAll}
                        />
                      ) : selectedDrafts.length ? (
                        <Unselect onClick={deSelectAll} />
                      ) : (
                        <Checkbox
                          checked={selectedAll}
                          onChange={toggleSelectAll}
                        />
                      )}
                    </th>
                    <th style={{ width: '7%' }}>ID</th>
                    <th style={{ width: '58%' }}>Details</th>
                    <th style={{ width: '20%' }}>Last Updated</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {drafts.map((order, idx) => (
                    <tr key={idx}>
                      <td>
                        <div>
                          <Checkbox
                            checked={!!checkedList[order.id]}
                            onChange={() => toggleItemCheck(order.id)}
                          />
                        </div>
                      </td>
                      <td>#{order.id}</td>
                      <td onClick={() => handleOrderClick(order.id)}>
                        <div className=''>
                          <p className='flex gap-1 line-clamp-1 truncate'>
                            {order.tpl_service && (
                              <>
                                <span>{order.tpl_service}</span> ·{' '}
                              </>
                            )}
                            {order.weight && (
                              <>
                                <span>
                                  Weight:{' '}
                                  {order.weight ? `${order.weight}kg` : 'NA'}{' '}
                                </span>
                                ·{' '}
                              </>
                            )}
                            <span>
                              Origin:{' '}
                              {order?.sender
                                ? `${
                                    order?.sender?.address?.state_name ||
                                    getStateOfCountry(
                                      order?.sender?.address
                                        ?.state_or_province_code,
                                      order?.sender?.address?.country
                                    )?.name ||
                                    order?.sender?.address
                                      ?.state_or_province_code
                                  }, ${order?.sender?.address?.country} `
                                : 'NA'}{' '}
                            </span>
                            ·{' '}
                            <span>
                              Destination:{' '}
                              {order?.receiver
                                ? `${
                                    order?.receiver?.address?.state_name ||
                                    getStateOfCountry(
                                      order?.receiver?.address
                                        ?.state_or_province_code,
                                      order?.receiver?.address?.country
                                    )?.name ||
                                    order?.receiver?.address
                                      ?.state_or_province_code
                                  }, ${order?.receiver?.address?.country} `
                                : 'NA'}{' '}
                            </span>
                          </p>
                        </div>
                      </td>
                      <td>{getDate(order.order_date)}</td>
                      <td>
                        <div className='flex items-center w-full justify-center'>
                          <button
                            className='btn-icon text-main-primary-2 hover:bg-main-hover hover:text-main-primary-2'
                            onClick={e => {
                              e.stopPropagation()
                              handleEdit(order.id)
                            }}
                          >
                            <HiOutlinePencil size={16} />
                          </button>
                          <button
                            className='btn-icon text-error hover:bg-error/20 hover:text-error'
                            onClick={e => {
                              e.stopPropagation()
                              openDeletePrompt(order.id)
                            }}
                          >
                            <AiOutlineDelete size={16} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Page.Body>
    </Page>
  )
}
