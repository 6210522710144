import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MoreIconDropdown } from '../../components/manifests/components/MoreIconsDropdown'
import { IoMdAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as User } from '../../assets/assign-user.svg'
import * as XLSX from 'xlsx'
import { fetchSingleManifest } from '../../slices/singleManifestSlice'
import { getDate } from '../../utils'
import noDataIcon from '../../assets/no-data.webp'
import ManifestTabs from '../../components/manifests/components/ManifestTabs'
import Loader from '../../components/loader/Loader'
import { MdOutlineFileDownload } from 'react-icons/md'
import { fetchHubs } from '../../slices/hubsSlice'
import { fetchRiders } from '../../slices/ridersSlice'
import { emptyBags } from '../../slices/bagsSlice'
import Page from '../../containers/Page'
import SearchManifest from '../../components/manifests/components/SearchManifest'
import { ManifestFilterModal } from '../../components/manifests/components/ManifestFilterModal'
import { capitalizeFirstLetter } from '../../utils'
import { fetchManifest } from '../../slices/manifestSlice'
import ManifestPagination from '../../components/manifests/components/ManifestPagination'

const TransferManifest = ({ metaTitle }) => {
  const dispatch = useDispatch()
  const allData = useSelector(
    state => state.manifest.dataManifest?.transfer_manifest
  )
  const navigate = useNavigate()
  const [manifestId, setManifestId] = useState('')
  const [activeTab, setActiveTab] = useState('Transfer')
  const [data, setData] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [destination, setDestination] = useState('')
  const [page_size, setPageSize] = useState(50)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if ((allData?.length !== 0) & (searchVal === '')) {
      setData(allData)
    }
  }, [allData, searchVal])

  const handleSearch = value => {
    if (value?.length !== 0) {
      const searchData = data?.filter(item =>
        item?.tracking_id?.includes(value)
      )
      setSearchVal(value)
      setData(searchData)
    } else {
      setData(allData)
      setSearchVal('')
    }
  }

  const handleFilter = () => {
    if (destination !== '') {
      const searchData = allData?.filter(
        item => item?.destination_hub_name === destination?.toLowerCase()
      )

      setSearchVal(destination)
      setData(searchData)
    } else {
      setData(allData)
      setSearchVal(destination)
    }
  }

  const exportManifest = () => {
    // create header

    const header = [
      'S/N',
      'Manifest ID',
      'Date',
      'Origin',
      'Destination',
      'Quantity',
      'Dispatcher',
      'Status'
    ]

    // create rows
    const rows = allData.map((manifest, id) => {
      let sn = id + 1
      return {
        'S/N': sn,
        'Manifest ID': manifest.tracking_id,
        Date: getDate(manifest.created_at),
        Origin: manifest.originating_hub_name,
        Destination: manifest.destination_hub_name,
        Quantity: manifest.meta.total_number_of_bags,
        Dispatcher: 'Sunday Monday',
        Status: manifest.originating_hub_name
      }
    })

    // generate worksheet and workbook
    const sheet = XLSX.utils.json_to_sheet(rows)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, sheet, 'Manifest')

    // fix headers
    XLSX.utils.sheet_add_aoa(sheet, [header], { origin: 'A1' })

    // column width
    const max_width = rows.reduce((w, r) => Math.max(w, r['Sender']?.length))

    sheet['!cols'] = [{ wch: max_width }]

    // create csv file
    XLSX.writeFile(workbook, `manifest.xlsx`, {
      compression: true
    })
  }

  const gotoManifest = type => {
    navigate(`/manifest/${type}`)
  }

  const createDSManifest = () => {
    navigate(`/manifest/create-manifest/`, {
      state: { mType: 'TM' }
    })
  }

  const gotoIndividualManifest = id => {
    navigate(`/manifest/single-transfer-manifest/${id}`, {
      state: { id }
    })
  }

  useEffect(() => {
    dispatch(fetchHubs())
    dispatch(fetchRiders())
    dispatch(emptyBags())
    dispatch(fetchManifest({ page_size, page }))
  }, [dispatch, page_size, page])
  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Manifests'}>
        <SearchManifest
          handleSearch={handleSearch}
          setOpenModal={setOpenModal}
        />
      </Page.Header>
      <Page.Body>
        <div className='relative w-full pb-20'>
          <div className='w-full flex justify-between items-center mt-8'>
            <div className='mt-0'>
              <div className='relative flex flex-row items-end gap-4'>
                <ManifestTabs activeTab={activeTab} />
              </div>
            </div>
            <div className='flex justify-around items-center space-x-8'>
              <button
                className='btn bg-g-400 text-dark-primary'
                onClick={exportManifest}
                disabled={!data?.length}
              >
                <MdOutlineFileDownload
                  size={18}
                  color='#333333'
                  className='mr-1'
                />
                Export
              </button>

              <button
                className='btn btn-primary text-white'
                onClick={() => createDSManifest()}
              >
                <IoMdAdd size={18} color='#ffffff' className='mr-1' />
                Create Manifest
              </button>
            </div>
          </div>

          <div className='flex justify-between items-center mt-8'>
            {searchVal?.length !== 0 && (
              <>
                Showing results for <b>{searchVal}</b>
              </>
            )}
            <div className='flex ml-auto py-4 px-2'>
              <ManifestPagination
                tableId='manifest-table'
                data={data}
                pageSize={page_size}
                totalCount={data?.length}
                page={page}
                setPage={setPage}
                setPageSize={setPageSize}
              />
            </div>
          </div>
          {data?.length === 0 ? (
            <div className='w-full flex justify-center items-center'>
              <div className='pt-14 w-full flex flex-col items-center justify-center'>
                <img
                  src={noDataIcon}
                  className='w-40 h-40 object-contain'
                  alt='no data icon'
                />
                <p className='text-gray-500 text-left my-2 font-semibold text-base md:text-xl'>
                  No manifest found
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className='w-full overflow-x-auto rounded-lg border border-[#EFEFEF] pb-48'>
                <table className='table--hover'>
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Manifest ID</th>
                      <th>Date </th>
                      <th>Origin</th>
                      <th>Transiting Hub</th>
                      <th>Bags</th>
                      <th>Total declared value</th>
                      <th>Weight</th>
                      {/* <th>Dispatcher</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((transfer, id) => (
                      <tr key={id}>
                        <td className='cursor-pointer'>{id + 1}</td>
                        <td
                          className='cursor-pointer'
                          onClick={() => {
                            gotoIndividualManifest(transfer.id)
                            dispatch(fetchSingleManifest(`TM/${transfer?.id}`))
                          }}
                        >
                          {transfer.tracking_id?.toUpperCase()}
                        </td>

                        <td
                          className='cursor-pointer'
                          onClick={() => {
                            gotoIndividualManifest(transfer.id)
                            dispatch(fetchSingleManifest(`TM/${transfer?.id}`))
                          }}
                        >
                          {getDate(transfer.created_at)}
                        </td>
                        <td
                          className='cursor-pointer'
                          onClick={() => {
                            gotoIndividualManifest(transfer.id)
                            dispatch(fetchSingleManifest(`TM/${transfer?.id}`))
                          }}
                        >
                          {capitalizeFirstLetter(
                            transfer?.originating_hub_name
                          )}
                        </td>
                        <td
                          className='cursor-pointer'
                          onClick={() => {
                            gotoIndividualManifest(transfer.id)
                            dispatch(fetchSingleManifest(`TM/${transfer?.id}`))
                          }}
                        >
                          {capitalizeFirstLetter(
                            transfer?.destination_hub_name
                          )}
                        </td>

                        <td
                          className='cursor-pointer'
                          onClick={() => {
                            gotoIndividualManifest(transfer.id)
                            dispatch(fetchSingleManifest(`TM/${transfer?.id}`))
                          }}
                        >
                          {transfer?.meta?.total_number_of_bags}
                        </td>
                        <td>
                          {transfer?.total_shipments_declared_value?.toLocaleString(
                            'en-NG',
                            {
                              style: 'currency',
                              currency: 'NGN'
                            }
                          )}
                        </td>
                        <td>{transfer?.total_shipments_weight}</td>
                        {/* <td
                          className='cursor-pointer'
                          onClick={() => {
                            gotoIndividualManifest(transfer.id)
                            dispatch(fetchSingleManifest(`TM/${transfer?.id}`))
                          }}
                        >
                          Sunday Monday
                        </td> */}
                        <td
                          className='cursor-pointer'
                          onClick={() => {
                            gotoIndividualManifest(transfer.id)
                            dispatch(fetchSingleManifest(`TM/${transfer?.id}`))
                          }}
                        >
                          {transfer.scan_events}
                        </td>
                        {!transfer?.assigned_to ? (
                          <td
                            onClick={() => {
                              setManifestId(transfer.id)
                            }}
                          >
                            <div>
                              <MoreIconDropdown
                                sn={id + 1}
                                id={id}
                                manifestId={manifestId}
                                component={true}
                                mType='TM'
                              />
                            </div>
                          </td>
                        ) : (
                          <td>
                            <User />
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
        <ManifestFilterModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          setDestination={setDestination}
          destination={destination}
          handleFilter={handleFilter}
          setSearchVal={setSearchVal}
        />
      </Page.Body>
    </Page>
  )
}

export default TransferManifest
