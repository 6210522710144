import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getDate } from '../../utils'
import ROUTES from '../../constants/routes'
import { statuses } from '../../fixtures/invoiceStatus'
import Pill from '../globals/Pill'

const Invoicelist = ({ item }) => {
  const navigate = useNavigate()

  return (
    <tr onClick={() => navigate(`${ROUTES.INVOICES.path}/${item.id}`)}>
      <td>{item.s_n}</td>
      <td>#{item.id}</td>
      <td>
        <Link
          to={`${ROUTES.ORDERS.path}?id=${item.order}`}
          className='hover:underline hover:text-primary'
          onClick={e => e.stopPropagation()}
        >
          #{item.order}
        </Link>
      </td>
      <td>{getDate(item.date_raised)}</td>
      <td>
        {item.meta.tpl_service === 'FIE'
          ? 'Economy by FEDEX'
          : item.meta.tpl_service === 'FPE'
          ? 'Priority by FEDEX'
          : item.meta.tpl_service}
      </td>
      <td>{item.weight} kg</td>
      <td>{item.customer_name}</td>
      <td>
        {item.total?.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN'
        })}
      </td>
      <td>{item.branch_name}</td>
      <td>
        <div className='flex flex-row items-center'>
          {item.status !== 2 && (
            <Pill
              name={statuses[item.status]?.name}
              theme={statuses[item.status]?.theme}
            />
          )}
        </div>
      </td>
    </tr>
  )
}

export default Invoicelist
