import AppFormField from './AppFormField'
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { getCompleteLocation } from '../../../utils/location'
import { useFormikContext } from 'formik'
import { useMemo } from 'react'

export default function AutoCompleteAddressField ({ onComplete, ...restProps }) {
  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API
  })

  const { setFieldValue } = useFormikContext()

  const handleOnChange = value => {
    getPlacePredictions({ input: value })
  }

  const suggestions = useMemo(() => {
    if (placePredictions) {
      return placePredictions.map(place => ({
        description: place.description,
        value: place.structured_formatting.main_text
      }))
    } else return null
  }, [placePredictions])

  const handleSelectSuggestion = item => {
    setFieldValue('address', item.value)
    getCompleteLocation(item.description, onComplete)
    onCloseSuggestions()
  }

  const onCloseSuggestions = () => {
    getPlacePredictions({ input: '' })
  }

  return (
    <AppFormField
      type='text'
      handleOnChange={handleOnChange}
      withSuggestions
      suggestions={suggestions}
      onSelectSuggestion={handleSelectSuggestion}
      onCloseSuggestions={onCloseSuggestions}
      {...restProps}
    />
  )
}
