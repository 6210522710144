import Modal from 'react-modal'
import { ReactComponent as Times } from '../../../assets/times.svg'
import { Formik } from 'formik'
import * as yup from 'yup'
import OrderItems from '../OrderItems'
import ordersApi from '../../../api/orders'
import { IS_ALL_NUMBERS, parseError } from '../../../utils'
import useToast from '../../../hooks/useToast'
import { CircularProgress } from '@mui/material'
import { useRef } from 'react'
const styles = {
  content: {
    borderRadius: '20px',
    maxWidth: '700px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}
export default function EditItems ({
  isOpen,
  onClose,
  items,
  shipmentType,
  courier,
  packageType,
  orderId,
  totalWeight,
  declaredValue
}) {
  const toast = useToast()
  const handleOnSubmit = async (body, actions) => {
    const payload = {
      packages: {
        items: body.items
      }
    }
    const response = await ordersApi.editItems(orderId, payload)
    actions.setSubmitting(false)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        if (response.status.code === 400) {
          toast('Cannot edit processed order', 'error')
        } else {
          toast('Error editting items in order', 'error')
        }
      }
      return
    }
    toast('Editted items successfully')
    onClose(true)
  }
  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape({
        items: yup.array().of(
          yup.object().shape(() => {
            const config = {
              name: yup.string().required('Item description is required'),
              unitMeasurement: yup.string().required('Unit is required'),
              quantity: yup.number().required('Quantity is required'),
              price: yup
                .number()
                .required('Price is required')
                .test(
                  'is-less-declared-value',
                  'Total items value must not exceed declared value',
                  function (value) {
                    const totalItemsValue = values.items.reduce(
                      (acc, curr) =>
                        typeof curr.price === 'number' &&
                        typeof curr.quantity === 'number'
                          ? acc + Number(curr.price) * Number(curr.quantity)
                          : acc,
                      0
                    )

                    return totalItemsValue <= declaredValue
                  }
                )
            }
            if (shipmentType === 'IN') {
              config.weight = yup
                .number()
                .required('Weight is required')
                .test(
                  'is-less-package-weight',
                  'Total items weight must not exceed total packages weight',
                  function (value) {
                    const itemsWeight = values.items.reduce(
                      (acc, curr) =>
                        typeof curr.weight === 'number' &&
                        typeof curr.quantity === 'number'
                          ? acc + Number(curr.weight) * Number(curr.quantity)
                          : acc,
                      0
                    )

                    return itemsWeight <= totalWeight
                  }
                )
              config.manufacturerCountry = yup
                .string()
                .required('Manufacturer country is required')
              config.hsCode = yup
                .string()
                .required('HS Code is required')
                .test('is-number', 'HS Code must be number', function (value) {
                  if (value) {
                    return IS_ALL_NUMBERS.test(value)
                  }
                  return true
                })
              if (courier === 'UPS') {
                config.name = yup
                  .string()
                  .required('Item description is required')
                  .max(
                    35,
                    'Item description must not exceed 35 charaters for UPS'
                  )
              }
            }
            return config
          })
        )
      })
    )
  const container = useRef(null)
  return (
    <Modal style={styles} isOpen={isOpen} ref={container}>
      <div>
        <div
          className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 cursor-pointer ml-auto'
          onClick={onClose}
        >
          <Times />
        </div>
        <div>
          <h3 className='text-lg text-dark-primary font-medium text-center mb-3'>
            Edit Items
          </h3>
          <div className='flex items-center gap-2 text-[15px] mb-2 w-full'>
            <span>
              Declared value:{' '}
              {declaredValue.toLocaleString('en-NG', {
                style: 'currency',
                currency: 'NGN'
              })}
            </span>
            •<span>Packages weight: {totalWeight.toLocaleString()}kg</span>
          </div>
          <Formik
            initialValues={{ items }}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
          >
            {formik => {
              return (
                <form onSubmit={formik.handleSubmit} id='edit-items-form'>
                  <OrderItems
                    container={container}
                    shipmentType={shipmentType}
                    packageType={packageType}
                    courier={courier}
                    defaultItemsOpen={false}
                  />
                  <div className='p-2 md:p-4 w-full'>
                    <button
                      className='btn btn-primary w-full text-white'
                      type='submit'
                      form='edit-items-form'
                    >
                      {formik.isSubmitting ? (
                        <CircularProgress size={28} color='inherit' />
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </form>
              )
            }}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
