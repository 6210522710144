import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import AuthLayout from '../containers/AuthLayout'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function ProtectedRoute ({ children }) {
  const { isLoggedIn } = useSelector(state => state.auth)

  const { pathname, search } = useLocation()
  const redirect = encodeURIComponent(`${pathname}${search}`)

  if (!isLoggedIn)
    return (
      <Navigate
        replace
        to={{ pathname: '/login', search: `?next=${redirect}` }}
      />
    )

  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  )
}
