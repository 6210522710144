import { CircularProgress } from "@mui/material";

const StateButton = ({
  bgColor,
  color,
  size,
  text,
  borderRadius,
  icon,
  loading,
  handlePublish,
}) => {
  return (
    <div
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={`px-5 text-${size} py-3 hover:drop-shadow-xl space-x-2 flex items-center justify-center cursor-pointer`}
      onClick={handlePublish}
    >
      {icon}{" "}
      <p className="font-semibold">
        {text} {loading && <CircularProgress size={13} color="inherit" />}
      </p>
    </div>
  );
};

export default StateButton;
