import DoorManifestPrintSheet from '../../components/manifests/DoorManifestPrintSheet'
import Page from '../../containers/Page'

export default function DoorManifestSheet ({ metaTitle }) {
  return (
    <Page metaTitle={metaTitle}>
      <Page.Body>
        <DoorManifestPrintSheet />
      </Page.Body>
    </Page>
  )
}
