import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: '',
  trialCount: 0
}

const shipmentSocketSlice = createSlice({
  name: 'shipmentSocket',
  initialState,
  reducers: {
    connect (state) {
      state.status = 'connecting';
      state.trialCount = state.trialCount + 1
    },
    disconnect (state) {
      state.status = 'disconnecting'
    },
    connected (state) {
      state.status = 'connected'
    },
    disconnected (state) {
      state.status = 'disconnected'
    },
    message (state, { payload }) {
      state.message = payload
    },
    cleanup (state) {
      state.message = null
    }
  }
})

export default shipmentSocketSlice.reducer

export const shipmentSocketActions = shipmentSocketSlice.actions
