import { Navigate, useSearchParams } from 'react-router-dom'
import ROUTES from '../constants/routes'
import { useSelector } from 'react-redux'

export default function IsUserRedirect ({ children }) {
  const { isLoggedIn } = useSelector(state => state.auth)

  const [searchParams] = useSearchParams()
  const next = searchParams.get('next')

  if (isLoggedIn) return <Navigate replace to={next || ROUTES.DASHBOARD.path} />

  return children
}
