import { createSlice } from '@reduxjs/toolkit'
import authApi from '../api/auth'
import cookies from '../utils/cookies'
import store from '../store'
import { setAuthCredsToCookies } from '../utils'

const handleRefreshToken = async () => {
  store.dispatch(
    authActions.updateAuthCreds({
      isLoading: true,
      isSuccess: false,
      isError: false
    })
  )
  const response = await authApi.refreshToken()

  if (!response.ok) {
    store.dispatch(
      authActions.updateAuthCreds({
        isLoading: false,
        isSuccess: false,
        isError: true
      })
    )
    store.dispatch(authActions.logout())
    return
  }

  store.dispatch(
    authActions.updateAuthCreds({
      isLoading: false,
      isSuccess: true,
      isError: false
    })
  )
  setAuthCredsToCookies(response.data)
  handleSessionTimeout()
}

const handleSessionTimeout = () => {
  const expireAt = cookies.get('expireAt')

  const now = new Date().getTime()
  const session = expireAt - now

  if (session > 1000) {
    setTimeout(() => {
      handleRefreshToken()
    }, session)
  }
}

const isTokenValid = () => {
  const token = cookies.get('authToken')
  if (token) {
    handleSessionTimeout()
    return true
  }

  cookies.remove('user')
  cookies.remove('accessToken')
  cookies.remove('refreshToken')
  cookies.remove('expireAt')

  return false
}

const initialState = {
  isLoggedIn: isTokenValid(),
  user: cookies.get('userData'),
  authCreds: {
    isLoading: false,
    isSuccess: false,
    isError: false
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login (state) {
      state.isLoggedIn = true
      handleSessionTimeout()
    },
    setUser (state, action) {
      cookies.set('userData', JSON.stringify(action.payload))
      state.user = action.payload
    },
    logout (state) {
      const logoutFromServer = async () => {
        state.authCreds.isLoading = true
        await authApi.logout()
        cookies.remove('accessToken')
        cookies.remove('authToken')
        cookies.remove('refreshToken')
        cookies.remove('expireAt')
        cookies.remove('userData')

        window.location.reload()
        // state.isLoggedIn = false
        // state.user = null
        // state.authCreds.isLoading = false
      }
      logoutFromServer()
    },
    updateAuthCreds (state, action) {
      state.authCreds = action.payload
    },
    cleanupAuthCreds (state) {
      state.authCreds = {
        isLoading: false,
        isSuccess: false,
        isError: false
      }
    }
  }
})

export const authActions = authSlice.actions

export default authSlice.reducer
