export const statuses = {
  0: {
    name: 'Pending',
    theme: {
      primary: '#BDBDBD',
      secondary: '#F2F2F2'
    },
    allowUpdate: false,
  },
  1: {
    name: 'Received',
    theme: {
      primary: '#FFC700',
      secondary: '#FFF6D799'
    },
    allowUpdate: false
  },
  2: {
    name: 'In Transit',
    theme: {
      primary: '#51A2FE',
      secondary: '#51A2FE1F'
    },
    allowUpdate: false
  },
  3: {
    name: 'Out For Delivery',
    theme: {
      primary: '#bf55ec',
      secondary: '#bf55ec33'
    },
    allowUpdate: true
  },
  4: {
    name: 'Delivered',
    theme: {
      primary: '#61A966',
      secondary: '#D8F7DA99'
    },
    allowUpdate: true
  },
  5: {
    name: 'Exception',
    theme: {
      primary: '#999999',
      secondary: '#E5E5E599'
    },
    allowUpdate: false
  },
  6: {
    name: 'Available for Pickup',
    theme: {
      primary: '#61A966',
      secondary: '#D8F7DA99'
    },
    allowUpdate: true
  },
  7: {
    name: 'Voided',
    theme: {
      primary: '#DE350B',
      secondary: '#F5CCC299'
    },
    allowUpdate: false
  }
}
