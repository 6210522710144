import InternationalManifestPrintSheet from '../../components/manifests/InternationalManifestPrintSheet'
import Page from '../../containers/Page'

export default function InternationalManifestSheet ({ metaTitle }) {
  return (
    <Page metaTitle={metaTitle}>
      <Page.Body>
        <InternationalManifestPrintSheet />
      </Page.Body>
    </Page>
  )
}
