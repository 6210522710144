import { Link } from 'react-router-dom'
import { useMemo } from 'react'
import ROUTES from '../../constants/routes'
function FleetCard ({ title, statuses }) {
  return (
    <div className='py-4 px-4 shadow-md-right bg-white rounded-xl h-full'>
      <div>
        <h5 className='font-semibold text-base mb-6'>{title}</h5>
      </div>
      <div className='flex flex-col items-start w-full gap-10 text-sm text-black'>
        {statuses.map((status, id) => (
          <div
            key={id}
            className='py-1.5 flex items-center justify-between w-full border-b border-g-400'
          >
            <Link
              to={`${ROUTES.ORDERS.path}?order_state=${status.order_state}`}
              className='opacity-80 hover:underline hover:text-primary'
            >
              {status.name}
            </Link>
            <span className='font-medium text-main-primary'>
              {status.value.toLocaleString()}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FleetCard
