import hmsClient from "./clients/hmsClient";
import tmsClient from "./clients/tmsClient";
import bookingClient from "./clients/bookingClient";

const endpoint = "manifests/";

const getAllManifest = async (params) => {
  const response = await hmsClient.get(
    `${endpoint}?page=${params.page}&page_size=${params.page_size}`
  );
  return response;
};

const getRiders = async () => {
  const response = await tmsClient.get(`riders/`);
  return response;
};

const sendException = async (body) => {
  const response = await hmsClient.post(`scan/scan_manifest/`, body);
  return response;
};

const sendShipmentException = async (body) => {
  const response = await hmsClient.post(`scan/scan_shipment/`, body);
  return response;
};

const getManifest = async (data) => {
  const response = await hmsClient.get(`${endpoint}${data}`);
  return response;
};

const publishManifest = async (data) => {
  const response = await hmsClient.patch(`${endpoint}${data}/publish/`);
  return response;
};

const triggerCreateManifest = async (body) => {
  const response = await bookingClient.post(
    "shipments/create_centre_manifest/",
    body
  );
  return response;
};

const triggerCreateInternationalManifest = async (body) => {
  const response = await bookingClient.post(
    "shipments/create_centre_manifest/",
    body
  );
  return response;
};

const triggerRedstarExpress = async (body) => {
  const response = await bookingClient.post(
    "shipments/create_rse_centre_manifest/",
    body
  );
  return response;
};

const addShioment = async (body) => {
  const response = await hmsClient.post(`manifests/add_shipments/`, body);
  return response;
};

const removeShioment = async (body) => {
  const response = await hmsClient.post(`manifests/remove_shipments/`, body);
  return response;
};

const getAllBags = async (data) => {
  const response = await hmsClient.get(`${data}`);
  return response;
};

const getAllClosedBags = async () => {
  const response = await hmsClient.get(`bags/get_closed_bags/`);
  return response;
};

const getExceptions = async () => {
  const response = await hmsClient.get("scan/exceptions/");
  return response;
};

const createManifest = async (body) => {
  const response = await hmsClient.post(endpoint, body);
  return response;
};

const assignManifest = async (data, body) => {
  const response = await hmsClient.patch(
    `${endpoint}${data}/assign_manifest/`,
    body
  );
  return response;
};

const getHubs = async () => {
  const response = await hmsClient.get(`hubs/`);
  return response;
};

const createDoorStep = async (body) => {
  const response = await bookingClient.post(
    `/shipments/build_delivery_packages/`,
    body
  );
  return response;
};

const getAllShipments = async (params) => {
  const response = await bookingClient.get(
    `shipments/?page_size=${params.page_size}&branch=${params.branch}&order_type=${params.order_type}&status=${params.status}&in_manifest=False&page=${params.page}&tpl_service=${params.tpl}`
  );
  return response;
};

const bulkTracking = async (param) => {
  const response = await hmsClient.get(`${endpoint}${param}/track/`);
  return response;
};

const removeShipmentFromBag = async (body, params) => {
  const response = await hmsClient.patch(
    `bags/${params}/remove_shipments/`,
    body
  );
  return response;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllManifest,
  getManifest,
  createManifest,
  getHubs,
  assignManifest,
  getRiders,
  getAllBags,
  createDoorStep,
  getExceptions,
  sendException,
  sendShipmentException,
  addShioment,
  removeShioment,
  triggerCreateManifest,
  triggerRedstarExpress,
  publishManifest,
  getAllClosedBags,
  getAllShipments,
  bulkTracking,
  triggerCreateInternationalManifest,
  removeShipmentFromBag,
};
