import { useEffect } from 'react'

export default function useOnClickOutside (ref, isVisible, handler) {
  useEffect(() => {
    const listener = event => {
      const el = ref?.current
      if (!isVisible) return
      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target)) {
        return
      }

      handler(event)
    }

    document.addEventListener(`mousedown`, listener)

    return () => {
      document.removeEventListener(`mousedown`, listener)
    }

    // Reload only if ref or handler changes
  }, [ref, handler, isVisible])
}