import { useContext } from 'react'
import AccountingDashboard from './AccountingDashboard'
import AreaDashboard from './AreaDashboard'
import BookingAgentDashboard from './BookingAgentDashboard'
import CeoDashboard from './CeoDashboard'
import CustomerSupportDashboard from './CustomerSupportDashboard'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import CooDashboard from './CooDashboard'

const roleDashboardMapping = {
  1: CooDashboard,
  2: AreaDashboard,
  3: AreaDashboard,
  6: CustomerSupportDashboard,
  8: BookingAgentDashboard,
  12: AccountingDashboard,
  13: AccountingDashboard,
  15: CeoDashboard
}

export default function Dashboard ({ metaTitle }) {
  const { userRole } = useContext(AuthLayoutContext)

  const isNotBranchSpecific = userRole?.domain.index > 0

  let DashboardComponent = roleDashboardMapping[userRole?.id]

  // If no role-specific dashboard is found, use CustomerSupportDashboard if not branch-specific
  if (!DashboardComponent) {
    if (isNotBranchSpecific) {
      DashboardComponent = CustomerSupportDashboard
    } else {
      DashboardComponent = BookingAgentDashboard
    }
  }

  return <DashboardComponent metaTitle={metaTitle} />
}
