const formatAddress = (address_components) => {
  const result = address_components?.reduce((acc, curr) => {
    const types = [
      { key: "country", typeValue: "country" },
      { key: "state", typeValue: "administrative_area_level_1" },
      {
        key: "city",
        typeValue: "locality",
      },
      { key: "postal_code", typeValue: "postal_code" },
    ];
    const validType = types.find(
      ({ typeValue }) => typeValue === curr.types[0]
    );

    if (validType) {
      return {
        ...acc,
        [validType.key]: {
          long_name: curr.long_name,
          short_name: curr.short_name,
        },
      };
    }

    return acc;
  }, {});

  return result;
};

const getLocation = (position, callback) => {
  fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`
  )
    .then((res) => res.json())
    .then((res) => {
      if (res.results[0]) {
        callback(formatAddress(res.results[0].address_components));
      }
    });
};

export const resolveLocation = (callback) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) =>
      getLocation(position, callback)
    );
  } else {
    alert("Geolocation is not supported by this browser.");
  }
};

export const getCompleteLocation = (address, callback) => {
  fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`
  )
    .then((res) => res.json())
    .then((res) => {
      if (res.status === "OK") {
        callback(formatAddress(res.results[0].address_components));
      }
    });
};
