import React, { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthLayoutContext } from '../../../containers/AuthLayout'
import ROLES from '../../../constants/roles'

export default function ManifestTabs ({ activeTab }) {
  const navigate = useNavigate()

  const { userRole } = useContext(AuthLayoutContext)

  const tabs = useMemo(() => {
    const allTabs = [
      {
        id: 'center',
        name: 'Center',
        type: 'cm'
      },
      {
        id: 'transfer',
        name: 'Transfer',
        type: 'tm'
      },
      {
        id: 'doorstep',
        name: 'DoorStep',
        type: 'ds'
      },
      {
        id: 'intl',
        name: 'International',
        type: 'im'
      }
    ]

    if (userRole) {
      const activeTabs = allTabs.filter(
        tab => ROLES[userRole.id].permissions.manifest[tab.id]
      )
      return activeTabs
    }
  }, [userRole])

  const gotoManifest = type => {
    navigate(`/manifest/${type}`)
  }

  return (
    <div className='relative flex flex-row items-end gap-4'>
      {tabs?.map((item, id) => (
        <button
          tabs
          className={`text-base border-b p-1 ${
            activeTab === item.name
              ? 'font-semibold border-primary bg-main-primary bg-opacity-5 rounded-t-lg'
              : 'border-transparent bg-transparent opacity-60 rounded-t-none hover:opacity-80'
          }`}
          onClick={() => {
            gotoManifest(item.type)
          }}
          key={id}
        >
          {item.name}
        </button>
      ))}
    </div>
  )
}
