export const statuses = {
  1: {
    name: 'Unprocessed',
    theme: {
      primary: '#BDBDBD',
      secondary: '#F2F2F2'
    }
  },
  2: {
    name: 'Processed',
    theme: {
      primary: '#FFC700',
      secondary: '#FFF6D799'
    }
  },
  3: {
    name: 'Completed',
    theme: {
      primary: '#61A966',
      secondary: '#D8F7DA99'
    }
  },
  4: {
    name: 'Voided',
    theme: {
      primary: '#DE350B',
      secondary: '#F5CCC299'
    }
  },
  // 5: {
  //   name: 'Incomplete',
  //   theme: {
  //     primary: '#BDBDBD',
  //     secondary: '#F2F2F2'
  //   }
  // },
  6: {
    name: 'Reprocessed',
    theme: {
      primary: '#FFC700',
      secondary: '#FFF6D799'
    }
  }
}
