import { Switch } from '@headlessui/react'

export default function ToggleSwitch ({ enabled, onChange, name }) {
  return (
    <Switch
      name={name}
      checked={enabled}
      onChange={onChange}
      value='enabled'
      className={`${enabled ? 'bg-main-primary' : 'bg-accent'}
relative inline-flex h-[25.5px] w-[49px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
    >
      <span className='sr-only'>Use setting</span>
      <span
        aria-hidden='true'
        className={`${enabled ? 'translate-x-6' : 'translate-x-0'}
pointer-events-none inline-block size-[22px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  )
}
