export const getYesterday = () => {
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  date.setDate(date.getDate() - 1)
  return date
}

export const getLast7Days = () => {
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  date.setDate(date.getDate() - 7)
  return date
}

export const getLast30Days = () => {
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  date.setDate(date.getDate() - 30)
  return date
}

export const resolveDate = (dateString = new Date()) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const formatNumbering = num => {
    return num < 10 ? `0${num}` : num
  }

  const formattedDate = `${formatNumbering(day)}-${formatNumbering(
    month
  )}-${year}`

  return formattedDate
}

export const resolveDateParams = (date_value, range) => {
  switch (date_value) {
    case 'today':
      return {
        start_date: resolveDate()
      }
    case 'yesterday':
      return {
        start_date: resolveDate(getYesterday()),
        end_date: resolveDate()
      }
    case 'last_7_days':
      return {
        start_date: resolveDate(getLast7Days()),
        end_date: resolveDate()
      }
    case 'last_30_days':
      return {
        start_date: resolveDate(getLast30Days()),
        end_date: resolveDate()
      }
    case 'range':
      return {
        start_date: resolveDate(range[0].format()),
        end_date: resolveDate(range[1].format())
      }
    default:
      return {}
  }
}

export const queryByDate = (refDate, query, range = [{}, {}]) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const queryFuncs = {
    today () {
      return today.getTime() <= refDate.getTime()
    },
    yesterday () {
      return (
        getYesterday(today).getTime() <= refDate.getTime() &&
        refDate.getTime() < today.getTime()
      )
    },
    last_7_days () {
      return (
        getLast7Days(today).getTime() <= refDate.getTime() ||
        refDate.getTime() >= today.getTime()
      )
    },
    last_30_days () {
      return (
        getLast30Days(today).getTime() <= refDate.getTime() ||
        refDate.getTime() >= today.getTime()
      )
    },
    range () {
      const start = new Date(range[0].format())
      const end = new Date(range[1].format())
      end.setHours(23, 59, 59, 99)
      return (
        start.getTime() <= refDate.getTime() &&
        refDate.getTime() <= end.getTime()
      )
    }
  }

  return queryFuncs[query]()
}
