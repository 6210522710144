import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import manifestApi from '../api/manifest'
import { parseError } from '../utils'

const initialState = {
  hubs: null
}

export const fetchHubs = createAsyncThunk('hubs/fetch', async () => {
  const response = await manifestApi.getHubs()

  if (!response.ok) {
    return parseError(response)
  }

  return response.data
})

const hubsSlice = createSlice({
  name: 'hubs',
  initialState,

  extraReducers: builder => {
    builder.addCase(fetchHubs.fulfilled, (state, action) => {
      state.hubs = action?.payload?.payload
    })
  }
})

export default hubsSlice.reducer
