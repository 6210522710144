import { PiWarningCircle } from 'react-icons/pi'
import { ReactComponent as Times } from '../../assets/times.svg'

export default function TrackingError ({ message, onClose }) {
  return (
    <div className='w-full max-w-[400px] p-6 bg-white rounded-2xl h-40 mt-8 mx-auto'>
      <div
        className='w-6 h-6 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center'
        onClick={onClose}
      >
        <Times className='w-6 h-6' />
      </div>
      <div className='flex items-center justify-center flex-col text-center gap-4 w-full'>
        <PiWarningCircle size={36} color='#dc2626' />
        <p className='text-error'>Error: {message}</p>
      </div>
    </div>
  )
}
