import React from 'react'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { Link } from 'react-router-dom'

export default function BackButton ({ onBack = () => {}, to = -1 }) {
  return (
    <div className='flex items-start'>
      <Link to={to} onClick={onBack}>
        <IoIosArrowRoundBack size={30} />
      </Link>
    </div>
  )
}
