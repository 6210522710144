import { IoMdAdd, IoMdClose } from 'react-icons/io'
import AppFormField from '../globals/Form/AppFormField'
import AppFormSelectField from '../globals/Form/AppFormSelectField'
import { dhlUnits, fedexUnits, upsUnits } from '../../fixtures/units'
import DataList from '../globals/Form/DataList'
import hscodes from '../../fixtures/hscodes'
import { docs } from '../../fixtures/docs'
import { useMemo } from 'react'
import { MdExpandMore } from 'react-icons/md'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition
} from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import { useFormikContext } from 'formik'
import useCountry from '../../hooks/useCountry'

export default function OrderItem ({
  index,
  item,
  isLast,
  onAdd,
  onRemove,
  shipmentType,
  courier,
  defaultOpen = true,
  packageType
}) {
  const { setFieldValue } = useFormikContext()

  const Country = useCountry()

  const countries = Country.getAllCountries()

  const fishNames = [
    {
      hsCode: '03055390',
      name: 'Achatina Achatina (Giant Snail)'
    },
    {
      hsCode: '03076000',
      name: 'Dried Stockfish'
    }
  ]

  const isFish = useMemo(() => packageType === 'fish/snail', [packageType])
  const isDocument = useMemo(() => packageType === 'document', [packageType])

  const totalValue = useMemo(
    () =>
      (
        (item.quantity && item.price && item.quantity * item.price) ||
        0
      ).toLocaleString('en-NG', {
        style: 'currency',
        currency: 'NGN'
      }),
    [item.price, item.quantity]
  )

  const totalWeight = useMemo(
    () =>
      (
        (item.weight && item.quantity && item.weight * item.quantity) ||
        0
      ).toLocaleString(),
    [item.weight, item.quantity]
  )

  const handleRemove = e => {
    e.stopPropagation()
    onRemove(index)
  }

  const handleFishName = (value, name) => {
    const fishName = fishNames.find(({ hsCode }) => hsCode === value).name
    setFieldValue(name, fishName)
  }

  return (
    <Disclosure
      as='div'
      defaultOpen={defaultOpen}
      className={twMerge(
        'py-3 border-b border-gray-100',
        isLast ? 'items--last-item' : ''
      )}
    >
      {({ open }) => {
        return (
          <>
            <DisclosureButton
              data-id={index}
              data-open={open}
              className='w-full'
            >
              <div className='flex flex-row w-full gap-2 justify-between items-start sm:items-center pb-1'>
                <h3>Item Details ({index + 1})</h3>
                <div className='flex items-center justify-end gap-2'>
                  {!open && (
                    <>
                      <span className='text-sm'>
                        {totalWeight.toLocaleString()}kg
                      </span>
                      •
                      <span className='text-sm'>
                        {totalValue.toLocaleString('en-NG', {
                          style: 'currency',
                          currency: 'NGN'
                        })}
                      </span>
                    </>
                  )}
                  {isLast && index !== 0 && (
                    <button
                      onClick={onAdd}
                      type='button'
                      className='btn btn-sm gap-1 items-center text-dark-primary'
                    >
                      <IoMdAdd size={16} color='#333333' />
                      <span className='hidden md:block'>Add</span>
                    </button>
                  )}
                  {index !== 0 && (
                    <button
                      onClick={handleRemove}
                      type='button'
                      className='btn btn-sm gap-1 items-center text-dark-primary'
                    >
                      <IoMdClose size={16} color='#333333' />
                      <span className='hidden md:block'>Remove</span>
                    </button>
                  )}
                  <div>
                    <MdExpandMore size={18} color='#333333' />
                  </div>
                </div>
              </div>
            </DisclosureButton>
            <Transition
              enter='transition origin-top duration-150 ease-out'
              enterFrom='transform scale-y-0 opacity-0'
              enterTo='transform scale-y-100 opacity-100'
              leave='transition origin-top duration-100 ease-out'
              leaveFrom='transform scale-y-100 opacity-100'
              leaveTo='transform scale-y-0 opacity-0'
            >
              <DisclosurePanel as='div' className='flex flex-col gap-2'>
                <div className='flex flex-col md:flex-row items-start w-full gap-2'>
                  {isFish ? (
                    <AppFormSelectField
                      title='Item Type'
                      name={`items.${index}.hsCode`}
                      handleOnChange={value =>
                        handleFishName(value, `items.${index}.name`)
                      }
                    >
                      <option value=''>Select</option>
                      {fishNames.map((fish, id) => (
                        <option key={id} value={fish.hsCode}>
                          {fish.name}
                        </option>
                      ))}
                    </AppFormSelectField>
                  ) : isDocument ? (
                    <AppFormSelectField
                      name={`items.${index}.name`}
                      title='Document Type'
                    >
                      <option value=''>Select</option>
                      {docs.map(([value, name], id) => (
                        <option key={id} value={value}>
                          {name}
                        </option>
                      ))}
                    </AppFormSelectField>
                  ) : (
                    <AppFormField
                      name={`items.${index}.name`}
                      title='Item Name'
                      type='text'
                    />
                  )}
                  <AppFormSelectField
                    name={`items.${index}.unitMeasurement`}
                    title='Unit Measurement'
                  >
                    <option value=''>Select</option>
                    {courier === 'UPS' || courier === 'AAJ' || courier === 'AMX'
                      ? upsUnits.map(([value, unit], id) => (
                          <option value={value} key={id}>
                            {unit}
                          </option>
                        ))
                      : courier === 'DHL'
                      ? dhlUnits.map(([value, unit], id) => (
                          <option value={value} key={id}>
                            {unit}
                          </option>
                        ))
                      : fedexUnits.map(([value, unit], id) => (
                          <option value={value} key={id}>
                            {unit}
                          </option>
                        ))}
                  </AppFormSelectField>
                  {shipmentType === 'IN' && !isFish && (
                    <DataList name={`items.${index}.hsCode`} title='HS Code'>
                      {hscodes.map((itemCode, i) => (
                        <option key={i} value={itemCode['CET Code']}>
                          {itemCode.Description}
                        </option>
                      ))}
                    </DataList>
                  )}
                </div>
                <div className='flex flex-col md:flex-row items-start w-full gap-2'>
                  <AppFormField
                    name={`items.${index}.quantity`}
                    title='Quantity'
                    type='number'
                    step='1'
                  />
                  <AppFormField
                    name={`items.${index}.price`}
                    title='Unit Value'
                    type='number'
                  />
                </div>
                <p className='text-secondary text-sm text-right'>
                  Total Value: {totalValue}
                </p>
                {shipmentType === 'IN' && (
                  <div className='flex flex-col md:flex-row items-start w-full gap-2'>
                    <AppFormField
                      name={`items.${index}.weight`}
                      step='0.01'
                      title='Weight (kg)'
                      type='number'
                    />
                    <AppFormSelectField
                      name={`items.${index}.manufacturerCountry`}
                      title='Manufacturer Country'
                    >
                      <option value=''>Select country</option>
                      {countries.map((country, index) => (
                        <option key={index} value={country.isoCode}>
                          {country.name}
                        </option>
                      ))}
                    </AppFormSelectField>
                  </div>
                )}
                {isFish && (
                  <div className='text-center flex flex-row justify-center gap-4 w-full mt-6'>
                    <span>
                      HS Code:{' '}
                      <span className='text-primary font-bold'>
                        {item.hsCode}
                      </span>
                    </span>
                  </div>
                )}
              </DisclosurePanel>
            </Transition>
          </>
        )
      }}
    </Disclosure>
  )
}
