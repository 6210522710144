import { ReactComponent as Times } from '../../../assets/times.svg'

export default function SearchResultsDescription ({ searchState, onClose }) {
  return (
    <div className='flex items-center border border-neutral rounded-full w-fit pl-1.5'>
      <p>
        Showing all results for{' '}
        {searchState && (
          <>
            <span className='font-medium'>{searchState.searchBy}</span> -{' '}
          </>
        )}
        <span className='font-medium'>"{searchState.searchValue}"</span>
      </p>
      <button
        className='cursor-pointer p-1 flex items-center bg-transparent hover:bg-neutral rounded-full transition-all ml-4 text-xs'
        onClick={onClose}
      >
        <Times className='w-4 h-4' />
      </button>
    </div>
  )
}
