import bookingClient from './clients/bookingClient'

const endpoint = 'receipts/'

const getReceipts = async params => {
  const validParams = {}
  for (const key in params) {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null)
      validParams[key] = params[key]
  }
  const search_params = new URLSearchParams(validParams).toString()

  const response = await bookingClient.get(`${endpoint}?${search_params}`)
  return response
}

const createReceipt = async body => {
  const response = await bookingClient.post(endpoint, body)
  return response
}

const readReceipt = async id => {
  const response = await bookingClient.get(`${endpoint}${id}/`)
  return response
}

const updateReceipt = async (id, body) => {
  const response = await bookingClient.put(`${endpoint}${id}/`, body)
  return response
}

const patchReceipt = async (id, body) => {
  const response = await bookingClient.patch(`${endpoint}${id}/`, body)
  return response
}

const deleteReceipt = async id => {
  const response = await bookingClient.delete(`${endpoint}${id}/`)
  return response
}

const exportReceipts = async params => {
  const validParams = {}
  for (const key in params) {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null)
      validParams[key] = params[key]
  }
  const search_params = new URLSearchParams(validParams).toString()

  const response = await bookingClient.get(
    `${endpoint}export/?${search_params}`
  )
  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getReceipts,
  createReceipt,
  readReceipt,
  updateReceipt,
  patchReceipt,
  deleteReceipt,
  exportReceipts
}
