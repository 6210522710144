import { Country } from 'country-state-city'

export default function useCountry () {
  const getCountryByCode = isoCode => {
    let country = Country.getCountryByCode(isoCode)
    if (country) {
      let phonecode = country.phonecode.split('-')[0]
      country.phonecode = phonecode.startsWith('+')
        ? phonecode
        : `+${phonecode}`
    }

    return country
  }

  const getAllCountries = () => {
    return Country.getAllCountries().map(country => {
      let phonecode = country.phonecode.split('-')[0]
      country.phonecode = phonecode.startsWith('+')
        ? phonecode
        : `+${phonecode}`

      return country
    })
  }

  const sortByIsoCode = countries => {
    return Country.sortByIsoCode(countries).map(country => {
      let phonecode = country.phonecode.split('-')[0]
      country.phonecode = phonecode.startsWith('+')
        ? phonecode
        : `+${phonecode}`

      return country
    })
  }

  return {
    getCountryByCode,
    getAllCountries,
    sortByIsoCode
  }
}
