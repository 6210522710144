import React, { useEffect, useState, useContext, useMemo } from 'react'
import Modal from 'react-modal'
import { FiArrowUpRight, FiArrowDownLeft, FiDownload } from 'react-icons/fi'
import { GrDocumentPdf } from 'react-icons/gr'
import { BiChevronDown } from 'react-icons/bi'
import Loader from '../loader/Loader'
import ordersApi from '../../api/orders'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import shipmentApi from '../../api/shipments'
import useApi from '../../hooks/useApi'
import useToast from '../../hooks/useToast'
import ConfirmDelete from './confirmDelete'
import { Link, useNavigate } from 'react-router-dom'
import { getStateOfCountry, parseError } from '../../utils'
import ProcessOrder from './ProcessOrderModal'
import ROUTES from '../../constants/routes'
import { statuses } from '../../fixtures/orderStatus'
import { HiOutlinePencil } from 'react-icons/hi2'
import EditCustomerInfo from './editOrder/EditCustomerInfo'
import EditItems from './editOrder/EditItems'
import useCountry from '../../hooks/useCountry'
import Pill from '../globals/Pill'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import ROLES from '../../constants/roles'
import { twMerge } from 'tailwind-merge'

const styles = {
  content: {
    borderRadius: '20px',
    inset: '20px',
    maxWidth: '768px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

const OrderModal = ({ isOpen, id, onClose, onUpdateOrders }) => {
  const { userRole } = useContext(AuthLayoutContext)
  const { data: orderData, request: readOrder } = useApi(
    ordersApi.readOrder,
    id
  )
  const { data: shipmentData, request: getShipment } = useApi(
    shipmentApi.getShipmentByOrder,
    id
  )

  const Country = useCountry()

  const toast = useToast()

  const [loading, setLoading] = useState(true)
  const [isProcessOrder, setIsProcessOrder] = useState({
    state: false,
    type: ''
  })
  const [isDelete, setIsDelete] = useState(false)
  const [isEditCustomer, setEditCustomer] = useState({
    state: false,
    type: ''
  })
  const [isEditItem, setEditItems] = useState(false)

  useEffect(() => {
    readOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (orderData) {
      if (orderData?.payload.order.order_state !== 1) {
        getShipment()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData?.payload.order.id])

  const navigate = useNavigate()

  useEffect(() => {
    if (orderData) {
      if (orderData.payload.order.order_state !== 1) {
        if (shipmentData) {
          setLoading(false)
        }
      } else setLoading(false)
    }
  }, [orderData, shipmentData])

  const handleDelete = async () => {
    setLoading(true)
    const response = await ordersApi.deleteOrder(orderData.payload.order.id)
    if (!response.ok) {
      setLoading(false)
      const apiError = parseError(response)
      if (apiError) {
        if (response.status === 409) {
          toast(
            'Cannot delete this order because it is referenced in an invoice.',
            'error'
          )
        } else if (response.status === 403) {
          toast('You do not have permission to perform this action.', 'error')
        } else {
          toast('Unable to delete order.', 'error')
        }
      }

      setIsDelete(false)
      return
    }
    toast('Order deleted')
    onUpdateOrders()
    onClose()
  }

  const handleOnDownloadInvoice = () => {
    const base64String = shipmentData.payload.invoice

    // Convert base64 to binary data
    const binaryData = atob(base64String)

    // Create a Uint8Array from binary data
    const byteArray = new Uint8Array(binaryData.length)
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i)
    }

    // Create a Blob from the Uint8Array
    const blob = new window.Blob([byteArray], { type: 'application/pdf' })

    // Create a temporary anchor element to initiate the download
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'invoice.pdf'
    document.body.appendChild(link)

    // Click the link to start the download
    link.click()

    // Remove the link after the download is complete
    setTimeout(function () {
      document.body.removeChild(link)
      URL.revokeObjectURL(link.href)
    }, 100)
  }

  const handleOnDownloadLabels = () => {
    if (orderData.payload.order.tpl_service === 'UPS') {
      if (orderData.payload.order.number_of_packages > 1) {
        const packages =
          orderData.payload.order.tpl_service === 'UPS'
            ? shipmentData.payload.packages
            : orderData.payload.order.tpl_service === 'DHL'
            ? shipmentData.payload.raw_response.documents
            : null
        packages.forEach((packageItem, index) => {
          const binaryData = atob(
            orderData.payload.order.tpl_service === 'UPS'
              ? packageItem.ShippingLabel.GraphicImage
              : orderData.payload.order.tpl_service === 'DHL'
              ? packageItem.content
              : null
          )

          // Create a Uint8Array from binary data
          const byteArray = new Uint8Array(binaryData.length)
          for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i)
          }

          // Create a Blob from the Uint8Array
          const blob = new window.Blob([byteArray], {
            type:
              orderData.payload.order.tpl_service === 'UPS'
                ? 'image/gif'
                : orderData.payload.order.tpl_service === 'DHL'
                ? 'application/pdf'
                : null
          })

          // Create a temporary anchor element to initiate the download
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download =
            orderData.payload.order.tpl_service === 'UPS'
              ? `image${index + 1}.gif`
              : orderData.payload.order.tpl_service === 'DHL'
              ? `image${index + 1}.pdf`
              : null
          document.body.appendChild(link)

          // Click the link to start the download
          link.click()

          // Remove the link after the download is complete
          setTimeout(function () {
            document.body.removeChild(link)
            URL.revokeObjectURL(link.href)
          }, 100)
        })
      } else {
        const binaryData = atob(
          shipmentData.payload.packages.ShippingLabel.GraphicImage
        )

        // Create a Uint8Array from binary data
        const byteArray = new Uint8Array(binaryData.length)
        for (let i = 0; i < binaryData.length; i++) {
          byteArray[i] = binaryData.charCodeAt(i)
        }

        // Create a Blob from the Uint8Array
        const blob = new window.Blob([byteArray], { type: 'image/gif' })

        // Create a temporary anchor element to initiate the download
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'image.gif'
        document.body.appendChild(link)

        // Click the link to start the download
        link.click()

        // Remove the link after the download is complete
        setTimeout(function () {
          document.body.removeChild(link)
          URL.revokeObjectURL(link.href)
        }, 100)
      }
    } else {
      shipmentData.payload.label_documents.forEach((label_document, index) => {
        const binaryData = atob(label_document)

        // Create a Uint8Array from binary data
        const byteArray = new Uint8Array(binaryData.length)
        for (let i = 0; i < binaryData.length; i++) {
          byteArray[i] = binaryData.charCodeAt(i)
        }

        // Create a Blob from Uint8Array
        const blob = new window.Blob([byteArray], { type: 'application/pdf' })

        // Create temporary achor element to initiate download
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `label-${index}.pdf`
        document.body.appendChild(link)

        // Click link to start download
        link.click()

        // Remove link after download is completed
        setTimeout(() => {
          document.body.removeChild(link)
          URL.revokeObjectURL(link.href)
        }, 100)
      })
    }
  }

  const updateOrder = () => {
    readOrder()
    onUpdateOrders()
  }

  const handleOnVoidOrder = async () => {
    setLoading(true)

    const isUnrestricted =
      userRole.id === 1 || userRole.id === 15 || userRole.id === 17

    const response = isUnrestricted
      ? await shipmentApi.voidShipmentUnrestricted(shipmentData.payload.id)
      : await shipmentApi.voidShipment(shipmentData.payload.id)

    if (!response.ok) {
      setLoading(false)
      const apiError = parseError(response)
      if (apiError) {
        toast('Unable to void this order.', 'error')
      }

      return
    }
    toast('Successfully voided order')
    setLoading(false)
    updateOrder()
  }

  const handleOnTrack = () => {
    navigate(
      `${ROUTES.TRACK.path}?track_id=${shipmentData.payload.tracking_id}`
    )
  }

  const canCreateOrder = useMemo(() => {
    if (userRole) {
      return ROLES[userRole.id].permissions?.orders?.create
    }
  }, [userRole])

  const isEdittable = useMemo(() => {
    return (
      orderData?.payload?.order.order_state === 1 ||
      orderData?.payload?.order.order_state === 4
    )
  }, [orderData?.payload?.order.order_state])

  const handleProcessOrder = (type = 'process') => {
    setIsProcessOrder({ state: true, type })
  }

  const onCloseProcessOrder = (isSuccess = false) => {
    setIsProcessOrder({ state: false, type: '' })
    if (isSuccess) {
      updateOrder()
    }
  }

  const onEditCustomer = type => {
    setEditCustomer({ state: true, type })
  }

  const onCloseEditCustomer = isSuccess => {
    setEditCustomer({ state: false, type: '' })
    if (isSuccess) updateOrder()
  }

  const onEditItems = () => {
    setEditItems(true)
  }

  const onCloseEditItems = isSuccess => {
    setEditItems(false)
    if (isSuccess) updateOrder()
  }

  return (
    <Modal style={styles} isOpen={isOpen} onRequestClose={onClose}>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {isProcessOrder.state && (
            <ProcessOrder
              isOpen={isProcessOrder.state}
              onClose={onCloseProcessOrder}
              orderData={orderData.payload}
              onReload={updateOrder}
              isReprocess={isProcessOrder.type === 'reprocess'}
            />
          )}
          {isDelete && (
            <ConfirmDelete
              isOpen={isDelete}
              onConfirm={handleDelete}
              onCancel={() => setIsDelete(false)}
              text='Are you sure you want to delete this order?'
            />
          )}
          {isEditCustomer.state && (
            <EditCustomerInfo
              isOpen={isEditCustomer.state}
              onClose={onCloseEditCustomer}
              customerType={isEditCustomer.type}
              customer={orderData.payload.order[isEditCustomer.type]}
              shipmentType={orderData.payload.order.type}
              orderId={orderData.payload.order.id}
            />
          )}
          {isEditItem && (
            <EditItems
              isOpen={isEditItem}
              onClose={onCloseEditItems}
              items={orderData.payload.order.packages.items}
              shipmentType={orderData.payload.order.type}
              courier={orderData.payload.order.tpl_service}
              packageType={orderData.payload.order.packages.type}
              orderId={orderData.payload.order.id}
              totalWeight={orderData.payload.order.weight}
              declaredValue={orderData.payload.order.packages.itemsValue}
            />
          )}
          <div className='flex items-center w-full border-b border-gray-100 pb-4'>
            {orderData.payload.order.order_state === 1 &&
              !orderData.payload.order.paid && (
                <button
                  className='btn btn-error'
                  onClick={() => setIsDelete(true)}
                >
                  Delete
                </button>
              )}
            <div className='gap-5 ml-auto flex'>
              {orderData.payload.order.order_state === 1 ? (
                canCreateOrder && (
                  <button
                    className='btn btn-primary'
                    onClick={() => handleProcessOrder()}
                  >
                    Process Order
                  </button>
                )
              ) : orderData.payload.order.order_state === 4 ? (
                <button
                  className='btn btn-primary'
                  onClick={() => handleProcessOrder('reprocess')}
                >
                  Reprocess Order
                </button>
              ) : orderData.payload.meta?.invoice ? (
                <Link
                  className='btn btn-primary'
                  to={`${ROUTES.INVOICES.path}/${orderData.payload.meta?.invoice}`}
                >
                  View Invoice
                </Link>
              ) : null}
              <button className='btn btn-accent' onClick={onClose}>
                Close
              </button>
            </div>
          </div>

          <div className='flex bg-white py-4 lg:p-10 gap-10 flex-col rounded-lg'>
            <div className='pb-6 relative text-center'>
              <h1 className='font-bold text-4xl'>
                {orderData.payload.order.total.toLocaleString('en-NG', {
                  style: 'currency',
                  currency: 'NGN'
                })}
              </h1>
              <p className='text-dark-primary/70'>
                {orderData.payload.order.weight}kg | #
                {orderData.payload.order.id} |{' '}
                {orderData.payload.order.shipment_rate === 'CR'
                  ? 'Cargo Rate'
                  : 'Standard Rate'}{' '}
                | {orderData.payload.order.tpl_service}
              </p>
              <div className='sm:absolute top-0 right-0 flex flex-col items-center gap-2'>
                <Pill
                  size='lg'
                  name={statuses[orderData.payload.order.order_state].name}
                  theme={statuses[orderData.payload.order.order_state].theme}
                />

                {orderData.payload.order.paid &&
                  orderData.payload.order.order_state === 1 && (
                    <div className='flex flex-row items-center'>
                      <div className='bg-[#00FF66] w-2 h-2 rounded-xl mr-0.5' />
                      <div className='font-medium text-[#00AB44]'>Paid</div>
                    </div>
                  )}
              </div>
            </div>

            <div className='flex gap-4 flex-col px-3 bg-gray-50 rounded-lg lg:rounded-xl divide-y divide-gray-100'>
              <div className='flex items-center gap-2 md:gap-4 text-left w-full py-5'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <FiArrowUpRight size={18} color='#FF4D00' />
                </div>
                <div
                  className={twMerge(
                    isEdittable
                      ? 'w-[calc(100%-4.5rem)] md:w-[calc(100%-6.5rem)]'
                      : 'w-[calc(100%-2.5rem)] md:w-[calc(100%-4rem)]'
                  )}
                >
                  <h4 className='font-semibold'>
                    {orderData.payload.order.sender?.contact?.name}
                  </h4>
                  <p className='text-dark-primary/70'>
                    {orderData.payload.order.sender.address.street_lines[0]},{' '}
                    {orderData.payload.order.sender.address.city},{' '}
                    {orderData.payload.order.sender.address.state_name ||
                      getStateOfCountry(
                        orderData.payload.order.sender.address
                          .state_or_province_code,
                        orderData.payload.order.sender.address.country
                      )?.name ||
                      orderData.payload.order.sender.address
                        .state_or_province_code}
                    , {orderData.payload.order.sender.address.country} |{' '}
                    {orderData.payload.order.sender.address.postal_code} |{' '}
                    {orderData.payload.order.sender?.contact?.phone_number}
                  </p>
                </div>
                {isEdittable && (
                  <div className='flex justify-center items-center w-6'>
                    <button
                      className='cursor-pointer p-2 flex bg-transparent hover:bg-neutral rounded-full transition-all'
                      onClick={() => onEditCustomer('sender')}
                    >
                      <HiOutlinePencil size={16} />
                    </button>
                  </div>
                )}
              </div>

              <div className='flex items-center gap-2 md:gap-4 text-left w-full py-5'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <FiArrowDownLeft size={18} color='#FF4D00' />
                </div>
                <div
                  className={twMerge(
                    isEdittable
                      ? 'w-[calc(100%-4.5rem)] md:w-[calc(100%-6.5rem)]'
                      : 'w-[calc(100%-2.5rem)] md:w-[calc(100%-4rem)]'
                  )}
                >
                  <h4 className='font-semibold'>
                    {orderData.payload.order.receiver.contact?.name}
                  </h4>
                  <p className='text-dark-primary/70'>
                    {orderData.payload.order.receiver.address.street_lines[0]},{' '}
                    {orderData.payload.order.receiver.address.city},{' '}
                    {orderData.payload.order.receiver.address.state_name ||
                      getStateOfCountry(
                        orderData.payload.order.receiver.address
                          .state_or_province_code,
                        orderData.payload.order.receiver.address.country
                      )?.name ||
                      orderData.payload.order.receiver.address
                        .state_or_province_code}
                    , {orderData.payload.order.receiver.address.country}{' '}
                    {orderData.payload.order.receiver.address.postal_code} |{' '}
                    {orderData.payload.order.receiver.contact?.phone_number}
                  </p>
                </div>
                {isEdittable && (
                  <div className='flex justify-center items-center w-6'>
                    <button
                      className='cursor-pointer p-2 flex bg-transparent hover:bg-neutral rounded-full transition-all'
                      onClick={() => onEditCustomer('receiver')}
                    >
                      <HiOutlinePencil size={16} />
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className='text-left'>
              <div className='grid grid-cols-12'>
                <div className='col-span-10'>
                  <h3 className='text-xl text-[#FF4D00]'>Items</h3>
                </div>
                <div className='col-span-2 flex justify-end'>
                  {(orderData.payload.order.order_state === 1 ||
                    orderData.payload.order.order_state === 4) && (
                    <button
                      className='cursor-pointer p-2 flex bg-transparent hover:bg-neutral rounded-full transition-all'
                      onClick={onEditItems}
                    >
                      <HiOutlinePencil size={16} />
                    </button>
                  )}
                </div>
              </div>
              <div className='px-2 lg:px-4'>
                {orderData.payload.order.packages.items &&
                orderData.payload.order.packages.items.length > 0
                  ? orderData.payload.order.packages.items.map(
                      (item, index) => (
                        <div
                          key={index}
                          className='grid grid-cols-12 gap-2 text-dark-primary text-left justify-between items-start py-5'
                        >
                          <div className='col-span-9'>
                            <h4 className='font-bold'>{item.name}</h4>
                            <p className='text-dark-primary/70'>
                              {item.price.toLocaleString('en-NG', {
                                style: 'currency',
                                currency: 'NGN'
                              })}
                              {orderData.payload.order.type === 'IN'
                                ? ` | ${item.hsCode}`
                                : ''}
                              {typeof item.weight === 'number'
                                ? ` | ${item.weight}kg`
                                : ''}
                              {item.manufacturerCountry
                                ? ` | ${
                                    Country.getCountryByCode(
                                      item.manufacturerCountry
                                    )?.name
                                  }`
                                : ''}
                            </p>
                          </div>
                          <div className='col-span-3 gap-4 flex justify-end'>
                            <span className='font-medium text-[#FF4D00] select-none'>
                              {item.quantity} {item.unitMeasurement}
                            </span>
                          </div>
                        </div>
                      )
                    )
                  : ''}
              </div>
            </div>

            {shipmentData && (
              <Accordion allowZeroExpanded className='mt-10'>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton className='flex items-center justify-between bg-gray-300 rounded-lg py-5 px-3'>
                      <p className='font-bold text-xl text-gray1'>Shipment</p>
                      <BiChevronDown size={24} />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className='flex items-center justify-between px-3'>
                      <h1 className='text-xl'>
                        Tracking ID: {shipmentData.payload.tracking_id}
                      </h1>
                      <div className='flex items-center gap-4 my-10'>
                        {orderData.payload.order.order_state === 4 ? (
                          <span
                            className={`w-fit py-2 px-5 bg-[#F5CCC299] border border-[#DE350B] text-[#DE350B] block mx-auto btn-rounded font-medium`}
                          >
                            Voided
                          </span>
                        ) : (
                          <>
                            <button
                              className='btn btn-sm btn-primary'
                              onClick={handleOnTrack}
                            >
                              Track
                            </button>
                            <button
                              onClick={handleOnVoidOrder}
                              className='btn btn-sm btn-secondary'
                            >
                              Void
                            </button>
                          </>
                        )}
                      </div>
                    </div>

                    <div className='w-full flex flex-row flex-wrap items-center justify-between gap-4'>
                      <button
                        onClick={handleOnDownloadInvoice}
                        disabled={!shipmentData.payload.invoice}
                        className={`w-fit flex items-center rounded-lg bg-[#FFD8C8] text-[#FF4D00] p-4 hover:bg-[#FF4D00] hover:text-white ${
                          !shipmentData.payload.invoice
                            ? 'btn-disabled cursor-not-allowed'
                            : ''
                        }`}
                      >
                        <div className='flex items-center gap-3 mr-2'>
                          <GrDocumentPdf size={18} />
                          <p>Download Shipment Invoice</p>
                        </div>
                        <FiDownload size={18} />
                      </button>

                      <button
                        className='btn btn-secondary'
                        onClick={handleOnDownloadLabels}
                      >
                        Download Shipping Label
                      </button>
                    </div>

                    <div className='my-16 flex flex-col items-end'>
                      {orderData.payload.order.tpl_service === 'UPS' ? (
                        shipmentData.payload.packages?.length > 1 ? (
                          <Slide>
                            {shipmentData.payload.packages.map(
                              (packageItem, index) => {
                                return (
                                  <img
                                    key={index}
                                    src={`data:image/png;base64, ${packageItem.ShippingLabel.GraphicImage}`}
                                    alt=''
                                    className='object-cover w-full h-96'
                                  />
                                )
                              }
                            )}
                          </Slide>
                        ) : (
                          <img
                            alt=''
                            className='object-cover w-full h-96 mb-6 rounded-lg border-2 border-gray-100'
                            src={`data:image/png;base64, ${shipmentData.payload.packages.ShippingLabel.GraphicImage}`}
                          />
                        )
                      ) : (
                        <Slide>
                          {shipmentData.payload.label_documents?.map(
                            (label, index) => {
                              return (
                                <embed
                                  key={index}
                                  src={`data:application/pdf;base64, ${label}`}
                                  className='object-cover w-full h-96'
                                />
                              )
                            }
                          )}
                        </Slide>
                      )}
                    </div>

                    {orderData.payload.order.type === 'IN' && (
                      <div className='w-full text-left my-10'>
                        <h1 className='font-bold text-xl text-gray-700'>
                          Carrier Tracking Number
                        </h1>
                        <p className='text-gray-400'>
                          {shipmentData.payload.shipment_tracking_number}
                        </p>
                      </div>
                    )}
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            )}
          </div>
        </div>
      )}
    </Modal>
  )
}

export default OrderModal
