import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tracker: [],
  trackerHash: [],
};

const shipmentTrackerSlice = createSlice({
  name: "shipmentTracker",
  initialState,
  reducers: {
    trackShipments: (state, payload) => {
      let cloneTracker = state.tracker;
      cloneTracker.push(payload?.payload);
      state.tracker = [...cloneTracker];
    },

    saveShipmentTracker: (state, payload) => {
      let cloneTracker = state.trackerHash;
      cloneTracker.push(payload?.payload);
      state.trackerHash = cloneTracker;
    },

    emptyTrackShipments: (state) => {
      state.trackerHash = [];
      state.tracker = [];
    },
  },
});
export const { trackShipments, saveShipmentTracker, emptyTrackShipments } =
  shipmentTrackerSlice.actions;

export default shipmentTrackerSlice.reducer;
