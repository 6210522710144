import React, { useEffect, useMemo, useContext } from 'react'
import { Navigate, useSearchParams, useLocation } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import { CreateOrderContext } from '../../containers/CreateOrderLayout'
import ShipmentSectionForm from '../../components/orders/newOrder/ShipmentSectionForm'

const ShipmentSection = ({ metaTitle }) => {
  const { order, updateActiveStep, resolvePathname } =
    useContext(CreateOrderContext)

  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('ord')
  const quoteId = searchParams.get('qt')

  const location = useLocation()

  const backRoute = useMemo(() => {
    if (order.payload.packages) {
      switch (order.payload.packages.type) {
        case 'document':
          return `${ROUTES.ORDERS.CREATE_ORDER.DOCUMENT.path}${location.search}`
        case 'fish/snail':
          return `${ROUTES.ORDERS.CREATE_ORDER.FISH_SNAIL.path}${location.search}`
        default:
          return `${ROUTES.ORDERS.CREATE_ORDER.PACKAGE_SECTION.path}${location.search}`
      }
    }
  }, [location.search, order.payload.packages])

  useEffect(() => {
    updateActiveStep({
      stepId: 5,
      metaTitle,
      back: backRoute
    })
  }, [backRoute, metaTitle, updateActiveStep])

  if (!order.payload.type) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}`
        )}
      />
    )
  }

  if (!order.payload.sender) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=sender&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=sender`
        )}
      />
    )
  }

  if (!order.payload.receiver) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=receiver&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=receiver`
        )}
      />
    )
  }

  if (order.payload.type === 'LC' && !order.payload.category) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.PACKAGE_TYPE.path}?ctm=sender&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.PACKAGE_TYPE.path}`
        )}
      />
    )
  }

  if (!order.payload.packages) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}${location.search}&ctm=sender`
            : `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}`
        )}
      />
    )
  }

  return (
    <div>
      <h2 className='py-4 md:pt-0 font-bold text-2xl text-center'>
        Shipment Information
      </h2>
      <div className='flex flex-col items-center pb-8'>
        <ShipmentSectionForm />
      </div>
    </div>
  )
}

export default ShipmentSection
