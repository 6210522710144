import React, { useContext, useEffect, useMemo, useState } from 'react'
import ReactModal from 'react-modal'
import ordersApi from '../../api/orders'
import AppForm from '../globals/Form/AppForm'
import AppFormField from '../globals/Form/AppFormField'
import Loader from '../loader/Loader'
import TempQuote from '../quotes/TempQuote'
import { City, State } from 'country-state-city'
import { IoMdAdd, IoMdClose } from 'react-icons/io'
import useToast from '../../hooks/useToast'
import { capitalizeFirstLetter, parseError, scrollToTarget } from '../../utils'
import SelectPackaging from '../globals/SelectPackaging'
import _ from 'lodash'
import AppFormSelectField from '../globals/Form/AppFormSelectField'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import useCountry from '../../hooks/useCountry'

const customStyles = {
  content: {
    borderRadius: '20px',
    inset: '20px',
    maxWidth: '768px',
    margin: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

const PackageFormQuote = ({
  fishShipment = false,
  addons,
  categories,
  locations,
  type = 'IN',
  customer,
  allowCreateOrder
}) => {
  // const { userLocation } = useContext(AppContext)
  const Country = useCountry()

  const layoutContext = useContext(AuthLayoutContext)

  const initFormData = () => {
    const config = {
      sender_country_code: 'NG',
      sender_state: '',
      sender_city: '',
      receiver_country_code: type === 'LC' ? 'NG' : 'US',
      receiver_state: '',
      receiver_city: '',
      type,
      tpl_service: type === 'LC' ? 'AAJ' : fishShipment ? 'DHL' : '',
      package_insurance: 'FR',
      packages: {
        type: fishShipment ? 'fish/snail' : 'regular',
        itemsValue: '',
        addOns: [],
        packages: [
          {
            unitMeasurement: 'KGS',
            actualWeight: '',
            predefinedDimension: ''
          }
        ]
      }
    }

    if (type === 'LC') {
      config.delivery_mode = ''
      config.sender_area_or_province = ''
      config.receiver_area_or_province = ''
    }

    return config
  }

  const [formData, setFormData] = useState(initFormData())
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [quoteData, setQuoteData] = useState()
  const [cityNotListed, setCityNotListed] = useState({
    sender: false,
    receiver: false
  })
  const [stateNotListed, setStateNotListed] = useState({
    sender: false,
    receiver: false
  })

  const [packagingErrors, setPackagingErrors] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // useEffect(() => {
  //   if (userLocation) {
  //     setFormData(state => ({
  //       ...state,
  //       sender_country_code: userLocation.country.short_name
  //     }))
  //   }
  // }, [userLocation])

  const senderStates = useMemo(() => {
    let states = []

    if (type === 'LC') {
      states = Array.from(new Set(locations.map(({ state }) => state))).map(
        state => ({ name: state, isoCode: state })
      )
      states = states.sort((a, b) => a.name.localeCompare(b.name))
    } else {
      states = State.getStatesOfCountry(formData.sender_country_code)
    }

    return states
  }, [formData.sender_country_code, locations, type])

  const senderCities = useMemo(() => {
    let cities = []

    if (
      !stateNotListed.sender &&
      formData.sender_country_code &&
      formData.sender_state
    ) {
      if (type === 'LC') {
        cities = locations.reduce((acc, curr) => {
          if (curr.state === formData.sender_state) {
            acc.push({ name: curr.city })
          }
          return acc
        }, [])
        cities = cities.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        cities = City.getCitiesOfState(
          formData.sender_country_code,
          formData.sender_state
        )
      }
    }

    return cities
  }, [
    stateNotListed.sender,
    formData.sender_country_code,
    formData.sender_state,
    type,
    locations
  ])

  const senderProvinces = useMemo(() => {
    let provinces = []

    if (type === 'LC' && formData.sender_city) {
      let location = locations.find(lctn => lctn.city === formData.sender_city)
      if (location) {
        provinces = [...location.provinces].sort((a, b) => a.localeCompare(b))
      }
    }

    return provinces
  }, [formData.sender_city, locations, type])

  const receiverStates = useMemo(() => {
    let states = []

    if (type === 'LC') {
      states = Array.from(new Set(locations.map(({ state }) => state))).map(
        state => ({ name: state, isoCode: state })
      )
      states = states.sort((a, b) => a.name.localeCompare(b.name))
    } else {
      states = State.getStatesOfCountry(formData.receiver_country_code)
    }

    return states
  }, [formData.receiver_country_code, locations, type])

  const receiverCities = useMemo(() => {
    let cities = []

    if (
      !stateNotListed.receiver &&
      formData.receiver_country_code &&
      formData.receiver_state
    ) {
      if (type === 'LC') {
        cities = locations.reduce((acc, curr) => {
          if (curr.state === formData.receiver_state) {
            acc.push({ name: curr.city })
          }
          return acc
        }, [])

        cities = cities.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        cities = City.getCitiesOfState(
          formData.receiver_country_code,
          formData.receiver_state
        )
      }
    }
    return cities
  }, [
    formData.receiver_country_code,
    formData.receiver_state,
    locations,
    stateNotListed.receiver,
    type
  ])

  const receiverProvinces = useMemo(() => {
    let provinces = []

    if (type === 'LC' && formData.receiver_city) {
      let location = locations.find(
        location => location.city === formData.receiver_city
      )
      if (location) {
        provinces = [...location.provinces].sort((a, b) => a.localeCompare(b))
      }
    }

    return provinces
  }, [formData.receiver_city, locations, type])

  useEffect(() => {
    if (
      senderStates
      // && userLocation
    ) {
      const defaultState =
        // senderStates.find(
        //   ({ name }) =>
        //     name.toLowerCase() === userLocation?.state?.long_name.toLowerCase()
        // ) ||
        senderStates[0]

      setFormData(prevState => ({
        ...prevState,
        sender_state: defaultState?.isoCode
      }))
    }
  }, [senderStates])

  useEffect(() => {
    if (senderCities) {
      // const defaultCity =
      //   formData.sender_state.toLowerCase() ===
      //   userLocation?.state?.long_name.toLowerCase()
      //     ? cities.find(
      //         ({ name }) =>
      //           name.toLowerCase() ===
      //           userLocation?.city?.long_name.toLowerCase()
      //       ) || cities[0]
      //     : cities[0]

      setFormData(state => ({
        ...state,
        sender_city: senderCities[0]?.name
      }))
    }
  }, [senderCities])

  useEffect(() => {
    if (senderProvinces) {
      setFormData(state => ({
        ...state,
        sender_area_or_province: senderProvinces[0]
      }))
    }
  }, [senderProvinces])

  useEffect(() => {
    if (receiverStates) {
      setFormData(state => ({
        ...state,
        receiver_state: receiverStates[0]?.isoCode
      }))
    }
  }, [receiverStates])

  useEffect(() => {
    if (receiverCities) {
      setFormData(state => ({
        ...state,
        receiver_city: receiverCities[0]?.name
      }))
    }
  }, [receiverCities])

  useEffect(() => {
    if (receiverProvinces) {
      setFormData(state => ({
        ...state,
        receiver_area_or_province: receiverProvinces[0]
      }))
    }
  }, [receiverProvinces])

  useEffect(() => {
    if (formData.type === 'LC') {
      // eslint-disable-next-line default-case
      switch (formData.category) {
        case 1:
          setFormData(state => ({ ...state, package_insurance: 'EI' }))
          break
        case 2:
          setFormData(state => ({ ...state, package_insurance: 'NE' }))
          break
        case 3:
          setFormData(state => ({ ...state, package_insurance: 'HI' }))
          break
      }
    }
  }, [formData.type, formData.category])

  const addPackage = () => {
    setFormData(state => ({
      ...state,
      packages: {
        ...state.packages,
        packages: [
          ...state.packages.packages,
          {
            unitMeasurement: 'KGS',
            actualWeight: '',
            predefinedDimension: ''
          }
        ]
      }
    }))

    scrollToTarget('.packages--last-item', layoutContext?.layoutContainer)
  }

  const removePackage = id => {
    setFormData(state => {
      return {
        ...state,
        packages: {
          ...state.packages,
          packages: state.packages.packages.filter(
            (item, item_id) => item_id !== id
          )
        }
      }
    })
  }

  const addAddOn = () => {
    const maxLength = fishShipment
      ? addons.length
      : addons.filter(item => !item.name.toLowerCase().includes('fish')).length

    if (formData.packages.addOns.length < maxLength) {
      const defaultSelected = addons.find(item => {
        const isNotSelected = id =>
          formData.packages.addOns.every(addon => addon.id !== id)
        if (fishShipment) {
          return isNotSelected(item.id)
        } else {
          return (
            !item.name.toLowerCase().includes('fish') && isNotSelected(item.id)
          )
        }
      })
      const updatedFormData = { ...formData }
      updatedFormData.packages.addOns.push({
        id: defaultSelected.id,
        quantity: ''
      })
      setFormData(updatedFormData)

      scrollToTarget('.addons--last-item', layoutContext?.layoutContainer)
    }
  }

  const removeAddOn = index => {
    const updatedFormData = { ...formData }
    updatedFormData.packages.addOns.splice(index, 1)
    setFormData(updatedFormData)
  }

  const toast = useToast()

  const handleFieldChange = (value, name, category = null, id = null) => {
    switch (category) {
      case 'itemsValue':
        setFormData(state => {
          const newData = { ...state }
          newData.packages.itemsValue = value ? Number(value) : ''
          return newData
        })
        break
      case 'packages':
        setFormData(state => {
          const newData = { ...state }
          if (name === 'predefinedDimension') {
            delete newData.packages.packages[id].packageDimension
          }
          newData.packages.packages[id][name] = value ? Number(value) : ''
          return newData
        })
        break
      case 'addOns':
        setFormData(state => {
          const newData = { ...state }
          newData.packages.addOns[id][name] = value ? Number(value) : ''
          return newData
        })
        break
      default:
        if (name === 'category') {
          setFormData(state => ({
            ...state,
            category: Number(value)
          }))
        } else {
          setFormData(state => ({ ...state, [name]: value }))
        }
        break
    }
  }

  const handlePackageDimension = (dimension, index) => {
    setFormData(state => {
      const packages = [...state.packages.packages]
      delete packages[index].predefinedDimension
      packages[index].packageDimension = dimension
      return {
        ...state,
        packages: {
          ...state.packages,
          packages
        }
      }
    })
  }

  const handleOnSubmit = async e => {
    e.preventDefault()

    setPackagingErrors(null)
    const packagingErrors = []

    for (let i = 0; i < formData.packages.packages.length; i++) {
      const item = formData.packages.packages[i]
      const isDimension = !!(
        item.predefinedDimension ||
        (item.packageDimension?.length &&
          item.packageDimension?.width &&
          item.packageDimension?.height &&
          item.packageDimension?.weight)
      )
      if (!isDimension) {
        packagingErrors.push(`${i}`)
      }
    }

    if (packagingErrors.length) {
      setPackagingErrors(packagingErrors)
      setTimeout(() => {
        scrollToTarget(
          '.packaging-error.block',
          layoutContext?.layoutContainer,
          150
        )
      }, 500)
      return
    }

    setLoading(true)
    setIsModalOpen(true)

    const payload = {
      sender: {
        address: {
          country: Country.getCountryByCode(formData.sender_country_code).name,
          country_code: formData.sender_country_code,
          state_or_province_code: formData.sender_state,
          city: formData.sender_city
        }
      },
      receiver: {
        address: {
          country: Country.getCountryByCode(formData.receiver_country_code)
            .name,
          country_code: formData.receiver_country_code,
          state_or_province_code: formData.receiver_state,
          city: formData.receiver_city
        }
      },
      tpl_service: formData.tpl_service,
      packages: {
        ..._.cloneDeep(formData.packages),
        itemsValue: formData.packages.itemsValue
      },
      type: formData.type,
      package_insurance: formData.package_insurance,
      fish_shipment: fishShipment
    }

    payload.packages.packages = payload.packages.packages.map(item => {
      if (item.packageDimension) {
        ;['height', 'length', 'width', 'weight'].forEach(property => {
          item.packageDimension[property] = Number(
            item.packageDimension[property]
          )
        })
        return item
      }
      return item
    })

    if (payload.type === 'LC') {
      payload.category = formData.category
      payload.delivery_mode = formData.delivery_mode
      payload.sender.address.area_or_province = formData.sender_area_or_province
      payload.receiver.address.area_or_province =
        formData.receiver_area_or_province
    }

    if (payload.package_insurance === 'PM') {
      payload.insured_value = Number(formData.insured_value)
    }

    if (customer === 'Partners') {
      payload.partner = true
    }

    if (customer === 'E-commerce') {
      payload.shipa_or_ecommerce = true
    }

    const response = await ordersApi.generateQuote(payload)

    if (!response.ok) {
      setLoading(false)
      setIsModalOpen(false)
      setError(true)
      const apiError = parseError(response)
      if (apiError) {
        if (response.data.status_code >= 500) {
          toast(response.data.message || response.data.detail.message, 'error')
        } else {
          toast(
            response.data.error ||
              response.data.error_detail ||
              'Unable to complete order. Check entry details and try again.',
            'error'
          )
        }
      }
      return
    }

    setError(false)
    setQuoteData(response.data[0].payload)

    setLoading(false)
  }

  return (
    <>
      <ReactModal isOpen={isModalOpen} style={customStyles}>
        {loading ? (
          <Loader />
        ) : error ? null : (
          <TempQuote
            data={quoteData}
            onClose={() => setIsModalOpen(false)}
            setLoading={setLoading}
            formData={formData}
            allowCreateOrder={allowCreateOrder}
            customer={customer}
          />
        )}
      </ReactModal>
      <div className='py-10 flex justify-center flex-col'>
        <AppForm onSubmit={handleOnSubmit} padding='0' id='package_form_quote'>
          <div className='px-4 md:px-8 space-y-6'>
            <AppFormField
              title='Declared Value'
              name='itemsValue'
              type='number'
              showNaira='true'
              required
              min='1000'
              max={type === 'IN' ? '200000' : '1000000000'}
              value={formData.packages.itemsValue}
              handleOnChange={(val, name) =>
                handleFieldChange(val, name, 'itemsValue')
              }
            />

            <div className='gap-6 flex items-center'>
              <AppFormSelectField
                name='tpl_service'
                title='Carrier'
                required
                handleOnChange={handleFieldChange}
                value={formData.tpl_service}
                disabled={formData.type === 'LC'}
              >
                <option value=''>Select</option>
                {formData.type === 'LC' ? (
                  <option value='AAJ'>AAJ Express</option>
                ) : fishShipment ? (
                  <>
                    <option value='DHL'>DHL</option>
                    <option value='AMX'>
                      Aramex Standard (Food To US/UK/Canada)
                    </option>
                  </>
                ) : (
                  <>
                    <option value='DHL'>Express by DHL</option>
                    <option value='FIE'>Economy by FedEx</option>
                    <option value='FIP' disabled>
                      Priority by FedEx
                    </option>
                    <option value='UPS'>Economy by UPS</option>
                    <option value='AMX'>
                      Aramex Standard (Food To US/UK/Canada)
                    </option>
                  </>
                )}
              </AppFormSelectField>
            </div>

            {formData.type === 'LC' && (
              <AppFormSelectField
                title='Category'
                name='category'
                value={formData.category}
                handleOnChange={handleFieldChange}
                required
              >
                <option value=''>Select</option>
                {categories.map((category, id) => (
                  <option
                    key={id}
                    value={category.id}
                    disabled={category.id === 3}
                  >
                    {category.name}
                  </option>
                ))}
              </AppFormSelectField>
            )}

            <AppFormSelectField
              title='Package Insurance'
              name='package_insurance'
              value={formData.package_insurance}
              handleOnChange={handleFieldChange}
              required
              disabled={formData.type === 'LC'}
            >
              <option value=''>Select</option>
              {formData.type === 'LC' ? (
                <>
                  <option value='EI'>Electronics Insurance</option>
                  <option value='NE'>Non Electronics Insurance</option>
                  <option value='HI'>Haulage Insurance</option>
                </>
              ) : (
                <>
                  <option value='FR'>Free</option>
                  <option value='SD'>Standard</option>
                  <option value='PM'>Premium</option>
                </>
              )}
            </AppFormSelectField>

            {formData.package_insurance === 'PM' && (
              <AppFormField
                name='insured_value'
                title='Insured Value'
                type='number'
                showNaira
                value={formData.insured_value}
                handleOnChange={handleFieldChange}
                required
              />
            )}

            {formData.type === 'LC' && (
              <AppFormSelectField
                title='Delivery Mode'
                name='delivery_mode'
                value={formData.delivery_mode}
                handleOnChange={handleFieldChange}
                required
              >
                <option value=''>Select</option>
                <option value='DOOR_STEP'>DOORSTEP</option>
                <option value='PICKUP'>PICKUP</option>
              </AppFormSelectField>
            )}
          </div>

          <div className='flex flex-col md:flex-row gap-6 px-4 md:px-8 py-10'>
            <div className='w-full'>
              <h4 className='mb-4 text-xl'>Origin</h4>
              <div className='space-y-6 w-full'>
                {/* SENDER COUNTRY */}
                <AppFormSelectField
                  name='sender_country_code'
                  title='Country'
                  handleOnChange={handleFieldChange}
                  value={formData.sender_country_code}
                  required
                  disabled
                >
                  {Country.getAllCountries().map((country, index) => (
                    <option
                      key={index}
                      value={country.isoCode}
                      disabled={
                        formData.receiver_country_code === country.isoCode
                      }
                    >
                      {country.name}
                    </option>
                  ))}
                </AppFormSelectField>

                <div>
                  {stateNotListed.sender ? (
                    <AppFormField
                      name='sender_state'
                      title='State'
                      handleOnChange={handleFieldChange}
                      value={formData.sender_state}
                      required
                    />
                  ) : (
                    <AppFormSelectField
                      name='sender_state'
                      title='State'
                      handleOnChange={handleFieldChange}
                      value={formData.sender_state}
                      required
                    >
                      {senderStates.map((state, index) => (
                        <option key={index} value={state.isoCode}>
                          {capitalizeFirstLetter(state.name)}
                        </option>
                      ))}
                    </AppFormSelectField>
                  )}
                  {type === 'IN' && (
                    <button
                      className='text-info hover:text-secondary transition-all text-sm pt-2 mt-1'
                      onClick={() =>
                        setStateNotListed(state => ({
                          ...state,
                          sender: !stateNotListed.sender
                        }))
                      }
                      type='button'
                    >
                      {stateNotListed.sender
                        ? 'Select State'
                        : 'State not listed?'}
                    </button>
                  )}
                </div>
                <div>
                  {cityNotListed.sender ? (
                    <AppFormField
                      name='sender_city'
                      title='City'
                      handleOnChange={handleFieldChange}
                      value={formData.sender_city}
                      required
                    />
                  ) : (
                    <AppFormSelectField
                      name='sender_city'
                      title='City'
                      handleOnChange={handleFieldChange}
                      value={formData.sender_city}
                      required
                    >
                      {senderCities.map((city, index) => (
                        <option key={index} value={city.name}>
                          {capitalizeFirstLetter(city.name)}
                        </option>
                      ))}
                    </AppFormSelectField>
                  )}
                  {type === 'IN' && (
                    <button
                      className='text-info hover:text-secondary transition-all text-sm pt-2 mt-1'
                      onClick={() =>
                        setCityNotListed(state => ({
                          ...state,
                          sender: !cityNotListed.sender
                        }))
                      }
                      type='button'
                    >
                      {cityNotListed.sender
                        ? 'Select City'
                        : 'City not listed?'}
                    </button>
                  )}
                </div>

                {type === 'LC' && (
                  <AppFormSelectField
                    name='sender_area_or_province'
                    title='Province or Area'
                    handleOnChange={handleFieldChange}
                    value={formData.sender_area_or_province}
                    required
                  >
                    {senderProvinces.map((province, index) => (
                      <option key={index} value={province}>
                        {capitalizeFirstLetter(province)}
                      </option>
                    ))}
                  </AppFormSelectField>
                )}
              </div>
            </div>

            {/* DIVIDER */}
            <div className='hidden md:divider md:divider-horizontal' />

            {/* RECEIVER COUNTRY */}
            <div className='w-full'>
              <h4 className='mb-4 text-xl'>Destination</h4>
              <div className='space-y-6'>
                <AppFormSelectField
                  name='receiver_country_code'
                  title='Country'
                  handleOnChange={handleFieldChange}
                  value={formData.receiver_country_code}
                  disabled={formData.type === 'LC'}
                  required
                >
                  {Country.getAllCountries().map((country, index) => (
                    <option
                      key={index}
                      value={country.isoCode}
                      disabled={
                        formData.sender_country_code === country.isoCode
                      }
                    >
                      {country.name}
                    </option>
                  ))}
                </AppFormSelectField>

                <div>
                  {stateNotListed.receiver ? (
                    <AppFormField
                      name='receiver_state'
                      title='State'
                      handleOnChange={handleFieldChange}
                      value={formData.receiver_state}
                      required
                    />
                  ) : (
                    <AppFormSelectField
                      name='receiver_state'
                      title='State'
                      handleOnChange={handleFieldChange}
                      value={formData.receiver_state}
                      required
                    >
                      {receiverStates.map((state, index) => (
                        <option key={index} value={state.isoCode}>
                          {capitalizeFirstLetter(state.name)}
                        </option>
                      ))}
                    </AppFormSelectField>
                  )}
                  {type === 'IN' && (
                    <button
                      className='text-info hover:text-secondary transition-all text-sm pt-2 mt-1'
                      onClick={() =>
                        setStateNotListed(state => ({
                          ...state,
                          receiver: !stateNotListed.receiver
                        }))
                      }
                      type='button'
                    >
                      {stateNotListed.receiver
                        ? 'Select State'
                        : 'State not listed?'}
                    </button>
                  )}
                </div>

                <div>
                  {cityNotListed.receiver ? (
                    <AppFormField
                      name='receiver_city'
                      title='City'
                      handleOnChange={handleFieldChange}
                      value={formData.receiver_city}
                      required
                    />
                  ) : (
                    <AppFormSelectField
                      name='receiver_city'
                      title='City'
                      handleOnChange={handleFieldChange}
                      value={formData.receiver_city}
                      required
                    >
                      {receiverCities.map((city, index) => (
                        <option key={index} value={city.name}>
                          {capitalizeFirstLetter(city.name)}
                        </option>
                      ))}
                    </AppFormSelectField>
                  )}
                  {type === 'IN' && (
                    <button
                      className='text-info hover:text-secondary transition-all text-sm pt-2 mt-1'
                      onClick={() =>
                        setCityNotListed(state => ({
                          ...state,
                          receiver: !cityNotListed.receiver
                        }))
                      }
                      type='button'
                    >
                      {cityNotListed.receiver
                        ? 'Select City'
                        : 'City not listed?'}
                    </button>
                  )}
                </div>

                {type === 'LC' && (
                  <AppFormSelectField
                    name='receiver_area_or_province'
                    title='Province or Area'
                    handleOnChange={handleFieldChange}
                    value={formData.receiver_area_or_province}
                    required
                  >
                    {receiverProvinces.map((province, index) => (
                      <option key={index} value={province}>
                        {capitalizeFirstLetter(province)}
                      </option>
                    ))}
                  </AppFormSelectField>
                )}
              </div>
            </div>
          </div>

          <div className='flex items-center justify-between bg-secondary px-4 md:px-8 py-6 mt-10 w-full'>
            <h3 className='text-white'>Packages</h3>
            <button
              onClick={addPackage}
              type='button'
              className='btn btn-sm btn-ghost text-white gap-2 items-center'
            >
              <IoMdAdd size={18} /> <span className='hidden md:block'>Add</span>
            </button>
          </div>

          {formData.packages.packages.map((packageItem, index) => (
            <div
              key={index}
              className={`flex flex-col ${
                index === formData.packages.packages.length - 1
                  ? 'pb-10 packages--last-item'
                  : ''
              }`}
            >
              <div className='flex flex-row gap-2 justify-between items-start sm:items-center px-4 md:px-8'>
                <h3>Item ({index + 1})</h3>
                <div className='gap-4 flex items-center justify-end'>
                  {index === formData.packages.packages.length - 1 &&
                    formData.packages.packages.length !== 1 && (
                      <button
                        onClick={addPackage}
                        type='button'
                        className='btn btn-sm gap-2 items-center'
                      >
                        <IoMdAdd size={18} color='black' />{' '}
                        <span className='hidden md:block'>Add</span>
                      </button>
                    )}
                  {index !== 0 && (
                    <button
                      onClick={() => removePackage(index)}
                      type='button'
                      className='btn btn-sm gap-2 items-center'
                    >
                      <IoMdClose size={18} color='black' />{' '}
                      <span className='hidden md:block'>Remove</span>
                    </button>
                  )}
                </div>
              </div>
              <div className='flex flex-col md:flex-row items-start gap-6 w-full px-4 md:px-8'>
                <div className='w-full'>
                  <AppFormField
                    name='actualWeight'
                    title='Actual Weight (kg)'
                    type='number'
                    step='0.01'
                    min='0.01'
                    value={packageItem.actualWeight}
                    handleOnChange={(val, name) =>
                      handleFieldChange(val, name, 'packages', index)
                    }
                    required
                  />
                </div>
                <div className='w-full'>
                  <label className='label'>
                    <span className='label-text'>Packaging</span>
                  </label>
                  <SelectPackaging
                    value={`${
                      packageItem.predefinedDimension
                        ? packageItem.predefinedDimension
                        : packageItem.packageDimension?.length &&
                          packageItem.packageDimension?.width &&
                          packageItem.packageDimension?.height &&
                          packageItem.packageDimension?.weight
                        ? 'custom'
                        : ''
                    }`}
                    onSelectPredefined={val =>
                      handleFieldChange(
                        val,
                        'predefinedDimension',
                        'packages',
                        index
                      )
                    }
                    dimension={packageItem.packageDimension}
                    handlePackageDimension={dimension =>
                      handlePackageDimension(dimension, index)
                    }
                    shipmentType={type}
                  />
                  <p
                    className={`packaging-error text-red-500 text-sm ${
                      packagingErrors?.find(pkgId => Number(pkgId) === index)
                        ? 'block'
                        : 'hidden'
                    }`}
                  >
                    Select a packaging
                  </p>
                </div>
              </div>
            </div>
          ))}

          <div className='flex items-center justify-between bg-secondary px-4 md:px-8 py-6 mt-10 w-full'>
            <h3 className='text-white'>Add Ons</h3>

            <button
              onClick={addAddOn}
              type='button'
              className='btn btn-sm btn-ghost text-white gap-2 items-center'
            >
              <IoMdAdd size={18} /> <span className='hidden md:block'>Add</span>
            </button>
          </div>

          {formData.packages.addOns.map((addOn, index) => (
            <div
              key={index}
              className={`pb-10 ${
                index === formData.packages.addOns.length - 1
                  ? 'addons--last-item'
                  : ''
              }`}
            >
              <div className='flex flex-row gap-2 justify-between items-start sm:items-center px-4 md:px-8'>
                <h3>Add Ons ({index + 1})</h3>
                <div className='gap-4 flex items-center justify-end'>
                  {index === formData.packages.addOns.length - 1 &&
                    formData.packages.addOns.length !== 1 && (
                      <button
                        onClick={addAddOn}
                        type='button'
                        className='btn btn-sm gap-2 items-center'
                      >
                        <IoMdAdd size={18} color='black' />{' '}
                        <span className='hidden md:block'>Add</span>
                      </button>
                    )}
                  <button
                    onClick={() => removeAddOn(index)}
                    type='button'
                    className='btn btn-sm gap-2 items-center'
                  >
                    <IoMdClose size={18} color='black' />{' '}
                    <span className='hidden md:block'>Remove</span>
                  </button>
                </div>
              </div>
              <div className='flex flex-col md:flex-row gap-6 items-center mt-3 w-full px-4 md:px-8'>
                <AppFormSelectField
                  name='id'
                  title='Add On'
                  value={addOn.id}
                  handleOnChange={(val, name) =>
                    handleFieldChange(val, name, 'addOns', index)
                  }
                  required
                >
                  {addons.map((addon, index) => {
                    const alreadyExists = !!formData.packages.addOns.find(
                      item => item.id === addon.id
                    )

                    return (
                      <option
                        key={index}
                        value={addon.id}
                        disabled={
                          (!fishShipment &&
                            addon.name.toLowerCase().includes('fish')) ||
                          alreadyExists
                        }
                      >
                        {addon.name}
                      </option>
                    )
                  })}
                </AppFormSelectField>
                <AppFormField
                  name='quantity'
                  title='Quantity'
                  type='number'
                  min='1'
                  step='1'
                  value={addOn.quantity}
                  handleOnChange={(val, name) =>
                    handleFieldChange(val, name, 'addOns', index)
                  }
                  required
                />
              </div>
            </div>
          ))}

          <div
            className={`${
              formData.packages.addOns.length ? '' : 'pt-4'
            } flex flex-col`}
          >
            <button
              type='submit'
              className='btn btn-lg text-white btn-primary mx-8'
              form='package_form_quote'
            >
              Get Quote
            </button>
          </div>
        </AppForm>
      </div>
    </>
  )
}

export default PackageFormQuote
