import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import shipmentsApi from '../api/shipments'
import { parseError, urlQueryStringToObject } from '../utils'

const initialState = {
  data: null,
  meta: {
    previous: 0,
    page: 1,
    next: 0
  }
}

export const fetchShipments = createAsyncThunk(
  'shipments/fetch',
  async (params = {}, config = {}) => {
    const query = Object.assign({ page_size: 50, page: 1 }, params)

    const response = await shipmentsApi.getShipments(query, config)

    if (!response.ok) {
      return { type: 'error', error: parseError(response) }
    }

    return {
      type: 'success',
      response_data: response.data,
      query_params: query
    }
  }
)

const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchShipments.fulfilled, (state, action) => {
      if (action.payload.type === 'success') {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.meta = Object.assign({}, state.meta, meta)
        state.data = action.payload?.response_data?.payload
      }
    })
    builder.addCase(fetchShipments.rejected, (state, action) => {})
  }
})

export const shipmentsActions = shipmentsSlice.actions

export default shipmentsSlice.reducer
