import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import useToast from "../../../hooks/useToast";
import { ReactComponent as Times } from "../../../assets/times.svg";
import manifestApi from "../../../api/manifest";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "#0000004f",
    zIndex: 60,
  },
};

export const RaiseExceptionModal = ({
  openModal,
  setOpenModal,
  waybillData,
  type,
  location,
  hub,
  manifestData,
  removeShipment,
}) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.exceptions?.dataExceptions);
  const toast = useToast();
  const [code, setCode] = useState("");
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shipmentWaybill, setShipmentWaybill] = useState("");
  const [disabled, setDisabled] = useState(true);

  const dataBody = {
    scan_type: "EXCEPTION_SCAN",
    object_type: type,
    manifest_id: manifestData,
    meta: {
      location: location,
      hub: hub,
    },
    exception: +id,
  };

  const shipmentBody = {
    scan_type: "ORIGIN_SCAN",
    object_type: type,
    shipment_id: Array.isArray(waybillData) ? shipmentWaybill : waybillData,
    meta: {
      location: location,
      hub: hub,
    },
  };

  const handleException = async () => {
    setLoading(true);
    setDisabled(true);
    let result = null;
    if (type === "MANIFEST") {
      result = await manifestApi.sendException(dataBody);
    } else {
      result = await manifestApi.sendShipmentException(shipmentBody);
    }

    if (result.ok) {
      toast("Exception raised!", "success");
      setLoading(false);
      if (type !== "MANIFEST") {
        removeShipment();
      }
    }

    if (!result.ok) {
      setLoading(false);
      toast(result?.problem, "error");
    }
  };

  useEffect(() => {
    if (id !== null) {
      const getCode = data?.filter((item) => item?.id === +id);
      setCode(getCode[0]?.code);
    }
  }, [id]);

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={setOpenModal}
        style={customStyles}
      >
        <div className="container w-[700px] h-[500px] overflow-y-scroll">
          <div
            className="flex justify-center items-center w-7 h-7 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer"
            onClick={() => {
              setCode("");
              setOpenModal(false);
            }}
          >
            <Times />
          </div>
          <div className="w-full flex justify-center items-center text-xl font-semibold">
            Raise Exception
          </div>

          <div className="w-full bg-[#F5F5F5] px-8 py-3 mt-2">
            <div>Shipment details</div>
            <div className="w-full flex justify-between mt-6 pb-12">
              {type === "SHIPMENT" ? (
                <div className="w-[50%]">
                  <div>Waybill Number</div>
                  {Array.isArray(waybillData) ? (
                    <select
                      name="period"
                      className="w-[90%] h-[40px] mt-4 border-[#BDBDBD] rounded-lg"
                      onChange={(e) => {
                        setShipmentWaybill(e.target.value);
                      }}
                    >
                      <option>select waybill Id</option>
                      {waybillData?.map((item, id) => (
                        <option value={item} key={id}>
                          {item}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="flex justify-start items-center pl-3 bg-white w-[90%] border-[#BDBDBD] border-[1px] h-[40px] rounded-lg mt-4">
                      {type === "SHIPMENT" && waybillData?.toUpperCase()}
                    </div>
                  )}
                </div>
              ) : (
                <div className="w-[50%]">
                  <div>Manifest ID</div>
                  <div className="flex justify-start items-center pl-3 bg-white w-[90%] border-[#BDBDBD] border-[1px] h-[40px] rounded-lg mt-4">
                    {type === "MANIFEST" && manifestData?.toUpperCase()}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* start of container two */}

          <div className="w-full bg-[#F5F5F5] px-8 py-3 mt-4 pb-24">
            <div className="w-full flex justify-between mt-6 pb-4">
              <div className="w-[50%]">
                <div className="font-semibold">Reason of Exception</div>
                <select
                  name="period"
                  className="w-[90%] h-[40px] mt-4 border-[#BDBDBD] rounded-lg"
                  onChange={(e) => {
                    setId(e.target.value);
                    setDisabled(false);
                  }}
                >
                  <option>Select Exception</option>
                  {data?.map((item, id) => (
                    <option value={item?.id} key={id}>
                      {item.message}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-[50%]">
                <div className="font-semibold">Exception code</div>
                <div className="flex justify-start items-center pl-3 bg-white w-[90%] border-[#BDBDBD] border-[1px] h-[40px] rounded-lg mt-4">
                  {code}
                </div>
              </div>
            </div>
            {/* <div className="font-semibold">
              Attachments <span className="font-normal">(optional)</span>
            </div>
            <div className=" bg-white w-[45%] border-[#BDBDBD] border-[1px] rounded-lg mt-4 p-2">
              <input type="file" accept="image/png, image/jpeg" />
            </div> */}
          </div>

          {/* <div className="w-full flex justify-end mt-6">
            <span
              className="flex space-x-2 justify-center items-center w-[150px] h-[50px] bg-main-primary rounded-[100px] cursor-pointer"
              onClick={handleException}
            >
              <p className="text-white text-xs">
                {loading === true ? "Loading..." : "Submit"}
              </p>
            </span>
          </div> */}

          <div className="w-full flex justify-end mt-12">
            <button
              className={`w-[150px] h-[50px] btn ${
                disabled ? "btn-neutral btn-disabled" : "btn-primary"
              } btn-rounded`}
              disabled={disabled}
              onClick={() => {
                handleException();
              }}
            >
              {loading === true ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
