import { IoMdAdd } from 'react-icons/io'
import OrderPackage from './OrderPackage'
import { FieldArray, useFormikContext } from 'formik'
import { scrollToTarget } from '../../utils'
import { useMemo } from 'react'

export default function OrderPackages ({
  container,
  shipmentType,
  defaultItemsOpen = true
}) {
  const { values, errors } = useFormikContext()

  const defaultPackage = {
    unitMeasurement: 'KGS',
    actualWeight: '',
    predefinedDimension: ''
  }

  const totalWeight = useMemo(() => {
    return values.packages.reduce((acc, curr) => {
      return acc + Number(curr.actualWeight)
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values.packages)])

  return (
    <section id='packages-section'>
      <FieldArray name='packages'>
        {arrayHelpers => {
          const onAdd = () => {
            arrayHelpers.push(defaultPackage)
            scrollToTarget('.packages--last-item', container)
          }

          return (
            <div>
              <div className='flex items-center justify-between bg-gray-300 p-2 md:p-4 w-full'>
                <h1 className='font-bold text-xl text-left'>Packages</h1>
                <div className='flex items-center justify-end gap-2'>
                  <span className='text-sm'>
                    {totalWeight.toLocaleString()}kg
                  </span>
                  <button
                    onClick={onAdd}
                    type='button'
                    className='btn btn-sm btn-ghost gap-1 items-center text-dark-primary'
                  >
                    <IoMdAdd size={16} color='#333333' />
                    <span className='hidden md:block'>Add</span>
                  </button>
                </div>
              </div>

              <div className='px-4 md:px-8'>
                {values.packages.map((_package, index) => {
                  const packageErrors = errors.packages
                    ? errors.packages[index]
                    : null

                  const packagingError = packageErrors?.dimension

                  return (
                    <OrderPackage
                      key={index}
                      _package={_package}
                      index={index}
                      isLast={index === values.packages.length - 1}
                      onAdd={onAdd}
                      onRemove={() => arrayHelpers.remove(index)}
                      shipmentType={shipmentType}
                      defaultOpen={defaultItemsOpen || packageErrors}
                      packagingError={packagingError}
                    />
                  )
                })}
              </div>
            </div>
          )
        }}
      </FieldArray>
    </section>
  )
}
