import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BiSearch } from 'react-icons/bi'
import AddressBookItem from '../customers/AddressBookItem'
import { IoMdAdd } from 'react-icons/io'
import 'react-phone-number-input/style.css'
import Loader from '../loader/Loader'
import Pagination from '../globals/pagination/pagination'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCustomers } from '../../slices/customersSlice'
import { HiMapPin } from 'react-icons/hi2'
import ROUTES from '../../constants/routes'
import useNavigateWithParams from '../../hooks/useNavigateWithParams'
import NewCustomer from './NewCustomer'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import CustomerPreview from './CustomerPreview'
import CustomerTabs from './CustomerTabs'
import MultipieceCustomerPrompt from '../orders/newOrder/multipiece/MultipieceCustomerPrompt'
import noDataIcon from '../../assets/no-data.webp'
import { CreateOrderContext } from '../../containers/CreateOrderLayout'

export default function AddressBook () {
  const [customers, setCustomers] = useState([])
  const [tableData, setTableData] = useState([])
  const [activeTab, setActiveTab] = useState({ name: 'Regulars', id: 0 })
  const [searchParams, setSearchParams] = useSearchParams()
  const [isPreviewCustomer, setPreviewCustomer] = useState({
    state: false,
    customer: null
  })
  const [isEditCustomer, setEditCustomer] = useState({
    state: false,
    customer: null
  })
  const [isMultipieceCustomerPrompt, setMultipieceCustomerPrompt] =
    useState(false)

  const quoteId = searchParams.get('qt')
  const customerType = searchParams.get('ctm')
  const multipiece = searchParams.get('mpo')
  const billTo = searchParams.get('bill_to')

  const { layoutContainer } = useContext(AuthLayoutContext)
  const { order, updateOrder, resolvePathname, formatCustomer } =
    useContext(CreateOrderContext)
  const { sender, receiver, type: shipmentType } = order.payload

  const [oldCustomer, setOldCustomer] = useState(true)
  const [showLoader, setShowLoader] = useState(false)
  const [secondPageLoaded, setSecondPageLoaded] = useState(false)
  const [serializedData, setSerializedData] = useState([])

  const customersStore = useSelector(state => state.customers)
  const quotes = useSelector(state => state.quotes)

  const dispatch = useDispatch()

  const selectedCustomer = useMemo(() => {
    if (customerType === 'receiver') {
      return serializedData?.find(
        customer => customer.id === receiver?.customer_id
      )
    }
    if (customerType === 'sender') {
      return serializedData?.find(
        customer => customer.id === sender?.customer_id
      )
    }
  }, [customerType, receiver, sender, serializedData])

  const customerLocation = useMemo(() => {
    if (customerType === 'sender') {
      return 'Nigeria'
    }
    if (customerType === 'receiver') {
      if (quoteId) {
        if (quotes.data) {
          const quote = quotes.data.find(({ id }) => id === Number(quoteId))
          if (quote) {
            return quote.receiver_country.split(',')[0].trim()
          }
        }
      } else if (shipmentType === 'LC') return 'Nigeria'
      else return 'Global'
    }
  }, [customerType, quoteId, quotes.data, shipmentType])

  const validCustomers = useMemo(() => {
    const customers = (() => {
      // eslint-disable-next-line default-case
      switch (activeTab.id) {
        case 0:
          return customersStore.regulars.data
        case 1:
          return customersStore.partners.data
        case 2:
          return customersStore.ecommerce.data
      }
    })()

    if (customerLocation && customerLocation !== 'Global') {
      return customers?.filter(item => item.country === customerLocation)
    }
    if (customerLocation && customerLocation === 'Global') {
      return customers?.filter(item => item.country !== 'Nigeria')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    customersStore.regulars.data,
    customersStore.partners.data,
    customersStore.ecommerce.data,
    customerLocation
  ])

  useEffect(() => {
    if (!customerType) {
      setSearchParams('ctm', 'sender')
    } else if (customerType === 'receiver' && !sender) {
      navigateWithParams({
        pathname: resolvePathname(
          ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path
        ),
        search: {
          ctm: 'sender'
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerType, sender])

  useEffect(() => {
    if (secondPageLoaded && layoutContainer) {
      layoutContainer.scrollTo({ top: 0 })
    }
  }, [layoutContainer, secondPageLoaded])

  useEffect(() => {
    if (validCustomers) {
      setCustomers(validCustomers)
    }
  }, [validCustomers])

  useEffect(() => {
    if (secondPageLoaded) {
      setSecondPageLoaded(false)
    }
  }, [secondPageLoaded])

  const saveCustomer = (customer, data) => {
    const formattedCustomer = formatCustomer(data, 'new')

    updateOrder({
      [customer]: formattedCustomer
    })
  }

  const navigateWithParams = useNavigateWithParams()

  const closeMultipieceCustomerPrompt = () => {
    setMultipieceCustomerPrompt(false)
  }

  const handleOnClickCustomer = item => {
    if (multipiece) {
      if (customerType === billTo) {
        setMultipieceCustomerPrompt(true)
        return
      }
    }

    setPreviewCustomer({ state: true, customer: item })
  }

  const onPreviewCustomerClose = () => {
    setPreviewCustomer({ state: false, customer: null })
  }

  const handleSender = () => {
    setTimeout(() => {
      setShowLoader(false)
      setSecondPageLoaded(true)
      navigateWithParams({
        pathname: resolvePathname(
          ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path
        ),
        search: {
          ctm: 'receiver'
        }
      })
    }, 1000)
  }

  const handleReceiver = () => {
    navigateWithParams({
      pathname: resolvePathname(ROUTES.ORDERS.CREATE_ORDER.PACKAGE_TYPE.path),
      search: {
        ctm: null
      }
    })
  }

  const handleCustomerPreviewProceed = customer => {
    setShowLoader(true)
    onPreviewCustomerClose()

    if (customerType === 'sender') {
      saveCustomer(customerType, customer)
      handleSender()
    }

    if (customerType === 'receiver') {
      saveCustomer(customerType, customer)
      handleReceiver()
    }
  }

  const onEditCustomer = customer => {
    setPreviewCustomer({ state: false, customer: null })
    setEditCustomer({ state: true, customer })
    setOldCustomer(false)
  }

  const handleOnSearch = ({ target }) => {
    const value = target.value
    if (value) {
      const filteredCustomers = validCustomers.filter(item => {
        let results
        if (activeTab === 0) {
          results = item.full_name.toLowerCase().includes(value.toLowerCase())
        } else {
          results =
            item.full_name.toLowerCase().includes(value.toLowerCase()) ||
            item.business_name.toLowerCase.includes(value.toLowerCase())
        }

        return results
      })
      setCustomers(filteredCustomers)
    } else {
      setCustomers(validCustomers)
    }
  }

  const onNewCustomer = customer => {
    dispatch(fetchCustomers())
    saveCustomer(customerType, customer)
    setOldCustomer(true)

    if (customerType === 'sender') {
      setTimeout(() => {
        setSecondPageLoaded(true)
      }, 1000)
    }
  }

  const onCloseNewCustomer = () => {
    setOldCustomer(true)
  }

  const handleMultipieceCustomerPromptProceed = () => {
    closeMultipieceCustomerPrompt()
    setShowLoader(true)

    if (customerType === 'sender') {
      handleSender()
    }

    if (customerType === 'receiver') {
      handleReceiver()
    }
  }

  if (showLoader) return <Loader />

  return (
    <div className='flex flex-col items-center pb-8'>
      {isPreviewCustomer.state && (
        <CustomerPreview
          isOpen={isPreviewCustomer.state}
          onClose={onPreviewCustomerClose}
          customer={isPreviewCustomer.customer}
          type={customerType}
          allowEdit={activeTab.id !== 1}
          onEdit={onEditCustomer}
          onProceed={handleCustomerPreviewProceed}
        />
      )}
      {isMultipieceCustomerPrompt && (
        <MultipieceCustomerPrompt
          isOpen={isMultipieceCustomerPrompt}
          customer={customerType}
          onClose={closeMultipieceCustomerPrompt}
          onProceed={handleMultipieceCustomerPromptProceed}
        />
      )}
      <div className='rounded-lg border md:px-4 w-full max-w-3xl bg-white'>
        <div>
          {oldCustomer ? (
            <div className='text-left flex gap-6 p-4 flex-col'>
              <div id='customers-list'>
                <h1 className='text-[#FF4D00] text-xl mb-4'>
                  Address Books
                  <span className='text-sm text-black inline-flex py-1 px-1.5 bg-neutral rounded-lg ml-3 select-none gap-1'>
                    <HiMapPin size={16} />
                    {customerLocation}
                  </span>
                </h1>

                <div className='flex flex-wrap flex-row justify-between items-center mb-4'>
                  <CustomerTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />

                  <div className='ml-auto'>
                    <button
                      className='p-2 rounded-full md:rounded-lg bg-transparent hover:bg-gray-100 cursor-pointer transition-all flex items-center gap-1 text-primary'
                      onClick={() => setOldCustomer(false)}
                    >
                      <span className='hidden sm:inline-block'>
                        New customer
                      </span>

                      <IoMdAdd size={18} color='#fe6802' />
                    </button>
                  </div>
                </div>

                <div className='form-control w-full sm:w-96'>
                  <div className='input-group'>
                    <input
                      type='text'
                      placeholder={`Search customer by name${
                        activeTab === 1 || activeTab === 2
                          ? ' or business name'
                          : ''
                      }`}
                      className='input w-full'
                      onChange={handleOnSearch}
                      disabled={!validCustomers}
                    />
                    <span>
                      <BiSearch color='#ffffff' size={18} />
                    </span>
                  </div>
                </div>

                <div className='pt-2 flex flex-col'>
                  {!validCustomers ? (
                    <Loader />
                  ) : tableData?.length ? (
                    tableData.map(item => (
                      <AddressBookItem
                        selectedCustomer={selectedCustomer}
                        isSender={sender?.customer_id === item.id}
                        key={item.id}
                        item={item}
                        handleOnClick={handleOnClickCustomer}
                        tab={activeTab}
                      />
                    ))
                  ) : (
                    <div className='py-5 w-full flex flex-col gap-2 items-center justify-center'>
                      <img
                        src={noDataIcon}
                        className='w-40 h-40 object-contain'
                        alt='no data icon'
                      />
                      <p className='no_data_description_text'>
                        No customer found
                      </p>
                    </div>
                  )}
                </div>
                <div className='mt-10'>
                  <Pagination
                    tableId='customers-list'
                    data={customers}
                    setSerializedData={setSerializedData}
                    setTableData={setTableData}
                    reverse={false}
                    findItem={selectedCustomer?.s_n}
                  />
                </div>
              </div>
            </div>
          ) : (
            <NewCustomer
              customerType={customerType}
              shipmentType={shipmentType}
              onNewCustomer={onNewCustomer}
              onCloseNewCustomer={onCloseNewCustomer}
              customer={isEditCustomer.customer}
              isEdit={isEditCustomer.state}
            />
          )}
        </div>
      </div>
    </div>
  )
}
