export const statuses = {
  1: {
    name: 'Pending',
    theme: {
      primary: '#FFC700',
      secondary: '#FFF6D799'
    }
  },
  // 2: {
  //   name: 'Confirmation Pending',
  //   theme: {
  //     primary: '#bf55ec',
  //     secondary: '#bf55ec33'
  //   }
  // },
  3: {
    name: 'Partial Payment',
    theme: {
      primary: '#1d4ed8',
      secondary: '#1d4ed833'
    }
  },
  4: {
    name: 'Paid',
    theme: {
      primary: '#00AB44',
      secondary: '#00AB4433'
    }
  },
  5: {
    name: 'Voided',
    theme: {
      primary: '#DE350B',
      secondary: '#F5CCC299'
    }
  }
}
