import axios from 'axios'
import bookingClient from './clients/bookingClient'

const endpoint = 'shipments/'

const getShipments = async (params, config = {}) => {
  const validParams = {}
  for (const key in params) {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null)
      validParams[key] = params[key]
  }
  const search_params = new URLSearchParams(validParams).toString()

  const response = await bookingClient.get(
    `${endpoint}?${search_params}`,
    config
  )
  return response
}

const readShipment = async id => {
  const response = await bookingClient.get(`${endpoint}${id}`)
  return response
}

const getShipmentByOrder = async orderID => {
  const response = await bookingClient.get(`${endpoint}${orderID}`)
  return response
}

const voidShipment = async shipmentID => {
  const response = await bookingClient.delete(`${endpoint}${shipmentID}/void/`)
  return response
}

const voidShipmentUnrestricted = async shipmentID => {
  const response = await bookingClient.delete(
    `${endpoint}${shipmentID}/void-unrestricted/`
  )
  return response
}

const trackShipment = async shipmentID => {
  const response = await bookingClient.get(`${endpoint}${shipmentID}/track/`)
  return response
}

const trackShipmentWithTrackingID = async trackingID => {
  const response = await bookingClient.get(`${endpoint}${trackingID}/track/`)
  return response
}

const updateShipmentStatus = async (trackingID, body) => {
  const response = await bookingClient.patch(
    `${endpoint}${trackingID}/update_status/`,
    body
  )
  return response
}

const exportShipments = async params => {
  const validParams = {}
  for (const key in params) {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null)
      validParams[key] = params[key]
  }
  const search_params = new URLSearchParams(validParams).toString()

  const response = await bookingClient.get(
    `${endpoint}export/?${search_params}`
  )
  return response
}

const uploadAcknowledgeDocument = async (trackingID, body) => {
  const response = await bookingClient.patch(
    `${endpoint}${trackingID}/upload_acknowledgement_copy/`,
    body
  )
  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getShipments,
  readShipment,
  getShipmentByOrder,
  voidShipment,
  voidShipmentUnrestricted,
  trackShipment,
  trackShipmentWithTrackingID,
  updateShipmentStatus,
  exportShipments,
  uploadAcknowledgeDocument
}
