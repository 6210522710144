export default function TrackingIcon ({ variant = 'secondary', ...restProps }) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 3.3125C10.3418 3.3125 6.5625 6.90957 6.5625 11.3398C6.5625 16.4375 12.1875 24.5158 14.2529 27.3061C14.3387 27.4238 14.451 27.5197 14.5809 27.5858C14.7107 27.6518 14.8543 27.6863 15 27.6863C15.1457 27.6863 15.2893 27.6518 15.4191 27.5858C15.549 27.5197 15.6613 27.4238 15.7471 27.3061C17.8125 24.517 23.4375 16.4416 23.4375 11.3398C23.4375 6.90957 19.6582 3.3125 15 3.3125Z'
        stroke={
          variant === 'primary'
            ? '#fe6802'
            : variant === 'secondary'
            ? '#2E3034'
            : `${variant}`
        }
        stroke-width='1.875'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15 14.5625C16.5533 14.5625 17.8125 13.3033 17.8125 11.75C17.8125 10.1967 16.5533 8.9375 15 8.9375C13.4467 8.9375 12.1875 10.1967 12.1875 11.75C12.1875 13.3033 13.4467 14.5625 15 14.5625Z'
        stroke={
          variant === 'primary'
            ? '#fe6802'
            : variant === 'secondary'
            ? '#2E3034'
            : `${variant}`
        }
        stroke-width='1.875'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
