import React from 'react'
import { getDate } from '../../utils'
import { Link } from 'react-router-dom'
import ROUTES from '../../constants/routes'

const Receiptlist = ({ item, handleOnClick }) => {
  return (
    <tr onClick={handleOnClick}>
      <td>{item.s_n}</td>
      <td>{item.name}</td>
      <td>
        <Link
          onClick={e => e.stopPropagation()}
          to={`${ROUTES.INVOICES.path}?id=${item.invoice}`}
          className='hover:text-primary hover:underline'
        >
          #{item.invoice}
        </Link>
      </td>
      <td>
        <Link
          to={`${ROUTES.ORDERS.path}?id=${item.shipment}`}
          onClick={e => e.stopPropagation()}
          className='hover:text-primary hover:underline'
        >
          #{item.shipment}
        </Link>
      </td>
      <td>
        <Link
          to={`${ROUTES.TRACK.path}?track_id=${item.tracking_no}`}
          onClick={e => e.stopPropagation()}
          className='hover:underline hover:text-primary'
        >
          {item.tracking_no}
        </Link>
      </td>
      <td>{getDate(item.date_time)}</td>
      <td>{item.customer}</td>
      <td>{item.weight} kg</td>
      <td>
        {item.amount_paid.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN'
        })}
      </td>
    </tr>
  )
}

export default Receiptlist
