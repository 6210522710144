import { IoMdAdd, IoMdClose } from 'react-icons/io'
import AppFormSelectField from '../globals/Form/AppFormSelectField'
import AppFormField from '../globals/Form/AppFormField'
import { useSelector } from 'react-redux'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition
} from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import { MdExpandMore } from 'react-icons/md'
import { useFormikContext } from 'formik'

export default function OrderAddOn ({
  index,
  isLast,
  onAdd,
  onRemove,
  defaultOpen = true,
  isFish = false
}) {
  const { values } = useFormikContext()

  const addonsStore = useSelector(state => state.addons)

  const handleRemove = e => {
    e.stopPropagation()
    onRemove(index)
  }

  return (
    <Disclosure
      as='div'
      defaultOpen={defaultOpen}
      key={index}
      className={twMerge(
        'py-3 border-b border-gray-100',
        isLast ? 'addons--last-item' : ''
      )}
    >
      {({ open }) => {
        return (
          <>
            <DisclosureButton
              data-id={index}
              data-open={open}
              className='w-full'
            >
              <div className='flex flex-row gap-2 justify-between items-start sm:items-center'>
                <h3>Add Ons ({index + 1})</h3>

                <div className='flex items-center gap-2'>
                  {isLast && index !== 0 && (
                    <button
                      onClick={onAdd}
                      type='button'
                      className='btn btn-sm gap-1 items-center text-dark-primary'
                    >
                      <IoMdAdd size={16} color='#333333' />
                      <span className='hidden md:block'>Add</span>
                    </button>
                  )}
                  <button
                    onClick={handleRemove}
                    type='button'
                    className='btn btn-sm gap-1 items-center text-dark-primary'
                  >
                    <IoMdClose size={16} color='#333333' />
                    <span className='hidden md:block'>Remove</span>
                  </button>
                  <div>
                    <MdExpandMore size={18} color='#333333' />
                  </div>
                </div>
              </div>
            </DisclosureButton>
            <Transition
              enter='transition origin-top duration-150 ease-out'
              enterFrom='transform scale-y-0 opacity-0'
              enterTo='transform scale-y-100 opacity-100'
              leave='transition origin-top duration-100 ease-out'
              leaveFrom='transform scale-y-100 opacity-100'
              leaveTo='transform scale-y-0 opacity-0'
            >
              <DisclosurePanel
                as='div'
                className='flex flex-col md:flex-row gap-2 w-full'
              >
                <div className='w-full'>
                  <AppFormSelectField
                    name={`addOns.${index}.id`}
                    title='Add On'
                  >
                    <option value=''>Select</option>
                    {addonsStore.data.map(addon => {
                      const alreadyExists = !!values.addOns.find(
                        item => item.id === addon.id
                      )
                      return (
                        <option
                          key={addon.id}
                          value={`${addon.id}`}
                          disabled={
                            (!isFish &&
                              addon.name.toLowerCase().includes('fish')) ||
                            alreadyExists
                          }
                        >
                          {addon.name}
                        </option>
                      )
                    })}
                  </AppFormSelectField>
                </div>
                <div className='w-full'>
                  <AppFormField
                    name={`addOns.${index}.quantity`}
                    title='Quantity'
                    type='number'
                    min='1'
                    step='1'
                  />
                </div>
              </DisclosurePanel>
            </Transition>
          </>
        )
      }}
    </Disclosure>
  )
}
