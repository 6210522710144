export const Left = ({ onPrev, disabled, tableId }) => {
  return (
    <button
      aria-controls={tableId}
      aria-describedby='previous page'
      id='pagination-prev'
      tabIndex={0}
      onClick={onPrev}
      aria-disabled={disabled}
      disabled={disabled}
      className={`p-2 ${disabled ? 'opacity-40' : ''}`}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          d='M10 13L5 8L10 3'
          stroke='#031425'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </button>
  )
}

export const Right = ({ onNext, disabled, tableId }) => {
  return (
    <button
      aria-describedby='next page'
      aria-controls={tableId}
      id='pagination-next'
      tabIndex={0}
      onClick={onNext}
      disabled={disabled}
      aria-disabled={disabled}
      className={`p-2 ${disabled ? 'opacity-40' : ''}`}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          d='M6 3L11 8L6 13'
          stroke='#031425'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </button>
  )
}
