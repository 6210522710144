import { MdOutlineFileDownload } from "react-icons/md";
import { ShipmentsFilter } from "../../components";
import FilterButton from "../../components/globals/filter/FilterButton";
import Search from "../../components/globals/Search/Search";
import Page from "../../containers/Page";
import Tabs from "../../components/globals/Tabs";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import FilterTag from "../../components/globals/filter/FilterTag";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalizeFirstLetter,
  exportCSVData,
  getDate,
  getStateOfCountry,
  parseError,
} from "../../utils";
import Loader from "../../components/loader/Loader";
import noDataIcon from "../../assets/no-data.webp";
import Pagination from "../../components/globals/pagination/ServerPagination";
import ShipmentDetail from "../../components/shipments/ShipmentDetail";
import { fetchShipments } from "../../slices/shipmentsSlice";
import { fetchBranches } from "../../slices/orgsSlice";
import { Link, useSearchParams } from "react-router-dom";
import ROUTES from "../../constants/routes";
import SearchResultsDescription from "../../components/globals/Search/SearchResultsDescription";
import { resolveDateParams } from "../../helpers/queryByDate";
import { statuses } from "../../fixtures/shipmentsStatus";
import { AuthLayoutContext } from "../../containers/AuthLayout";
import Pill from "../../components/globals/Pill";
import { CircularProgress } from "@mui/material";
import shipmentsApi from "../../api/shipments";
import useToast from "../../hooks/useToast";
import ExportDialogue from "../../components/globals/export/ExportDialogue";
import ShipmentRaiseException from "../../components/shipments/ShipmentRaiseException";
import { ExceptionModal } from "../../components/shipments/ExceptionModal";

const tabs = [
  {
    name: "International",
    value: "IN",
  },
  {
    name: "Local",
    value: "LC",
  },
];

export default function Shipments({ metaTitle }) {
  const { userRole } = useContext(AuthLayoutContext);
  const [searchParams] = useSearchParams();
  const statusQuery = searchParams.get("status") ?? "";
  const userData = useSelector(state => state.auth.user)
  const shipmentsStore = useSelector((state) => state.shipments);
  const [raiseModal, setRaiseModal] = useState(false);
  const [searchBy, setSearchBy] = useState("tracking_id");
  const [searchValue, setSearchValue] = useState("");
  const [serverSearch, setServerSearch] = useState(null);
  const [serializedData, setSerializedData] = useState(null);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [filterTags, setFilterTags] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeShipment, setActiveShipment] = useState();
  const [isExportOpen, setExportOpen] = useState(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50,
    order_type: activeTab.value,
    status: statusQuery,
    tracking_id: "",
    shipment_tracking_number: "",
    branch: "",
  });
  const [filter, setFilter] = useState({
    package_type: "",
    category: "",
    date: "",
    branch: "",
    status: statusQuery,
    shipment_type: "",
  });
  const [isExportLoading, setExportLoading] = useState(false);

  const initializeDateRange = () => {
    const now = new Date();
    const prevMonth = new Date();
    prevMonth.setMonth(now.getMonth() - 1);
    const endDate = dayjs(
      `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
    );
    const startDate = dayjs(
      `${prevMonth.getFullYear()}-${
        prevMonth.getMonth() + 1
      }-${prevMonth.getDate()}`
    );
    return [startDate, endDate];
  };
  const [dateRange, setDateRange] = useState(initializeDateRange());
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch()
  const toast = useToast()

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const loadShipments = useCallback(() => {
    const promise = dispatch(fetchShipments(queryParams))

    return () => {
      promise.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    if (userRole?.domain.index > 0) {
      dispatch(fetchBranches());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole?.domain.index]);

  useEffect(() => {
    const params = {};
    const tags = [];

    for (const key in filter) {
      if (filter[key]) {
        if (key === "date") {
          let tag = { name: key, value: "" };
          const { start_date, end_date } = resolveDateParams(
            filter.date,
            dateRange
          );
          params.start_date = start_date;
          params.end_date = end_date;
          if (filter.date === "range") {
            tag.value = `From: ${dateRange[0]
              .format()
              .slice(0, 10)}, To: ${dateRange[1].format().slice(0, 10)}`;
          } else {
            tag.value = capitalizeFirstLetter(filter[key].replaceAll("_", " "));
          }
          tags.push(tag);
        } else if (key === "status") {
          params.status = filter[key];
          let tag = { name: key, value: statuses[filter[key]]?.name };
          tags.push(tag);
        } else if (key === "package_type") {
          let tag = { name: key, value: "" };
          const packages = {
            regular: "Regular",
            document: "Document",
            "fish/snail": "Fish & Snail",
          };
          params.package_type = filter[key];
          tag.value = packages[filter[key]];
          tags.push(tag);
        } else if (key === "category") {
          let tag = { name: key, value: "" };
          const categories = {
            1: "Electronics",
            2: "Non-Eletronics",
            3: "Haulage",
          };
          tag.value = categories[filter[key]];
          params.category = filter[key];
          tags.push(tag);
        } else if (key === "branch") {
          let tag = { name: key, value: filter[key] };

          tags.push(tag);
          params.branch = filter[key];
        } else if (key === "shipment_type") {
          let tag = { name: "Carrier", value: filter[key] };
          tags.push(tag);
          params.shipment_type = filter[key];
        }
      }
    }

    const query = { ...queryParams, ...params };
    query.page = 1;

    setQueryParams(query);

    setFilterTags(tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, filter]);

  useEffect(() => {
    setSerializedData(null)
    const abortRequest = loadShipments()
    return () => {
      if (abortRequest) abortRequest()
    }
  }, [loadShipments])

  const showFilter = () => {
    setIsFilterOpen(true);
  };

  const closeFilter = () => {
    setIsFilterOpen(false);
  };

  const onFilterDelete = (key) => {
    setFilter((state) => ({
      ...state,
      [key]: "",
    }));
    let query = { ...queryParams };
    if (key === "date") {
      delete query.start_date;
      if (filter[key] !== "today") delete query.end_date;
      if (filter[key] === "range") setDateRange(initializeDateRange());
    } else delete query[key];

    setQueryParams(query);
  };

  const onPage = (params) => {
    setSerializedData(null);
    setQueryParams((state) => ({ ...state, ...params }));
  };

  const onSelectTab = (item) => {
    setQueryParams((state) => ({ ...state, order_type: item.value }));
    setActiveTab(item);
  };

  const searchOptions = useMemo(() => {
    const options = [
      { name: "Tracking ID", value: "tracking_id" },
      { name: "Order ID", value: "order" },
      {
        name: "Receiver's name",
        value: "receiver_name",
      },
    ];

    if (activeTab.value === "IN") {
      options.splice(1, 0, {
        name: "Carrier Tracking No",
        value: "shipment_tracking_number",
      });
    }

    return options;
  }, [activeTab]);

  const searchInputPlacehHolder = useMemo(() => {
    return searchBy === "tracking_id"
      ? "Enter tracking ID"
      : searchBy === "shipment_tracking_number"
      ? "Enter Carrier tracking ID"
      : searchBy === "receiver_name"
      ? "Enter receiver's name"
      : "Enter order ID";
  }, [searchBy]);

  const shipments = useMemo(() => {
    if (serializedData) {
      if (searchValue) {
        const results = serializedData.filter((shipment) => {
          const queries = {
            search: {
              apply: !!searchValue,
              match() {
                // eslint-disable-next-line default-case
                switch (searchBy) {
                  case "tracking_id":
                    return shipment.tracking_id
                      .toLowerCase()
                      .includes(searchValue.toLowerCase());
                  case "shipment_tracking_number":
                    return shipment.shipment_tracking_number
                      .toLowerCase()
                      .includes(searchValue.toLowerCase());
                  case "receiver_name":
                    return shipment.meta.receiver_name
                      .toLowerCase()
                      .includes(searchValue.toLowerCase());
                  case "order":
                    return shipment.order
                      .toString()
                      .includes(searchValue.toLowerCase().replace("#", ""));
                }
              },
            },
          };

          let matchesQueries = [];

          for (let query in queries) {
            if (queries[query].apply) {
              matchesQueries.push(queries[query].match());
            }
          }

          return matchesQueries.every((match) => match);
        });
        return results;
      }
      return serializedData;
    } else return null;
  }, [searchBy, searchValue, serializedData]);

  const onActiveShipment = (id) => {
    setActiveShipment(id);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setActiveShipment(null);
  };

  const handleOnVoidedShipment = () => {
    loadShipments();
  };

  const handleServerSearch = () => {
    setQueryParams((state) => ({ ...state, [searchBy]: searchValue }));
    setServerSearch({
      searchBy: searchOptions.find((opt) => opt.value === searchBy)?.name,
      searchValue,
    });
    setSearchValue("");
  };

  const onCloseServerSearch = () => {
    setServerSearch(null);
    const query = { ...queryParams };
    delete query[searchBy];
    setQueryParams(query);
  };

  const exportShipments = async (params, onCompleted) => {
    delete params.page;
    delete params.page_size;

    const response = await shipmentsApi.exportShipments(params);
    if (!response.ok) {
      const apiError = parseError(response);
      if (apiError) {
        toast("Error exporting data", "error");
      }
      onCompleted();
      return;
    }

    const file_name = (() => {
      // eslint-disable-next-line default-case
      switch (userRole?.domain.index) {
        case 0:
          return `shipments_${userData.branch.name}.csv`;
        case 1: // update name for area
        case 2: // update name for region
        case 3:
          return "shipments.csv";
      }
    })();

    exportCSVData(response.data, file_name);

    onCompleted();
  };

  const handleExport = () => {
    if (queryParams.start_date) {
      setExportLoading(true);

      exportShipments(queryParams, () => {
        setExportLoading(false);
      });
    } else {
      setExportOpen(true);
    }
  };

  const closeExport = () => {
    setExportOpen(false);
  };

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={"Shipments"}>
        <Search
          value={searchValue}
          multiple={true}
          searchBy={searchBy}
          searchOptions={searchOptions}
          onSearchOptionChange={(option) => setSearchBy(option)}
          inputPlaceHolder={searchInputPlacehHolder}
          handleSearch={onSearchChange}
          allowServerSearch={true}
          onServerSearch={handleServerSearch}
        />
        <FilterButton onClick={showFilter} />
      </Page.Header>
      <Page.Body>
        {isModalOpen && activeShipment && (
          <ShipmentDetail
            id={activeShipment}
            isOpen={isModalOpen}
            onClose={handleModalClose}
            onVoided={handleOnVoidedShipment}
            onUpdateShipments={loadShipments}
            type={activeTab.value}
          />
        )}
        {isFilterOpen && (
          <ShipmentsFilter
            isOpen={isFilterOpen}
            onClose={closeFilter}
            filter={filter}
            setFilter={setFilter}
            dateRange={dateRange}
            setDateRange={setDateRange}
            type={activeTab.value}
          />
        )}
        {isExportOpen && (
          <ExportDialogue
            isOpen={isExportOpen}
            name="shipments"
            onClose={closeExport}
            options={queryParams}
            onExport={exportShipments}
          />
        )}
        <div className="mb-3 flex flex-col gap-2 lg:gap-3">
          <div className="flex items-center gap-2 lg:gap-3">
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}

            {!!filterTags.length && (
              <div className="flex items-center gap-2 flex-wrap">
                <p className="text-base font-medium text-dark-primary">
                  Filter:
                </p>
                {filterTags.map(({ name, value }, id) => (
                  <FilterTag
                    key={id}
                    name={name}
                    value={value}
                    onDelete={onFilterDelete}
                  />
                ))}
              </div>
            )}
            <div className="flex lg:hidden ml-auto">
              <Pagination
                tableId="shipments-table"
                pageSize={shipmentsStore.meta?.page_size}
                totalCount={shipmentsStore.meta?.count}
                data={shipmentsStore.data}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={shipmentsStore.meta?.page}
              />
            </div>
          </div>

          <div className="flex gap-2 flex-row flex-wrap items-center justify-between w-full">
            <Tabs items={tabs} onSelectTab={onSelectTab} active={activeTab} />
            <div className="flex items-center gap-3 ml-auto">
              <div className="hidden lg:flex">
                <Pagination
                  tableId="shipments-table"
                  pageSize={shipmentsStore.meta?.page_size}
                  totalCount={shipmentsStore.meta?.count}
                  data={shipmentsStore.data}
                  setSerializedData={setSerializedData}
                  onPage={onPage}
                  page={shipmentsStore.meta?.page}
                />
              </div>
              <button
                className="btn bg-g-400 text-dark-primary"
                onClick={handleExport}
                disabled={!shipments?.length}
              >
                <MdOutlineFileDownload
                  size={18}
                  color="#333333"
                  className="mr-1"
                />
                {isExportLoading ? (
                  <>
                    Exporting
                    <CircularProgress
                      style={{ marginLeft: "0.5rem" }}
                      size={18}
                      color="inherit"
                    />
                  </>
                ) : (
                  "Export"
                )}
              </button>
            </div>
          </div>
        </div>

        {!shipments ? (
          <Loader />
        ) : (
          <>
            <div className="data-table-wrapper">
              <table id="shipments-table" className="table--hover">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Tracking ID</th>
                    <th>Order</th>
                    <th>Date</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Receiver</th>
                    <th>
                      Delivery {activeTab.value === "LC" ? "Mode" : "Type"}
                    </th>
                    <th>Carrier</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {shipments?.map((item, id) => (
                    <tr key={id} onClick={() => onActiveShipment(item.id)}>
                      <td>{item.s_n}</td>
                      <td>{item.tracking_id}</td>
                      <td>
                        <Link
                          to={`${ROUTES.ORDERS.path}?id=${item.order}`}
                          onClick={(e) => e.stopPropagation()}
                          className="hover:text-primary hover:underline"
                        >
                          #{item.order}
                        </Link>
                      </td>
                      <td>{getDate(item.created_at)}</td>
                      <td>{`${
                        getStateOfCountry(
                          item.meta.origin[1],
                          item.meta.origin[0]
                        )?.name || item.meta.origin[1]
                      }, ${item.meta?.origin[0]}`}</td>
                      <td>{`${
                        getStateOfCountry(
                          item.meta.destination[1],
                          item.meta.destination[0]
                        )?.name || item.meta.destination[1]
                      }, ${item.meta?.destination[0]}`}</td>
                      <td>{item.meta?.receiver_name}</td>
                      <td>
                        {activeTab.value === "LC"
                          ? item.meta.delivery_mode?.replace("_", " ")
                          : item.meta.delivery_type?.replace("_", " ")}
                      </td>
                      <td>
                        {item.meta.tpl_service === "FIE" ||
                        item.meta.tpl_service === "FPE"
                          ? "FEDEX"
                          : item.meta.tpl_service}
                      </td>
                      <td>
                        <Pill
                          name={statuses[item.status]?.name}
                          theme={statuses[item.status]?.theme}
                        />
                      </td>
                      <td>
                        <div>
                          <ShipmentRaiseException
                            sn={id + 1}
                            id={id}
                            setOpenModal={setRaiseModal}
                            isManifest={true}
                            clickThreeDots={() => {}}
                            tracking_id={item?.id}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {shipments?.length === 0 && (
              <div className="pt-14 w-full flex flex-col gap-2 items-center justify-center">
                <img
                  src={noDataIcon}
                  className="w-40 h-40 object-contain"
                  alt="no data icon"
                />
                <p className="no_data_description_text">No shipment found</p>
              </div>
            )}
          </>
        )}

        <ExceptionModal
          openModal={raiseModal}
          setOpenModal={setRaiseModal}
          //manifestData={waybillData?.tracking_id}
          type="MANIFEST"
          //location={waybillData?.destination_hub_name}
          //hub={waybillData?.destination_hub}
        />
      </Page.Body>
    </Page>
  );
}
