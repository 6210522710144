export default function ReceiptIcon ({ variant = 'secondary', ...restProps }) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.75 7.0625C3.75 6.31658 4.04632 5.60121 4.57376 5.07376C5.10121 4.54632 5.81658 4.25 6.5625 4.25H18.4375C19.1834 4.25 19.8988 4.54632 20.4262 5.07376C20.9537 5.60121 21.25 6.31658 21.25 7.0625V18H26.25V22.6875C26.25 23.7649 25.822 24.7983 25.0601 25.5601C24.2983 26.322 23.2649 26.75 22.1875 26.75H7.8125C6.73506 26.75 5.70175 26.322 4.93988 25.5601C4.17801 24.7983 3.75 23.7649 3.75 22.6875V7.0625ZM21.25 24.875H22.1875C22.7677 24.875 23.3241 24.6445 23.7343 24.2343C24.1445 23.8241 24.375 23.2677 24.375 22.6875V19.875H21.25V24.875ZM6.5625 6.125C6.31386 6.125 6.0754 6.22377 5.89959 6.39959C5.72377 6.5754 5.625 6.81386 5.625 7.0625V22.6875C5.625 23.895 6.605 24.875 7.8125 24.875H19.375V7.0625C19.375 6.81386 19.2762 6.5754 19.1004 6.39959C18.9246 6.22377 18.6861 6.125 18.4375 6.125H6.5625ZM9.0625 9.25C8.81386 9.25 8.5754 9.34877 8.39959 9.52459C8.22377 9.7004 8.125 9.93886 8.125 10.1875C8.125 10.4361 8.22377 10.6746 8.39959 10.8504C8.5754 11.0262 8.81386 11.125 9.0625 11.125H15.9375C16.1861 11.125 16.4246 11.0262 16.6004 10.8504C16.7762 10.6746 16.875 10.4361 16.875 10.1875C16.875 9.93886 16.7762 9.7004 16.6004 9.52459C16.4246 9.34877 16.1861 9.25 15.9375 9.25H9.0625ZM8.125 15.1875C8.125 14.9389 8.22377 14.7004 8.39959 14.5246C8.5754 14.3488 8.81386 14.25 9.0625 14.25H15.9375C16.1861 14.25 16.4246 14.3488 16.6004 14.5246C16.7762 14.7004 16.875 14.9389 16.875 15.1875C16.875 15.4361 16.7762 15.6746 16.6004 15.8504C16.4246 16.0262 16.1861 16.125 15.9375 16.125H9.0625C8.81386 16.125 8.5754 16.0262 8.39959 15.8504C8.22377 15.6746 8.125 15.4361 8.125 15.1875ZM9.0625 19.25C8.81386 19.25 8.5754 19.3488 8.39959 19.5246C8.22377 19.7004 8.125 19.9389 8.125 20.1875C8.125 20.4361 8.22377 20.6746 8.39959 20.8504C8.5754 21.0262 8.81386 21.125 9.0625 21.125H12.8125C13.0611 21.125 13.2996 21.0262 13.4754 20.8504C13.6512 20.6746 13.75 20.4361 13.75 20.1875C13.75 19.9389 13.6512 19.7004 13.4754 19.5246C13.2996 19.3488 13.0611 19.25 12.8125 19.25H9.0625Z'
        fill={variant === 'primary' ? '#fe6802' : '#2E3034'}
      />
    </svg>
  )
}
