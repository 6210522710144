import { useState } from "react";
import manifestApi from "../../../api/manifest";
import useToast from "../../../hooks/useToast";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CreateManifestButtons = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const createManifest = async () => {
    setLoading(true);
    const result = await manifestApi.triggerCreateManifest();

    if (result.ok) {
      toast("Manifest created", "success");
      setLoading(false);
    }

    if (!result.ok) {
      toast(result?.problem, "error");
      setLoading(false);
    }
  };

  return (
    <div className="shadow-lg bg-white pb-8">
      <span
        className="flex items-center py-3 px-4 cursor-pointer hover:bg-main-hover"
        onClick={() => {
          createManifest();
        }}
      >
        <div className="font-medium text-xs">
          Create Center Manifest{" "}
          {loading && <CircularProgress size={13} color="inherit" />}
        </div>
      </span>
      <span
        className="flex items-center py-3 px-4 cursor-pointer hover:bg-main-hover"
        onClick={() => {
          navigate(`/manifest/create-manifest/`, {
            state: { mType: "TM" },
          });
        }}
      >
        <div className="font-medium text-xs">Create Transfer Manifest</div>
      </span>
      <span
        className="flex items-center py-3 px-4 cursor-pointer hover:bg-main-hover"
        onClick={() => {
          navigate(`/manifest/create-doorstep/`, {
            state: { mType: "DS" },
          });
        }}
      >
        <div className="font-medium text-xs">Create Door-step Manifest</div>
      </span>
    </div>
  );
};

export default CreateManifestButtons;
