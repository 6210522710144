import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ordersApi from '../api/orders'
import { parseError } from '../utils'

const initialState = {
  data: null
}

export const fetchCategories = createAsyncThunk(
  'categories/fetch',
  async () => {
    const response = await ordersApi.getOrderCategories()

    if (!response.ok) {
      return parseError(response)
    }

    return response.data.payload
  }
)

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export const categoriesActions = categoriesSlice.actions

export default categoriesSlice.reducer
