import ReactModal from 'react-modal'
import PaymentDialogue from './PaymentDialogue'

const customStyles = {
  content: {
    borderRadius: '0',
    border: 'none',
    padding: '0',
    inset: '20px',
    height: 'fit-content',
    margin: 'auto',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'none'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function PaymentModal ({
  isOpen,
  onClose,
  orderData,
  customer,
  onSuccess,
  setOrderDetails,
  onProcessOrder,
  onShipment,
  multipiece
}) {
  return (
    <ReactModal style={customStyles} isOpen={isOpen}>
      <div className='w-full md:max-w-4xl flex flex-col justify-center'>
        <div className='flex justify-end w-full pb-1'>
          <button className='btn btn-accent' onClick={onClose}>
            Cancel
          </button>
        </div>
        <PaymentDialogue
          orderData={orderData}
          customer={customer}
          onSuccess={onSuccess}
          setOrderDetails={setOrderDetails}
          onProcessOrder={onProcessOrder}
          onShipment={onShipment}
          multipiece={multipiece}
        />
      </div>
    </ReactModal>
  )
}
