import Modal from 'react-modal'
import Loader from '../../loader/Loader'
import { useEffect, useMemo } from 'react'
import useApi from '../../../hooks/useApi'
import ordersApi from '../../../api/orders'
import { capitalizeFirstLetter, getStateOfCountry } from '../../../utils'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../../constants/routes'

const style = {
  content: {
    borderRadius: '0',
    inset: '20px',
    maxWidth: '768px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function OrderDraftModal ({ isOpen, id, onClose }) {
  const { data, request: readOrder } = useApi(ordersApi.readOrder, id)
  const categories = useSelector(state => state.categories)
  const navigate = useNavigate()

  useEffect(() => {
    readOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const draftData = useMemo(() => {
    return data?.payload.order
  }, [data])

  const shipmentTypes = {
    IN: 'International',
    LC: 'Local'
  }

  const categoryTypes = (() => {
    const obj = {}
    categories.data.forEach(({ name, id }) => {
      obj[id] = name
    })

    return obj
  })()

  const delivery = {
    LC: 'delivery_mode',
    IN: 'delivery_type'
  }

  const shipmentRates = {
    SR: 'Standard Rate',
    CR: 'Cargo Rate'
  }

  const insurances = {
    EI: 'Electronics',
    NE: 'Non Electronics',
    HI: 'Haulage',
    FR: 'Free',
    SD: 'Standard',
    PM: 'Premium'
  }

  const handleShipmentType = () => {
    navigate(
      `${ROUTES.ORDERS.CREATE_ORDER.path}${draftData.id}/${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}`
    )
  }

  const handleCustomer = type => {
    navigate(
      `${ROUTES.ORDERS.CREATE_ORDER.path}${draftData.id}/${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}/?ctm=${type}`
    )
  }

  const handlePackageType = () => {
    navigate(
      `${ROUTES.ORDERS.CREATE_ORDER.path}${draftData.id}/${ROUTES.ORDERS.CREATE_ORDER.PACKAGE_TYPE.path}`
    )
  }

  const handlePackage = route => {
    navigate(`${ROUTES.ORDERS.CREATE_ORDER.path}${draftData.id}/${route}`)
  }

  const totalPackages = useMemo(() => {
    if (draftData?.packages?.packages) {
      const weight = draftData.packages.packages.reduce((acc, curr) => {
        return acc + curr.actualWeight
      }, 0)
      return { length: draftData.packages.packages.length, weight }
    }

    return {
      length: '',
      weight: ''
    }
  }, [draftData?.packages?.packages])

  const totalItems = useMemo(() => {
    if (draftData?.packages?.items) {
      const weightPrice = draftData.packages?.items.reduce(
        (acc, curr) => {
          return {
            weight: acc.weight + curr.weight,
            price: acc.price + acc.weight
          }
        },
        { weight: 0, price: 0 }
      )

      return { length: draftData.packages?.items?.length, ...weightPrice }
    }

    return {
      length: '',
      weight: '',
      price: ''
    }
  }, [draftData?.packages?.items])

  const handleResume = () => {
    if (!draftData.type) {
      return handleShipmentType()
    }

    if (!draftData.sender) {
      return handleCustomer('sender')
    }

    if (!draftData.receiver) {
      return handleCustomer('receiver')
    }

    switch (draftData.type) {
      case 'LC':
        if (!draftData.category) {
          return handlePackageType()
        }
        if (!draftData.packages?.type) {
          return handlePackageType()
        }
        break
      case 'IN':
        if (!draftData.packages?.type) {
          return handlePackageType()
        }
        break
      default:
        break
    }

    switch (draftData.packages?.type) {
      case 'fish/snail':
        return handlePackage(ROUTES.ORDERS.CREATE_ORDER.FISH_SNAIL.path)
      case 'document':
        return handlePackage(ROUTES.ORDERS.CREATE_ORDER.DOCUMENT.path)
      case 'regular':
      default:
        return handlePackage(ROUTES.ORDERS.CREATE_ORDER.PACKAGE_SECTION.path)
    }
  }

  return (
    <Modal style={style} isOpen={isOpen}>
      <div>
        <div className='border-b border-gray-100 pb-4 px-6'>
          <h3 className='text-base font-semibold mb-2'>Order Draft Details</h3>
          <p className='text-sm text-dark-primary/70'>
            Pick up from where you left off
          </p>
        </div>
        <div className='p-6 flex flex-col gap-4'>
          {!draftData ? (
            <Loader />
          ) : (
            <>
              <div className='flex w-full gap-2 md:gap-4 p-4 bg-gray-50'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <span className='text-base text-main-primary'>1</span>
                </div>
                <div className='w-[calc(100%-3rem)] md:w-[calc(100%-5rem)]'>
                  <h4 className='font-semibold'>Shipment Type</h4>
                  <p className='text-sm text-dark-primary/70 truncate'>
                    {draftData.type ? shipmentTypes[draftData.type] : 'N/A'}
                  </p>
                </div>
              </div>
              <div className='flex w-full gap-2 md:gap-4 p-4 bg-gray-50'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <span className='text-base text-main-primary'>2</span>
                </div>
                <div className='w-[calc(100%-3rem)] md:w-[calc(100%-5rem)]'>
                  <h4 className='font-semibold'>Sender</h4>
                  <p className='text-sm text-dark-primary/70 truncate'>
                    {draftData.sender
                      ? `${draftData.sender.contact?.name} • ${
                          draftData.sender.address.street_lines[0]
                        }, ${draftData.sender.address.city}, ${
                          draftData.sender.address.state_name ||
                          getStateOfCountry(
                            draftData.sender.address.state_or_province_code,
                            draftData.sender.address.country
                          )?.name ||
                          draftData.sender.address.state_or_province_code
                        }, ${draftData.sender.address.country} • ${
                          draftData.sender.address.postal_code
                        } • ${draftData.sender.contact?.phone_number}`
                      : 'N/A'}
                  </p>
                </div>
              </div>
              <div className='flex w-full gap-2 md:gap-4 p-4 bg-gray-50'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <span className='text-base text-main-primary'>3</span>
                </div>
                <div className='w-[calc(100%-3rem)] md:w-[calc(100%-5rem)]'>
                  <h4 className='font-semibold'>Receiver</h4>
                  <p className='text-sm text-dark-primary/70 truncate'>
                    {draftData.receiver
                      ? `${draftData.receiver.contact?.name} • ${
                          draftData.receiver.address.street_lines[0]
                        }, ${draftData.receiver.address.city}, ${
                          draftData.receiver.address.state_name ||
                          getStateOfCountry(
                            draftData.receiver.address.state_or_province_code,
                            draftData.receiver.address.country
                          )?.name ||
                          draftData.receiver.address.state_or_province_code
                        }, ${draftData.receiver.address.country} • ${
                          draftData.receiver.address.postal_code
                        } • ${draftData.receiver.contact?.phone_number}`
                      : 'N/A'}
                  </p>
                </div>
              </div>
              <div className='flex w-full gap-2 md:gap-4 p-4 bg-gray-50'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <span className='text-base text-main-primary'>4</span>
                </div>
                <div className='w-[calc(100%-3rem)] md:w-[calc(100%-5rem)]'>
                  <h4 className='font-semibold'>Package</h4>
                  <p className='text-sm text-dark-primary/70 truncate'>
                    {draftData.packages
                      ? `${
                          categoryTypes[draftData.category] ||
                          capitalizeFirstLetter(draftData.packages?.type)
                        } • ${draftData.packages?.itemsValue?.toLocaleString(
                          'en-NG',
                          {
                            style: 'currency',
                            currency: 'NGN'
                          }
                        )}${
                          draftData.packages?.tpl
                            ? ` • ${draftData.packages?.tpl}`
                            : ''
                        } • Packages (${totalPackages.length.toLocaleString()}, ${totalPackages.weight.toLocaleString()}kg) • Items (${totalItems.length.toLocaleString()}, ${totalItems.weight.toLocaleString()}kg, ${totalItems.price.toLocaleString(
                          'en-NG',
                          {
                            style: 'currency',
                            currency: 'NGN'
                          }
                        )})`
                      : 'N/A'}
                  </p>
                </div>
              </div>
              <div className='flex w-full gap-2 md:gap-4 p-4 bg-gray-50'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <span className='text-base text-main-primary'>5</span>
                </div>
                <div className='w-[calc(100%-3rem)] md:w-[calc(100%-5rem)]'>
                  <h4 className='font-semibold'>Shipment Information</h4>
                  <p className='text-sm text-dark-primary/70 truncate'>
                    {`${
                      draftData.description ? `${draftData.description}` : ''
                    }${
                      draftData.shipment_rate
                        ? `${draftData.description ? ' • ' : ''}${
                            shipmentRates[draftData.shipment_rate]
                          }`
                        : ''
                    }${
                      draftData[delivery[draftData.type]]
                        ? `${
                            draftData.description || draftData.shipment_rate
                              ? ' • '
                              : ''
                          }${draftData[delivery[draftData.type]].replace(
                            '_',
                            ' '
                          )}`
                        : ''
                    }${
                      draftData.package_insurance
                        ? `${
                            draftData.description ||
                            draftData.shipment_rate ||
                            draftData[delivery[draftData.type]]
                              ? ' • '
                              : ''
                          }Insurance: ${
                            insurances[draftData.package_insurance]
                          }`
                        : ''
                    }`}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        <div className='pt-4 px-6'>
          <div className='flex w-full justify-end gap-3'>
            <button
              className='btn bg-g-400 text-dark-primary'
              onClick={onClose}
            >
              Close
            </button>
            <button className='btn btn-primary' onClick={handleResume}>
              Resume Order Creation
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
