import { useSelector } from 'react-redux'
import {} from '../../slices/ordersSlice'
import noDataIcon from '../../assets/no-data.webp'
import { MdOutlineFileDownload } from 'react-icons/md'
import * as XLSX from 'xlsx'

import Pagination from '../../components/globals/pagination/ServerPagination'
function FinancialAnalysis ({ analysisData, onPage, setSerializedData }) {
  const dashboardOrdersData = useSelector(state => state.orders)

  const tableHeader = [
    'S/N',
    'Date',
    'Waybill No.',
    'Customer',
    'Branch',
    'Carrier',
    'Shipment Type',
    'Weight',
    'Add Ons',
    'Add Ons Total',
    'Shipping Fee',
    'Payment method',
    'Insurance Fee',
    'Fuel Surcharge',
    'Subtotal',
    'Tax(VAT 7.5%)',
    'Total Cost'
  ]

  const exportInvoices = () => {
    const createHeader = (type = 'intl') => {
      const header = tableHeader.filter(item => item !== 'S/N')
      return header
    }

    // create rows
    const createRows = (type = 'intl') => {
      const rows = analysisData.map((item, ind) => {
        return {
          Date: item.date,
          'Waybill No.': item.waybillNo,
          Customer: item.fullname,
          Branch: item.branch,
          Carrier: item.tpl,
          'Shipment Type': item.type === 'LC' ? 'Local' : `Int'l`,
          Weight: `${item.weight} kg`,
          'Add Ons': item.addOns,
          'Add ONS Total': item.addOnsTotal,
          'Shipping Fee': item.shippingFee,
          'Payment method': item.payment,
          'Insurance fee': item.insuranceFee,
          'Fuel Surcharge': item.fuel,
          Subtotal: item.subTotal,
          'Tax(VAT 7.5%)': item.tax,
          'Total Cost': item.total
        }
      })

      return rows
    }

    const intl_header = createHeader('intl')
    const intl_rows = createRows('intl')

    // generate workbook
    const workbook = XLSX.utils.book_new()

    // generate worksheets
    const intl_sheet = XLSX.utils.json_to_sheet(intl_rows)

    XLSX.utils.book_append_sheet(workbook, intl_sheet, 'Order Analysis')

    // fix headers
    XLSX.utils.sheet_add_aoa(intl_sheet, [intl_header], {
      origin: 'A1'
    })

    // column width
    const max_width = intl_rows.reduce((w, r) => Math.max(w, r.Sender?.length))

    intl_sheet['!cols'] = [{ wch: max_width }]

    XLSX.writeFile(workbook, `Order_Analysis.xlsx`, {
      compression: true
    })
  }

  return (
    <>
      <div className=' rounded-lg border bg-white h-full border-[#EFEFEF] mt-4'>
        <div className='flex w-full justify-end align-center mt-10 mr-[17px] mb-5'>
          <button
            onClick={exportInvoices}
            className='btn border-[#BDBDBD] border-[1.5px]  bg-transparent text-dark-primary mr-7'
          >
            <MdOutlineFileDownload size={18} color='#333333' className='mr-1' />
            Export
          </button>
        </div>
        <div className='overflow-x-scroll w-full'>
          <table id='quotes-table' className='table--hover'>
            <thead className='border-b border-[#EFEFEF]'>
              <tr>
                {tableHeader.map((item, id) => {
                  return (
                    <th key={id}>
                      <p
                        className={`pr-2 text-left ${
                          id === 0 ? 'w-[80px]' : 'w-[170px]'
                        }`}
                      >
                        {item}
                      </p>
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody className='overflow-x-scroll max-w-[600px] overflow-auto '>
              {analysisData?.map((item, id) => (
                <tr className='border-b border-[#EFEFEF] bg-white' key={id}>
                  <td className=''>{id + 1}</td>
                  <td className=''>{item.date}</td>
                  <td>{item.waybillNo ? `#${item.waybillNo}` : '--'}</td>
                  <td>{item.fullname}</td>
                  <td>{item.branch}</td>
                  <td>{item.tpl}</td>
                  <td>{item.type === 'LC' ? 'Local' : `Int'l`}</td>
                  <td>{item.weight} kg</td>
                  <td>{item.addOns}</td>
                  <td>{item.addOnsTotal}</td>
                  <td>{item.shippingFee}</td>
                  <td>{item.payment}</td>
                  <td>{item.insuranceFee}</td>
                  <td>{item.fuel}</td>
                  <td>{item.subTotal}</td>
                  <td>{item.tax}</td>
                  <td>{item.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='flex ml-auto py-4 px-2'>
          <Pagination
            tableId='dashboard-table'
            data={dashboardOrdersData.data}
            pageSize={dashboardOrdersData?.meta?.page_size}
            totalCount={dashboardOrdersData?.meta?.count}
            page={dashboardOrdersData?.meta?.page}
            setSerializedData={setSerializedData}
            onPage={onPage}
          />
        </div>
        {(dashboardOrdersData.data?.length === 0 ||
          dashboardOrdersData.data === undefined) && (
          <div className='pt-14 w-full flex flex-col items-center justify-center mb-10'>
            <img
              src={noDataIcon}
              className='w-40 h-40 object-contain'
              alt='no data icon'
            />
            <p className='text-gray-500 text-left my-2 font-semibold text-base md:text-xl'>
              No order found
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default FinancialAnalysis
