import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import manifestApi from '../api/manifest'
import { parseError, urlQueryStringToObject } from '../utils'
import store from '../store'

const initialState = {
  data: null,
  meta: {
    previous: 0,
    page: 1,
    next: 0
  }
}

export const fetchAllManifestShipments = createAsyncThunk(
  'allShipments/fetch',
  async (params = {}) => {
    const userData = store.getState().auth.user
    const query = Object.assign(
      {
        page_size: 500,
        page: 1,
        branch: userData?.branch?.name
      },
      params
    )

    const response = await manifestApi.getAllShipments(query)

    if (!response.ok) {
      return parseError(response)
    }

    return { response_data: response.data, query_params: query }
  }
)

const allShipmentsSlice = createSlice({
  name: 'allShipments',
  initialState,

  extraReducers: builder => {
    builder.addCase(fetchAllManifestShipments.fulfilled, (state, action) => {
      const meta = action.payload?.response_data?.meta || {}
      if (meta.next) {
        meta.next = urlQueryStringToObject(meta.next).page
      }
      meta.page = action.payload?.query_params?.page
      meta.previous = meta.page > 1 ? meta.page - 1 : 0
      state.meta = Object.assign({}, state.meta, meta)
      state.data = action.payload?.response_data?.payload
    })
  }
})

export default allShipmentsSlice.reducer
