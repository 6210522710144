const Button = ({
  bgColor,
  color,
  size,
  text,
  borderRadius,
  icon,
  gotoPrint
}) => {
  return (
    <div
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={`px-5 text-${size} py-3 hover:drop-shadow-xl space-x-2 flex items-center justify-center cursor-pointer`}
      onClick={gotoPrint}
    >
      {icon} <p className='font-semibold'>{text}</p>
    </div>
  )
}

export default Button
