import { IoMdAdd, IoMdClose } from 'react-icons/io'
import SelectPackaging from '../globals/SelectPackaging'
import AppFormField from '../globals/Form/AppFormField'
import { twMerge } from 'tailwind-merge'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition
} from '@headlessui/react'
import { MdExpandMore } from 'react-icons/md'
import { Field, useFormikContext } from 'formik'
import { useMemo } from 'react'

export default function OrderPackage ({
  _package,
  index,
  isLast,
  onAdd,
  onRemove,
  shipmentType,
  defaultOpen = true
}) {
  const { setFieldValue } = useFormikContext()

  const handleRemove = e => {
    e.stopPropagation()
    onRemove(index)
  }

  const handleSelectPredefined = val => {
    setFieldValue(`packages.${index}.dimension`, val)
  }

  const handlePackageDimension = dimension => {
    setFieldValue(`packages.${index}.dimension`, JSON.stringify(dimension))
  }

  const packagingValue = useMemo(() => {
    if (_package.dimension) {
      if (isNaN(_package.dimension)) {
        const dimension = JSON.parse(_package.dimension)
        if (
          dimension?.length &&
          dimension?.width &&
          dimension?.height &&
          dimension?.weight
        ) {
          return 'custom'
        }
      } else return _package.dimension
    }

    return ''
  }, [_package.dimension])

  const packageDimension = useMemo(() => {
    let parsedValue = _package.dimension ? JSON.parse(_package.dimension) : ''
    return typeof parsedValue === 'object' ? parsedValue : {}
  }, [_package.dimension])

  return (
    <Disclosure
      as='div'
      defaultOpen={defaultOpen}
      key={index}
      className={twMerge(
        'py-3 border-b border-gray-100',
        isLast ? 'packages--last-item' : ''
      )}
    >
      {({ open }) => {
        return (
          <>
            <DisclosureButton
              data-id={index}
              data-open={open}
              className='w-full'
            >
              <div className='flex flex-row gap-2 justify-between items-start sm:items-center pb-1'>
                <h3>Package ({index + 1})</h3>

                <div className='flex items-center justify-end gap-2'>
                  {!open && (
                    <span className='text-sm'>
                      {(_package.actualWeight || 0).toLocaleString()}kg
                    </span>
                  )}
                  {isLast && index !== 0 && (
                    <button
                      onClick={onAdd}
                      type='button'
                      className='btn btn-sm gap-1 items-center text-dark-primary'
                    >
                      <IoMdAdd size={16} color='#333333' />
                      <span className='hidden md:block'>Add</span>
                    </button>
                  )}
                  {index !== 0 && (
                    <button
                      onClick={handleRemove}
                      type='button'
                      className='btn btn-sm gap-1 items-center text-dark-primary'
                    >
                      <IoMdClose size={16} color='#333333' />
                      <span className='hidden md:block'>Remove</span>
                    </button>
                  )}
                  <div>
                    <MdExpandMore size={18} color='#333333' />
                  </div>
                </div>
              </div>
            </DisclosureButton>
            <Transition
              enter='transition origin-top duration-150 ease-out'
              enterFrom='transform scale-y-0 opacity-0'
              enterTo='transform scale-y-100 opacity-100'
              leave='transition origin-top duration-100 ease-out'
              leaveFrom='transform scale-y-100 opacity-100'
              leaveTo='transform scale-y-0 opacity-0'
            >
              <DisclosurePanel
                as='div'
                className='flex flex-col md:flex-row gap-2 w-full items-start'
              >
                <div className='w-full'>
                  <AppFormField
                    name={`packages.${index}.actualWeight`}
                    title='Actual Weight(KG)'
                    step='0.01'
                    min='0.01'
                    type='number'
                  />
                </div>
                <div className='w-full'>
                  <label className='label'>
                    <span className='label-text'>Packaging</span>
                  </label>
                  <Field name={`packages.${index}.dimension`}>
                    {({ field, form, meta }) => {
                      const handleTouch = () => {
                        form.setTouched({ [field.name]: true })
                      }

                      return (
                        <>
                          <SelectPackaging
                            value={packagingValue}
                            onTouch={handleTouch}
                            onSelectPredefined={handleSelectPredefined}
                            dimension={packageDimension}
                            handlePackageDimension={handlePackageDimension}
                            shipmentType={shipmentType}
                          />
                          {meta.touched && meta.error && (
                            <p
                              className={twMerge(
                                'packaging-error text-red-500 text-sm'
                              )}
                            >
                              {meta.error}
                            </p>
                          )}
                        </>
                      )
                    }}
                  </Field>
                </div>
              </DisclosurePanel>
            </Transition>
          </>
        )
      }}
    </Disclosure>
  )
}
