import { paymentSocketActions } from '../../slices/paymentSocketSlice'
import Socket from '../../utils/Socket'
import { hashToken } from '../../utils/crypto'

export const paymentSocketMiddleware = () => store => next => async action => {
  const socket = new Socket()

  const { type } = action

  switch (type) {
    case 'paymentSocket/connect': {
      const token = await hashToken()
      const url =
        process.env.REACT_APP_BOOKING_WEBSOCKET +
        `/ws/payment_status/?tk=${token}`
      socket.connect(url)

      socket.on('open', () => {
        store.dispatch(paymentSocketActions.connected())
      })
      socket.on('message', e => {
        const message = JSON.parse(e.data)
        const userData = store.getState().auth.user
        if (Array.isArray(message.data)) {
          if (message.data[0].meta?.user_id === userData?.user.user_id) {
            store.dispatch(paymentSocketActions.message(message))
          }
        } else {
          if (message.data?.meta?.user_id === userData?.user.user_id) {
            store.dispatch(paymentSocketActions.message(message))
          }
        }
      })
      socket.on('close', () => {
        store.dispatch(paymentSocketActions.disconnected())
      })
      break
    }
    case 'paymentSocket/disconnect':
      socket.disconnect()
      store.dispatch(paymentSocketActions.disconnected())
      break

    default:
      break
  }

  return next(action)
}
