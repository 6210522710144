import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts'

const COLORS = ['#A6A6A699', '#F2901E']

const LocalLegend = ({ total }) => {
  return (
    <div className='flex items-center gap-2'>
      <svg
        width='20'
        height='8'
        viewBox='0 0 20 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect y='3.5' width='20' height='1' rx='0.5' fill='#A6A6A699' />
        <circle cx='10.5' cy='4' r='3.5' fill='#A6A6A699' />
      </svg>

      <span className='text-xs'>Local ({total.toLocaleString()})</span>
    </div>
  )
}

const InternationalLegend = ({ total }) => {
  return (
    <div className='flex items-center gap-2'>
      <svg
        width='20'
        height='8'
        viewBox='0 0 20 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect y='3.5' width='20' height='1' rx='0.5' fill='#F2901E' />
        <circle cx='10.5' cy='4' r='3.5' fill='#F2901E' />
      </svg>

      <span className='text-xs'>International ({total.toLocaleString()})</span>
    </div>
  )
}

const Legend = ({ data }) => {
  return (
    <div className='flex items-center justify-center gap-4 w-full'>
      <LocalLegend total={data.LC} />
      <InternationalLegend total={data.IN} />
    </div>
  )
}

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

export default function ShipmentTypePieChart ({ data }) {
  const chartData = [
    { name: 'Local', value: Number(data.LC) },
    { name: 'International', value: Number(data.IN) }
  ]

  return (
    <div className='py-4 px-4 shadow-md-right bg-white rounded-xl h-full'>
      <div>
        <h5 className='font-semibold text-base mb-6'>Shipment Type</h5>
      </div>
      <div className='w-full h-64 flex items-center justify-center mb-6'>
        <ResponsiveContainer width='100%' height='100%'>
          <PieChart width={400} height={400}>
            <Pie
              data={chartData}
              cx='50%'
              cy='50%'
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill='#8884d8'
              dataKey='value'
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <Legend data={data} />
    </div>
  )
}
