import TransferManifestPrintSheet from '../../components/manifests/TransferManifestPrintSheet'
import Page from '../../containers/Page'

export default function ManifestSheet ({ metaTitle }) {
  return (
    <Page metaTitle={metaTitle}>
      <Page.Body>
        <TransferManifestPrintSheet />
      </Page.Body>
    </Page>
  )
}
