import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image
} from '@react-pdf/renderer'
import { pdfjs } from 'react-pdf'
import logo from '../../assets/logo-text.png'
import Barcode from 'react-barcode'
import { QRCodeSVG } from 'qrcode.react'
import PNG from 'save-svg-as-png'
import { getDate } from '../../utils'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF'
  },
  page_wrapper: {
    padding: 20
  },
  header_section: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  header_logo_section: {
    width: '50%'
  },
  header_logo: {
    width: 56,
    height: 'auto',
    objectFit: 'contain'
  },
  header_description: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    rowGap: 10,
    marginBottom: 16
  },
  header_description_title: {
    textTransform: 'uppercase',
    color: '#BDBDBD',
    marginRight: 2
  },
  header_description_detail: {
    color: '#333333'
  },
  title: {
    color: '#333333',
    fontSize: 18,
    fontWeight: 700,
    paddingTop: 10,
    paddingBottom: 20,
    textTransform: 'uppercase'
  },
  table: {
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    color: '#333333',
    fontSize: 14
  },
  table_header: {
    fontWeight: 600
  },
  table_body: { fontWeight: 400 },
  table_row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  table_sn: {
    borderBottom: '1px solid #333333',
    borderRight: '1px solid #333333',
    width: '50%',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    textAlign: 'left'
  },
  table_group_waybill: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    borderBottom: '1px solid #333333',
    // borderRight: "1px solid #333333",
    width: '50%'
  },
  table_group_waybill_item: {
    marginBottom: 4,
    textTransform: 'uppercase'
  },
  table_origin: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    borderBottom: '1px solid #333333',
    // borderRight: "1px solid #333333",
    width: '50%',
    textTransform: 'uppercase'
  },
  table_destination: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    borderBottom: '1px solid #333333',
    borderRight: '1px solid #333333',
    width: '23%'
  },
  table_quantity: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    borderBottom: '1px solid #333333',
    width: '21%'
  },
  footer: {
    marginTop: 10
  },
  footer_info: {
    padding: 15,
    fontSize: 12,
    backgroundColor: '#FFEDD5',
    marginBottom: 20
  },
  footer_info_text: {
    color: '#000000',
    opacity: 0.8
  },
  footer_action_info: {
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 15,
    color: '#333333'
  },
  codes_container: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderTop: '4px solid #000000',
    borderBottom: '4px solid #000000',
    rowGap: 100
  },
  bar_code: {
    width: 250,
    height: 'auto',
    objectFit: 'contain',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center'
  },
  qr_code: {
    width: 250,
    height: 'auto',
    objectFit: 'contain',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  qr_codeTwo: {
    width: 150,
    height: 'auto',
    objectFit: 'contain',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
})

export default function InternationalManifestPrintSheet () {
  const allData = useSelector(state => state?.singleManifest?.singleManifest)
  const userData = useSelector(state => state.auth.user)

  const [codes, setCodes] = useState({
    bar_code: '',
    qr_code: ''
  })

  const barCodeContainer = useRef(null)
  const qrCodeContainer = useRef(null)

  useEffect(() => {
    if (barCodeContainer.current) {
      const svg = barCodeContainer.current.querySelector('svg')
      PNG.svgAsPngUri(svg, {}, pngDataURI => {
        setCodes(state => ({ ...state, bar_code: pngDataURI }))
      })
    }

    if (qrCodeContainer.current) {
      const svg = qrCodeContainer.current.querySelector('svg')
      PNG.svgAsPngUri(svg, {}, pngDataURI => {
        setCodes(state => ({ ...state, qr_code: pngDataURI }))
      })
    }
  }, [])

  useEffect(() => {}, [])

  const ManifestPDFSheet = () => {
    return (
      <Document>
        <Page size='A4' style={styles.page}>
          <View style={styles.page_wrapper}>
            <View style={styles.header_section}>
              <View style={styles.header_logo_section}>
                <Image src={logo} style={styles.header_logo} />
              </View>
              <View style={styles.header_description}>
                <Text>International Manifest</Text>
                <Text>TPL Service: {allData?.meta?.tpl_service}</Text>
                <Text>
                  <Text style={styles.header_description_title}>
                    Shipment Date:
                  </Text>
                  <Text style={styles.header_description_detail}>
                    {getDate(allData?.created_at)}
                  </Text>
                </Text>
                {/* <Text>
                  <Text style={styles.header_description_title}>
                    Shipped From:
                  </Text>
                  <Text style={styles.header_description_detail}>
                    {allData?.originating_hub_name}
                  </Text>
                </Text> */}
              </View>
            </View>
            <View style={styles.title}>
              <Text>
                {allData?.originating_center} - {allData?.destination_hub_name}/
                {allData?.tracking_id}
              </Text>
            </View>
            <View style={styles.table}>
              <View style={styles.table_header}>
                <View style={styles.table_row}>
                  <View style={styles.table_sn}>
                    <Text>SN</Text>
                  </View>
                  <View style={styles.table_group_waybill}>
                    <Text>Waybill no</Text>
                  </View>
                </View>
              </View>
              <View style={styles.table_body}>
                {allData?.meta?.shipments.map((item, id) => (
                  <View key={id} style={styles.table_row}>
                    <View style={styles.table_sn}>
                      <Text>{id + 1}</Text>
                    </View>

                    <View style={styles.table_origin}>
                      <Text>{item}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <View style={styles.footer}>
              <View style={styles.footer_info}>
                <Text style={styles.footer_info_text}>
                  Upon scanning, the group waybill numbers and volume linked to
                  this manifest should match the labelled containers or bags
                  presented
                </Text>
              </View>
              {/* <View>
                <View style={styles.footer_action_info}>
                  <Text>Please scan upon drop-off or receipt of manifest</Text>
                </View>
                <View style={styles.codes_container}>
                  <Image src={codes.bar_code} style={styles.bar_code} />
                  <Image src={codes.qr_code} style={styles.qr_codeTwo} />
                </View>
              </View> */}
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 20,
                  fontSize: 12,
                  paddingRight: 20
                }}
              >
                <View>
                  <Text>
                    Supervisor: {userData?.employee.first_name}{' '}
                    {userData?.employee.last_name}
                  </Text>
                  <Text style={{ marginTop: 20 }}>
                    Signature:...............................
                  </Text>
                </View>
                <View>
                  <Text>
                    Astro or captain:{localStorage.getItem('riderName')}
                  </Text>
                  <Text style={{ marginTop: 20 }}>
                    Signature:...............................
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    )
  }

  return (
    <div>
      <div className='ml-auto mb-4 w-fit p-4'>
        <PDFDownloadLink
          document={<ManifestPDFSheet />}
          fileName='manifest.pdf'
          className='btn btn-primary'
        >
          Print Manifest
        </PDFDownloadLink>
      </div>
      <div>
        <div>
          <div
            style={styles.page}
            className='bg-white border p-4 lg:p-6 w-full overflow-x-hidden'
          >
            <div style={styles.header_section}>
              <div style={styles.header_logo_section}>
                <img src={logo} className={styles.header_logo} alt='log' />
              </div>
              <div style={styles.header_description}>
                <div style={styles.title}>
                  <p className='text-primary'>International manifest</p>
                </div>
                <div>
                  <p className='text-black font-semibold text-[16px]'>
                    TPL Service: {allData?.meta?.tpl_service}
                  </p>
                </div>
                <p>
                  <span style={styles.header_description_title}>
                    Shipment Date:
                  </span>
                  <span> {getDate(allData?.created_at)}</span>
                </p>
                {/* <p>
                  <span style={styles.header_description_title}>
                    Shipped From:
                  </span>
                  <span>{allData?.originating_hub_name}</span>
                </p> */}
              </div>
            </div>

            <div style={styles.title}>
              <p>
                {allData?.originating_center} - {allData?.destination_hub_name}/
                {allData?.tracking_id}
              </p>
            </div>
            <div className='w-full flex justify-center items-center'>
              <div
                className='w-[40%] h-[50px] flex justify-center items-center'
                style={{
                  borderBottom: '1px solid gray',
                  borderRight: '1px solid gray',
                  ...styles.table_header
                }}
              >
                <p>SN</p>
              </div>
              <div
                className='w-[40%] h-[50px] flex justify-center items-center'
                style={{
                  borderBottom: '1px solid gray',
                  ...styles.table_header
                }}
              >
                <p>Waybill no</p>
              </div>
            </div>
            {allData?.meta?.shipments.map((waybill, id) => (
              <div className='w-full flex justify-center items-center' key={id}>
                <div
                  className='w-[40%] h-[50px] flex justify-center items-center'
                  style={{
                    borderBottom: '1px solid gray',
                    borderRight: '1px solid gray',
                    ...styles.table_body
                  }}
                >
                  <p>{id + 1}</p>
                </div>
                <div
                  className='w-[40%] h-[50px] flex justify-center items-center'
                  style={{
                    borderBottom: '1px solid gray',
                    ...styles.table_body
                  }}
                >
                  <p>{waybill?.toUpperCase()}</p>
                </div>
              </div>
            ))}
            <div style={styles.footer}>
              <div style={styles.footer_info}>
                <p style={styles.footer_info_text}>
                  Upon scanning, the group waybill numbers and volume linked to
                  this manifest should match the labelled containers or bags
                  presented
                </p>
              </div>
              {/* <div>
                <div style={styles.footer_action_info}>
                  <p>Please scan upon drop-off or receipt of manifest</p>
                </div>
                <div style={styles.codes_container}>
                  <div style={styles.bar_code} ref={barCodeContainer}>
                    <Barcode value={allData?.tracking_id} />
                  </div>
                  <div style={styles.qr_code} ref={qrCodeContainer}>
                    <QRCodeSVG value={allData?.tracking_id} />
                  </div>
                </div>
              </div> */}
              <div className='w-full flex justify-between justify-items-end mt-8 pr-44'>
                <div>
                  <p>
                    {' '}
                    Supervisor: {userData?.employee.first_name}{' '}
                    {userData?.employee.last_name}
                  </p>
                  <p className='mt-8'>
                    {' '}
                    Signature:...............................
                  </p>
                </div>
                <div>
                  <p>Astro or captain: {localStorage.getItem('riderName')}</p>
                  <p className='mt-8'>
                    {' '}
                    Signature:................................
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
