import { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchQuotes } from '../../slices/quotesSlice'
import Loader from '../../components/loader/Loader'
import { getDate } from '../../utils'
import noDataIcon from '../../assets/no-data.webp'
import Pagination from '../../components/globals/pagination/pagination'
import { Link } from 'react-router-dom'
import ReactModal from 'react-modal'
import TempQuote from '../../components/quotes/TempQuote'
import Page from '../../containers/Page'
import Search from '../../components/globals/Search/Search'
import ROUTES from '../../constants/routes'
import QuotesIcon from '../../components/navicons/Quotes'
import useCountry from '../../hooks/useCountry'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import ROLES from '../../constants/roles'

const customStyles = {
  content: {
    borderRadius: '20px',
    inset: '20px',
    maxWidth: '768px',
    margin: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function Quotes ({ metaTitle }) {
  const dispatch = useDispatch()
  const quotesStore = useSelector(state => state.quotes)

  const [tableData, setTableData] = useState([])
  const [data, setData] = useState()
  const [activeQuote, setActiveQuote] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchBy, setSearchBy] = useState('id')
  const [searchValue, setSearchValue] = useState('')

  const Country = useCountry()
  const { userRole } = useContext(AuthLayoutContext)

  useEffect(() => {
    dispatch(fetchQuotes())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (quotesStore.data) setData(quotesStore.data)
  }, [quotesStore.data])

  const handleView = item => {
    setIsModalOpen(true)
    setActiveQuote(item)
  }

  const handleSearch = ({ target }) => {
    setSearchValue(target.value)
    if (target.value) {
      const results = quotesStore.data.filter(quote => {
        return searchBy === 'order'
          ? quote.has_complete_order &&
              String(quote[searchBy]).includes(target.value)
          : String(quote[searchBy]).includes(target.value)
      })
      setData(results)
    } else {
      setData(quotesStore.data)
    }
  }

  const quotes = useMemo(() => {
    if (data) {
      return data.map((_, id) => ({ ..._, s_n: id + 1 }))
    }
  }, [data])

  const searchOptions = [
    {
      name: 'Quote ID',
      value: 'id'
    },
    {
      name: 'Order ID',
      value: 'order'
    },
    {
      name: 'Destination',
      value: 'receiver_country',
      options: [
        {
          name: 'All',
          value: ''
        },
        ...Country.getAllCountries().map((country, index) => ({
          name: country.name,
          value: country.name
        }))
      ]
    }
  ]

  const searchInputPlaceHolder = useMemo(() => {
    return searchBy === 'id' ? 'Enter quote ID' : 'Enter order ID'
  }, [searchBy])

  const canCreateOrder = useMemo(() => {
    if (userRole) {
      return ROLES[userRole.id].permissions?.orders?.create
    }
  }, [userRole])

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Quotes'}>
        <Search
          value={searchValue}
          multiple={true}
          searchBy={searchBy}
          searchOptions={searchOptions}
          onSearchOptionChange={option => setSearchBy(option)}
          inputPlaceHolder={searchInputPlaceHolder}
          handleSearch={handleSearch}
        />
      </Page.Header>
      <Page.Body>
        {isModalOpen && (
          <ReactModal
            isOpen={isModalOpen}
            style={customStyles}
            onRequestClose={() => setIsModalOpen(false)}
          >
            {loading ? (
              <Loader />
            ) : (
              <TempQuote
                data={activeQuote}
                onClose={() => setIsModalOpen(false)}
                setLoading={setLoading}
                allowCreateOrder={canCreateOrder}
              />
            )}
          </ReactModal>
        )}
        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div></div>
          <div className='flex justify-end'>
            <Link to={ROUTES.QUOTES.GET_QUOTE.path} className='btn btn-primary'>
              <QuotesIcon variant={'#ffffff'} className='w-4 mr-1' />
              Get Quote
            </Link>
          </div>
        </div>
        {!quotes ? (
          <Loader />
        ) : (
          <>
            <div className='data-table-wrapper'>
              <table id='quotes-table' className='table--hover'>
                <thead className='border-b border-[#EFEFEF]'>
                  <tr>
                    <th>S/N</th>
                    <th>ID</th>
                    <th>Date Raised</th>
                    <th>Order</th>
                    <th>Weight</th>
                    <th>Total Amount</th>
                    <th>Origin</th>
                    <th>Destination</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((item, id) => (
                    <tr
                      className='border-b border-[#EFEFEF] bg-white'
                      key={id}
                      onClick={() => handleView(item)}
                    >
                      <td>{item.s_n}</td>
                      <td>#{item.id}</td>
                      <td>{getDate(item.date_raised)}</td>
                      <td>
                        {item.has_complete_order ? (
                          <Link
                            to={`/orders?id=${item.order}`}
                            className='hover:underline hover:text-primary'
                          >
                            #{item.order}
                          </Link>
                        ) : (
                          'null'
                        )}
                      </td>
                      <td>{item.weight}kg</td>
                      <td>
                        {item.total?.toLocaleString('en-NG', {
                          style: 'currency',
                          currency: 'NGN'
                        })}
                      </td>
                      <td>{item.sender_country}</td>
                      <td>{item.receiver_country}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {tableData?.length === 0 && (
              <div className='pt-14 w-full flex flex-col gap-2 items-center justify-center'>
                <img
                  src={noDataIcon}
                  className='w-40 h-40 object-contain'
                  alt='no data icon'
                />
                <p className='no_data_description_text'>No quote found</p>
              </div>
            )}
            <Pagination
              tableId='quotes-table'
              data={data}
              setTableData={setTableData}
            />
          </>
        )}
      </Page.Body>
    </Page>
  )
}
