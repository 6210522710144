import { useEffect, useMemo, useState } from 'react'
import Page from '../../containers/Page'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUnremittedCashTransactions } from '../../slices/transactionSlice'
import Loader from '../../components/loader/Loader'
import { AgentCashTransactions } from '../../components'
import { fetchInvoices } from '../../slices/invoicesSlice'
import { fetchReceipts } from '../../slices/receiptsSlice'
import transactionsApi from '../../api/transactions'
import { CircularProgress } from '@mui/material'
import { parseError } from '../../utils'
import useToast from '../../hooks/useToast'
import noDataIcon from '../../assets/no-data.webp'

const Agent = ({ data, onViewTransactions, onRemit }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleRemit = () => {
    setIsLoading(true)
    onRemit(data.transactions, () => setIsLoading(false))
  }

  return (
    <div className='rounded-lg bg-white flex flex-col'>
      <div className='border-b border-g-200 p-2'>
        <p className='font-medium'>{data.agent.name}</p>
      </div>
      <div className='text-dark-primary/70 py-2 text-sm p-2'>
        <p>Date: {data.date}</p>
        <p>
          Total:{' '}
          <span>
            {data.amount.toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })}
          </span>
        </p>
        <p>Transactions: {data.transactions.length}</p>
      </div>
      <div className='flex items-center gap-4 w-full p-2'>
        <button
          className='border-[0.2px] border-[#DE350B] bg-[#FFFFFF] hover:bg-[#f5ccc2] text-[#DE350B] rounded-3xl px-2 py-1 block text-center text-sm transition-all whitespace-nowrap w-fit'
          onClick={() => onViewTransactions(data)}
        >
          View transactions
        </button>
        <button
          className='border-[0.2px] border-[#DE350B] bg-[#DE350B] text-white rounded-3xl px-2 py-1 flex items-center justify-center text-center text-sm transition-all whitespace-nowrap w-24'
          onClick={handleRemit}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={16} color='inherit' />
          ) : (
            'Remit cash'
          )}
        </button>
      </div>
    </div>
  )
}

export default function RemitCashTransactions ({ metaTitle }) {
  const transactions = useSelector(
    state => state.transactions.unremitted_cash_transactions
  )
  const invoices = useSelector(state => state.invoices)
  const receipts = useSelector(state => state.receipts)

  const dispatch = useDispatch()
  const toast = useToast()

  const [isTransactionsOpen, setTransactionsOpen] = useState(false)
  const [activeAgent, setActiveAgent] = useState(null)
  const [isRemittingAll, setIsRemittingAll] = useState(false)

  const matchedTransactions = useMemo(() => {
    if (transactions && receipts.data && invoices.data) {
      return transactions.map(agent => {
        let transactions = agent.transactions.map(trx => {
          const invoice = invoices.data.find(inv => trx.invoice === inv.id)
          let invoiceData
          if (invoice) {
            const { id, order, bill_to } = invoice
            invoiceData = { id, order, bill_to }
          }

          const receipt = receipts.data.find(
            receipt => receipt.invoice === trx.invoice
          )?.name

          return {
            ...trx,
            invoiceData,
            receipt
          }
        })

        return {
          ...agent,
          transactions
        }
      })
    }
  }, [invoices.data, receipts.data, transactions])

  useEffect(() => {
    dispatch(fetchUnremittedCashTransactions())
    dispatch(fetchInvoices())
    dispatch(fetchReceipts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (transactions && activeAgent) {
      const agent = transactions.find(
        agent_trx => agent_trx.agent.id === activeAgent.id
      )
      setActiveAgent(agent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions])

  const handleViewTransactions = data => {
    setActiveAgent(data)
    setTransactionsOpen(true)
  }

  const handleCloseTransactions = () => {
    setTransactionsOpen(false)
    setActiveAgent(null)
  }

  const handleRemit = async (transactions, callback) => {
    const response = await transactionsApi.remitCashTransactions({
      transactions
    })

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Error remitting cash', 'error')
      }
    } else {
      toast('Remitted cash transactions!')
      dispatch(fetchUnremittedCashTransactions())
    }
    callback()
  }

  const handleRemitAll = () => {
    setIsRemittingAll(true)
    const payload = matchedTransactions.reduce((acc, agent_trxs) => {
      const references = agent_trxs.transactions.map(
        trx => trx.reference_number
      )
      return [...acc, ...references]
    }, [])
    handleRemit(payload, () => setIsRemittingAll(false))
  }

  const handleRemitTransactions = (trxs, callback) => {
    const payload = trxs.map(trx => trx.reference_number)

    handleRemit(payload, callback)
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Remit Cash Transactions'}></Page.Header>
      <Page.Body>
        {isTransactionsOpen && activeAgent && (
          <AgentCashTransactions
            isOpen={isTransactionsOpen}
            onClose={handleCloseTransactions}
            data={activeAgent}
            onRemit={handleRemitTransactions}
          />
        )}
        <div className='flex w-full gap-3 mb-2 lg:mb-4 justify-between items-center flex-wrap'>
          <h4 className='text-base'>
            Unremitted cash transactions by Booking Agents
          </h4>
          <button
            className='btn btn-primary ml-auto w-24 flex items-center justify-center'
            disabled={isRemittingAll || !matchedTransactions?.length}
            onClick={handleRemitAll}
          >
            {isRemittingAll ? (
              <CircularProgress size={16} color='inherit' />
            ) : (
              'Remit All'
            )}
          </button>
        </div>
        <div>
          {!matchedTransactions ? (
            <Loader />
          ) : (
            <div>
              {matchedTransactions.length ? (
                <div className='grid grid-container--fill gap-3'>
                  {matchedTransactions.map((item, id) => (
                    <Agent
                      data={item}
                      key={id}
                      onViewTransactions={handleViewTransactions}
                      onRemit={handleRemitTransactions}
                    />
                  ))}
                </div>
              ) : (
                <div className='pt-14 w-full flex flex-col items-center justify-center'>
                  <img
                    src={noDataIcon}
                    className='w-40 h-40 object-contain'
                    alt='no data icon'
                  />
                  <p className='text-gray-500 text-left my-2 font-semibold text-base md:text-xl'>
                    No transaction found
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </Page.Body>
    </Page>
  )
}
