import ReactModal from 'react-modal'
import confetti from '../../assets/confetti.svg'
import success from '../../assets/succes.png'

const customStyles = {
  content: {
    borderRadius: '20px',
    maxWidth: '768px',
    height: 'fit-content',
    maxHeight: '95%',
    margin: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function PaymentSuccessful ({ isOpen, transaction }) {
  return (
    <ReactModal style={customStyles} isOpen={isOpen}>
      <div className='relative'>
        <div className='flex flex-col items-center'>
          <img
            src={confetti}
            className='absolute top-4 left-1/2 -translate-x-1/2'
            alt='bg-confetti'
          />

          <div className='pt-14 pb-10 flex flex-col items-center w-full text-center'>
            <div className='mb-4'>
              <img src={success} alt='success' className='w-40' />
            </div>

            <div className='mb-8'>
              <h4 className='font-semibold mb-4'>Payment Successful</h4>
              <p className='text-[#3A3A3AB2] text-sm max-w-sm'>
                Your Payment has been processed! details of transaction are
                included below
              </p>
            </div>

            <div className='text-[#3A3A3A]'>
              <div className='mb-2'>
                <span className='font-semibold mr-2'>
                  Transaction Reference:
                </span>
                <span className='text-dark-primary/70'>
                  {transaction?.reference_number}
                </span>
              </div>
              <div>
                <span className='font-semibold mr-2'>Amount Paid:</span>
                <span className='text-dark-primary/70'>
                  {(transaction?.amount.total || 0).toLocaleString('en-NG', {
                    style: 'currency',
                    currency: 'NGN'
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
