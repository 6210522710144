import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: '',
  trialCount: 0
}

const paymentSocketSlice = createSlice({
  name: 'paymentSocket',
  initialState,
  reducers: {
    connect (state) {
      state.status = 'connecting'
      state.trialCount++
    },
    disconnect (state) {
      state.status = 'disconnecting'
    },
    connected (state) {
      state.status = 'connected'
    },
    disconnected (state) {
      state.status = 'disconnected'
    },
    message (state, { payload }) {
      state.message = payload
    },
    cleanup (state) {
      state.message = null
    }
  }
})

export default paymentSocketSlice.reducer

export const paymentSocketActions = paymentSocketSlice.actions
