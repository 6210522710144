import ReactModal from 'react-modal'
import { ReactComponent as Times } from '../../assets/times.svg'
import { getDate } from '../../utils'
import { Link } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import { CircularProgress } from '@mui/material'
import { useState } from 'react'
import { statuses } from '../../fixtures/transactionsStatus'
import Pill from '../globals/Pill'

const style = {
  content: {
    maxWidth: '750px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto',
    borderRadius: '20px'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function TransactionModal ({
  isOpen,
  onClose,
  trx,
  onRemit,
  canRemit
}) {
  const [isRemitting, setIsRemitting] = useState(false)

  const handleRemit = trx => {
    setIsRemitting(true)
    onRemit([trx], () => setIsRemitting(false))
  }

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onClose} style={style}>
      <div>
        <div
          className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer'
          onClick={onClose}
        >
          <Times />
        </div>
        <div className='flex flex-col p-4 gap-6'>
          <h5 className='text-[#3A3A3A] text-xl font-bold'>
            {trx.customer_name}
          </h5>
          <div className='flex flex-col gap-6 text-dark-primary/70'>
            <div className='flex flex-row items-start justify-between'>
              <div className='w-1/2 text-left'>
                <p>Order</p>
              </div>
              <div className='font-medium w-1/2 text-right'>
                <p>
                  <Link
                    to={`${ROUTES.ORDERS.path}?id=${trx.order}`}
                    className='hover:underline'
                  >
                    #{trx.order}
                  </Link>
                </p>
              </div>
            </div>
            <div className='flex flex-row items-start justify-between'>
              <div className='w-1/2 text-left'>
                <p>Invoice</p>
              </div>
              <div className='font-medium w-1/2 text-right'>
                <p>
                  <Link
                    to={`${ROUTES.INVOICES.path}/${trx.invoiceData?.id}`}
                    className='hover:underline'
                  >
                    #{trx.invoice}
                  </Link>
                </p>
              </div>
            </div>
            <div className='flex flex-row items-start justify-between'>
              <div className='w-1/2 text-left'>
                <p>Reference Number</p>
              </div>
              <div className='font-medium w-1/2 text-right'>
                <p>{trx.reference_number}</p>
              </div>
            </div>
            <div className='flex flex-row items-start justify-between'>
              <div className='w-1/2 text-left'>
                <p>Transaction Name</p>
              </div>
              <div className='font-medium w-1/2 text-right'>
                <p>{trx.name}</p>
              </div>
            </div>
            <div className='flex flex-row items-start justify-between'>
              <div className='w-1/2 text-left'>
                <p>Transaction Description</p>
              </div>
              <div className='font-medium w-1/2 text-right'>
                <p>{trx.description}</p>
              </div>
            </div>
            <div className='flex flex-row items-start justify-between'>
              <div className='w-1/2 text-left'>
                <p>Amount</p>
              </div>
              <div className='font-medium w-1/2 text-right'>
                <p>
                  {trx.amount.total.toLocaleString('en-NG', {
                    style: 'currency',
                    currency: 'NGN'
                  })}
                </p>
              </div>
            </div>
            <div className='flex flex-row items-start justify-between'>
              <div className='w-1/2 text-left'>
                <p>Date & Time</p>
              </div>
              <div className='font-medium w-1/2 text-right'>
                <p>{getDate(trx.date_time)}</p>
              </div>
            </div>
            {(trx.payment_method === 'POS' || trx.payment_method === 'TRF') && (
              <div className='flex flex-row items-start justify-between'>
                <div className='w-1/2 text-left'>
                  <p>Bank</p>
                </div>
                <div className='font-medium w-1/2 text-right'>
                  <p>Opay Bank</p>
                </div>
              </div>
            )}
            <div className='flex flex-row items-start justify-between'>
              <div className='w-1/2 text-left'>
                <p>Payment Method</p>
              </div>
              <div className='font-medium w-1/2 text-right'>
                <p>
                  {trx.payment_method === 'POS'
                    ? 'POS'
                    : trx.payment_method === 'TRF'
                    ? 'Transfer'
                    : 'CASH'}
                </p>
              </div>
            </div>
            <div className='flex flex-row items-start justify-between'>
              <div className='w-1/2 text-left'>
                <p>Status</p>
              </div>
              <div className='font-medium w-1/2 text-right'>
                <Pill
                  name={statuses[trx.status].name}
                  theme={statuses[trx.status].theme}
                />
              </div>
            </div>
            <div className='flex flex-row items-start justify-between'>
              <div className='w-1/2 text-left'></div>
              <div className='font-medium w-1/2 flex justify-end gap-4'>
                {trx.status === 2 && trx.receipt && (
                  <Link
                    to={`${ROUTES.RECEIPTS.path}/${trx.receipt}`}
                    className='border-[0.2px] border-[#DE350B] bg-[#F5CCC299] hover:bg-[#f5ccc2] text-[#DE350B] rounded-3xl px-2 py-1 block text-center text-sm transition-all whitespace-nowrap w-fit'
                  >
                    View receipt
                  </Link>
                )}
                {(trx.payment_method === '' || trx.payment_method === 'CASH') &&
                  (trx.remitted ? (
                    <span className='bg-[#d8f7da99] border border-[#61A966] text-[#61A966] px-2 py-1 rounded-3xl flex items-center justify-center whitespace-nowrap text-sm'>
                      Remitted
                    </span>
                  ) : canRemit ? (
                    <button
                      className='border-[0.2px] border-[#DE350B] bg-[#DE350B] text-white rounded-3xl px-2 py-1 flex items-center justify-center text-center text-sm whitespace-nowrap w-24'
                      onClick={() => handleRemit(trx)}
                      disabled={isRemitting}
                    >
                      {isRemitting ? (
                        <CircularProgress size={16} color='inherit' />
                      ) : (
                        'Remit cash'
                      )}
                    </button>
                  ) : (
                    ''
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
