import { HiOutlineMinusSmall } from 'react-icons/hi2'

export default function Unselect ({ onClick }) {
  return (
    <span
      className='group flex items-center justify-center size-5 rounded-sm bg-white/10 ring-1 ring-white/15 border cursor-pointer'
      role='button'
      onClick={onClick}
    >
      <HiOutlineMinusSmall className='size-4' />
    </span>
  )
}
