import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import manifestApi from '../api/manifest'
import { parseError } from '../utils'

const initialState = {
  singleManifest: null
}

export const fetchSingleManifest = createAsyncThunk(
  'singleManifest/fetch',
  async data => {
    const response = await manifestApi.getManifest(data)

    if (!response.ok) {
      return parseError(response)
    }

    return response.data
  }
)

const singleManifestSlice = createSlice({
  name: 'manifest',
  initialState,
  reducers: {
    // updateData(state, { payload }) {
    //   state.data = payload;
    // },
  },
  extraReducers: builder => {
    builder.addCase(fetchSingleManifest.fulfilled, (state, action) => {
      state.singleManifest = action?.payload?.payload
    })
  }
})

export default singleManifestSlice.reducer
