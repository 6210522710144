import React from 'react'
import { Field, useFormikContext } from 'formik'

const DataList = ({
  name,
  title,
  children,
  value,
  handleOnChange = () => {},
  required,
  type
}) => {
  const formikContext = useFormikContext()
  if (formikContext) {
    return (
      <div className='form-control w-full'>
        <label className='label'>
          <span className='label-text'>{title}</span>
        </label>
        <Field name={name}>
          {({ field, form, meta }) => {
            return (
              <>
                <input
                  placeholder={title}
                  {...field}
                  onChange={e => {
                    field.onChange(e)
                    handleOnChange(e.target.value, e.target.name)
                  }}
                  list={name}
                  name={name}
                  className='input'
                />
                <datalist id={name}>{children}</datalist>
                {meta.touched && meta.error ? (
                  <p className='text-red-500 text-sm'>{meta.error}</p>
                ) : null}
              </>
            )
          }}
        </Field>
      </div>
    )
  }

  return (
    <div className='form-control w-full'>
      <label className='label'>
        <span className='label-text'>{title}</span>
      </label>
      <input
        placeholder={title}
        onChange={e => {
          handleOnChange(e.target.value, e.target.name)
        }}
        list={name}
        name={name}
        value={value}
        className='input'
        required={required}
        type={type}
      />
      <datalist id={name}>{children}</datalist>
    </div>
  )
}

export default DataList
