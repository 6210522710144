import Modal from 'react-modal'
import { ReactComponent as Times } from '../../../../assets/times.svg'
import { FaCheck } from 'react-icons/fa'
import { BsInfoCircle } from 'react-icons/bs'
import { useState } from 'react'
import { CircularProgress } from '@mui/material'

const styles = {
  content: {
    borderRadius: '20px',
    width: '450px',
    height: 'fit-content',
    margin: 'auto',
    inset: '20px'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function MultipieceBillTo ({
  isOpen,
  onClose,
  order,
  nextActionType = 'add-order',
  actionText,
  nextAction
}) {
  const [billTo, setBillTo] = useState('')
  const [isLoading, setLoading] = useState(false)

  const handleProceed = async () => {
    setLoading(true)
    await nextAction(billTo)
    setLoading(false)
  }

  return (
    <Modal style={styles} isOpen={isOpen} onRequestClose={onClose}>
      <div>
        <button
          className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 cursor-pointer ml-auto flex'
          onClick={onClose}
        >
          <Times />
        </button>
        <h3 className='text-lg text-dark-primary font-medium text-center mb-3'>
          Choose bill to
        </h3>
        <div>
          <div className='mb-5'>
            <p className='mb-4'>Choose who to bill this multipiece order to:</p>
            <div className='flex flex-wrap items-center gap-4 mb-3'>
              <button
                onClick={() => setBillTo('sender')}
                className='btn text-[#4F4F4F] gap-1'
              >
                <p>Sender: {order.sender?.contact?.name}</p>
                {billTo === 'sender' && <FaCheck size={16} color='green' />}
              </button>
              <button
                onClick={() => setBillTo('receiver')}
                className='btn text-[#4F4F4F] gap-1'
              >
                <p>Receiver: {order.receiver?.contact?.name}</p>
                {billTo === 'receiver' && <FaCheck size={16} color='green' />}
              </button>
            </div>

            {nextActionType === 'add-order' && (
              <div className='flex gap-1 '>
                <BsInfoCircle
                  size={14}
                  style={{ color: 'rgb(51, 51, 51 / 0.6)' }}
                  className='mt-0.5'
                />
                <p className='text-sm text-dark-primary/60'>
                  You will not be able to change the selected customer in any of
                  the orders in this multipiece.
                </p>
              </div>
            )}
          </div>

          <button
            className='btn btn-primary w-full'
            disabled={!billTo}
            onClick={handleProceed}
          >
            {isLoading ? (
              <CircularProgress size={28} color='inherit' />
            ) : (
              actionText
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}
