export const docs = [
  ['Annual report', 'Annual report'],
  ['Bill of lading', 'Bill of lading'],
  ['Certificates', 'Certificates'],
  ['Charts, graphs', 'Charts, graphs'],
  ['Completed Forms', 'Completed Forms'],
  ['Credit note', 'Credit note'],
  ['Deeds', 'Deeds'],
  ['Diplomatic Material', 'Diplomatic Material'],
  ['Documents- general business', 'Documents- general business'],
  ['Educational material- printed', 'Educational material- printed'],
  ['Examination papers', 'Examination papers'],
  ['Identity document', 'Identity document'],
  ['Invoice - not blank', 'Invoice - not blank'],
  ['Letter, correspondence', 'Letter, correspondence'],
  ['Manuscripts', 'Manuscripts'],
  ['Medical Examination Result', 'Medical Examination Result'],
  ['Music - printed, manuscript', 'Music - printed, manuscript'],
  ['Passports', 'Passports'],
  ['Personal mail', 'Personal mail'],
  [
    'Photographs - as part of business reports',
    'Photographs - as part of business reports'
  ],
  [
    'Plans, drawings - for architectural, industrial, engineering purposes',
    'Plans, drawings - for architectural, industrial,engineering purposes'
  ],
  ['Price lists', 'Price lists'],
  ['Printed matter', 'Printed matter'],
  ['Ship manifest - computer ', 'Ship manifest - computer '],
  ['Shipping schedules', 'Shipping schedules'],
  ['Visa applications', 'Visa applications']
]
