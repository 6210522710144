import {
  Page as PDFPage,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from '@react-pdf/renderer'
import { pdfjs } from 'react-pdf'
import { formatAmount, getDate, capitalizeFirstLetter } from '../../utils'
import logo from '../../assets/aajLogo3.png'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function InvoicePDFSheet ({ invoice }) {
  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#FFFFFF'
    },
    page_wrapper: {
      padding: 20
    },
    header_section: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10
    },
    header_logo_section: {
      width: '50%'
    },
    header_logo: {
      width: 120,
      height: 'auto',
      objectFit: 'contain'
    },
    header_description: {
      fontSize: 10,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 6
    },
    header_description_title: {
      textTransform: 'uppercase',
      color: '#BDBDBD',
      fontSize: 10
    },
    header_description_detail: {
      color: '#333333',
      fontSize: 10
    },
    content_header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: 10
    },
    content_description: {
      fontSize: 9,
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      marginBottom: 16
    },
    content__items__header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#FFEDD5',
      paddingVertical: 10,
      paddingHorizontal: 3,
      border: '1px solid #D8D8D8'
    },
    content__items__wrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      fontSize: 10,
      border: '1px solid #D8D8D8'
    },
    content__items__item: {
      width: invoice.type === 'LC' ? '25%' : '20%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: '1px solid #D8D8D8',
      paddingVertical: 8
    },
    content__add_info__header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: '#FFEDD5',
      paddingVertical: 10,
      paddingHorizontal: 3,
      marginTop: 30,
      border: '1px solid #D8D8D8'
    },
    content__add_info__item: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: 10,
      border: '1px solid #D8D8D8',
      height: 20
    },
    content__add_info__item__title: {
      width: '30%',
      height: '100%',
      borderRight: '1px solid #D8D8D8',
      justifyContent: 'center'
    },
    content__add_info__item__title__text: {
      marginLeft: 5
    },
    content__add_info__item__description: {
      width: '70%'
    },
    content__add_info__item__description__text: {
      marginLeft: 15
    }
  })

  return (
    <Document>
      <PDFPage size='A4' style={styles.page}>
        <View style={styles.page_wrapper}>
          <View style={styles.header_section}>
            <View style={styles.header_logo_section}>
              <Image src={logo} style={styles.header_logo} />
            </View>
            <View style={styles.header_description}>
              <Text>AAJ EXPRESS LOGISTIC LTD</Text>
              <Text style={styles.header_description_detail}>
                13 Oguntona Crescent Gbagada, Lagos, Nigeria.
              </Text>
              <Text style={styles.header_description_detail}>
                +2349088991086
              </Text>
              <Text>support@aajexpress.org</Text>
              <Text>www.aajexpress.org</Text>
            </View>
          </View>
          <View style={{ marginBottom: 10, marginTop: 30 }}>
            <Text
              style={{ fontSize: 18, color: '#FF4D00', fontWeight: 'bold' }}
            >
              Invoice #{invoice.id}
            </Text>
          </View>
          <View style={styles.content_header}>
            <View
              style={{
                width: '100px',
                gap: 2,
                ...styles.content_description
              }}
            >
              <View>
                <Text>Origin:</Text>
              </View>
              <Text>
                {/* {invoice.origin.name} */}
                {capitalizeFirstLetter(
                  invoice.origin.name?.split(' ')[0]?.toLowerCase()
                )}{' '}
                {capitalizeFirstLetter(
                  invoice.origin.name?.split(' ')[1]?.toLowerCase()
                )}
              </Text>
              <Text>{invoice.origin.address}</Text>
              <Text>{invoice.origin.postal_code}</Text>
            </View>

            <View
              style={{
                letterSpacing: 0.5,
                width: '100px',
                gap: 2,
                ...styles.content_description
              }}
            >
              <View>
                <Text>Ship to:</Text>
              </View>
              <Text>{capitalizeFirstLetter(invoice.destination.name)}</Text>
              <Text>{invoice.destination.address}</Text>
              <Text>{invoice.destination.city}</Text>
              <Text>{invoice.destination.state}</Text>
              <Text>{invoice.destination.country}</Text>
            </View>
            <View
              style={{
                width: '100px',
                gap: 2,
                ...styles.content_description
              }}
            >
              <View>
                <Text>Billed to:</Text>
              </View>
              <Text>{capitalizeFirstLetter(invoice.customer_name)}</Text>
            </View>
            <View
              style={{ width: '20%', gap: 4, ...styles.content_description }}
            >
              <View>
                <Text>Date raised:</Text>
              </View>
              <Text>{getDate(invoice.date_raised)}</Text>
            </View>
            <View
              style={{ width: '15%', gap: 2, ...styles.content_description }}
            >
              <View>
                <Text>Ship via:</Text>
              </View>
              <Text>{invoice.meta.tpl_service}</Text>
            </View>
            <View
              style={{ width: '15%', gap: 2, ...styles.content_description }}
            >
              <View>
                <Text>Tracking</Text>
              </View>
              <Text>{invoice.tracking_no}</Text>
            </View>
          </View>

          <View style={styles.content__items__header}>
            <Text style={{ fontSize: 10 }}> ITEMS</Text>
          </View>
          <View style={styles.content__items__wrapper}>
            <View style={styles.content__items__item}>
              <Text style={{ fontSize: 10 }}>NAME</Text>
            </View>

            {invoice.type === 'IN' && (
              <View style={styles.content__items__item}>
                <Text style={{ fontSize: 10 }}>WEIGHT</Text>
              </View>
            )}
            <View style={styles.content__items__item}>
              <Text style={{ fontSize: 10 }}>QUANTITY</Text>
            </View>

            <View style={styles.content__items__item}>
              <Text style={{ fontSize: 10 }}> UNIT PRICE</Text>
            </View>

            <View style={styles.content__items__item}>
              <Text style={{ fontSize: 10 }}>TOTAL AMOUNT</Text>
            </View>
          </View>
          {invoice?.payload?.items?.map((item, index) => (
            <View style={styles.content__items__wrapper} key={index}>
              <View style={styles.content__items__item}>
                <Text>{item.name}</Text>
              </View>

              {invoice.type === 'IN' && (
                <View style={styles.content__items__item}>
                  <Text>{item.weight}</Text>
                </View>
              )}
              <View style={styles.content__items__item}>
                <Text>
                  {item.quantity} ({item.unitMeasurement})
                </Text>
              </View>

              <View style={styles.content__items__item}>
                <Text>NGN {(item.price / item.quantity).toLocaleString()}</Text>
              </View>

              <View style={styles.content__items__item}>
                <Text>NGN {formatAmount(item.price)}</Text>
              </View>
            </View>
          ))}
          <View style={styles.content__add_info__header}>
            <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
              ADDITIONAL INFORMATION
            </Text>
          </View>
          <View style={styles.content__add_info__item}>
            <View style={styles.content__add_info__item__title}>
              <Text style={styles.content__add_info__item__title__text}>
                Weight
              </Text>
            </View>
            <View style={styles.content__add_info__item__description}>
              <Text style={styles.content__add_info__item__description__text}>
                {invoice.weight}kg
              </Text>
            </View>
          </View>
          <View style={styles.content__add_info__item}>
            <View style={styles.content__add_info__item__title}>
              <Text style={styles.content__add_info__item__title__text}>
                Rate
              </Text>
            </View>
            <View style={styles.content__add_info__item__description}>
              <Text style={styles.content__add_info__item__description__text}>
                {invoice.rate === 'SR' ? 'Standard Rate' : 'Cargo Rate'}
              </Text>
            </View>
          </View>
          <View style={styles.content__add_info__item}>
            <View style={styles.content__add_info__item__title}>
              <Text style={styles.content__add_info__item__title__text}>
                Served by
              </Text>
            </View>
            <View style={styles.content__add_info__item__description}>
              <Text style={styles.content__add_info__item__description__text}>
                {invoice.served_by}
              </Text>
            </View>
          </View>
          <View style={styles.content__add_info__item}>
            <View style={styles.content__add_info__item__title}>
              <Text style={styles.content__add_info__item__title__text}>
                Shipping fee
              </Text>
            </View>
            <View style={styles.content__add_info__item__description}>
              <Text style={styles.content__add_info__item__description__text}>
                NGN {formatAmount(invoice.extra_charges.on_shipping_fee)}
              </Text>
            </View>
          </View>
          <View style={styles.content__add_info__item}>
            <View style={styles.content__add_info__item__title}>
              <Text style={styles.content__add_info__item__title__text}>
                Add ons total
              </Text>
            </View>
            <View style={styles.content__add_info__item__description}>
              <Text style={styles.content__add_info__item__description__text}>
                NGN{' '}
                {formatAmount(
                  invoice.addons?.total_addons_price === 0
                    ? `${invoice.addons?.total_addons_price}.00`
                    : invoice.addons?.total_addons_price
                )}
              </Text>
            </View>
          </View>
          <View style={styles.content__add_info__item}>
            <View style={styles.content__add_info__item__title}>
              <Text style={styles.content__add_info__item__title__text}>
                Insurance (
                {invoice.insurance.type === 'FR'
                  ? 'Free'
                  : invoice.insurance.type === 'PM'
                  ? 'Premium'
                  : invoice.insurance.type === 'SD'
                  ? 'Standard'
                  : invoice.insurance.type === 'EI'
                  ? 'Electronics Insurance'
                  : invoice.insurance.type === 'NE'
                  ? 'Non-Electronics Insurance'
                  : invoice.insurance.type === 'HI'
                  ? 'Haulage'
                  : null}
                )
              </Text>
            </View>
            <View style={styles.content__add_info__item__description}>
              <Text style={styles.content__add_info__item__description__text}>
                NGN{' '}
                {formatAmount(
                  invoice.insurance.fee === 0
                    ? `${invoice.insurance.fee}.00`
                    : invoice.insurance.fee
                )}
              </Text>
            </View>
          </View>
          <View style={styles.content__add_info__item}>
            <View style={styles.content__add_info__item__title}>
              <Text style={styles.content__add_info__item__title__text}>
                Fuel surcharge
              </Text>
            </View>
            <View style={styles.content__add_info__item__description}>
              <Text style={styles.content__add_info__item__description__text}>
                NGN{' '}
                {formatAmount(
                  invoice.extra_charges?.fuel_surcharge?.amount === 0
                    ? `${invoice.extra_charges?.fuel_surcharge?.amount}.00`
                    : invoice.extra_charges?.fuel_surcharge?.amount
                )}
              </Text>
            </View>
          </View>
          {invoice.extra_charges.security_handling_fee && (
            <View style={styles.content__add_info__item}>
              <View style={styles.content__add_info__item__title}>
                <Text style={styles.content__add_info__item__title__text}>
                  Security handling fee
                </Text>
              </View>
              <View style={styles.content__add_info__item__description}>
                <Text style={styles.content__add_info__item__description__text}>
                  NGN{' '}
                  {formatAmount(
                    invoice.extra_charges.security_handling_fee.amount
                  )}
                </Text>
              </View>
            </View>
          )}
          {invoice.extra_charges.packaging_fee && (
            <View style={styles.content__add_info__item}>
              <View style={styles.content__add_info__item__title}>
                <Text style={styles.content__add_info__item__title__text}>
                  Packaging fee
                </Text>
              </View>
              <View style={styles.content__add_info__item__description}>
                <Text style={styles.content__add_info__item__description__text}>
                  NGN {formatAmount(invoice.extra_charges.packaging_fee.amount)}
                </Text>
              </View>
            </View>
          )}
          <View style={styles.content__add_info__item}>
            <View style={styles.content__add_info__item__title}>
              <Text style={styles.content__add_info__item__title__text}>
                Subtotal
              </Text>
            </View>
            <View style={styles.content__add_info__item__description}>
              <Text style={styles.content__add_info__item__description__text}>
                NGN {formatAmount(invoice.sub_total)}
              </Text>
            </View>
          </View>
          <View style={styles.content__add_info__item}>
            <View style={styles.content__add_info__item__title}>
              <Text style={styles.content__add_info__item__title__text}>
                Tax(Vat 7.5%)
              </Text>
            </View>
            <View style={styles.content__add_info__item__description}>
              <Text style={styles.content__add_info__item__description__text}>
                NGN {formatAmount(invoice.tax)}
              </Text>
            </View>
          </View>
          <View style={styles.content__add_info__item}>
            <View style={styles.content__add_info__item__title}>
              <Text style={styles.content__add_info__item__title__text}>
                Total
              </Text>
            </View>
            <View style={styles.content__add_info__item__description}>
              <Text style={styles.content__add_info__item__description__text}>
                NGN {formatAmount(invoice.total)}
              </Text>
            </View>
          </View>
          <View style={styles.content__add_info__item}>
            <View style={styles.content__add_info__item__title}>
              <Text style={styles.content__add_info__item__title__text}>
                Balance due
              </Text>
            </View>
            <View style={styles.content__add_info__item__description}>
              <Text style={{ marginLeft: 15, fontWeight: 700 }}>
                NGN {formatAmount(invoice.balance_due)}
              </Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              fontSize: 10,
              lineHeight: 1.5,
              marginTop: 10,
              color: '#333333'
            }}
          >
            <View>
              <Text>
                Thank you for shipping with us. You can track your shipment with
                the tracking id at our website.
              </Text>
            </View>
            <View>
              <Text>Tracking url: www.aajexpress.org/#track</Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              fontSize: 8,
              marginTop: 30,
              color: '#333333',
              backgroundColor: '#FFEDD5',
              paddingHorizontal: 20,
              paddingVertical: 8
            }}
          >
            <Text>
              Custom duties and taxes may apply to your items in the destination
              country. AAJ has no control over these charges as these tariffs
              are determined by the customs of the destination country and
              payable only by the receiver.
            </Text>
            <View style={{ height: 10 }} />
            <Text>
              AAJ shall not be responsible for any delays arising from customs
              border control. The shipper and receiver are responsible for
              providing all documentation needed for customs clearance. In the
              event the shipper or receiver fails to provide this, the shipper
              will be charged for the return of the shipment to the origin.
            </Text>
            <View style={{ height: 10 }} />
            <Text>
              AAJ shall not be liable for confiscation or destruction of illegal
              or illicit items such as copied or unauthorized designer brands
              and other illegal items defined by the destination country's
              government agencies.
            </Text>
          </View>

          <View
            style={{
              width: '100%',
              fontSize: 10,
              lineHeight: 1.5,
              marginTop: 20,
              color: 'rgb(209,213,219)'
            }}
          >
            <Text>
              Shipper agrees to the AAJ Express Logistics terms found at
              www.aajexpress.org and AAJ Express service centers.
            </Text>
          </View>
        </View>
      </PDFPage>
    </Document>
  )
}
