import { HiCheck } from 'react-icons/hi2'
import { Checkbox as HeadlessUiCheckbox } from '@headlessui/react'

export default function Checkbox ({ checked, onChange }) {
  return (
    <HeadlessUiCheckbox
      checked={checked}
      onChange={onChange}
      className='group flex items-center justify-center size-5 rounded-sm bg-white/10 ring-1 ring-white/15 ring-inset data-[checked]:bg-main-primary-2 border data-[checked]:border-main-primary-2'
    >
      <HiCheck className='hidden size-4 fill-white group-data-[checked]:block' />
    </HeadlessUiCheckbox>
  )
}
