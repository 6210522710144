export const statuses = {
  1: {
    name: 'Pending',
    theme: {
      primary: '#FFC700',
      secondary: '#FFF6D799'
    }
  },
  2: {
    name: 'Success',
    theme: {
      primary: '#00AB44',
      secondary: '#00AB4433'
    }
  },
  3: {
    name: 'Failed',
    theme: {
      primary: '#DE350B',
      secondary: '#F5CCC299'
    }
  },
  4: {
    name: 'Closed',
    class: 'text-dark-primary',
    theme: {
      primary: '#0c1a57',
      secondary: '#0c1a5733'
    }
  },
  // 5: {
  //   name: 'Pay Later',
  //   class: ''
  // },
  6: {
    name: 'Voided',
    theme: {
      primary: '#DE350B',
      secondary: '#F5CCC299'
    }
  }
}
