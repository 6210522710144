import CenterManifestPrintSheet from '../../components/manifests/CenterManifestPrintSheet'
import Page from '../../containers/Page'

export default function CenterManifestSheet ({ metaTitle }) {
  return (
    <Page metaTitle={metaTitle}>
      <Page.Body>
        <CenterManifestPrintSheet />
      </Page.Body>
    </Page>
  )
}
