import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
  memo
} from 'react'
import { reverseArr } from '../../../helpers/reverseArr'
import { Left, Right } from './arrows'
import { scrollToTarget } from '../../../utils'
import { AuthLayoutContext } from '../../../containers/AuthLayout'
import { twMerge } from 'tailwind-merge'

const serialize = data => {
  return data.map((item, id) => ({ s_n: id + 1, ...item }))
}

export default function Pagination (props) {
  const {
    data: initialData,
    setTableData,
    tableId,
    style,
    activeStyle,
    reverse = true,
    setSerializedData = () => {},
    findItem
  } = props
  const [activePage, setActivePage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const perPageOptions = [10, 20, 50, 100]

  const data = useMemo(() => {
    let result = reverse ? reverseArr(initialData) : initialData

    const serialized = serialize(result)
    setSerializedData(serialized)

    return serialized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, reverse])

  const pages = useMemo(() => {
    const newArr = []
    for (let i = 0; i < data.length; i += perPage) {
      newArr.push(data.slice(i, i + perPage))
    }
    setActivePage(1)
    return newArr
  }, [data, perPage])

  const handleChangePerPage = ({ target }) => {
    setPerPage(target.value)
  }

  const noOfPages = useMemo(() => pages.length, [pages])

  const { layoutContainer } = useContext(AuthLayoutContext)

  useEffect(() => {
    if (pages.length) {
      setTableData(pages[activePage - 1])
    } else {
      setTableData([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, pages])

  useEffect(() => {
    if (findItem > 0) {
      const page = Math.ceil(findItem / perPage)
      setActivePage(page)
    }
  }, [findItem, perPage])

  const listOfPages = useMemo(() => {
    const arr = []
    let i = 0
    while (i < noOfPages) {
      arr.push(i + 1)
      i++
    }
    return arr
  }, [noOfPages])

  const [copyOfListOfPages, setCopyOfListOfPages] = useState(listOfPages)

  useEffect(() => {
    setCopyOfListOfPages(listOfPages)
  }, [listOfPages])

  const scrollToTableTop = useCallback(() => {
    scrollToTarget(`#${tableId}`, layoutContainer, 150)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableId])

  const onPrev = () => {
    if (activePage !== 1) {
      if (listOfPages.length > 6) {
        if (
          activePage === copyOfListOfPages[0] ||
          activePage === copyOfListOfPages[1]
        ) {
          if (copyOfListOfPages[0] === 1) {
          } else {
            setCopyOfListOfPages(state => {
              let copy = [...state]
              copy.unshift(copyOfListOfPages[0] - 1)
              return copy
            })
            scrollToTableTop()
          }
        }
      }
    }

    activePage !== 1 && setActivePage(state => state - 1)
  }

  const onNext = () => {
    if (activePage !== noOfPages) {
      if (copyOfListOfPages.length > 6) {
        setCopyOfListOfPages(state => {
          let copy = [...state]
          copy.shift()
          return copy
        })
      }
      activePage !== noOfPages && setActivePage(state => state + 1)
      scrollToTableTop()
    }
  }

  const PageButton = memo(({ value, onPageClick }) => {
    return (
      <span
        className={twMerge(
          'text-center transition-all cursor-pointer pagination-button text-sm',
          activePage === value ? 'active' : ''
        )}
        style={activePage === value ? activeStyle : style}
        onClick={() => {
          onPageClick()
          scrollToTableTop()
        }}
        role='button'
        aria-label='page number'
        tabIndex={0}
      >
        {value}
      </span>
    )
  })

  if (pages.length === 0) {
    return <></>
  }

  return (
    <div className='flex flex-col md:flex-row md:items-center w-full justify-between p-3 lg:pt-5 text-sm'>
      <div className='flex items-center gap-2'>
        <span>Page size:</span>
        <select
          value={perPage}
          onChange={handleChangePerPage}
          className='bg-white border border-g-200 w-20 rounded-lg'
        >
          {perPageOptions.map((row, id) => (
            <option key={id} value={row}>
              {row}
            </option>
          ))}
        </select>
      </div>
      <div
        className='flex flex-row justify-between items-center gap-2 lg:gap-4'
        aria-describedby='pagination'
      >
        {/* previous */}
        <Left onPrev={onPrev} disabled={activePage === 1} tableId={tableId} />

        {/* page numbers */}
        <div className='w-fit text-sm flex gap-1.5 justify-center items-center flex-row flex-wrap'>
          {(() => {
            if (listOfPages.length <= 6) {
              return (
                <>
                  {copyOfListOfPages.map((value, id) => (
                    <PageButton
                      key={id}
                      value={value}
                      onPageClick={() => setActivePage(value)}
                    />
                  ))}
                </>
              )
            } else {
              return (
                <>
                  {copyOfListOfPages[0] >= 2 && (
                    <>
                      <PageButton
                        value={1}
                        onPageClick={() => {
                          setActivePage(1)
                          setCopyOfListOfPages(listOfPages)
                        }}
                      />
                      {copyOfListOfPages[0] > 2 && (
                        <span className='text-dark-primary/70 mx-1'>. . .</span>
                      )}
                    </>
                  )}

                  {copyOfListOfPages.length === 6 ? (
                    <>
                      {copyOfListOfPages.map((value, id) => (
                        <PageButton
                          value={value}
                          key={id}
                          onPageClick={() => {
                            if (
                              value ===
                              copyOfListOfPages[copyOfListOfPages.length - 1]
                            ) {
                              setCopyOfListOfPages(state => {
                                let copy = [...state]
                                copy.push(value + 1)
                                return copy
                              })
                            }
                            setActivePage(value)
                          }}
                        />
                      ))}
                      {copyOfListOfPages[copyOfListOfPages.length - 1] !==
                        listOfPages[listOfPages.length - 1] && (
                        <>
                          <span className='text-dark-primary/70 mx-1'>
                            . . .
                          </span>
                          <PageButton
                            value={listOfPages[listOfPages.length - 1]}
                            onPageClick={() => {
                              setCopyOfListOfPages(listOfPages)
                              setActivePage(listOfPages[listOfPages.length - 1])
                            }}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {copyOfListOfPages.slice(0, 3).map((value, id) => (
                        <PageButton
                          key={id}
                          value={value}
                          onPageClick={() => {
                            if (value === copyOfListOfPages[2]) {
                              setCopyOfListOfPages(state => {
                                let copy = [...state]
                                copy.shift()
                                return copy
                              })
                            }
                            if (value === copyOfListOfPages[0] && value !== 2) {
                              if (value !== 1) {
                                setCopyOfListOfPages(state => {
                                  let copy = [...state]
                                  copy.unshift(copyOfListOfPages[0] - 1)
                                  return copy
                                })
                              }
                            }

                            setActivePage(value)
                          }}
                        />
                      ))}

                      <span className='text-dark-primary/70 mx-1'>. . .</span>

                      {copyOfListOfPages.slice(-3).map((value, id) => (
                        <PageButton
                          key={id}
                          value={value}
                          onPageClick={() => {
                            if (
                              value ===
                              copyOfListOfPages[copyOfListOfPages.length - 3]
                            ) {
                              setCopyOfListOfPages(state => {
                                let copy = [...state]
                                copy.pop()
                                return copy
                              })
                            }

                            setActivePage(value)
                          }}
                        />
                      ))}

                      {copyOfListOfPages[copyOfListOfPages.length - 1] !==
                        listOfPages[listOfPages.length - 1] && (
                        <>
                          {copyOfListOfPages[copyOfListOfPages.length - 1] !==
                            listOfPages[listOfPages.length - 2] && (
                            <span className='text-p-100 mx-1'>. . .</span>
                          )}
                          <PageButton
                            value={listOfPages[listOfPages.length - 1]}
                            onPageClick={() => {
                              setCopyOfListOfPages(listOfPages)
                              setActivePage(listOfPages[listOfPages.length - 1])
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )
            }
          })()}
        </div>

        {/* next */}
        <Right
          onNext={onNext}
          disabled={activePage === noOfPages}
          tableId={tableId}
        />
      </div>
    </div>
  )
}
