import bookingClient from './clients/bookingClient'

const endpoint = 'dashboard/'

const getDashboard = async query => {
  const response = await bookingClient.get(
    query ? `${endpoint}${query}` : endpoint
  )

  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDashboard
}
