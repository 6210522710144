import React, { useState } from 'react'
import Modal from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import { ReactComponent as Times } from '../../../assets/times.svg'
import { capitalizeFirstLetter } from '../../../utils'
import { useMemo } from 'react'

const styles = {
  content: {
    borderRadius: '20px',
    maxWidth: '450px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export const ManifestFilterModal = ({
  openModal,
  setOpenModal,
  setDestination,
  destination,
  handleFilter,
  setSearchVal
}) => {
  const allHub = useSelector(state => state?.hubs?.hubs)
  const allHubs = useMemo(()=>
    !allHub ? [] : [...allHub]?.sort((a, b) => a.name.localeCompare(b.name))
  ,[allHub])
  const [disabled, setDisabled] = useState(true)

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={setOpenModal}
        style={styles}
        contentLabel='Example Modal'
      >
        <div
          className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer'
          onClick={() => {
            setSearchVal('')
            setOpenModal(false)
            setDisabled(true)
          }}
        >
          <Times />
        </div>
        <div className='text-xl font-semibold'>Destination</div>
        <select
          name='period'
          className='w-full h-[40px] mt-4'
          onChange={e => {
            setDestination(e.target.value)
            setDisabled(false)
          }}
        >
          <option className='bg-white' value={''}>
            {' '}
            Select destinations
          </option>
          {allHubs?.map(hub => (
            <option className='bg-white' value={hub?.name}>
              {' '}
              {capitalizeFirstLetter(hub?.name)}{' '}
            </option>
          ))}
        </select>
        <div className='w-full flex justify-end mt-12'>
          <button
            className={`btn ${
              disabled ? 'btn-neutral btn-disabled' : 'btn-primary'
            } btn-rounded`}
            disabled={disabled}
            onClick={() => {
              setOpenModal(false)
              handleFilter(destination)
            }}
          >
            Apply Filters
          </button>
        </div>
      </Modal>
    </div>
  )
}
