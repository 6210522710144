import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Popover, PopoverButton,
  PopoverPanel } from '@headlessui/react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import useToast from '../../../hooks/useToast'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Times } from '../../../assets/times.svg'
import { ReactComponent as User } from '../../../assets/assign-user.svg'
import { ReactComponent as Print } from '../../../assets/print.svg'
import { HiOutlinePencil } from 'react-icons/hi2'
import { ReactComponent as Truck } from '../../../assets/truck.svg'
import { ReactComponent as Bike } from '../../../assets/bike.svg'
import { IoIosSearch } from 'react-icons/io'
import manifestApi from '../../../api/manifest'

export const MoreIconDropdown = ({ sn, id, manifestId, component, mType }) => {
  const ridersData = useSelector(state => state?.riders?.riders)
  const toast = useToast()
  const navigate = useNavigate()
  const [toggleMore, setToggleMore] = useState(0)
  const [assignee, setAssignee] = useState(false)
  const [selected, setSelected] = useState(false)
  const [checkSelected, setCheckSelected] = useState('')
  const [riderId, setRiderId] = useState('')
  const [riderName, setRiderName] = useState('')
  const [loading, setLoading] = useState(false)
  const [riderVal, setRiderVal] = useState([])
  const [riderSearchedVal, setRiderSearchedVal] = useState('')

  const handleSelected = value => {
    setCheckSelected(value)
  }

  const handleAssign = async () => {
    setLoading(true)
    const result = await manifestApi.assignManifest(manifestId, {
      manifest_type: mType,
      rider: { id: riderId, name: riderName }
    })

    if (result.ok) {
      toast('Rider assigned', 'success')
      setLoading(false)
    } else {
      toast(result?.problem, 'error')
      setLoading(false)
    }
    setToggleMore(id)
    setAssignee(false)
    setSelected(false)
  }

  useEffect(() => {
    if ((ridersData?.length !== 0) & (riderSearchedVal === '')) {
      setRiderVal(ridersData)
    }
  }, [ridersData, riderSearchedVal])

  const findRider = value => {
    if (value?.length !== 0) {
      const searchData = ridersData?.filter(item => item?.name?.includes(value))
      setRiderVal(searchData)
    } else {
      setRiderVal(ridersData)
    }
  }

  return (
    <Popover className='relative'>
      <PopoverButton>
        <BsThreeDotsVertical
          className='cursor-pointer -z-10'
          onClick={() => {
            setToggleMore(id + 1)
          }}
        />
      </PopoverButton>
      {toggleMore === sn && (
        <PopoverPanel className='absolute -left-40 mt-2 -translate-x-1/2 transform'>
          {!assignee ? (
            <div className='overflow-hidden shadow-lg w-[300px] py-4 bg-white'>
              <span
                className='flex items-center space-x-4 p-3 cursor-pointer hover:bg-[#FCE3C7]'
                onClick={() => setAssignee(true)}
              >
                <User />
                <p>Assign to drivers</p>
              </span>
              {/* <span
                className="flex items-center space-x-4 p-3 cursor-pointer hover:bg-[#FCE3C7]"
                onClick={() => setAssignee(true)}
              >
                <User />
                <p>Reassign</p>
              </span> */}
              {component !== true && (
                <>
                  <span className='flex items-center space-x-4 p-3 cursor-pointer hover:bg-[#FCE3C7]'>
                    <Print />
                    <p>Print</p>
                  </span>
                  {/* <span
                    className="flex items-center space-x-4 p-3 cursor-pointer hover:bg-[#FCE3C7]"
                    onClick={() => {
                      navigate(`/manifest/edit-manifest`, {
                        state: { mType: "TM" },
                      });
                    }}
                  >
                    <HiOutlinePencil size={16} />
                    <p>Edit manifest</p>
                  </span> */}
                </>
              )}
            </div>
          ) : (
            <div className='overflow-hidden shadow-lg w-[300px] pb-4 bg-white'>
              <div
                className='w-9 h-9 hover:border-solid hover:border-gray-500 ml-auto cursor-pointer'
                onClick={() => {
                  setAssignee(false)
                }}
              >
                <Times width={24} height={24} />
              </div>

              <div className='flex w-full p-4'>
                <input
                  type='text'
                  placeholder='Search for a captain or astronaut'
                  className='w-[85%] h-[36px] txtinp bg-white border-[1px] border-[#E5E4E5] rounded-l-xl'
                  onChange={e => {
                    findRider(e.target.value)
                  }}
                />
                <span className='w-[15%] h-[36px] bg-[#E5E4E5] rounded-r-xl flex justify-center items-center'>
                  <IoIosSearch className='w-[24px] h-[24px]' />
                </span>
              </div>
              <div className='overflow-y-scroll h-[150px]'>
                {riderVal?.map(rider => (
                  <span
                    className={`flex items-center space-x-4 p-4 cursor-pointer hover:bg-[#FCE3C7] ${
                      checkSelected === rider?.id ? 'bg-main-hover' : 'bg-white'
                    }`}
                    onClick={() => {
                      setSelected(!selected)
                      setRiderId(rider.id)
                      setRiderName(rider.name)
                      handleSelected(rider?.id)
                      localStorage.setItem('riderName', rider.name)
                    }}
                  >
                    <Truck />
                    <p>{rider.name}</p>
                  </span>
                ))}
              </div>

              <div className='w-full flex justify-end mt-12'>
                <span
                  className='flex space-x-2 justify-center items-center w-[125px] h-[50px] bg-main-primary rounded-[100px] cursor-pointer'
                  onClick={handleAssign}
                >
                  <p className='text-white text-xs'>
                    {loading === true ? 'Loading...' : 'Assign'}
                  </p>
                </span>
              </div>
            </div>
          )}
        </PopoverPanel>
      )}
    </Popover>
  )
}
