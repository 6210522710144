import bookingClient from './clients/bookingClient'

const endpoint = 'invoices/'

const getInvoices = async params => {
  const validParams = {}
  for (const key in params) {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null)
      validParams[key] = params[key]
  }

  const search_params = new URLSearchParams(validParams).toString()

  const response = await bookingClient.get(`${endpoint}?${search_params}`)
  return response
}

const createInvoice = async body => {
  const response = await bookingClient.post(endpoint, body)
  return response
}

const raiseInvoice = async body => {
  const response = await bookingClient.post(`${endpoint}raise_invoice/`, body)
  return response
}

const readInvoice = async id => {
  const response = await bookingClient.get(`${endpoint}${id}/`)
  return response
}

const updateInvoice = async (id, body) => {
  const response = await bookingClient.put(`${endpoint}${id}/`, body)
  return response
}

const patchInvoice = async (id, body) => {
  const response = await bookingClient.patch(`${endpoint}${id}/`, body)
  return response
}

const deleteInvoice = async id => {
  const response = await bookingClient.delete(`${endpoint}${id}/`)
  return response
}

const generateReceipt = async (id, body) => {
  const response = await bookingClient.post(
    `${endpoint}${id}/generate_receipt/`,
    body
  )
  return response
}

const confirmPayment = async id => {
  const response = await bookingClient.post(`${endpoint}${id}/confirm_payment/`)
  return response
}

const exportInvoices = async params => {
  const validParams = {}
  for (const key in params) {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null)
      validParams[key] = params[key]
  }

  const search_params = new URLSearchParams(validParams).toString()

  const response = await bookingClient.get(
    `${endpoint}export/?${search_params}`
  )
  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getInvoices,
  createInvoice,
  raiseInvoice,
  readInvoice,
  updateInvoice,
  patchInvoice,
  deleteInvoice,
  generateReceipt,
  confirmPayment,
  exportInvoices
}
