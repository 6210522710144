export default function Pill ({ name, theme, size = 'md' }) {
  const sizeClass = {
    md: 'py-0.5 px-2 text-[13px]',
    lg: 'py-1 px-4 text-sm'
  }

  return (
    <span
      className={`flex w-fit items-center justify-center text-center ${sizeClass[size]} btn-rounded font-normal select-none border min-w-[4.5rem]`}
      style={{
        borderColor: theme?.primary,
        color: theme?.primary,
        background: theme?.secondary
      }}
    >
      {name}
    </span>
  )
}
