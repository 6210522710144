import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ordersApi from '../api/orders'
import { parseError } from '../utils'

const initialState = {
  data: null
}

export const fetchDeliveryLocations = createAsyncThunk(
  'locations/fetch',
  async () => {
    const response = await ordersApi.getDeliveryLocations()

    if (!response.ok) {
      return parseError(response)
    }

    return response.data.payload
  }
)

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDeliveryLocations.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export const locationsActions = locationsSlice.actions

export default locationsSlice.reducer
