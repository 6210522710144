import store from '../store'
import orgClient from './clients/orgClient'

const getBranches = async () => {
  const org = store.getState().auth.user?.organisation

  if (org?.oid) {
    const response = await orgClient.get(`/organisations/${org?.oid}/branches/`)

    return response
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBranches
}
