import Modal from 'react-modal'

const styles = {
  content: {
    borderRadius: '20px',
    width: '450px',
    height: 'fit-content',
    margin: 'auto',
    inset: '20px'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function MultipieceOrderRemovePrompt ({
  isOpen,
  order,
  onConfirm,
  onDecline
}) {
  return (
    <Modal style={styles} isOpen={isOpen}>
      <div className='bg-white flex flex-col items-center gap-5'>
        <div className='text-center'>
          <h3 className='text-lg text-dark-primary font-medium mb-3'>
            Confirm removal
          </h3>
          <p>
            Are you sure you want to remove order <span>{order}</span> from this
            multipiece? <br />
            You will have to make a separate payment for this order.
          </p>
        </div>
        <div className='flex w-full justify-between items-center'>
          <button className='btn btn-error' onClick={onConfirm}>
            Yes, remove
          </button>
          <button className='btn btn-primary' onClick={onDecline}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}
