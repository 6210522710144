export default function Tabs ({ items, active, onSelectTab }) {
  return (
    <div className='relative flex flex-row items-end gap-4'>
      {items.map((item, id) => (
        <button
          key={id}
          className={`text-base border-b p-1 ${
            active.value === item.value
              ? 'font-semibold border-primary bg-main-primary bg-opacity-5 rounded-t-lg'
              : 'border-transparent bg-transparent opacity-60 rounded-t-none hover:opacity-80'
          }`}
          onClick={() => onSelectTab(item)}
        >
          {item.name}
        </button>
      ))}
    </div>
  )
}
