import { useContext, useEffect, useMemo, useState } from "react";
import FilterTag from "../../components/globals/filter/FilterTag";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../slices/transactionSlice";
import Loader from "../../components/loader/Loader";
import Pagination from "../../components/globals/pagination/ServerPagination";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoIosArrowRoundBack } from "react-icons/io";
import {
  capitalizeFirstLetter,
  exportCSVData,
  formatAmount,
  getDate,
  parseError,
} from "../../utils";
import SalesforceFilter from "../../components/salesforce/SalesforceFilter";
import salesforceApi from "../../api/salesforce";
import dayjs from "dayjs";
import { resolveDateParams } from "../../helpers/queryByDate";
import TransactionModal from "../../components/transactions/TransactionModal";
import noDataIcon from "../../assets/no-data.webp";
import Page from "../../containers/Page";
import Search from "../../components/globals/Search/Search";
import FilterButton from "../../components/globals/filter/FilterButton";
import ROLES from "../../constants/roles";
import { MdOutlineFileDownload } from "react-icons/md";
import { statuses } from "../../fixtures/transactionsStatus";
import SearchResultsDescription from "../../components/globals/Search/SearchResultsDescription";
import { useCallback } from "react";
import { fetchBranches } from "../../slices/orgsSlice";
import {
  fetchSalesforce,
  fetchSingleSalesforce,
  fetchSalesForceCommision,
} from "../../slices/salesforceSlice";
import { AuthLayoutContext } from "../../containers/AuthLayout";
import Pill from "../../components/globals/Pill";
import { CircularProgress } from "@mui/material";
import transactionsApi from "../../api/transactions";
import useToast from "../../hooks/useToast";
import ExportDialogue from "../../components/globals/export/ExportDialogue";
import { SalesforceModal } from "../../components/salesforce/salesforceModal";
import MarkPaidSuccessful from "../../components/salesforce/successModal";

function SalesForce({ metaTitle }) {
  const { userRole } = useContext(AuthLayoutContext);
  const sizeClass = {
    md: "py-0.5 px-2 text-[13px]",
    lg: "py-1 px-4 text-sm",
  };
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const salesforceStore = useSelector((state) => state?.salesforce?.salesforce);
  const commisions = useSelector((state) => state?.salesforce?.commision);
  const salesForceAgent = useSelector(
    (state) => state?.salesforce?.singleSalesforce
  );

  const [filter, setFilter] = useState({
    date: "",
    payment_method: "",
    status: "",
    branch: "",
  });
  const [date_type, setDateType] = useState("");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [showSingle, setShowSingle] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [holdAllCheckData, setHoldAllCheckData] = useState([]);
  const [salesForceId, setSalesForceId] = useState([]);
  const [salesforceModal, setSalesforceModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [trxInFocus, setTrxInFocus] = useState({});
  const [isExportOpen, setExportOpen] = useState(false);
  const [serverSearch, setServerSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [serializedData, setSerializedData] = useState();
  const [filterTags, setFilterTags] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50,
  });
  const [isExportLoading, setExportLoading] = useState(false);
  console.log(holdAllCheckData, "bebebeb");
  const tempData = holdAllCheckData;
  const tempId = salesForceId;
  const saveCheckData = (id, data) => {
    let amnt = amount;
    if (!holdAllCheckData?.includes(id)) {
      amnt = amnt + data?.commission_amount;
      tempData.push(id);
      tempId.push(data.id);
      setHoldAllCheckData([...tempData]);
      setSalesForceId([...tempId]);
      setAmount(amnt);
    } else {
      amnt = amnt - data?.commission_amount;
      const filterData = holdAllCheckData?.filter((item) => item !== id);
      const filterSalesforceId = salesForceId?.filter(
        (item) => item !== data.id
      );
      setHoldAllCheckData([...filterData]);
      setSalesForceId([...filterSalesforceId]);
      setAmount(amnt);
    }
  };

  const calculateAll = () => {
    if (!isCheckedAll) {
      let cloneHolder = [];
      setIsCheckedAll(true);
      const addAll = commisions
        ?.map((item) => {
          cloneHolder.push(item?.order);
          setHoldAllCheckData([...cloneHolder]);
          return item?.commission_amount;
        })
        .reduce((a, b) => a + b, 0);
      setAmount(addAll);
    } else {
      setIsCheckedAll(false);
      setAmount(0);
      setHoldAllCheckData([]);
    }
  };
  const initDateRange = () => {
    const now = new Date();
    const prevMonth = new Date();
    prevMonth.setMonth(now.getMonth() - 1);
    const endDate = dayjs(
      `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
    );
    const startDate = dayjs(
      `${prevMonth.getFullYear()}-${
        prevMonth.getMonth() + 1
      }-${prevMonth.getDate()}`
    );
    return [startDate, endDate];
  };

  const [dateRange, setDateRange] = useState(initDateRange());

  const [search, setSearch] = useState({ by: "customer_name", value: "" });

  const transactionsStore = useSelector((state) => state.transactions);

  const userData = JSON.parse(window.localStorage.getItem("userData"))?.payload;

  const dispatch = useDispatch();
  const toast = useToast();

  const loadTransactions = useCallback(() => {
    dispatch(fetchTransactions(queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    dispatch(fetchSalesforce());
  }, [dispatch]);

  const markAsPaidSalesforce = async (id, body) => {
    setLoading(true);
    let result = await salesforceApi.markAsPaid(id, body);
    if (result.ok) {
      setOpenSuccessModal(true);
      setSalesforceModal(false);
      setLoading(false);
    }
    if (!result.ok) {
      toast("Not paid", "error");
      setLoading(false);
    }
  };
  useEffect(() => {
    dispatch(fetchBranches());
  }, [dispatch]);

  useEffect(() => {
    const params = {};
    const tags = [];

    for (const key in filter) {
      if (filter[key]) {
        if (key === "date") {
          let tag = { name: key, value: "" };
          const { start_date, end_date } = resolveDateParams(
            filter.date,
            dateRange
          );
          params.start_date = start_date;
          params.end_date = end_date;
          if (filter.date === "range") {
            tag.value = `From: ${dateRange[0]
              .format()
              .slice(0, 10)}, To: ${dateRange[1].format().slice(0, 10)}`;
          } else {
            tag.value = capitalizeFirstLetter(filter[key].replaceAll("_", " "));
          }
          tags.push(tag);
        } else if (key === "payment_method") {
          const payment_methods = {
            TRF: "Transfer",
            POS: "POS",
            PAY_LATER: "Pay Later",
            CASH: "Cash",
          };

          let tag = { name: key, value: payment_methods[filter[key]] };

          params.payment_method = filter[key];
          tags.push(tag);
        } else if (key === "status") {
          let tag = { name: key, value: statuses[filter[key]].name };

          tags.push(tag);
          params.status = filter[key];
        } else if (key === "branch") {
          let tag = { name: key, value: filter[key] };

          tags.push(tag);
          params.branch = filter[key];
        }
      }
    }

    const query = { ...queryParams, ...params };
    query.page = 1;

    setQueryParams(query);

    setFilterTags(tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, filter]);

  useEffect(() => {
    setSerializedData(null);
    loadTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const displaySingleData = (id) => {
    dispatch(fetchSingleSalesforce(id));
    dispatch(fetchSalesForceCommision(id, { date_type, start_date, end_date }));
    setShowSingle(!showSingle);
  };

  const transactions = useMemo(() => {
    if (serializedData) {
      if (search.value) {
        const results = serializedData.filter((trx) => {
          const queries = {
            search: {
              apply: !!search.value,
              match() {
                if (search.by === "customer_name") {
                  return trx.customer_name
                    .toLowerCase()
                    .includes(search.value.toLowerCase());
                } else if (search.by === "invoice") {
                  return String(trx.invoice).includes(search.value);
                } else if (search.by === "order") {
                  return String(trx.order).includes(search.value);
                }
              },
            },
          };

          let matchesQueries = [];

          for (let query in queries) {
            if (queries[query].apply) {
              matchesQueries.push(queries[query].match());
            }
          }

          return matchesQueries.every((match) => match);
        });

        return results;
      }

      return serializedData;
    } else return null;
  }, [serializedData, search.by, search.value]);

  const onSearchChange = ({ target }) => {
    setSearch((state) => ({ ...state, value: target.value }));
  };

  const handleServerSearch = () => {
    setQueryParams((state) => ({
      ...state,
      [search.by]: search.value,
    }));
    setServerSearch({
      searchBy: searchOptions.find((opt) => opt.value === search.by)?.name,
      searchValue: search.value,
    });
    setSearch((state) => ({ ...state, value: "" }));
  };

  const onCloseServerSearch = () => {
    setServerSearch(null);
    const query = { ...queryParams };
    delete query[search.by];
    setQueryParams(query);
  };

  const showFilter = () => {
    setIsFilterOpen(true);
  };

  const closeFilter = () => {
    setIsFilterOpen(false);
  };

  const onFilterDelete = (key) => {
    setFilter((state) => ({
      ...state,
      [key]: "",
    }));

    let query = { ...queryParams };
    if (key === "date") {
      delete query.start_date;
      if (filter[key] !== "today") delete query.end_date;
      if (filter[key] === "range") setDateRange(initDateRange());
    } else delete query[key];

    setQueryParams(query);
  };

  // const handleModalClose = () => {
  //   setIsModalOpen(false);
  //   setTrxInFocus({});
  // };

  const searchOptions = [
    {
      name: "Customer",
      value: "customer_name",
    },
    {
      name: "Invoice ID",
      value: "invoice",
    },
    {
      name: "Order ID",
      value: "order",
    },
  ];

  // const searchInputPlaceHolder = useMemo(() => {
  //   return search.by === "customer_name"
  //     ? "Search..."
  //     : search.by === "invoice"
  //     ? "Search..."
  //     : "Search...";
  // }, [search.by]);

  const exportTransactions = async (params, onCompleted) => {
    delete params.page;
    delete params.page_size;

    const response = await transactionsApi.exportTransactions(params);
    if (!response.ok) {
      const apiError = parseError(response);
      if (apiError) {
        toast("Error exporting data", "error");
      }
      onCompleted();
      return;
    }

    const file_name = (() => {
      // eslint-disable-next-line default-case
      switch (userRole?.domain.index) {
        case 0:
          return `transactions_${userData.branch.name}.csv`;
        case 1: // update name for area
        case 2: // update name for region
        case 3:
          return "transactions.csv";
      }
    })();

    exportCSVData(response.data, file_name);

    onCompleted();
  };

  const handleExport = () => {
    if (queryParams.start_date) {
      setExportLoading(true);

      exportTransactions(queryParams, () => {
        setExportLoading(false);
      });
    } else {
      setExportOpen(true);
    }
  };

  const closeExport = () => {
    setExportOpen(false);
  };

  const onPage = (params) => {
    setSerializedData(null);
    setQueryParams((state) => ({ ...state, ...params }));
  };

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header
        title={
          showSingle
            ? salesForceAgent?.first_name + " " + salesForceAgent?.last_name
            : "Salesforce"
        }
      >
        <Search
          value={search.value}
          multiple={false}
          searchBy={search.by}
          searchOptions={searchOptions}
          onSearchOptionChange={(option) =>
            setSearch({ by: option, value: "" })
          }
          inputPlaceHolder={"Search..."}
          handleSearch={onSearchChange}
          allowServerSearch={true}
          onServerSearch={handleServerSearch}
        />
        <FilterButton onClick={showFilter} />
      </Page.Header>
      <Page.Body>
        {/* {isModalOpen && (
          <TransactionModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            trx={trxInFocus}
          />
        )} */}
        {isFilterOpen && (
          <SalesforceFilter
            isOpen={isFilterOpen}
            onClose={closeFilter}
            filter={filter}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        )}
        {isExportOpen && (
          <ExportDialogue
            isOpen={isExportOpen}
            name="transactions"
            onClose={closeExport}
            options={queryParams}
            onExport={exportTransactions}
          />
        )}

        <div className="mb-3 flex flex-col gap-2 lg:gap-3">
          <div className="flex items-center gap-2 lg:gap-3">
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}
            {!!filterTags.length && (
              <div className="flex items-center gap-2 flex-wrap">
                <p className="text-base font-medium text-dark-primary">
                  Filter:
                </p>
                {filterTags.map(({ name, value }, id) => (
                  <FilterTag
                    key={id}
                    name={name}
                    value={value}
                    onDelete={onFilterDelete}
                  />
                ))}
              </div>
            )}

            <div className="flex lg:hidden ml-auto">
              <Pagination
                tableId="transactions-table"
                pageSize={transactionsStore.meta?.page_size}
                totalCount={transactionsStore.meta?.count}
                data={transactionsStore.logs}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={transactionsStore.meta?.page}
              />
            </div>
          </div>

          <div className="flex gap-2 flex-row flex-wrap items-center justify-between w-full">
            {showSingle && (
              <IoIosArrowRoundBack
                onClick={() => setShowSingle(!showSingle)}
                size={28}
                className="cursor-pointer"
              />
            )}
            <div className="flex items-center gap-3 ml-auto">
              <div className="hidden lg:flex">
                <Pagination
                  tableId="transactions-table"
                  pageSize={transactionsStore.meta?.page_size}
                  totalCount={transactionsStore.meta?.count}
                  data={transactionsStore.logs}
                  setSerializedData={setSerializedData}
                  onPage={onPage}
                  page={transactionsStore.meta?.page}
                />
              </div>
              <button
                className="btn bg-g-500 text-dark-primary"
                onClick={handleExport}
                disabled={!transactions?.length}
              >
                <MdOutlineFileDownload
                  size={20}
                  color="#333333"
                  className="mr-1"
                />
                {isExportLoading ? (
                  <CircularProgress size={18} color="inherit" />
                ) : (
                  "Export"
                )}
              </button>
            </div>
          </div>
        </div>
        {!salesforceStore ? (
          <Loader />
        ) : showSingle === false ? (
          <>
            <div className="mt-5 w-full overflow-x-auto border border-[#EFEFEF] rounded-lg">
              <table
                id="transactions-table"
                className="bg-white w-full z-0 text-left text-dark-primary table--hover"
              >
                <thead className="border-b border-[#EFEFEF]">
                  <tr>
                    <th className="px-2"></th>
                    <th>Full Name</th>
                    <th>Phone</th>
                    <th>Agent Code</th>
                    <th>Email</th>
                    <th>Commission received</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {salesforceStore?.map((item, id) => {
                    return (
                      <tr
                        className="border-b border-[#EFEFEF] bg-white transaition-all hover:bg-base-100 cursor-pointer"
                        key={id}
                        onClick={() => {
                          displaySingleData(item.id);
                          //setShowSingle(!showSingle)
                        }}
                      >
                        <td></td>
                        <td>
                          {item.first_name} {item.last_name}
                        </td>
                        <td>{item?.phone}</td>
                        <td>{item?.salesforce_code}</td>
                        <td>{item.email}</td>
                        <td>{formatAmount(item.commission)}</td>
                        <td>
                          <BsThreeDotsVertical />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {(!salesforceStore.length || salesforceStore === undefined) && (
              <div className="pt-14 w-full flex flex-col gap-2 items-center justify-center">
                <img
                  src={noDataIcon}
                  className="w-40 h-40 object-contain"
                  alt="no data icon"
                />
                <p className="no_data_description_text">No transaction found</p>
              </div>
            )}
          </>
        ) : (
          <div className="bg-white">
            <div className="w-full flex justify-between bg-white border-y-[1.5px] border-[#878787] h-[97px] mt-16">
              <div className="w-3/4 flex justify-end items-center px-12">
                Total
              </div>
              <div className="flex bg-white border-[1px] border-[#878787] h-[95px]" />
              <div className="w-1/4 flex justify-around items-center">
                <div className="font-bold">
                  ₦ {formatAmount(amount?.toFixed(2))}
                </div>
                <button
                  className="w-[125px] h-[50px] text-white font-semibold bg-primary p-2"
                  onClick={() => setSalesforceModal(true)}
                >
                  Pay
                </button>
              </div>
            </div>
            <div className="mt-5 w-full overflow-x-auto">
              <table
                id="transactions-table"
                className="bg-white w-full z-0 text-left text-dark-primary table--hover"
              >
                <thead className="border-b border-[#EFEFEF]">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        className="w-[20px] h-[20px] border-[#959595] checked:bg-primary"
                        checked={
                          isCheckedAll
                             ? true
                             : false
                         }
                        onChange={() => calculateAll()}
                      />
                    </th>
                    <th className="px-2">SN</th>
                    <th>Order No.</th>
                    <th>Customer</th>
                    <th>Date</th>
                    <th>Amount Paid (₦)</th>
                    <th>Percentage</th>
                    <th>Commission (₦)</th>
                    <th>Pay Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {commisions?.map((commision, id) => {
                    return (
                      <tr
                        className="border-b border-[#EFEFEF] bg-white transaition-all hover:bg-base-100 cursor-pointer"
                        key={id}
                      >
                        <td>
                          {" "}
                          <input
                            type="checkbox"
                            className="w-[20px] h-[20px] border-[#959595] checked:bg-primary accent-pink-300"
                            checked={
                             holdAllCheckData?.includes(commision?.order)
                                ? true
                                : false
                            }
                            onChange={() => {
                              saveCheckData(commision?.order, commision);
                              setIsCheckedAll(false)
                            }}
                          />
                        </td>
                        <td onClick={() => setShowSingle(!showSingle)}>
                          {id + 1}
                        </td>
                        <td>#{commision?.order}</td>
                        <td>{commision?.customer_name}</td>
                        <td>{getDate(commision?.order_date)}</td>
                        <td>{commision?.amount_paid}</td>
                        <td>{commision?.commission_percent}%</td>
                        <td>{commision?.commission_amount}</td>
                        <td>-----</td>
                        <td className="text-sm text-center italic ">
                          <span
                            className={`flex w-fit items-center ${sizeClass["md"]} justify-center btn-rounded font-medium select-none border min-w-[4.5rem] border-[#A9A5A5] py-1 bg-[#F4F2F2]`}
                          >
                            {commision?.status}
                          </span>
                        </td>
                        {/* <td><BsThreeDotsVertical/></td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {(!transactions?.length || transactions === undefined) && (
              <div className="pt-14 w-full flex flex-col gap-2 items-center justify-center">
                <img
                  src={noDataIcon}
                  className="w-40 h-40 object-contain"
                  alt="no data icon"
                />
                <p className="no_data_description_text">No transaction found</p>
              </div>
            )}
          </div>
        )}

        <SalesforceModal
          salesforceModal={salesforceModal}
          setSalesforceModal={setSalesforceModal}
          markAsPaidSalesforce={markAsPaidSalesforce}
          loading={loading}
          amount={amount}
          salesForceAgent={salesForceAgent}
          salesForceId={salesForceId}
        />
        <MarkPaidSuccessful
          openSuccessModal={openSuccessModal}
          setOpenSuccessModal={setOpenSuccessModal}
          amount={amount}
          salesForceAgent={salesForceAgent}
        />
      </Page.Body>
    </Page>
  );
}

export default SalesForce;
