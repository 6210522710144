import React, { useEffect, useContext, useMemo } from 'react'
import { Package } from '../../components'
import { Navigate, useSearchParams, useLocation } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import { urlQueryStringToObject } from '../../utils'
import { CreateOrderContext } from '../../containers/CreateOrderLayout'

const PackageSection = ({ metaTitle }) => {
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('ord')
  const quoteId = searchParams.get('qt')
  const location = useLocation()

  const {
    updateActiveStep,
    order: {
      payload: { sender, receiver, type: shipmentType, category }
    },
    resolvePathname
  } = useContext(CreateOrderContext)

  const backRoute = useMemo(() => {
    let params = {}
    if (location.search) {
      params = urlQueryStringToObject(location.search)
    }

    if (orderId) {
      params.ctm = 'receiver'

      return `${
        ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path
      }?${new URLSearchParams(params).toString()}`
    }

    return Object.values(params)[0]
      ? `${ROUTES.ORDERS.CREATE_ORDER.PACKAGE_TYPE.path}?${new URLSearchParams(
          params
        ).toString()}`
      : ROUTES.ORDERS.CREATE_ORDER.PACKAGE_TYPE.path
  }, [location.search, orderId])

  useEffect(() => {
    updateActiveStep({
      stepId: 4,
      metaTitle,
      back: resolvePathname(backRoute)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backRoute, metaTitle, updateActiveStep])

  if (!shipmentType) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}`
        )}
      />
    )
  }

  if (shipmentType === 'LC' && !category) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.PACKAGE_TYPE.path}?ctm=sender&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.PACKAGE_TYPE.path}`
        )}
      />
    )
  }

  if (!sender) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=sender&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=sender`
        )}
      />
    )
  }

  if (!receiver) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=receiver&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=sender`
        )}
      />
    )
  }

  return (
    <div>
      <h2 className='py-4 md:pt-0 font-bold text-2xl text-center'>Package</h2>
      <Package />
    </div>
  )
}

export default PackageSection
