import Modal from 'react-modal'
import noDataIcon from '../../../assets/no-data.webp'

const styles = {
  content: {
    borderRadius: '20px',
    width: '450px',
    height: 'fit-content',
    margin: 'auto',
    inset: '20px'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function EmptyExportDataNotification ({ isOpen, onClose }) {
  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel='empty-export-data-notification'
    >
      <div className='bg-white flex flex-col items-center gap-8'>
        <img
          src={noDataIcon}
          className='w-24 h-24 object-contain'
          alt='no data icon'
        />
        <h4 className='text-center font-normal'>
          No data found for your requested options.
        </h4>
        <div className='flex w-full justify-center items-center'>
          <button className='btn btn-primary' onClick={onClose}>
            Ok
          </button>
        </div>
      </div>
    </Modal>
  )
}
