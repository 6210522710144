import { useContext, useEffect, useMemo, useState } from 'react'
import FilterTag from '../../components/globals/filter/FilterTag'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTransactions } from '../../slices/transactionSlice'
import Loader from '../../components/loader/Loader'
import Pagination from '../../components/globals/pagination/ServerPagination'
import {
  capitalizeFirstLetter,
  exportCSVData,
  formatAmount,
  getDate,
  parseError
} from '../../utils'
import { Link } from 'react-router-dom'
import TransactionsFilter from '../../components/transactions/TransactionsFilter'
import dayjs from 'dayjs'
import { resolveDateParams } from '../../helpers/queryByDate'
import TransactionModal from '../../components/transactions/TransactionModal'
import noDataIcon from '../../assets/no-data.webp'
import ROUTES from '../../constants/routes'
import Page from '../../containers/Page'
import Search from '../../components/globals/Search/Search'
import FilterButton from '../../components/globals/filter/FilterButton'
import ROLES from '../../constants/roles'
import { MdOutlineFileDownload } from 'react-icons/md'
import { statuses } from '../../fixtures/transactionsStatus'
import SearchResultsDescription from '../../components/globals/Search/SearchResultsDescription'
import { useCallback } from 'react'
import { fetchBranches } from '../../slices/orgsSlice'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import Pill from '../../components/globals/Pill'
import { CircularProgress } from '@mui/material'
import transactionsApi from '../../api/transactions'
import useToast from '../../hooks/useToast'
import ExportDialogue from '../../components/globals/export/ExportDialogue'

export default function Transactions ({ metaTitle }) {
  const { userRole } = useContext(AuthLayoutContext)

  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [filter, setFilter] = useState({
    date: '',
    payment_method: '',
    status: '',
    branch: ''
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [trxInFocus, setTrxInFocus] = useState({})
  const [isExportOpen, setExportOpen] = useState(false)
  const [serverSearch, setServerSearch] = useState(null)
  const [serializedData, setSerializedData] = useState(null)
  const [filterTags, setFilterTags] = useState([])
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [isExportLoading, setExportLoading] = useState(false)

  const initDateRange = () => {
    const now = new Date()
    const prevMonth = new Date()
    prevMonth.setMonth(now.getMonth() - 1)
    const endDate = dayjs(
      `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
    )
    const startDate = dayjs(
      `${prevMonth.getFullYear()}-${
        prevMonth.getMonth() + 1
      }-${prevMonth.getDate()}`
    )
    return [startDate, endDate]
  }

  const [dateRange, setDateRange] = useState(initDateRange())

  const [search, setSearch] = useState({ by: 'customer_name', value: '' })

  const transactionsStore = useSelector(state => state.transactions)

  const userData = useSelector(state => state.auth.user)

  const dispatch = useDispatch()
  const toast = useToast()

  const loadTransactions = useCallback(() => {
    dispatch(fetchTransactions(queryParams))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  useEffect(() => {
    if (userRole?.domain.index > 0) {
      dispatch(fetchBranches())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole?.domain.index])

  useEffect(() => {
    const params = {}
    const tags = []

    for (const key in filter) {
      if (filter[key]) {
        if (key === 'date') {
          let tag = { name: key, value: '' }
          const { start_date, end_date } = resolveDateParams(
            filter.date,
            dateRange
          )
          params.start_date = start_date
          params.end_date = end_date
          if (filter.date === 'range') {
            tag.value = `From: ${dateRange[0]
              .format()
              .slice(0, 10)}, To: ${dateRange[1].format().slice(0, 10)}`
          } else {
            tag.value = capitalizeFirstLetter(filter[key].replaceAll('_', ' '))
          }
          tags.push(tag)
        } else if (key === 'payment_method') {
          const payment_methods = {
            TRF: 'Transfer',
            POS: 'POS',
            PAY_LATER: 'Pay Later',
            CASH: 'Cash'
          }

          let tag = { name: key, value: payment_methods[filter[key]] }

          params.payment_method = filter[key]
          tags.push(tag)
        } else if (key === 'status') {
          let tag = { name: key, value: statuses[filter[key]].name }

          tags.push(tag)
          params.status = filter[key]
        } else if (key === 'branch') {
          let tag = { name: key, value: filter[key] }

          tags.push(tag)
          params.branch = filter[key]
        }
      }
    }

    const query = { ...queryParams, ...params }
    query.page = 1

    setQueryParams(query)

    setFilterTags(tags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, filter])

  useEffect(() => {
    setSerializedData(null)
    loadTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  const canRemit = useMemo(() => {
    if (userRole) {
      return ROLES[userRole.id].permissions.transactions?.remit
    }
  }, [userRole])

  const transactions = useMemo(() => {
    if (serializedData) {
      if (search.value) {
        const results = serializedData.filter(trx => {
          const queries = {
            search: {
              apply: !!search.value,
              match () {
                if (search.by === 'customer_name') {
                  return trx.customer_name
                    .toLowerCase()
                    .includes(search.value.toLowerCase())
                } else if (search.by === 'invoice') {
                  return String(trx.invoice).includes(search.value)
                } else if (search.by === 'order') {
                  return String(trx.order).includes(search.value)
                }
              }
            }
          }

          let matchesQueries = []

          for (let query in queries) {
            if (queries[query].apply) {
              matchesQueries.push(queries[query].match())
            }
          }

          return matchesQueries.every(match => match)
        })

        return results
      }

      return serializedData
    } else return null
  }, [serializedData, search.by, search.value])

  const onSearchChange = ({ target }) => {
    setSearch(state => ({ ...state, value: target.value }))
  }

  const handleServerSearch = () => {
    setQueryParams(state => ({
      ...state,
      [search.by]: search.value
    }))
    setServerSearch({
      searchBy: searchOptions.find(opt => opt.value === search.by)?.name,
      searchValue: search.value
    })
    setSearch(state => ({ ...state, value: '' }))
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[search.by]
    setQueryParams(query)
  }

  const showFilter = () => {
    setIsFilterOpen(true)
  }

  const closeFilter = () => {
    setIsFilterOpen(false)
  }

  const onFilterDelete = key => {
    setFilter(state => ({
      ...state,
      [key]: ''
    }))

    let query = { ...queryParams }
    if (key === 'date') {
      delete query.start_date
      if (filter[key] !== 'today') delete query.end_date
      if (filter[key] === 'range') setDateRange(initDateRange())
    } else delete query[key]

    setQueryParams(query)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setTrxInFocus({})
  }

  const onTrxClick = trx => {
    setIsModalOpen(true)
    setTrxInFocus(trx)
  }

  const searchOptions = [
    {
      name: 'Customer',
      value: 'customer_name'
    },
    {
      name: 'Invoice ID',
      value: 'invoice'
    },
    {
      name: 'Order ID',
      value: 'order'
    }
  ]

  const searchInputPlaceHolder = useMemo(() => {
    return search.by === 'customer_name'
      ? 'Enter customer name'
      : search.by === 'invoice'
      ? 'Enter invoice ID'
      : 'Enter order ID'
  }, [search.by])

  const exportTransactions = async (params, onCompleted) => {
    delete params.page
    delete params.page_size

    const response = await transactionsApi.exportTransactions(params)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Error exporting data', 'error')
      }
      onCompleted()
      return
    }

    const file_name = (() => {
      // eslint-disable-next-line default-case
      switch (userRole?.domain.index) {
        case 0:
          return `transactions_${userData.branch.name}.csv`
        case 1: // update name for area
        case 2: // update name for region
        case 3:
          return 'transactions.csv'
      }
    })()

    exportCSVData(response.data, file_name)

    onCompleted()
  }

  const handleExport = () => {
    if (queryParams.start_date) {
      setExportLoading(true)

      exportTransactions(queryParams, () => {
        setExportLoading(false)
      })
    } else {
      setExportOpen(true)
    }
  }

  const closeExport = () => {
    setExportOpen(false)
  }

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Transactions'}>
        <Search
          value={search.value}
          multiple={true}
          searchBy={search.by}
          searchOptions={searchOptions}
          onSearchOptionChange={option => setSearch({ by: option, value: '' })}
          inputPlaceHolder={searchInputPlaceHolder}
          handleSearch={onSearchChange}
          allowServerSearch={true}
          onServerSearch={handleServerSearch}
        />
        <FilterButton onClick={showFilter} />
      </Page.Header>
      <Page.Body>
        {isModalOpen && (
          <TransactionModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            trx={trxInFocus}
          />
        )}
        {isFilterOpen && (
          <TransactionsFilter
            isOpen={isFilterOpen}
            onClose={closeFilter}
            filter={filter}
            setFilter={setFilter}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        )}
        {isExportOpen && (
          <ExportDialogue
            isOpen={isExportOpen}
            name='transactions'
            onClose={closeExport}
            options={queryParams}
            onExport={exportTransactions}
          />
        )}

        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex items-center gap-2 lg:gap-3'>
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}
            {!!filterTags.length && (
              <div className='flex items-center gap-2 flex-wrap'>
                <p className='text-base font-medium text-dark-primary'>
                  Filter:
                </p>
                {filterTags.map(({ name, value }, id) => (
                  <FilterTag
                    key={id}
                    name={name}
                    value={value}
                    onDelete={onFilterDelete}
                  />
                ))}
              </div>
            )}
            <div className='flex lg:hidden ml-auto'>
              <Pagination
                tableId='transactions-table'
                pageSize={transactionsStore.meta?.page_size}
                totalCount={transactionsStore.meta?.count}
                data={transactionsStore.logs}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={transactionsStore.meta?.page}
              />
            </div>
          </div>

          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <div className='flex items-center gap-3 ml-auto'>
              <div className='hidden lg:flex'>
                <Pagination
                  tableId='transactions-table'
                  pageSize={transactionsStore.meta?.page_size}
                  totalCount={transactionsStore.meta?.count}
                  data={transactionsStore.logs}
                  setSerializedData={setSerializedData}
                  onPage={onPage}
                  page={transactionsStore.meta?.page}
                />
              </div>
              <button
                className='btn bg-g-400 text-dark-primary'
                onClick={handleExport}
                disabled={!transactions?.length}
              >
                <MdOutlineFileDownload
                  size={18}
                  color='#333333'
                  className='mr-1'
                />
                {isExportLoading ? (
                  <>
                    Exporting
                    <CircularProgress
                      style={{ marginLeft: '0.5rem' }}
                      size={18}
                      color='inherit'
                    />
                  </>
                ) : (
                  'Export'
                )}
              </button>
              {canRemit && (
                <Link
                  className='btn btn-primary'
                  to={ROUTES.TRANSACTIONS.REMIT_CASH_TRANSACTIONS.path}
                >
                  Remit Cash Transactions
                </Link>
              )}
            </div>
          </div>
        </div>
        {!transactions ? (
          <Loader />
        ) : (
          <>
            <div className='mt-5 w-full overflow-x-auto border border-[#EFEFEF] rounded-lg'>
              <table
                id='transactions-table'
                className='bg-white w-full z-0 text-left text-dark-primary table--hover'
              >
                <thead className='border-b border-[#EFEFEF]'>
                  <tr>
                    <th>S/N</th>
                    <th>Customer</th>
                    <th>Date/Time</th>
                    <th>Order</th>
                    <th>Invoice</th>
                    <th>Amount (₦)</th>
                    <th>Payment Method</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions?.map((trx, id) => {
                    return (
                      <tr
                        className='border-b border-[#EFEFEF] bg-white transaition-all hover:bg-base-100 cursor-pointer'
                        key={id}
                        onClick={() => onTrxClick(trx)}
                      >
                        <td>{trx.s_n}</td>
                        <td>{trx.customer_name}</td>
                        <td>{getDate(trx.date_time)}</td>
                        <td>
                          {typeof trx.order === 'number' && (
                            <Link
                              to={`${ROUTES.ORDERS.path}?id=${trx.order}`}
                              onClick={e => e.stopPropagation()}
                              className='hover:underline hover:text-primary'
                            >
                              #{trx.order}
                            </Link>
                          )}
                        </td>
                        <td>
                          {typeof trx.invoice === 'number' && (
                            <Link
                              to={`${ROUTES.INVOICES.path}/${trx.invoice}`}
                              onClick={e => e.stopPropagation()}
                              className='hover:underline hover:text-primary'
                            >
                              #{trx.invoice}
                            </Link>
                          )}
                        </td>
                        <td>{formatAmount(trx.amount.total)}</td>
                        <td>
                          {trx.payment_method === 'POS'
                            ? 'POS'
                            : trx.payment_method === 'TRF'
                            ? 'Transfer'
                            : trx.status === 5 ||
                              trx.payment_method === 'PAY_LATER'
                            ? 'Pay Later'
                            : 'CASH'}
                        </td>
                        <td>
                          {trx.status !== 5 && (
                            <Pill
                              name={statuses[trx.status]?.name}
                              theme={statuses[trx.status]?.theme}
                            />
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            {(!transactions.length || transactions === undefined) && (
              <div className='pt-14 w-full flex flex-col gap-2 items-center justify-center'>
                <img
                  src={noDataIcon}
                  className='w-40 h-40 object-contain'
                  alt='no data icon'
                />
                <p className='no_data_description_text'>No transaction found</p>
              </div>
            )}
          </>
        )}
      </Page.Body>
    </Page>
  )
}
