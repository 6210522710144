import React from 'react'

import { BiChevronRight } from 'react-icons/bi'
import { FaCheck } from 'react-icons/fa'
import { AiFillClockCircle } from 'react-icons/ai'
import { useSearchParams } from 'react-router-dom'
import { getStateOfCountry } from '../../utils'

const AddressBookItem = ({
  item,
  handleOnClick,
  selectedCustomer,
  tab,
  isSender
}) => {
  const [searchParams] = useSearchParams()
  const cusType = searchParams.get('ctm')

  return (
    <button
      onClick={() => handleOnClick(item)}
      className={`flex w-full gap-3 md:gap-6 text-dark-primary text-left items-center hover:bg-gray-100 md:px-4 rounded-md select-none ${
        cusType === 'receiver' && isSender ? 'bg-gray-100 opacity-70' : ''
      }`}
      disabled={cusType === 'receiver' && isSender}
    >
      <AiFillClockCircle size={25} />
      <div className='flex items-center justify-between w-full py-4 border-b-1 border-gray-100'>
        <div>
          <h3 className='font-bold'>{item.full_name}</h3>
          <p className='text[#E0E0E0]'>
            {`${item.address}, ${item.city}, ${
              item.state_name ||
              getStateOfCountry(item.state, item.country)?.name ||
              item.state
            }, ${item.country}`}
          </p>
          {(tab.id === 1 || tab.id === 2) && (
            <p className='text-sm'>Company: {item.business_name}</p>
          )}
        </div>
        <div>
          {selectedCustomer?.id === item.id ? (
            <span className='text-main-primary-2'>
              <FaCheck size={18} />
            </span>
          ) : isSender ? (
            <span className='p-1 rounded-xl text-sm bg-gray-400 text-dark-primary'>
              Sender
            </span>
          ) : (
            <BiChevronRight size={18} />
          )}
        </div>
      </div>
    </button>
  )
}

export default AddressBookItem
