export default function UsersIcon ({ variant = 'secondary', ...restProps }) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 13C17.7614 13 20 10.7614 20 8C20 5.23858 17.7614 3 15 3C12.2386 3 10 5.23858 10 8C10 10.7614 12.2386 13 15 13Z'
        stroke={variant === 'primary' ? '#fe6802' : '#2E3034'}
        stroke-width='2'
      />
      <path
        d='M24.9975 23C25 22.795 25 22.5862 25 22.375C25 19.2688 20.5225 16.75 15 16.75C9.4775 16.75 5 19.2688 5 22.375C5 25.4812 5 28 15 28C17.7887 28 19.8 27.8037 21.25 27.4537'
        stroke={variant === 'primary' ? '#fe6802' : '#2E3034'}
        stroke-width='2'
        stroke-linecap='round'
      />
    </svg>
  )
}
