import { useCallback, useEffect, useState } from 'react'
import { useMemo } from 'react'
import PaymentModal from '../payment/paymentModal'
import OrderSummary from './newOrder/OrderSummary'
import { useSelector } from 'react-redux'
import ShipmentModal from './ShipmentModal'
import Modal from 'react-modal'
import ordersApi from '../../api/orders'
import useToast from '../../hooks/useToast'
import { PiWarningCircle } from 'react-icons/pi'
import MultipieceOrders from './newOrder/multipiece/MultipieceOrders'
import { parseError, scrollToTarget } from '../../utils'
import { twMerge } from 'tailwind-merge'
import CreateMultipiece from './newOrder/multipiece/CreateMultipiece'
import Loader from '../loader/Loader'

const createStyles = isMultipiece => {
  return {
    content: {
      backgroundColor: isMultipiece ? 'transparent' : '#ffffff',
      inset: '20px',
      border: 'none',
      borderRadius: isMultipiece ? '0px' : '20px',
      maxWidth: isMultipiece ? '1024px' : '768px',
      margin: 'auto',
      height: 'fit-content',
      maxHeight: '95%'
    },
    overlay: {
      backgroundColor: '#0000004f',
      zIndex: 60
    }
  }
}

export default function ProcessOrder ({
  isOpen,
  onClose,
  orderData: initialOrder,
  onReload,
  isReprocess
}) {
  const { paid: isPaid } = initialOrder.order

  const [orderData, setOrderData] = useState({
    order: initialOrder.order,
    quote: initialOrder.meta?.quote
  })
  const [orderDetails, setOrderDetails] = useState(
    initialOrder.order.multi_piece ? [] : { order_id: initialOrder.order.id }
  )
  const [isPayment, setIsPayment] = useState(false)
  const [billTo, setBillTo] = useState(
    initialOrder.meta.quote?.bill_to
      ? `${initialOrder.meta.quote?.bill_to}`
      : ''
  )
  const [shipment, setShipment] = useState(
    initialOrder.order.multi_piece
      ? [{ status: 'listening' }]
      : { status: 'listening' }
  )
  const [multipiece, setMultipiece] = useState()
  const [multipieceOrders, setMultipieceOrders] = useState([])
  const [modalContainer, setModalContainer] = useState(null)
  const [isCreateMultipiece, setCreateMultipiece] = useState(false)

  const reinitiateData = () => {
    setOrderData({
      order: initialOrder.order,
      quote: initialOrder.meta?.quote
    })
    if (initialOrder.order.multi_piece) {
      if (!Array.isArray(orderDetails)) {
        setOrderDetails([])
      }
      if (!Array.isArray(shipment)) {
        setShipment([{ status: 'listening' }])
      }
    }
    scrollToTarget('#order-summary-top', modalContainer)
  }

  useEffect(() => {
    reinitiateData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOrder])

  const addonsStore = useSelector(state => state.addons)
  const userData = useSelector(state => state.auth.user)

  const toast = useToast()

  const isMultipiece = useMemo(() => {
    return !!orderData.order.multi_piece
  }, [orderData.order.multi_piece])

  const isShipmentOpen = useMemo(() => {
    const isValid = status =>
      status === 'processing' || status === 'processed' || status === 'error'

    if (isMultipiece) {
      return shipment.some(({ status }) => isValid(status))
    }

    return isValid(shipment.status)
  }, [isMultipiece, shipment])

  const customer = useMemo(() => {
    if (orderData && billTo) {
      return orderData.order[billTo]
    }
  }, [orderData, billTo])

  const loadMultipiece = async () => {
    const response = await ordersApi.getMultipiece(orderData.order.multi_piece)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Unable to get multipiece', 'error')
      }
      return
    }

    const orders = response.data.payload.orders.map(order_id => {
      const existing_order = multipieceOrders.find(
        order => order?.order.id === order_id
      )
      return existing_order ?? undefined
    })

    setMultipieceOrders(orders)

    setMultipiece(response.data.payload)
  }

  const loadMultipieceOrders = async () => {
    const requests = multipiece.orders.map(order => {
      const request = async () => {
        const response = await ordersApi.readOrder(order)
        return response.data.payload
      }
      return request()
    })

    const responses = await Promise.all(requests)
    const formattedResponses = responses.map(({ order, meta: { quote } }) => ({
      order,
      quote
    }))

    setMultipieceOrders(formattedResponses)
  }

  useEffect(() => {
    if (isMultipiece) {
      loadMultipiece()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMultipiece])

  useEffect(() => {
    if (multipiece) {
      setOrderDetails(multipiece.orders.map(order_id => ({ order_id })))
      loadMultipieceOrders()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipiece])

  useEffect(() => {
    if (multipieceOrders.length) {
      let commonCustomer

      for (let i = 0; i < multipieceOrders.length; i++) {
        if (multipieceOrders[i]) {
          const { sender, receiver } = multipieceOrders[i].order
          if (commonCustomer?.sender.customer_id === sender.customer_id) {
            commonCustomer = 'sender'
            break
          } else if (
            commonCustomer?.receiver.customer_id === receiver.customer_id
          ) {
            commonCustomer = 'receiver'
            break
          } else {
            commonCustomer = { sender, receiver }
          }
        }
      }

      if (typeof commonCustomer === 'string') setBillTo(commonCustomer)
    }
  }, [multipieceOrders])

  const handleShipmentResponse = (response, onSuccess, onError) => {
    if (!response.ok) {
      let error
      if (response.data?.status_code === 503) {
        error =
          response.data.message ||
          response.data.detail?.detail?.code ||
          'An error occured while trying to book shipment, please try again later.'
      } else if (response.data?.status_code === 400) {
        const _response = response.data.detail?.response
        const _error = Array.isArray(_response?.errors)
          ? _response.errors[0]?.message?.message
          : _response.detail?.message
        error =
          _error ||
          'An error occured while trying to book shipment, please try again.'
      } else if (response.data?.status_code === 422) {
        error =
          response.data.detail?.detail?.message ||
          'An error occured while trying to book shipment, please try again later.'
      }

      onError(error)
    } else {
      if (response.data.detail) {
        const _response = response.data.detail?.response
        const _error = Array.isArray(_response?.errors)
          ? _response.errors[0]?.message?.message
          : _response?.TransStatusDetails?.message

        const error =
          _error ||
          'An error occured while trying to book shipment, please try again later.'
        onError(error)
      } else {
        onSuccess(response.data.payload.shipment)
      }
    }
  }

  useEffect(() => {
    if (isReprocess) {
      const reprocessOrder = async () => {
        const response = await ordersApi.reProcessOrder(orderData.order.id)

        const onSuccess = shipment => {
          setOrderDetails(state => ({
            ...state,
            shipment,
            tpl_service: orderData.order.tpl_service
          }))
          setShipment({ status: 'processed' })
        }

        const onError = error => {
          setShipment({ status: 'error', error })
        }

        handleShipmentResponse(response, onSuccess, onError)
      }

      reprocessOrder()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReprocess])

  const processOrder = async order_index => {
    if (isMultipiece) {
      let requests = []
      if (typeof order_index === 'number') {
        requests = multipieceOrders.map((data, index) =>
          index === order_index ? data : null
        )
      } else {
        // process if unprocessed or voided
        requests = multipieceOrders.map(data =>
          data.order.order_state === 1 || data.order.order_state === 4
            ? data
            : null
        )
      }

      setShipment(state => {
        requests.forEach((order, index) => {
          if (order) {
            state[index] = { status: 'processing' }
          }
        })

        return state
      })

      const responses = await Promise.all(
        requests.map(order => {
          const request = async () => {
            let response

            if (order.order.order_state === 4) {
              response = await ordersApi.reProcessOrder(order.order.id)
            } else {
              response = await ordersApi.processOrder(
                order.order.id,
                order.quote
              )
            }
            return response.data
          }
          return order ? request() : null
        })
      )

      const shipmentResponses = [...shipment]
      const _orderDetails = [...orderDetails]

      const onSuccess = (shipment, index) => {
        _orderDetails[index] = {
          ..._orderDetails[index],
          shipment,
          tpl_service: multipieceOrders[index].order.tpl_service
        }
        shipmentResponses[index] = { status: 'processed' }
      }

      const onError = (error, index) => {
        shipmentResponses[index] = { status: 'error', error }
      }

      responses.forEach((response, index) => {
        if (response) {
          handleShipmentResponse(
            response,
            shipment => onSuccess(shipment, index),
            error => onError(error, index)
          )
        }
      })

      setOrderDetails(_orderDetails)
      setShipment(shipmentResponses)
    } else {
      setShipment({ status: 'processing' })

      let response

      if (orderData.order.order_state === 4) {
        response = await ordersApi.reProcessOrder(orderData.order.id)
      } else {
        response = await ordersApi.processOrder(
          orderData.order.id,
          orderData.quote
        )
      }

      const onSuccess = shipment => {
        setOrderDetails(state => ({
          ...state,
          shipment,
          tpl_service: orderData.order.tpl_service
        }))
        setShipment({ status: 'processed' })
      }

      const onError = error => {
        setShipment({ status: 'error', error })
      }

      handleShipmentResponse(response, onSuccess, onError)
    }
  }

  const handlePaymentProceed = async (bill_to = billTo) => {
    if (isPaid) {
      processOrder()
    } else {
      if (isMultipiece) {
        setBillTo(bill_to)
        setIsPayment(true)
      } else {
        orderData.quote.bill_to = orderData.order[bill_to].customer_id
        orderData.quote.raise_by = `${userData.employee.first_name} ${userData.employee.last_name}`
        orderData.quote.branch_name = userData.branch.name
        setIsPayment(true)
      }
    }
  }

  const onPaymentSuccess = () => {
    setTimeout(() => {
      shipment.status === 'listening' && setShipment({ status: 'processing' })
    }, 2000)
  }

  const onCloseShipment = () => {
    setIsPayment(false)
    onClose(shipment.status === 'processed' ? true : null)
  }

  const onRetryProcessShipment = order_index => {
    processOrder(order_index)
  }

  const updateModalContainer = useCallback(modalNode => {
    setModalContainer(modalNode)
  }, [])

  const handleMultipieceOrderClick = index => {
    const { order, quote } = multipieceOrders[index]
    const activeOrder = { order, quote }

    setOrderData(activeOrder)
    scrollToTarget('#order-summary-top', modalContainer)
  }

  const openCreateMultipiece = () => {
    setCreateMultipiece(true)
  }

  const closeCreateMultipiece = (isSuccess = false, mpo) => {
    setCreateMultipiece(false)

    if (isSuccess) {
      onReload()
    }
  }

  return (
    <Modal
      style={createStyles(isMultipiece)}
      isOpen={isOpen}
      contentRef={updateModalContainer}
    >
      {isCreateMultipiece && (
        <CreateMultipiece
          isOpen={isCreateMultipiece}
          onClose={closeCreateMultipiece}
          order={orderData.order}
          billTo={billTo}
        />
      )}
      <div id='order-summary-top'>
        {isShipmentOpen && (
          <ShipmentModal
            isOpen={isShipmentOpen}
            orderDetails={orderDetails}
            shipment={shipment}
            onRetry={onRetryProcessShipment}
            shouldClose
            onClose={onCloseShipment}
            isMultipiece={isMultipiece}
          />
        )}

        {isPayment && (
          <PaymentModal
            isOpen={isPayment}
            onClose={() => setIsPayment(false)}
            orderData={isMultipiece ? multipieceOrders : orderData}
            customer={customer}
            onSuccess={onPaymentSuccess}
            setOrderDetails={setOrderDetails}
            onProcessOrder={processOrder}
            onShipment={setShipment}
            multipiece={
              isMultipiece
                ? {
                    id: multipiece.id,
                    total: multipiece.total,
                    bill_to: billTo
                  }
                : null
            }
          />
        )}
        <div
          className={twMerge(
            'flex items-center justify-end w-full pb-4',
            !isMultipiece && 'border-b'
          )}
        >
          <button className='btn btn-accent' onClick={onClose}>
            Cancel
          </button>
        </div>
        <div className={twMerge(isMultipiece ? 'grid grid-cols-12' : '')}>
          <div
            className={twMerge(
              'py-4 px-4 lg:px-10 lg:pb-10 bg-white',
              isMultipiece
                ? 'col-span-12 lg:col-span-9 border rounded-lg flex flex-col gap-8'
                : ''
            )}
          >
            {isReprocess ? (
              <div className='py-10 text-center'>
                <h4 className='text-2xl font-semibold -mb-10'>
                  Reprocessing shipment
                </h4>
                <Loader />
              </div>
            ) : orderData.quote ? (
              <>
                <OrderSummary
                  orderData={orderData}
                  addons={addonsStore.data}
                  onProceed={handlePaymentProceed}
                  billTo={billTo}
                  setBillTo={setBillTo}
                  onCreateMultipiece={openCreateMultipiece}
                />
                {isMultipiece && (
                  <div className='block lg:hidden'>
                    <MultipieceOrders
                      from='process-order'
                      multipiece={multipiece}
                      billTo={billTo}
                      setBillTo={setBillTo}
                      orders={multipieceOrders}
                      activeOrder={orderData}
                      onOrderClick={handleMultipieceOrderClick}
                      // onAddOrder={handleMultipieceAddOrder}
                      onPaymentProceed={handlePaymentProceed}
                      onReload={loadMultipiece}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className='py-6 flex items-center justify-center flex-col text-center gap-4 w-full'>
                <PiWarningCircle size={36} color='#dc2626' />
                <p className='text-error'>
                  Error: No quote for this order was found. Kindly delete this
                  order and create a new one.
                </p>
              </div>
            )}
          </div>
          <div
            className={twMerge(
              isMultipiece
                ? 'hidden lg:block lg:col-span-3 pl-2 pt-2'
                : 'hidden'
            )}
          >
            <div className='sticky top-10'>
              {isMultipiece && (
                <MultipieceOrders
                  from='process-order'
                  multipiece={multipiece}
                  billTo={billTo}
                  setBillTo={setBillTo}
                  orders={multipieceOrders}
                  activeOrder={orderData}
                  onOrderClick={handleMultipieceOrderClick}
                  // onAddOrder={handleMultipieceAddOrder}
                  onPaymentProceed={handlePaymentProceed}
                  onReload={loadMultipiece}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
