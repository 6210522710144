import { AiFillCloseCircle } from 'react-icons/ai';

function OrderCancelled () {
  return (
    <div className='w-full'>
      {/* HEADER */}
      <div className='gap-5 flex w-full justify-end pb-4 border-b border-gray-100'>
        <button className='btn btn-error' type='submit'>
          Close
        </button>
      </div>

      {/* BODY */}
      <div className='flex flex-col justify-center items-center w-full h-96 text-center'>
        {/* MAIN CONTENT */}
        <AiFillCloseCircle className='text-green-600' size={70} />
        <span className='font-bold text-xl mt-4'>Order Cancelled</span>
      </div>
    </div>
  );
}

export default OrderCancelled;
