import React from 'react'
import { BiSearch } from 'react-icons/bi'
import FilterButton from '../../globals/filter/FilterButton'

const SearchManifest = ({ handleSearch, setOpenModal }) => {
  const showFilter = () => {
    setOpenModal(true)
  }
  return (
    <>
      <div className='sticky flex w-full justify-end items-center top-0 z-10 bg-[#F5F5F5]'>
        <div className='flex space-x-2 justify-start items-center'>
          <div className='flex w-[350px]'>
            <input
              type='text'
              placeholder='Search for waybill or manifest ID......'
              className='w-[80%] txtinp bg-white border-[1px] border-[#E5E4E5] rounded-l-xl'
              onChange={e => handleSearch(e.target.value)}
            />
            <span className='w-[13%] h-[40px] bg-[#E5E4E5] rounded-r-xl flex justify-center items-center'>
              <BiSearch size={18} color='#333333' />
            </span>
          </div>
          {/* end of search input */}
          <FilterButton onClick={showFilter} />
        </div>
      </div>
    </>
  )
}

export default SearchManifest
