const hscodes = [
  {
    "CET Code": "0101210000",
    "Description": "Live Purebred breeding horses",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0101290000",
    "Description": "Live Horses other than pure bred",
    "SU": "U",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0101301000",
    "Description": "Live Purebred breeding asses",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0101309000",
    "Description": "Live Assses other than pure bred",
    "SU": "U",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0101900000",
    "Description": "Live Mules and hinnies",
    "SU": "U",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0102210000",
    "Description": "Live Purebred breeding cattle",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0102290000",
    "Description": "Live Cattle other than pure bred",
    "SU": "U",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0102310000",
    "Description": "Live Purebred breeding bufalo",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0102390000",
    "Description": "Live Bufalo other than pure bred",
    "SU": "U",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0102900000",
    "Description": "Other live bovine animals.",
    "SU": "U",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0103100000",
    "Description": "Live Purebred breeding swine",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0103910000",
    "Description": "Other live swine other than Purebred breeding weighing less than 50kg",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0103920000",
    "Description": "Other live swine other than Purebred breeding Weighing 50 kg or more",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0104101000",
    "Description": "Live Purebred breeding sheep",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0104109000",
    "Description": "Live sheep other than pure bred",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0104201000",
    "Description": "Live Purebred breeding goat",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0104209000",
    "Description": "Live goat other than pure bred",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0105111000",
    "Description": "Live Breeding fowls of the species Gallus domesticus weighing not more than 185g",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0105119000",
    "Description": "Live Nonbreeding fowls of the species Gallus domesticus weighing not more than 185g",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0105120000",
    "Description": "Live Turkeys weighing not more than 185g",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0105130000",
    "Description": "Live Ducks weighing not more than 185g",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0105140000",
    "Description": "Live Geese weighing not more than 185g",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0105150000",
    "Description": "Live Guinea fowls weighing not more than 185g",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0105940000",
    "Description": "Live Fowls of the species Gallus domesticus weighing more than 185g",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0105990000",
    "Description": "Other live poultry other than species Gallus domesticus weighing more than 185g",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0106110000",
    "Description": "Live Primates",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0106120000",
    "Description": "Live Whales, dolphins and porpoises; manatees & dugongs; seals, sea lions and walruses",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0106130000",
    "Description": "Live Camels and other camelids (Camelidae)",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0106140000",
    "Description": "Live Rabbits and hares",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0106190000",
    "Description": "Other Live Mammals not specified in 01.06",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0106200000",
    "Description": "Live Reptiles (including snakes and turtles)",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "0106310000",
    "Description": "Live Birds of prey",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0106320000",
    "Description": "Live Psittaciformes (including parrots, parakeets, macaws and cockatoos)",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0106330000",
    "Description": "Live Ostriches; emus (Dromaius novaehollandiae)",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0106390000",
    "Description": "Other live birds not specified in this heading.",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0106410000",
    "Description": "Live Bees",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0106490000",
    "Description": "Live insects other than bees.",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0106900000",
    "Description": "Other live animals not elsewere specified.",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0201100000",
    "Description": "Carcasses and halfcarcasses of bovine animals; fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0201200000",
    "Description": "Meat of bovine animals fresh or chilled; other cuts with bone in.",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0201300000",
    "Description": "Boneless meat of bovine animals; fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0202100000",
    "Description": "Carcasses and halfcarcasses of bovine animal; frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0202200000",
    "Description": "Other meat of bovine animals cuts with bone in; frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0202300000",
    "Description": "Boneless meat of bovine animals frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0203110000",
    "Description": "Carcasses and halfcarcasses of swines, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0203120000",
    "Description": "Hams, shoulders and cuts thereof of swines, fresh or chilled, with bone in",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0203190000",
    "Description": "Other meat of swines, fresh or chilled not mentioned in 02.03.",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0203210000",
    "Description": "Carcasses and halfcarcasses of swines, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0203220000",
    "Description": "Hams, shoulders and cuts thereof, of swine with bone in, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0203290000",
    "Description": "Other frozen meat of swine not mentioned in this heading.",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0204100000",
    "Description": "Carcasses and halfcarcasses of lamb, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0204210000",
    "Description": "Carcasses and halfcarcasses of sheep, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0204220000",
    "Description": "Other meat of sheep cuts with bone in, fresh or chilled.",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0204230000",
    "Description": "Boneless meat of sheep, fresh or chilled.",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0204300000",
    "Description": "Carcasses and halfcarcasses of lamb, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0204410000",
    "Description": "Carcasses and halfcarcasses of sheep, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0204420000",
    "Description": "Other meat of sheep cuts with bone in, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0204430000",
    "Description": "Boneless meat of sheep, frozen.",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0204500000",
    "Description": "Meat of goats, fresh, chilled or frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0205000000",
    "Description": "Meat of horses, asses, mules or hinnies, fresh, chilled or frozen.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0206100000",
    "Description": "Edible offal of bovine animals, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0206210000",
    "Description": "Edible tongues of bovine animals, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0206220000",
    "Description": "Edible Livers of bovine animals, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0206290000",
    "Description": "Other edible offal of bovine animals not specified in 02.06, frozen.",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0206300000",
    "Description": "Edible offal of swine, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0206410000",
    "Description": "Edible Livers of swine, frozen.",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0206490000",
    "Description": "Other edible offal of swine not specified in 02.06, frozen.",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0206800000",
    "Description": "Other, Edible offal of 02.06 not specified, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0206900000",
    "Description": "Other, Edible offal of 02.06 not specified, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207110000",
    "Description": "Meat and edible offal of species gallus domesticus, Not cut in pieces, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207120000",
    "Description": "Meat and edible offal of species gallus domesticus, Not cut in pieces, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207130000",
    "Description": "Cuts and offal, of the fowl of species gallus domesticus,fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207140000",
    "Description": "Cuts and offal, of the fowl of species gallus domesticus, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207240000",
    "Description": "Meat and edible offal of turkey, not cut in pieces, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207250000",
    "Description": "Meat and edible offal of turkey, not cut in pieces,frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207260000",
    "Description": "Cuts and offal, of turkey, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207270000",
    "Description": "Cuts and offal, of turkey, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207410000",
    "Description": "Meat and edible offal of ducks, Not cut in pieces, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207420000",
    "Description": "Meat and edible offal of ducks, Not cut in pieces, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207430000",
    "Description": "Edible Fatty livers, of ducks, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207440000",
    "Description": "Other meat and edible offal, of ducks not specified in this heading, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207450000",
    "Description": "Other meat and edible offal, of ducks not specified in this heading, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207510000",
    "Description": "Meat and edible offal of geese, Not cut in pieces, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207520000",
    "Description": "Meat and edible offal of geese, Not cut in pieces, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207530000",
    "Description": "Fatty livers, of geese, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207540000",
    "Description": "Other meat and edible offal, of geese not specified in this heading, fresh or chilled",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207550000",
    "Description": "Other meat and edible offal, of geese not specified in this heading, frozen",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0207600000",
    "Description": "Meat and edible offal of guinea fowls",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0208100000",
    "Description": "Other meat and edible offal of rabbits or hares, fresh, chilled or frozen.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0208300000",
    "Description": "Other meat and edible offal of primates, fresh chilled or frozen.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0208400000",
    "Description": "Meat and edible offal of whales, dolphins and porpoises ; of manatees and dugongs",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0208500000",
    "Description": "meat and edible offal of reptiles (including snakes and turtle",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0208600000",
    "Description": "Meat and edible offal of camels and other camelidae",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0208900000",
    "Description": "Meat and edible offal not specified in 02.08, fresh, chilled or frozen",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0209100000",
    "Description": "Pig fat not rendered or otherwise extracted, preserved in any way.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0209900000",
    "Description": "Other fat not rendered or otherwise extracted, preserved in any way.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0210110000",
    "Description": "Hams, shoulders and cuts thereof, of swine with bone in, frozen",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0210120000",
    "Description": "Bellies (streaky) and cuts thereof, of swine, salted, in brine, dried or smoked.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0210190000",
    "Description": "Meat of swine of 02.10, salted, in brine, dried or smoked.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0210200000",
    "Description": "Meat of bovine animals, salted, in brine, dried or smoked.",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0210910000",
    "Description": "Others edible flours and meals of meat or meat offal of primate",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0210920000",
    "Description": "Others edible flours and meals of whales, dolphins and porpoises; manatees and dugongs.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0210930000",
    "Description": "Others edible flours and meals of meat or meat offal of reptile; snakes and turtle",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0210990000",
    "Description": "Edible flours and meals of meat or meat offal of animals not specified in 02.10.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301110000",
    "Description": "Live Freshwater ornamental fish",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301190000",
    "Description": "Live ornamental fish other than freshwater fish",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301911000",
    "Description": "Live young trout fish (fish fingerlings)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301919000",
    "Description": "Live trout fish other than fingerlings.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301921000",
    "Description": "Live young Eels fish (fish fingerlings)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301929000",
    "Description": "Live Eels fish other than fingerlings",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301931000",
    "Description": "Live young carp fish (fish fingerlings)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301939000",
    "Description": "Live carp fish other than fingerlings",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301941000",
    "Description": "Live young Atlantic and Pacific bluefin tunas (Thunnus thynnus Thunnus orientalis)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301949000",
    "Description": "Matured Atlantic and Pacific bluefin tunas (Thunnus thynnus Thunnus orientalis)",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301951000",
    "Description": "Live young southern bluefin tunas (Thunnus maccoyii) fish (fish fingerlings)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301959000",
    "Description": "Live southern bluefin tunas (Thunnus maccoyii) fish other than fingerlings.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301991000",
    "Description": "Other live young fish (fish fingerlings) not specified in this heading.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0301999000",
    "Description": "Other live fish other than fingerlings not specified in this heading.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302110000",
    "Description": "Trout (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus aguabonita etc)",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302130000",
    "Description": "Pacific salmon (Oncorhynchus nerka, Oncorhynchus gorbuscha etc)",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302140000",
    "Description": "Atlantic salmon (Salmo salar) and Danube salmon (Hucho hucho) meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302190000",
    "Description": "Other Salmonidae meat excluding livers and roes not specied in this subheading",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302210000",
    "Description": "Halibut (Reinhardtius hippoglossoides, Hippoglossus hippoglossus etc",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302220000",
    "Description": "Plaice(Pleuronectes platessa) meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302230000",
    "Description": "Sole (Solea spp.)meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302240000",
    "Description": "Turbots (Psetta maxima)meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302290000",
    "Description": "Other Flat fish meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302310000",
    "Description": "Albacore or longfinned tunas (Thunnus alalunga)meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302320000",
    "Description": "Yellowfin tunas (Thunnus albacares)meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302330000",
    "Description": "Skipjack or stripebellied bonito meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302340000",
    "Description": "Bigeye tunas (Thunnus obesus)meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302350000",
    "Description": "Atlantic and Pacific bluefin tunas (Thunnus thynnus, Thunnus orientalis)",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302360000",
    "Description": "Southern bluefin tunas (Thunnus maccoyii)meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302390000",
    "Description": "Other Tunas meat not specified in this heading, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302410000",
    "Description": "Herrings (Clupea harengus, Clupea pallasii) meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302420000",
    "Description": "Anchovies (Engraulis spp.)meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302430000",
    "Description": "Sardines (Sardina pilchardus, Sardinops spp.), sardinella, brisling or sprats.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302440000",
    "Description": "Mackerel (Scomber scombrus, Scomber australasicus, Scomber japonicus)",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302450000",
    "Description": "Jack and horse mackerel (Trachurus spp.) meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302460000",
    "Description": "Cobia (Rachycentron canadum) meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302470000",
    "Description": "Swordfish (Xiphias gladius) meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302490000",
    "Description": "Other fish, fresh or chilled, excluding fish fillets and other fish meat of heading 0304",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302510000",
    "Description": "Cod (Gadus morhua, Gadus ogac, Gadus macrocephalus) fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302520000",
    "Description": "Haddock (Melanogrammus aeglefinus) meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302530000",
    "Description": "Coalfish (Pollachius virens) meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302540000",
    "Description": "Hake (Merluccius spp., Urophycis spp.) meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302550000",
    "Description": "Alaska Pollack (Theragra chalcogramma)meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302560000",
    "Description": "Blue whitings (Micromesistius poutassou, Micromesistiusaustralis",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302590000",
    "Description": "Other Cod meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302710000",
    "Description": "Tilapias (Oreochromis spp.) meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302720000",
    "Description": "Catfish (Pangasius spp., Silurus spp., Clarias spp.,Ictalurus spp.)",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302730000",
    "Description": "Carp (Cyprinus carpio, Carassius carassius, Ctenopharyngodon idellus etc)",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302740000",
    "Description": "Eels (Anguilla spp.) meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302790000",
    "Description": "Other tilapias meat not specified in this heading, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302810000",
    "Description": "Dogfish and other sharks meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302820000",
    "Description": "Rays and skates (Rajidae)meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302830000",
    "Description": "Toothfish (Dissostichus spp.)meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302840000",
    "Description": "Seabass (Dicentrarchus spp.) meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302850000",
    "Description": "Seabream (Sparidae) meat, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302890000",
    "Description": "Other meat not specified in 03.02, fresh or chilled.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302910000",
    "Description": "Livers, roes and milt",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302920000",
    "Description": "Shark fins",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0302990000",
    "Description": "Other Livers, roes, milt, fish fins, heads, tails, maws and other edible\nfish offal",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303110000",
    "Description": "Sockeye salmon (red salmon) (Oncorhynchus nerka) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303120000",
    "Description": "Other Pacific salmon (Oncorhynchus gorbuscha, Oncorhynchus keta etc)",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303130000",
    "Description": "Atlantic salmon (Salmo salar) and Danube salmon (Hucho hucho) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303140000",
    "Description": "Trout (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki etc)",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303190000",
    "Description": "Other Salmonidae meat excluding livers and roes not specified in this heading, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303230000",
    "Description": "Tilapias (Oreochromis spp.) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303240000",
    "Description": "Catfish (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303250000",
    "Description": "Carp (Cyprinus carpio, Carassius carassius, Ctenopharyngodon idellus, frozen",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303260000",
    "Description": "Eels (Anguilla spp.) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303290000",
    "Description": "Other tilapias meat not specified in this heading, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303310000",
    "Description": "Halibut (Reinhardtius hippoglossoides, Hippoglossus hippoglossus etc",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303320000",
    "Description": "Plaice (Pleuronectes platessa) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303330000",
    "Description": "Sole (Solea spp.)meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303340000",
    "Description": "Turbots (Psetta maxima) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303390000",
    "Description": "Other Eels meat not specified in this heading, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303410000",
    "Description": "Albacore or longfinned tunas (Thunnus alalunga) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303420000",
    "Description": "Yellowfin tunas (Thunnus albacares) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303430000",
    "Description": "Skipjack or stripbellied bonito meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303440000",
    "Description": "Bigeye tunas (Thunnus obesus) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303450000",
    "Description": "Atlantic and Pacific bluefin tunas (Thunnus thynnus, Thunnus orientalis) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303460000",
    "Description": "Southern bluefin tunas (Thunnus maccoyii) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303490000",
    "Description": "Other Tunas meat not specified in this heading, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303510000",
    "Description": "Herrings (Clupea harengus, Clupea pallasii) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303530000",
    "Description": "Sardines, sardinella, brisling or sprats.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303540000",
    "Description": "Mackerel (Scomber scombrus, Scomber australasicus, Scomber japonicus) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303550000",
    "Description": "Jack and horse mackerel (Trachurus spp.) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303560000",
    "Description": "Cobia (Rachycentron canadum) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303570000",
    "Description": "Swordfish (Xiphias gladius) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303590000",
    "Description": "Other herrings, etc excl edible fish offal of subheadings 0303.91 to 0303.99",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303630000",
    "Description": "Cod (Gadus morhua, Gadus ogac, Gadus macrocephalus), frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303640000",
    "Description": "Haddock (Melanogrammus aeglefinus) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303650000",
    "Description": "Coalfish (Pollachius virens) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303660000",
    "Description": "Hake (Merluccius spp., Urophycis spp.) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303670000",
    "Description": "Alaska Pollack (Theragra chalcogramma) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303680000",
    "Description": "Blue whitings (Micromesistius poutassou, Micromesistius australis) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303690000",
    "Description": "Other Fish of the families not mentioned in this subheadng.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303810000",
    "Description": "Dogfish and other sharks meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303820000",
    "Description": "Rays and skates (Rajidae) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303830000",
    "Description": "Toothfish (Dissostichus spp.) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303840000",
    "Description": "Seabass (Dicentrarchus spp.) meat, frozen.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303890000",
    "Description": "Other Fish, frozen, excluding fish fillets and other not mentioned in this subheading.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303910000",
    "Description": "Livers, roes and milt",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0303920000",
    "Description": "--  Shark fins",
    "ID": 20,
    "DATE OF VALIDITY": "22/06/2020"
  },
  {
    "CET Code": "0303990000",
    "Description": "Other (fish fins, heads, tails, maws and other edible fish offals)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304310000",
    "Description": "Tilapias (Oreochromis spp.) fillets (whether or not minced), fresh or chilled.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304320000",
    "Description": "Catfish (Pangasius spp., Silurus spp., Clarias spp.,.. fresh or chilled.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304330000",
    "Description": "Nile Perch (Lates niloticus), fresh or chilled.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304390000",
    "Description": "Fresh or chilled fillets of heading 03.04 not specified or mentioned",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304410000",
    "Description": "Fresh or chilled fillets of Pacific salmon, Atlantic salmon and Danube salmon",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304420000",
    "Description": "Fresh or chilled fillets Trout",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304430000",
    "Description": "Fresh or chilled fillets of Flat fish",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304440000",
    "Description": "Fresh or chilled fillets of Fish of the families Bregmacerotidae, Euclichthyidae",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304450000",
    "Description": "Fresh or chilled fillets of Swordfish (Xiphias gladius)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304460000",
    "Description": "Fresh or chilled fillets of Toothfish (Dissostichus spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304470000",
    "Description": "Dogfish and other sharks",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304480000",
    "Description": "Rays and skates (Rajidae)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304490000",
    "Description": "Other Fresh or chilled fillets not specified in this heading.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304510000",
    "Description": "Fresh or chilled Tilapias, catfish, carp, eels, Nile perch and snakeheads",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304520000",
    "Description": "Fresh or chilled Salmonidae",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304530000",
    "Description": "Fresh or chilled Fish of of the families Bregmacerotidae, Euclichthyidae, Gadidae",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304540000",
    "Description": "Fresh or chilled Swordfish (Xiphias gladius)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304550000",
    "Description": "Fresh or chilled Toothfish (Dissostichus spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304560000",
    "Description": "Dogfish and other sharks",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304570000",
    "Description": "Rays and skates (Rajidae)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304590000",
    "Description": "Other Fresh or chilled not specified in this subheading",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304610000",
    "Description": "Frozen fillets of Tilapias (Oreochromis spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304620000",
    "Description": "Frozen fillets of Catfish (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304630000",
    "Description": "Frozen fillets of Nile Perch (Lates niloticus)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304690000",
    "Description": "Frozen fillets of carp, eels, Nile perch and snakeheads",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304710000",
    "Description": "Frozen fillets of Cod (Gadus morhua, Gadus ogac, Gadus macrocephalus)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304720000",
    "Description": "Frozen fillets of Haddock (Melanogrammus aeglefinus)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304730000",
    "Description": "Frozen fillets of Coalfish (Pollachius virens)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304740000",
    "Description": "Frozen fillets of Hake (Merluccius spp., Urophycis spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304750000",
    "Description": "Frozen fillets of Alaska Pollack (Theragra chalcogramma)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304790000",
    "Description": "Other Frozen fillets of fish of the families Bregmacerotidae, Bregmacerotidae",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304810000",
    "Description": "Frozen fillets of Pacific salmon, Atlantic salmon and Danube salmon.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304820000",
    "Description": "Frozen fillets of Trout",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304830000",
    "Description": "Frozen fillets Flat fish",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304840000",
    "Description": "Frozen fillets of Swordfish (Xiphias gladius)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304850000",
    "Description": "Frozen fillets of Toothfish (Dissostichus spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304860000",
    "Description": "Frozen fillets of Herrings (Clupea harengus, Clupea pallasii)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304870000",
    "Description": "Frozen fillets Tunas, skipjack or stripebellied bonito, pelamis",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304880000",
    "Description": "Dogfish, other sharks, rays and skates (Rajidae)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304890000",
    "Description": "Other Frozen fillets of fish not specified in this subheading",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304910000",
    "Description": "Frozen Swordfish (Xiphias gladius)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304920000",
    "Description": "Frozen Toothfish (Dissostichus spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304930000",
    "Description": "Frozen Tilapias, catfish, carp, Nile perch and snakeheads.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304940000",
    "Description": "Frozen Alaska Pollack (Theragra chalcogramma)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304950000",
    "Description": "Frozen Fish of the families Bregmacerotidae,...",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304960000",
    "Description": "Dogfish and other sharks",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304970000",
    "Description": "Rays and skates (Rajidae)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0304990000",
    "Description": "Other Frozen fish not specified in 03.04.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305200000",
    "Description": "Livers and roes of fish, dried, smoked, salted or in brine",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305310000",
    "Description": "Fish fillets of Tilapias, catfish, ..; dried, salted or in brine, but not smoked ",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305320000",
    "Description": "Fish fillets of Fish of the families Bregma.....dried, salted, brine, but not smoked ",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305390000",
    "Description": "Other fish, dried, salted or in brine, not elsewhere mentioned.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305410000",
    "Description": "Smokedfish, including fillet of Pacific, Atlantic... salmon other than edible fish offal",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305420000",
    "Description": "Smoked fish, including fillets of Herrings other than edible fish offal",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305430000",
    "Description": "Smoked fish, including fillets of Trout, other than edible fish offal",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305440000",
    "Description": "Smoked fish, including fillets of Tilapias, catfish, carpother than edible fish offal",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305490000",
    "Description": "Other Smoked fish, including fillets other than edible offals not elsewhere specified.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305510000",
    "Description": "Dried Cod (Gadus morhua, Gadus ogac, Gadus macrocephalus)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305520000",
    "Description": "Tilapias, catfish, carp, eels, Nile perch and snakeheads",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305530000",
    "Description": "Fish of the families Bregmacerotidae, Euclichthyidae, Gadidae, Macouridae, Melanonida",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305540000",
    "Description": "Herrings, anchovies, sardines, sardinella, brisling or sprats, mackerel ...",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305590000",
    "Description": "Other Dried fish, other than edible fish offal, whether or not salted but not smoked :",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "0305610000",
    "Description": "Herring ssalted but not dried or smoked and fish in brine, other than edible fish offal:",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305620000",
    "Description": "Cod salted but not dried or smoked and fish in brine, other than edible fish offal",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305630000",
    "Description": "Anchovies (Engraulis spp.), salted but not dried or smoked and fish in brine.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305640000",
    "Description": "Tilapias, catfish, carp, eels, Nile perch and snakeheads.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305690000",
    "Description": "fish, salted but not dried, smoked, brined, other than edible fish offal not mentioned",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305710000",
    "Description": "Shark fins",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305721000",
    "Description": "Cod head",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305729000",
    "Description": "Other Fish heads, tails and maws other than cod head.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0305790000",
    "Description": "Other Fish fins, heads, tails, maws and other edible fish offal not elsewhere mentioned",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306110000",
    "Description": "Frozen Rock lobster and other sea crawfish (Palinurus spp., Panulirus spp., Jasus spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306120000",
    "Description": "Frozen Lobsters (Homarus spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306140000",
    "Description": "Frozen Crabs",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306150000",
    "Description": "Frozen Norway lobsters (Nephrops norvegicus)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306160000",
    "Description": "Frozen Coldwater shrimps and prawns (Pandalus spp., Crangon crangon)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306170000",
    "Description": "Other Frozen shrimps and prawns",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306190000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "0306310000",
    "Description": "Rock lobster and other sea crawfish (Palinurus spp., Panulirus\nspp., Jasus spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306320000",
    "Description": "Lobsters (Homarus spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306330000",
    "Description": "Crabs",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306340000",
    "Description": "Norway lobsters (Nephrops norvegicus)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306350000",
    "Description": "Cold-water shrimps and prawns (Pandalus spp., Crangon crangon)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306360000",
    "Description": "Other shrimps and prawns",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306390000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "0306910000",
    "Description": "Rock lobster and other sea crawfish (Palinurus spp, Panulirus   spp, Jasus spp)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306920000",
    "Description": "Lobsters (Homarus spp)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306930000",
    "Description": "Crabs",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306940000",
    "Description": "Norway lobsters (Nephrops norvegicus)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306950000",
    "Description": "Shrimps and prawns",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0306990000",
    "Description": "--  Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0307110000",
    "Description": "Live, fresh or chilled Oysters",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307120000",
    "Description": "Frozen",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307190000",
    "Description": "Other: dried, salted or in brine Oysters",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307210000",
    "Description": "Live, fresh or chilled Scallops, and queen scallops, of the genera Pecten.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307220000",
    "Description": "Frozen Scallops, including queen scallops, of the genera Pecten, Chlamys\nor Placopecten",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307290000",
    "Description": "Other: dried, salted or in brine Scallops, and queen scallops, of the genera Pecten.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307310000",
    "Description": "Live, fresh or chilled Mussels (Mytilus spp., Perna spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307320000",
    "Description": "Frozen Mussels (Mytilus spp., Perna spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307390000",
    "Description": "Other: dried, salted or in brine Mussels (Mytilus spp., Perna spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307420000",
    "Description": "Live, fresh or chilled cuttle fish and squid.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307430000",
    "Description": "Frozen Cuttle fish and squid",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307490000",
    "Description": "Other Cuttle fish and squid, Excl. frozen, live, fresh or chilled",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307510000",
    "Description": "Live, fresh or chilled Octopus (Octopus spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307520000",
    "Description": "Frozen Octopus (Octopus spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307590000",
    "Description": "Other Octopus (Octopus spp.) Excl. frozen, live, fresh or chilled",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307600000",
    "Description": "live, fresh, chilled, frozen, dried, salted or in brine Snails, other than sea snails",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307710000",
    "Description": "Live, fresh or chilled Clams, cockles and ark shells.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307720000",
    "Description": "Frozen Clams, cockles and ark shells",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307790000",
    "Description": "Other Clams, cockles and ark shells Excl. frozen, live, fresh or chilled",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307810000",
    "Description": "Live, fresh or chilled Abalone (Haliotis spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307820000",
    "Description": "Live, fresh or chilled stromboid conchs (Strombus spp)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307830000",
    "Description": "Frozen abalone (Haliotis spp)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307840000",
    "Description": "Frozen stromboid conchs (Strombus spp)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307870000",
    "Description": "Other abalone (Haliotis spp)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307880000",
    "Description": "Other stromboid conchs (Stromboid spp)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307910000",
    "Description": "Other Live, fresh or chilled Molluscs not elsewhere specified fit for human consumption.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307920000",
    "Description": "Other frozen Molluscs, not elsewhere specified in this heading fit for human consumption",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0307990000",
    "Description": "Other dried, salted or in brine;incl flours. meals and pellets fit for human consumption",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0308110000",
    "Description": "Live, fresh or chilled Sea cucumbers (Stichopus japonicus, Holothurioidea)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0308120000",
    "Description": "Frozen Sea cucumbers (Stichopus japonicus, Holothurioidea)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0308190000",
    "Description": "Other, dried, salted or in brine Sea cucumbers (Stichopus japonicus, Holothurioidea) fit",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0308210000",
    "Description": "Live, fresh or chilled Sea urchins (Strongylocentrotus spp., Paracentrotus lividus,....)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0308220000",
    "Description": "Frozen Sea urchins (Strongylocentrotus spp., Paracentrotus lividus...)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0308290000",
    "Description": "Other Sea urchins (Strongylocentrotus spp., Paracentrotus lividus....)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0308300000",
    "Description": "Live, fresh or chilled Jellyfish (Rhopilema spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0308900000",
    "Description": "Frozen, dried, salted or in brine Jellyfish (Rhopilema spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0309100000",
    "Description": "- Of fish",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0309901000",
    "Description": "-- Of crustaceans",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0309909000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0401100000",
    "Description": "Milk and cream, not concentrated and sugarless of a fat content, by weight,= < 1 %",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0401200000",
    "Description": "Milk and cream, not concentrated and sugarless of a fat content, by weight, >= 1 % < 6 %",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0401400000",
    "Description": "Milk and cream, not concentrated and sugarlessof a fat content, by weight, > 6 % <10 %",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0401500000",
    "Description": "Milk and cream, not concentrated and sugarlessof a fat content, by weight, <=10 %",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0402101000",
    "Description": "Milk/cream, conc. containing sugar/sweetening matter; solid, <= 1.5 %fat content, >25 kg",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0402102100",
    "Description": "sweetened solid Milk/cream concentrate; <= 1.5 % fat content for pharmaceuticals",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0402102900",
    "Description": "sweetened solid Milk/cream concentrate; <= 1.5 % fat content not for pharmaceuticals",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "0402211000",
    "Description": "non sweetened solid Milk/cream concentrate; > 1.5 %fat content,>25 kg",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0402212100",
    "Description": "Non sweetened solid Milk/cream concentrate; > 1.5 % fat content for pharmaceuticals",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0402212900",
    "Description": "Non sweetened solid Milk/cream concentrate; > 1.5 % fat content not for pharmaceuticals",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "0402291000",
    "Description": "sweetened solid Milk/cream concentrate; > 1.5 % fat content In packings >25 kg",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0402292100",
    "Description": "sweetened solid Milk/cream concentrate; <= 1.5 % fat content for phamaceuticals",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0402292900",
    "Description": "sweetened solid Milk/cream concentrate; <= 1.5 % fat content not for phamaceuticals",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "0402911000",
    "Description": "liquid milk/cream In packings of 25 kg or more",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0402912000",
    "Description": "liquid milk/cream In packings of less than 25 kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0402990000",
    "Description": "Other liquid milk containing sweetened",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0403201000",
    "Description": "--  Plain, not containing fruit or cocoa",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0403202000",
    "Description": "--  Containing fruit but not containing cocoa",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0403203000",
    "Description": "--  Containing cocoa but not containing fruit",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0403209000",
    "Description": "--  Other",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0403901100",
    "Description": "Buttermilk; In powder form",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0403901900",
    "Description": "Buttermil;, not in powdered form",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0403909100",
    "Description": "Curdled milk and cream, kephir and other fermented or acidified milk (in powder form)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "0403909900",
    "Description": "Curdled milk and cream, kephir and other fermented or acidified milk (not in powder)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "0404100000",
    "Description": "Whey and modified whey.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "0404900000",
    "Description": "Natural milk;concentrated not elsewhere specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "0405100000",
    "Description": "Butter",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0405200000",
    "Description": "Dairy spreads",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0405901000",
    "Description": "Butterfat and butteroil",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "0405909000",
    "Description": "Other fats and oils derived from milk excluding dairy spread, Butterfat and butteroil.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0406100000",
    "Description": "Fresh (unripened or uncured) cheese, including whey cheese and curd",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0406200000",
    "Description": "Grated or powdered cheese, of all kinds",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0406300000",
    "Description": "Processed cheese, not grated or powdered",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0406400000",
    "Description": "Blue-veined cheese and other cheese containing veins produced by Penicillium roqueforti",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0406900000",
    "Description": "curds",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0407110000",
    "Description": "fetilised eggs of fowls of the species Gallus domesticus",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0407190000",
    "Description": "fertilised eggs of birds excluding fowls of the species Gallus domesticus",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0407210000",
    "Description": "Non fertilised eggs of of the species Gallus domesticus",
    "SU": "KG",
    "ID": 35,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0407290000",
    "Description": "Non fertilised eggs of birds excluding fowls of the species Gallus domesticus",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0407900000",
    "Description": "Others birds egg not specified.",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0408110000",
    "Description": "Dried egg yolks",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0408190000",
    "Description": "Egg yolks (not dried)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0408910000",
    "Description": "Non yolk part of eggs; Dried",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0408990000",
    "Description": "Non yolk part of eggs;(excl. dried)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0409000000",
    "Description": "Natural honey.",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0410100000",
    "Description": "- Insects",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0410900000",
    "Description": "- Other",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0501000000",
    "Description": "Human hair, unworked, whether or not washed or scoured; waste of human hair.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0502100000",
    "Description": "Pigs', hogs' or boars' bristles and hair and waste thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0502900000",
    "Description": "Other bristles and hair; and waste of such bristles or hair",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0504000000",
    "Description": "Guts, bladders and stomachs of animals, fresh,preserved in any way.",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0505100000",
    "Description": "Feathers of a kind used for stuffing; down",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0505900000",
    "Description": "Other parts of birds treated for preservation or disinfected",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0506100000",
    "Description": "Ossein and bones treated with acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0506900000",
    "Description": "Other bones and horn cores treated with acid or degelatinised;powder & waste of products",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0507101000",
    "Description": "Elephant tusks",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0507109000",
    "Description": "Other forms of ivory excluding tusks",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0507900000",
    "Description": "tortoise and whales exoskeleton, not cut to shape; powder and waste of these products.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0508000000",
    "Description": "Coral, shells of molluscs, crustaceans or the kind, not cut to shape; waste thereof.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0510000000",
    "Description": "Animal products used in pharmaceutical products, fresh, chilled, frozen or preserved.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0511100000",
    "Description": "Bovine semen",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0511910000",
    "Description": "Products of aquatic invertebrates; dead animals of Chapter 3",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0511990000",
    "Description": "Other animal products not elsewhere specified, unfit for human consumption",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0601100000",
    "Description": "Bulbs, tubers, tuberous roots, corms, crowns and rhizomes, dormant",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0601200000",
    "Description": "tuberous roots, corms, crowns and rhizomes, chicory plants and roots",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0602100000",
    "Description": "Unrooted cuttings and slips",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0602200000",
    "Description": "Trees, shrubs and bushes, grafted or not, of kinds which bear edible fruit or nuts",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0602300000",
    "Description": "Rhododendrons and azaleas, grafted or not",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0602400000",
    "Description": "Roses, grafted or not",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "0602900000",
    "Description": "Other live plants (including their roots), cuttings and slips; mushroom spawn",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0603110000",
    "Description": "Roses",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0603120000",
    "Description": "Carnations",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0603130000",
    "Description": "Orchids",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0603140000",
    "Description": "Chrysanthemums",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0603150000",
    "Description": "Lilies (Lilium spp.)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0603190000",
    "Description": "Other fresh cut flowers and flower buds",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0603900000",
    "Description": "Other cut flowers & flower buds of kind suitable ornamental purposes fresh,dried,dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0604200000",
    "Description": "Fresh",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0604900000",
    "Description": "Other, not fresh",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0701100000",
    "Description": "Seed",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0701900000",
    "Description": "Other fresh or chilled potatoes or seed",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0702000000",
    "Description": "Tomatoes, fresh or chilled.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0703100000",
    "Description": "Onions and shallots",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0703200000",
    "Description": "Garlic",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0703900000",
    "Description": "Leeks and other alliaceous vegetables",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0704100000",
    "Description": "Cauliflowers and headed broccoli",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0704200000",
    "Description": "Brussels sprouts",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0704900000",
    "Description": "Other cabbages, cauliflowers, kohirabi, kale & simila edible brassicas, fresh or chilled",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0705110000",
    "Description": "Cabbage lettuce (head lettuce)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0705190000",
    "Description": "Other lettuce, fresh or chilled",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0705210000",
    "Description": "Witloof chicory (Cichorium intybus var. foliosum)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0705290000",
    "Description": "Other chicory, fresh or chilled",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0706100000",
    "Description": "Carrots and turnips",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0706900000",
    "Description": "Other similar edible roots, fresh or chilled",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0707000000",
    "Description": "Cucumbers and gherkins, fresh or chilled.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0708100000",
    "Description": "Peas (Pisum sativum)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0708200000",
    "Description": "Beans (Vigna spp., Phaseolus spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0708900000",
    "Description": "Other leguminous vegetables",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0709200000",
    "Description": "Asparagus",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0709300000",
    "Description": "Aubergines (eggplants)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0709400000",
    "Description": "Celery other than celeriac",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0709510000",
    "Description": "Mushrooms of the genus Agaricus",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0709520000",
    "Description": "-- Mushrooms of the genus Boletus",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0709530000",
    "Description": "--   Mushrooms of the genus Cantharellus",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0709540000",
    "Description": "--   Shiitake (Lentinus edodes)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0709550000",
    "Description": "--   Matsutake (Tricholoma matsutake, Tricholoma magnivelare, Tricholoma anatolicum,",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0709560000",
    "Description": "--   Truffles (Tuber spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0709590000",
    "Description": "Other mushrooms and truffles, fresh or chilled",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0709600000",
    "Description": "Fruits of the genus Capsicum or of the genus Pimenta",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0709700000",
    "Description": "Spinach, New Zealand spinach and orache spinach (garden spinach)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0709910000",
    "Description": "Globe artichokes",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0709920000",
    "Description": "Olives",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0709930000",
    "Description": "Pumpkins, squash and gourds (Cucurbita spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0709991000",
    "Description": "Sweet corn",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0709999000",
    "Description": "Other vegetables, fresh or chilled not specified or included.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0710100000",
    "Description": "Potatoes",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0710210000",
    "Description": "Peas (Pisum sativum)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0710220000",
    "Description": "Beans (Vigna spp., Phaseolus spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0710290000",
    "Description": "Other leguminous vegetables, shelled or unshelled",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0710300000",
    "Description": "Spinach, New Zealand spinach and orache spinach (garden spinach)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0710400000",
    "Description": "Sweet corn",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0710800000",
    "Description": "Other vegetables",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0710900000",
    "Description": "Mixtures of vegetables",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0711200000",
    "Description": "Olives",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0711400000",
    "Description": "Cucumbers and gherkins",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0711510000",
    "Description": "Mushrooms of the genus Agaricus",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0711590000",
    "Description": "Other mushrooms and truffles",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0711900000",
    "Description": "Other vegetables; mixtures of vegetables",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0712200000",
    "Description": "Onions",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0712310000",
    "Description": "Mushrooms of the genus Agaricus",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0712320000",
    "Description": "Wood ears (Auricularia spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0712330000",
    "Description": "Jelly fungi (Tremella spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0712340000",
    "Description": "--   Shiitake (Lentinus edodes)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0712390000",
    "Description": "Other mushrooms, woodears,jelly fungi & truffles whole, cut, sliced, broken or in powder",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0712900000",
    "Description": "Other vegetables; mixtures of vegetables",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713101000",
    "Description": "Peas (Pisum sativum) Seeds",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713109000",
    "Description": "Other peas not specified.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713201000",
    "Description": "Chickpeas (garbanzos) Seeds",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713209000",
    "Description": "Other chickpeas not specified.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713311000",
    "Description": "Seeds of Beans of the species Vigna mungo (L.) Hepper or Vigna radiata (L.) Wilczek:",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713319000",
    "Description": "Beans of the species Vigna mungo, Hepper or Vigna radiata, Wilczek(excluding seedss)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713321000",
    "Description": "Small red (Adzuki) beans (Phaseolus or Vigna angularis) Seeds",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713329000",
    "Description": "Small red (Adzuki) beans (Phaseolus or Vigna angularis)(excluding seedss)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713331000",
    "Description": "Seeds of Kidney beans, including white pea beans",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713339000",
    "Description": "Kidney beans, including white pea beans (excluding seedss)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713341000",
    "Description": "Seeds of Bambara beans (Vigna subterranea or Voandzeia subterranea):",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713349000",
    "Description": "Other Bambara beans (Vigna subterranea or Voandzeia subterranea):(excluding seedss)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713351000",
    "Description": "Seeds of Cow peas (Vigna unguiculata)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713359000",
    "Description": "Other Cow peas (Vigna unguiculata) (excluding seedss)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713390000",
    "Description": "Other: beans not specified.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713401000",
    "Description": "Seeds of Lentils",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713409000",
    "Description": "Other lentils not specified.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713501000",
    "Description": "Seeds of Broad beans and horse beans",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713509000",
    "Description": "Other broad beans not specified.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713601000",
    "Description": "Seeds of Pigeon peas (Cajanus cajan)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713609000",
    "Description": "Other pigeon peas not specified.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0713900000",
    "Description": "Dried leguminous not specified.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0714100000",
    "Description": "Manioc (cassava)",
    "SU": "KG",
    "ID": 20,
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0714200000",
    "Description": "Sweet potatoes",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0714300000",
    "Description": "Yams (Dioscorea spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0714400000",
    "Description": "Taro (Colocasia spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0714500000",
    "Description": "Yautia (Xanthosoma spp.)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0714900000",
    "Description": "Other roots and tubers Not specified or included in this heading",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0801110000",
    "Description": "Desiccated Coconuts",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0801120000",
    "Description": "Coconuts In the inner shell (endocarp)",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0801190000",
    "Description": "Other coconuts, fresh or dried, whether or not shelled or peeled",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0801210000",
    "Description": "Brazil nuts In shell",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0801220000",
    "Description": "Brazil nuts Shelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0801310000",
    "Description": "Cashew nuts In shell",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0801320000",
    "Description": "Cashew nuts Shelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802110000",
    "Description": "Almonds In shell",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802120000",
    "Description": "Almonds Shelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802210000",
    "Description": "Almonds In shell",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802220000",
    "Description": "Almonds Shelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802310000",
    "Description": "Walnuts In shell",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802320000",
    "Description": "Walnuts Shelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802410000",
    "Description": "Chestnuts (Castanea spp.) In shell",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802420000",
    "Description": "Chestnuts (Castanea spp.) Shelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802510000",
    "Description": "Pistachios In shell",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802520000",
    "Description": "Pistachios Shelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802610000",
    "Description": "Macadamia nuts In shell",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802620000",
    "Description": "Macadamia nuts Shelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802700000",
    "Description": "Kola nuts (Cola spp.)",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802800000",
    "Description": "Areca nuts",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "0802910000",
    "Description": "Pine nuts, shelled",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0802920000",
    "Description": "--  Pine nuts, shelled",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0802990000",
    "Description": "--  Other",
    "SU": "KG",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "0803101000",
    "Description": "Fresh Plantains",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0803102000",
    "Description": "Dried Plantains",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0803901000",
    "Description": "Other Fresh Bananas excluding plantain",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0803902000",
    "Description": "Other dried Bananas excluding plantain",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0804100000",
    "Description": "Dates fresh or dried",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0804200000",
    "Description": "Figs fresh or dried",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0804300000",
    "Description": "Pineapples fresh or dried",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0804400000",
    "Description": "Avocados fresh or dried",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0804501000",
    "Description": "Mangoes fresh or dried",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0804509000",
    "Description": "Guavas and mangosteens, fresh or dried.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0805100000",
    "Description": "Oranges fresh or dried",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0805210000",
    "Description": "Mandarins (including tangerines and satsumas)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0805220000",
    "Description": "Clementines",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0805290000",
    "Description": "Other; wilkings and similar citrus hybrids",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0805400000",
    "Description": "Grapefruit, including pomelos",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0805500000",
    "Description": "Lemons and limes fresh or dried.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0805900000",
    "Description": "Other citrus fruit, fresh or dried",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0806100000",
    "Description": "Fresh grape",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0806200000",
    "Description": "Dried grape",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0807110000",
    "Description": "Watermelons",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0807190000",
    "Description": "Other melons excluding watermelons",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0807200000",
    "Description": "Papaws (papayas)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0808100000",
    "Description": "Apples",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0808300000",
    "Description": "Pears",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0808400000",
    "Description": "Quinces",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0809100000",
    "Description": "Apricots",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0809210000",
    "Description": "Sour cherries (Prunus cerasus)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0809290000",
    "Description": "Other Cherries, excluding sour cherries",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0809300000",
    "Description": "Peaches, including nectarines",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0809400000",
    "Description": "Plums and sloes",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0810100000",
    "Description": "fresh Strawberries",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0810200000",
    "Description": "fresh Raspberries, blackberries, mulberries and loganberries",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0810300000",
    "Description": "fresh Black, white or red currants and gooseberries",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0810400000",
    "Description": "fresh Cranberries, bilberries and other fruits of the genus Vaccinium",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0810500000",
    "Description": "fresh Kiwifruit",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0810600000",
    "Description": "fresh Durians",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0810700000",
    "Description": "fresh Persimmons",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0810900000",
    "Description": "Other fruit not elsewhere specified, fresh",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0811100000",
    "Description": "Strawberries",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0811200000",
    "Description": "Raspberries, blackberries, mulberries, loganberries, currants and gooseberries",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0811900000",
    "Description": "Other Fruit and nuts, uncooked or cooked, frozen, whether or not sweetened.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0812100000",
    "Description": "Cherries",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0812900000",
    "Description": "Other fruit and nuts, provisionally preserved but unsuitable for immediate consumption",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0813100000",
    "Description": "Apricots",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0813200000",
    "Description": "Prunes",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0813300000",
    "Description": "Apples",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0813401000",
    "Description": "Tamarind",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0813409000",
    "Description": "Other fruits not specified.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0813500000",
    "Description": "Mixtures of nuts or dried fruits of this Chapter",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0814000000",
    "Description": "Peel of citrus fruit or melons, fresh, frozen, dried or preserved.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901111100",
    "Description": "Cherry red Arabica,Not decaffeinated, non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901111200",
    "Description": "Extraprima, prima, superior, Arabica,Not decaffeinated, non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901111300",
    "Description": "Normal, small, very small sized, Arabica,Not decaffeinated, non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901111900",
    "Description": "other, Arabica,Not decaffeinated, non roasted coffee not elsewhere specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112100",
    "Description": "Cherry red rabusta,Not decaffeinated, non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112200",
    "Description": "Extra prima, prima, superior excellent grade O, not decaffe robusta non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112300",
    "Description": "Extra prima, normal excellent, grade four not decaffe robusta non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112400",
    "Description": "Normal extra prima, small, grade two not decaffe robusta non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112510",
    "Description": "Grade one Extra prima, prima, superior not decaffe robusta non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112520",
    "Description": "Grade two Extra prima, prima, superior not decaffe robusta non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112590",
    "Description": "Grade three Extra prima, prima, superior, not decaffe robusta non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112610",
    "Description": "Grade one Normal, small, very small sized not decaffe robusta non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112620",
    "Description": "Grade three Normal, small, very small sized not decaffe robusta non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112630",
    "Description": "Grade four Normal, small, very small sized not decaffe robusta non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112690",
    "Description": "Other Normal, small, very small sized not decaffeinated robusta coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112700",
    "Description": "Green coffee, broken not decaffeinated robusta coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112800",
    "Description": "Black coffee, broken not decaffeinated robusta coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901112900",
    "Description": "Other non decaffe robusta non roasted coffee not elsewhere specied,",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901113100",
    "Description": "Cherryred, arabusta, non decaffe, non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901113200",
    "Description": "Extraprima, prima, superior, arabusta, non decaffe, non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901113300",
    "Description": "Normal, small, very small sized, arabusta, non decaffe, non roasted coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901113400",
    "Description": "Other arabusta, non decaffe, non roasted coffee not elsewhere specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901114100",
    "Description": "Cherryred, Liberica, non decaffe, non roasted coffee not elsewhere specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901114200",
    "Description": "Extraprima, prima, superior, Liberica, non decaffe, non roasted coffee not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901114300",
    "Description": "Normal, small, very small sized, Liberica, non decaffe, non roasted coffee not specif.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901114400",
    "Description": "Other Liberica, non decaffe, non roasted coffee not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901115100",
    "Description": "Other, Cherry red, non decaffe, non roasted coffee not elsewhere specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901115200",
    "Description": "Other, Extraprima, prima, superior non decaffe, non roasted coffee not elsewhere specif.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901115300",
    "Description": "Other, Normal, small, very small sized, non decaffe/roasted coffee not elsewhere specif.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901115400",
    "Description": "Other, Extra prima, prima superior, non decaffe/ roasted coffee not elsewhere specif.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901121000",
    "Description": "Decaffeinated, non roasted Robusta coffee..",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901122000",
    "Description": "Decaffeinated, non roasted Arabusta coffee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901129000",
    "Description": "Other decaffeinated coffee not specified elsewhere.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901211000",
    "Description": "Uncrushed, Not decaffeinated, roasted coffee",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901212000",
    "Description": "Crushed, Not decaffeinated, roasted coffee",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901221000",
    "Description": "Uncrushed, decaffeinated, roasted coffee",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901222000",
    "Description": "Crushed, decaffeinated, roasted coffee",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0901900000",
    "Description": "Other roasted decaffeinated coffee",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0902100000",
    "Description": "Green tea (not fermented) in immediate packings of a content not exceeding 3 kg",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0902200000",
    "Description": "Other green tea (not fermented) exceeding 3kg",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0902300000",
    "Description": "Black tea (fermented), in immediate packings of a content not exceeding 3 kg",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0902400000",
    "Description": "Other black tea (fermented) and other partly fermented tea, exceeding 3kg.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "0903000000",
    "Description": "Mate.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0904110000",
    "Description": "Pepper Neither crushed nor ground",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0904120000",
    "Description": "Pepper Crushed or ground",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0904210000",
    "Description": "Fruits of the genus Capsicum or of the genus Pimenta, Dried, neither crushed nor ground",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0904220000",
    "Description": "Fruits of the genus Capsicum or of the genus Pimenta, Crushed or ground",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0905100000",
    "Description": "Vanilla, Neither crushed nor ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0905200000",
    "Description": "Fruits of the genus Capsicum or of the genus Pimentaanilla, Crushed or ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0906110000",
    "Description": "Cinnamon (Cinnamomum zeylanicum Blume), Neither crushed nor ground ",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0906190000",
    "Description": "Other Cinnamon and cinnamon flowers, Neither crushed nor ground ",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0906200000",
    "Description": "Cinnamon and cinnamontree flowers, Crushed or ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0907100000",
    "Description": "Cloves (whole fruit, cloves and stems),Neither crushed nor ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0907200000",
    "Description": "Cloves (whole fruit, cloves and stems), Crushed or ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0908110000",
    "Description": "Nutmeg, Neither crushed nor ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0908120000",
    "Description": "Nutmeg, Crushed or ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0908210000",
    "Description": "Mace, Neither crushed nor ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0908220000",
    "Description": "Mace, Crushed or ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0908310000",
    "Description": "Cardamons, Neither crushed nor ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0908320000",
    "Description": "Cardamons, Crushed or ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0909210000",
    "Description": "Seeds of coriander : Neither crushed nor ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0909220000",
    "Description": "Seeds of coriander, Crushed or ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0909310000",
    "Description": "Seeds of cumin, Neither crushed nor ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0909320000",
    "Description": "Seeds of cumin, Crushed or ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0909610000",
    "Description": "Seeds of anise, badian, caraway or fennel; juniper berries, Neither crushed nor ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0909620000",
    "Description": "Seeds of anise, badian, caraway or fennel; juniper berries, Crushed or ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0910110000",
    "Description": "Ginger, Neither crushed nor ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0910120000",
    "Description": "Ginger, Crushed or ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0910200000",
    "Description": "Saffron",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0910300000",
    "Description": "Turmeric (curcuma)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0910910000",
    "Description": "Mixtures referred to in Note 1 (b) to this Chapter",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "0910990000",
    "Description": "Other spices not elsewhere mentioned.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1001110000",
    "Description": "Durum wheat, Seed",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1001190000",
    "Description": "Durum wheat (Not in seeds)",
    "SU": "KG",
    "ID": 5,
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1001910000",
    "Description": "meslin Seed",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1001990000",
    "Description": "Meslin (Not in seeds)",
    "SU": "KG",
    "ID": 5,
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1002100000",
    "Description": "Seed",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1002900000",
    "Description": "Other (excluding seedss)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1003100000",
    "Description": "Rye Seed",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1003900000",
    "Description": "Rye(excluding seedss)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1004100000",
    "Description": "Oats Seed",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1004900000",
    "Description": "Oats (excluding seedss)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1005100000",
    "Description": "Maize (corn) Seed",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1005900000",
    "Description": "Maize (corn), (not in seed)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1006101000",
    "Description": "Rice Seedlings",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1006109000",
    "Description": "Other Rice in the husk (paddy or rough) not specified.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1006200000",
    "Description": "- Husked brown rice (Miller/BIP)",
    "ID": 10,
    "LVY": 20,
    "DATE OF VALIDITY": "19/06/2020"
  },
  {
    "CET Code": "1006301000",
    "Description": "--  In packings of more than 5 kg or in bulk",
    "ID": 10,
    "LVY": 50,
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "1006309000",
    "Description": "Semimilled or wholly milled rice, whether polished or glazed, In packings of <5 kg",
    "SU": "KG",
    "ID": 10,
    "LVY": 50,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1006400000",
    "Description": "Broken rice",
    "SU": "KG",
    "ID": 10,
    "LVY": 50,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1007100000",
    "Description": "sorghum Seed",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1007900000",
    "Description": "sorghum (excluding seedss)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1008100000",
    "Description": "Buckwheat",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1008210000",
    "Description": "eed of Millet",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1008290000",
    "Description": "Millet (excluding seedss)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1008300000",
    "Description": "Canary seeds",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1008400000",
    "Description": "Fonio (Digitaria spp.)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1008500000",
    "Description": "Quinoa (Chenopodium quinoa)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1008600000",
    "Description": "Triticale",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1008900000",
    "Description": "Other cereals not elsewhere specified.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1101000000",
    "Description": "Wheat or meslin flour.",
    "SU": "KG",
    "ID": 20,
    "LVY": 50,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1102200000",
    "Description": "Maize (corn) flour",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1102901000",
    "Description": "flour of millet or sorghum",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1102909000",
    "Description": "flour of other cereals not specified.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1103110000",
    "Description": "Groats and meal of wheat",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1103130000",
    "Description": "Groats and meal of maize (corn)",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1103190000",
    "Description": "Groats and mealof other cereals",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1103200000",
    "Description": "Pellets of cereals",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1104120000",
    "Description": "Rolled or flaked grains of oats",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1104190000",
    "Description": "Rolled or flaked grains of other cereals",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1104220000",
    "Description": "Other worked grains (for example, hulled, pearled, sliced or kibbled) : of oats",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1104230000",
    "Description": "Other worked grains (for example, hulled, pearled, sliced or kibbled)  Of maize (corn)",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1104290000",
    "Description": "Other worked grains (for example, hulled, pearled, sliced or kibbled) Of other cereals",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1104300000",
    "Description": "Germ of cereals, whole, rolled, flaked or ground",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1105100000",
    "Description": "Flour, meal and powder of potatoes",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1105200000",
    "Description": "Flakes, granules and pellets of potatoes",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1106100000",
    "Description": "Flour, meal and powder Of the dried leguminous vegetables of heading 07.13",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1106201100",
    "Description": "Flour and powder Of sago or of roots or tubers of heading 07.14 cassava",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1106201200",
    "Description": "Meal/dough of cassava",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1106202000",
    "Description": "Flour, meal and powder Of yam",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1106209100",
    "Description": "Cocoyam flour",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1106209900",
    "Description": "Rice in the husk (paddy or rough) (excluding seeds)",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1106301000",
    "Description": "Plantain flour",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1106309000",
    "Description": "Flour, meal and powder Of the products of Chapter 8 not elsewhere specified",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1107100000",
    "Description": "Malt Not roasted",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1107200000",
    "Description": "Malt Roasted",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1108111000",
    "Description": "Wheat starch; Pharmaceutical grade",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1108119000",
    "Description": "Wheat starch excluding Pharmaceutical grade",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1108121000",
    "Description": "Maize (corn) starch; Pharmaceutical grade",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1108129000",
    "Description": "Maize (corn) starch excluding Pharmaceutical grade",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1108131000",
    "Description": "Pharmaceutical grade",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1108139000",
    "Description": "Potato starch excluding Pharmaceutical grade",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1108141000",
    "Description": "Pharmaceutical grade",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1108149000",
    "Description": "Manioc (cassava) starch not Pharmaceutical grade",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1108191000",
    "Description": "Other starches of Pharmaceutical grade not elsewhere specified",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1108199000",
    "Description": "Other starches not elsewhere specified or included excluding Pharmaceutical grade",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1108200000",
    "Description": "Inulin",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1109000000",
    "Description": "Wheat gluten, whether or not dried.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1201100000",
    "Description": "Soya beans Seed",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "1201900000",
    "Description": "Soya beans (excluding seedss)",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "1202300000",
    "Description": "Groundnuts Seed",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "1202411000",
    "Description": "Groundnuts in shell used for manufacturing oil",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "1202419000",
    "Description": "Groundnuts in shell not used for manufacturing oil",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "1202421000",
    "Description": "Groundnuts shelled used for manufacturing oil",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "1202429000",
    "Description": "Groundnuts shelled not used for manufacturing oil",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "1203000000",
    "Description": "Copra.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1204000000",
    "Description": "Linseed, whether or not broken.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1205100000",
    "Description": "Low erucic acid rape or colza seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1205900000",
    "Description": "Rape or colza seeds, whether or not broken Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1206000000",
    "Description": "Sunflower seeds, whether or not broken.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1207100000",
    "Description": "Palm nuts and kernels",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1207210000",
    "Description": "cotton Seed",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1207290000",
    "Description": "cotton (excluding seedss)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1207300000",
    "Description": "Castor oil seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1207400000",
    "Description": "Sesamum seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1207500000",
    "Description": "Mustard seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1207600000",
    "Description": "Safflower (Carthamus tinctorius) seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1207700000",
    "Description": "Melon seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1207910000",
    "Description": "Poppy seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1207991000",
    "Description": "Shea nuts (karate nuts)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1207999000",
    "Description": "Other oil seeds and oleaginous fruits, not elsewhere specified",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1208100000",
    "Description": "Flours and meals of soya beans",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1208900000",
    "Description": "Flour and meals of oil seeds or oleagin Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1209100000",
    "Description": "Sugar beet seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1209210000",
    "Description": "Lucerne (alfalfa) seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1209220000",
    "Description": "Clover (Trifolium spp.) seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1209230000",
    "Description": "Fescue seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1209240000",
    "Description": "Kentucky blue grass (Poa pratensis L.) seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1209250000",
    "Description": "Rye grass (Lolium multiflorum Lam., Lolium perenne L.) seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1209290000",
    "Description": "Seeds, fruit and spores, of a kind used Other (Seeds of forage plants)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1209300000",
    "Description": "Seeds of herbaceous plants cultivated principally for their flowers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1209910000",
    "Description": "Vegetable seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1209990000",
    "Description": "Seeds, fruit and spores, of a kind used Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1210100000",
    "Description": "Hop cones, neither ground nor powdered nor in the form of pellets",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1210200000",
    "Description": "Hop cones, ground, powdered or in the form of pellets; lupulin",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1211200000",
    "Description": "Ginseng roots",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1211300000",
    "Description": "Coca leaf",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1211400000",
    "Description": "Poppy straw",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1211500000",
    "Description": "Ephedra",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1211600000",
    "Description": "- Bark of African cherry (Prunus africana)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "1211901000",
    "Description": "Pyrethrum",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1211902000",
    "Description": "Bark and wood used in pharmacy and medicine",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1211909000",
    "Description": "Other Plants or parts, used in perfumery, pharmacy or insecticidal, in any form.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1212210000",
    "Description": "Seaweeds and other algae Fit for human consumption",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1212290000",
    "Description": "Seaweeds and other algae not Fit for human consumption",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1212910000",
    "Description": "Sugar beet",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1212920000",
    "Description": "Locust beans (carob)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1212930000",
    "Description": "Sugar cane",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1212940000",
    "Description": "Chicory roots",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1212990000",
    "Description": "Other Not specified or included in this heading",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1213001000",
    "Description": "Corn husks",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1213009000",
    "Description": "Cereal straw",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1214100000",
    "Description": "Lucerne (alfalfa) meal and pellets",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1214900000",
    "Description": "Other forage products,whether or not in the form of pellets",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1301200000",
    "Description": "Gum Arabic",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1301900000",
    "Description": "Other Lac excluding Gum Arabic",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1302110000",
    "Description": "Vegetable saps and extracts of Opium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1302120000",
    "Description": "Vegetable saps and extracts of liquorice",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1302130000",
    "Description": "Vegetable saps and extracts of hops",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1302140000",
    "Description": "Of ephedra",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1302190000",
    "Description": "Other Vegetable saps and extracts not specified in this subheading.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1302200000",
    "Description": "Pectic substances, pectinates and pectates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1302310000",
    "Description": "Agaragar",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1302320000",
    "Description": "Mucilages and thickeners, derived from locust beans, locust bean seeds or guar seeds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1302390000",
    "Description": "elsewhere specified",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1401100000",
    "Description": "Bamboos",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1401200000",
    "Description": "Rattans",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1401900000",
    "Description": "Vegetable materials of a kind used primarily for plaiting excluding bamboos and rattans.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1404200000",
    "Description": "Cotton linters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1404901000",
    "Description": "Hard seeds, pips, hulls and nuts of a kind used for carving",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1404909000",
    "Description": "Other Vegetable products not elsewhere specified or included in this chapter.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1501100000",
    "Description": "Lard",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1501200000",
    "Description": "Other pig fat",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1501900000",
    "Description": "poultry fat, other than that of heading 02.09 or 15.03",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1502100000",
    "Description": "Tallow",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "1502900000",
    "Description": "Other Fats of bovine animals, sheep or goats, not specified in this heading.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "1503000000",
    "Description": "Lard stearin,/oil, oleo stearin/oil and tallow oil, not emulsified or mixed or prepared.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1504100000",
    "Description": "Fishliver oils and their fractions whether or not refined, but not chemically modified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1504200000",
    "Description": "Fats and oils and their fractions, of fish, other than liver oils",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1504300000",
    "Description": "Fats and oils and their fractions, of marine mammals",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1505000000",
    "Description": "Wool grease and fatty substances derived therefrom (including lanolin).",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1506000000",
    "Description": "Other animal fats and oils and their fractions, but not chemically modified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1507100000",
    "Description": "Crude soyabean oil, whether or not degummed",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1507900000",
    "Description": "refined soyabean oil, but not chemically modified.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1508100000",
    "Description": "Crude groundnut oil",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1508901000",
    "Description": "groundnut oil, Put up for retail sale in packings with a net content of 5 litres or less",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1508909000",
    "Description": "groundnut oil, Put up for retail sale in packings with a net content of 5 litres or more",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1509201000",
    "Description": "--  Put up for retail sale in packings with a net content of 5 litres or less",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "1509209000",
    "Description": "--  Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "1509301000",
    "Description": "--  Put up for retail sale in packings with a net content of 5 litres or less",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "1509309000",
    "Description": "--  Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "1509401000",
    "Description": "--  Put up for retail sale in packings with a net content of 5 litres or less",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "1509409000",
    "Description": "--  Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "1509901000",
    "Description": "Other olive oils put up for retail sale in packings with a net content of <=5l",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1509909000",
    "Description": "Other olive oils put up for retail sale in packings with a net content of >5l",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1510100000",
    "Description": "-  Crude olive pomace oil",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "1510901000",
    "Description": "-- Put up for retail sale in packings with a net content of 5 litres or less",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "1510909000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "1511100000",
    "Description": "Crude palm oil",
    "SU": "KG",
    "ID": 10,
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1511901000",
    "Description": "Fractions of Palm oil and its fractions, not fit for human consumption.",
    "SU": "KG",
    "ID": 10,
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1511909100",
    "Description": "Refined palm oil put up for retail sale in packings with content of 5 litres or less",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1511909900",
    "Description": "Refined palm oil put up for retail sale in packings with content of 5 litres or more",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1512110000",
    "Description": "Crude Sunflowerseed or safflower oil",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1512190000",
    "Description": "refined Sunflowerseed or safflower oil",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1512210000",
    "Description": "Crude Cottonseed oil, whether or not gossypol has been removed",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1512290000",
    "Description": "Refined Cottonseed oil",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1513110000",
    "Description": "Crude Coconut (copra) oil",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1513190000",
    "Description": "refined Coconut (copra) oil",
    "SU": "KG",
    "ID": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1513210000",
    "Description": "Crude Palm kernel oil",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1513290000",
    "Description": "Refined Palm kernel oil",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1514110000",
    "Description": "Crude Rape, colza or mustard oil",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1514190000",
    "Description": "Refined Rape, colza or mustard oil",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1514910000",
    "Description": "Crude mustard oil",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1514990000",
    "Description": "refined mustard oil",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1515110000",
    "Description": "Crude Linseed oil",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1515190000",
    "Description": "Refined Linseed oil",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1515210000",
    "Description": "Crude Maize (corn) oil",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1515290000",
    "Description": "Refined Maize (corn) oil",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1515300000",
    "Description": "Castor oil and its fractions",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1515500000",
    "Description": "Sesame oil and its fractions",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1515600000",
    "Description": "- Microbial fats and oils and their fractions",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "1515901100",
    "Description": "Crude Shea (karite) oil",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1515901900",
    "Description": "Refined Shea (karite) oil",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1515909000",
    "Description": "Other fixed vegetable fats and oils not elsewhere specified.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1516100000",
    "Description": "Animal fats and oils and their fractions",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1516201000",
    "Description": "Hydrogenated vegetable fats not further prepared.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1516209000",
    "Description": "Other vegetable fats and oil, not specified in this subheading.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1516300000",
    "Description": "-  Microbial fats and oils and their fractions”.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "1517100000",
    "Description": "Margarine, excluding liquid margarine",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "1517901000",
    "Description": "Other food preparations of vegetable fats",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1517909000",
    "Description": "Other vegetable not specified in this heading other than those of heading 15.16.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1518000000",
    "Description": "Animal or vegetable fats and oils, boiled, oxidised... by heat in vacuum or in inert gas",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1520000000",
    "Description": "Glycerol, crude; glycerol waters and glycerol lyes.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1521100000",
    "Description": "Vegetable waxes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1521901000",
    "Description": "Beeswax and other insect waxes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1521909000",
    "Description": "Other other insect waxes and spermaceti",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1522000000",
    "Description": "Degras; residues resulting from the treatment of fatty substances, animal or veg waxes.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1601001000",
    "Description": "Sausages and similar products, Of liver; food preparations based on these products.",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1601009000",
    "Description": "Sausages and similar products, of heading 16.01 not elsewhere specified.",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1602100000",
    "Description": "Homogenised preparations of meat, meat offal or blood.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1602200000",
    "Description": "Prepared or preserved liver of any animal",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1602310000",
    "Description": "Prepared or preserved meat of turkeys",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1602320000",
    "Description": "Prepared or preserved meat of fowls of the species Gallus domesticus",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1602390000",
    "Description": "Other prepared or preserved meat, meat offal or blood not elsewhere specified",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1602410000",
    "Description": "Prepared or preserved Hams and cuts thereof of swine",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1602420000",
    "Description": "Prepared or preserved Shoulders and cuts thereof of swine",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1602490000",
    "Description": "Other prepared or preserved mixtyres of meat, meat offal or blood.",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1602501000",
    "Description": "Canned beef (corned beef)",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1602509000",
    "Description": "meat of bonvine animal excluding canned.",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1602900000",
    "Description": "Other prepared/preserved meat not elsewhere mention; preparation of blood of any animal.",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1603000000",
    "Description": "Extracts and juices of meat, fish or crustaceans, molluscs or aquatic invertebrates.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1604110000",
    "Description": "prepared or preserved Salmon, whole or in piece but not mixed.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1604120000",
    "Description": "prepared or preserved Herrings, whole or in piece but not mixed.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1604131000",
    "Description": "prepared or preserved, Sardines, in 1/4 club cans of a height of 30 mm or less:",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1604139000",
    "Description": "Other Sardines, sardinella and brisling or sprats not specified inthis subheading.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1604140000",
    "Description": "prepare or preserved Tunas, skipjack and bonito (Sarda spp.)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1604150000",
    "Description": "prepare or preserved Mackerel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1604160000",
    "Description": "prepare or preserved Anchovies",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1604170000",
    "Description": "prepare or preserved Eels",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1604180000",
    "Description": "Shark fins",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1604190000",
    "Description": "Other prepared or preserved fish whole or in pieces not specified in this subheading",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1604200000",
    "Description": "Other prepared or preserved fish not specified in this heading",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1604310000",
    "Description": "prepare or preserved Caviar",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1604320000",
    "Description": "prepare or preserved Caviar substitutes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605100000",
    "Description": "prepare or preserved Crab",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605210000",
    "Description": "prepare or preserved Shrimps and prawns not in airtight container",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605290000",
    "Description": "prepare or preserved Shrimps and prawns in airtight container",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605300000",
    "Description": "prepare or preserved Lobster",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605400000",
    "Description": "Other prepare or preserved crustaceans not specified 16.05.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605510000",
    "Description": "prepare or preserved Oysters",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605520000",
    "Description": "prepare or preserved Scallops, including queen scallops",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605530000",
    "Description": "prepare or preserved Mussels",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605540000",
    "Description": "prepare or preserved Cuttle fish and squid",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605550000",
    "Description": "prepare or preserved Octopus",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605560000",
    "Description": "prepare or preserved Clams, cockles and arkshells",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605570000",
    "Description": "prepare or preserved Abalone",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605580000",
    "Description": "prepare or preserved Snails, other than sea snails",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605590000",
    "Description": "Other molluscs prepared or preserved not specified in16.05.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605610000",
    "Description": "prepare or preserved Sea cucumbers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605620000",
    "Description": "prepare or preserved Sea urchins",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605630000",
    "Description": "prepare or preserved Jellyfish",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1605690000",
    "Description": "Other aquatic invertebrates, prepared or preserved, not specified in this subheading.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1701120000",
    "Description": "Raw Beet sugar not containing added flavouring or colouring matter",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 50,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1701130000",
    "Description": "Raw Cane sugar specified in Subheading Note 2 to Chapter 17",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 60,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1701141000",
    "Description": "Cane sugar specified in Subheading Note 2 to Chapter 17, Meant for sugar refinery",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 5,
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "1701149000",
    "Description": "Other Raw sugar not containing added flavour or colouring matter not specif. in 17.01",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1701911000",
    "Description": "Other, Cane or beet sugar ..,with favour/colour in powder, crystal or granule form",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 50,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1701919000",
    "Description": "Other, Cane or beet sugar ..,with favour/colour, Not in powder, crystal or granule form",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 50,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1701991000",
    "Description": "In powder, crystal or granule form",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1701999000",
    "Description": "Other cane or beet sugar and chemically pure sucrose, in solid form",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 50,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1702110000",
    "Description": "Lactose and lactose syrup; Containing by weight 99 % or more lactose, expressed as ",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1702190000",
    "Description": "Other; Containing by weight less than 99% lactose, exp",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1702200000",
    "Description": "Maple sugar and maple syrup",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1702300000",
    "Description": "Glucose and glucose syrup, containing 0 to < 20 % dry weight of fructose.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1702400000",
    "Description": "Glucose/glucose syrup, containing 20 to =< 50 % dry weight of fructose, no invert sugar",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1702500000",
    "Description": "Chemically pure fructose",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1702600000",
    "Description": "Glucose/glucose syrup, containing > 50 % dry weight of fructose, no invert sugar",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1702900000",
    "Description": "Other, including invert sugar and other sugar containing dry state 50 % of fructose",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1703100000",
    "Description": "Cane molasses",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1703900000",
    "Description": "Other molasses resulting from the extraction or refining of sugar",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1704100000",
    "Description": "Chewing gum, whether or not sugarcoated",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1704900000",
    "Description": "Other Sugar confectionery (including white chocolate), not containing cocoa.",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1801001100",
    "Description": "Superior quality Cocoa beans",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1801001200",
    "Description": "Standard quality Cocoa beans",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1801001900",
    "Description": "Other quality Cocoa beans not specified in 18.01",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1801002000",
    "Description": "Roasted Cocoa beans",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1801003000",
    "Description": "Broken Cocoa beans",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1802000000",
    "Description": "Cocoa shells, husks, skins and other cocoa waste.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1803100000",
    "Description": "Not defatted Cocoa paste",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1803200000",
    "Description": "Wholly or partly defatted Cocoa paste",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1804001000",
    "Description": "Fat and oil of cocoa",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1804002000",
    "Description": "Natural cocoa butter",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1804009000",
    "Description": "Other butter of cocoa and deodorised cocoa",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1805001000",
    "Description": "Cocoa powder In packing with a net content exceeding 2 kg",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1805009000",
    "Description": "Cocoa powder In packing with a net content not exceeding 2 kg",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1806100000",
    "Description": "Cocoa powder, containing added sugar or other sweetening matter",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1806200000",
    "Description": "Other preparations Cocoa powder in blocks, slabs... in packing exceeding 2kg",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1806310000",
    "Description": "Other preparations Cocoa powder in blocks, slabs... in packing not exceeding 2kg; Filled",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1806321000",
    "Description": "Other preparations Cocoa powder in blocks... in packing =< 2kg;not Filled Chocolate",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1806329000",
    "Description": "Other preparations Cocoa powder in block... in packing =< 2kg;not Filled; no Chocolate",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1806901000",
    "Description": "Other confectionery containing cocoa and chocolate",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1806909000",
    "Description": "Other food preparationsnot containing cocoa and chocolate",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1901100000",
    "Description": "Preparations for infant use, put up for retail sale",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1901200000",
    "Description": "Mixes and doughs for the preparation of bakers' wares of heading 19.05",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1901901000",
    "Description": "Milk preparations containing vegetable fats /oils, powdered/granular, packings > 25 kg",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "04/02/2022"
  },
  {
    "CET Code": "1901902000",
    "Description": "Prep. of milk containing vegetable fats and oils, powdered or granular =12.5kg or <25kg",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/05/2022"
  },
  {
    "CET Code": "1901903000",
    "Description": "Malt extract",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1901904000",
    "Description": "Malted beverage powder in bulk of not less than 25 kg",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "1901909100",
    "Description": "Cassava food preparation of 11.06 (including \"Gari\" excluding products of 19.03)",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "1901909900",
    "Description": "Other food preparations not specified in 19.02.",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "1902110000",
    "Description": "Uncooked pasta, not stuffed or otherwise prepared : Containing eggs",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1902190000",
    "Description": "Uncooked pasta, not stuffed or otherwise prepared : not Containing eggs",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1902200000",
    "Description": "Stuffed pasta, whether or not cooked or otherwise prepared",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1902300000",
    "Description": "Other pasta not specified elsewhere",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1902400000",
    "Description": "Couscous",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1903000000",
    "Description": "Tapioca and substitutes from starch, in the form of flakes, grains...or similar forms.",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "1904100000",
    "Description": "Prepared foods obtained by the swelling or roasting of cereals or cereal products",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "1904201000",
    "Description": "Oat meal in packings of not less than 25 kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1904209000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1904300000",
    "Description": "Bulgur wheat",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1904900000",
    "Description": "Other prepared foods obtained by the swelling or roasting of cereals/cereal products",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1905100000",
    "Description": "Crispbread",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1905200000",
    "Description": "Gingerbread and the like",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1905310000",
    "Description": "Sweet biscuits",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1905320000",
    "Description": "Waffles and wafers",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1905400000",
    "Description": "Rusks, toasted bread and similar toasted products",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "1905900000",
    "Description": "Bread,pastry cakes, biscuits and other, Other.",
    "SU": "KG",
    "ID": 35,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2001100000",
    "Description": "Cucumbers and gherkins",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2001900000",
    "Description": "Vegetables, fruit, nuts and other edible Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2002100000",
    "Description": "Tomatoes, whole or in pieces",
    "SU": "KG",
    "ID": 35,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2002901100",
    "Description": "Triple concentrate(Note : additional LEVY USD 1,500 per Metric ton applicable)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "2002901900",
    "Description": "Other concentrate (excluding Triple concentrate(Note: ADD. LEVY USD 1,500/MT applicable)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "2002902000",
    "Description": "Tomato paste or concentrates, put up for retail sale",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2002909000",
    "Description": "Other Tomatoes prepared or preserved not specified.",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2003100000",
    "Description": "Mushrooms of the genus Agaricus",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2003900000",
    "Description": "Mushrooms and truffles, prepared or preserved Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2004100000",
    "Description": "Potatoes",
    "SU": "KG",
    "ID": 35,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2004900000",
    "Description": "Other vegetables and mixtures of vegetables",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2005100000",
    "Description": "Homogenised vegetables",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2005200000",
    "Description": "Potatoes",
    "SU": "KG",
    "ID": 35,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2005400000",
    "Description": "Peas (Pisum sativum)",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2005510000",
    "Description": "Beans, shelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2005590000",
    "Description": "Beans,not shelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2005600000",
    "Description": "Asparagus",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2005700000",
    "Description": "Olives",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2005800000",
    "Description": "Sweet corn (Zea mays var. saccharata)",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2005910000",
    "Description": "Bamboo shoots",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2005990000",
    "Description": "Other vegetables and mixtures of vegetables (excluding bamboo shoot)",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2006000000",
    "Description": "Vegetables, fruit, nuts, fruitpeel and other parts of plants, preserved by sugar.",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2007100000",
    "Description": "Homogenised preparations",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2007910000",
    "Description": "Citrus fruit",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2007990000",
    "Description": "Jams, fruit jellies, marmalades, fruit Other.",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2008111000",
    "Description": "Peanut butter",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2008119000",
    "Description": "Other nuts Not specified or included in this subheading",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2008190000",
    "Description": "Other, including mixtures",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2008200000",
    "Description": "Pineapples",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2008300000",
    "Description": "Citrus fruit",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2008400000",
    "Description": "Pears",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2008500000",
    "Description": "Apricots",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2008600000",
    "Description": "Cherries",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2008700000",
    "Description": "Peaches, including nectarines",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2008800000",
    "Description": "Strawberries",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2008910000",
    "Description": "Palm hearts",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2008930000",
    "Description": "Cranberries (Vaccinium macrocarpon, Vaccinium oxycoccos, Vaccinium vitisidaea)",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2008970000",
    "Description": "Mixtures",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2008990000",
    "Description": "Fruit,nuts and other edible part of plant Other(Other, including mixtures...)",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2009111000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009119000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009121000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009129000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009191000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009199000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009211000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009219000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009291000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009299000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009311000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009319000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009391000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009399000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009411000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009419000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009491000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009499000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009501000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009509000",
    "Description": "Other tomatoes juice not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009611000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009619000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009691000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009699000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009711000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009719000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009791000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009799000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009811000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009819000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009891100",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009891900",
    "Description": "Other guava juice not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009892100",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009892900",
    "Description": "Other tamarind juice nnot specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009893100",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009893900",
    "Description": "Other mango juice not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009899100",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009899900",
    "Description": "Other juice not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009901000",
    "Description": "Concentrate of weight not less than 25 kg for industrial use",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2009909000",
    "Description": "Other; Concentrate of weight less than 25kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2101110000",
    "Description": "Extracts, essences and concentrates",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2101120000",
    "Description": "Preparation with a basis of extracts, essences or concentrates or with a basis of coffee",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2101200000",
    "Description": "Extracts, essences and concentrates, of tea or mate, and preparations",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2101300000",
    "Description": "Roasted chicory and coffee substitutes, and extracts, essences and concentrates thereof",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2102100000",
    "Description": "Active yeasts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2102200000",
    "Description": "Inactive yeasts; other singlecell microorganisms, dead",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2102300000",
    "Description": "Prepared baking powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2103100000",
    "Description": "Soya sauce",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2103200000",
    "Description": "Tomato ketchup and other tomato sauces",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2103300000",
    "Description": "Mustard flour and meal and prepared mustard",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2103901000",
    "Description": "Fish sauce \"NUOCMAM\"",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2103909100",
    "Description": "Seasoning powder in packings of not less than 25 kg",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2103909900",
    "Description": "---  Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "2104101000",
    "Description": "Preparations in block or loaf form",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2104109000",
    "Description": "Other Soups and broths and preparations therefor not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2104200000",
    "Description": "Homogenised composite food preparations",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2105000000",
    "Description": "Ice cream and other edible ice, whether or not containing cocoa.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2106101000",
    "Description": "Protein concentrates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2106102000",
    "Description": "Textured protein substances",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2106901000",
    "Description": "Syrups containing added flavouring or colouring matter",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2106909100",
    "Description": "Stock powder",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "2106909200",
    "Description": "Extracts, essences and flavouring products of a kind used in the drink industries",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2106909300",
    "Description": "Breadimprovers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2106909400",
    "Description": "Powders for the instant preparation of drinks",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2106909500",
    "Description": "Preparations for \"herbal tea\"",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2106909600",
    "Description": "Food supplements",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2106909900",
    "Description": "Other Food preparations not elsewhere specified or included.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "2201101000",
    "Description": "Mineral waters",
    "SU": "LT",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "2201102000",
    "Description": "Aerated waters",
    "SU": "LT",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "2201900000",
    "Description": "Waters, including natural or artificial Other",
    "SU": "LT",
    "ID": 35,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2202100000",
    "Description": "Waters, mineral and aerated waters, containing sugar or sweetening matter or flavoured",
    "SU": "LT",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/06/2022"
  },
  {
    "CET Code": "2202910000",
    "Description": "Non-alcoholic beer",
    "SU": "L",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/06/2022"
  },
  {
    "CET Code": "2202991000",
    "Description": "Beverages containing high doses of caffeine of the kind “Energy drinks”",
    "SU": "L",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/06/2022"
  },
  {
    "CET Code": "2202999000",
    "Description": "-  Other",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/06/2022"
  },
  {
    "CET Code": "2203001000",
    "Description": "In containers holding 50 centilitres or less",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "01/06/2022"
  },
  {
    "CET Code": "2203009000",
    "Description": "Other Beer made from malt not specified.",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "01/06/2022"
  },
  {
    "CET Code": "2204100000",
    "Description": "Sparkling wine",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "2204210000",
    "Description": "Other wine; grape with fermentation prevented by alcohol : In containers holding <2 L",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "2204220000",
    "Description": "In containers holding more than 2 l but not more than 10 l",
    "SU": "L",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "2204291000",
    "Description": "Wine; grape with fermentation prevented by alcohol : In containers >200 l for industry",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "2204299000",
    "Description": "Grape must with fermentation prevented by alcohol: In containers >200L not for industry",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "2204300000",
    "Description": "Other grape must not elsewhere specified",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "2205100000",
    "Description": "Vermouth and other wine of grapes flavoured with plants/aromatic substances. In <=2 L",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "2205900000",
    "Description": "Vermouth and other wine of grapes flavoured with plants/aromatic substances. In >2 L",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 50,
    "EXC": 20,
    "DATE OF VALIDITY": "01/06/2022"
  },
  {
    "CET Code": "2206001000",
    "Description": "Other beer not made from malt",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/06/2022"
  },
  {
    "CET Code": "2206009100",
    "Description": "Palm wine",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "16/09/2020"
  },
  {
    "CET Code": "2206009900",
    "Description": "Other fermented beverages not elsewhere specified.",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/06/2022"
  },
  {
    "CET Code": "2207101000",
    "Description": "Undenatured ethyl alcohol with alcoholic =>80% vol: For medical, pharmacy or science",
    "SU": "CL",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "07/12/2021"
  },
  {
    "CET Code": "2207109000",
    "Description": "Undenatured ethyl alcohol with alcoholic =>80% vol: not for medical, pharmacy or science",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "04/11/2021"
  },
  {
    "CET Code": "2207200000",
    "Description": "Ethyl alcohol and other spirits, denatured, of any strength",
    "SU": "CL",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "15/04/2022"
  },
  {
    "CET Code": "2208201000",
    "Description": "Brandy",
    "SU": "L",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "14/01/2022"
  },
  {
    "CET Code": "2208209000",
    "Description": "Other spirits not specified.",
    "SU": "LT",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "14/01/2022"
  },
  {
    "CET Code": "2208300000",
    "Description": "Whiskies",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "2208400000",
    "Description": "Rum and other spirits obtained by distilling fermented sugarcane products",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "2208500000",
    "Description": "Gin and Geneva",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "2208600000",
    "Description": "Vodka",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "2208700000",
    "Description": "Liqueurs and cordials",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "2208900000",
    "Description": "Other spirits, liqueurs & other spirituous beverages",
    "SU": "L",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "14/01/2022"
  },
  {
    "CET Code": "2209001000",
    "Description": "Alcohol vinegar",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "2209009000",
    "Description": "Other Vinegar and substitutes for vinegar obtained from acetic acid not specified.",
    "SU": "CL",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "2301100000",
    "Description": "Flours, meals and pellets, of meat or meat offal; greaves",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2301200000",
    "Description": "Flours, meals and pellets, of fish, crustaceans, molluscs or other aquatic invertebrates",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2302100000",
    "Description": "Bran, sharps and other residues of maize (corn)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2302300000",
    "Description": "Bran, sharps and other residues of wheat",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2302400000",
    "Description": "Bran, sharps and other residues of other cereals",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2302500000",
    "Description": "Bran, sharps and other residues of leguminous plants",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2303100000",
    "Description": "Residues of starch manufacture and similar residues",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2303200000",
    "Description": "Beetpulp, bagasse and other waste of sugar manufacture",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2303300000",
    "Description": "Brewing or distilling dregs and waste",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2304000000",
    "Description": "Oilcake and other solid residues, resulting from the extraction of soyabean oil.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2305000000",
    "Description": "Oilcake and other solid residues, resulting from the extraction of groundnut oil.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2306100000",
    "Description": "Oilcake and other solid residues, resulting from the extraction of oil from cotton seeds",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2306200000",
    "Description": "Oilcake and other solid residues, resulting from the extraction of oil from linseed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2306300000",
    "Description": "Oilcake and solid residues, resulting from the extraction of oil from sunflower seeds",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2306410000",
    "Description": "Oilcake and residues, from the extraction of oil from low erucic acid rape/colza seeds",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2306490000",
    "Description": "Oilcake and residues, from the extraction of oil from other erucic acid rape/colza seed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2306500000",
    "Description": "Oilcake and residues, from the extraction of oil from of coconut or copra",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2306600000",
    "Description": "Oilcake and residues, from the extraction of oil from of palm nuts or kernels",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2306900000",
    "Description": "Other oil cakes & residues, resulting from extraction of other vegetable fats and oils",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2307000000",
    "Description": "Wine lees; argol.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2308000000",
    "Description": "Vegetable materials,waste, residues.. used in animal feeding, not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2309100000",
    "Description": "Dog or cat food, put up for retail sale",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2309901000",
    "Description": "Preparations containing added vitamins",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2309909000",
    "Description": "Other animal feed preparations not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2401100000",
    "Description": "Tobacco, not stemmed/stripped",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2401200000",
    "Description": "Tobacco, partly or wholly stemmed/stripped",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2401300000",
    "Description": "Tobacco refuse",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2402100000",
    "Description": "Cigars, cheroots and cigarillos, containing tobacco",
    "SU": "ST",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "01/06/2022"
  },
  {
    "CET Code": "2402200000",
    "Description": "Cigarettes containing tobacco",
    "SU": "ST",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "2402900000",
    "Description": "Cigars, cheroots, cigarillos and cigaret Other",
    "SU": "ST",
    "ID": 20,
    "VAT": "7.5",
    "EXC": 20,
    "DATE OF VALIDITY": "01/06/2022"
  },
  {
    "CET Code": "2403110000",
    "Description": "Water pipe tobacco specified in Subheading Note 1 to this Chapter",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/06/2022"
  },
  {
    "CET Code": "2403190000",
    "Description": "Other Smoking tobacco, whether or not containing tobacco",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/09/2020"
  },
  {
    "CET Code": "2403910000",
    "Description": "\"Homogenised\" or \"reconstituted\" tobacco",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 60,
    "EXC": 20,
    "DATE OF VALIDITY": "05/09/2020"
  },
  {
    "CET Code": "2403991000",
    "Description": "Expanded tobacco",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/09/2020"
  },
  {
    "CET Code": "2403999000",
    "Description": "Other manufactured tobacco and manufactured tobacco not specified in 24.03",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 50,
    "DATE OF VALIDITY": "05/09/2020"
  },
  {
    "CET Code": "2404110000",
    "Description": "-- Containing tobacco or reconstituted tobacco",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 50,
    "EXC": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2404120000",
    "Description": "-- Other, containing nicotine",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 50,
    "EXC": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2404190000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 50,
    "EXC": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2404910000",
    "Description": "-- For oral application",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 50,
    "EXC": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2404920000",
    "Description": "-- For transdermal application",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 50,
    "EXC": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2404990000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 50,
    "EXC": 20,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2501001000",
    "Description": "Denatured salt",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2501002000",
    "Description": "-  Salt for human consumption",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "LVY": 60,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2501003000",
    "Description": "Compressed salt used in animal feeding",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2501009000",
    "Description": "Other salt not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2502000000",
    "Description": "Unroasted iron pyrites.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2503000000",
    "Description": "Sulphur of all kinds, other than sublimed, precipitated and colloidal sulphur.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2504100000",
    "Description": "Natural graphite in powder or in flakes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2504900000",
    "Description": "Other Natural graphite not specified in 25.04",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2505100000",
    "Description": "Silica sands and quartz sands",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2505900000",
    "Description": "Natural sands of all kinds, excluding silica and quartz",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2506100000",
    "Description": "Quartz",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2506200000",
    "Description": "Quartzite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2507000000",
    "Description": "Kaolin and other kaolinic clays, whether or not calcined.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2508100000",
    "Description": "Bentonite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2508300000",
    "Description": "Fireclay",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2508400000",
    "Description": "Other clays",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2508500000",
    "Description": "Andalusite, kyanite and sillimanite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2508600000",
    "Description": "Mullite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2508700000",
    "Description": "Chamotte or dinas earths",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2509000000",
    "Description": "Chalk.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2510100000",
    "Description": "Natural calcium phosphates, aluminium calcium phosphates and phosphatic chalk. Unground",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2510200000",
    "Description": "Natural calcium phosphates, aluminium calcium phosphates and phosphatic chalk .Ground",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2511100000",
    "Description": "Natural barium sulphate (barytes)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2511200000",
    "Description": "Natural barium carbonate (witherite)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2512000000",
    "Description": "Siliceous fossil meals and similar siliceous earths, of specific gravity =< 1",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2513100000",
    "Description": "Pumice stone",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2513200000",
    "Description": "Emery, natural corundum, natural garnet and other natural abrasives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2514000000",
    "Description": "Slate, in blocks or slabs of a rectangular shape.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2515110000",
    "Description": "Crude or roughly trimmed",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2515120000",
    "Description": "Marble and travertine cut into blocks or slabs in rectangular/ square shape",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2515200000",
    "Description": "Ecaussine and other calcareous monumental or building stone; alabaster",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2516110000",
    "Description": "Crude or roughly trimmed",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2516120000",
    "Description": "Granite cut into blocks or slabs in rectangular/ square shape",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2516200000",
    "Description": "Sandstone",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2516900000",
    "Description": "Other monumental or building stone",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2517100000",
    "Description": "Pebbles, gravel.. used for road metalling, railway or other ballast, shingle and flint",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2517200000",
    "Description": "Macadam of slag, dross or similar industrial waste,",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2517300000",
    "Description": "Tarred macadam",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2517410000",
    "Description": "Granules, chippings and powder of marble",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2517490000",
    "Description": "Pebbles, gravel, broken or crushed stone Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2518100000",
    "Description": "Dolomite, not calcined or sintered",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2518200000",
    "Description": "Calcined or sintered dolomite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2519100000",
    "Description": "Natural magnesium carbonate (magnesite)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2519900000",
    "Description": "Natural magnesium carbonate (magnesite); Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2520100000",
    "Description": "Gypsum; anhydrite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2520200000",
    "Description": "Plasters",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2521000000",
    "Description": "Limestone flux; limestone and stone of a kind used for manufacture of lime or cement.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2522100000",
    "Description": "Quicklime",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2522200000",
    "Description": "Slaked lime",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2522300000",
    "Description": "Hydraulic lime",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2523100000",
    "Description": "Cement clinkers",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2523210000",
    "Description": "White cement, whether or not artificially coloured",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2523290000",
    "Description": "Other excluding White cement",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 30,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2523300000",
    "Description": "Aluminous cement",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2523900000",
    "Description": "Other hydraulic cements",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2524100000",
    "Description": "Crocidolite",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2524900000",
    "Description": "Other Asbestos excluding Crocidolite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2525100000",
    "Description": "Crude mica and mica rifted into sheets or splitting",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2525200000",
    "Description": "Mica powder",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2525300000",
    "Description": "Mica waste",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2526100000",
    "Description": "Limestone flux; limestone and other calcareous stone, not crushed, not powdered",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2526200000",
    "Description": "Limestone flux; limestone and other calcareous stone; Crushed or powdered",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2528000000",
    "Description": "Natural borates/concentrates,not including borates; containing <= 85 % of H3BO3",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2529100000",
    "Description": "Feldspar",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2529210000",
    "Description": "Feldspar; Containing by weight 97 % or less of calcium fluoride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2529220000",
    "Description": "Feldspar; Containing by weight more than 97 % of calcium fluoride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2529300000",
    "Description": "Leucite; nepheline and nepheline syenite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2530100000",
    "Description": "Vermiculite, perlite and chlorites, unexpanded",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2530200000",
    "Description": "Kieserite, epsomite (natural magnesium sulphates)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2530900000",
    "Description": "Other Mineral substances not elsewhere specified or included",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2601111000",
    "Description": "Nonagglomerated: Iron ores lumps",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2601112000",
    "Description": "Nonagglomerated: Iron ores fines",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2601113000",
    "Description": "Nonagglomerated: Iron ores concentrates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2601119000",
    "Description": "Roasted iron pyrites.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2601120000",
    "Description": "Agglomerated Iron ores and concentrates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2601200000",
    "Description": "Roasted iron pyrites",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2602000000",
    "Description": "Manganese ores/concentrates, including ferruginous manganese' dry content =>20 %",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2603000000",
    "Description": "Copper ores and concentrates.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2604000000",
    "Description": "Nickel ores and concentrates.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2605000000",
    "Description": "Cobalt ores and concentrates.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2606000000",
    "Description": "Aluminium ores and concentrates.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2607000000",
    "Description": "Lead ores and concentrates.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2608000000",
    "Description": "Zinc ores and concentrates.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2609000000",
    "Description": "Tin ores and concentrates.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2610000000",
    "Description": "Chromium ores and concentrates.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2611000000",
    "Description": "Tungsten ores and concentrates.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2612100000",
    "Description": "Uranium ores and concentrates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2612200000",
    "Description": "Thorium ores and concentrates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2613100000",
    "Description": "Roasted",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2613900000",
    "Description": "Molybdenum ores and concentrates. Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2614000000",
    "Description": "Titanium ores and concentrates.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2615100000",
    "Description": "Zirconium ores and concentrates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2615900000",
    "Description": "Niobium, tantalum, vanadium or zirconium Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2616100000",
    "Description": "Silver ores and concentrates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2616901000",
    "Description": "Gold ores and concentrates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2616909000",
    "Description": "Other Precious metal ores and concentrates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2617100000",
    "Description": "Antimony ores and concentrates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2617900000",
    "Description": "Other ores and concentrates.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2618000000",
    "Description": "Granulated slag (slag sand) from the manufacture of iron or steel.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2619000000",
    "Description": "Slag, dross (other than granulated slag), scalings and othe",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2620110000",
    "Description": "Hard zinc spelter",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2620190000",
    "Description": "Ash and residues (other than from the manufacture of iron and steel Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2620210000",
    "Description": "Leaded gasoline sludges and leaded antiknock compound sludges",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2620290000",
    "Description": "Ash and residues Otherlead",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2620300000",
    "Description": "Containing mainly copper",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2620400000",
    "Description": "Containing mainly aluminium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2620600000",
    "Description": "Containing arsenic, mercury, thallium or their mixtures, ",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2620910000",
    "Description": "Containing antimony, beryllium, cadmium, chromium or their mixtures",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2620990000",
    "Description": "Other ashes and residues containing arsenic, metals or their compounds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2621100000",
    "Description": "Ash and residues from the incineration of municipal waste",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2621900000",
    "Description": "Other slag and ash, including seaweed as Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2701110000",
    "Description": "Anthracite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2701120000",
    "Description": "Bituminous coal",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2701190000",
    "Description": "Other coal",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2701200000",
    "Description": "Briquettes, ovoids and similar solid fuels manufactured from coal",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2702100000",
    "Description": "Lignite, whether or not pulverised, but not agglomerated",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2702200000",
    "Description": "Agglomerated lignite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2703000000",
    "Description": "Peat (including peat litter), whether or not agglomerated.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2704000000",
    "Description": "Coke and semicoke of coal, of lignite or of peat, whether ",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2705000000",
    "Description": "Coal gas, water gas, producer gas and similar gases, other ",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2706000000",
    "Description": "Tar distilled from coal, from lignite or from peat, and oth",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2707100000",
    "Description": "Benzol (benzene)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2707200000",
    "Description": "Toluol (toluene)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2707300000",
    "Description": "Xylol (xylenes)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2707400000",
    "Description": "Naphthalene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2707500000",
    "Description": "Other aromatic hydrocarbon mixtures of which 65 % or more",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2707910000",
    "Description": "Creosote oils",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2707990000",
    "Description": "Benzol (Benzene) Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2708100000",
    "Description": "Pitch",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2708200000",
    "Description": "Pitch coke",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2709000000",
    "Description": "Petroleum oils and oils obtained from bituminous minerals, crude.",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "2710121000",
    "Description": "Partially refined oil including crude oil having undergone primary refinement",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710122100",
    "Description": "White spirit",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710122900",
    "Description": "Other special spirit not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710123000",
    "Description": "Aviation spirit",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2710124000",
    "Description": "Motor spirit, super",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2710125000",
    "Description": "Motor spirit, ordinary",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2710129000",
    "Description": "Other Light oils and preparations Not specified or included",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710191100",
    "Description": "Kerosine type jet fuel",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2710191200",
    "Description": "Kerosine",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2710191900",
    "Description": "Other medium oils not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710192100",
    "Description": "Gas oil",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710192200",
    "Description": "Domestic fuel oil",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710192300",
    "Description": "Light fuel oil",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710192400",
    "Description": "Heavy fuel oil I",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710192500",
    "Description": "Heavy fuel oil II",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710193100",
    "Description": "Meant to be mixed further",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "2710193200",
    "Description": "Brake fluid",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710193300",
    "Description": "Grease",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710193910",
    "Description": "Twocycle motor oil",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710193990",
    "Description": "Other motor oil not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "2710200000",
    "Description": "Petroleum oils and oils obtained from bituminous minerals",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2710910000",
    "Description": "Containing polychlorinated biphenyls (PCBs), polychlorin",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "2710990000",
    "Description": "Petroleum oils and oils obtained from.. other waste oil",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "2711110000",
    "Description": "Natural gas",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2711120000",
    "Description": "Propane",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2711130000",
    "Description": "Butanes",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2711140000",
    "Description": "Ethylene, propylene, butylene and butadiene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2711190000",
    "Description": "Other Liquefied petroleum gases and other gaseous hydrocarbons",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2711210000",
    "Description": "Natural gas",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "2711290000",
    "Description": "Other petroleum gases etc in gaseous state",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2712100000",
    "Description": "Petroleum jelly",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2712200000",
    "Description": "Paraffin wax containing by weight less than 0.75 % of oil",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2712900000",
    "Description": "Petroleum jelly; parfin wax,micro cryst, ne other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2713110000",
    "Description": "Not calcined",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2713120000",
    "Description": "Calcined",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2713200000",
    "Description": "Petroleum bitumen",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2713900000",
    "Description": "Other residues of petroleum oils or of oils obtained from bituminous minerals",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2714100000",
    "Description": "Bituminous or oil shale and tar sands",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2714900000",
    "Description": "Bitumen and asphalt, natural; bituminous Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2715000000",
    "Description": "Bituminous mixtures based on natural asphalt, on natural bi",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2716000000",
    "Description": "Electrical energy. (optional heading)",
    "SU": "TKH",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "2801100000",
    "Description": "Chlorine",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2801200000",
    "Description": "Iodine",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2801300000",
    "Description": "Fluorine; bromine",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2802000000",
    "Description": "Sulphur, sublimed or precipitated; colloidal sulphur.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2803000000",
    "Description": "Carbon (carbon blacks and other forms of carbon not elsewhere specified or included).",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2804100000",
    "Description": "Hydrogen",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "2804210000",
    "Description": "Argon",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "2804290000",
    "Description": "Other Rare gases, Not specified or included",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "2804300000",
    "Description": "Nitrogen",
    "SU": "M3",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2804400000",
    "Description": "Oxygen",
    "SU": "M3",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2804500000",
    "Description": "Boron; tellurium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2804610000",
    "Description": "Containing by weight not less than 99.99 % of silicon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2804690000",
    "Description": "Hydrogen, rare gases and other non metals Other(Silicon)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2804700000",
    "Description": "Phosphorus",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2804800000",
    "Description": "Arsenic",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2804900000",
    "Description": "Selenium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2805110000",
    "Description": "Sodium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2805120000",
    "Description": "Calcium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2805190000",
    "Description": "Alkali or alkalineearth metals; rareearth Other (Alkali or alkalineear",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2805300000",
    "Description": "Rareearth metals, scandium and yttrium, whether or not intermixed or interalloyed",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2805400000",
    "Description": "Mercury",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2806100000",
    "Description": "Hydrogen chloride (hydrochloric acid)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2806200000",
    "Description": "Chlorosulphuric acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2807000000",
    "Description": "Sulphuric acid; oleum.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2808000000",
    "Description": "Nitric acid; sulphonitric acids.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2809100000",
    "Description": "Diphosphorus pentaoxide",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2809200000",
    "Description": "Phosphoric acid and polyphosphoric acids",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2810000000",
    "Description": "Oxides of boron; boric acids.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2811110000",
    "Description": "Hydrogen fluoride (hydrofluoric acid)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2811120000",
    "Description": "Hydrogen cyanide (hydrocyanic acid)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2811190000",
    "Description": "Other inorganic acids",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2811210000",
    "Description": "Carbon dioxide",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2811220000",
    "Description": "Silicon dioxide",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2811290000",
    "Description": "Other inorganic Oxygen compounds of non metals",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2812110000",
    "Description": "Carbonyl dichloride (phosgene)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2812120000",
    "Description": "Phosphorus oxychloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2812130000",
    "Description": "Phosphorus trichloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2812140000",
    "Description": "Phosphorus pentachloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2812150000",
    "Description": "Sulphur monochloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2812160000",
    "Description": "Sulphur dichloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2812170000",
    "Description": "Thionyl chloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2812190000",
    "Description": "Other Chlorides and Chloride oxides of non-metals",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2812900000",
    "Description": "Other Halides and halide oxides of nonmetals (excluding Chlorides and chloride oxides)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2813100000",
    "Description": "Carbon disulphide",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2813900000",
    "Description": "Sulphides of nonmetals; commercial phosphorus trisulphide; Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2814100000",
    "Description": "Anhydrous ammonia",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2814200000",
    "Description": "Ammonia in aqueous solution",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2815110000",
    "Description": "Solid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2815120000",
    "Description": "In aqueous solution (soda lye or liquid soda)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2815200000",
    "Description": "Potassium hydroxide (caustic potash)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2815300000",
    "Description": "Peroxides of sodium or potassium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2816100000",
    "Description": "Hydroxide and peroxide of magnesium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2816400000",
    "Description": "Oxides, hydroxides and peroxides, of strontium or barium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2817000000",
    "Description": "Zinc oxide; zinc peroxide.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2818100000",
    "Description": "Artificial corundum, whether or not chemically defined",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2818200000",
    "Description": "Aluminium oxide, other than artificial corundum",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2818300000",
    "Description": "Aluminium hydroxide",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2819100000",
    "Description": "Chromium trioxide",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2819900000",
    "Description": "Other Chromium oxides and hydroxides",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2820100000",
    "Description": "Manganese dioxide",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2820900000",
    "Description": "Other manganese oxides not specified.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2821100000",
    "Description": "Iron oxides and hydroxides",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2821200000",
    "Description": "Earth colours",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2822000000",
    "Description": "Cobalt oxides and hydroxides; commercial cobalt oxides.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2823000000",
    "Description": "Titanium oxides.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2824100000",
    "Description": "Lead monoxide (litharge, massicot)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2824900000",
    "Description": "Other lead oxides",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2825100000",
    "Description": "Hydrazine and hydroxylamine and their inorganic salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2825200000",
    "Description": "Lithium oxide and hydroxide",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2825300000",
    "Description": "Vanadium oxides and hydroxides",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2825400000",
    "Description": "Nickel oxides and hydroxides",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2825500000",
    "Description": "Copper oxides and hydroxides",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2825600000",
    "Description": "Germanium oxides and zirconium dioxide",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2825700000",
    "Description": "Molybdenum oxides and hydroxides",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2825800000",
    "Description": "Antimony oxides",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2825900000",
    "Description": "  Other inorganic bases; other metal oxides, hydroxides and peroxides not specified.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2826120000",
    "Description": "Fluorides of aluminium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2826190000",
    "Description": "Fluorides; fluorosilicates and other complex fluorine salts.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2826300000",
    "Description": "Sodium hexafluoroaluminate (synthetic cryolite)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2826900000",
    "Description": "Flourides; fluoursilicates, Other(Fluorides).",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2827100000",
    "Description": "Ammonium chloride",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2827200000",
    "Description": "Calcium chloride",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2827310000",
    "Description": "chlorides of magnesium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2827320000",
    "Description": "chlorides of aluminium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2827350000",
    "Description": "chlorides of nickel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2827390000",
    "Description": "Other Cyanides and cyanide oxides (excluding cynides of sodium)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2827410000",
    "Description": "Chloride oxides and chloride hydroxides of copper",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2827490000",
    "Description": "Chloride oxides and chloride hydroxides of other elements excluding Cooper",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2827510000",
    "Description": "Bromides of sodium or of potassium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2827590000",
    "Description": "Chlorides, chloride oxides and chloride ... Other(Bromides",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2827600000",
    "Description": "Iodides and iodide oxides",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2828100000",
    "Description": "Commercial calcium hypochlorite and other calcium hypochlorites",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2828901000",
    "Description": "Sodium hypochlorite (bleach) whether or not concentrated",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2828909000",
    "Description": "Other bleach not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "2829110000",
    "Description": "Chlorates of sodium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2829190000",
    "Description": "Chlorates anderchlorates; bromates and Other(Chlorate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2829900000",
    "Description": "Other chlorates and perchlorates; bromates and ?perbromates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2830100000",
    "Description": "Sodium sulphides",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2830900000",
    "Description": "Other Sulphides; polysulphides, whether or not chemically defined",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2831100000",
    "Description": "Dithionites and sulphoxylates of sodium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2831900000",
    "Description": "Other Dithionites and sulphoxylates (excl. of sodium)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2832100000",
    "Description": "Sodium sulphites",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2832200000",
    "Description": "Other sulphites",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2832300000",
    "Description": "Thiosulphates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2833110000",
    "Description": "Disodium sulphate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2833190000",
    "Description": "Other Sodium Sulphates",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2833210000",
    "Description": "Of magnesium",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2833220000",
    "Description": "Of aluminium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 65,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2833240000",
    "Description": "Of nickel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2833250000",
    "Description": "Of copper",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2833270000",
    "Description": "Of barium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2833290000",
    "Description": "Other sulphates nt mentioned or included.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2833300000",
    "Description": "Alums",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 65,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2833400000",
    "Description": "Peroxosulphates (persulphates)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2834100000",
    "Description": "Nitrites",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2834210000",
    "Description": "Nitrates of potassium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2834290000",
    "Description": "Other Nitrates not specified in 28.35",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2835100000",
    "Description": "Phosphinates (hypophosphites) and phosphonates (phosphites)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2835220000",
    "Description": "Phosphates of mono or disodium",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2835240000",
    "Description": "Phosphates of potassium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2835250000",
    "Description": "Calcium hydrogenorthophosphate (\"dicalcium phosphate\")",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2835260000",
    "Description": "Other phosphates of calcium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2835290000",
    "Description": "Other Phosphates not specified or included",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2835310000",
    "Description": "Sodium triphosphate (sodium tripolyphosphate)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2835390000",
    "Description": "Phosphinates (hypophishites), phosphonates. Other (Polyphosphates)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2836200000",
    "Description": "Disodium carbonate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2836300000",
    "Description": "Sodium hydrogencarbonate (sodium bicarbonate)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2836400000",
    "Description": "Potassium carbonates",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2836500000",
    "Description": "Calcium carbonate",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2836600000",
    "Description": "Barium carbonate",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2836910000",
    "Description": "Lithium carbonates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2836920000",
    "Description": "Strontium carbonate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2836990000",
    "Description": "Other Carbonates not specified or included",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2837110000",
    "Description": "Cyanides and cyanide oxides of sodium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2837190000",
    "Description": "Other Cyanides and cyanide oxides not specified in 28.37",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2837200000",
    "Description": "Complex cyanides",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2839110000",
    "Description": "Sodium metasilicates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2839190000",
    "Description": "Other silicate of sodium not specified in 28.39",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2839900000",
    "Description": "Other silicates other than of sodium not specified in 28.39",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2840110000",
    "Description": "Anhydrous Disodium tetraborate (refined borax)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2840190000",
    "Description": "Disodium tetraborate (non anhudrous)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2840200000",
    "Description": "Other borates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2840300000",
    "Description": "Peroxoborates (perborates)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2841300000",
    "Description": "Sodium dichromate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2841500000",
    "Description": "Other chromates and dichromates; peroxochromates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2841610000",
    "Description": "Potassium permanganate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2841690000",
    "Description": "Salts of oxometallic or peroxometallic acids. Other(Manganites, manganat",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2841700000",
    "Description": "Molybdates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2841800000",
    "Description": "Tungstates (wolframates)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2841900000",
    "Description": "Salts ooxometallic or peroxometallic Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2842100000",
    "Description": "Double or complex silicates, including aluminosilicates",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2842900000",
    "Description": "Other salt of inorganic acids or organic not specified in 28.42",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2843100000",
    "Description": "Colloidal precious metals",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2843210000",
    "Description": "Silver nitrate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2843290000",
    "Description": "Other Silver compounds excluding Silver nitrate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2843300000",
    "Description": "Gold compounds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2843900000",
    "Description": "amalgams",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2844100000",
    "Description": "Natural uranium and compounds, alloys, dispersions, ceramic products containing uranium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2844200000",
    "Description": "Uranium, plutonium; compound; alloys, dispersions.. enriched in U 235,",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2844300000",
    "Description": "Uranium, plutonium; alloys, dispersions.. enriched in U 235, thorium/compounds.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2844500000",
    "Description": "Spent (irradiated) fuel elements (cartridges) of nuclear reactors",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2845100000",
    "Description": "Heavy water (deuterium oxide)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2845900000",
    "Description": "Isotopes other than those of heading 28.44; Other....",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2846100000",
    "Description": "Cerium compounds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2846900000",
    "Description": "Compounds, inganic or organic, of rare earth metals, of yttrium or of...",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2847000000",
    "Description": "Hydrogen peroxide, whether or not solidified with urea.",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2849100000",
    "Description": "Carbides of calcium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2849200000",
    "Description": "Carbides of silicon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2849900000",
    "Description": "Other Carbides not specified in 28.49",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2850000000",
    "Description": "Hydrides, nitrides, azides, silicides and borides, other than compounds of heading 28.49",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2852100000",
    "Description": "Inorganic or organic compounds of mercury Chemically defined",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2852900000",
    "Description": "Inorganic or organic compounds of mercury Not Chemically defined",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2853100000",
    "Description": "Cyanogen chloride (chlorcyan)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2853900000",
    "Description": "Other Phosphides, whether or not chemically defined, excluding ferrophosphorus;",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2901100000",
    "Description": "Acyclic hydrocarbons Saturated",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2901210000",
    "Description": "Ethylene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2901220000",
    "Description": "Propene (propylene)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2901230000",
    "Description": "Butene (butylene) and isomers thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2901240000",
    "Description": "Buta1,3diene and isoprene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2901290000",
    "Description": "Other unsaturated acyclic hydrocarbons, not specified in 29.01",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2902110000",
    "Description": "Cyclohexane",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2902190000",
    "Description": "Cyclic hydrocarbons cycleones and cycloterpenes: Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2902200000",
    "Description": "Benzene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2902300000",
    "Description": "Toluene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2902410000",
    "Description": "oXylene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2902420000",
    "Description": "mXylene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2902430000",
    "Description": "pXylene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2902440000",
    "Description": "Mixed xylene isomers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2902500000",
    "Description": "Styrene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2902600000",
    "Description": "Ethylbenzene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2902700000",
    "Description": "Cumene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2902900000",
    "Description": "Other Cyclic hydrocarbons not specified in 29.02",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903110000",
    "Description": "Chloromethane (methyl chloride) and chloroethane (ethyl chloride)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903120000",
    "Description": "Dichloromethane (methylene chloride)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903130000",
    "Description": "Chloroform (trichloromethane)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903140000",
    "Description": "Carbon tetrachloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903150000",
    "Description": "Ethylene dichloride (ISO) (1,2dichloroethane)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903191000",
    "Description": "1,1,1Trichloroethane (methyl chloroform)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903199000",
    "Description": "Other Halogenated derivatives of hydrocarbons not specified.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903210000",
    "Description": "Vinyl chloride (chloroethylene)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903220000",
    "Description": "Trichloroethylene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903230000",
    "Description": "Tetrachloroethylene (perchloroethylene)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903290000",
    "Description": "Unsaturated chlorinated derivatives of Other [acyclic hydrocarbons]",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903410000",
    "Description": "-- Trifluoromethane (HFC-23)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 0,
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "2903420000",
    "Description": "Dichlorodifluoromethane",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "2903430000",
    "Description": "--  Fluoromethane (HFC-41), 1,2-difluoroethane (HFC-152) and 1,1-difluoroethane (HFC-152",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 0,
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "2903440000",
    "Description": "Pentafluoroethane (HFC-125), 1,1,1-trifluoroethane (HFC-143a) and 1,1,2-trifluoroethane",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "2903450000",
    "Description": "-- 1,1,1,2-Tetrafluoroethane (HFC-134a) and 1,1,2,2-tetrafluoroethane (HFC-134)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 0,
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "2903460000",
    "Description": "1,1,1,2,3,3,3-Heptafluoropropane (HFC-227ea), 1,1,1,2,2,3-hexafluoropropane (HFC-236cb),",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 0,
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "2903470000",
    "Description": "-- 1,1,1,3,3-Pentafluoropropane (HFC-245fa) and 1,1,2,2,3-pentafluoropropane (HFC-245ca)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 0,
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "2903480000",
    "Description": "-- 1,1,1,3,3-Pentafluorobutane (HFC-365mfc) and 1,1,1,2,2,3,4,5,5,5-decafluoropentane (H",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 0,
    "EXC": 0,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2903490000",
    "Description": "Other halogenated derivatives of acyclic hydrocarbons nes containing >= 2 halogens",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "2903510000",
    "Description": "2,3,3,3-Tetrafluoropropene (HFO-1234yf), 1,3,3,3-tetrafluoropropene (HFO-1234ze) and (Z)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "2903590000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 0,
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "2903610000",
    "Description": "-- Methyl bromide (bromomethane)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 0,
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "2903620000",
    "Description": "-- Ethylene dibromide (ISO) (1,2-dibromoethane)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "2903690000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2903710000",
    "Description": "Chlorodifluoromethane",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903720000",
    "Description": "Dichlorotrifluoroethanes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903730000",
    "Description": "Dichlorofluoroethanes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903740000",
    "Description": "Chlorodifluoroethanes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903750000",
    "Description": "Dichloropentafluoropropanes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903760000",
    "Description": "Bromochlorodifluoromethane, bromotrifluoromethane and dibromotetrafluoroethanes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903770000",
    "Description": "Other, perhalogenated only with fluorine and chlorine",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903780000",
    "Description": "Other perhalogenated derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903791000",
    "Description": "Chlorotetrafluoroethanes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903792000",
    "Description": "Other derivates of methane, ethane/propane halogenated only with fluorine and chlorine",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903793000",
    "Description": "Derivatives of methane, ethane/propane halogenated only with fluorine and bromine",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903799000",
    "Description": "Other Halogenated derivatives of acyclic hydrocarbons not specified in29.03",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903810000",
    "Description": "1,2,3,4,5,6Hexachlorocyclohexane (HCH (ISO)), including lindane (ISO, INN)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903820000",
    "Description": "Aldrin (ISO), chlordane (ISO) and heptachlor (ISO)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903830000",
    "Description": "--  Mirex (ISO)",
    "ID": 5,
    "DATE OF VALIDITY": "22/06/2020"
  },
  {
    "CET Code": "2903890000",
    "Description": "Other Halogenated derivatives of cyclanic, cyclenic or cyclotopenic hydrocarbons",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903910000",
    "Description": "Chlorobenzene, odichlorobenzene and pdichlorobenzene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903920000",
    "Description": "Hexachlorobenzene (ISO) and DDT (ISO) (clofenotane, 1,1,1trichloro2,2bis",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903930000",
    "Description": "Pentachlorobenzene (ISO)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903940000",
    "Description": "Hexabromobiphenyls\tkg",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2903990000",
    "Description": "Other Halogenated derivatives of aromatic hydrocarbons",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2904100000",
    "Description": "Derivatives containing only sulpho groups, their salts and ethyl esters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2904200000",
    "Description": "Derivatives containing only nitro or only nitroso groups",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2904310000",
    "Description": "Perfluorooctane sulphonic acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2904320000",
    "Description": "Ammonium perfluorooctane sulphonate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2904330000",
    "Description": "Lithium perfluorooctane sulphonate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2904340000",
    "Description": "Potassium perfluorooctane sulphonate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2904350000",
    "Description": "Other salts of perfluorooctane sulphonic acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2904360000",
    "Description": "Perfluorooctane sulphonyl fluoride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2904910000",
    "Description": "Trichloronitromethane (chloropicrin)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2904990000",
    "Description": "Other Perfluorooctane sulphuric acid, its salts and perfluorooctane sulphonyl fluoride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905110000",
    "Description": "Methanol (methyl alcohol)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905120000",
    "Description": "Propan1ol (propyl alcohol) and propan2ol (isopropyl alcohol)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905130000",
    "Description": "Butan1ol (nbutyl alcohol)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905140000",
    "Description": "Other butanols",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905160000",
    "Description": "Octanol (octyl alcohol) and isomers thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905170000",
    "Description": "Dodecan1ol (lauryl ), hexadecan1ol (cetyl ) and octadecan1ol (stearyl)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905190000",
    "Description": "Other Saturated monohydric alcohols Not specified or included",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905220000",
    "Description": "Acyclic terpene alcohols",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905290000",
    "Description": "Acyclic alcohols and their halogenated, Other (Unsaturated monohydric alc]",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905310000",
    "Description": "Ethylene glycol (ethanediol)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905320000",
    "Description": "Propylene glycol (propane1,2diol)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905390000",
    "Description": "Acyclic alcohols and their halogenated, Other (Diols)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905410000",
    "Description": "2Ethyl2(hydroxymethyl)propane1,3diol (trimethylolpropane)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905420000",
    "Description": "Pentaerythritol",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905430000",
    "Description": "Mannitol",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905440000",
    "Description": "Dglucitol (sorbitol)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905450000",
    "Description": "Glycerol",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905490000",
    "Description": "Acyclic alcohols and their halogenated, Other(Other polyhydric alcohols)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905510000",
    "Description": "Ethchlorvynol",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2905590000",
    "Description": "Acyclic alcohols and their halogenated, Other(Halogenated, sulphonated, n",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2906110000",
    "Description": "Menthol",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2906120000",
    "Description": "Cyclohexanol, methylcyclohexanols and dimethylcyclohexanols",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2906130000",
    "Description": "Sterols and inositols",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2906190000",
    "Description": "Other cyclanic, cyclenic or cycloterpenic alcohols and derivatives, nes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2906210000",
    "Description": "Benzyl alcohol",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2906290000",
    "Description": "Other Aromatic alcohol excluding Benzyl alcohol",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2907110000",
    "Description": "Phenol (hydroxybenzene) and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2907120000",
    "Description": "Cresols and their salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2907130000",
    "Description": "Octylphenol, nonylphenol and their isomers; salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2907150000",
    "Description": "Naphthols and their salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2907190000",
    "Description": "Phenols; phenolalcohols. Other(Monophenols)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2907210000",
    "Description": "Resorcinol and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2907220000",
    "Description": "Hydroquinone (quinol) and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2907230000",
    "Description": "4,4'Isopropylidenediphenol (bisphenol A, diphenylolpropane) and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2907290000",
    "Description": "Phenols; phenolalcohols. Other(Polyphenols; phenol alcohols)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2908110000",
    "Description": "Pentachlorophenol (ISO)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2908190000",
    "Description": "Other, Derivatives containing only halogen substituents and their salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2908910000",
    "Description": "Dinoseb (ISO) and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2908920000",
    "Description": "4,6Dinitroocresol (DNOC (ISO)) and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2908990000",
    "Description": "Other, Derivatives containing only halogen substituents and their salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2909110000",
    "Description": "Diethyl ether",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2909190000",
    "Description": "Acyclic ethers and their halogenated, sulphonated, not specified in 29.09",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2909200000",
    "Description": "Cyclanic, cyclenic... ethers and their halogenated, sulphonated... derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2909300000",
    "Description": "Aromatic ethers and their halogenated, sulphonated, nitrated or nitrosated derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2909410000",
    "Description": "2,2'Oxydiethanol (diethylene glycol, digol)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2909430000",
    "Description": "Monobutyl ethers of ethylene glycol or of diethylene glycol",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2909440000",
    "Description": "Other monoalkylethers of ethylene glycol or of diethylene glycol",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2909490000",
    "Description": "Etheralcohols & halogenated, sulphonated/nitrated/nitrosated deriv: not specif. in 29.09",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2909500000",
    "Description": "Etherphenols, etheralcoholphenols and halogenated, sulphonated, nitrated.. derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2909600000",
    "Description": "Alcohol/ether peroxides, ketone peroxides and halogenated, sulphonated... derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2910100000",
    "Description": "Oxirane (ethylene oxide)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2910200000",
    "Description": "Methyloxirane (propylene oxide)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2910300000",
    "Description": "1Chloro2,3epoxypropane (epichlorohydrin)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2910400000",
    "Description": "Dieldrin (ISO, INN)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2910500000",
    "Description": "Endrin (ISO)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2910900000",
    "Description": "Other Epoxides, epoxyalcohols, epoxyphenols",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2911000000",
    "Description": "Alcohol/ether/ketone peroxides and their halogenated, sulphonated... derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2912110000",
    "Description": "Methanal (formaldehyde)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2912120000",
    "Description": "Ethanal (acetaldehyde)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2912190000",
    "Description": "Other acyclic aldehydes, without oxygen function, nes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2912210000",
    "Description": "Benzaldehyde",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2912290000",
    "Description": "Other Cyclic aldehydes without other oxygen function Not specified or included",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2912410000",
    "Description": "Vanillin (4hydroxy3methoxybenzaldehyde)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2912420000",
    "Description": "Ethylvanillin (3ethoxy4hydroxybenzaldehyde)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2912490000",
    "Description": "Aldehydes, whether or not with other oxy Other",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2912500000",
    "Description": "Cyclic polymers of aldehydes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2912600000",
    "Description": "Paraformaldehyde",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2913000000",
    "Description": "Halogenated, sulphonated/nitrated or nitrosated derivatives of product of heading 29.12.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914110000",
    "Description": "Acetone",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914120000",
    "Description": "Butanone (methyl ethyl ketone)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914130000",
    "Description": "4Methylpentan2one (methyl isobutyl ketone)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914190000",
    "Description": "Other Acyclic ketones without other oxygen function not specified in 29.14",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914220000",
    "Description": "Cyclohexanone and methylcyclohexanones",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914230000",
    "Description": "Ionones and methylionones",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914291000",
    "Description": "Camphor",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914299000",
    "Description": "Other Cyclanic, cyclenic or cycloterpenic ketones not specified in 29.14",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914310000",
    "Description": "Phenylacetone (phenylpropan2one)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914390000",
    "Description": "Ketone and quinons, whether or not wit.. Other (aromatic ketones without other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914400000",
    "Description": "Ketonealcohols and ketonealdehydes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914500000",
    "Description": "Ketonephenols and ketones with other oxygen function",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914610000",
    "Description": "Anthraquinone",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914620000",
    "Description": "Coenzyme Q10 (ubidecarenone (INN))",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914690000",
    "Description": "Other Quinones",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914710000",
    "Description": "Chlordecone (ISO)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2914790000",
    "Description": "Other Halogenated, sulphonated, nitrated or nitrosated derivatives",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915110000",
    "Description": "Formic acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915120000",
    "Description": "Salts of formic acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915130000",
    "Description": "Esters of formic acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915210000",
    "Description": "Acetic acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915240000",
    "Description": "Acetic anhydride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915290000",
    "Description": "Saturated acyclic monocarboxylic acids a Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915310000",
    "Description": "Ethyl acetate",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915320000",
    "Description": "Vinyl acetate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915330000",
    "Description": "nButyl acetate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915360000",
    "Description": "Dinoseb (ISO) acetate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915390000",
    "Description": "Other, esters of acetic acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915400000",
    "Description": "Mono, di or trichloroacetic acids, their salts and esters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915500000",
    "Description": "Propionic acid, its salts and esters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915600000",
    "Description": "Butanoic acids, pentanoic acids, their salts and esters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915700000",
    "Description": "Palmitic acid, stearic acid, their salts and esters",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2915900000",
    "Description": "Saturated acyclic monocarboxylic acids .. Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2916110000",
    "Description": "Acrylic acid and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2916120000",
    "Description": "Esters of acrylic acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2916130000",
    "Description": "Methacrylic acid and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2916140000",
    "Description": "Esters of methacrylic acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2916150000",
    "Description": "Oleic, linoleic or linolenic acids, their salts and esters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2916160000",
    "Description": "Binapacryl (ISO)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2916190000",
    "Description": "Unsaturated acyclic monocarboxylic acid.. Other(Unsaturated acyclic monocarbo..)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2916200000",
    "Description": "Cyclanic, cyclenic... monocarboxylic acids, their anhydrides, halides... derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2916310000",
    "Description": "Benzoic acid, its salts and esters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2916320000",
    "Description": "Benzoyl peroxide and benzoyl chloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2916340000",
    "Description": "Phenylacetic acid and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2916390000",
    "Description": "Other Aromatic monocarboxylic acids, their anhydrides, halides... and their derivatives",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2917110000",
    "Description": "Oxalic acid, its salts and esters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2917120000",
    "Description": "Adipic acid, its salts and esters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2917130000",
    "Description": "Azelaic acid, sebacic acid, their salts and esters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2917140000",
    "Description": "Maleic anhydride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2917190000",
    "Description": "Polycoxylic acids, their anhydrides, .. Other(Acyclic polycarboxylic acids..)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2917200000",
    "Description": "Cyclanic, cyclenic... polycarboxylic acids, their anhydrides... and their derivatives",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2917320000",
    "Description": "Dioctyl orthophthalates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2917330000",
    "Description": "Dinonyl or didecyl orthophthalates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2917340000",
    "Description": "Other esters of orthophthalic acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2917350000",
    "Description": "Phthalic anhydride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2917360000",
    "Description": "Terephthalic acid and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2917370000",
    "Description": "Dimethyl terephthalate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2917390000",
    "Description": "Pycaboxylic acids, their anhydrides,.. Other(Aromatic polycarboxylic acids..)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918110000",
    "Description": "Lactic acid, its salts and esters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918120000",
    "Description": "Tartaric acid",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918130000",
    "Description": "Salts and esters of tartaric acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918140000",
    "Description": "Citric acid",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918150000",
    "Description": "Salts and esters of citric acid",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918160000",
    "Description": "Gluconic acid, its salts and esters",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918170000",
    "Description": "2,2-Diphenyl-2-hydroxyacetic acid (benzilic acid)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918180000",
    "Description": "Chlorobenzilate (ISO)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918190000",
    "Description": "Carboxylic acids with additional oxygen Other",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918210000",
    "Description": "Salicylic acid and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918220000",
    "Description": "OAcetylsalicylic acid, its salts and esters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918230000",
    "Description": "Other esters of salicylic acid and their salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918290000",
    "Description": "Carboxyc acids with additional oxygen.. Other(Caboxylic acids wit phenol ..)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918300000",
    "Description": "Carboxylic acids with aldehyde/ketone without oxygen, anhydride... and their derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918910000",
    "Description": "2,4,5T (ISO) (2,4,5trichlorophenoxyacetic acid), its salts and esters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2918990000",
    "Description": "Other Carboxylic acids with aldehyde or ketone function not specified in 29.18",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2919100000",
    "Description": "Tris(2,3dibromopropyl) phosphate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2919900000",
    "Description": "Other,Phosphoric esters and their salts, including lactophosphates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2920110000",
    "Description": "Parathion (ISO) and parathionmethyl (ISO) (methylparathion)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2920190000",
    "Description": "Other, Thiophosphoric esters and their salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2920210000",
    "Description": "Dimethyl phosphite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2920220000",
    "Description": "Diethyl Phosphite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2920230000",
    "Description": "Trimethyl phosphite\tkg",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2920240000",
    "Description": "Triethyl phosphite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2920290000",
    "Description": "Other Phosphite esters and their salts; their halogenated, sulphonated ...",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2920300000",
    "Description": "Endosulfan (ISO)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2920900000",
    "Description": "Other Esters of other inorganic acids (excl. of hydrogen halides) etc, nes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921110000",
    "Description": "Methylamine, di or trimethylamine and their salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921120000",
    "Description": "2-(N,N-Dimethylamino)ethylchloride hydrochloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921130000",
    "Description": "2-(N,N-Diethylamino)ethylchloride hydrochloride",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921140000",
    "Description": "2-(N,N-Diisopropylamino)ethylchloride hydrochloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921190000",
    "Description": "Other Acyclic monoamines and their derivatives, nes salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921210000",
    "Description": "Ethylenediamine and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921220000",
    "Description": "Hexamethylenediamine and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921290000",
    "Description": "Aminefunction compounds. Other(Acyclic polyamines and their ...)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921300000",
    "Description": "Cyclanic, cyclenic... mono or polyamines, and their derivatives; salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921410000",
    "Description": "Aniline and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921420000",
    "Description": "Aniline derivatives and their salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921430000",
    "Description": "Toluidines and their derivatives; salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921440000",
    "Description": "Diphenylamine and its derivatives; salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921450000",
    "Description": "1Naphthylamine, 2naphthylamine and their derivatives; salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921460000",
    "Description": "Amfetamine, benzfetamine, dexamfetamine (INN",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921490000",
    "Description": "Aminefunction compounds. Other(Aromatic monoamines and their..)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921510000",
    "Description": "o, m, pPhenylenediamine, diaminotoluenes, and their derivatives; salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2921590000",
    "Description": "Aminefunction compounds. Other(Aromatic polyamines and their..)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922110000",
    "Description": "Monoethanolamine and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922120000",
    "Description": "Diethanolamine and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922140000",
    "Description": "Dextropropoxyphene and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922150000",
    "Description": "--  Triethanolamine",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "22/06/2020"
  },
  {
    "CET Code": "2922160000",
    "Description": "Diethanolammonium perfluorooctane sulphonate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922170000",
    "Description": "Methyldiethanolamine and ethyldiethanolamine",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922180000",
    "Description": "2-(N,N-Diisopropylamino)ethanol",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922190000",
    "Description": "Other Aminoalcohols, other than those containing more than one kind of Oxygenfunction...",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922210000",
    "Description": "Aminohydroxynaphthalenesulphonic acids and their salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922290000",
    "Description": "Other Aminonapthols and Aphenols, etc 1 oxygen function, salts, nes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922310000",
    "Description": "Amfepramone, methadone and normethadone ; salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922390000",
    "Description": "Other Aminoaldehydes, aminoketones and aminoquinones, not specified in 29.22",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922410000",
    "Description": "Lysine and its esters; salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922421000",
    "Description": "Chemically refined monosodium glutamate powder or granules for retail sale ( eg A1)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922429000",
    "Description": "Other Glutamic acid and its salts Not specified or included",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922430000",
    "Description": "Anthranilic acid and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922440000",
    "Description": "Tilidine and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922490000",
    "Description": "Oxygenfunction aminocompounds. Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2922500000",
    "Description": "Aminoalcoholphenols, aminoacidphenols and other aminocompounds with oxygen function",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2923100000",
    "Description": "Choline and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2923200000",
    "Description": "Lecithins and other phosphoaminolipids",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2923300000",
    "Description": "- Tetraethylammonium perfluorooctane sulphonate",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "22/06/2020"
  },
  {
    "CET Code": "2923400000",
    "Description": "Didecyldimethylammonium perfluorooctane sulphonate",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2923900000",
    "Description": "Other Quaternary ammonium salts and hydroxid....",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2924110000",
    "Description": "Meprobamate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2924120000",
    "Description": "Fluoroacetamide (ISO), monocrotophos (ISO) and phosphamidon (ISO)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2924190000",
    "Description": "Carboxyamidefunction compounds; amidefun Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2924210000",
    "Description": "Ureines and their derivatives; salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2924230000",
    "Description": "2Acetamidobenzoic acid (Nacetylanthranilic acid) and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2924240000",
    "Description": "Ethinamate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2924250000",
    "Description": "Alachlor (ISO)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2924290000",
    "Description": "Other Cyclic amides incl cyclic carbamates.... and their derivatives; salt thereof",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2925110000",
    "Description": "Saccharin and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2925120000",
    "Description": "Glutethimide",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2925190000",
    "Description": "Carboxyimidefunction compounds (includi.. Other(Imides and their derivates..)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2925210000",
    "Description": "Chlordimeform (ISO)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2925290000",
    "Description": "Other Imines and their derivatives; salts thereof; Not specified or included",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2926100000",
    "Description": "Acrylonitrile",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2926200000",
    "Description": "1Cyanoguanidine (dicyandiamide)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2926300000",
    "Description": "Fenproporex and its salts; methadone intermediate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2926400000",
    "Description": "alpha-Phenylacetoacetonitrile",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2926900000",
    "Description": "Other Nitrile function compounds.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2927000000",
    "Description": "Diazo, azo or azoxycompounds.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2928000000",
    "Description": "Organic derivatives of hydrazine or of hydroxylamine.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2929100000",
    "Description": "Isocyanates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2929900000",
    "Description": "Compounds with other nitrogen function. Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2930100000",
    "Description": "- 2-(N,N-Dimethylamino) ethanethiol",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2930200000",
    "Description": "Thiocarbamates and dithiocarbamates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2930300000",
    "Description": "Thiuram mono, di or tetrasulphides",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2930400000",
    "Description": "Methionine",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2930600000",
    "Description": "2-(N,N-Diethylamino)ethanethiol",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2930700000",
    "Description": "Bis(2-hydroxyethyl)sulphide (thiodiglycol (INN))",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2930800000",
    "Description": "Aldicarb (ISO), captafol (ISO) and methamidophos (ISO)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2930900000",
    "Description": "Other Organo-sulphur compounds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2931100000",
    "Description": "Tetramethyl lead and tetraethyl lead",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2931200000",
    "Description": "Tributyltin compounds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2931390000",
    "Description": "Other Organo-phosphorus derivatives not else where specified",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2931410000",
    "Description": "--  Dimethyl methylphosphonate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2931420000",
    "Description": "--  Dimethyl propylphosphonate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2931430000",
    "Description": "--  Diethyl ethylphosphonate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2931440000",
    "Description": "--  Methylphosphonic acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2931450000",
    "Description": "-- Salt of methylphosphonic acid and (aminoiminomethyl)urea (1 : 1)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2931460000",
    "Description": "-- 2,4,6-Tripropyl-1,3,5,2,4,6-trioxatriphosphinane 2,4,6-trioxide",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2931470000",
    "Description": "--  (5-Ethyl-2-methyl-2-oxido-1,3,2-dioxaphosphinan-5-yl) methyl methyl methylphosphonat",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2931480000",
    "Description": "--  3,9-Dimethyl-2,4,8,10-tetraoxa-3,9-diphosphaspiro[5.5] undecane 3,9-dioxide",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2931490000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "2931510000",
    "Description": "-- Methylphosphonic dichloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "2931520000",
    "Description": "-- Propylphosphonic dichloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "2931530000",
    "Description": "-- O-(3-chloropropyl) O-[4-nitro-3-(trifluoromethyl)phenyl] methylphosphonothionate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "2931540000",
    "Description": "-- Trichlorfon (ISO)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "2931590000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "2931900000",
    "Description": "Other organoinorganic compounds; Not else where specified or included",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2932110000",
    "Description": "Tetrahydrofuran",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2932120000",
    "Description": "2Furaldehyde (furfuraldehyde)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2932130000",
    "Description": "Furfuryl alcohol and tetrahydrofurfuryl alcohol",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2932140000",
    "Description": "Sucralose",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2932190000",
    "Description": "Other Heterocyclic compounds containing an unfused furan ring......",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2932200000",
    "Description": "Lactones",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2932910000",
    "Description": "Isosafrole",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2932920000",
    "Description": "1(1,3Benzodioxol5yl)propan2one",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2932930000",
    "Description": "Piperonal",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2932940000",
    "Description": "Safrole",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2932950000",
    "Description": "Tetrahydrocannabinols (all isomers)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2932960000",
    "Description": "-- Carbofuran (ISO)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "2932990000",
    "Description": "Other Heterocyclic compounds with oxygen hetero-atoms......",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933110000",
    "Description": "Phenazone (antipyrin) and its derivatives",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933190000",
    "Description": "Heterocyclic compounds with nitrogen he.. Other(Compounds containing an unfused..)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933210000",
    "Description": "Hydantoin and its derivatives",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933290000",
    "Description": "Heterocyclic compounds with nitrogen .. Other(Compounds containing an unfused..)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933310000",
    "Description": "Pyridine and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933320000",
    "Description": "Piperidine and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933330000",
    "Description": "Alfentanil, anileridine, bezitramide, bromazepam, difenoxin, diphenoxylate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933340000",
    "Description": "-- Other fentanyls and their derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "2933350000",
    "Description": "-- 3-Quinuclidinol",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "2933360000",
    "Description": "-- 4-Anilino-N-phenethylpiperidine (ANPP)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "2933370000",
    "Description": "-- N-Phenethyl-4-piperidone (NPP)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "2933390000",
    "Description": "Heterocyclic compounds with nitrogen .. Other(Compound containing in the str..)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933410000",
    "Description": "Levorphanol and its salts",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933490000",
    "Description": "Heterocyclic compounds with nitrogen het Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933520000",
    "Description": "Malonylurea (barbituric acid) and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933530000",
    "Description": "Allobarbital, amobarbital, barbital, butalbital, butobarbital, cyclobarbital.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933540000",
    "Description": "Other derivatives of malonylurea (barbituric acid); salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933550000",
    "Description": "Loprazolam, mecloqualone, methaqualone and zipeprol ; salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933590000",
    "Description": "Heterocyclic compounds with nitrogen he.. Other(Compounds containing a pyrimidine)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933610000",
    "Description": "Melamine",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933690000",
    "Description": "Heterocyclic compounds with nitrogen he.. Other(Compounds containing an unfused..)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933710000",
    "Description": "6Hexanelactam (epsiloncaprolactam)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933720000",
    "Description": "Clobazam and methyprylon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933790000",
    "Description": "Other lactams",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933910000",
    "Description": "Alprazolam, camazepam, chlordiazepoxide, clonazepam, clorazepate, delorazepam, diazepam",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933920000",
    "Description": "Azinphos-methyl (ISO)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2933990000",
    "Description": "Heterocyclic compounds with nitrogen he.. Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2934100000",
    "Description": "Compounds containing an unfused thiazole ring (whether or",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2934200000",
    "Description": "Compounds containing in the structure a benzothiazole ringsystem , not further fused",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2934300000",
    "Description": "Compounds containing in the structure a phenothiazine ringsystem, not further fused",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2934910000",
    "Description": "Aminorex, brotizolam, clotiazepam, cloxazolam, dextromoramide salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2934920000",
    "Description": "-- Other fentanyls and their derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "2934990000",
    "Description": "Nucleic acids and their salts, whether .. Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2935100000",
    "Description": "N-Methylperfluorooctane sulphonamide",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2935200000",
    "Description": "N-Ethylperfluorooctane sulphonamide",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2935300000",
    "Description": "N-Ethyl-N-(2hydroxyethyl) perfluorooctane sulphonamide",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2935400000",
    "Description": "N-(2-Hydroxyethyl)- N-methylperfluorooctane sulphonamide",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2935500000",
    "Description": "Other perfluorooctane sulphonamides",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2935900000",
    "Description": "Other Sulphonamides",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2936210000",
    "Description": "Vitamins A and their derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2936220000",
    "Description": "Vitamin B1 and its derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2936230000",
    "Description": "Vitamin B2 and its derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2936240000",
    "Description": "D or DLPantothenic acid (Vitamin B3 or Vitamin B5) and its derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2936250000",
    "Description": "Vitamin B6 and its derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2936260000",
    "Description": "Vitamin B12 and its derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2936270000",
    "Description": "Vitamin C and its derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2936280000",
    "Description": "Vitamin E and its derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2936290000",
    "Description": "Other vitamins and their derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2936900000",
    "Description": "Other, including natural concentrates",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2937110000",
    "Description": "Somatotropin, its derivatives and structural analogues",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2937120000",
    "Description": "Insulin and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2937190000",
    "Description": "Hormones, prostaglandins, thromboxanes... Other(Polypeptide hormones, protein..)",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2937210000",
    "Description": "Aminorex, brotizolam, clotiazepam, cloxazolam, dextromoramide... salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2937220000",
    "Description": "Halogenated derivatives of corticosteroidal hormones",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2937230000",
    "Description": "Oestrogens and progestogens",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "2937290000",
    "Description": "Hormones, prostaglandins, thromboxanes... Other(Steroidal hormones, their deriva..)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2937500000",
    "Description": "Prostaglandins, thromboxanes and leukotrienes, derivatives and structural analogues",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2937900000",
    "Description": "Hormones, prostagndins, thromboxanes .. Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2938100000",
    "Description": "Rutoside (rutin) and its derivatives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2938900000",
    "Description": "Glycodsides, natural or reproduced by .. Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939110000",
    "Description": "Concentrates of poppy straw; buprenorphine, codeine... and thebaine; salts thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939190000",
    "Description": "Vegetable alkaloids, natural or reprodu.. Other(Alkaloides of opium and their..)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939200000",
    "Description": "Alkaloids of cinchona and their derivatives; salts thereof",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939300000",
    "Description": "Caffeine and its salts",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939410000",
    "Description": "Ephedrine and its salts",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939420000",
    "Description": "Pseudoephedrine and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939430000",
    "Description": "Cathine and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939440000",
    "Description": "Norephedrine and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939450000",
    "Description": "-- Levometamfetamine, metamfetamine (INN), metamfetamine racemate and their salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "2939490000",
    "Description": "Vegetable alkaloids, natural or reprodu.. Other(Ephedrines and their salts)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939510000",
    "Description": "Fenetylline and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939590000",
    "Description": "Vegetable alkaloids, natural or reprodu.. Other(Theophylline and aminophyline)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939610000",
    "Description": "Ergometrine and its salts",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939620000",
    "Description": "Ergotamine and its salts",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939630000",
    "Description": "Lysergic acid and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939690000",
    "Description": "Vegetable alkaloids, natural or reprodu.. Other(Alkaloids of rye ergot and their..",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939720000",
    "Description": "-- Cocaine, ecgonine; salts, esters and other derivatives thereof",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "2939790000",
    "Description": "- Other, of vegetal origin",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2939800000",
    "Description": "Other Alkaloids, natural or reproduced by synthesis, and their salts, ethers, esters....",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2940000000",
    "Description": "Sugars,pure, other than sucrose... other than products of heading 29.37, 29.38 or 29.39.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2941100000",
    "Description": "Penicillins and their derivatives with a penicillanic acid structure; salts thereof",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2941200000",
    "Description": "Streptomycins and their derivatives; salts thereof",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2941300000",
    "Description": "Tetracyclines and their derivatives; salts thereof",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2941400000",
    "Description": "Chloramphenicol and its derivatives; salts thereof",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2941500000",
    "Description": "Erythromycin and its derivatives; salts thereof",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2941900000",
    "Description": "Antibiotics Other",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "2942000000",
    "Description": "Other organic compounds.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3001200000",
    "Description": "Extracts of glands or other organs or of their secretions",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3001900000",
    "Description": "Glands and other organs for organotherap Other",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3002120000",
    "Description": "Antisera and other blood fractions",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3002130000",
    "Description": "Immunological products, unmixed, not put up in measured doses or in forms",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3002140000",
    "Description": "Immunological products, mixed, not put up in measured doses or in forms or packings",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3002150000",
    "Description": "Immunological products, put up in measured doses or in forms or packings for retail sale",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3002410000",
    "Description": "-- Vaccines for human medicine",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3002420000",
    "Description": "-- Vaccines for veterinary medicine",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3002490000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3002510000",
    "Description": "-- Cell therapy products",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3002590000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3002901000",
    "Description": "Ferments",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3002909000",
    "Description": "Other similar products not else where specified.",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3003100000",
    "Description": "Containing penicillins or derivatives thereof, with a penicillanic or their derivatives",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3003200000",
    "Description": "Containing other antibiotics",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3003310000",
    "Description": "Containing insulin",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3003390000",
    "Description": "Medicaments (excluding goods of heading Other",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3003410000",
    "Description": "Containing ephedrine or its salts",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3003420000",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3003430000",
    "Description": "Containing norephedrine or its salts",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3003490000",
    "Description": "Other, containing alkaloids or derivatives thereof",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3003600000",
    "Description": "Other, containing antimalarial active principles described in SH Note 2 to this chpt",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3003900000",
    "Description": "Other medicaments (excl. goods of heading 30.02, 30.05 or 30.06) ....",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3004100000",
    "Description": "Containing penicillins or derivatives thereof, with a pen",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3004200000",
    "Description": "Containing other antibiotics",
    "SU": "KG",
    "ID": 0,
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3004310000",
    "Description": "Containing insulin",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3004320000",
    "Description": "Containing corticosteroid hormones, their derivatives or structural analogues",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3004390000",
    "Description": "Other Containing hormones or other products of Heading 29.37",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3004410000",
    "Description": "Containing ephedrine or its salts",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3004420000",
    "Description": "Containing pseudoephedrine (INN)\nor its salts",
    "SU": "KG",
    "VAT": "0",
    "LVY": 20,
    "DATE OF VALIDITY": "04/06/2020"
  },
  {
    "CET Code": "3004430000",
    "Description": "Containing norephedrine or its salts",
    "SU": "KG",
    "ID": 0,
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3004490000",
    "Description": "Other containing Alkaloids or derivatives not else where specified in this sub-heading",
    "SU": "KG",
    "ID": 0,
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3004500000",
    "Description": "Other medicaments containing vitamins or other products of heading 29.36",
    "SU": "KG",
    "ID": 0,
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3004600000",
    "Description": "Other, containing antimalarial active principles described in SH N2 to this chapter",
    "SU": "KG",
    "ID": 0,
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3004902000",
    "Description": "Oral Rehydration Salt (ORS)",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3004909000",
    "Description": "Other medicaments not else where specified",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3005100000",
    "Description": "Adhesive dressings and other articles having an adhesive layer",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3005900000",
    "Description": "Wadding, gauze, bandages and similar art Other",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3006100000",
    "Description": "Sterile surgical catgut, similar sterile suture materials",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3006300000",
    "Description": "Opacifying preparations for Xray examinations; diagnosti",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3006400000",
    "Description": "Dental cements and other dental fillings; bone reconstruction cements",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3006500000",
    "Description": "Firstaid boxes and kits",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3006600000",
    "Description": "Chemical contraceptive preparations based on hormones, on",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3006700000",
    "Description": "Gel preparations designed to be used in human or veterina",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3006910000",
    "Description": "Appliances identifiable for ostomy use",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "3006920000",
    "Description": "Waste pharmaceuticals",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3006930000",
    "Description": "-- Placebos and blinded (or double-blinded) clinical trial kits for a recognised clinica",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3101000000",
    "Description": "Animal or vegetable fertilisers, whether or not mixed toget",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3102100000",
    "Description": "Urea, whether or not in aqueous solution",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3102210000",
    "Description": "Ammonium sulphate",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3102290000",
    "Description": "Other double salts and mixtures of ammonium sulphate and ammonium nitrate",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3102300000",
    "Description": "Ammonium nitrate, whether or not in aqueous solution",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3102400000",
    "Description": "Mixtures of ammonium nitrate with calcium carbonate or ot",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3102500000",
    "Description": "Sodium nitrate",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3102600000",
    "Description": "Double salts and mixtures of calcium nitrate and ammonium nitrate",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3102800000",
    "Description": "Mixtures of urea and ammonium nitrate in aqueous or ammoniacal solution",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3102900000",
    "Description": "Other, including mixtures not specified in the foregoing subheadings",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3103110000",
    "Description": "Superphosphates Containing by weight 35% or more of diphosphorus pentaoxide (P2O5)",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3103190000",
    "Description": "Other Superphosphates",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3103900000",
    "Description": "Other Mineral or chemical fertilizer phosphatic.",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3104200000",
    "Description": "Potassium chloride",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3104300000",
    "Description": "Potassium sulphate",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3104900000",
    "Description": "Other Mineral or chemical fertilisers, potassic; Not specified or included",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3105100000",
    "Description": "Goods of this Chapter in tablets or similar forms or in p",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3105200000",
    "Description": "Mineral or chemical fertilisers containing the three fert",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3105300000",
    "Description": "Diammonium hydrogenorthophosphate (diammonium phosphate)",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3105400000",
    "Description": "Ammonium dihydrogenorthophosphate (monoammonium phosphate",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3105510000",
    "Description": "Containing nitrates and phosphates",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3105590000",
    "Description": "Mineral or chemical fertilizers containi Other",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3105600000",
    "Description": "Mineral or chemical fertilisers containing the two fertil",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3105900000",
    "Description": "Mineral or chemical fertiliser containing Other",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3201100000",
    "Description": "Quebracho extract",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3201200000",
    "Description": "Wattle extract",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3201900000",
    "Description": "Tanning extracts of vegetablorigin; ta Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3202100000",
    "Description": "Synthetic organic tanning substances",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3202900000",
    "Description": "Synthetic ganic tanning substances; in other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3203001000",
    "Description": "Natural indigo",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3203009000",
    "Description": "Other Colouring matter of vegetable or animal origin not specified.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3204110000",
    "Description": "Disperse dyes and preparations based thereon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3204120000",
    "Description": "Acid dyes, whether or not premetallised, and preparation",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3204130000",
    "Description": "Basic dyes and preparations based thereon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3204140000",
    "Description": "Direct dyes and preparations based thereon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3204150000",
    "Description": "Vat dyes (including those usable in that state as pigmen",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3204160000",
    "Description": "Reactive dyes and preparations based thereon",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3204170000",
    "Description": "Pigments and preparations based thereon",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3204180000",
    "Description": "-- Carotenoid colouring matters and preparations based thereon",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3204190000",
    "Description": "Other, including mixtures of colouring matter of two or ",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3204200000",
    "Description": "Synthetic organic products of a kind used as fluorescent brightening agents",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3204900000",
    "Description": "Other Synthetic organic colouring matter, whether or not ",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3205000000",
    "Description": "Colour lakes; preparations as specified in Note 3 to this Chapter based on colour lakes.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3206110000",
    "Description": "Containing 80 % or more by weight of titanium dioxide calculated on the dry matter",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3206190000",
    "Description": "Other pigments and preparations based on titanium dioxide",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3206200000",
    "Description": "Pigments and preparations based on chromium compounds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3206410000",
    "Description": "Ultramarine and preparations based thereon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3206420000",
    "Description": "Lithopone and other pigments and preparations based on zinc sulphide",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3206491000",
    "Description": "Pigments and preparations based on cadminum compounds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3206492000",
    "Description": "Pigments and preparations based on hexacynoferrates (ferrocynides and ferricynides)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3206499000",
    "Description": "Other pigments covered in the heading not specified or included",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3206500000",
    "Description": "Inorganic products of a kind used as luminophores",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3207100000",
    "Description": "Prepared pigments, prepared opacifiers, prepared colours and similar preparations",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3207200000",
    "Description": "Vitrifiable enamels and glazes, engobes (slips) and similar preparations",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3207300000",
    "Description": "Liquid lustres and similar preparations",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3207400000",
    "Description": "Glass frit and other glass, in the form of powder, granules or flakes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3208100000",
    "Description": "Based on polyesters",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3208201000",
    "Description": "Varnishes (including lacquers)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3208202000",
    "Description": "Paints (including enamels)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3208209000",
    "Description": "Solutions as defined in Note 4 to this Chapter",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3208901000",
    "Description": "Varnishes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3208902100",
    "Description": "Dry powder paint",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3208902900",
    "Description": "Other Paints and varnishes covered in the heading not specified or included",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3208909000",
    "Description": "Solutions as defined in Note 4 to this Chapter",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3209101000",
    "Description": "Varnishes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3209102000",
    "Description": "Paints",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3209901000",
    "Description": "Varnishes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 0,
    "DATE OF VALIDITY": "21/12/2020"
  },
  {
    "CET Code": "3209902000",
    "Description": "Paints",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3210001000",
    "Description": "Varnishes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3210002000",
    "Description": "Paints",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3210009000",
    "Description": "Other paints and varnishes not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3211000000",
    "Description": "Prepared driers.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3212100000",
    "Description": "Stamping foils",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3212900000",
    "Description": "Pigments (including metallic powders and Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 0,
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3213100000",
    "Description": "Colours in sets",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3213900000",
    "Description": "Artists, students or signboard painter Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3214101000",
    "Description": "Glaziers' putty, grafting putty, resin cements, caulking compounds and other mastics",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3214102000",
    "Description": "Painters' fillings",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3214900000",
    "Description": "nonrefractory surfacing preparations for facades, indoor",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3215110000",
    "Description": "-- Black",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "3215190000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "04/01/2022"
  },
  {
    "CET Code": "3215901000",
    "Description": "Writing ink",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3215909000",
    "Description": "Other ink not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3301120000",
    "Description": "Essential oils of orange",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3301130000",
    "Description": "Essential oils of lemon",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3301190000",
    "Description": "Essential oils of citrus fruit Other.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3301240000",
    "Description": "Essential oils of peppermint (Mentha piperita)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3301250000",
    "Description": "Essential oils of other mints",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3301291000",
    "Description": "Essential oils of citronella",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3301299000",
    "Description": "Other Essential oils other than those of citrus fruit ",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3301300000",
    "Description": "Resinoids",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3301900000",
    "Description": "Other essential oils, aqueous distillates and aqueous solutions of essential oils",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3302100000",
    "Description": "Mixtures of odoriferous substances of a kind used in the food or drink industries",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3302901000",
    "Description": "Mixtures of odoriferous substances of a kind used in perfumery",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3302909000",
    "Description": "Mixtures of odoriferous substances of a kind used in other industries",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3303001000",
    "Description": "Liquid perfumes, containing alcohol",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3303002000",
    "Description": "Liquid perfumes, not containing alcohol",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3303009000",
    "Description": "Other Perfumes and toilet waters not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3304100000",
    "Description": "Lip makeup preparations",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3304200000",
    "Description": "Eye makeup preparations",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3304300000",
    "Description": "Manicure or pedicure preparations",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3304910000",
    "Description": "Powders, whether or not compressed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3304990000",
    "Description": "Beauty or makeup preparations and prepar Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "3305100000",
    "Description": "Shampoos",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "33049930",
    "Description": "Body cream lotion",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "34011100",
    "Description": "Body soap",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "33049990",
    "Description": "Face powder",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "33049190",
    "Description": "Cosmetics",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3305200000",
    "Description": "Preparations for permanent waving or straightening",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3305300000",
    "Description": "Hair lacquers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3305900000",
    "Description": "Preparations for use on the hair. Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3306100000",
    "Description": "Dentifrices",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3306200000",
    "Description": "Yarn used to clean between the teeth (dental floss)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3306900000",
    "Description": "Preparations for oral or dental hygiene, Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3307100000",
    "Description": "Preshave, shaving or aftershave preparations",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3307200000",
    "Description": "Personal deodorants and antiperspirants",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3307300000",
    "Description": "Perfumed bath salts and other bath preparations",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3307410000",
    "Description": "\"Agarbatti\" and other odoriferous preparations which operate by burning",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3307490000",
    "Description": "Preshave, shaving oaftershave preparat Other (Preparations for perfumingor deodo",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3307900000",
    "Description": "Preshave, shaving or aftershave preparat Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3401111000",
    "Description": "Medicated soaps",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3401119000",
    "Description": "Soap For toilet use (excluding Medicated soaps)",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3401191000",
    "Description": "Household soaps",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3401192000",
    "Description": "Surfaceactive products and preparations",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3401199000",
    "Description": "Other soaps covered in this subheading but not specified or included",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3401200000",
    "Description": "Soap in other forms",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3401300000",
    "Description": "Organic surfaceactive products and preparations for wash",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3402311000",
    "Description": "---  Put up for retail sale",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3402319000",
    "Description": "---  Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3402391000",
    "Description": "---  Put up for retail sale",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3402399000",
    "Description": "Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3402411000",
    "Description": "---  Put up for retail sale",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3402419000",
    "Description": "---  Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3402421000",
    "Description": "---  Put up for retail sale",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3402429000",
    "Description": "---  Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3402491000",
    "Description": "---  Put up for retail sale",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3402499000",
    "Description": "---  Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3402500000",
    "Description": "- Preparations put up for retail sale",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3402900000",
    "Description": "Organic surfaceactive agents (other than Other.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3403110000",
    "Description": "Preparations for the treatment of textile materials, lea",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3403190000",
    "Description": "Lubricating preparations (including cutt Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3403910000",
    "Description": "Preparations for the treatment of textile materials, lea",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3403990000",
    "Description": "Lubricating preparations (including cutt other Preparations for the treatment of.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3404200000",
    "Description": "Of poly(oxyethylene) (polyethylene glycol)",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "3404900000",
    "Description": "Other artificial waxes and prepared waxes",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3405100000",
    "Description": "Polishes, creams and similar preparations for footwear or leather",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3405200000",
    "Description": "Polishes, creams and similar preparations for the mainten",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3405300000",
    "Description": "Polishes and similar preparations for coachwork, other than metal polishes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3405400000",
    "Description": "Scouring pastes and powders and other scouring preparations",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3405900000",
    "Description": "Polishes and creams, for footwear, furni Other.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3406000000",
    "Description": "Candles, tapers and the like.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3407000000",
    "Description": "Modelling pastes, including those put up for children's amu",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3501100000",
    "Description": "Casein",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3501900000",
    "Description": "Casein, caseinates and other casein deri Other.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3502110000",
    "Description": "Dried",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3502190000",
    "Description": "Albumins (including concentrates of two Other (Egg albumin).",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3502200000",
    "Description": "Milk albumin, including concentrates of two or more whey proteins",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3502900000",
    "Description": "Albumins (including concentrates of two or Other.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3503000000",
    "Description": "Gelatin (including gelatin in rectangular (including square",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3504000000",
    "Description": "Peptones and their derivatives; other protein substances an",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3505100000",
    "Description": "Dextrins and other modified starches",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3505200000",
    "Description": "Glues",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3506100000",
    "Description": "Products suitable for use as glues or adhesives, put up f",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "3506910000",
    "Description": "Adhesives based on polymers of headings 39.01 to 39.13 or on rubber",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3506990000",
    "Description": "Prepared glues and other prepared adhesives Other adhesives",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3507100000",
    "Description": "Rennet and concentrates thereof",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3507900000",
    "Description": "Other enzymes prepared and not elsewhere specified or included",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3601000000",
    "Description": "Propellent powders.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3602001000",
    "Description": "Dynamite",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3602009000",
    "Description": "Other Prepared explosives, other than propellent powders not specified.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3603100000",
    "Description": "- Safety fuses",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3603200000",
    "Description": "- Detonating cords",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3603300000",
    "Description": "- Percussion caps",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3603400000",
    "Description": "- Detonating caps",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3603500000",
    "Description": "- Igniters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3603600000",
    "Description": "- Electric detonators",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3604100000",
    "Description": "Fireworks",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3604900000",
    "Description": "Fireworks, gnalling flares, rain rockets, fog signals and other phyrotec",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3605000000",
    "Description": "Matches, other than pyrotechnic articles of heading 36.04.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3606100000",
    "Description": "Liquid or liquefiedgas fuels in containers of a kind use",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3606900000",
    "Description": "Other Liquid or liquefiedgas fuels in containers of a ki",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3701100000",
    "Description": "For Xray",
    "SU": "M2",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3701200000",
    "Description": "Instant print film",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3701300000",
    "Description": "Other plates and film, with any side exceeding 255 mm",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3701910000",
    "Description": "For colour photography (polychrome)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3701990000",
    "Description": "Photographic plates and film in the flat Other.",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702100000",
    "Description": "For Xray",
    "SU": "M2",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702310000",
    "Description": "For colour photography (polychrome)",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702320000",
    "Description": "Other, with silver halide emulsion",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702391000",
    "Description": "Instant print film",
    "SU": "M2",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702399000",
    "Description": "Other film, without perforations, of a width not exceeding 105 mm not specified.",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702410000",
    "Description": "Of a width exceeding 610 mm and of a length exceeding 20",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702420000",
    "Description": "Of a width exceeding 610 mm and of a length exceeding 20",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702430000",
    "Description": "Of a width exceeding 610 mm and of a length not exceeding 200 m",
    "SU": "M2",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702440000",
    "Description": "Of a width exceeding 105 mm but not exceeding 610 mm",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702520000",
    "Description": "Of a width not exceeding 16 mm",
    "SU": "M",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702530000",
    "Description": "Of a width exceeding 16 mm but not exceeding 35 mm and o",
    "SU": "M",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702540000",
    "Description": "Of a width exceeding 16 mm but not exceeding 35 mm and o",
    "SU": "M",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702550000",
    "Description": "Of a width exceeding 16 mm but not exceeding 35 mm and of a length exceeding 30 m",
    "SU": "M",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702560000",
    "Description": "Of a width exceeding 35 mm",
    "SU": "M",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702960000",
    "Description": "Of a width not exceeding 35 mm and of a length not exceeding 30 m",
    "SU": "M",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702970000",
    "Description": "Of a width not exceeding 35 mm and of a length exceeding 30 m",
    "SU": "M",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3702980000",
    "Description": "Of a width exceeding 35 mm",
    "SU": "M",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3703100000",
    "Description": "In rolls of a width exceeding 610 mm",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3703200000",
    "Description": "Other, for colour photography (polychrome)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3703900000",
    "Description": "Photographic paper, paperboard and text.. Other.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3704000000",
    "Description": "Photographic plates, film, paper, paperboard and textiles, exposed but not developed.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3705000000",
    "Description": "Photographic plates and film, exposed and developed, other than cinematographic film",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3706101000",
    "Description": "For exhibition in cinema halls",
    "SU": "M",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3706109000",
    "Description": "Other Cinematographic film of width 35mm 0r more not specified.",
    "SU": "M",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3706901000",
    "Description": "For exhibition in cinema halls",
    "SU": "M",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3706909000",
    "Description": "Other Cinematographic film not specified.",
    "SU": "M",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "3707100000",
    "Description": "Sensitising emulsions",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3707900000",
    "Description": "Chemical preparations for photographic... Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3801100000",
    "Description": "Artificial graphite",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3801200000",
    "Description": "Colloidal or semicolloidal graphite",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3801300000",
    "Description": "Carbonaceous pastes for electrodes and similar pastes for furnace linings",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3801900000",
    "Description": "Artificial graphite; colloil or semico... Other.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3802100000",
    "Description": "Activated carbon",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3802900000",
    "Description": "Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3803000000",
    "Description": "Tall oil, whether or not refined.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3804000000",
    "Description": "Residual lyes from the manufacture of wood pulp, whether or",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3805100000",
    "Description": "Gum, wood or sulphate turpentine oils",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3805900000",
    "Description": "Gum, wood or sulphate turpentine and other. Other.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3806100000",
    "Description": "Rosin and resin acids",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3806200000",
    "Description": "Salts of rosin, of resin acids or of derivatives of rosin",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3806300000",
    "Description": "Ester gums",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3806900000",
    "Description": "Other derivatives of rosin and resin acids, nes; rosin spirit and oils, nes",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3807000000",
    "Description": "Wood tar; wood tar oils; wood creosote; wood naphtha; veget",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808520000",
    "Description": "DDT (ISO) (clofenotane (INN)), in packings of a net weight content not exceeding 300g",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808591100",
    "Description": "For agricultural purposes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808591900",
    "Description": "Other, put up for retail sale.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808599000",
    "Description": "Other, not put up for retail sale.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808610000",
    "Description": "In packings of a net weight content not exceeding 300 g",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808620000",
    "Description": "In packings of a net weight content exceeding 300 g but not exceeding 7.5 kg",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808690000",
    "Description": "Other, in packing of a net weight content exceeding 7.5kg",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808911110",
    "Description": "Containing bromomethane (methyl bromide) or bromochloromethane",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808911190",
    "Description": "Other not containing bromomethane (methyl bromide) or bromochloromethane",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808911910",
    "Description": "Mosquito coils",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808911920",
    "Description": "Containing bromomethane (methyl bromide) or bromochloromethane",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808911990",
    "Description": "Other Insecticides Put up for retail sale, Of the ty",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808919000",
    "Description": "Other Insecticides not Put up for retail sale, Of th",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808921000",
    "Description": "Containing bromomethane (methyl bromide) or bromochloromethane",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808929000",
    "Description": "Other Fungicides not specified or included in this heading.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808931000",
    "Description": "Containing bromomethane (methyl bromide) or bromochloromethane",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808939000",
    "Description": "Other Herbicides, antisprouting products and plantgr",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808941000",
    "Description": "Containing bromomethane (methyl bromide) or bromochloromethane",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808949000",
    "Description": "Other Disinfectant not specified or included in this heading.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808991000",
    "Description": "Containing bromomethane (methyl bromide) or bromochloromethane",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3808999000",
    "Description": "Other Products covered in this heading but not specified or included",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3809100000",
    "Description": "With a basis of amylaceous substances",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3809910000",
    "Description": "Of a kind used in the textile or like industries",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3809920000",
    "Description": "Of a kind used in the paper or like industries",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3809930000",
    "Description": "Of a kind used in the leather or like industries",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3810100000",
    "Description": "Pickling preparations for metal surfaces; soldering, braz",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3810900000",
    "Description": "fluxes and other auxiliary preparations for soldering, br",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3811110000",
    "Description": "Based on lead compounds",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3811190000",
    "Description": "Antiknock preparations, oxidation i Other.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3811210000",
    "Description": "Containing petroleum oils or oils obtained from bituminous minerals",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3811290000",
    "Description": "Other additives for lubricating oils (excl. with petroleum oils)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3811900000",
    "Description": "Other additives other than for lubricating oils nes",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3812100000",
    "Description": "Prepared rubber accelerators",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3812200000",
    "Description": "Compound plasticisers for rubber or plastics",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3812310000",
    "Description": "Mixtures of oligomers of 2,2,4-trimethyl-1,2-dihydroquinoline (TMQ) kg",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3812390000",
    "Description": "--  Other",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "22/06/2020"
  },
  {
    "CET Code": "3813001000",
    "Description": "Containing bromochlorodifluoromethane, bromotrifluoromet",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3813002000",
    "Description": "Containing methane, ethane or propane hydrobromofluocarbons (HBFCs)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3813003000",
    "Description": "Containing methane, ethane or hydrochlorofluorocarbons (HCFCs)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3813004000",
    "Description": "Containing bromochloromethane",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3813009000",
    "Description": "Other not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3814001000",
    "Description": "Containing methane, ethane or propane chlorofluorocarbon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "3814002000",
    "Description": "Containing methane, ethane or propane hydrochlorofluoroc",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3814003000",
    "Description": "Containing carbon tetrachloride, bromochloromethane, met",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3814009000",
    "Description": "Other Organic composite solvents and thinners, not elsew",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3815110000",
    "Description": "With nickel or nickel compounds as the active substance",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3815120000",
    "Description": "With precious metal or precious metal compounds as the active substance",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3815190000",
    "Description": "Reaction initiators, reaction accelerato.. Other.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3815900000",
    "Description": "Reaction initiators, reaction accele Other.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3816000000",
    "Description": "Refractory cements, mortars, concretes and similar composit",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3817000000",
    "Description": "Mixed alkylbenzenes and mixed alkylnaphthalenes, other than",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3818000000",
    "Description": "Chemical elements doped for use in electronics, in the form",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3819000000",
    "Description": "Hydraulic brake fluids and other prepared liquids for hydra",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3820000000",
    "Description": "Antifreezing preparations and prepared deicing fluids.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3821000000",
    "Description": "Prepared culture media for the development or maintenance o",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3822110000",
    "Description": "-- For malaria",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3822120000",
    "Description": "-- For Zika and other diseases transmitted by mosquitoes of the  genus Aedes",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3822130000",
    "Description": "-- For blood-grouping",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3822190000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3822900000",
    "Description": "- Other",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3823110000",
    "Description": "Stearic acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3823120000",
    "Description": "Oleic acid",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3823130000",
    "Description": "Tall oil fatty acids",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3823190000",
    "Description": "Industrial monocarboxylic fatty acids n oils from refining nes",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3823700000",
    "Description": "Industrial fatty alcohols",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824100000",
    "Description": "Prepared binders for foundry moulds or cores",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824300000",
    "Description": "Nonagglomerated metal carbides mixed together or with metallic binders",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824400000",
    "Description": "Prepared additives for cements, mortars or concretes",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824500000",
    "Description": "Nonrefractory mortars and concretes",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824600000",
    "Description": "Sorbitol other than that of subheading 2905.44",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3824810000",
    "Description": "Containing oxirane (ethylene oxide)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824820000",
    "Description": "Containing polychlorinated biphenyls (PCBs), polychlorin",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824830000",
    "Description": "Containing tris(2,3dibromopropyl) phosphate",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824840000",
    "Description": "Containing aldrin (ISO), camphechlor (ISO) (toxaphene), chlordane (ISO) etc",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824850000",
    "Description": "Containing 1,2,3,4,5,6-hexachlorocyclohexane (HCH (ISO)), including lindane (ISO, INN)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824860000",
    "Description": "Containing pentachlorobenzene (ISO) or hexachlorobenzene (ISO)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824870000",
    "Description": "Containing perfluorooctane sulphonic acid, its salts, perfluorooctane sulphonamides, or",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824880000",
    "Description": "Containing tetra-, penta-, hexa- hepta- or octabromodiphenyl ethers",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824890000",
    "Description": "-- Containing short-chain chlorinated paraffins",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3824910000",
    "Description": "Mixtures and preparations consisting mainly of ....methylphosphonate",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824920000",
    "Description": "-- Polyglycol esters of methylphosphonic acid",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3824991000",
    "Description": "Ion exchangers",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824992000",
    "Description": "Ink removers",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824993000",
    "Description": "Fluid and non-fluid correctors",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824999100",
    "Description": "Containg carbon tetrachloride, bromochloromethane or 1,1,1-trichloroethane (methyl chlor",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824999200",
    "Description": "Containing bromomethane (methyl bromide)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824999300",
    "Description": "Containing methane, ethane or propane hydrobromofluorocarbons (HBFCs)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824999400",
    "Description": "Containing methane, ethane or propane hydrochlorofluorocarbons (HCFCs)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3824999900",
    "Description": "Other not elsewhere specified or included in the sub-heading",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/10/2021"
  },
  {
    "CET Code": "3825100000",
    "Description": "Municipal waste",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3825200000",
    "Description": "Sewage sludge",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3825300000",
    "Description": "Clinical waste",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3825410000",
    "Description": "Halogenated",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3825490000",
    "Description": "Residual products of the chemical or allied Other[ waste organic sol]..",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3825500000",
    "Description": "Wastes of metal pickling liquors, hydraulic fluids, brake fluids and antifreeze fluids",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3825610000",
    "Description": "Mainly containing organic constituents",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3825690000",
    "Description": "Residual products of the chemical or allied Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3825900000",
    "Description": "Residual products of the other waste from l Other.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3826000000",
    "Description": "Biodiesel and mixtures thereof, not containing or containin",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3827110000",
    "Description": "-- Containing chlorofluorocarbons (CFCs), whether or not containing hydrochlorofluorocar",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827120000",
    "Description": "-- Containing hydrobromofluorocarbons (HBFCs)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827130000",
    "Description": "-- Containing carbon tetrachloride",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827140000",
    "Description": "-- Containing 1,1,1-trichloroethane (methyl chloroform)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827200000",
    "Description": "- Containing bromochlorodifluoromethane (Halon-1211), bromotrifluoromethane (Halon-1301)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827310000",
    "Description": "-- Containing substances of subheadings 2903.41 to 2903.48",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827320000",
    "Description": "-- Other, containing substances of subheadings 2903.71 to 2903.75",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827390000",
    "Description": "Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827400000",
    "Description": "- Containing methyl bromide (bromomethane) or bromochloromethane",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827510000",
    "Description": "-- Containing trifluoromethane (HFC-23)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827590000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827610000",
    "Description": "-- Containing 15 % or more by mass of 1,1,1-trifluoroethane (HFC-143a)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827620000",
    "Description": "-- Other, not included in the subheading above, containing 55 % or more by mass of penta",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827630000",
    "Description": "-- Other, not included in the subheadings above, containing 40 % or more by mass of pent",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827640000",
    "Description": "-- Other, not included in the subheadings above, containing 30 % or more by mass of 1,1,",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827650000",
    "Description": "-- Other, not included in the subheadings above, containing 20 % or more by mass of difl",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827680000",
    "Description": "-- Other, not included in the subheadings above, containing substances of subheadings 29",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827690000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3827900000",
    "Description": "- Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3901100000",
    "Description": "Polyethylene having a specific gravity of less than 0.94",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3901200000",
    "Description": "Polyethylene having a specific gravity of 0.94 or more",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3901300000",
    "Description": "Ethylenevinyl acetate copolymers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3901400000",
    "Description": "Ethylene-alpha-olefin copolymers, having a specific gravity of less than 094",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3901900000",
    "Description": "Other polymers of ethylene, in primary forms, nes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3902100000",
    "Description": "Polypropylene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3902200000",
    "Description": "Polyisobutylene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3902300000",
    "Description": "Propylene copolymers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3902900000",
    "Description": "Polymers of propylene or of other olefin.. Other.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3903110000",
    "Description": "Expansible",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3903190000",
    "Description": "Other polystyrene (excl. expansible) in primary forms",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3903200000",
    "Description": "Styreneacrylonitrile (SAN) copolymers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3903300000",
    "Description": "Acrylonitrilebutadienestyrene (ABS) copolymers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3903900000",
    "Description": "Polymers of styrene, in primary forms. Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3904100000",
    "Description": "Poly(vinyl chloride), not mixed with any other substances",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3904210000",
    "Description": "Nonplasticised",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3904220000",
    "Description": "Plasticised",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3904300000",
    "Description": "Vinyl chloridevinyl acetate copolymers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3904400000",
    "Description": "Other vinyl chloride copolymers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3904500000",
    "Description": "Vinylidene chloride polymers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3904610000",
    "Description": "Polytetrafluoroethylene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3904690000",
    "Description": "Polymers of vinyl chloride or of other Other(Fluoropolymers].",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3904900000",
    "Description": "Polymers of vinyl chloride of other ... Other.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3905120000",
    "Description": "In aqueous dispersion",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3905190000",
    "Description": "Polymers of vinyl acetate or of other vinyl acetate; Othekg",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3905210000",
    "Description": "In aqueous dispersion",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3905290000",
    "Description": "Other Vinyl acetate copolymers not specified.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3905300000",
    "Description": "Poly(vinyl alcohol), whether or not containing unhydrolysed acetate groups",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3905910000",
    "Description": "Copolymers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3905990000",
    "Description": "Other polymers of other vinyl esters and other vinyl polymers in primary forms, nes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3906100000",
    "Description": "Poly(methyl methacrylate)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3906900000",
    "Description": "Other Acrylic Polymers prepared in primary forms, nes",
    "SU": "KG",
    "ID": 5,
    "LVY": 15,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "3907100000",
    "Description": "Polyacetals",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3907210000",
    "Description": "-- Bis(polyoxyethylene) methylphosphonate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3907290000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3907300000",
    "Description": "Epoxide resins",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3907400000",
    "Description": "Polycarbonates",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3907500000",
    "Description": "Alkyd resins",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3907610000",
    "Description": "Having a viscosity number of 78 ml/g or higher",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 5,
    "DATE OF VALIDITY": "08/04/2021"
  },
  {
    "CET Code": "3907690000",
    "Description": "Other Poly(ethylene terephthalate)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3907700000",
    "Description": "Poly(lactic acid)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3907910000",
    "Description": "Unsaturated",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3907990000",
    "Description": "Polyacetals, other polyethers and epoxideOther (other polyesters).",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3908100000",
    "Description": "Polyamide6, 11, 12, 6,6, 6,9, 6,10 or 6,12",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3908900000",
    "Description": "Other Polyamides in primary forms; Not specified or included",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3909100000",
    "Description": "Urea resins; thiourea resins",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3909200000",
    "Description": "Melamine resins",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3909310000",
    "Description": "Poly(methylene phenyl isocyanate) (crude MDI, polymeric MDI)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3909390000",
    "Description": "Other Amino-resins in primary form",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3909400000",
    "Description": "Phenolic resins",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3909500000",
    "Description": "Polyurethanes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3910000000",
    "Description": "Silicones in primary forms.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3911100000",
    "Description": "Petroleum resins, coumarone, indene or coumaroneindene resins and polyterpenes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3911200000",
    "Description": "- Poly(1,3-phenylene methylphosphonate)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "3911900000",
    "Description": "polysulphides, polysulphones and other products specified",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3912110000",
    "Description": "Nonplasticised",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3912120000",
    "Description": "Plasticised",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3912200000",
    "Description": "Cellulose nitrates (including collodions)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3912310000",
    "Description": "Carboxymethylcellulose and its salts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3912390000",
    "Description": "Other cellulose ethers, in primary forms, nes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3912900000",
    "Description": "Other cellulose and its chemical derivatives in primary forms, nes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3913100000",
    "Description": "Alginic acid, its salts and esters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3913900000",
    "Description": "Natural polymers (for example,....) Other.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3914000000",
    "Description": "Ionexchangers based on polymers of headings 39.01 to 39.13, in primary forms.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3915100000",
    "Description": "Of polymers of ethylene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3915200000",
    "Description": "Of polymers of styrene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3915300000",
    "Description": "Of polymers of vinyl chloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3915900000",
    "Description": "Of other plastics",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3916100000",
    "Description": "Of polymers of ethylene",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3916200000",
    "Description": "Of polymers of vinyl chloride",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3916900000",
    "Description": "Of other plastics",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917100000",
    "Description": "Artificial guts (sausage casings) of hardened protein or of cellulosic materials",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3917211000",
    "Description": "For water supply",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917219000",
    "Description": "Tubes, pipes and hoses, rigid Of polymers of ethylene not for For water supply",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917221000",
    "Description": "For water supply",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917229000",
    "Description": "Other; of polymers of propylene not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917231000",
    "Description": "For for water supply",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917239000",
    "Description": "Tubes, pipes and hoses, excluding those for water supply",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917291000",
    "Description": "For water supply",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917299000",
    "Description": "For use other than water supplyr",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917310000",
    "Description": "Flexible tubes, pipes and hoses, having a minimum burst pressure of 27.6 MPa",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917320000",
    "Description": "Other, not reinforced or otherwise combined with other materials, without fittings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917330000",
    "Description": "Other, not reinforced or otherwise combined with other materials, with fittings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917391000",
    "Description": "Guts of other plastic material",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917399000",
    "Description": "Other Other tubes, pipes and hoses of plastic material",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917401000",
    "Description": "For water supply",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3917409000",
    "Description": "Other fittings not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3918100000",
    "Description": "Of polymers of vinyl chloride",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3918900000",
    "Description": "Of other plastics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3919100000",
    "Description": "In rolls of a width not exceeding 20 cm",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "3919900000",
    "Description": "Self-adhesive plates, sheets film, foil Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3920101000",
    "Description": "Other plates, sheets, film, foil and strip, of polymers of ethylene Not printed",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "LVY": 20,
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "3920102000",
    "Description": "Other plates, sheets, film, foil and strip, of polymers of ethylen Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "LVY": 10,
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "3920201000",
    "Description": "Other plates, sheets, film, foil and strip, of polymers of propylene Not printed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3920202000",
    "Description": "Other plates, sheets, film, foil and strip, of polymers of propylene Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "22/12/2020"
  },
  {
    "CET Code": "3920301000",
    "Description": "Other plates, sheets, film, foil and strip, of polymers of styrene Not printed",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "3920302000",
    "Description": "Other plates, sheets, film, foil and strip, of polymers of styrene Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3920430000",
    "Description": "Plates, sheets, film... of vinyl chloride Cont. by weight not < 6 % of plasticisers",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3920490000",
    "Description": "Plates, sheets, film... of vinyl chloride Cont. by weight >6 % of plasticisers",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3920510000",
    "Description": "Plates, sheets, film... of poly(methyl methacrylate)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3920590000",
    "Description": "Other Plates, sheets, film... of acrylic polymers not specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3920610000",
    "Description": "Plates, sheets, film... of polycarbonates",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3920620000",
    "Description": "Plates, sheets, film... of poly(ethylene terephthalate)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3920630000",
    "Description": "Plates, sheets, film... of unsaturated polyesters",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3920690000",
    "Description": "Plates, sheets, film... of other polyesters",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3920710000",
    "Description": "Plates, sheets, film... of regenerated cellulose",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3920730000",
    "Description": "Plates, sheets, film... of cellulose acetate",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3920790000",
    "Description": "Plates, sheets, film... of other cellulose derivatives",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3920910000",
    "Description": "Plates, sheets, film... of poly(vinyl butyral)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3920920000",
    "Description": "Plates, sheets, film... of polyamides",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3920930000",
    "Description": "Plates, sheets, film... of aminoresins",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3920940000",
    "Description": "Plates, sheets, film... of phenolic resins",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3920990000",
    "Description": "Plates, sheets, film... of other plastics",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3921110000",
    "Description": "Plates, sheets, film... of polymers Plates, sheets, film... of styrene",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3921120000",
    "Description": "Plates, sheets, film... of polymers Plates, sheets, film... of vinyl chloride",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "EXC": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3921130000",
    "Description": "Plates, sheets, film... of polyurethanes",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3921140000",
    "Description": "Plates, sheets, film... of regenerated cellulose",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3921190000",
    "Description": "Plates, sheets, film... of other plastics",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "3921901000",
    "Description": "Other Plates, sheets, film... Of, Not printed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3921902000",
    "Description": "Other Plates, sheets, film... Of; Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 0,
    "DATE OF VALIDITY": "21/12/2020"
  },
  {
    "CET Code": "3922100000",
    "Description": "Baths, showerbaths, sinks and washbasins",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3922200000",
    "Description": "Lavatory seats and covers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3922900000",
    "Description": "bidets, flushing cisterns and similar sanitary ware, of plastics.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3923100000",
    "Description": "Boxes, cases, crates and similar articles",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3923210000",
    "Description": "Sacks and bags (including cones) of polymers of ethylene",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "3923290000",
    "Description": "Sacks and bags (including cones) of other plastics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3923301000",
    "Description": "Preforms",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3923309000",
    "Description": "Other carboys, bottles, flasks and similar articles not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3923401000",
    "Description": "Cassettes without magnetic tape",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3923409000",
    "Description": "Other spools, cops, bobbins and similar articles not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3923500000",
    "Description": "Stoppers, lids, caps and other closures",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3923900000",
    "Description": "Other stoppers, lids and caps",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "3924100000",
    "Description": "Tableware and kitchenware",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3924901000",
    "Description": "Wash basins and buckets",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3924902000",
    "Description": "Feeding bottles",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3924909000",
    "Description": "Other household articles and hygienic or toilet articles, of plastics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3925100000",
    "Description": "Reservoirs, tanks, vats and similar containers, of a capacity exceeding 300 l",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3925200000",
    "Description": "Doors, windows and their frames and thresholds for doors",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3925300000",
    "Description": "Shutters, blinds (including Venetian blinds) and similar articles and parts thereof",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3925900000",
    "Description": "Builders? ware of plastics, not elsewher.. Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3926100000",
    "Description": "Office or school supplies",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3926200000",
    "Description": "Articles of apparel and clothing accessories (including gloves, mittens and mitts)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3926300000",
    "Description": "Fittings for furniture, coachwork or the like",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3926400000",
    "Description": "Statuettes and other ornamental articles",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "3926901000",
    "Description": "Articles for fishing",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "3926909100",
    "Description": "Toothpick",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "3926909900",
    "Description": "Other articles of plastics and articles of other mater",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "4001100000",
    "Description": "Natural rubber latex, whether or not prevulcanised",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4001210000",
    "Description": "Smoked sheets",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4001220000",
    "Description": "Technically specified natural rubber (TSNR)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4001290000",
    "Description": "Other natural rubber in primary forms or in plates etc, nes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4001300000",
    "Description": "Balata, guttapercha, guayule, chicle and similar natural gums",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002110000",
    "Description": "Latex",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002190000",
    "Description": "Synthetic rubber and factice derived from Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002200000",
    "Description": "Butadiene rubber (BR)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002310000",
    "Description": "Isobuteneisoprene (butyl) rubber (IIR)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002390000",
    "Description": "Synthetic rubber and factice derived from Other[isobutene isoprene ]..",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002410000",
    "Description": "Latex",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002490000",
    "Description": "Synthetic rubber and factice derived from Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002510000",
    "Description": "Latex",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002590000",
    "Description": "Synthetic rubber and factice derived from.. Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002600000",
    "Description": "Isoprene rubber (IR)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002700000",
    "Description": "Ethylenepropylenenonconjugated diene rubber (EPDM)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002800000",
    "Description": "Mixtures of any product of heading 40.01 with any product of this heading",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002910000",
    "Description": "Latex",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4002990000",
    "Description": "Synthetic rubber and factice derived from..Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4003000000",
    "Description": "Reclaimed rubber in primary forms or in plates, sheets or strip.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4004000000",
    "Description": "Waste, parings and scrap of rubber (other than hard rubber)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4005100000",
    "Description": "Compounded with carbon black or silica",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4005200000",
    "Description": "Solutions; dispersions other than those of subheading 4005.10",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4005910000",
    "Description": "Plates, sheets and strip",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4005990000",
    "Description": "Compounded rubber,unvulcanized,in primary..Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4006100000",
    "Description": "\"Camelback\" strips for retreading rubber tyres",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4006900000",
    "Description": "Other forms and articles of unvulcanised rubber,nes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4007000000",
    "Description": "Vulcanised rubber thread and cord.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4008111000",
    "Description": "Other Plates, sheets and strip of cellular rubber of the type used for making soles",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4008119000",
    "Description": "Other Plates, sheets and strip of cellular rubber excluding those for making shoes.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4008190000",
    "Description": "Rods and profile shapes, of cellular rubber other than hard rubber.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4008211000",
    "Description": "Other Plates, sheets and strip of non-cellular rubber of the type used for making soles",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "4008219000",
    "Description": "Other Plates, sheets and strip of non-cellular rubber excl. type used for making soles",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "4008290000",
    "Description": "Other rods and profile shapes of noncellular, vulcanised rubber (excl. hard rubber)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4009110000",
    "Description": "Tubes, pipes..., of vulcan. rubber excl. hard rubber, not reinforced; Without fittings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4009120000",
    "Description": "Tubes, pipes..., of vulcan. rubber excl. hard rubber, not reinforced; With fittings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4009210000",
    "Description": "Tubes, pipes..., of vulc. rubber excl. hard rubber, reinf. com. with metal; No fittings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4009220000",
    "Description": "Tubes, pipes..., of vulc. rubber excl. hard rubber, reinf. com. with metal; With fitting",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4009310000",
    "Description": "Reinf. or otherwise comb. only with textile materials tubes, pipe... Without fittings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4009320000",
    "Description": "Reinf. or otherwise comb. only with textile materials tubes, pipe... With fittings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4009410000",
    "Description": "Reinforced or otherwise combined with other materials tubes, pipe... Without fittings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4009420000",
    "Description": "Reinforced or otherwise combined with other materials tubes, pipe... With fittings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4010110000",
    "Description": "Reinforced only with metal",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4010120000",
    "Description": "Reinforced only with textile materials",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4010190000",
    "Description": "Conveyor or transmission belts or beltin Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4010310000",
    "Description": "Endless transmission belts of trapezoidal crosssection ",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4010320000",
    "Description": "Endless transmission belts of trapezoidal crosssection ",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4010330000",
    "Description": "Endless transmission belts of trapezoidal crosssection ",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4010340000",
    "Description": "Endless transmission belts of trapezoidal crosssection ",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4010350000",
    "Description": "Endless synchronous belts, of an outside circumference e",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4010360000",
    "Description": "Endless synchronous belts, of an outside circumference e",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4010390000",
    "Description": "Conveyor or transmission belts or belting Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4011100000",
    "Description": "Of a kind used on motor cars (including station wagons and racing cars)",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4011200000",
    "Description": "Of a kind used on buses or lorries",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4011300000",
    "Description": "Of a kind used on aircraft",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4011400000",
    "Description": "Of a kind used on motorcycles",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4011500000",
    "Description": "Of a kind used on bicycles",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4011700000",
    "Description": "Of a kind used on agricultural or forestry vehicles and machines",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/04/2020"
  },
  {
    "CET Code": "4011800000",
    "Description": "Of a kind used on construction, mining or industrial handling vehicles and machines",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/04/2020"
  },
  {
    "CET Code": "4011900000",
    "Description": "Other New pneumatic tyres, of rubber",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/04/2020"
  },
  {
    "CET Code": "4012110000",
    "Description": "Of a kind used on motor cars (including station wagons and racing cars)",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4012120000",
    "Description": "Of a kind used on buses or lorries",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4012130000",
    "Description": "Of a kind used on aircraft",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4012190000",
    "Description": "Retreaded or used pneu tyres of rubber Of Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4012201000",
    "Description": "For retreading manufacture",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4012209000",
    "Description": "Other Used pneumatic tyres not specified.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4012900000",
    "Description": "Other retreaded or used pneumatic tyres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4013100000",
    "Description": "Inner tubes, of rubber of a kind used on motor cars (including station wagons an",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4013200000",
    "Description": "Inner tubes, of rubber ofa kind used on bicycles",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4013901000",
    "Description": "Inner tubes, of rubber of the type used by motorcycles(with engine displacement =<50cm3)",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4013909000",
    "Description": "Other Inner tubes, of rubber not specified.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4014100000",
    "Description": "Sheath contraceptives",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4014901000",
    "Description": "Teats and similar articles",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "4014902000",
    "Description": "Bulbs for syringes and medicine droppers and similar articles",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "4014909000",
    "Description": "Other pharmaceutical articles of rubber nes",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "4015120000",
    "Description": "-- Of a kind used for medical, surgical, dental or veterinary purposes",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4015190000",
    "Description": "Articles of apparel and clothing accessr Other gloves mittens and mitts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "4015900000",
    "Description": "other articles of apparel and clothing accessories of vulcanised rubber, nes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4016100000",
    "Description": "Of cellular rubber",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4016910000",
    "Description": "Floor coverings and mats",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4016920000",
    "Description": "Erasers",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "4016930000",
    "Description": "Gaskets, washers and other seals",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4016940000",
    "Description": "Boat or dock fenders, whether or not inflatable",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4016951000",
    "Description": "Other inflatable articles of the type used for tyre manufacture",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4016959000",
    "Description": "Other Other inflatable articles non vulcanised rubber,",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4016990000",
    "Description": "Other articles of vulcanised rubber other than hard rubber, not specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "4017000000",
    "Description": "Hard rubber (for example, ebonite) in all forms, including ",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4101200000",
    "Description": "Whole hides and skins, unsplit, of a weight per skin not ",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4101500000",
    "Description": "Whole hides and skins, of a weight exceeding 16 kg",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4101900000",
    "Description": "Other, including butts, bends and bellies",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4102100000",
    "Description": "With wool on",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4102210000",
    "Description": "Pickled",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4102290000",
    "Description": "Raw skins of sheep or lambsWithout wool on: Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4103200000",
    "Description": "Of reptiles",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4103300000",
    "Description": "Of swine",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4103900000",
    "Description": "Other raw hides and skin[fresh, or Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4104110000",
    "Description": "Full grains, unsplit; grain splits",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4104190000",
    "Description": "Tanned or crust hides and skins of bovine Other[wet in state(including wetblue]",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4104410000",
    "Description": "Full grains, unsplit; grain splits",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4104490000",
    "Description": "Tanned or crust hides and skins of bovine n Other[ dry state (crust)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4105100000",
    "Description": "In the wet state (including wetblue)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4105300000",
    "Description": "In the dry state (crust)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4106210000",
    "Description": "In the wet state (including wetblue)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4106220000",
    "Description": "In the dry state (crust)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4106310000",
    "Description": "In the wet state (including wetblue)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4106320000",
    "Description": "In the dry state (crust)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4106400000",
    "Description": "Of reptiles",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4106910000",
    "Description": "In the wet state (including wetblue)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4106920000",
    "Description": "In the dry state (crust)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4107110000",
    "Description": "Full grains, unsplit",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4107120000",
    "Description": "Grain splits",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4107190000",
    "Description": "Other Whole hides and skins not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4107910000",
    "Description": "Full grains, unsplit",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4107920000",
    "Description": "Grain splits",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4107990000",
    "Description": "Other hides and skins not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4112000000",
    "Description": "Leather further prepared after tanning or crusting, includi",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4113100000",
    "Description": "Of goats or kids",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4113200000",
    "Description": "Of swine",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4113300000",
    "Description": "Of reptiles",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4113900000",
    "Description": "Leather further prepared after tanningr.. Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4114100000",
    "Description": "Chamois (including combination chamois) leather",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4114200000",
    "Description": "Patent leather and patent laminated leather; metallised leather",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4115100000",
    "Description": "Composition leather with a basis of leather or leather fi",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4115200000",
    "Description": "Parings and other waste of leather or of composition leat",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4201000000",
    "Description": "Saddlery and harness for any animal (including traces, lead",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4202111000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202119000",
    "Description": "Other Trunks, suitcases, vanitycases, executive cas",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202121000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202129000",
    "Description": "Other Trunks, suitcases, vanitycases, executive cas",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202191100",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202191900",
    "Description": "Other Trunks, suitcases, vanitycases, executive cas",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202192100",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202192900",
    "Description": "Other Trunks, suitcases, vanitycases, executive cas",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202199100",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202199900",
    "Description": "Other forms of Trunks, suitcases, vanitycases, execu",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202211000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202219000",
    "Description": "Other Handbags, whether or not with shoulder strap, in",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202221000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202229000",
    "Description": "Other Handbags, whether or not with shoulder strap, in",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202291000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202299000",
    "Description": "Other orms of Handbags, whether or not with shoulder s",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4202310000",
    "Description": "With outer surface of leather or of composition leather",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4202320000",
    "Description": "With outer surface of plastic sheeting or of textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4202390000",
    "Description": "Other Articles of a kind normally carried in the pocket ",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4202911000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4202919000",
    "Description": "Other Products covered in this heading but not specifie",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4202921000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4202929000",
    "Description": "Other Products covered in this heading but not specifie",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4202991000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4202999000",
    "Description": "Other Products covered in this heading but not specifie",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4203100000",
    "Description": "Articles of apparel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4203210000",
    "Description": "Specially designed for use in sports",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4203290000",
    "Description": "Articles of apparel and clothing accesso Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4203300000",
    "Description": "Belts and bandoliers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4203400000",
    "Description": "Other clothing accessories",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4205000000",
    "Description": "Other articles of leather or of composition leather.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4206000000",
    "Description": "Articles of gut (other than silkworm gut), of goldbeater's",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4301100000",
    "Description": "Of mink, whole, with or without head, tail or paws",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4301300000",
    "Description": "Of lamb, the following : Astrakhan, Broadtail, Caracul, P",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4301600000",
    "Description": "Of fox, whole, with or without head, tail or paws",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4301800000",
    "Description": "Other furskins, whole, with or without head, tail or paws",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4301900000",
    "Description": "Heads, tails, paws and other pieces or cuttings, suitable for furriers' use",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4302110000",
    "Description": "Of mink",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4302190000",
    "Description": "Other Whole skins, with or without head, tail or paws, not assembled not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4302200000",
    "Description": "Heads, tails, paws and other pieces or cuttings, not assembled",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4302300000",
    "Description": "Whole skins and pieces or cuttings thereof, assembled",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4303100000",
    "Description": "Articles of apparel and clothing accessories",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4303900000",
    "Description": "Other articles of furskins, nes",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4304000000",
    "Description": "Artificial fur and articles thereof.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4401110000",
    "Description": "Coniferous",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4401120000",
    "Description": "Non-coniferous",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4401210000",
    "Description": "Coniferous",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4401220000",
    "Description": "Nonconiferous",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4401310000",
    "Description": "Wood pellets",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4401320000",
    "Description": "-- Wood briquettes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4401390000",
    "Description": "Other Sawdust and wood waste and scrap, whether or not a",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4401410000",
    "Description": "-- Sawdust",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4401490000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4402100000",
    "Description": "Of bamboo",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4402200000",
    "Description": "- Of shell or nut",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4402900000",
    "Description": "Other wood charcoal",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403110000",
    "Description": "Coniferous",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403120000",
    "Description": "Non-coniferous",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403210000",
    "Description": "Of pine (Pinus spp), of which any cross-sectional dimension is 15 cm or more",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403220000",
    "Description": "Of pine (Pinus spp), other",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403230000",
    "Description": "Of fir (Abies spp) and spruce (Picea spp), of which any cross-sectional dimension is 15",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403240000",
    "Description": "Of fir (Abies spp) and spruce (Picea spp), other",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403250000",
    "Description": "Other, of which any cross-sectional dimension is 15 cm or more",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403260000",
    "Description": "Other",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403410000",
    "Description": "Dark Red Meranti, Light Red Meranti and Meranti Bakau",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4403420000",
    "Description": "-- Teak",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4403490000",
    "Description": "other tropical wood specified in subhead note 1 to chapter 40 in the rough excl.440341",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4403910000",
    "Description": "Of oak (Quercus spp.)",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4403930000",
    "Description": "Of beech (Fagus spp), of which any cross-sectional dimension is 15 cm or more",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403940000",
    "Description": "Of beech (Fagus spp), other",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403950000",
    "Description": "Of birch (Betula spp), of which any cross-sectional dimension is 15 cm or more",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4403960000",
    "Description": "Of birch (Betula spp), other",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403970000",
    "Description": "Of poplar and aspen (Populus spp)",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403980000",
    "Description": "Of eucalyptus (Eucalyptus spp)",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4403990000",
    "Description": "Other not elsewhere speficied in this sub-heading",
    "SU": "M3",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4404100000",
    "Description": "Coniferous",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4404200000",
    "Description": "Nonconiferous",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4405000000",
    "Description": "Wood wool; wood flour.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4406110000",
    "Description": "Coniferous",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4406120000",
    "Description": "Non-coniferous",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4406910000",
    "Description": "Coniferous",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4406920000",
    "Description": "Non-coniferous",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4407110000",
    "Description": "Of pine (Pinus spp)",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4407120000",
    "Description": "Of fir (Abies spp) and spruce (Picea spp)",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4407130000",
    "Description": "-- Of S-P-F (spruce (Picea spp.), pine (Pinus spp.) and fir (Abies spp.))",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4407140000",
    "Description": "-- Of Hem-fir (Western hemlock (Tsuga heterophylla) and fir (Abies spp.))",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4407190000",
    "Description": "Other",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4407210000",
    "Description": "Mahogany (Swietenia spp.)",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4407220000",
    "Description": "Virola, Imbuia and Balsa",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4407230000",
    "Description": "-- Teak",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4407250000",
    "Description": "Dark Red Meranti, Light Red Meranti and Meranti Bakau",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4407260000",
    "Description": "White Lauan, White Meranti, White Seraya, Yellow Meranti and Alan",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4407270000",
    "Description": "Sapelli",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4407280000",
    "Description": "Iroko",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4407290000",
    "Description": "Other; of tropical wood specified in Subheading Note 2 to this Chapter not specified.",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4407910000",
    "Description": "Of oak (Quercus spp.)",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4407920000",
    "Description": "Of beech (Fagus spp.)",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4407930000",
    "Description": "Of maple (Acer spp.)",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4407940000",
    "Description": "Of cherry (Prunus spp.)",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4407950000",
    "Description": "Of ash (Fraxinus spp.)",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4407960000",
    "Description": "Of birch (Betula spp)",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4407970000",
    "Description": "Of poplar and aspen (Populus spp)",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4407990000",
    "Description": "Other not elsewhere speficied in this sub-heading",
    "SU": "M3",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4408100000",
    "Description": "Coniferous",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4408310000",
    "Description": "Dark Red Meranti, Light Red Meranti and Meranti Bakau",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4408390000",
    "Description": "Sheets for veneering (including those ob Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4408900000",
    "Description": "Veneer sheets and sheets for plywood and other wood, =<6mm thick, nes",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4409100000",
    "Description": "Coniferous",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4409210000",
    "Description": "Of bamboo",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4409220000",
    "Description": "Of tropical wood",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4409290000",
    "Description": "Other Nonconiferous not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4410110000",
    "Description": "Particle board",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4410120000",
    "Description": "Oriented strand board (OSB)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4410190000",
    "Description": "Other Particle board, oriented strand board (OSB) and similar board not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4410900000",
    "Description": "Particle board and similar board (for ex Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4411120000",
    "Description": "Of a thickness not exceeding 5 mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4411130000",
    "Description": "Of a thickness exceeding 5 mm but not exceeding 9 mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4411140000",
    "Description": "Of a thickness exceeding 9 mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4411920000",
    "Description": "Of a density exceeding 0.8 g/cm3",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4411930000",
    "Description": "Of a density exceeding 0.5 g/cm3 but not exceeding 0.8 g/cm3",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4411940000",
    "Description": "Of a density not exceeding 0.5 g/cm3",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4412100000",
    "Description": "Of bamboo",
    "SU": "M3",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4412310000",
    "Description": "With at least one outer ply of tropical wood specified i",
    "SU": "M3",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4412330000",
    "Description": "Other, with at least one outer ply of non-coniferous wood of the species alder (Alnus sp",
    "SU": "M3",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4412340000",
    "Description": "--  Other, with at least one outer ply of non-coniferous wood not under 4412.33",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/07/2020"
  },
  {
    "CET Code": "4412390000",
    "Description": "Other plywood, consisting solely of sheets of wood (othe",
    "SU": "M3",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4412410000",
    "Description": "-- With at least one outer ply of tropical wood",
    "SU": "M3",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4412420000",
    "Description": "-- Other, with at least one outer ply of non-coniferous wood",
    "SU": "M3",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4412490000",
    "Description": "-- Other, with both outer plies of coniferous wood",
    "SU": "M3",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4412510000",
    "Description": "-- With at least one outer ply of tropical wood",
    "SU": "M3",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4412520000",
    "Description": "-- Other, with at least one outer ply of non-coniferous wood",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4412590000",
    "Description": "-- Other, with both outer plies of coniferous wood",
    "SU": "M3",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4412910000",
    "Description": "-- With at least one outer ply of tropical wood",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4412920000",
    "Description": "-- Other, with at least one outer ply of non-coniferous wood",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "4412990000",
    "Description": "Other Plywood, veneered panels and similar laminated wood not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4413000000",
    "Description": "Densified wood, in blocks, plates, strips or profile shapes.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4414100000",
    "Description": "- Of tropical wood",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4414900000",
    "Description": "- Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4415100000",
    "Description": "Cases, boxes, crates, drums and similar packings; cabledrums",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4415200000",
    "Description": "Pallets, box pallets and other load boards; pallet collars",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "4416000000",
    "Description": "Casks, barrels, vats, tubs and other coopers' products and ",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4417000000",
    "Description": "Tools, tool bodies, tool handles, broom or brush bodies and",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4418110000",
    "Description": "-- Of tropical wood",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4418190000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4418210000",
    "Description": "-- Of tropical wood",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4418290000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4418300000",
    "Description": "- Posts and beams other than products of subheadings 4418.81 to 4418.89",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "4418400000",
    "Description": "Shuttering for concrete constructional work",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4418500000",
    "Description": "Shingles and shakes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4418730000",
    "Description": "Of bamboo or with at least the top layer (wear layer) of bamboo",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4418740000",
    "Description": "Other, for mosaic floors",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4418750000",
    "Description": "Other, multilayer",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4418790000",
    "Description": "Other Assembled flooring panels not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4418810000",
    "Description": "-- Glue-laminated timber (glulam)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4418820000",
    "Description": "-- Cross-laminated timber (CLT or X-lam)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4418830000",
    "Description": "-- I beams",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4418890000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4418910000",
    "Description": "Of bamboo",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4418920000",
    "Description": "-- Cellular wood panels",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4418990000",
    "Description": "--  Other",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "22/06/2020"
  },
  {
    "CET Code": "4419110000",
    "Description": "Bread boards, chopping boards and similar boards",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4419120000",
    "Description": "Chopsticks",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4419190000",
    "Description": "Other, of bamboo",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4419200000",
    "Description": "- Of tropical wood",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4419900000",
    "Description": "Other Tableware and kitchenware, of wood.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4420110000",
    "Description": "-- Of tropical wood",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "06/01/2022"
  },
  {
    "CET Code": "4420190000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "4420900000",
    "Description": "Other Wood marquetry and inlaid wood; caskets",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4421100000",
    "Description": "Clothes hangers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4421200000",
    "Description": "- Coffins",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "4421911000",
    "Description": "Match splints",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4421912000",
    "Description": "Toothpicks",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4421919000",
    "Description": "Other of bamboo",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4421991000",
    "Description": "Match splints",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4421992000",
    "Description": "Toothpicks",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4421999000",
    "Description": "Other not elsewhere specified in this sub-heading",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4501100000",
    "Description": "Natural cork, raw or simply prepared",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4501900000",
    "Description": "Other Natural cork,raw or simply prepared;waste..",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4502000000",
    "Description": "Natural cork, debacked or roughly squared, or in rectangula",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4503100000",
    "Description": "Corks and stoppers",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4503901000",
    "Description": "Floats for fishing nets",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4503909000",
    "Description": "Other articles of natural cork not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4504100000",
    "Description": "Blocks, plates, sheets and strip; tiles of any shape; solid cylinders, including discs",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4504900000",
    "Description": "Agglomerated cork (with or without a binding cork... Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4601210000",
    "Description": "Of bamboo",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4601220000",
    "Description": "Of rattan",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4601290000",
    "Description": "Other Mats, matting and screens of vegetable materials not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4601920000",
    "Description": "Of bamboo",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4601930000",
    "Description": "Of rattan",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4601940000",
    "Description": "Of other vegetable materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4601991000",
    "Description": "Mats, matting and screens",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4601999000",
    "Description": "Other Plaits and similar products of plaiting material",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4602110000",
    "Description": "Of bamboo",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4602120000",
    "Description": "Of rattan",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4602190000",
    "Description": "Other Basketwork, wickerwork and other articles, made di",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4602900000",
    "Description": "Basketwork,wickerwork and other articles... Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4701000000",
    "Description": "Mechanical wood pulp.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4702000000",
    "Description": "Chemical wood pulp, dissolving grades.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4703110000",
    "Description": "Coniferous",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4703190000",
    "Description": "Nonconiferous",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4703210000",
    "Description": "Coniferous",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "4703290000",
    "Description": "Nonconiferous",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4704110000",
    "Description": "Coniferous",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4704190000",
    "Description": "Nonconiferous",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4704210000",
    "Description": "Coniferous",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "4704290000",
    "Description": "Nonconiferous",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4705000000",
    "Description": "Wood pulp obtained by a combination of mechanical and chemical pulping processes.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4706100000",
    "Description": "Cotton linters pulp",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4706200000",
    "Description": "Pulps of fibres derived from recovered (waste and scrap) paper or paperboard",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4706300000",
    "Description": "Other, of bamboo",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4706910000",
    "Description": "Mechanical",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4706920000",
    "Description": "Chemical",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4706930000",
    "Description": "Obtained by a combination of mechanical and chemical processes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4707100000",
    "Description": "Unbleached kraft paper or paperboard or corrugated paper or paperboard",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4707200000",
    "Description": "Other paper or paperboard made mainly of bleached chemica",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4707300000",
    "Description": "Paper or paperboard made mainly of mechanical pulp (for e",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4707900000",
    "Description": "Other, including unsorted waste and scrap",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4801000000",
    "Description": "Newsprint, in rolls or sheets.",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802100000",
    "Description": "Handmade paper and paperboard",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802200000",
    "Description": "Paper and paperboard of a kind used as a base for photos",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802400000",
    "Description": "Wallpaper base",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802540000",
    "Description": "Weighing less than 40 g/m2",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802551000",
    "Description": "Paper or paperboard Weighing =>40 g/m2 or but not> 150 g/m2, in rolls of a width <150mm",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802559000",
    "Description": "Paper or paperboard Weighing =>40 g/m2 or but not> 150 g/m2, in rolls of a width >150mm",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802561000",
    "Description": "Carbonising base paper",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802569000",
    "Description": "Non Carbonising base paper",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802570000",
    "Description": "Other, weighing 40 g/m2 or more but not more than 150 g/m2",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802580000",
    "Description": "Weighing more than 150 g/m2",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802611000",
    "Description": "Other paper and paperboard in rolls of a width not exceeding 150mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802619000",
    "Description": "Other paper and paperboard in rolls of a width exceeding 150mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802621000",
    "Description": "Carbonising base paper",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802629000",
    "Description": "Non Carbonising base paper",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802691000",
    "Description": "Carbonising base paper",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4802699000",
    "Description": "Non Carbonising base paper",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4803000000",
    "Description": "Toilet or facial tissue stock, towel or napkin stock and si",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "4804110000",
    "Description": "Unbleached",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4804190000",
    "Description": "Uncoated kraft paper and board,in rolls... Other[kraftliner]",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4804210000",
    "Description": "Unbleached",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4804290000",
    "Description": "Other Sack kraft paper not specified.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4804310000",
    "Description": "Unbleached",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4804390000",
    "Description": "Uncoated krt paper and paperboard, in rolls or sheets, Other kraft paper",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4804410000",
    "Description": "Unbleached",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4804420000",
    "Description": "Bleached uniformly throughout the mass and of which more",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4804490000",
    "Description": "Uncoated kraft paper and paperboard, in rolls or sheets, other kraft pape",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4804510000",
    "Description": "Unbleached",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4804520000",
    "Description": "Bleached uniformly throughout the mass and of which more",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4804590000",
    "Description": "Uncoated kraft paper and paperboard, in rolls or sheets, other kraft pape",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4805110000",
    "Description": "Semichemical fluting paper",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "4805120000",
    "Description": "Straw fluting paper",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "4805190000",
    "Description": "Other uncoated paper and paperboard, in rolls or sheets, not further work",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "4805240000",
    "Description": "Weighing 150 g/m2 or less",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "4805250000",
    "Description": "Weighing more than 150 g/m2",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "4805300000",
    "Description": "Sulphite wrapping paper",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4805400000",
    "Description": "Filter paper and paperboard",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4805500000",
    "Description": "Felt paper and paperboard",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4805910000",
    "Description": "Weighing 150 g/m2 or less",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4805920000",
    "Description": "Weighing more than 150 g/m2 but less than 225 g/m2",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4805930000",
    "Description": "Weighing 225 g/m2 or more",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4806100000",
    "Description": "Vegetable parchment",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4806200000",
    "Description": "Greaseproof papers",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4806300000",
    "Description": "Tracing papers",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4806400000",
    "Description": "Glassine and other glazed transparent or translucent papers",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4807000000",
    "Description": "Composite paper and paperboard (made by sticking flat layer",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4808100000",
    "Description": "Corrugated paper and paperboard, whether or not perforated",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4808400000",
    "Description": "Kraft paper, creped or crinkled, whether or not embossed or perforated",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4808900000",
    "Description": "Paper and paperboard, corrugated (with or without glued flat surface shee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4809200000",
    "Description": "Selfcopy paper",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4809900000",
    "Description": "Carbon paper, selfcopy paper and other copying or transfer papers (inclu",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810131000",
    "Description": "Paper and paperboard of a kind used for writing, printing  in rolls; Printed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "4810139000",
    "Description": "Paper and paperboard of a kind used for writing, printing  in rolls; not printed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810140000",
    "Description": "In sheets with one side not exceeding 435 mm and the oth",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810190000",
    "Description": "Other Paper and paperboard of a kind used for writing, p",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810221000",
    "Description": "In rolls of a width not exceeding 150mm or in rectangu",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810229000",
    "Description": "In rolls of a width exceeding 150mm or in rectan",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810291000",
    "Description": "In rolls of a width not exceeding 150mm or in rectangu",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810299000",
    "Description": "In rolls of a width exceeding 150mm or in rectan",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810311000",
    "Description": "In rolls of a width not exceeding 150mm or in rectangu",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810319000",
    "Description": "In rolls of a width exceeding 150mm or in rectan",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810321000",
    "Description": "In rolls of a width not exceeding 150mm or in rectangu",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810329000",
    "Description": "In rolls of a width exceeding 150mm or in rectan",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810391000",
    "Description": "In rolls of a width not exceeding 150mm or in rectangu",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810399000",
    "Description": "In rolls of a width exceeding 150mm or in rectan",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810921000",
    "Description": "In rolls of a width not exceeding 150mm or in rectangu",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810929000",
    "Description": "In rolls of a width exceeding 150mm or in rectan",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810991000",
    "Description": "In rolls of a width not exceeding 150mm or in rectangu",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4810999000",
    "Description": "In rolls of a width exceeding 150mm or in rectan",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "4811101000",
    "Description": "Floor covering on a base of paper or of paperboard, whether or not cut to size",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811109000",
    "Description": "Other Tarred, bituminised or asphalted paper and paperboard not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811411000",
    "Description": "In rolls of a width not exceeding 150mm or in rectangu",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811419000",
    "Description": "In rolls of a width exceeding 150mm or in rectan",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811491000",
    "Description": "In rolls of a width not exceeding 150mm or in rectangu",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811499000",
    "Description": "In rolls of a width exceeding 150mm or in rectan",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811511000",
    "Description": "In rolls of a width not exceeding 150mm or in rectangu",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811512000",
    "Description": "Floor covering on a base of paper or of paperboard, whether or not cut to size",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811519000",
    "Description": "Other bleachd Paper and paperboard Not specified or included in this subheading",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811591000",
    "Description": "In rolls of a width not exceeding 150mm or in rectangu",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "4811592000",
    "Description": "Floor covering on a base of paper or of paperboard, whether or not cut to size",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811599000",
    "Description": "Other Paper and paperboard coated, impregnated or cove",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "4811601000",
    "Description": "In rolls of a width not exceeding 150mm or in rectangul",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811602000",
    "Description": "Floor covering on a base of paper or of paperboard, whether or not cut to size",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811609000",
    "Description": "Other cellulose wadding not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811901000",
    "Description": "In rolls of a width not exceeding 150mm or in rectangul",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811902000",
    "Description": "Floor covering on a base of paper or of paperboard, whether or not cut to size",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4811909000",
    "Description": "Other paper, paperboard, cellulose wadding and webs of cellulose fibre not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4812000000",
    "Description": "Filter blocks, slabs and plates, of paper pulp.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4813100000",
    "Description": "In the form of booklets or tubes",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4813200000",
    "Description": "In rolls of a width not exceeding 5 cm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4813900000",
    "Description": "Other cigarette paper whether or not cut to size or in the form of booklets or tubes",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4814200000",
    "Description": "Wallpaper and similar wall coverings, consisting of paper",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4814900000",
    "Description": "Wallpaper and similar wall coverings; window transparenes. Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4816200000",
    "Description": "Selfcopy paper",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4816900000",
    "Description": "Carbon paper and other copying or transfer papers (other ",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4817100000",
    "Description": "Envelopes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4817200000",
    "Description": "Letter cards, plain postcards and correspondence cards",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4817300000",
    "Description": "Boxes, pouches, wallets and writing compendiums, of paper",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4818100000",
    "Description": "Toilet paper",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4818200000",
    "Description": "Handkerchiefs, cleansing or facial tissues and towels",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4818300000",
    "Description": "Tablecloths and serviettes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4818500000",
    "Description": "Articles of apparel and clothing accessories",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4818900000",
    "Description": "Other toilet paper and similar paper of a kind used for household or sanitary purposes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4819100000",
    "Description": "Cartons, boxes and cases, of corrugated paper or paperboard",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4819201000",
    "Description": "Cartons and boxes lined with polypropylene film",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4819209000",
    "Description": "Other Folding cases, of noncorrugated paper or paperboard not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4819300000",
    "Description": "Sacks and bags, having a base of a width of 40 cm or more",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4819400000",
    "Description": "Other sacks and bags, including cones",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4819500000",
    "Description": "Other packing containers, including record sleeves",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4819600000",
    "Description": "Box files, letter trays, storage boxes and similar articl",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4820100000",
    "Description": "Registers, account books, note books, order books, receip",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4820200000",
    "Description": "Exercise books",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4820300000",
    "Description": "Binders (other than book covers), folders and file covers",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "4820400000",
    "Description": "Manifold business forms and interleaved carbon sets",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4820500000",
    "Description": "Albums for samples or for collections",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4820901000",
    "Description": "Other articles of stationery",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "4820909000",
    "Description": "Other similar articles of stationary not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "4821100000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 50,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4821900000",
    "Description": "Other Paper or paperboard labels of all kinds, not printed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4822100000",
    "Description": "Of a kind used for winding textile yarn",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "4822900000",
    "Description": "Other excluding used for winding textile yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4823200000",
    "Description": "Filter paper and paperboard",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "4823400000",
    "Description": "Rolls, sheets and dials, printed for selfrecording apparatus",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4823610000",
    "Description": "Of bamboo",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4823690000",
    "Description": "Other Trays, dishes, plates, cups and the like, of paper or paperboard not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4823700000",
    "Description": "Moulded or pressed articles of paper pulp",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4823900000",
    "Description": "Otherpaper,paperboard,cellulose wadding andOther",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "4901100000",
    "Description": "In single sheets, whether or not folded",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4901910000",
    "Description": "Dictionaries and encyclopaedias, and serial instalments thereof",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4901991000",
    "Description": "Scientific or school printed books, brochures, leaflets and similar printed matter",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4901999000",
    "Description": "Other Printed books, brochures, leaflets and similar p",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4902100000",
    "Description": "Appearing at least four times a week",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "4902900000",
    "Description": "Newspapers,journals and periodicals.... Other",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "4903000000",
    "Description": "Children's picture, drawing or colouring books.",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4904000000",
    "Description": "Music, printed or in manuscript, whether or not bound or illustrated.",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4905200000",
    "Description": "- In book form",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "4905900000",
    "Description": "- Other",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "4906000000",
    "Description": "Plans and drawings for architectural, engineering, industri",
    "SU": "KG",
    "ID": 0,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "4907000000",
    "Description": "Unused postage, revenue or similar stamps of current or new",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4908100000",
    "Description": "Transfers (decalcomanias), vitrifiable",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "4908900000",
    "Description": "Transfers(decalcomanias). Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4909000000",
    "Description": "Printed or illustrated postcards; printed cards bearing per",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4910000000",
    "Description": "Calendars of any kind, printed, including calendar blocks.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4911100000",
    "Description": "Trade advertising material, commercial catalogues and the like",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4911910000",
    "Description": "Pictures, designs and photographs",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4911991000",
    "Description": "Printed matter for administrative use",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4911999100",
    "Description": "Telephone recharge cards",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "4911999900",
    "Description": "Other printed matter, including printed pictures and ",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5001000000",
    "Description": "Silkworm cocoons suitable for reeling.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5002000000",
    "Description": "Raw silk (not thrown).",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5003000000",
    "Description": "Silk waste (including cocoons unsuitable for reeling, yarn waste and garnetted stock).",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5004000000",
    "Description": "Silk yarn (other than yarn spun from silk waste) not put up for retail sale.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5005000000",
    "Description": "Yarn spun from silk waste, not put up for retail sale.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5006000000",
    "Description": "Silk yarn and yarn spun from silk waste, put up for retail sale; silkworm gut.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5007100000",
    "Description": "Fabrics of noil silk",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5007200000",
    "Description": "Other fabrics, containing 85 % or more by weight of silk ",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5007900000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5101110000",
    "Description": "Shorn wool",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5101190000",
    "Description": "Wool, not carded or combed. Greasy, including fleecewashed wool: Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5101210000",
    "Description": "Shorn wool",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5101290000",
    "Description": "Wool, not carded or combed. Degreased, not carbonized: Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5101300000",
    "Description": "Carbonised",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5102110000",
    "Description": "Of Kashmir (cashmere) goats",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5102190000",
    "Description": "Fine or coarse animal hair, not carded or combed. Fine animal hair: Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5102200000",
    "Description": "Coarse animal hair",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5103100000",
    "Description": "Noils of wool or of fine animal hair",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5103200000",
    "Description": "Other waste of wool or of fine animal hair",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5103300000",
    "Description": "Waste of coarse animal hair",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5104000000",
    "Description": "Garnetted stock of wool or of fine or coarse animal hair.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5105100000",
    "Description": "Carded wool",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5105210000",
    "Description": "Combed wool in fragments",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5105290000",
    "Description": "Other wool tops and combed wool (excl. in fragments)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5105310000",
    "Description": "Of Kashmir (cashmere) goats",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5105390000",
    "Description": "Other fine animal hair, carded or combed",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5105400000",
    "Description": "Coarse animal hair, carded or combed",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5106100000",
    "Description": "Containing 85 % or more by weight of wool",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5106200000",
    "Description": "Containing less than 85 % by weight of wool",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5107100000",
    "Description": "Containing 85 % or more by weight of wool",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5107200000",
    "Description": "Containing less than 85 % by weight of wool",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5108100000",
    "Description": "Carded",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5108200000",
    "Description": "Combed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5109100000",
    "Description": "Containing 85 % or more by weight of wool or of fine animal hair",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5109900000",
    "Description": "Other Yarn of wool or fine animal hair, put up for retail sale",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5110000000",
    "Description": "Yarn of coarse animal hair or of horsehair (including gimpe",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5111110000",
    "Description": "Of a weight not exceeding 300 g/m2",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5111190000",
    "Description": "Woven Fabrics of carded wool or carded fine Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5111200000",
    "Description": "Other, mixed mainly or solely with manmade filaments",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5111300000",
    "Description": "Other, mixed mainly or solely with manmade staple fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5111900000",
    "Description": "Of a weight exceeding 300 g/m2",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5112110000",
    "Description": "Of a weight not exceeding 200 g/m2",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5112190000",
    "Description": "Woven fabrics of combed wool or of combed fine animal hair. Containing 85",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5112200000",
    "Description": "Other, mixed mainly or solely with manmade filaments",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5112300000",
    "Description": "Other, mixed mainly or solely with manmade staple fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5112900000",
    "Description": "Woven fabrics of combed wool orf combed fine animal hair. Containing 85%",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5113000000",
    "Description": "Woven fabrics of coarse animal hair or of horsehair.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5201001000",
    "Description": "Woven fabrics of coarse animal hair, Cotton, not carded or combed, Not ginned",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5201009000",
    "Description": "Woven fabrics of coarse animal hair, Cotton, not carded or combed, Ginned",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5202100000",
    "Description": "Yarn waste (including thread waste)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5202910000",
    "Description": "Garnetted stock",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5202990000",
    "Description": "Cotton waste (including yarn waste and... Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5203001000",
    "Description": "Carded",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5203002000",
    "Description": "Combed",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5204110000",
    "Description": "Containing 85 % or more by weight of cotton",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5204190000",
    "Description": "Cotton sewing thread, whether or not put... Other (Not pup up for retail sale)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5204200000",
    "Description": "Put up for retail sale",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205110000",
    "Description": "Measuring 714.29 decitex or more (not exceeding 14 metric number)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205120000",
    "Description": "Measuring less than 714.29 decitex but not less than 232",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205130000",
    "Description": "Measuring less than 232.56 decitex but not less than 192",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205140000",
    "Description": "Measuring less than 192.31 decitex but not less than 125",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205150000",
    "Description": "Measuring less than 125 decitex (exceeding 80 metric number)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205210000",
    "Description": "Measuring 714.29 decitex or more (not exceeding 14 metric number)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205220000",
    "Description": "Measuring less than 714.29 decitex but not less than 232",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205230000",
    "Description": "Measuring less than 232.56 decitex but not less than 192",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205240000",
    "Description": "Measuring less than 192.31 decitex but not less than 125",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205260000",
    "Description": "Measuring less than 125 decitex but not less than 106.38",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205270000",
    "Description": "Measuring less than 106.38 decitex but not less than 83.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205280000",
    "Description": "Measuring less than 83.33 decitex (exceeding 120 metric number)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205310000",
    "Description": "Measuring per single yarn 714.29 decitex or more (not ex",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205320000",
    "Description": "Measuring per single yarn less than 714.29 decitex but n",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205330000",
    "Description": "Measuring per single yarn less than 232.56 decitex but n",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205340000",
    "Description": "Measuring per single yarn less than 192.31 decitex but n",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205350000",
    "Description": "Measuring per single yarn less than 125 decitex (exceedi",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205410000",
    "Description": "Measuring per single yarn 714.29 decitex or more (not ex",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205420000",
    "Description": "Measuring per single yarn less than 714.29 decitex but n",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205430000",
    "Description": "Measuring per single yarn less than 232.56 decitex but n",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205440000",
    "Description": "Measuring per single yarn less than 192.31 decitex but n",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205460000",
    "Description": "Measuring per single yarn less than 125 decitex but not ",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205470000",
    "Description": "Measuring per single yarn less than 106.38 decitex but n",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5205480000",
    "Description": "Measuring per single yarn less than 83.33 decitex (excee",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206110000",
    "Description": "Measuring 714.29 decitex or more (not exceeding 14 metric number)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206120000",
    "Description": "Measuring less than 714.29 decitex but not less than 232",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206130000",
    "Description": "Measuring less than 232.56 decitex but not less than 192",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206140000",
    "Description": "Measuring less than 192.31 decitex but not less than 125",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206150000",
    "Description": "Measuring less than 125 decitex (exceeding 80 metric number)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206210000",
    "Description": "Measuring 714.29 decitex or more (not exceeding 14 metric number)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206220000",
    "Description": "Measuring less than 714.29 decitex but not less than 232",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206230000",
    "Description": "Measuring less than 232.56 decitex but not less than 192",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206240000",
    "Description": "Measuring less than 192.31 decitex but not less than 125",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206250000",
    "Description": "Measuring less than 125 decitex (exceeding 80 metric number)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206310000",
    "Description": "Measuring per single yarn 714.29 decitex or more (not ex",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206320000",
    "Description": "Measuring per single yarn less than 714.29 decitex but n",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206330000",
    "Description": "Measuring per single yarn less than 232.56 decitex but n",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206340000",
    "Description": "Measuring per single yarn less than 192.31 decitex but n",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206350000",
    "Description": "Measuring per single yarn less than 125 decitex (exceedi",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206410000",
    "Description": "Measuring per single yarn 714.29 decitex or more (not ex",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206420000",
    "Description": "Measuring per single yarn less than 714.29 decitex but n",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206430000",
    "Description": "Measuring per single yarn less than 232.56 decitex but n",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206440000",
    "Description": "Measuring per single yarn less than 192.31 decitex but n",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5206450000",
    "Description": "Measuring per single yarn less than 125 decitex (exceedi",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5207100000",
    "Description": "Containing 85 % or more by weight of cotton",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5207901000",
    "Description": "Fishing yarn",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5207909000",
    "Description": "Other cotton yarn not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208110000",
    "Description": "Plain weave, weighing not more than 100 g/m2",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208120000",
    "Description": "Plain weave, weighing more than 100 g/m2",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208130000",
    "Description": "3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208190000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208210000",
    "Description": "Plain weave, weighing not more than 100 g/m2",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208220000",
    "Description": "Plain weave, weighing more than 100 g/m2",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208230000",
    "Description": "3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208291000",
    "Description": "Dimity, damasks and the like",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208299000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208310000",
    "Description": "Plain weave, weighing not more than 100 g/m2",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208320000",
    "Description": "Plain weave, weighing more than 100 g/m2",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208330000",
    "Description": "3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208391000",
    "Description": "Dimity, damasks and the like",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208399000",
    "Description": "Other dyed fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208410000",
    "Description": "Plain weave, weighing not more than 100 g/m2",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208420000",
    "Description": "Plain weave, weighing more than 100 g/m2",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208430000",
    "Description": "3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208490000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208511000",
    "Description": "Cotton yarn excl sew.. cont. < 85 % by wgt of cotton, Obt. by waxbased printing process",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208519000",
    "Description": "Cotton yarn excl sew.. cont. < 85 % by wgt of cotton, Obt. by other printing process",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208521000",
    "Description": "Cotton yarn excl sew. cont. < 85 % by wgt of cotton, Obt. by waxbased printing process",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208529000",
    "Description": "Cotton yarn excl sew.. cont. < 85 % by wgt of cotton, Obtained by other printing process",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5208590000",
    "Description": "Other fabrics nes",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209110000",
    "Description": "Plain weave",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209120000",
    "Description": "3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209190000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209210000",
    "Description": "Plain weave",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209220000",
    "Description": "3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209291000",
    "Description": "Dimity, damasks and the like",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209299000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209310000",
    "Description": "Plain weave",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209320000",
    "Description": "3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209391000",
    "Description": "Dimity, damasks and the like",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209399000",
    "Description": "Other Dyed fabric, Not specified or included in this heading.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209410000",
    "Description": "Plain weave",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209420000",
    "Description": "Denim",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209430000",
    "Description": "Other fabrics of 3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209490000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209511000",
    "Description": "Obtained by waxbased printing process",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209519000",
    "Description": "Obtained by other printing process",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209520000",
    "Description": "3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5209590000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5210110000",
    "Description": "Plain weave",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5210190000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5210210000",
    "Description": "Plain weave",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5210290000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5210310000",
    "Description": "Plain weave",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5210320000",
    "Description": "3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5210390000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5210410000",
    "Description": "Plain weave",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5210490000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5210511000",
    "Description": "Obtained by waxbased printing process",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5210519000",
    "Description": "Obtained by other printing process",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5210590000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211110000",
    "Description": "Plain weave",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211120000",
    "Description": "3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211190000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211200000",
    "Description": "Bleached",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211310000",
    "Description": "Plain weave",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211320000",
    "Description": "3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211390000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211410000",
    "Description": "Plain weave",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211420000",
    "Description": "Denim",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211430000",
    "Description": "Other fabrics of 3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211490000",
    "Description": "Other fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211511000",
    "Description": "Obtained by waxbased printing process",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211519000",
    "Description": "Obtained by other printing process",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211520000",
    "Description": "3thread or 4thread twill, including cross twill",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5211590000",
    "Description": "Other fabrics nes",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5212110000",
    "Description": "Other woven fabrics of cotton Weighing not more than 200 g/m2; Unbleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5212120000",
    "Description": "Other woven fabrics of cotton Weighing not more than 200 g/m2; Bleached",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5212130000",
    "Description": "Other woven fabrics of cotton Weighing not more than 200 g/m2; Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5212140000",
    "Description": "Other woven fabrics of cotton Weighing =<200 g/m2; Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5212150000",
    "Description": "Other woven fabrics of cotton Weighing not more than 200 g/m2; Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5212210000",
    "Description": "Other woven fabrics of cotton Weighing more than 200 g/m2; Unbleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5212220000",
    "Description": "Other woven fabrics of cotton Weighing more than 200 g/m2; Bleached",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5212230000",
    "Description": "Other woven fabrics of cotton Weighing more than 200 g/m2; Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5212240000",
    "Description": "Other woven fabrics of cotton Weighing more than 200 g/m2; Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5212250000",
    "Description": "Other woven fabrics of cotton Weighing more than 200 g/m2; Printed",
    "SU": "KG",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5301100000",
    "Description": "Flax, raw or retted",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5301210000",
    "Description": "Broken or scutched",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5301290000",
    "Description": "Flax, raw or processed but not spun;... Other (Flax, broken, scotched..)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5301300000",
    "Description": "Flax tow and waste",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5302100000",
    "Description": "True hemp, raw or retted",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5302900000",
    "Description": "Other tow and waste of true hemp (including yarn waste and garnetted stock).",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5303100000",
    "Description": "Jute and other textile bast fibres, raw or retted",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5303900000",
    "Description": "Other Jute and other textile bast fibres not specified.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5305000000",
    "Description": "Coconut, abaca (Manila hemp or Musa textilis Nee), ramie an",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5306100000",
    "Description": "Single",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5306200000",
    "Description": "Multiple (folded) or cabled",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5307100000",
    "Description": "Single",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5307200000",
    "Description": "Multiple (folded) or cabled",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "5308100000",
    "Description": "Coir yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5308200000",
    "Description": "True hemp yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5308901000",
    "Description": "Sisal yarn or of other vegetable textile fibres of the genus Agave",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5308909000",
    "Description": "Other paper yarn not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5309110000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5309190000",
    "Description": "Other woven fabrics of flax containing 85% or more by weight of flax",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5309210000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5309290000",
    "Description": "Other woven fabrics of flax, containing less than 85% by weight of flax",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5310100000",
    "Description": "Unbleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5310900000",
    "Description": "Other woven fabrics of jute or other textile bast fibres (excl. unbleached)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5311000000",
    "Description": "Woven fabrics of other vegetable textile fibres; woven fabrics of paper yarn.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5401100000",
    "Description": "Of synthetic filaments",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5401201000",
    "Description": "Put up for retail sale",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5401209000",
    "Description": "Other sewing thread.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402110000",
    "Description": "High tenacity yarn of nylon or other polyamides : Of aramids",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402191000",
    "Description": "High tenacity yarn of nylon or other polyamides : Of nylon",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402199000",
    "Description": "Other High tenacity yarn of nylon or other polyamides,",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402200000",
    "Description": "High tenacity yarn of polyesters",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402310000",
    "Description": "Of nylon or other polyamides, measuring per single yarn not more than 50 tex",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402320000",
    "Description": "Of nylon or other polyamides, measuring per single yarn more than 50 tex",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402330000",
    "Description": "Textured yarn : Of polyesters",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402340000",
    "Description": "Textured yarn : Of polypropylene",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402390000",
    "Description": "Other Textured yarn not specified or mentioned.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402440000",
    "Description": "Elastomeric",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402450000",
    "Description": "Other, of nylon or other polyamides",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402460000",
    "Description": "Other, of polyesters, partially oriented",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402470000",
    "Description": "Other, of polyesters",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402480000",
    "Description": "Other, of polypropylene",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402490000",
    "Description": "Other yarn, single, untwisted or with a twist not exceed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402510000",
    "Description": "Of nylon or other polyamides",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402520000",
    "Description": "Of polyesters",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402530000",
    "Description": "Of polypropylene",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402590000",
    "Description": "Other yarn not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402610000",
    "Description": "Of nylon or other polyamides",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402620000",
    "Description": "Of polyesters",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402630000",
    "Description": "Of polypropylene",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5402690000",
    "Description": "Other yarn multiple (folded) or cabled not elsewhere specified in this sub heading",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5403100000",
    "Description": "High tenacity yarn of viscose rayon",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5403310000",
    "Description": "Of viscose rayon, untwisted or with a twist not exceeding 120 turns per metre",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5403320000",
    "Description": "Of viscose rayon, with a twist exceeding 120 turns per metre",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5403330000",
    "Description": "Of cellulose acetate",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "5403390000",
    "Description": "Other yarn, single not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5403410000",
    "Description": "Of viscose rayon",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5403420000",
    "Description": "Of cellulose acetate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5403490000",
    "Description": "Multiple or cabled artificial yarn, nes, not put up for retail sale",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5404110000",
    "Description": "Elastomeric",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "5404120000",
    "Description": "Other, of polypropylene",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "5404190000",
    "Description": "Other Monofilament not specified.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "5404900000",
    "Description": "Synthetic monofilament of >=67 decitex... Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5405000000",
    "Description": "Artificial monofilament of 67 decitex or more and of which ",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5406000000",
    "Description": "Manmade filament yarn (other than sewing thread), put up for retail sale.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407100000",
    "Description": "Woven fabrics obtained from high tenacity yarn of nylon o",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407201000",
    "Description": "Polypropylene fabrics, of the type used as carpet backing",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "5407209000",
    "Description": "Other woven fabrics not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407300000",
    "Description": "Fabrics specified in Note 9 to Section XI",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407410000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407420000",
    "Description": "Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407430000",
    "Description": "Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407440000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407510000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407520000",
    "Description": "Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407530000",
    "Description": "Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407540000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407610000",
    "Description": "Containing 85 % or more by weight of nontextured polyester filaments",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407690000",
    "Description": "OTHER WOVEN FABRICS OF SYNTH C YARN >=85% TEXTRD/NONTEXTRD POLYSTR FILMN",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407710000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407720000",
    "Description": "Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407730000",
    "Description": "Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407740000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407810000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407820000",
    "Description": "Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407830000",
    "Description": "Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407840000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407910000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407920000",
    "Description": "Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407930000",
    "Description": "Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5407940000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5408100000",
    "Description": "Woven fabrics obtained from high tenacity yarn of viscose rayon",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5408210000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5408220000",
    "Description": "Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5408230000",
    "Description": "Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5408240000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5408310000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5408320000",
    "Description": "Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5408330000",
    "Description": "Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5408340000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5501110000",
    "Description": "-- Of aramids",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "5501190000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "5501200000",
    "Description": "Of polyesters",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "5501300000",
    "Description": "Acrylic or modacrylic",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "5501400000",
    "Description": "Of polypropylene",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5501900000",
    "Description": "Synthetic filament tow. Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5502100000",
    "Description": "Artifical filament tow - of cellulose acetate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5502900000",
    "Description": "Other Artifical filament tow",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5503110000",
    "Description": "Of aramids",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5503190000",
    "Description": "Other; Of nylon or other polyamides not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5503200000",
    "Description": "Of polyesters",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "5503300000",
    "Description": "Acrylic or modacrylic",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "5503400000",
    "Description": "Of polypropylene",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5503900000",
    "Description": "Other tow and waste of true hemp (including yarn waste and garnetted stock).",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5504100000",
    "Description": "Of viscose rayon",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5504900000",
    "Description": "Artifial staple fibres, not carded, co.. Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5505100000",
    "Description": "Of synthetic fibres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5505200000",
    "Description": "Of artificial fibres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5506100000",
    "Description": "Of nylon or other polyamides",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5506200000",
    "Description": "Of polyesters",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "5506300000",
    "Description": "Acrylic or modacrylic",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "5506400000",
    "Description": "Of polypropylene",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5506900000",
    "Description": "Other Synthetic staple fibres, carded, combed or otherwis",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "5507000000",
    "Description": "Artificial staple fibres, carded, combed or otherwise processed for spinning.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5508100000",
    "Description": "Of synthetic staple fibres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5508200000",
    "Description": "Of artificial staple fibres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509110000",
    "Description": "Single yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509120000",
    "Description": "Multiple (folded) or cabled yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509210000",
    "Description": "Single yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "5509220000",
    "Description": "Multiple (folded) or cabled yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509310000",
    "Description": "Single yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509320000",
    "Description": "Multiple (folded) or cabled yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509410000",
    "Description": "Single yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509420000",
    "Description": "Multiple (folded) or cabled yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509510000",
    "Description": "Mixed mainly or solely with artificial staple fibres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509520000",
    "Description": "Mixed mainly or solely with wool or fine animal hair",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509530000",
    "Description": "Mixed mainly or solely with cotton",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509590000",
    "Description": "Yarn (other than sewing thread) of synth Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509610000",
    "Description": "Mixed mainly or solely with wool or fine animal hair",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509620000",
    "Description": "Mixed mainly or solely with cotton",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509690000",
    "Description": "Yarn (other than sewing thread) of synth Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509910000",
    "Description": "Mixed mainly or solely with wool or fine animal hair",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509920000",
    "Description": "Mixed mainly or solely with cotton",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5509990000",
    "Description": "Yarn (other than sewing thre) of synth Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5510110000",
    "Description": "Single yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5510120000",
    "Description": "Multiple (folded) or cabled yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5510200000",
    "Description": "Other yarn, mixed mainly or solely with wool or fine animal hair",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5510300000",
    "Description": "Other yarn, mixed mainly or solely with cotton",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5510900000",
    "Description": "Other yarn",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5511100000",
    "Description": "Of synthetic staple fibres, containing 85 % or more by weight of such fibres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5511200000",
    "Description": "Of synthetic staple fibres, containing less than 85 % by weight of such fibres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5511300000",
    "Description": "Of artificial staple fibres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5512110000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5512191000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5512199000",
    "Description": "Other (ecl. Printed) fabrics >=85% by weight of polyester staple fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5512210000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5512291000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5512299000",
    "Description": "OtherWoven fabrics of heading 55.12, Containing 85% or",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5512910000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5512991000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5512999000",
    "Description": "Other non specified Woven fabrics type of heading 55.12, Not printed.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5513110000",
    "Description": "Of polyester staple fibres, plain weave",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5513120000",
    "Description": "3thread or 4thread twill, including cross twill, of polyester staple fibres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5513130000",
    "Description": "Other woven fabrics of polyester staple fibres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5513190000",
    "Description": "Other woven fabrics",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5513210000",
    "Description": "Of polyester staple fibres, plain weave",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5513230000",
    "Description": "Other woven fabrics of polyester staple fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5513290000",
    "Description": "Other woven fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5513310000",
    "Description": "Of polyester staple fibres, plain weave",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5513390000",
    "Description": "Other woven fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5513410000",
    "Description": "Of polyester staple fibres, plain weave",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5513490000",
    "Description": "Other woven fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5514110000",
    "Description": "Of polyester staple fibres, plain weave",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5514120000",
    "Description": "3thread or 4thread twill, including cross twill, of polyester staple fibres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5514190000",
    "Description": "Other woven fabrics",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5514210000",
    "Description": "Of polyester staple fibres, plain weave",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5514220000",
    "Description": "3thread or 4thread twill, including cross twill, of polyester staple fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5514230000",
    "Description": "Other woven fabrics of polyester staple fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5514290000",
    "Description": "Other woven fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5514300000",
    "Description": "Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5514410000",
    "Description": "Of polyester staple fibres, plain weave",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5514420000",
    "Description": "3thread or 4thread twill, including cross twill, of polyester staple fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5514430000",
    "Description": "Other woven fabrics of polyester staple fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5514490000",
    "Description": "Other woven fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5515110000",
    "Description": "Mixed mainly or solely with viscose rayon staple fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5515120000",
    "Description": "Mixed mainly or solely with manmade filaments",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5515130000",
    "Description": "Mixed mainly or solely with wool or fine animal hair",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5515190000",
    "Description": "Other woven fabrics of synthetic staple Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5515210000",
    "Description": "Mixed mainly or solely with manmade filaments",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5515220000",
    "Description": "Mixed mainly or solely with wool or fine animal hair",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5515290000",
    "Description": "Other woven fabrics of synthic staple Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5515910000",
    "Description": "Mixed mainly or solely with manmade filaments",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5515990000",
    "Description": "Other woven fabrics of synthetic staple.. Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516110000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516120000",
    "Description": "Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516130000",
    "Description": "Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516140000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516210000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516220000",
    "Description": "Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516230000",
    "Description": "Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516240000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516310000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516320000",
    "Description": "Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516330000",
    "Description": "Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516340000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516410000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516420000",
    "Description": "Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516430000",
    "Description": "Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516440000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516910000",
    "Description": "Unbleached or bleached",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516920000",
    "Description": "Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516930000",
    "Description": "Of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5516940000",
    "Description": "Printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5601210000",
    "Description": "Of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5601220000",
    "Description": "Of manmade fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5601290000",
    "Description": "Wadding of textile materials and article.. Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5601300000",
    "Description": "Textile flock and dust and mill neps",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5602100000",
    "Description": "Needleloom felt and stitchbonded fibre fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5602210000",
    "Description": "Of wool or fine animal hair",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5602290000",
    "Description": "Of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5602900000",
    "Description": "Felt, whether or not impregnated, coated Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5603110000",
    "Description": "Weighing not more than 25 g/m2",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "5603120000",
    "Description": "Weighing more than 25 g/m2 but not more than 70 g/m2",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "5603130000",
    "Description": "Weighing more than 70 g/m2 but not more than 150 g/m2",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "5603140000",
    "Description": "Weighing more than 150 g/m2",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "5603910000",
    "Description": "Weighing not more than 25 g/m2",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "5603920000",
    "Description": "Weighing more than 25 g/m2 but not more than 70 g/m2",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "5603930000",
    "Description": "Weighing more than 70 g/m2 but not more than 150 g/m2",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "5603940000",
    "Description": "Weighing more than 150 g/m2",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "5604100000",
    "Description": "Rubber thread and cord, textile covered",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5604900000",
    "Description": "Rubber thread and cord, textile covered; Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5605000000",
    "Description": "Metallised yarn, whether or not gimped, being textile yarn,",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5606000000",
    "Description": "Gimped yarn, and strip and the like of heading 54.04 or 54.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5607210000",
    "Description": "Binder or baler twine",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5607290000",
    "Description": "Other; Of sisal or other textile fibres of the genus Agave",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5607410000",
    "Description": "Binder or baler twine",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5607490000",
    "Description": "Other Twine, cordage, ropes and cables, whether or not p",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5607500000",
    "Description": "Of other synthetic fibres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5607900000",
    "Description": "Other excluding Binder or baler twine",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5608110000",
    "Description": "Made up fishing nets",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5608190000",
    "Description": "Other Knotted netting of twine, cordage or rope; made up",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5608901000",
    "Description": "Made up fishing nets",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5608909000",
    "Description": "Other knotted netting twine not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5609000000",
    "Description": "Articles of yarn, strip or the like of heading 54.04 or 54.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5701100000",
    "Description": "Of wool or fine animal hair",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5701900000",
    "Description": "Of other textile materials",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5702100000",
    "Description": "\"Kelem\", \"Schumacks\", \"Karamanie\" and similar handwoven rugs",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5702200000",
    "Description": "Floor coverings of coconut fibres (coir)",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5702310000",
    "Description": "Of wool or fine animal hair",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5702320000",
    "Description": "Of manmade textile materials",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5702390000",
    "Description": "Of other textile materials",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5702410000",
    "Description": "Of wool or fine animal hair",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5702420000",
    "Description": "Of manmade textile materials",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5702490000",
    "Description": "Of other textile materials",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5702500000",
    "Description": "Other, not of pile construction, not made up",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5702910000",
    "Description": "Of wool or fine animal hair",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5702920000",
    "Description": "Of manmade textile materials",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5702990000",
    "Description": "Of other textile materials",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5703100000",
    "Description": "Of wool or fine animal hair",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5703210000",
    "Description": "-- Turf",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "5703290000",
    "Description": "-- Other",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "5703310000",
    "Description": "-- Turf",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "5703390000",
    "Description": "-- Other",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "5703900000",
    "Description": "Of other textile materials",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5704100000",
    "Description": "Tiles, having a maximum surface area of 0.3 m2",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5704200000",
    "Description": "- Tiles, having a maximum surface area exceeding 0.3 sqm but not exceeding 1 m2",
    "ID": 20,
    "DATE OF VALIDITY": "22/06/2020"
  },
  {
    "CET Code": "5704900000",
    "Description": "Other Carpets and textile floor coverings, of felt, not tufted or flocked....",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5705000000",
    "Description": "Other carpets and other textile floor coverings, whether or not made up.",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5801100000",
    "Description": "Of wool or fine animal hair",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5801210000",
    "Description": "Uncut weft pile fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5801220000",
    "Description": "Cut corduroy",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5801230000",
    "Description": "Other weft pile fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5801260000",
    "Description": "Chenille fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5801270000",
    "Description": "Warp pile fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5801310000",
    "Description": "Uncut weft pile fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5801320000",
    "Description": "Cut corduroy",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5801330000",
    "Description": "Other weft pile fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5801360000",
    "Description": "Chenille fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5801370000",
    "Description": "Warp pile fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5801900000",
    "Description": "Of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5802100000",
    "Description": "- Terry towelling and similar woven terry fabrics, of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "5802200000",
    "Description": "Terry towelling and similar woven terry fabrics, of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5802300000",
    "Description": "Tufted textile fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5803001000",
    "Description": "Gauze,excl. narrow fabrics of heading 58.06. Of polypropylene, used as carpet backing",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "5803009000",
    "Description": "Other Gauze, other than narrow fabrics of heading 58.06 not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5804100000",
    "Description": "Tulles and other net fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5804210000",
    "Description": "Of manmade fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5804290000",
    "Description": "Of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5804300000",
    "Description": "Handmade lace",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5805000000",
    "Description": "Handwoven tapestries of the type Gobelins, Flanders, Aubus",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5806100000",
    "Description": "Woven pile fabrics (including terry towelling and similar",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5806200000",
    "Description": "Other woven fabrics, containing by weight 5 % or more of ",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5806310000",
    "Description": "Of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5806320000",
    "Description": "Of manmade fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5806390000",
    "Description": "Of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5806400000",
    "Description": "Fabrics consisting of warp without weft assembled by means of an adhesive (bolducs)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5807100000",
    "Description": "Woven",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5807900000",
    "Description": "Labels, badges and similar arties... Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5808100000",
    "Description": "Braids in the piece",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5808900000",
    "Description": "Braids in the piece; ornamental trimms.. Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5809000000",
    "Description": "Woven fabrics of metal thread and woven fabrics of metallis",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5810100000",
    "Description": "Embroidery without visible ground",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5810910000",
    "Description": "Of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5810920000",
    "Description": "Of manmade fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5810990000",
    "Description": "Of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5811000000",
    "Description": "Quilted textile products in the piece, composed of one or m",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5901100000",
    "Description": "Textile fabrics coated with gum or amylaceous substances,",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5901900000",
    "Description": "Textile fabrics coated with gum or amylaceous substances, of a kind used",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5902100000",
    "Description": "Of nylon or other polyamides",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5902200000",
    "Description": "Of polyesters",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5902900000",
    "Description": "Tyre cord fabric of high tenacity yarn.. Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5903100000",
    "Description": "With poly(vinyl chloride)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5903200000",
    "Description": "With polyurethane",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5903900000",
    "Description": "Textile fabrics impregnated, coated... Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5904100000",
    "Description": "Linoleum",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5904900000",
    "Description": "Linoleum, whether or not cut to shape;.. Other",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5905000000",
    "Description": "Textile wall coverings.",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "5906100000",
    "Description": "Adhesive tape of a width not exceeding 20 cm",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5906910000",
    "Description": "Knitted or crocheted",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5906990000",
    "Description": "Other rubberised textile fabrics, nes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5907000000",
    "Description": "Textile fabrics otherwise impregnated, coated or covered; p",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5908000000",
    "Description": "Textile wicks, woven, plaited or knitted, for lamps, stove",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5909000000",
    "Description": "Textile hosepiping and similar textile tubing, with or with",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5910000000",
    "Description": "Transmission or conveyor belts or belting, of textile mater",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5911100000",
    "Description": "Textile fabrics, felt and feltlined woven fabrics, coate",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5911200000",
    "Description": "Bolting cloth, whether or not made up",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5911310000",
    "Description": "Weighing less than 650 g/m2",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5911320000",
    "Description": "Weighing 650 g/m2 or more",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5911400000",
    "Description": "Straining cloth of a kind used in oil presses or the like, including that of human hair",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "5911900000",
    "Description": "Textile products and artic, for techn... Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6001100000",
    "Description": "\"Long pile\" fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6001210000",
    "Description": "Long pile fabrics of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6001220000",
    "Description": "Long pile fabrics of man-made fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6001290000",
    "Description": "Long pile fabrics of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6001910000",
    "Description": "Long pile fabrics of other textile materials of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6001920000",
    "Description": "Long pile fabrics of textile materials of man-made fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6001990000",
    "Description": "Long pile fabrics of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6002400000",
    "Description": "Containing by weight 5 % or more of elastomeric yarn but not containing rubber thread",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "6002900000",
    "Description": "Other fabrics, capes, <=30 cm other than those of pos 60.01",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6003100000",
    "Description": "Other fabrics of wool or fine animal hair",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6003200000",
    "Description": "Other fabrics of cotton <=30 cm excl of 60.01 and 60.02, of wool of fine animal hair",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6003300000",
    "Description": "Fabrics of synthetic fibres <=30 cm excl of 60.01 and 60.02",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6003400000",
    "Description": "Of artificial fibres <=30 cm excl of 60.01 and 60.02",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6003900000",
    "Description": "Other knitted or crocheted fabrics of a width c other than those of 60.01 or 60.02",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6004100000",
    "Description": "Containing by weight 5 % or more of elastomeric yarn but not containing rubber thread",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6004900000",
    "Description": "Other knitted or crocheted fabr. >30 cm excl those in pos 60.01",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005210000",
    "Description": "Unbleached or bleached excl those of chapt 60.01 to 60.04",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005220000",
    "Description": "Dyed, excl those of chapt 60.01 to 60.04",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005230000",
    "Description": "Of yarns of different colours excl those of pos 60.01 to 60.04",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005240000",
    "Description": "Printed yarns excl those of pos 60.01 to 60.04",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005350000",
    "Description": "Fabrics specified in Subheading Note 1 to this Chapter",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005360000",
    "Description": "Other, unbleached or bleached",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005370000",
    "Description": "Other, dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005380000",
    "Description": "Other, of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005390000",
    "Description": "Other, printed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005410000",
    "Description": "Fabrics of unbleached or bleached excl those of pos 60.01 to 60.04",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005420000",
    "Description": "Dyed fabrics excl those of pos 60.01 to 60.04",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005430000",
    "Description": "Of yarns of different colours excl those pos no 60.01 a 60.04",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005440000",
    "Description": "Printed fabrics excl those of pos 60.01 to 60.40",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6005900000",
    "Description": "Warp knit fabrics (incl thos made on gallon knitting mach) excl those of 60.01 to 60.04",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006100000",
    "Description": "Fabrics of wool or fine animal hair",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006210000",
    "Description": "Unbleached or bleached fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006220000",
    "Description": "Other dyed fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006230000",
    "Description": "Other fabrics of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006240000",
    "Description": "Other printed fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006310000",
    "Description": "Other printed fabrics unbleached or bleached",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006320000",
    "Description": "Other Dyed synthetic fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006330000",
    "Description": "Other fabrics of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006340000",
    "Description": "Other printed synthetic fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006410000",
    "Description": "Other fabrics of artificial fibre Unbleached or bleached",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006420000",
    "Description": "Other fabrics of artificial fibre, Dyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006430000",
    "Description": "Other fabrics of yarns of different colours",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006440000",
    "Description": "Other printed fabrics of yarns",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6006900000",
    "Description": "Other knitted or crocheted fabrics. (Of artificial fibres) NES",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6101200000",
    "Description": "Men's or boy's suits of cotton, excl those of pos 61.03, knitted or crocheted",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6101300000",
    "Description": "Men's or boy's suits of man-made fibres, synth excl those of pos 61.03, knit or croch",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6101900000",
    "Description": "Men's or boy's suits of other textile materials excl those of 61.03, knit or croch",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6102100000",
    "Description": "Women's or girl's suitsof wool or fine ani hair, excl those of pos 61.04, knit or croch",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6102200000",
    "Description": "Women's or girl's suits of cotton, excl those of pos 61.04, knit or croch",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6102300000",
    "Description": "Women's or girl's suits of man-made synth ot artif fibres, excl those of pos 61.04",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6102900000",
    "Description": "Women's or girl's suits of other textile mat excl those of pos 61.04, knit or croch",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6103100000",
    "Description": "Men's or boy's suits (other than swimwear), knitted or crocheted",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6103220000",
    "Description": "Men's or boy's suits (other than swimwear) of cotton, knit or croch",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6103230000",
    "Description": "Men's or boy's suits (other than swimwear) of synthetic fibres, knitted or crocheted",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6103290000",
    "Description": "Men's or boy's suits (other than swimwear) of other textile materials, knit or croch",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6103310000",
    "Description": "Men's or boy's suits (other than swimwear) of wool or fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6103320000",
    "Description": "Men's or boy's suits (other than swimwear) of cotton, knit or croch",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6103330000",
    "Description": "Men's or boy's suits (other than swimwear) of synthetic fibres, knit or croch",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6103390000",
    "Description": "Men's or boy's suits (other than swimwear) of other textile materials,knit or croch",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6103410000",
    "Description": "Men's or boy's suits (other than swimwear) of wool or fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6103420000",
    "Description": "Men's or boy's suits (other than swimwear) of cotton, knit or croch",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6103430000",
    "Description": "Men's or boy's suits (other than swimwear) of synthetic fibres, knit or croch",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6103490000",
    "Description": "Men's or boy's suits (other than swimwear) of other textile materials,",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104130000",
    "Description": "Women's or girl's suits of (excl swimwear) of synthetic fibres knited or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104190000",
    "Description": "Women or girls suits other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104220000",
    "Description": "Women's or girl's suits of (excl swimwear) of Ensembles made of cotton, knit or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104230000",
    "Description": "Women's or girl's suits of (excl swimwear) of or girls Ensembles of synth fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104290000",
    "Description": "Women's or girl's suits(excl swimwear) of Ensembles of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104310000",
    "Description": "Women's or girl's suits (excl swimwear) of ... wool or fine ani hair, knit or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104320000",
    "Description": "Women's or girl's suits (excl swimwear)of Jackets and blazers of cotton; knit or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104330000",
    "Description": "Women's or girl's suits  (excl swimwear) Jackets and blazers made of synth fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104390000",
    "Description": "Women's or girls's Jackets and blazers of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104410000",
    "Description": "Women's or girl's suits (excl swimwear) of wool or fine animal hair, knit or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104420000",
    "Description": "Women's or girls's dresses (excl swimwear) made of cotton, knit or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104430000",
    "Description": "Women's or girl's suits  (excl swimwear) synthetic fibres, knit or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104440000",
    "Description": "Women's or girl'ss dresses made of artificial fibres knited or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104490000",
    "Description": "Women's or girl's suits (excl swimwear)women or girls Dresses of other text mat",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104510000",
    "Description": "Women's or girls's Skirts, div skirts made of wool or fine animal hair knit or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104520000",
    "Description": "Women's or girl's suits, skirts,(excl swimwear) and div skirts of cotton, knit or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104530000",
    "Description": "Women's or girls's Skirts, div skirts of synthetic fibres, knited or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104590000",
    "Description": "Women's or girl's suits, skirts(excl swimwear) div skirts, textile mat; knit or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104610000",
    "Description": "Women's or girls's suits  ( excl swimwear) of wool or fine anim hair, knit or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104620000",
    "Description": "Women or girls suits  ( excl swimwear) of cotton, knited or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104630000",
    "Description": "Women's or girls 's suits... (excl swimwear)of synth fibre knited or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6104690000",
    "Description": "Women's or girl's suits  (excl swimwear) ",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6105100000",
    "Description": "Men's or boys' shirts, knitted or crocheted made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6105200000",
    "Description": "Men's or boys' shirts, knitted or crocheted made of manmade fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6105900000",
    "Description": "Men's or boys' shirts, knitted or crocheted made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6106100000",
    "Description": "Women's or girls' blouses ... knitted or croch made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6106200000",
    "Description": "Women's or girls' blouses... knit or croch made of man_made fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6106900000",
    "Description": "Women's or girls' blousesknitor croch of other text mat",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6107110000",
    "Description": "Men's or boys' underpants, briefs made of cotton; knited or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6107120000",
    "Description": "Men's or boys' underpants, briefs made of manmade fibres, knited or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6107190000",
    "Description": "Men's or boys' underpants, briefs made of other textile materials, knited or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6107210000",
    "Description": "Men's or boys' Nightshirts and pyjamas made of cotton, knited or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6107220000",
    "Description": "Men's or boys' Nightshirts and pyjamas made of man-made fibres, knit or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6107290000",
    "Description": "Men's or boys' Nightshirts and pyjamas made of other textile mat, knit or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6107910000",
    "Description": "Other Men's or boys' clothings made of cotton, knited or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6107990000",
    "Description": "Other Men's or boys' clothings of other textile mat, knit or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6108110000",
    "Description": "Women's or girls' Slips and petticoats of man-made fibres, knited or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6108190000",
    "Description": "Women's or girls Slips and petticoats other textile materials, knited or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6108210000",
    "Description": "Women's or girl's Briefs and panties of cotton, knited or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6108220000",
    "Description": "Women's or girl's Briefs and panties of manmade fibres, knited or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6108290000",
    "Description": "Women's or girl's Briefs and panties of other textile materials, knited or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6108310000",
    "Description": "Women's or girls Nightdresses and pyjamas of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6108320000",
    "Description": "Women's or girl's Nightdresses and pyjamas of man-made fibres, knited or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6108390000",
    "Description": "Women's or girl's Nightdresses and pyjamas of other textile material, knited or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6108910000",
    "Description": "Women's or girl's clothings of cotton not specified, knited or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6108920000",
    "Description": "Women's or girl's clothings of man-made fibre not specified, knitd or croached",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6108990000",
    "Description": "Women's or girl's clothings, not specified of other textile materials, knit or croach",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6109100000",
    "Description": "T-shirts, singlets and other vests, knitted or crocheted made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6109900000",
    "Description": "T-shirts, singlets and other vests, knitted or crocheted made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6110110000",
    "Description": "Jerseys, pullovers... and similar articles, knitted or crocheted made of wool",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6110120000",
    "Description": "Jerseys, pullovers...and similar articles, knitted or crocheted made of Kashmir goats",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6110190000",
    "Description": "Jerseys, pullovers, cardigans, waistcoats and similar articules, knitted",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6110200000",
    "Description": "Other Jerseys, pullovers... and similar articles, made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6110300000",
    "Description": "Other Jerseys, pullovers... and similar articles, made fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6110900000",
    "Description": "Other Jerseys... similar articles made of textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6111200000",
    "Description": "Babies' garments and clothing accessories, knitted or crocheted made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "6111300000",
    "Description": "Babies' garments and clothing accessories, knitted or crocheted made of synthetic fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "6111900000",
    "Description": "Babies' garments and clothing accessories, made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "6112110000",
    "Description": "Track suits made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6112120000",
    "Description": "Track suits made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6112190000",
    "Description": "Track suits made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6112200000",
    "Description": "Ski suits",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6112310000",
    "Description": "Men's or boy's swimwear made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6112390000",
    "Description": "Men's or boy's swimwear made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6112410000",
    "Description": "Women's or girl's swimwear made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6112490000",
    "Description": "Women's or girl's swimwear made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6113000000",
    "Description": "Garments, made up of knitted or crocheted fabrics of heading 59.03, 59.06 or 59.07.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6114200000",
    "Description": "Other garments, knitted or crochetedr made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6114300000",
    "Description": "Other garments, knitted or crocheted made of manmade fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6114900000",
    "Description": "Other garments, knitted or crocheted made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6115100000",
    "Description": "Graduated compression hosiery (for example, stockings for varicose veins)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6115210000",
    "Description": "Other panty hose and tights made of synthetic fibres, measuring /single yarn < 67decitex",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6115220000",
    "Description": "Other panty hose and tights made of synthetic fibres, measuring/single yarn =>67decitex",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6115290000",
    "Description": "Other panty hose and tights made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6115300000",
    "Description": "Other women's full length or knee length hosiery, measuring per single yarn < 67 decitex",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6115940000",
    "Description": "Women's full length hosiery, measuring... <67 decitex made of wool of animal hair",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6115950000",
    "Description": "Other women's, measuring per single yarn< 67 decitex made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6115960000",
    "Description": "Other women's hosiery, measuring per single yarn < 67 decitex made of synthetic fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6115990000",
    "Description": "Other women's , measuring per single yarn<67 decitex made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6116100000",
    "Description": "Impregnated, coated or covered with plastics or rubber",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6116910000",
    "Description": "Gloves, mittens and mitts.. or crocheted made of wool or fine animal hair",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6116920000",
    "Description": "Gloves, mittens and mitts, knitted or crocheted not specified made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6116930000",
    "Description": "Gloves, mittens and mitts, knitted or crocheted not specified made of synthetic fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6116990000",
    "Description": "Gloves, mittens and mitts, knitted or crocheted not specified made of other textile",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6117100000",
    "Description": "Shawls, scarves, mufflers, mantillas, veils and the like",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6117800000",
    "Description": "Other accessories Shawls, scarves, mufflers, mantillas, veils",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6117900000",
    "Description": "Parts of other clothing accessories",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6201200000",
    "Description": "- Of wool or fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "6201300000",
    "Description": "- Of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "6201400000",
    "Description": "- Of man-made fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "6201900000",
    "Description": "- Of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "6202200000",
    "Description": "- Of wool or fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "6202300000",
    "Description": "- Of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "6202400000",
    "Description": "- Of man-made fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "6202900000",
    "Description": "- Of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "6203110000",
    "Description": "Men's or boys' suits made of wool or fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6203120000",
    "Description": "Men's or boys' suits made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6203190000",
    "Description": "Men's or boys' suits made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6203220000",
    "Description": "Men's or boys' Ensembles made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6203230000",
    "Description": "Men's or boys' Ensembles made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6203290000",
    "Description": "Men's or boys' Ensembles made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6203310000",
    "Description": "Men's or boys' Ensembles made of wool or fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6203320000",
    "Description": "Men's or boys' Ensembles made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6203330000",
    "Description": "Men's or boys' Ensembles made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6203390000",
    "Description": "Men's or boys' Ensembles made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6203410000",
    "Description": "Men's or boys' Jackets and blazerss made of wool or fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6203420000",
    "Description": "Men's or boys' Jackets and blazerss made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6203430000",
    "Description": "Men's or boys' Jackets and blazerss made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6203490000",
    "Description": "Men's or boys' Jackets and blazerss made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204110000",
    "Description": "Women's or girls' suits made of wool or fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204120000",
    "Description": "Women's or girls' suits made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204130000",
    "Description": "Women's or girls' suits made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204190000",
    "Description": "Women's or girls' suits made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204210000",
    "Description": "Women's or girls' Ensembles made of wool or fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204220000",
    "Description": "Women's or girls' Ensembles made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204230000",
    "Description": "Women's or girls' Ensembles made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204290000",
    "Description": "Women's or girls' Ensembles made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204310000",
    "Description": "Women's or girls' Jackets and blazers made of wool or fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204320000",
    "Description": "Women's or girls' Jackets and blazers made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204330000",
    "Description": "Women's or girls' Jackets and blazers made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204390000",
    "Description": "Women's or girls' Jackets and blazers made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204410000",
    "Description": "Women's or girls' Jackets and blazers made of wool or fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204420000",
    "Description": "Women's or girls' Jackets and blazers made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204430000",
    "Description": "Women's or girls' Jackets and blazers made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204440000",
    "Description": "Women's or girls' Jackets and blazers made of artificial fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204490000",
    "Description": "Women's or girls' Jackets and blazers made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204510000",
    "Description": "Women's or girls' Skirts and divided skirts made of wool or fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204520000",
    "Description": "Women's or girls' Skirts and divided skirts made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204530000",
    "Description": "Women's or girls' Skirts and divided skirts made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204590000",
    "Description": "Women's or girls' Skirts and divided skirts made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204610000",
    "Description": "Women/girls' Trouser, bib/brace overalls, breeches/shorts made of wool/fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204620000",
    "Description": "Women's or girls' Trousers, bib and brace overalls, breeches and shorts made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204630000",
    "Description": "Women/girls' Trousers, bib/brace overall, breeches & shorts made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6204690000",
    "Description": "Women/girls' Trouser, bib/brace overall, breeches/shorts made of other textile material",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6205200000",
    "Description": "Men's or boys' shirts made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6205300000",
    "Description": "Men's or boys' shirts made of manmade fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6205900000",
    "Description": "Men's or boys' shirts made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6206100000",
    "Description": "Women's or girls' blouses, shirts and shirtblouses made of silk or silk waste",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6206200000",
    "Description": "Women's or girls' blouses, shirts and shirtblouses made of wool or fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6206300000",
    "Description": "Women's or girls' blouses, shirts and shirtblouses made of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6206400000",
    "Description": "Women's or girls' blouses, shirts and shirtblouses made of manmade fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6206900000",
    "Description": "Women's or girls' blouses, shirts and shirtblouses made of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6207110000",
    "Description": "Men's or boys' Underpants and briefs of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6207190000",
    "Description": "Men's or boys' Underpants and briefs of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6207210000",
    "Description": "Men's or boys' Nightshirts and pyjamas of cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6207220000",
    "Description": "Men's or boys' Nightshirts and pyjamas of manmade fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6207290000",
    "Description": "Men's or boys' Nightshirts and pyjamas of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6207910000",
    "Description": "Other articles of heading 62.07 not specified of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6207990000",
    "Description": "Other articles of heading 62.07 not specified of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6208110000",
    "Description": "Women's or girls' Slips and petticoats manmade fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6208190000",
    "Description": "Women's or girls' Slips and petticoats other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6208210000",
    "Description": "Women's or girls' Nightdresses and pyjamas cotton",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6208220000",
    "Description": "Women's or girls' Nightdresses and pyjamas manmade fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6208290000",
    "Description": "Women's or girls' Nightdresses and pyjamas other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6208910000",
    "Description": "Women's or girls' articles of heading 62.08 made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6208920000",
    "Description": "Women's or girls' articles of heading 62.08 made of manmade fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6208990000",
    "Description": "Women's or girls' articles of heading 62.08 made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6209200000",
    "Description": "Babies' garments and clothing accessories made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "6209300000",
    "Description": "Babies' garments and clothing accessories made of synthetic fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "6209900000",
    "Description": "Babies' garments and clothing accessories made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "6210100000",
    "Description": "Garment of fabrics of heading 56.02 or 56.03",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6210200000",
    "Description": "Other garments of the type described in subheadings 6201.11 to 6201.19",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6210300000",
    "Description": "Other garments of the type described in subheadings 6202.11 to 6202.19",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6210400000",
    "Description": "Other men's or boys' garments",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6210500000",
    "Description": "Other women's or girls' garments",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6211110000",
    "Description": "Swimwear Men's or boys'",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6211120000",
    "Description": "Swimwear Women's or girls'",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6211200000",
    "Description": "Swimwear Ski suits",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6211320000",
    "Description": "Other garments, men's or boys' made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6211330000",
    "Description": "Other garments, men's or boys' made of manmade fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6211390000",
    "Description": "Other garments, men's or boys' made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6211420000",
    "Description": "Other garments, women's or girls' made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6211430000",
    "Description": "Other garments, women's or girls' made of manmade fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6211490000",
    "Description": "Other garments, women's or girls' made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6212100000",
    "Description": "Brassieres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6212200000",
    "Description": "Girdles and pantygirdles",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6212300000",
    "Description": "Corselettes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6212900000",
    "Description": "Brassieres, girdles, corsets, braces, suspenders, garters and similar art",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6213200000",
    "Description": "Handkerchiefs made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6213900000",
    "Description": "Handkerchiefs made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6214100000",
    "Description": "Shawls, scarves, mufflers, mantillas, veils and the like made of silk or silk waste",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6214200000",
    "Description": "Shawls, scarves, mufflers, mantillas, veils and the like made of wool/fine animal hair",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6214300000",
    "Description": "Shawls, scarves, mufflers, mantillas, veils and the like made of synthetic fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6214400000",
    "Description": "Shawls, scarves, mufflers, mantillas, veils and the like made of artificial fibres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6214900000",
    "Description": "Of other textile materials",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6215100000",
    "Description": "Ties, bow ties and cravats made of silk or silk waste",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6215200000",
    "Description": "Ties, bow ties and cravats made of manmade fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6215900000",
    "Description": "Ties, bow ties and cravats made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6216000000",
    "Description": "Gloves, mittens and mitts.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6217100000",
    "Description": "Other made up clothing accessories;",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6217900000",
    "Description": "Parts of garments or of clothing accessories, other than those of heading 62.12.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6301100000",
    "Description": "Electric blankets",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6301200000",
    "Description": "Blankets (excl. electric blankets) and travelling rugs, of wool or of fine animal hair",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6301300000",
    "Description": "Blankets (other than electric blankets) and travelling rugs, of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6301400000",
    "Description": "Blankets (other than electric blankets) and travelling rugs, of synthetic fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6301900000",
    "Description": "Other blankets and travelling rugs",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302100000",
    "Description": "Bed linen, knitted or crocheted",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302210000",
    "Description": "Other bed linen not specified, printed, made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302220000",
    "Description": "Other bed linen not specified, printed, made of manmade fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302290000",
    "Description": "Other bed linen not specified, printed, made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302310000",
    "Description": "Other bed linen not specified, not printed, made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302320000",
    "Description": "Other bed linen not specified, not printed, made of manmade fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302390000",
    "Description": "Other bed linen not specified, not printed, made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302400000",
    "Description": "Table linen, knitted or crocheted",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302510000",
    "Description": "Other table linen not specified, made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302530000",
    "Description": "Other table linen not specified, made of manmade fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302590000",
    "Description": "Other table linen not specified, made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302600000",
    "Description": "Toilet linen and kitchen linen, of terry towelling or similar terry fabrics, of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302910000",
    "Description": "Toilet/kitchen linen, of terry towelling or similar terry fabrics, made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302930000",
    "Description": "Toilet/kitchen linen, of terry towelling or similar terry fabric, made of manmade fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6302990000",
    "Description": "Toilet/kitchen linen of terry towelling or similar, of cotton of other text materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6303120000",
    "Description": "Knitted or crocheted curtains made of synthetic fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6303190000",
    "Description": "Knitted or crocheted curtains made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6303910000",
    "Description": "Other Curtains not specified made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6303920000",
    "Description": "Other Curtains not specified made of synthetic fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6303990000",
    "Description": "Other Curtains not specified made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6304110000",
    "Description": "Bedspreads Knitted or crocheted",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6304190000",
    "Description": "Other bedspread excluding knitted or crocheted",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6304200000",
    "Description": "Bed nets specified in Subheading Note 1 to this Chapter",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6304910000",
    "Description": "-- Knitted or crocheted",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "21/06/2020"
  },
  {
    "CET Code": "6304920000",
    "Description": "Not knitted or crocheted furnishing articles of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6304930000",
    "Description": "Not knitted or crocheted furnishing articles of synthetic fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6304990000",
    "Description": "Not knitted or crocheted furnishing articles of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6305100000",
    "Description": "Sacks and bags,used for the packing of goods made of jute or textile of heading 53.03",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6305200000",
    "Description": "Sacks and bags, of a kind used for the packing of goods made of cotton",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6305320000",
    "Description": "Flexible intermediate bulk containers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6305330000",
    "Description": "Other manmade textile materials of polyethylene or polypropylene strip or the like",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6305390000",
    "Description": "Sacks/bags used for packing, made of handmaid textile material not specified in 63.05",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6305900000",
    "Description": "Sacks and bags, oused for packing of goods, of textile materials not specified in 63.05",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6306120000",
    "Description": "Tarpaulins, awnings and sunblinds made of synthetic fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6306190000",
    "Description": "Tarpaulins, awnings and sunblinds made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6306220000",
    "Description": "Tents made of synthetic fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6306290000",
    "Description": "Tents made of other textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6306300000",
    "Description": "Sails",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6306400000",
    "Description": "Pneumatic mattresses",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6306900000",
    "Description": "Other Tarpaulins, awnings sails for boats, sailboards... not specified in 63.06",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6307100000",
    "Description": "Floorcloths, dishcloths, dusters and similar cleaning cloths",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6307200000",
    "Description": "Lifejackets and lifebelts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6307900000",
    "Description": "Other made up articles, including dress patterns",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6308000000",
    "Description": "Sets consisting of woven fabric and yarn, for making rugs... in packings for retail sale",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6309000000",
    "Description": "Worn clothing and other worn articles.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6310101000",
    "Description": "Mutilated rags",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "15/04/2022"
  },
  {
    "CET Code": "6310109000",
    "Description": "Other sorted Used/new rags, ... and worn out articles of twine... (not Mutilated rags)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6310901000",
    "Description": "Mutilated rags",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6310909000",
    "Description": "Other used or worn out articles of twine not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6401101000",
    "Description": "Footwear incorporating a protective metal toecap: Presented fully built",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6401109000",
    "Description": "Footwear incorporating a protective metal toecap: Presented in CKD for assembly industry",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6401921000",
    "Description": "Footwear Covering the ankle but not covering knee presented CKD for assembly industry",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6401929000",
    "Description": "Footwear Covering the ankle but not covering knee presented fully built",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6401991000",
    "Description": "Footwear Covering the ankle and the knee presented CKD for assembly industry",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6401999000",
    "Description": "Footwear Covering the ankle and the knee presented fully built",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6402121000",
    "Description": "Footwear with outer soles and uppers of rubber/plastics; skyboots/snowboard footwear CKD",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6402129000",
    "Description": "Footwear with outer soles and uppers of rubber/plastics; skyboots/snowboard footwear FBU",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6402191000",
    "Description": "Other sports footwear not specified or mentioned presented CKD for the assembly industry",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6402199000",
    "Description": "Other sports footwear not specified or mentioned presented FBU",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6402201000",
    "Description": "Footwear with upper straps or thongs assembled to the sole by means of plugs CKD",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6402209000",
    "Description": "Footwear with upper straps or thongs assembled to the sole by means of plugs FBU",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6402911000",
    "Description": "Footwear without upper strap/thong assembled to the sole by plug Covering the ankle CKD",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6402919000",
    "Description": "Footwear without upper strap/thong assembled to the sole by plug Covering the ankle FBU",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "6402991000",
    "Description": "Footwear without upper strap/thong fixed to sole by plug Covering beyond the ankle CKD",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6402992000",
    "Description": "Beach sandals",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6402999000",
    "Description": "Footwear without upper strap/thong fixed to sole by plug Covering beyond the ankle FBU",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403121000",
    "Description": "ski footwear with soles of plastic/leather and uppers of leather CKD.",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403129000",
    "Description": "ski footwear with soles of plastic/leather and uppers of leather FBU.",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403191000",
    "Description": "Non ski footwear with soles of plastic/leather and uppers of leather CKD.",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403199000",
    "Description": "Non ski footwear with soles of plastic/leather and uppers of leather FBU.",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403201000",
    "Description": "Footwear with outer sole of leather/uppers of leather strap across instep /big toe CKD",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403209000",
    "Description": "Footwear with outer sole of leather/uppers of leather strap across instep /big toe FBU",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403401000",
    "Description": "Footwear, incorporating a protective metal toecap CKD for the assembly industry",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403409000",
    "Description": "Footwear, incorporating a protective metal toecap FBU",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403511000",
    "Description": "Footwear not specified in heading with outer soles of leather  covering the ankle CKD",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403519000",
    "Description": "Footwear not specified in heading with outer soles of leather  covering the ankle FBU",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403591000",
    "Description": "Footwear not specified in heading with outer soles of leather  beyond the ankle CKD",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403599000",
    "Description": "Footwear not specified in heading with outer soles of leather  beyond the ankle FBU",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403911000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403919000",
    "Description": "Other footwear with outer soles of leather Covering the an",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403991000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6403999000",
    "Description": "Other footwear type specified Covering the ankle, not for the assembly industry",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6404111000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6404119000",
    "Description": "Other Sports footwear; tennis shoes, basketball shoes,",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6404191000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6404199000",
    "Description": "Other Footwear with outer soles of rubber or plastics ",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6404201000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6404209000",
    "Description": "Other Footwear with outer soles of leather or composition leather: fully built.",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6405101000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6405109000",
    "Description": "Other With uppers of leather or composition leather: fully built",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6405201000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6405209000",
    "Description": "Other With uppers of textile materials: fully built",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6405901000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "2U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6405909000",
    "Description": "Other footwear: fully built",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6406100000",
    "Description": "Uppers and parts thereof, other than stiffeners",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6406200000",
    "Description": "Outer soles and heels, of rubber or plastics",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6406901000",
    "Description": "Gaiters, spats and similar articles, and parts thereof",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6406902000",
    "Description": "Assemblings composed of uppers attached to outer soles ",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6406909000",
    "Description": "Other parts of footwear",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6501000000",
    "Description": "Hatforms, hat bodies and hoods of felt, shaped or brims; plateaux and manchons of felt.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6502000000",
    "Description": "Hatshapes, plaited or made by strips of any material, shaped, brims/lined or trimmed.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6504000000",
    "Description": "Hats and other headgear made by strips of any material, shaped, brims/lined or trimmed.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6505001000",
    "Description": "Hair nets",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6505009000",
    "Description": "Hat/headgear, knitted/crocheted, made up from lace, felt/ textile fabric, lined/trimmed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6506100000",
    "Description": "Safety headgear",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6506910000",
    "Description": "Headgear, whether or not lined or trimmed other than safety headgear of plastic /rubber",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6506990000",
    "Description": "Headgear, whether or not lined or trimmed other than safety headgear not made of rubber",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6507000000",
    "Description": "Headbands, linings, covers, hat foundations, hat frames, peaks..., for headgear.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "6601100000",
    "Description": "Garden or similar umbrellas",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6601910000",
    "Description": "Umbrellas/sun umbrellas (including walking-stick umbrellas; Having a telescopic shaft",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6601990000",
    "Description": "Umbrellas/sun umbrellas (including walking-stick umbrells; Not having a telescopic shaft",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6602000000",
    "Description": "Walkingsticks, seatsticks, whips, ridingcrops and the like.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6603200000",
    "Description": "Umbrella frames, including frames mounted on shafts (sticks)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6603900000",
    "Description": "Parts, trimmings and accesries of articles of heading 66.01 or 66.02: Ot",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6701000000",
    "Description": "Skins and parts of bird with their feathers or dow (not 05.05 and worked quills/scapes).",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6702100000",
    "Description": "Artificial flowers, foliage and fruit and parts thereof; articles made of plastics.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6702900000",
    "Description": "Artificial flowers, foliage and fruit and parts thereof; not made of plastics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6703000000",
    "Description": "Human hair, dressed, thinned, bleached or worked; for use in making wigs or the like.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6704110000",
    "Description": "Complete wigs of synthetic textile materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6704190000",
    "Description": "Other False beards, eyebrows eyelashes of synthetic textile materials not complete wig",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6704200000",
    "Description": "Wigs, false beards, eyebrows and eyelashes... and the like, of human hair",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6704900000",
    "Description": "Wigs, false beards, eyebrows and eyelashes... and the like, of animal hair",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6801000000",
    "Description": "Setts, curbstones and flagstones, of natural stone (except slate).",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6802100000",
    "Description": "Tiles, cubes. whether or not rectangular, coloured, chippings and powder",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6802210000",
    "Description": "Marble, travertine and alabaster",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6802230000",
    "Description": "Granite",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6802290000",
    "Description": "Other stone",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6802910000",
    "Description": "Marble, travertine and alabaster",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6802920000",
    "Description": "calcareous stone",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6802930000",
    "Description": "Granite",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6802991000",
    "Description": "Steatite",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6802999000",
    "Description": "Other stones covered in this heading but not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6803000000",
    "Description": "Worked slate and articles of slate or of agglomerated slate.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6804100000",
    "Description": "Millstones and grindstones for milling, grinding or pulping",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6804210000",
    "Description": "millstones, grindstones, grinding wheels of agglomerated synthetic or natural diamond",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6804220000",
    "Description": "millstones, grindstones, grinding wheels of other agglomerated abrasives or of ceramics",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6804230000",
    "Description": "millstones, grindstones, grinding wheels of natural stone",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6804300000",
    "Description": "Hand sharpening or polishing stones",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6805100000",
    "Description": "Natural or artificial abrasive powder or grain on a base of woven textile fabric only",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6805200000",
    "Description": "Natural or artificial abrasive powder or grain on a base of paper or paperboard only",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6805300000",
    "Description": "Natural or artificial abrasive powder or grain on a base of other materials",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6806100000",
    "Description": "Slag wool, rock wool and similar mineral wools inc. mixures, in bulk, sheets or rolls",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6806200000",
    "Description": "Exfoliated vermiculite, exp. clays and similar exp. mineral materials incl. mixtures.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6806900000",
    "Description": "Heat/soundinsulating or soundabsorbing min. material, not 68.11 & 68.12 or Chap. 69.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6807100000",
    "Description": "Articles of asphalt or of similar material; In rolls",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6807900000",
    "Description": "Articles of asphalt or of similar material (excl. ones in rolls)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6808000000",
    "Description": "Panels, boards, tiles... of veg. fibre of wood, mixed with cement or min. binders.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6809110000",
    "Description": "Articles of plaster or of based on plaster faced or reinforced with paper/paperboard.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6809190000",
    "Description": "Oher articles of plaster or based on plaster, not faced or reinforced with paperboard",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6809900000",
    "Description": "Other articles of plaster or of compositions based on plasters not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6810110000",
    "Description": "Building blocks and bricks of cement, of concrete or of artificial stone",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6810190000",
    "Description": "Other Tiles, flagstones, bricks and similar articles excl. building blocks and bricks",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "6810910000",
    "Description": "Prefabricated structural components for building or civil engineering",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6810990000",
    "Description": "Articles of cement, of concrete or of artificial stone not specified in this heading",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6811401000",
    "Description": "Tubes, pipes and tube or pipe fittings Containing asbestos for water supply",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6811409000",
    "Description": "Other articles Containing asbestos excl. tubes, pipes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6811810000",
    "Description": "Corrugated sheets not containing asbesto",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6811820000",
    "Description": "Other sheets, panels, tiles and similar article but not containing asbestors",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6811891000",
    "Description": "Tubes, pipes and tube or pipe fittings for water supply not containing asbestos ",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6811899000",
    "Description": "Other Articles not containing wsbestors not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6812800000",
    "Description": "Fabricated asbestos fibres; mixtures with a basis of asbestos of crocidolite",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6812910000",
    "Description": "Clothing, clothing accessories, footwear... of fabricated asbestos fibre",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6812990000",
    "Description": "Fabricated asbestos fibres; mixtures with a basis of asbestos ... not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6813200000",
    "Description": "Friction material/articles thereof not mounted, for brakes/clutches containing asbestos",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6813810000",
    "Description": "Brake linings and pads Not containing asbestos",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6813890000",
    "Description": "Other friction material not containing asbestos",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6814100000",
    "Description": "Plates, sheets and strips of agglomerated or reconstituted mica",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6814900000",
    "Description": "Worked mica and article of mica, including agglomerated/reconstituted mica not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6815110000",
    "Description": "-- Carbon fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "6815120000",
    "Description": "-- Fabrics of carbon fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "6815130000",
    "Description": "-- Other articles of carbon fibres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "6815190000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "6815200000",
    "Description": "Articles of peat",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6815910000",
    "Description": "Article of stone or other mineral substances containing magnesite, dolomite or chromite",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6815990000",
    "Description": "Articles of stone or other mineral substances, nes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6901000000",
    "Description": "Bricks, blocks, tiles and other ceramic of siliceous fossil meals of siliceous earths.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6902100000",
    "Description": "Ref. bricks, blocks, tile containing > 50 % of Mg, Ca or Cr, exp. as MgO, CaO or Cr2O3",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6902200000",
    "Description": "Ref. bricks, blocks, tile containing containing >50 % of alumina, of silica its mixture",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6902900000",
    "Description": "Other Refractory bricks, blocks, tiles and similar constructional goods not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6903100000",
    "Description": "Other refractory ceramic goods containing >50 % of graphite or other ",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6903200000",
    "Description": "Other refractory ceramic goods containing>50 % of alumina or other carbon or its mixture",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6903900000",
    "Description": "Other refractory ceramic goods covered in this heading but not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6904100000",
    "Description": "Ceramic building bricks",
    "SU": "500",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6904900000",
    "Description": "Flooring blocks, support or filler tiles and the like",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6905100000",
    "Description": "Roofing tiles",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6905900000",
    "Description": "Chimneypots/ liners, cowls, architectural ornaments and ceramic constructional goods.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6906000000",
    "Description": "Ceramic pipes, conduits, guttering and pipe fittings.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6907210000",
    "Description": "Of a water absorption coefficient by weight not exceeding 05 %",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6907220000",
    "Description": "Of a water absorption coefficient by weight exceeding 05 % but not exceeding 10 %",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6907230000",
    "Description": "Of a water absorption coefficient by weight exceeding 10 %",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6907300000",
    "Description": "Mosaic cubes and the like, other than those of subheading 690740",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6907400000",
    "Description": "Finishing ceramics",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6909110000",
    "Description": "Ceramic wares for laboratory, chemical or other similar use made of porcelain or china",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6909120000",
    "Description": "Ceramic wares for laboratory. having a hardness = 9 or more on the Mohs scale",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6909190000",
    "Description": "Ceramic wares for laboratory, chemical not specified",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6909900000",
    "Description": "Ceramic troughs, tubs... used in agriculture and for the conveyance or packing of goods.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6910100000",
    "Description": "Ceramic sinks, wash basins made of porcelain or china",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6910900000",
    "Description": "Ceramic sinks, wash basins.. excluding porcelain or china",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "6911100000",
    "Description": "Tableware and kitchenware of porcelain or china",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6911900000",
    "Description": "Toilet articles, of porcelain or china.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6912001000",
    "Description": "Tableware and kitchenware other than of porcelain or china",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6912009000",
    "Description": "Toilet articles, other than of porcelain or china.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6913100000",
    "Description": "Statuettes and other ornamental ceramic articles of porcelain or china",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6913900000",
    "Description": "Statuettes and other ornamental ceramic articles other than of porcelain or china",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6914100000",
    "Description": "Other ceramic articles not specified of porcelain or china",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "6914900000",
    "Description": "Other ceramic articles not specified other than of porcelain or china",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7001000000",
    "Description": "Cullet and other waste and scrap of glass; glass in the mass.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7002100000",
    "Description": "Glass in Balls",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7002200000",
    "Description": "Glass in Rods",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7002310000",
    "Description": "Glass in tubes of fused quartz or other fused silica",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7002320000",
    "Description": "Tubes of other glass with linear coefficient of expansion > 5 x 106 /K @ 0 oC to 300 oC",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7002390000",
    "Description": "Glass in tubes (other than microspheres of heading 70.18) not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7003120000",
    "Description": "Cast glass and rolled glass, in sheets, nonwired, coloured, opacified /absorbent layer",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7003190000",
    "Description": "Cast glass and rolled glass, in sheets, nonwired, non coloured.",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7003200000",
    "Description": "Coloured, opacified... or having absorbent layered wired Cast and rolled sheetsglass",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7003300000",
    "Description": "Cast glass and rolled glass, profiles, not otherwise worked.",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7004200000",
    "Description": "Drawn glass and blown glass, in sheets, nonwired, coloured, opacified /absorbent layer",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7004900000",
    "Description": "Drawn glass and blown glass, non coloured.",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7005100000",
    "Description": "Float glass and surface ground or polished glass, in sheets, nonwired.",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7005210000",
    "Description": "Float glass, coloured throughout the mass opacified, flashed or merely surface ground",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7005290000",
    "Description": "Float glass, non wired and non coloured,",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7005300000",
    "Description": "Float glass and surface ground or polished glass, in sheets, wired.",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7006000000",
    "Description": "Glass of heading 70.03 to 70.05, bent, edged..not framed /fitted with other materials.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7007110000",
    "Description": "Toughened safety glassof size and shape suitable for incorporation in vehicles, aircraft",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7007190000",
    "Description": "Toughened safety glassof size and shape not suitable for vehicles, aircraft",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7007210000",
    "Description": "Laminated safety glass of shape suitable for incorporation in vehicles, aircraft",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7007290000",
    "Description": "Laminated safety glass not suitable for incorporation in vehicles, aircraft",
    "SU": "M2",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7008000000",
    "Description": "Multiplewalled insulating units of glass.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7009101000",
    "Description": "Rearview mirrors for vehicles for motorcycles and bicycles",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7009109000",
    "Description": "Rearview mirrors for vehicles for other vehicles",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7009910000",
    "Description": "Glass mirrors unframed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7009920000",
    "Description": "Glass mirrors Framed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010100000",
    "Description": "Ampoules of glass",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010200000",
    "Description": "Stoppers, lids and other closures of glass",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010901100",
    "Description": "Carboys, bottles and flasks of glass > 1l",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010901200",
    "Description": "Glass Jars, pots and similar containers, including preserving jars > 1l",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010901900",
    "Description": "Other phials, ampoules and other containers, of glass, >1 l",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010902100",
    "Description": "Carboys, bottles and flasks of a capacity exceeding 0.33l =< 1l",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010902200",
    "Description": "Jars, pots... including preserving jars of a capacity exceeding 0.33l =< 1l",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010902900",
    "Description": "Other containers not specified for the conveyance/packing of goods exceeding 0.33l =< 1l",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010903100",
    "Description": "Carboys, bottles and flasks exceeding 0.15l but not exceeding 0.33l:",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010903200",
    "Description": "Jars, pots and similar containers, including preserving jars > 0.15l<= 0.33l:",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010903900",
    "Description": "Other containers, of glass, used for the conveyance or packing of goods > 0.15l<= 0.33l:",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010904100",
    "Description": "Carboys, bottles and flasks of a capacity not exceeding 0.15l:",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010904200",
    "Description": "Jars, pots and similar containers, including preserving jars Of a capacity < 0.15l:",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7010904900",
    "Description": "Other containers not specified of glass of a capacity < 0.15 litres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7011100000",
    "Description": "Glass envelopes (including bulbs and tubes), open, and glass parts for electric lamps,",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7011200000",
    "Description": "Glass envelopes (including bulbs and tubes), open, and glass parts For cathoderay tubes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7011900000",
    "Description": "Glass envelopes, open, and glass parts of unspecified use",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7013100000",
    "Description": "Glassware used in office, indoor decoration...(not of heading 70.10 /70.18)glassceramics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7013220000",
    "Description": "Stemware drinking glasses, other than of glassceramics of lead crystal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7013280000",
    "Description": "Other, Stemware drinking glasses, other than of glassceramics not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7013330000",
    "Description": "Drinking glasses of lead crystal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7013370000",
    "Description": "Drinking glasses, other than of glass ceramics not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7013410000",
    "Description": "Glassware of a kind used for table or kitchen purposes made of lead crystal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7013420000",
    "Description": "Glassware of used for table/kitchen. Made of lead crystal a <5 x 106/K @ 0 oC to 300 oC",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7013490000",
    "Description": "Other glassware of a kind used for table or kitchen purposes not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7013910000",
    "Description": "Glassware use not specified in 70.13 made of lead crystal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7013990000",
    "Description": "Other types of glassware not specified of any use.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7014000000",
    "Description": "Signalling glassware and optical elements of glass excl. 70.15)not optically worked",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7015100000",
    "Description": "Glasses for corrective spectacles",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7015900000",
    "Description": "Clock or watch glasses and similar glasses, glasses for non corrective or",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7016100000",
    "Description": "Glass cubes and other glass smallwares for mosaics or similar decorative purposes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7016900000",
    "Description": "Paving blocks... and articles of pressed/moulded glass, for building/const. purposes;",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7017100000",
    "Description": "Laboratory, hygienic /pharmaceutical glassware, made of fused quartz or fused silica",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7017200000",
    "Description": "Lab., pharma.. glassware, with linear coef. expansion < 5 x 106/Kelvin @ 0 oC - 300 oC",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7017900000",
    "Description": "Lab., hygienic or pharmaceutical glassware, not specified in 70.17",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7018100000",
    "Description": "Glass beads, imitation pearls, imitation precious...and similar glass smallwares",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7018200000",
    "Description": "Glass microspheres not exceeding 1 mm in diameter",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7018900000",
    "Description": "Glass eyes not prosthetic articles; statuettes, ornaments , not imitation jewellery",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7019110000",
    "Description": "Glass fibres and articles thereof Chopped strands, of a length of =< 50 mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7019120000",
    "Description": "Rovings ( long and narrow bundle of glass fiber)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7019130000",
    "Description": "-- Other yarn, slivers",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019140000",
    "Description": "-- Mechanically bonded mats",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019150000",
    "Description": "-- Chemically bonded mats",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019190000",
    "Description": "Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7019610000",
    "Description": "-- Closed woven fabrics of rovings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019620000",
    "Description": "-- Other closed fabrics of rovings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019630000",
    "Description": "-- Closed woven fabrics, plain weave, of yarns, not coated or laminated",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019640000",
    "Description": "-- Closed woven fabrics, plain weave, of yarns, coated or laminated",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019650000",
    "Description": "-- Open woven fabrics of a width not exceeding 30 cm",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019660000",
    "Description": "-- Open woven fabrics of a width exceeding 30 cm",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019690000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019710000",
    "Description": "-- Veils (thin sheets)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019720000",
    "Description": "-- Other closed fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019730000",
    "Description": "-- Other open fabrics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019800000",
    "Description": "- Glass wool and articles of glass wool",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7019900000",
    "Description": "Glass fibres and articles thereof (woven or not) not specified or mentioned in 70.19.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7020001000",
    "Description": "Fishing floats for fishing nets made of glass",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7020009000",
    "Description": "Other articles of glass not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7101100000",
    "Description": "Natural pearls",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7101210000",
    "Description": "Unworked Cultured pearls",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7101220000",
    "Description": "Worked Cultured pearls",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7102100000",
    "Description": "Unsorted Diamonds",
    "SU": "CRT",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7102210000",
    "Description": "Diamonds, Unworked or simply sawn, cleaved or bruted",
    "SU": "CRT",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7102290000",
    "Description": "Other industrial diamonds not specified.",
    "SU": "CRT",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7102310000",
    "Description": "Diamonds, Unworked or simply sawn, cleaved or bruted",
    "SU": "CRT",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7102390000",
    "Description": "Other non industrial diamonds not specified.",
    "SU": "CRT",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7103100000",
    "Description": "Precious stones and semiprecious stones, unworked or simply sawn or roughly shaped",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7103910000",
    "Description": "Rubies, sapphires and emeralds",
    "SU": "CRT",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7103990000",
    "Description": "Precious /semiprecious stones, other than diamonds worked not specified in 71.03",
    "SU": "CRT",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7104100000",
    "Description": "Piezoelectric quartz",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7104210000",
    "Description": "-- Diamonds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7104290000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7104910000",
    "Description": "-- Diamonds",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7104990000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7105100000",
    "Description": "Dust and powder of natural or synthetic precious or semiprecious stones made of diamonds",
    "SU": "CRT",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7105900000",
    "Description": "Dust and powder of natural or synthetic precious or semiprecious stones not specified",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7106100000",
    "Description": "Silver (including silver plated with gold or platinum) in powder form.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7106910000",
    "Description": "Silver (incl. silver plated with gold or platinum)unwrought",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7106920000",
    "Description": "Silver (incl. silver plated with gold or platinum) in semimanufactured forms",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7107000000",
    "Description": "Base metals clad with silver, not further worked than semimanufactured.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7108110000",
    "Description": "Nonmonetary Gold (including gold plated with platinum) in Powder form",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7108120000",
    "Description": "Nonmonetary Gold (including gold plated with platinum) in other unwrought forms",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7108131000",
    "Description": "Gold bullion",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7108139000",
    "Description": "Other semimanufactured forms of Gold, not specified or included in this heading",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7108200000",
    "Description": "Monetary Gold (including gold plated with platinum) in any form",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7109000000",
    "Description": "Base metals or silver, clad with gold, not further worked than semimanufactured.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7110110000",
    "Description": "Platinum  unwrought or in powder form",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7110190000",
    "Description": "Platinum, unwrought or in semi manufactured form",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7110210000",
    "Description": "Palladium unwrought or in powder form",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7110290000",
    "Description": "Platinum, unwrought or in semimanufactur Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7110310000",
    "Description": "Rhodium unwrought or in powder form",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7110390000",
    "Description": "Platinum, unwrought or in semi manufacturedform",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7110410000",
    "Description": "Iridium, osmium and ruthenium, unwrought or in powder form",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7110490000",
    "Description": "Iridium, osmium and ruthenium, unwrought or in semimanufactur Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7111000000",
    "Description": "Base metals, silver or gold, clad with platinum, not further worked, semimanufactured.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7112300000",
    "Description": "Ash containing precious metal or precious metal compounds",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7112910000",
    "Description": "Waste and scrap of gold, metal clad with gold, excl. sweepings containing other metals",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7112920000",
    "Description": "Waste/scrap of platinum, metal clad with platinum, excl. sweepings with precious metals",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7112990000",
    "Description": "Waste/scrap of precious metal or of metal clad with precious metalnot specified in 71.12",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7113110000",
    "Description": "Articles of jewellery and parts of silver.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7113190000",
    "Description": "Articles of jewellery and parts of other precious metals.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7113200000",
    "Description": "Articles of jewellery and parts thereoo of base metal clad with precious metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7114110000",
    "Description": "Articles of goldsmiths' or silversmiths' wares and parts thereof, of silver.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7114190000",
    "Description": "Articles of goldsmiths' or silversmiths' wares and parts, of other precious metal.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7114200000",
    "Description": "Articles of goldsmiths' or silversmiths'... of base metal clad with precious metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7115100000",
    "Description": "Precious metal Catalysts in the form of wire cloth or grill, of platinum",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7115900000",
    "Description": "Other articles of precious metal not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7116100000",
    "Description": "Articles of natural or cultured pearls",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7116200000",
    "Description": "Articles of precious or semiprecious stones (natural, synthetic or reconstructed)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7117110000",
    "Description": "Imitation jewellery of base metal; Cufflinks and studs",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7117190000",
    "Description": "Imitation jewellery of base metal; other than Cufflinks and studs",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7117900000",
    "Description": "Other Imitation jewellery not specified in 71.17.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7118100000",
    "Description": "Coin (other than gold coin), not being legal tender",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7118900000",
    "Description": "Coin (Legal tender).",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7201100000",
    "Description": "Nonalloy pig iron containing by weight 0.5 % or less of phosphorus",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7201200000",
    "Description": "Nonalloy pig iron containing by weight more than 0.5 % of phosphorus",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7201500000",
    "Description": "Alloy pig iron; spiegeleisen",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202110000",
    "Description": "Ferromanganese containing by weight more than 2 % of carbon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202190000",
    "Description": "Ferromanganese containing by weight less than 2 % of carbon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202210000",
    "Description": "Ferrosilicon containing by weight more than 55 % of silicon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202290000",
    "Description": "Ferrosilicon containing by weight less than 55 % of silicon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202300000",
    "Description": "Ferro-silico-manganese",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202410000",
    "Description": "Ferrochromium Containing by weight more than 4 % of carbon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202490000",
    "Description": "Ferrochromium Containing by weight less than 4 % of carbon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202500000",
    "Description": "Ferrosilicochromium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202600000",
    "Description": "Ferro-nickel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202700000",
    "Description": "Ferro-molybdenum",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202800000",
    "Description": "Ferro-tungsten and ferro-silicotungsten",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202910000",
    "Description": "Ferro-titanium and ferro-silicotitanium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202920000",
    "Description": "Ferro-vanadium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202930000",
    "Description": "Ferro-niobium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7202990000",
    "Description": "Other Ferroalloys not specified in 72.02.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7203100000",
    "Description": "Ferrous products obtained by direct reduction of iron ore",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "7203900000",
    "Description": "Ferrous products obtained by direct reduction of other spongy ferrous products",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "7204100000",
    "Description": "Waste and scrap of cast iron",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7204210000",
    "Description": "Waste and scrap of stainless steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7204290000",
    "Description": "Waste and scrap of alloy steel excluding stainless steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7204300000",
    "Description": "Waste and scrap of tinned iron or steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7204410000",
    "Description": "Turnings, shavings, chips, milling waste... of iron or steel whether or not in bundles",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7204490000",
    "Description": "Other waste and scrap not specified in 72.04",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7204500000",
    "Description": "Remelting scrap ingots",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7205100000",
    "Description": "Granules of pig iron, spiegeleisen, iron or steel.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7205210000",
    "Description": "Powders of alloy steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7205290000",
    "Description": "Powders of other granules of iron and steel other than alloy steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7206100000",
    "Description": "Ingots of Iron and nonalloy steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7206900000",
    "Description": "Iron and nonalloy steel in other primary forms (excluding iron of heading 72.03)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7207110000",
    "Description": "Semifinished product of iron or nonalloy steel with <0.25% carbon (rectangle or sqaure)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7207120000",
    "Description": "Other, of rectangular (other than square) crosssection",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7207190000",
    "Description": "Semiproducts of iron or nonalloy steel, <0.25% carbon, nes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7207200000",
    "Description": "Containing by weight 0.25 % or more of carbon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7208100000",
    "Description": "In coils, not further worked than hotrolled, with patterns in relief",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7208250000",
    "Description": "Of a thickness of 4.75 mm or more",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7208260000",
    "Description": "Of a thickness of 3 mm or more but less than 4.75 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7208270000",
    "Description": "Of a thickness of less than 3 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7208360000",
    "Description": "Of a thickness exceeding 10 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7208370000",
    "Description": "Of a thickness of 4.75 mm or more but not exceeding 10 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7208380000",
    "Description": "Of a thickness of 3 mm or more but less than 4.75 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7208390000",
    "Description": "Of a thickness of less than 3 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7208400000",
    "Description": "Not in coils, not further worked than hotrolled, with patterns in relief",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7208510000",
    "Description": "Of a thickness exceeding 10 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7208520000",
    "Description": "Of a thickness of 4.75 mm or more but not exceeding 10 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7208530000",
    "Description": "Of a thickness of 3 mm or more but less than 4.75 mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7208540000",
    "Description": "Of a thickness of less than 3 mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7208900000",
    "Description": "Flatrolled products of iron or nonalloy Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7209150000",
    "Description": "Of a thickness of 3 mm or more",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7209160000",
    "Description": "Of a thickness exceeding 1 mm but less than 3 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7209170000",
    "Description": "Of a thickness of 0.5 mm or more but not exceeding 1 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7209180000",
    "Description": "Of a thickness of less than 0.5 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "7209250000",
    "Description": "Of a thickness of 3 mm or more",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7209260000",
    "Description": "Of a thickness exceeding 1 mm but less than 3 mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7209270000",
    "Description": "Of a thickness of 0.5 mm or more but not exceeding 1 mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7209280000",
    "Description": "Of a thickness of less than 0.5 mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7209900000",
    "Description": "Flatrolled products of iron or nonalloy Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210110000",
    "Description": "Of a thickness of 0.5 mm or more",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210120000",
    "Description": "Of a thickness of less than 0.5 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210200000",
    "Description": "Plated or coated with lead, including terneplate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210300000",
    "Description": "Electrolytically plated or coated with zinc",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210410000",
    "Description": "Corrugated",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210491000",
    "Description": "In coils",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210499000",
    "Description": "Other Flatrolled products of iron or nonalloy steel,",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210500000",
    "Description": "Plated or coated with chromium oxides or with chromium and chromium oxides",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210611000",
    "Description": "In coils",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210619000",
    "Description": "Other Flatrolled products of iron or nonalloy steel,",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210691000",
    "Description": "In coils",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210699000",
    "Description": "Other Flatrolled products of iron or nonalloy steel,",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210701000",
    "Description": "In coils",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210709000",
    "Description": "Other Painted, varnished or coated with plastics:",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210901000",
    "Description": "Plated or coated with other materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7210909000",
    "Description": "Other flat rolled products.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7211130000",
    "Description": "Rolled on four faces or in a closed box pass, of a width",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7211140000",
    "Description": "Other, of a thickness of 4.75 mm or more",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7211190000",
    "Description": "Flatrolled products of iron or nonalloy her",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7211230000",
    "Description": "Containing by weight less than 0.25 % of carbon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7211290000",
    "Description": "Flatrolled products of iron or nonalloy Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7211900000",
    "Description": "FLATROLLED IRON/NONALLOY STEEL,WID<600MM,NES(INC.FURTHR WORKED THAN ROLLE",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7212100000",
    "Description": "Plated or coated with tin",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7212200000",
    "Description": "Electrolytically plated or coated with zinc",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7212300000",
    "Description": "Otherwise plated or coated with zinc",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7212400000",
    "Description": "Painted, varnished or coated with plastics",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7212500000",
    "Description": "Otherwise plated or coated",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7212600000",
    "Description": "Clad",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7213100000",
    "Description": "Containing indentations, ribs, grooves or other deformati",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7213201000",
    "Description": "Of circular crosssection measuring less than or equal to 5.5 mm in diameter",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7213209000",
    "Description": "Other Bars and rods, hotrolled, in irregularly wound co",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7213911000",
    "Description": "Of a diameter not exceeding 5.5mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7213919000",
    "Description": "Other Bars and rods, hotrolled, in irregularly wound",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7213990000",
    "Description": "Other Bars and rods, hotrolled, in irregularly wound co",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7214100000",
    "Description": "Forged",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7214200000",
    "Description": "Containing indentations, ribs, grooves or other deformati",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7214300000",
    "Description": "Other, of freecutting steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7214910000",
    "Description": "Of rectangular (other than square) crosssection",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7214990000",
    "Description": "Other bars and rods of iron or nonalloy steel, not furt",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7215100000",
    "Description": "Of freecutting steel, not further worked than coldformed or coldfinished",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7215500000",
    "Description": "Other, not further worked than coldformed or coldfinished",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7215900000",
    "Description": "Other bars and rods of iron or nonalloy Imported by Bonafide manufacturer",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7216100000",
    "Description": "U, I or H sections, not further worked than hotrolled, h",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7216210000",
    "Description": "L sections",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7216220000",
    "Description": "T sections",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7216310000",
    "Description": "U sections",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7216320000",
    "Description": "I sections",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7216330000",
    "Description": "H sections",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7216400000",
    "Description": "L or T sections, not further worked than hotrolled, hot",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7216500000",
    "Description": "Other angles, shapes and sections, not further worked tha",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7216610000",
    "Description": "Obtained from flatrolled products",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7216690000",
    "Description": "Angles, shapes and sections of iron or n Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7216910000",
    "Description": "Coldformed or coldfinished from flatrolled products",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7216990000",
    "Description": "Angles, shapes and sections of iron or n Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7217101000",
    "Description": "Of a diameter not exceeding 5.5mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7217109000",
    "Description": "Other not plated or coated, whether or not polished not specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7217201000",
    "Description": "Of a diameter not exceeding 5.5mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7217209000",
    "Description": "Other plated with zinc not specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7217301000",
    "Description": "Of a diameter not exceeding 5.5mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7217309000",
    "Description": "Other plated with other base metals not specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7217901000",
    "Description": "Of a diameter not exceeding 5.5mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7217909000",
    "Description": "Other wire of iron or nonsteel not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7218100000",
    "Description": "Stainless steel in Ingots and other primary forms",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7218910000",
    "Description": "Semifinished products of stainless steel Of rectangular (other than square) crosssection",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7218990000",
    "Description": "Semifinished products of stainless steel Of square crosssection",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219110000",
    "Description": "Flatrolled pdts of stainless steel,width of =>600 m. not f/worked, thickness >10 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219120000",
    "Description": "Flatrolled pdts of stainless steel,width of =>600 m. not f/worked, thickness= >4.75<10mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219130000",
    "Description": "Flatrolled pdts of stainless steel,width of =>600 m. not f/worked, thickness= >4.75<10mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219140000",
    "Description": "Of a thickness of less than 3 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219210000",
    "Description": "Of a thickness exceeding 10 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219220000",
    "Description": "Of a thickness of 4.75 mm or more but not exceeding 10 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219230000",
    "Description": "Of a thickness of 3 mm or more but less than 4.75 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219240000",
    "Description": "Of a thickness of less than 3 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219310000",
    "Description": "Of a thickness of 4.75 mm or more",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219320000",
    "Description": "Of a thickness of 3 mm or more but less than 4.75 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219330000",
    "Description": "Of a thickness exceeding 1 mm but less than 3 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219340000",
    "Description": "Of a thickness of 0.5 mm or more but not exceeding 1 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219350000",
    "Description": "Of a thickness of less than 0.5 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7219900000",
    "Description": "Other Not further worked than coldrolled (coldreduced): Not specified",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7220110000",
    "Description": "Of a thickness of 4.75 mm or more",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7220120000",
    "Description": "Of a thickness of less than 4.75 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7220200000",
    "Description": "Not further worked than coldrolled (coldreduced)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7220900000",
    "Description": "Flatrolled products of stainless steel, Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7221000000",
    "Description": "Bars and rods, hotrolled, in irregularly wound coils, of stainless steel.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7222110000",
    "Description": "Of circular crosssection",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7222190000",
    "Description": "Other bars and rods of stainless steel;.. Other (Bars and rods, not further worked)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7222200000",
    "Description": "Bars and rods, not further worked than coldformed or coldfinished",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7222300000",
    "Description": "Other bars and rods",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7222400000",
    "Description": "Angles, shapes and sections",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7223000000",
    "Description": "Wire of stainless steel.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7224100000",
    "Description": "Ingots and other primary forms",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7224900000",
    "Description": "Other alloy steel ingots or other prim... Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7225110000",
    "Description": "Grainoriented",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7225190000",
    "Description": "Flatrolled products of siliconelectrical steel,>=600mm wide,(excl.grainoriented)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7225300000",
    "Description": "Other, not further worked than hotrolled, in coils",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7225400000",
    "Description": "Other, not further worked than hotrolled, not in coils",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7225500000",
    "Description": "Other, not further worked than coldrolled (coldreduced)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7225910000",
    "Description": "Electrolytically plated or coated with zinc",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7225920000",
    "Description": "Otherwise plated or coated with zinc",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7225990000",
    "Description": "Flatrolled products of other alloy steel Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7226110000",
    "Description": "Grainoriented",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7226190000",
    "Description": "Flatrolled products of other alloy steel Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7226200000",
    "Description": "Of high speed steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7226910000",
    "Description": "Not further worked than hotrolled",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7226920000",
    "Description": "Not further worked than coldrolled (coldreduced)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7226990000",
    "Description": "Flatrolled products of other alloy steel, <600mm wide, nes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7227100000",
    "Description": "Of high speed steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7227200000",
    "Description": "Of silicomanganese steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7227900000",
    "Description": "Other Bars and rods, hotrolled, in irregularly wound coi",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7228100000",
    "Description": "Bars and rods, of high speed steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7228200000",
    "Description": "Bars and rods, of silicomanganese steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7228300000",
    "Description": "Other bars and rods, not further worked than hotrolled, hotdrawn or extruded",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7228400000",
    "Description": "Other bars and rods, not further worked than forged",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7228500000",
    "Description": "Other bars and rods, not further worked than coldformed or coldfinished",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7228600000",
    "Description": "Other bars and rods",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7228700000",
    "Description": "Angles, shapes and sections",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7228800000",
    "Description": "Hollow drill bars and rods",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7229200000",
    "Description": "Of silicomanganese steel",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7229900000",
    "Description": "Wire of other alloy steel. Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7301100000",
    "Description": "Sheet piling of iron or steel",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7301200000",
    "Description": "Angles, shapes and sections of iron or steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7302100000",
    "Description": "Rails of iron or steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7302300000",
    "Description": "Switch blades, crossing frogs, point rods and other crossing pieces of iron or steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7302400000",
    "Description": "Fishplates and sole plates of iron or steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7302900000",
    "Description": "Railway or tramway track construction .. Other of iron or steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7303001000",
    "Description": "Of a kind used for supplies under pressure of cast iron",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7303009000",
    "Description": "Other Tubes, pipes and hollow profiles, of cast iron not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304110000",
    "Description": "Line pipe of a kind used for oil or gas pipelines : Of stainless steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304190000",
    "Description": "Other Line pipe of a kind used for oil or gas pipelines not specified.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304220000",
    "Description": "Drill pipe of stainless steel used in drilling for oil or gas :",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304230000",
    "Description": "Other drill pipe not stainless steel used in drilling for oil or gas :",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304240000",
    "Description": "Other,Casing, tubing of stainless steel of a kind used in drilling for oil or gas :",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304290000",
    "Description": "Other,Casing, tubing not of stainless steel of a kind used in drilling for oil or gas :",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304311000",
    "Description": "Circular crosssec., of iron/nonalloy steel, oldreduced,used for supplies under pressure",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304312000",
    "Description": "Circular crosssec., of iron/nonalloy steel, coldreducd,For manuf. of bicycle/motorcycle",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304319000",
    "Description": "Other,coldreduced Circular crosssec., of iron/nonalloy steel, not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304391000",
    "Description": "Other coldreduced crosssection, of iron or nonalloy steel for supplies under pressure",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304392000",
    "Description": "Other coldreduced iron/nonalloy steel, usd for manufacture of bicycles/m.cycle frame",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304399000",
    "Description": "Other coldreduced iron/nonalloy steel, usd for manufacturing not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304411000",
    "Description": "Circular crosssec., of stainless steel, coldreduced,used for supplies under pressure.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304419000",
    "Description": "Circular crosssec., of stainl. steel, coldreduced,not used for supplies under pressure.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304491000",
    "Description": "Circular crosssec., not specified,used for supplies under pressure.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304499000",
    "Description": "Circular crosssec., not specified,not used for supplies under pressure.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304511000",
    "Description": "Circular crosssec., of alloy steel,coldreduced, used for supplies under pressure.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304519000",
    "Description": "Circular crosssec., of alloy steel,coldreduced,not used for supplies under pressure.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304591000",
    "Description": "Circular crosssec., of alloy steel, not coldreduced, used for supplies under pressure.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304599000",
    "Description": "Circular crosssec., of alloy steel, not coldreduced, excl. for supplies under pressure.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7304900000",
    "Description": "Tubes, pipes and hollow profiles, seamless, of iron or steel. Not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7305110000",
    "Description": "Line pipes, ext. diameter >406.4mm; Longitudinally submerged arc welded",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7305120000",
    "Description": "Other Line pipes, ext. diameter >406.4mm; longitudinally welded",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7305190000",
    "Description": "Other tubes and pipes not specified in thid heading",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7305200000",
    "Description": "Casing of a kind used in drilling for oil or gas",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7305311000",
    "Description": "Casing used in drilling for oil/gas, L/welded, kind used for supplies under pressure",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7305319000",
    "Description": "Casing used in drilling oil/gas,L/ welded, kind not used for supplies under pressure",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7305391000",
    "Description": "Casing used in drilling for oil/gas, H/welded, kind used for supplies under pressure",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7305399000",
    "Description": "Casing used in drilling for oil/gasH/ welded, kind not used for supplies under pressure",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7305901000",
    "Description": "tube and pipe,ext. diameter > 406.4 mm, of iron/steel. used for supplies under pressure",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7305909000",
    "Description": "Tube and pipe,ext. diameter > 406.4 mm, of iron/steel. Excl. supplies under pressure",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7306110000",
    "Description": "Line pipe of a kind used for oil or gas pipelines , Welded, of stainless steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7306190000",
    "Description": "Line pipe of a kind used for oil or gas pipelines , Welded,not stainless steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7306210000",
    "Description": "Casing and tubing of a kind used in drilling for oil or gas: Welded, of stainless steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7306290000",
    "Description": "Casing and tubing of a kind used in drilling for oil or gas: Welded, not stainless steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7306301000",
    "Description": "Other, welded tube/pipe c/crosssect, iron/nonalloy steel,usd for supplies under pres..",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7306309000",
    "Description": "Other welded of iron or nonalloy steel not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7306401000",
    "Description": "Other, welded tube/pips c/crosssect, stainless steel,usd for supplies under pressure",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7306409000",
    "Description": "Other, welded tube/pips c/crosssect, stainless steel,usd for supplies under pressure",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7306501000",
    "Description": "Other, welded tube/pips c/crosssect, alloy steel,usd for supplies under pressure",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7306509000",
    "Description": "Other, welded tube/pips c/crosssect, alloy steel, not used for supplies under pressure",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7306610000",
    "Description": "Other, welded tube/pips sq/rect sect, alloy steel.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7306690000",
    "Description": "Other, welded tube/pips non circular sect, alloy steel.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7306900000",
    "Description": "Tubes, pipes and hollow profiles, riveted, of iron or steel, NES",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7307111000",
    "Description": "Tube/pipe fittings,cast fitting, non malleable cast iron. used for supplies under pres..",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7307119000",
    "Description": "Tube/pipe fitting,cast fitt., non malleable cast iron.not used for supplies under pres..",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7307190000",
    "Description": "Tube or pipe cast fittings of iron and stell not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7307210000",
    "Description": "Flanges of stainless steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7307220000",
    "Description": "Threaded elbows, bends and sleeves of stainless steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7307230000",
    "Description": "Butt welding fittings of stainless steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7307290000",
    "Description": "Other tube or pipe fittings of stainless steel not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7307910000",
    "Description": "Flanges of material not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7307920000",
    "Description": "Threaded elbows, bends and sleeves material not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7307930000",
    "Description": "Butt welding fittings material not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7307990000",
    "Description": "Other Tube or pipe fittings not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7308100000",
    "Description": "Bridges and bridgesections of iron and steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7308201000",
    "Description": "Towers and lattice masts: Of a kind used for the transportation of electricity",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7308209000",
    "Description": "Other Towers and lattice masts not specified",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7308300000",
    "Description": "Doors, windows and their frames and thresholds for doors of iron and steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7308400000",
    "Description": "Equipment for scaffolding, shuttering, propping or pitpropping of iron and steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7308901000",
    "Description": "Poles with or without lighting fittings of a height of 8 m or more of iron and steel",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7308909100",
    "Description": "Metallic pipes of iron and steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7308909900",
    "Description": "Other structures of iron and steel Not specified or included in this heading",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7309001000",
    "Description": "Reservoir, tanks, tubs of a minimum capacity of 50,000 l",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7309009000",
    "Description": "Other Reservoirs, tanks, vats and similar containers for any material not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7310100000",
    "Description": "Tanks, casks, drums, cans, boxes and similar containers Of a capacity of 50 l or more",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7310210000",
    "Description": "Tanks, casks, ... and similar containers <50l, Cans to be closed by soldering..",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7310290000",
    "Description": "Other Tanks, casks, drums, cans, boxes and similar container not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7311000000",
    "Description": "Containers for compressed or liquefied gas, of iron or steel.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "7312101000",
    "Description": "Brake and derailleur cables for bicycles and motorcycles",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7312109100",
    "Description": "Other galvanized steel cables for electricity",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7312109900",
    "Description": "Other Stranded wire, ropes and cables not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7312900000",
    "Description": "Other plaited bands, slings, etc, of iron or steel, not electically insulated",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7313000000",
    "Description": "Barbed wire of iron or steel; twisted hoop or single flat w",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "7314120000",
    "Description": "Endless bands for machinery, of stainless steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7314140000",
    "Description": "Other woven cloth, of stainless steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7314190000",
    "Description": "Cloth, grill, netting and fencing, of iron or steel wirenot specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7314200000",
    "Description": "Grill, netting of wire max. crosssect.dimension >3 mm mesh size of =>100 cm2",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "7314310000",
    "Description": "Grill, netting and fencing, welded at the intersection : Plated or coated with zinc",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "7314390000",
    "Description": "Other grill, netting and fencing, welded at the intersection not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "7314410000",
    "Description": "Other cloth, grill, netting and fencing : Plated or coated with zinc",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "7314420000",
    "Description": "Other cloth, grill, netting and fencing : Coated with plastics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "7314490000",
    "Description": "Other cloth, grill, netting and fencing :not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "7314500000",
    "Description": "Expanded metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7315111000",
    "Description": "Roller chain: For bicycles and motorcycles",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7315119000",
    "Description": "Other Roller chain not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7315120000",
    "Description": "Other chains not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7315191000",
    "Description": "Parts of Shackles",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7315199000",
    "Description": "Parts of Articulated link chain (excluding Shackles)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7315200000",
    "Description": "Skid chain",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7315810000",
    "Description": "Studlink",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7315820000",
    "Description": "Other, welded link",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7315890000",
    "Description": "Other Chain and parts thereo of iron or steel not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7315900000",
    "Description": "Other parts",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7316000000",
    "Description": "Anchors, grapnels and parts thereof, of iron or steel.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7317001000",
    "Description": "Nails and corrugated nails",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7317009000",
    "Description": "Other tacks, drawing pins, staples not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7318110000",
    "Description": "Coach screws",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7318120000",
    "Description": "Other wood screws",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7318130000",
    "Description": "Screw hooks and screw rings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7318140000",
    "Description": "Selftapping screws",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7318150000",
    "Description": "Other screws and bolts, whether or not with their nuts or washers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7318160000",
    "Description": "Nuts",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7318190000",
    "Description": "Other screws and bolts, whether or not with their nuts or washers not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7318210000",
    "Description": "Spring washers and other lock washers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7318220000",
    "Description": "Other washers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7318230000",
    "Description": "Rivets",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7318240000",
    "Description": "Cotters and cotterpins",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7318290000",
    "Description": "Screws, bolts, nuts, coach screws, screw Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7319400000",
    "Description": "Safety pins and other pins",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7319900000",
    "Description": "Sewingeedles, knitting needles, bodk.. Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7320100000",
    "Description": "Leafsprings and leaves therefor",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7320200000",
    "Description": "Helical springs",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7320900000",
    "Description": "Springsnd leaves for springs, of iron Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7321111100",
    "Description": "Cooking appliances/plate warmers for gas fuel or both gas and fuel, with 2 burner, CKD",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7321111900",
    "Description": "Cooking appliances/plate warmers for gas fuel or both gas and fuel, with 2 burner, FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7321119100",
    "Description": "Cooking appliances/plate warmers for gas fuel or both gas and fuel, >2 burner, CKD",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7321119900",
    "Description": "Cooking appliances/plate warmer for gas fuel or both gas and fuel, >2 burner, FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7321121000",
    "Description": "Cooking appliances/plate warmers : For liquid fuel, With a max of 2 burners",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7321129000",
    "Description": "Cooking appliances/plate warmers : For liquid fuel, With a > 2 burners",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7321190000",
    "Description": "Cooking appliances/plate warmers : for solid fuel.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7321810000",
    "Description": "Other Cooking appliances not specified, For gas fuel or for both gas and other fuels",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7321820000",
    "Description": "Other Cooking appliances not specified; For liquid fuel",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7321890000",
    "Description": "Other, Other Cooking appliances not specified; including appliances for solid fuel",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "7321900000",
    "Description": "Parts of Other Cooking appliances",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7322110000",
    "Description": "Of cast iron",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7322190000",
    "Description": "Radiators for central heating, not elect Radiat of a kind used for motor",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7322900000",
    "Description": "Radiators for central heating, not elect Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7323100000",
    "Description": "Iron or steel wool; pot scourers and scouring or polishing pads, gloves and the like",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7323910000",
    "Description": "Pot scourer and scouring/polishing pads, gloves and the like of cast iron, not enamelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7323920000",
    "Description": "Pot scourers and scouring or polishing pads, gloves and the like Of cast iron, enamelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7323930000",
    "Description": "Pot scourers and scouring or polishing pads, gloves and the like Of stainless steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7323940000",
    "Description": "Pot scourers or polishing pad and the like Of iron (excl cast iron) or steel, enamelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7323991000",
    "Description": "Galvanized articles",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7323999000",
    "Description": "Table, kitchen or household articles+other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7324100000",
    "Description": "Sinks and wash basins, of stainless steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7324210000",
    "Description": "Of cast iron, whether or not enamelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7324290000",
    "Description": "Sanitary ware and parts thereof, of iro.. Other(Baths)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7324901000",
    "Description": "Enamelled",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7324902000",
    "Description": "Galvanized",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7324909000",
    "Description": "Other sanitary ware not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7325100000",
    "Description": "Other cast articles of iron or steel. Of nonmalleable cast iron",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7325910000",
    "Description": "Grinding balls and similar articles for mills",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7325991000",
    "Description": "Accessories for electrical installations",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7325999000",
    "Description": "Other cast articles of iron or steel, Not specified or included in this heading",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7326110000",
    "Description": "Grinding balls and similar articles for mills",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7326190000",
    "Description": "Forged or stamped articles of iron or steel, nes",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7326201000",
    "Description": "Monofilament rods for tyres",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7326209000",
    "Description": "Other articles of iron or steel wire not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7326901000",
    "Description": "Accessories for the transmission of electrical energy",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7326909000",
    "Description": "Other articles of iron or steel not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7401000000",
    "Description": "Copper mattes; cement copper (precipitated copper).",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7402000000",
    "Description": "Unrefined copper; copper anodes for electrolytic refining.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7403110000",
    "Description": "Cathodes and sections of cathodes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7403120000",
    "Description": "Copper Wirebars",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7403130000",
    "Description": "Copper Billets",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7403190000",
    "Description": "Other Refined copper not specified.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7403210000",
    "Description": "Copperzinc base alloys (brass)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7403220000",
    "Description": "Coppertin base alloys (bronze)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7403290000",
    "Description": "Other copper alloys (other than master alloys of heading 74.05)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7404000000",
    "Description": "Copper waste and scrap.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7405000000",
    "Description": "Master alloys of copper.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7406100000",
    "Description": "Copper Powders of nonlamellar structure",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7406200000",
    "Description": "Copper Powders of lamellar structure; flakes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7407100000",
    "Description": "bars, rods and profile Of refined copper",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7407210000",
    "Description": "bars, rods and profile Of copperzinc base alloys (brass)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7407290000",
    "Description": "Copper bars, rods and profiles not specified",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7408110000",
    "Description": "Copper wire of Refined copper of which the maximum crosssectional dimension exceeds 6 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7408190000",
    "Description": "Copper wire of Refined copper not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7408210000",
    "Description": "Copper wire of copperzinc base alloys (brass)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7408220000",
    "Description": "Copper wire of coppernickel base alloys (cupronickel) or coppernickelzinc base alloys",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7408290000",
    "Description": "Other copper wire of copper alloys not specified.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7409110000",
    "Description": "Copper plates, sheets.., of a thickness >0.15 mm,of refined copper, In coils",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7409190000",
    "Description": "Copper plates, sheets.., of a thickness >0.15 mm,of refined copper,not In coils",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7409210000",
    "Description": "In coils",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7409290000",
    "Description": "Copper plates, sheets and stripe, of a t Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7409310000",
    "Description": "Copper plates, sheets.., of a thickness >0.15 mm, of copperzinc base alloys: In coils",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7409390000",
    "Description": "Copper plates, sheets and stripe, of a t Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7409400000",
    "Description": "Copper plates of coppernickel base alloys or coppernickelzinc base alloys",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7409900000",
    "Description": "Copper plates of other copper alloys",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7410110000",
    "Description": "Copper foil, Not backed of refined copper",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7410120000",
    "Description": "Copper foil, Not backed of copper alloys",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7410210000",
    "Description": "Copper foil, backed of refined copper",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7410220000",
    "Description": "Copper foil, backed of copper alloys",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7411100000",
    "Description": "Copper tubes and pipes of refined copper",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7411210000",
    "Description": "Copper tubes and pipes. copperzinc base alloys (brass)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7411220000",
    "Description": "Copper tubes and pipes. coppernickel base alloys (cupronickel) or copperni",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7411290000",
    "Description": "Other Copper tubes and pipes not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7412100000",
    "Description": "Copper tube or pipe fittings (for example, couplings, elbows...). Of refined copper",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7412200000",
    "Description": "Copper tube or pipe fittings (for example, couplings, elbows, sleeves). copper alloys",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7413000000",
    "Description": "Stranded wire, cable, plaited bands and the like, of copper, not electrically insulated.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7415100000",
    "Description": "Nails and tacks, drawing pins, staples and similar articles copper",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7415210000",
    "Description": "Washers (including spring washers)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7415290000",
    "Description": "Non threaded articles such as rivets, cotters, cotterpins... of copper not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7415330000",
    "Description": "Screws; bolts and nuts copper",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7415390000",
    "Description": "Other threaded articles not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7418100000",
    "Description": "Table, kitchen or other household articles and parts thereof of copper",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7418200000",
    "Description": "Sanitary ware and parts thereof of copper",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7419200000",
    "Description": "- Cast, moulded, stamped or forged, but not further worked",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7419800000",
    "Description": "--  Reservoirs, tanks and other containers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7419802000",
    "Description": "--  Accessories for the transmission of electrical energy",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7419809000",
    "Description": "--  Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "7501100000",
    "Description": "Nickel mattes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7501200000",
    "Description": "Nickel oxide sinters and other intermediate products of nickel metallurgy",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7502100000",
    "Description": "Nickel, not alloyed",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7502200000",
    "Description": "Nickel alloys",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7503000000",
    "Description": "Nickel waste and scrap.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7504000000",
    "Description": "Nickel powders and flakes.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7505110000",
    "Description": "Bars, rods and profiles. Of nickel, not alloyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7505120000",
    "Description": "Nicke Bars, rods and profiles. Of nickel alloys",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7505210000",
    "Description": "Wire : Of nickel, not alloyed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7505220000",
    "Description": "Wire : Of nickel alloys",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7506100000",
    "Description": "Nickel plates, sheets, strip and foil. Of nickel, not alloyed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7506200000",
    "Description": "Nickel plates, sheets, strip and foil. Of nickel alloys",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7507110000",
    "Description": "Nickel plates, sheets, strip and foil. Of nickel, not alloyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7507120000",
    "Description": "Nickel plates, sheets, strip and foil. Of nickel alloys",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7507200000",
    "Description": "Tube or pipe fittings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7508100000",
    "Description": "Cloth, grill and netting, of nickel wire",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 10,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "7508901000",
    "Description": "Frames for textile designs",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7508909000",
    "Description": "Other articles of nickel not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7601100000",
    "Description": "Unwrought aluminium., not alloyed",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7601200000",
    "Description": "Unwrought aluminium. alloys",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7602000000",
    "Description": "Aluminium waste and scrap.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7603100000",
    "Description": "Aluminium Powders of nonlamellar structure",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 30,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7603200000",
    "Description": "Aluminium Powders of lamellar structure; flakes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7604100000",
    "Description": "bars, rods and profile Of aluminium, not alloyed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7604210000",
    "Description": "Hollow profiles Of aluminium alloys :",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7604290000",
    "Description": "Other aluminium alloys not specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7605110000",
    "Description": "Aluminium wire not alloyed Of which the maximum crosssectional dimension exceeds 7 mm",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7605190000",
    "Description": "Aluminium wire not alloyed, not specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7605210000",
    "Description": "Aluminium wire, alloyed Of which the maximum crosssectional dimension exceeds 7 mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7605290000",
    "Description": "Other aluminium wire, alloyed not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7606111000",
    "Description": "Aluminium plates,not alloyed, sheets.. thickness> 0.2 mm, rectangular/square Corrugated",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7606119000",
    "Description": "Aluminium plates,notalloyed, sheets.. Thick..> 0.2 mm, rectangular/square,not corrugated",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7606121000",
    "Description": "Aluminium plates,Alloyed, sheets.. thickness> 0.2 mm, rectang/square Corrugated",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7606129000",
    "Description": "Aluminium plates,Alloyed, sheets.. thickness> 0.2 mm, rectang/square,not corrugated",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7606911000",
    "Description": "Aluminium plates,not Alloyed, sheets.. thickness> 0.2 mm, rectang/square Corrugated",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7606919100",
    "Description": "Aluminium plates not Alloyed, sheets., > 0.2 mm, rect/sq, Painted, coated or varnished",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 30,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7606919900",
    "Description": "Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7606921000",
    "Description": "Aluminium plates,Alloyed, sheets., > 0.2 mm, rect/sq, Corrugated",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7606929100",
    "Description": "Aluminium plates,Alloyed, sheets., > 0.2 mm, rect/sq, Painted, coated or varnished",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 30,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7606929900",
    "Description": "Aluminium plates,Alloyed, sheets., > 0.2 mm, rect/sq, Plain not coated/painted",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 30,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7607110000",
    "Description": "Aluminium foil, not backed, Rolled but not further worked",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7607191000",
    "Description": "Aluminium foil, not backed, Rolled, Printed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7607199000",
    "Description": "Other Aluminium foil non backed Not specified or included in this subheading",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7607201000",
    "Description": "Aluminium foil backed, Rolled but not further worked Printed",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7607209000",
    "Description": "Other Aluminium foil backed Not specified or included in this subheading",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7608100000",
    "Description": "Aluminium tubes and pipes. Of aluminium, not alloyed",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7608200000",
    "Description": "Aluminium tubes and pipes. Of aluminium alloys",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7609000000",
    "Description": "Aluminium tube or pipe fittings (for example, couplings, elbows, sleeves).",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7610100000",
    "Description": "Doors, windows and their frames and thresholds for doors",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7610900000",
    "Description": "Aluminium structures (excluding prefabricated buildings of heading 94.06)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7611000000",
    "Description": "Aluminium reservoirs..and similar cont, capacity exceeding 300 l, not fitted",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7612100000",
    "Description": "Aluminium Collapsible tubular containers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "7612901000",
    "Description": "Aluminium Cans",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7612909000",
    "Description": "Other aluminium containers not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7613000000",
    "Description": "Aluminium containers for compressed or liquefied gas.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7614100000",
    "Description": "Stranded wire, cables, plaited bands and the like, of aluminium With steel core",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7614901000",
    "Description": "Aluminium neutral conductors with lead waterproof casing",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7614909000",
    "Description": "Other aluminium stranded wire, cables not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7615101000",
    "Description": "Pot scourers and scouring or polishing pads, gloves and the like of aluminium.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7615109000",
    "Description": "Other household articles not specified of aluminium.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7615200000",
    "Description": "Sanitary ware and parts thereof of aluminium.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7616100000",
    "Description": "Nails, tacks, staples (other than 83.05), screws, ... and similar articles of alluminium",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7616910000",
    "Description": "Cloth, grill, netting and fencing, of aluminium wire",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7616991000",
    "Description": "Accessories for the transmission of electrical energy of alluminium",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7616999000",
    "Description": "Other article of aluminium NES",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7801100000",
    "Description": "Refined lead",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7801910000",
    "Description": "Unwrought lead Containing by weight antimony as the principal other element",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7801990000",
    "Description": "Unwrought lead (excl. refined and containing antimony)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7802000000",
    "Description": "Lead waste and scrap.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7804110000",
    "Description": "Lead Sheets, strip and foil of a thickness (excluding any backing) not exceeding 0.2 mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7804190000",
    "Description": "Lead plates, sheets, str and foil; .. Other (Plates, sheets, strip and foil)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7804200000",
    "Description": "Lead Powders and flakes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7806001000",
    "Description": "Lead bars, rods, profiles, and wire",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7806002000",
    "Description": "Lead weights for fishing nets",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7806009000",
    "Description": "Other articles of lead not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7901110000",
    "Description": "Unwrought zinc. Containing by weight 99.99 % or more of zinc",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "7901120000",
    "Description": "Unwrought zinc. Containing by weight less than 99.99 % of zinc",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7901200000",
    "Description": "Unwrought zinc. Zinc alloys",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7902000000",
    "Description": "Zinc waste and scrap.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7903100000",
    "Description": "Zinc dust",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7903900000",
    "Description": "Zinc dust, powders and flakes. Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7904000000",
    "Description": "Zinc bars, rods, profiles and wire.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7905000000",
    "Description": "Zinc plates, sheets, strip and foil.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7907001000",
    "Description": "Articles of zinc other than squares or rectangles (zinc pastilles)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "7907009000",
    "Description": "Other articles of zinc not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8001100000",
    "Description": "Tin, not alloyed",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8001200000",
    "Description": "Tin alloys",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8002000000",
    "Description": "Tin waste and scrap.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8003000000",
    "Description": "Tin bars, rods, profiles and wire.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8007000000",
    "Description": "Other articles of tin.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8101100000",
    "Description": "Powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8101940000",
    "Description": "Unwrought tungsten, including bars and rods obtained simply by sintering",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8101960000",
    "Description": "Tungsten (wolfram) Wire",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8101970000",
    "Description": "Tungsten (wolfram) Waste and scrap",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8101991000",
    "Description": "Bars and rods, other than those obtained simply by simply by sintering, profiles..",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8101999000",
    "Description": "Other Tungsten (wolfram) and articles not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8102100000",
    "Description": "Molybdenum and articles thereof, including waste and scrap in Powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8102940000",
    "Description": "Unwrought molybdenum, including bars and rods obtained simply by sintering",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8102950000",
    "Description": "Bars and rods, other than those obtained simply by sinte",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8102960000",
    "Description": "Molybdenum Wire",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8102970000",
    "Description": "Molybdenum and article Waste and scrap",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8102990000",
    "Description": "Other Molybdenum and articles thereof, not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8103200000",
    "Description": "Unwrought tantalum, including bars and rods obtained simply by sintering; powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8103300000",
    "Description": "Tantalum and articles Waste and scrap",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8103910000",
    "Description": "-- Crucibles",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8103990000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8104110000",
    "Description": "Unwrought magnesium Containing at least 99.8 % by weight of magnesium",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8104190000",
    "Description": "Other Unwrought magnesium, containing <99.8% pure",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8104200000",
    "Description": "Magnesium and articles Waste and scrap",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8104300000",
    "Description": "Raspings, turnings and granules, graded according to size; powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8104900000",
    "Description": "Other magnesium and articles not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8105200000",
    "Description": "Cobalt mattes and other products of cobalt metallurgy; unwrought cobalt; powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8105300000",
    "Description": "Cobalt mattes Waste and scrap",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8105900000",
    "Description": "Other Cobalt mattes and other intermediate products not specified",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8106100000",
    "Description": "- Containing more than 99.99 % of bismuth, by weight",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8106900000",
    "Description": "- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8108200000",
    "Description": "Unwrought titanium; powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8108300000",
    "Description": "Waste and scrap",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8108900000",
    "Description": "Other Titanium and articles thereof not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8109210000",
    "Description": "-- Containing less than 1 part hafnium to 500 parts zirconium by weight",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8109290000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8109310000",
    "Description": "-- Containing less than 1 part hafnium to 500 parts zirconium by weight",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8109390000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8109910000",
    "Description": "-- Containing less than 1 part hafnium to 500 parts zirconium by weight",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8109990000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8110100000",
    "Description": "Unwrought antimony; powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8110200000",
    "Description": "Waste and scrap",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8110900000",
    "Description": "Antimony a articles thereof, including waste and scrap. Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8111000000",
    "Description": "Manganese and articles thereof, including waste and scrap.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8112120000",
    "Description": "Unwrought; powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8112130000",
    "Description": "Waste and scrap",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8112190000",
    "Description": "Beryllium, chromium, germanium, vanadium, gallium, hafnium, indium, niobi",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8112210000",
    "Description": "Unwrought; powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8112220000",
    "Description": "Waste and scrap",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8112290000",
    "Description": "Beryllium, chromm, germanium, vanadium, gallium, hafnium, indium, niobiu",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8112310000",
    "Description": "-- Unwrought; waste and scrap; powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8112390000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8112410000",
    "Description": "-- Unwrought; waste and scrap; powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8112490000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8112510000",
    "Description": "Unwrought; powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8112520000",
    "Description": "Waste and scrap",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8112590000",
    "Description": "Other thallium and articles thereof",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8112610000",
    "Description": "-- Waste and scrap",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8112690000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8112920000",
    "Description": "Unwrought; waste and scrap; powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8112990000",
    "Description": "Germanium, vanadium, gallium, hafnium, indium, niobium (",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8113000000",
    "Description": "Cermets and articles thereof, including waste and scrap.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8201101000",
    "Description": "Blanks spades and shovels",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8201109000",
    "Description": "Other spade and shovels not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8201301000",
    "Description": "Blanks; Mattocks, picks, hoes and rakes",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8201309000",
    "Description": "Other Mattocks, picks, hoes and rakes not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8201401000",
    "Description": "Blank matchets",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8201402000",
    "Description": "Blank axes, bill hooks and other similar hewing tools",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8201409000",
    "Description": "Other Axes, bill hooks and similar hewing tools not specied.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8201501000",
    "Description": "Blanks; Secateurs and similar onehanded pruners and shears (including poultry shears)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8201509000",
    "Description": "Secateurs and similar onehanded pruners and shears not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8201601000",
    "Description": "Blanks; Hedge shears, twohanded pruning shears and similar twohanded shears",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8201609000",
    "Description": "Other Hedge shear not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8201901000",
    "Description": "Blanks of a kind used in agriculture, horticulture or forestry",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8201909000",
    "Description": "Other hand tools not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8202100000",
    "Description": "Hand saws",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8202200000",
    "Description": "Band saw blades",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8202310000",
    "Description": "Circular saw blades (including slitting /slotting blades)with working part of steel",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8202390000",
    "Description": "Other,Circular saw blades not specified, including parts",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8202400000",
    "Description": "Chain saw blades",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8202910000",
    "Description": "Straight saw blades, for working metal",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8202990000",
    "Description": "Other saw blades excl. straight saw blade",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8203100000",
    "Description": "Files, rasps and similar tools",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8203200000",
    "Description": "Pliers (including cutting pliers), pincers, tweezers and similar tools",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8203300000",
    "Description": "Metal cutting shears and similar tools",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8203400000",
    "Description": "Pipecutters, bolt croppers, perforating punches and similar tools",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8204110000",
    "Description": "Nonadjustable Handoperated spanners and wrenches ",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8204120000",
    "Description": "Adjustable Handoperated spanners and wrenches ",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8204200000",
    "Description": "Interchangeable spanner sockets, with or without handles",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8205100000",
    "Description": "Drilling, threading or tapping tools",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8205200000",
    "Description": "Hammers and sledge hammers",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8205300000",
    "Description": "Planes, chisels, gouges and similar cutting tools for working wood",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8205400000",
    "Description": "Screwdrivers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8205511000",
    "Description": "Flat irons, nonelectric hand tools (including glaziers' diamonds) ",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8205519000",
    "Description": "Other household hand tools not specified in this subheading",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8205590000",
    "Description": "Other hand tools(including glaziers'diamonds) not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8205600000",
    "Description": "Blow lamps",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8205700000",
    "Description": "Vices, clamps and the like",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8205900000",
    "Description": "Other, including sets of articles of two or more of subheadings of this heading",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8206000000",
    "Description": "Tools of two or more of the headings 82.02 to 82.05, put up in sets for retail sale.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8207130000",
    "Description": "Interchangeable Rock drilling or earth boring tools with working part of cermets",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8207190000",
    "Description": "Other Interchangeable Rock drilling or earth boring tools not specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8207200000",
    "Description": "Interchangeable Dies for drawing or extruding metal",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8207300000",
    "Description": "Interchangeable Tools for pressing, stamping or punching",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8207400000",
    "Description": "Interchangeable Tools for tapping or threading",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8207500000",
    "Description": "Interchangeable Tools for drilling, other than for rock drilling",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8207600000",
    "Description": "Interchangeable Tools for boring or broaching",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8207700000",
    "Description": "Interchangeable Tools for milling",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8207800000",
    "Description": "Interchangeable Tools for turning",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8207900000",
    "Description": "Other interchangeable tools not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8208100000",
    "Description": "Knives and cutting blades, for metal working",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8208200000",
    "Description": "Knives and cutting blades, for wood working",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8208300000",
    "Description": "Knives and cutting blades, for kitchen appliances or for machines used by food industry",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8208400000",
    "Description": "Knives and cutting blades, for agricultural, horticultural or forestry machines",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8208900000",
    "Description": "Other Knives and cutting blades, for machines or for mechanical appliances not specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8209000000",
    "Description": "Plates, sticks, tips and the like for tools, unmounted, of cermets.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8210000000",
    "Description": "Handoperated mechanical appliances, weighing 10 kg or less",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8211100000",
    "Description": "Sets of assorted articles",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8211910000",
    "Description": "Table knives having fixed blades",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8211920000",
    "Description": "Other knives having fixed blades",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8211930000",
    "Description": "Knives having other than fixed blades",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8211940000",
    "Description": "Blades",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8211950000",
    "Description": "Handles of base metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8212100000",
    "Description": "Razors",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8212200000",
    "Description": "Safety razor blades, including razor blade blanks in strips",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8212901000",
    "Description": "Razor heads for industries",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8212909000",
    "Description": "Other parts of razors and razor blades not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8213000000",
    "Description": "Scissors, tailors' shears and similar shears, and blades therefor.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8214100000",
    "Description": "Paper knives, letter openers, erasing knives, pencil sharpeners and blades therefor",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8214200000",
    "Description": "Manicure or pedicure sets and instruments (including nail files)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8214900000",
    "Description": "Other articles of heading 82.14 not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8215100000",
    "Description": "Sets of assorted articles containing at least one article plated with precious metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8215200000",
    "Description": "Other sets of assorted articles not plated with precious metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8215910000",
    "Description": "Other articles (not in set) plated with precious metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8215990000",
    "Description": "Other articles (not in set)not plated with precious metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8301100000",
    "Description": "Padlocks",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8301200000",
    "Description": "Locks of a kind used for motor vehicles",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8301300000",
    "Description": "Locks of a kind used for furniture",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8301400000",
    "Description": "Other locks not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8301500000",
    "Description": "Clasps and frames with clasps, incorporating locks",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8301600000",
    "Description": "Parts of padlocks and locks",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8301700000",
    "Description": "Keys presented separately",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8302100000",
    "Description": "Hinges",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8302200000",
    "Description": "Castors",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8302300000",
    "Description": "Other mountings, fittings and similar articles suitable for motor vehicles",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8302410000",
    "Description": "Mountings, fittings and similar articles Suitable for buildings",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8302420000",
    "Description": "Other mountings, fittings and similar articles, suitable for furniture",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8302490000",
    "Description": "Other mountings, fittings and similar articles not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8302500000",
    "Description": "Hatracks, hatpegs, brackets and similar fixtures",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8302600000",
    "Description": "Automatic door closers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8303000000",
    "Description": "Armored or reinforced safe, strongboxes,doors and safe deposit lockers for strongrooms..",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8304000000",
    "Description": "Filing/cardindex cabinets. .. and similar office equip. of base metal, excl. 94.03.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8305100000",
    "Description": "Fittings for looseleaf binders or files",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8305200000",
    "Description": "Staples in strips",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8305900000",
    "Description": "Letter clips, letter corners...and similar office article, of base metal, including part",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8306100000",
    "Description": "Bells, gongs and the like",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8306210000",
    "Description": "Statuettes and other ornaments Plated with precious metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8306290000",
    "Description": "Statuettes and other ornaments not plated with precious metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8306300000",
    "Description": "Photograph, picture or similar frames; mirrors",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8307101000",
    "Description": "Cable sheaths of iron or steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8307109000",
    "Description": "Other flexible tubing of iron or steel excl. Cable sheaths",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8307900000",
    "Description": "Flexible tubing of other base metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8308100000",
    "Description": "Hooks, eyes and eyelets",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8308200000",
    "Description": "Tubular or bifurcated rivets",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8308900000",
    "Description": "Other articles of 83.08 not specified, including parts",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8309101000",
    "Description": "Crown corks for pharmaceutical industry",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "8309109000",
    "Description": "Other crown corks not specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "8309901000",
    "Description": "Other articles of heading 83.09 for pharmaceutical industry",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "8309909000",
    "Description": "Other articles of heading 83.09 not for pharmaceutical industry",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8310000000",
    "Description": "Signplates, nameplates, addressplates and similar plates of base metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8311100000",
    "Description": "Coated electrodes of base metal, for electric arcwelding",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8311200000",
    "Description": "Cored wire of base metal, for electric arcwelding",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8311300000",
    "Description": "Coated rods and cored wire, of base metal, for soldering, brazing or welding by flame",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8311900000",
    "Description": "Other articles of heading 83.11 not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8401100000",
    "Description": "Nuclear reactors",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8401200000",
    "Description": "Machinery and apparatus for isotopic separation, and parts thereof",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8401300000",
    "Description": "Fuel elements (cartridges), non-irradiated",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8401400000",
    "Description": "Parts of nuclear reactors",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8402110000",
    "Description": "Watertube boilers with a steam production exceeding 45 t per hour",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8402120000",
    "Description": "Watertube boilers with a steam production not exceeding 45 t per hour",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8402190000",
    "Description": "Other vapour generating boilers, including hybrid boilers",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8402200000",
    "Description": "Superheated water boilers",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8402900000",
    "Description": "Parts of articles of headind 84.02",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8403100000",
    "Description": "Boilers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8403900000",
    "Description": "Parts Central heating boilers other than those of heading 84.02.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8404100000",
    "Description": "Auxiliary plant for use with boilers of heading 84.02 or 84.03",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8404200000",
    "Description": "Condensers for steam or other vapour power units",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8404900000",
    "Description": "Parts of article of heading 84.04",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8405100000",
    "Description": "Producer/acetylene gas or water gas generators, with or without their purifiers;",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8405900000",
    "Description": "Parts of article of heading 84.05",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8406100000",
    "Description": "Other turbines for marine propulsion",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8406810000",
    "Description": "Other turbines for an output exceeding 40 MW",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8406820000",
    "Description": "Other turbines for an output not exceeding 40 MW",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8406900000",
    "Description": "Parts of Steam turbines and other vapour turbines.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8407100000",
    "Description": "Aircraft engines",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8407210000",
    "Description": "Outboard motors",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8407290000",
    "Description": "Sparkignition reciprocating or rotary internal combustion piston engines",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8407311000",
    "Description": "Recipr.. piston engine, for veh. of Chapter 87, capacity-<50cc, For assembly industries",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8407319000",
    "Description": "Reciprocating piston engine used for vehicles of Chapter 87;not for assembly industries",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8407321000",
    "Description": "Recip.. piston engine, for veh. of Chapter 87, capacity>50<250cc, For assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8407329000",
    "Description": "Recip.. piston engine, for veh. of Chapter 87, capacity>50<250cc",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8407330000",
    "Description": "Recip.. piston engine, for veh. of Chapter 87, capacity>250<1000cc",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8407340000",
    "Description": "Recip.. piston engine, for veh. of Chapter 87, capacity>1000cc",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8407900000",
    "Description": "Other engines not specified in 84.07",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8408100000",
    "Description": "Marine propulsion engines with diesel or sem-idiesel engines",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8408200000",
    "Description": "Diesel or semi-diesel Engines of a kind used for propulsion of vehicles of Chapter 87",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8408900000",
    "Description": "Other diesel or sem-idiesel engines not specified 84.08",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8409100000",
    "Description": "Parts suitable for use solely or principally for aircraft engines",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8409910000",
    "Description": "Suitable for use solely or principally with petrol fuel engines",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8409990000",
    "Description": "Parts for use principally with diesel or sem-idiesel engines excl. aircraft engine",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8410110000",
    "Description": "Hydraulic turbines and water wheels of a power =< 1,000 kW",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8410120000",
    "Description": "Hydraulic turbines and water wheels of a power >1,000 kW =< 10,000 kW",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8410130000",
    "Description": "Hydraulic turbines and water wheels of a power > 10,000 kW",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8410900000",
    "Description": "Parts, including regulators of Hydraulic turbines, water wheels, and regulators therefor",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8411110000",
    "Description": "Turbojets of a thrust not exceeding 25 kN",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8411120000",
    "Description": "Turbojets of a thrust exceeding 25 kN",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8411210000",
    "Description": "Turbopropellers of a power not exceeding 1,100 kW",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8411220000",
    "Description": "Turbopropellers of a power exceeding 1,100 kW",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8411810000",
    "Description": "Other gas turbines not specified of a power not exceeding 5,000 kW",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8411820000",
    "Description": "Other gas turbines not specified of a power exceeding 5,000 kW",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8411910000",
    "Description": "Parts of turbojets or turbopropellers",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8411990000",
    "Description": "Parts of other gas turbines not specified.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8412100000",
    "Description": "Reaction engines other than turbojets",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8412210000",
    "Description": "Linear acting (cylinders) Hydraulic power engines and motors",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8412290000",
    "Description": "Other Hydraulic power engines and motors not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8412310000",
    "Description": "Linear acting (cylinders) Pneumatic power engines and motors",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8412390000",
    "Description": "Other Pneumatic power engines and motors not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8412800000",
    "Description": "Other Engines and motors NES",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8412900000",
    "Description": "Parts ofother engines and motors not specified.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8413110000",
    "Description": "Pumps for dispensing fuel or lubricants, used in filling-stations or in garages",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8413190000",
    "Description": "Pumps fitted with a measuring device, not used in filling-stations/garages",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8413200000",
    "Description": "Hand pumps, other than those of subheading 8413.11 or 8413.19",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8413300000",
    "Description": "Pumps for fuel, lubricating or cooling medium for internal combustion piston engines",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8413400000",
    "Description": "Concrete pumps",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8413500000",
    "Description": "Other reciprocating positive displacement pumps",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8413600000",
    "Description": "Other rotary positive displacement pumps",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8413700000",
    "Description": "Other centrifugal pumps",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8413810000",
    "Description": "Other Pumps not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8413820000",
    "Description": "Pumps for Liquid elevators",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8413911000",
    "Description": "Parts of pump for motor vehicles",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8413912000",
    "Description": "Parts of pump for hand pumps",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8413919000",
    "Description": "Parts of pump for not specified",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8413920000",
    "Description": "Parts of pump for liquid elevators",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8414100000",
    "Description": "Vacuum pumps",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8414201000",
    "Description": "Hand or footoperated air pumps for the inflation of tyres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8414209000",
    "Description": "Other hand or foot-operated air pumps not specified",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8414301000",
    "Description": "Compressors of a kind used in refrigerating equipment; for the assembly industries",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8414309000",
    "Description": "Compressors of a kind used in refrigerating equipment;not for the assembly industries",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8414400000",
    "Description": "Air compressors mounted on a wheeled chassis for towing",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8414510000",
    "Description": "floor,wall, window, ceiling/roof fans, with a selfcontained electric motor =< 125 W",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8414590000",
    "Description": "Other fans not specified",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8414600000",
    "Description": "Hoods having a maximum horizontal side not exceeding 120 cm",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8414700000",
    "Description": "- Gas-tight biological safety cabinets",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/01/2022"
  },
  {
    "CET Code": "8414801000",
    "Description": "Industrial air compressors",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8414809000",
    "Description": "Other air compressors not specified",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8414901000",
    "Description": "Parts of sub heading 8414.20 to 8414.51",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8414909000",
    "Description": "Other parts of articles of this heading not specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8415101000",
    "Description": "Air conditioning machine, Window/wall types, selfcontained/\"splitsystem\", Presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8415109000",
    "Description": "Air conditioning machine, Window/wall types, selfcontained/\"splitsystem\", Presented FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8415200000",
    "Description": "Air conditioning machine of a kind used for persons, in motor vehicles",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8415810000",
    "Description": "Air conditioning machine, Incorporating a refrigerating unit and reversible heat pumps",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8415820000",
    "Description": "Other, air conditioning machine, incorporating a refrigerating unit",
    "SU": "U",
    "ID": 20,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8415830000",
    "Description": "Air conditioning machine, not incorporating a refrigerating unit",
    "SU": "U",
    "ID": 20,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8415901000",
    "Description": "Parts of air conditioning machine for the assembly industry",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8415909000",
    "Description": "Parts of air conditioning machine not for the assembly industry",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8416100000",
    "Description": "Furnace burners for liquid fuel",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8416200000",
    "Description": "Other furnace burners, including combination burners",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8416300000",
    "Description": "Mechanical stokers, including mech. grates and ash dischargers and similar appliances",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8416900000",
    "Description": "Parts of article of heading 84.16",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8417100000",
    "Description": "Furnaces and ovens for the roasting, melting or heat-treatment of ores, pyrites or metal",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8417200000",
    "Description": "Bakery ovens, including biscuit ovens",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8417800000",
    "Description": "Other industrial or laboratory furnaces and ovens, including incinerators NES",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8417900000",
    "Description": "Parts of article of heading 84.17",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8418101000",
    "Description": "Combined refrigeratorfreezers, fitted with separate external doors: Presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8418109000",
    "Description": "Combined refrigeratorfreezers, fitted with separate external doors: Presented FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8418211000",
    "Description": "Refrigerators, household type, Compressiontype: Presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8418219000",
    "Description": "Refrigerators, household type, Compressiontype: PresentedFBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8418291000",
    "Description": "Refrigerators, household type, non-compression-type Presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8418299000",
    "Description": "Refrigerators, household type, non-compression-type Presented FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8418301000",
    "Description": "Freezers of the chest type, not exceeding 800 l capacity: Presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8418309000",
    "Description": "FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8418401000",
    "Description": "Freezers of the upright type, not exceeding 900 l capacity: Presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8418409000",
    "Description": "Freezers of the upright type, not exceeding 900 l capacity: Presented FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8418501000",
    "Description": "Other furniture for storage/display, with refrig./freezing equipmt presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8418509000",
    "Description": "Other furniture for storage/display, with refrig./freezing equipmt presented FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8418610000",
    "Description": "Heat pumps other than air conditioning machines of heading 84.15",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8418690000",
    "Description": "Refrigerators,freezers and other refrig... Other(Heat pumps type)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8418910000",
    "Description": "Furniture designed to receive refrigerating or freezing equipment",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8418990000",
    "Description": "Other parts of refrigerators not specified.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8419110000",
    "Description": "Instantaneous gas water heaters",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8419120000",
    "Description": "-- Solar water heaters",
    "SU": "U",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8419190000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8419200000",
    "Description": "Medical, surgical or laboratory sterilisers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8419330000",
    "Description": "-- Lyophilisation apparatus, freeze drying units and spray dryers",
    "SU": "U",
    "ID": 5,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8419340000",
    "Description": "-- Other, for agricultural products",
    "SU": "U",
    "ID": 5,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8419350000",
    "Description": "-- Other, for wood, paper pulp, paper or paperboard",
    "SU": "U",
    "ID": 5,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8419390000",
    "Description": "Other Dryers not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8419400000",
    "Description": "Distilling or rectifying plant",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8419500000",
    "Description": "Heat exchange units",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8419600000",
    "Description": "Machinery for liquefying air or other gases",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "22/12/2020"
  },
  {
    "CET Code": "8419810000",
    "Description": "Other machinery, plant and equipment making hot drinks or for cooking or heating food",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8419890000",
    "Description": "Other machinery, plant or laboratory equipmentpment not specified",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8419900000",
    "Description": "Parts of article of heading 8419",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8420100000",
    "Description": "Calendering or other rolling machines",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8420910000",
    "Description": "Cylinders (Part of Calendering or other rolling machines)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8420990000",
    "Description": "Parts of Calendering or other rolling machines not specified",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8421110000",
    "Description": "Cream separators",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8421120000",
    "Description": "Clothes-dryers",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8421190000",
    "Description": "Other Centrifuges, including centrifugal dryers: Not specified",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8421211000",
    "Description": "Filtering or purifying machinery and apparatus for liquids; Domestic type",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8421219000",
    "Description": "Filtering or purifying machinery and apparatus for liquids; Other industrial type",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8421221000",
    "Description": "Centrifuges for filtering or purifying beverages other than water:; Domestic type",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8421229000",
    "Description": "Centrifuges for filtering/purifying beverages other than water; Other industrial type",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8421230000",
    "Description": "Oil or petrol-filters for internal combustion engines",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8421290000",
    "Description": "Oil or petrol-filters, not for internal combustion engines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8421310000",
    "Description": "Intake air filters for internal combustion engines",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8421320000",
    "Description": "-- Catalytic converters or particulate filters, whether or not combined, for purifying o",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8421391000",
    "Description": "\"Filter dryers\" for refrigerators and freezers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8421399000",
    "Description": "Other Filtering or purifying machinery and apparatus for gases not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8421910000",
    "Description": "Parts of centrifuges, including centrifugal dryers",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8421990000",
    "Description": "Parts of Centrifuges, including centrifugal dryers...for liquids or gases. NES",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8422110000",
    "Description": "Dish washing machines  Of the household type",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8422190000",
    "Description": "Dish washing machines  Of the industrial type",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8422200000",
    "Description": "Machinery for cleaning or drying bottles or other containers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8422300000",
    "Description": "Machinery for filling, closing, sealing, or labelling bot",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8422400000",
    "Description": "Machinery for filling; machinery for capsuling bottles; machinery for aerating beverages",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8422900000",
    "Description": "Parts of articles of this heading",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8423100000",
    "Description": "Personal weighing machines, including baby scales; household scales",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8423200000",
    "Description": "Scales for continuous weighing of goods on conveyors",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8423300000",
    "Description": "Constant weight scales and scales for discharging of material into containers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8423810000",
    "Description": "Other weighing machinery, Having a maximum weighing capacity not exceeding 30 kg",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8423820000",
    "Description": "Other weighing machinery Having a maximum weighing capacity >30 kg =< 5,000 kg",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8423890000",
    "Description": "Other Weighing machinery, NES",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8423900000",
    "Description": "Weighing machine weights of all kinds; parts of weighing machinery",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8424100000",
    "Description": "Fire extinguishers, whether or not charged",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8424200000",
    "Description": "Spray guns and similar appliances",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8424300000",
    "Description": "Steam or sand blasting machines and similar jet projecting machines",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8424410000",
    "Description": "Portable sprayers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "13/04/2020"
  },
  {
    "CET Code": "8424490000",
    "Description": "Other agricultural or horticultural sprayer",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8424820000",
    "Description": "Agricultural or horticultural",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8424890000",
    "Description": "Other Mechanical appliances for projecting, spraying liquids or powders, NES",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8424900000",
    "Description": "Parts of Mechanical appliances for projecting, dispersing or spraying liquids/powders",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8425110000",
    "Description": "Pulley tackle and hoists excl. kind used for raising vehicles: Powered by electric motor",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8425190000",
    "Description": "Pulley tackle/hoists excl. kind used for raising vehicles: Not Powered by electric motor",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8425310000",
    "Description": "Winches; capstans : Powered by electric motor",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8425390000",
    "Description": "Winches; capstans:not Powered by electric motor",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8425410000",
    "Description": "Built-in jacking systems of a type used in garages",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8425420000",
    "Description": "Other jacks and hoists, hydraulic",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8425490000",
    "Description": "Other jacks not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8426110000",
    "Description": "Overhead travelling cranes on fixed support",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8426120000",
    "Description": "Mobile lifting frames on tyres and straddle carriers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8426190000",
    "Description": "Transporter cranes, gantry cranes, bridge cranes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8426200000",
    "Description": "Tower cranes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8426300000",
    "Description": "Portal or pedestal jib cranes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8426410000",
    "Description": "Other machinery, selfpropelled : On tyres",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8426490000",
    "Description": "Other machinery, selfpropelled : not on tyres",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8426910000",
    "Description": "Other machinery designed for mounting on road vehicles",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8426990000",
    "Description": "Other machinery not designed for mounting on road vehicles",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8427100000",
    "Description": "Selfpropelled trucks powered by an electric motor",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8427200000",
    "Description": "Other selfpropelled trucks not powered by an electric motor",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8427900000",
    "Description": "Other trucks of heading 84.27 not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8428100000",
    "Description": "Lifts and skip hoists",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8428200000",
    "Description": "Pneumatic elevators and conveyors",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8428310000",
    "Description": "Specially designed for underground use",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "12/06/2015"
  },
  {
    "CET Code": "8428320000",
    "Description": "Other celevators/conveyors for materials: designed for underground use, bucket type",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8428330000",
    "Description": "OOther celevators/conveyors for materials: designed for underground use, belt type",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8428390000",
    "Description": "Other continuousaction elevators and conveyors,for goods or materials",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8428400000",
    "Description": "Escalators and moving walkways",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8428600000",
    "Description": "Teleferics, chairlifts, skidraglines; traction mechanisms for funiculars",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8428700000",
    "Description": "-- Industrial robots",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8428900000",
    "Description": "Otherlifting machinery NES",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8429110000",
    "Description": "Bulldozers and angledozers; Track laying",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8429190000",
    "Description": "Bulldozers and angledozers; ot-track laying",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8429200000",
    "Description": "Graders and levellers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8429300000",
    "Description": "Scrapers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8429400000",
    "Description": "Tamping machines and road rollers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8429510000",
    "Description": "Frontend shovel loaders",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8429520000",
    "Description": "Machinery with a 360o revolving superstructure",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8429590000",
    "Description": "Other articles of heading 84.26 not specifies",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8430100000",
    "Description": "Pile-drivers and pile-extractors",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8430200000",
    "Description": "Snowploughs and snowblowers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8430310000",
    "Description": "Coal or rock cutters and tunnelling machinery: Selfpropelled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8430390000",
    "Description": "Coal or rock cutters and tunnelling machinery:Non Selfpropelled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8430410000",
    "Description": "Other boring or sinking machinery: Selfpropelled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8430490000",
    "Description": "Other boring or sinking machinery: Non Selfpropelled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8430500000",
    "Description": "Other machinery not specified, selfpropelled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8430610000",
    "Description": "Tamping or compacting machinery",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8430690000",
    "Description": "Other machinery of heading 84.30, not selfpropelled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8431100000",
    "Description": "Parts suitable for use solely with the machinery of machinery of heading 84.25",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8431200000",
    "Description": "Parts suitable for use solely with the machinery of machinery of heading 84.27",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8431310000",
    "Description": "Parts suitable for use solely with the machinery of lifts, skip hoists or escalators",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8431390000",
    "Description": "Parts suitable for use solely with the machinery not specified",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8431410000",
    "Description": "Parts suitable for Buckets, shovels, grabs and grips",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8431420000",
    "Description": "Parts suitable for Bulldozer or angledozer blades",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8431430000",
    "Description": "Parts for boring or sinking machinery of subheading 8430.41 or 8430.49",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8431490000",
    "Description": "Parts suitable for use solely or princip Other",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8432100000",
    "Description": "Ploughs",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8432210000",
    "Description": "Disc harrows",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8432290000",
    "Description": "Agricultural, horticultural or forestry Other",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8432310000",
    "Description": "No-till direct seeders, planters and transplanters",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8432390000",
    "Description": "Other Seeders, planters and transplanters",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8432410000",
    "Description": "Manure spreaders",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8432420000",
    "Description": "Fertiliser distributors",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8432800000",
    "Description": "Other machinery not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8432900000",
    "Description": "Parts of article of heading 84.32",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8433110000",
    "Description": "Mowers for lawns.. Powered, with the cutting device rotating in a horizontal plane",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8433190000",
    "Description": "Mowers for lawns..Not Powered, with the cutting device rotating in a horizontal plane",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8433200000",
    "Description": "Other mowers, including cutter bars for tractor mounting",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8433300000",
    "Description": "Other hay-making machinery",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8433400000",
    "Description": "Straw or fodder balers, including pickup balers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8433510000",
    "Description": "Combine harvesterthreshers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8433520000",
    "Description": "Other threshing machinery",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8433530000",
    "Description": "Root or tuber harvesting machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8433590000",
    "Description": "Other Harvesting or threshing machinery not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8433600000",
    "Description": "Machines for cleaning, sorting or grading eggs, fruit or other agricultural produce",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8433900000",
    "Description": "Parts of article of heading 84.33",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8434100000",
    "Description": "Milking machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8434200000",
    "Description": "Dairy machinery",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8434900000",
    "Description": "Parts of Milking machines and dairy machinery.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8435100000",
    "Description": "Machinery used in the manufacture of wine, cider, fruit juices or similar beverages.",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8435900000",
    "Description": "Parts of Machinery used in the manuf. of wine, cider, fruit juices or similar beverage",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8436100000",
    "Description": "Machinery for preparing animal feeding stuffs",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8436210000",
    "Description": "Poultry incubators and brooders",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8436290000",
    "Description": "Other Machinery for preparing animal feeding stuffs not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8436800000",
    "Description": "Other machinery of this heading not speccified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8436910000",
    "Description": "Parts of poultry-keeping machinery or poultry incubators and brooders",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8436990000",
    "Description": "Parts of other machinery of heading not specified.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8437100000",
    "Description": "Machines for cleaning, sorting or grading seed, grain or dried leguminous vegetables",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8437800000",
    "Description": "- Other machinery of heading not specified.",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "05/06/2020"
  },
  {
    "CET Code": "8437900000",
    "Description": "Parts",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8438100000",
    "Description": "Bakery machinery and machinery for the manufacture of macaroni... or similar products",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8438200000",
    "Description": "Machinery for the manufacture of confectionery, cocoa or chocolate",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8438300000",
    "Description": "Machinery for sugar manufacture",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8438400000",
    "Description": "Brewery machinery",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8438500000",
    "Description": "Machinery for the preparation of meat or poultry",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8438600000",
    "Description": "Machinery for the preparation of fruits, nuts or vegetables",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8438800000",
    "Description": "Other machinery",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8438900000",
    "Description": "Parts",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8439100000",
    "Description": "Machinery for making pulp of fibrous cellulosic material",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8439200000",
    "Description": "Machinery for making paper or paperboard",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8439300000",
    "Description": "Machinery for finishing paper or paperboard",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8439910000",
    "Description": "Parts of machinery for making pulp of fibrous cellulosic material",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8439990000",
    "Description": "Other parts of Machinery for making or finishing paper or paperboard not specified",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8440100000",
    "Description": "Book-binding machinery",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8440900000",
    "Description": "Parts of Book-binding machinery",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8441100000",
    "Description": "Cutting machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8441200000",
    "Description": "Machines for making bags, sacks or envelopes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8441300000",
    "Description": "Machines for making cartons, boxes... or similar containers, other than by moulding",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8441400000",
    "Description": "Machines for moulding articles in paper pulp, paper or paperboard",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8441800000",
    "Description": "Machinery for making paper pulp, paper/paperboard, paper cutting machine not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8441900000",
    "Description": "Parts of paper pulp, paper or paperboard machines of all kinds.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8442300000",
    "Description": "Machinery, apparatus and equipment for making plates or other printing components;",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8442400000",
    "Description": "Parts of Machinery and equipment for making plates or other printing components;",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8442500000",
    "Description": "Plates, cylinders and other printing components..., prepared for printing purposes",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8443110000",
    "Description": "Offset printing machinery, reelfed",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443120000",
    "Description": "Offset printing machinery, sheetfed, office type",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443130000",
    "Description": "Other offset printing machinery not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443140000",
    "Description": "Letterpress printing machinery, reel fed, excluding flexographic printing",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443150000",
    "Description": "Letterpress printing machinery, other than reel fed, excluding flexographic printing",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443160000",
    "Description": "Flexographic printing machinery",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443170000",
    "Description": "Gravure printing machinery",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443190000",
    "Description": "Printing machinery used for printing by.. 0ther(offset printing machinery)",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443311000",
    "Description": "Printers incorporating copying function",
    "SU": "U",
    "ID": 20,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443319000",
    "Description": "Other printers not incorporating copying machine (facsimile etc)",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443321000",
    "Description": "Copying machines capable of connection to computer",
    "SU": "U",
    "ID": 20,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443329000",
    "Description": "Other machine capable of connection to computer excluding copying machine.",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443391000",
    "Description": "Copying machines not of subheading 8443.3210.00",
    "SU": "U",
    "ID": 20,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443399000",
    "Description": "Others excluding Copying machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8443910000",
    "Description": "Parts, accessories of printing machinery by means of plate.. (incl.84.42)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8443990000",
    "Description": "Other parts and accessoriesof printers not specified.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8444000000",
    "Description": "Machines for extruding, drawing, texturing or cutting manmade textile materials.",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8445110000",
    "Description": "Carding machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8445120000",
    "Description": "Combing machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8445130000",
    "Description": "Drawing or roving machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8445190000",
    "Description": "Machines for preparing textile fibres;spinning,doubling or twisting machin",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8445200000",
    "Description": "Textile spinning machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8445300000",
    "Description": "Textile doubling or twisting machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8445400000",
    "Description": "Textile winding (including weftwinding) or reeling machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8445900000",
    "Description": "Machines for preparing textile fibres;spinning,doubling otwisting machin",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8446100000",
    "Description": "Machinery for weaving fabrics of a width not exceeding 30 cm",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8446210000",
    "Description": "Power looms",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8446290000",
    "Description": "Weaving machines (looms). Other",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8446300000",
    "Description": "Machinery for weaving fabrics of a width exceeding 30 cm, shuttleless type",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8447110000",
    "Description": "Circular knitting machines, with cylinder diameter not exceeding 165 mm",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8447120000",
    "Description": "Circular knitting machines With cylinder diameter exceeding 165 mm",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8447200000",
    "Description": "Flat knitting machines; stitchbonding machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8447900000",
    "Description": "Other mahines of this heading not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8448110000",
    "Description": "Dobbies and Jacquards; card reducing or assembling machines for use therewith",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8448190000",
    "Description": "Auxiliary machinery for use with machines of heading 84.44, 84.45,84.46 or",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8448200000",
    "Description": "Parts and accessories of machines of heading 84.44 or of their auxiliary machinery",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8448310000",
    "Description": "Parts of Card clothing",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8448320000",
    "Description": "Parts of machines for preparing textile fibres, other than card clothing",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8448330000",
    "Description": "Parts of Spindles, spindle flyers, spinning rings and ring travellers",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8448390000",
    "Description": "Parts of other machinery and accessories of this heading not specified",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8448420000",
    "Description": "Parts and accessories of Reeds for looms, healds and healdframes",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8448490000",
    "Description": "Other parts and accessories of weaving machines (looms) or of their auxillary machinery",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8448510000",
    "Description": "Parts and accessories of Sinkers, needles and other articles used in forming stitches",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8448590000",
    "Description": "Other Parts and acces. of machine of heading 84.47 or their aux. machinery not specified",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8449000000",
    "Description": "Machinery for manuf. of felt/nonwovens shapes, incl. machinery for making felt hats/lock",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8450110000",
    "Description": "laundrytype washing machines,capacity =< 10 kg : Fullyautomatic machines",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8450120000",
    "Description": "laundrytype washing machines, with builtin centrifugal drier",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8450190000",
    "Description": "laundrytype washing machines,capacity =< 10 kg not specified",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8450200000",
    "Description": "laundrytype washing machines,capacity>10 kg",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8450900000",
    "Description": "Parts of machines nand accessories of this heading 84.50",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8451100000",
    "Description": "Drycleaning machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8451210000",
    "Description": "Drying machines, Each of a dry linen capacity not exceeding 10 kg",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8451290000",
    "Description": "Drying machines, Each of a dry linen capacity exceeding 10 kg",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "8451300000",
    "Description": "Ironing machines and presses (including fusing presses)",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8451400000",
    "Description": "Washing, bleaching or dyeing machines",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8451500000",
    "Description": "Machines for reeling, unreeling, folding, cutting or pinking textile fabrics",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8451800000",
    "Description": "Other machinery of this heading not specified",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8451900000",
    "Description": "Parts of machinery of this heading",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8452100000",
    "Description": "Sewing machines of the household type",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8452210000",
    "Description": "Automatic sewing machines units",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8452290000",
    "Description": "Non automatic sewing machines units",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8452300000",
    "Description": "Sewing machine needles",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8452900000",
    "Description": "Furniture, bases and covers for sewing machines and parts",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8453100000",
    "Description": "Machinery for preparing, tanning or working hides, skins or leather",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8453200000",
    "Description": "Machinery for making or repairing footwear",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8453800000",
    "Description": "Other machinery Machinery for preparing, tanning or working hides not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8453900000",
    "Description": "Parts of the machinery of this heading",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8454100000",
    "Description": "metallurgical Converters",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8454200000",
    "Description": "Ingot moulds and ladles",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8454300000",
    "Description": "Casting machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8454900000",
    "Description": "Parts of theaccesories of this heading",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8455100000",
    "Description": "Tube mills",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8455210000",
    "Description": "Hot or combination hot and cold",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8455220000",
    "Description": "Cold Metal-rolling mills",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8455300000",
    "Description": "Rolls for rolling mills",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8455900000",
    "Description": "Other parts ofMetal-rolling mills",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8456110000",
    "Description": "Operated by laser",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8456120000",
    "Description": "Operated by other light or photon beam processes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8456200000",
    "Description": "Machine-tools Operated by ultrasonic processes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8456300000",
    "Description": "Machine-tools Operated by electrodischarge processes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8456400000",
    "Description": "Operated by plasma arc processess",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8456500000",
    "Description": "Water-jet cutting machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8456900000",
    "Description": "Other machine tools of this heading not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8457100000",
    "Description": "Machining centres",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8457200000",
    "Description": "Unit construction machines (single station)",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8457300000",
    "Description": "Multistation transfer machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8458110000",
    "Description": "Horizontal lathes, Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8458190000",
    "Description": "Horizontal lathes, Not numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8458910000",
    "Description": "Other lathes, Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8458990000",
    "Description": "Other lathes, ot numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8459100000",
    "Description": "Waytype unit head machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8459210000",
    "Description": "Waytype unit head machines Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8459290000",
    "Description": "Waytype unit head machines, Not Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8459310000",
    "Description": "Other boringmilling machines, Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8459390000",
    "Description": "Other boringmilling machines, Not numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8459410000",
    "Description": "Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "13/04/2020"
  },
  {
    "CET Code": "8459490000",
    "Description": "Other boring-milling machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "13/04/2020"
  },
  {
    "CET Code": "8459510000",
    "Description": "Milling machines, kneetype Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8459590000",
    "Description": "Milling machines, kneetype Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8459610000",
    "Description": "Milling machines, kneetype numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8459690000",
    "Description": "Milling machines, kneetype not numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8459700000",
    "Description": "Other threading or tapping machines mot specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8460120000",
    "Description": "Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "13/04/2020"
  },
  {
    "CET Code": "8460190000",
    "Description": "Other flatsurface grinding machines, of atleast 0.01mm accuracy, nes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8460220000",
    "Description": "Centreless grinding machines, numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "13/04/2020"
  },
  {
    "CET Code": "8460230000",
    "Description": "Other cylindrical grinding machines, numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "13/04/2020"
  },
  {
    "CET Code": "8460240000",
    "Description": "Other, numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "13/04/2020"
  },
  {
    "CET Code": "8460290000",
    "Description": "Other grinding machines not elsewhere specified in this sub-heading",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "30/08/2019"
  },
  {
    "CET Code": "8460310000",
    "Description": "Sharpening (tool or cutter grinding) machines : Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8460390000",
    "Description": "Sharpening (tool or cutter grinding) machines : Non numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8460400000",
    "Description": "Honing or lapping machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8460900000",
    "Description": "Other machines for deburring, grinding, polishing, etc, metal, nes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8461200000",
    "Description": "Shaping or slotting machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8461300000",
    "Description": "Broaching machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8461400000",
    "Description": "Gear cutting, gear grinding or gear finishing machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8461500000",
    "Description": "Sawing or cuttingoff machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8461900000",
    "Description": "Other Machine tools working by removing metal or cermets,nes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8462110000",
    "Description": "-- Closed die forging machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462190000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462220000",
    "Description": "-- Profile forming machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462230000",
    "Description": "-- Numerically controlled press brakes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462240000",
    "Description": "-- Numerically controlled panel benders",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462250000",
    "Description": "-- Numerically controlled roll forming machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462260000",
    "Description": "-- Other numerically controlled bending, folding, straightening or flattening machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462290000",
    "Description": "Bending, folding...machines (including presses) :Not Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8462320000",
    "Description": "-- Slitting lines and cut-to-length lines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462330000",
    "Description": "-- Numerically controlled shearing machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462390000",
    "Description": "Shearing machine, excl. combined punching &shearing machine:Not numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8462420000",
    "Description": "-- Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462490000",
    "Description": "Punching or notching machines; Not Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8462510000",
    "Description": "-- Numerically controlled",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462590000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462610000",
    "Description": "-- Hydraulic presses",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462620000",
    "Description": "-- Mechanical presses",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462630000",
    "Description": "-- Servo-presses",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462690000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8462900000",
    "Description": "- Other",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8463100000",
    "Description": "Drawbenches for bars, tubes, profiles, wire or the like",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8463200000",
    "Description": "Thread rolling machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8463300000",
    "Description": "Machines for working wire",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8463900000",
    "Description": "Other machinetools for working metal, without removing material, nes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8464100000",
    "Description": "Sawing machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8464200000",
    "Description": "Grinding or polishing machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8464900000",
    "Description": "Other machine tools for working stone, ceramics. Not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8465100000",
    "Description": "Machines which can carry out different types of machining operations without tool change",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8465200000",
    "Description": "- Machining centres",
    "ID": 5,
    "DATE OF VALIDITY": "22/06/2020"
  },
  {
    "CET Code": "8465910000",
    "Description": "Sawing machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8465920000",
    "Description": "Planing, milling or moulding (by cutting) machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8465930000",
    "Description": "Grinding, sanding or polishing machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8465940000",
    "Description": "Bending or assembling machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8465950000",
    "Description": "Drilling or morticing machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8465960000",
    "Description": "Splitting, slicing or paring machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8465990000",
    "Description": "Other machine tools, nes, for working wood, cork, bone, hard rubber, etc",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8466100000",
    "Description": "Tool holders and selfopening dieheads",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8466200000",
    "Description": "Work holders",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8466300000",
    "Description": "Dividing heads and other special attachments for machinetools",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8466910000",
    "Description": "Parts and accessories suitable for machines of heading 84.64",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8466920000",
    "Description": "Parts and accessories suitable for machines of heading 84.65",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8466930000",
    "Description": "Parts and accessories suitable for machines of headings 84.56 to 84.61",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8466940000",
    "Description": "Parts and accessories suitable for machines of heading 84.62 or 84.63",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8467110000",
    "Description": "Pneumati tool; Rotary type (including combined rotarypercussion)",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8467190000",
    "Description": "Pneumatic tool; Non Rotary type",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8467210000",
    "Description": "Drills of all kinds",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8467220000",
    "Description": "Saws",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8467290000",
    "Description": "Other tools for working in the hand with selfcontained electric motor not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8467810000",
    "Description": "Chain saws",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8467890000",
    "Description": "Tools for working in the hand, with nonelectric motor,NES",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8467910000",
    "Description": "Parts of chain saws",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8467920000",
    "Description": "Parts of pneumatic tools",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8467990000",
    "Description": "Other Part of tools for working in the hand,pneumatic..not specified",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8468100000",
    "Description": "Handheld blow pipes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8468200000",
    "Description": "Gas operated machinery and apparatus",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8468800000",
    "Description": "Machinery and apparatus for soldering, brazing or welding not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8468900000",
    "Description": "Parts of Machinery and apparatus for soldering, brazing or welding",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8470100000",
    "Description": "Electronic calculators operating without an external source power.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8470210000",
    "Description": "electronic calculating machines Incorporating a printing device",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8470290000",
    "Description": "electronic calculating machines operaating without ext. power not specified in 84.70",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8470300000",
    "Description": "Other calculating machines not specified.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8470500000",
    "Description": "Cash registers",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8470900000",
    "Description": "Calculating machines and pocket size data processing machine.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8471301000",
    "Description": "Portable ADP machines =<10 kg Presented in CKD or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8471309000",
    "Description": "Portable ADP machines =<10 kg Presented in FBU.",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8471411000",
    "Description": "ADP Comprising in the same housing a CPU, an input and output unit. Presented CKD",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8471419000",
    "Description": "ADP Comprising in same housing a CPU, an input and output unit. Presented full built",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8471491000",
    "Description": "Other ADP presented in the form of systems: CKD",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8471499000",
    "Description": "Other ADP presented in the form of systems: full built",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8471501000",
    "Description": "Processing units other than those of subheading 8471.41 or 8471.49, CKD",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8471509000",
    "Description": "Processing units other than those of subheading 8471.41 or 8471.49, fully built",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8471601000",
    "Description": "Input or output units, whether or not containing storage units in the same housing, CKD",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8471609000",
    "Description": "Input/output units, whether or not containing storage units in the same housing, FBU",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8471701000",
    "Description": "Storage units, presented CKD",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8471709000",
    "Description": "Storage units, presented full built",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8471801000",
    "Description": "Other units of automatic data processing machines: CKD",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8471809000",
    "Description": "Other units of automatic data processing machines: full built",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8471900000",
    "Description": "Other Automatic data processing machines and units NES",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8472100000",
    "Description": "Duplicating machines",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8472300000",
    "Description": "Machines for sorting, for opening, closing..and machines for affixing.. postage stamps",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8472900000",
    "Description": "Other office machines; Not specified",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8473210000",
    "Description": "Part and access. of the elect. Calcul. machine of subhead. 8470.10, 8470.21 or 8470.29",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8473290000",
    "Description": "Other Parts and accessories not specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8473300000",
    "Description": "Parts and accessories of the machines of heading 84.71",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8473400000",
    "Description": "Parts and accessories of the machines of heading 84.72",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8473500000",
    "Description": "Parts and accessories equally for use with machi. of =>2 of the headings 84.69 to 84.72",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8474100000",
    "Description": "Sorting, screening, separating or washing machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8474200000",
    "Description": "Crushing or grinding machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8474310000",
    "Description": "Concrete or mortar mixers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8474320000",
    "Description": "Machines for mixing mineral substances with bitumen",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8474390000",
    "Description": "Mixing or kneading machines: Other",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8474800000",
    "Description": "Other machinery not specified in 8474",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8474900000",
    "Description": "Parts of article of heading 8474",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8475100000",
    "Description": "Machines for assembling electric or electronic lamps, tub",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8475210000",
    "Description": "Machines for making optical fibres and preforms thereof",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8475290000",
    "Description": "Machines for assembling electric or electronics; Other",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8475900000",
    "Description": "Parts",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8476210000",
    "Description": "Incorporating heating or refrigerating devices",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8476290000",
    "Description": "Automatic goods vending machines(for ... Other",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8476810000",
    "Description": "Incorporating heating or refrigerating devices",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8476890000",
    "Description": "Other Automatic goodsvending machines not specified.",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8476900000",
    "Description": "Parts",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8477100000",
    "Description": "Injectionmoulding machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8477200000",
    "Description": "Extruders",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8477300000",
    "Description": "Blow moulding machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8477400000",
    "Description": "Vacuum moulding machines and other thermoforming machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8477510000",
    "Description": "For moulding or retreading pneumatic tyres or for mouldi",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8477590000",
    "Description": "Other machinery for moulding or otherwise forming",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8477800000",
    "Description": "Other machinery",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8477900000",
    "Description": "Parts",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8478100000",
    "Description": "Machinery for preparing or making up tobacco,not specified or included elsewhere",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "05/06/2020"
  },
  {
    "CET Code": "8478900000",
    "Description": "Parts",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8479100000",
    "Description": "Machinery for public works, building or the like",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8479200000",
    "Description": "Machinery for the extraction or preparation of animal or fixed vegetable fats or oils",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8479300000",
    "Description": "Presses for the manufacture of particle board or fibre bu",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8479400000",
    "Description": "Rope or cablemaking machines",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8479500000",
    "Description": "Industrial robots, not elsewhere specified or included",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8479600000",
    "Description": "Evaporative air coolers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8479710000",
    "Description": "Of a kind used in airports",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8479790000",
    "Description": "Other Passenger boarding bridges not specified.",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8479810000",
    "Description": "For treating metal, including electric wire coilwinders",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8479820000",
    "Description": "Mixing, kneading, crushing, grinding, screening, sifting",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8479830000",
    "Description": "-- Cold isostatic presses",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8479890000",
    "Description": "Other machines and mechanical appliances having individual functions, nes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8479900000",
    "Description": "Parts",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8480100000",
    "Description": "Moulding boxes for metal foundry",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8480200000",
    "Description": "Mould bases",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8480300000",
    "Description": "Moulding patterns",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8480410000",
    "Description": "Injection or compression types",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8480490000",
    "Description": "Other Moulds for metal or metal carbides (excl. injection or compression)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8480500000",
    "Description": "Moulds for glass",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8480600000",
    "Description": "Moulds for mineral materials",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8480710000",
    "Description": "Injection or compression types",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8480790000",
    "Description": "Other moulds for rubber or plastics (excl. injection of compression)",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8481100000",
    "Description": "Pressurereducing valves",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8481200000",
    "Description": "Valves for oleohydraulic or pneumatic transmissions",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8481300000",
    "Description": "Check (nonreturn) valves",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8481400000",
    "Description": "Safety or relief valves",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8481800000",
    "Description": "Other appliances",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8481900000",
    "Description": "Parts",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8482100000",
    "Description": "Ball bearings",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8482200000",
    "Description": "Tapered roller bearings, including cone and tapered roller assemblies",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8482300000",
    "Description": "Spherical roller bearings",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8482400000",
    "Description": "Needle roller bearings",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8482500000",
    "Description": "Other cylindrical roller bearings",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8482800000",
    "Description": "Other, including combined ball/roller bearings",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8482910000",
    "Description": "Balls, needles and rollers",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8482990000",
    "Description": "Ball or roller bearings. Other(parts)",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8483100000",
    "Description": "Transmission shafts (including cam shafts and crank shafts) and cranks",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8483200000",
    "Description": "Bearing housings, incorporating ball or roller bearings",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8483300000",
    "Description": "Bearing housings, not incorporating ball or roller bearings; plain shaft bearings",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8483400000",
    "Description": "Gears and gearing, other than toothed wheels, chain sproc",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8483500000",
    "Description": "Flywheels and pulleys, including pulley blocks",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8483600000",
    "Description": "Clutches and shaft couplings (including universal joints)",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8483900000",
    "Description": "Toothed wheels, chain sprockets and other transmission el",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8484100000",
    "Description": "Gaskets and similar joints of metal sheeting combined wit",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8484200000",
    "Description": "Mechanical seals",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8484900000",
    "Description": "Other sets or assortments of gaskets and similar joints,put up in pouches, envelopes",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8485100000",
    "Description": "- By metal deposit",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8485200000",
    "Description": "- By plastics or rubber deposit",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8485300000",
    "Description": "- By plaster, cement, ceramics or glass deposit",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8485800000",
    "Description": "- Other",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8485900000",
    "Description": "- Parts",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8486100000",
    "Description": "Machines and apparatus for the manufacture of boules or wafers",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8486201100",
    "Description": "Direct writeonwater apparatus",
    "SU": "U",
    "ID": 20,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8486201200",
    "Description": "Step and repeat aligners",
    "SU": "U",
    "ID": 20,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8486201900",
    "Description": "Other Apparatus for the projection or drawing of circu",
    "SU": "U",
    "ID": 20,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8486209000",
    "Description": "Apparatus for the projection or drawing of circuit patterns on not specified",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8486301000",
    "Description": "For photographic or cinematographic laboratories; negatoscopes",
    "SU": "U",
    "ID": 20,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8486309000",
    "Description": "Other Machines and apparatus for the manufacture of flat panel displays:",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8486400000",
    "Description": "Machines and apparatus specified in Note 9 (C) to this Chapter",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8486901000",
    "Description": "Parts and accessories of machines and apparatus of subheading 8686.20 and 8486.30",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8486909000",
    "Description": "Other Parts and accessories",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8487100000",
    "Description": "Ships' or boats' propellers and blades therefor",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8487900000",
    "Description": "Other article of heading 87.84 not specified.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8501100000",
    "Description": "Electric motors of an output not exceeding 37.5 W",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8501200000",
    "Description": "Universal AC/DC motors of an output exceeding 37.5 W",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8501310000",
    "Description": "Other DC motors; DC generators  of an output not exceeding 750 W",
    "SU": "U",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "8501320000",
    "Description": "Other DC motors; DC generators  of an output exceeding 750 W but not exceeding 75 kW",
    "SU": "U",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "8501330000",
    "Description": "Other DC motors; DC generators  of an output exceeding 75 kW but not exceeding 375 kW",
    "SU": "U",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "8501340000",
    "Description": "Other DC motors; DC generators  of an output exceeding 375 kW",
    "SU": "U",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "8501400000",
    "Description": "Other AC motors, singlephase",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8501510000",
    "Description": "Other AC motors, multiphase of an output not exceeding 750 W",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8501520000",
    "Description": "Other AC motors, multiphase of an output exceeding 750 W but not exceeding 75 kW",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8501530000",
    "Description": "Other AC motors, multiphase of an output exceeding 75 kW",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8501610000",
    "Description": "Other AC motors, multiphase of an output not exceeding 75 kVA",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8501620000",
    "Description": "Other AC motors, multiphase of an output exceeding 75 kVA but not exceeding 375 kVA",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8501630000",
    "Description": "Other AC motors, multiphase of an output exceeding 375 kVA but not exceeding 750 kVA",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8501640000",
    "Description": "Other AC motors, multiphase of an output exceeding 750 kVA",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8501710000",
    "Description": "-- Of an output not exceeding 50 W",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8501720000",
    "Description": "-- Of an output exceeding 50 W",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8501800000",
    "Description": "- Photovoltaic AC generators",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8502111000",
    "Description": "Generating sets, diesel or semidiesel engines, output <75kVA,CKD for assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8502119000",
    "Description": "---  Other",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8502121000",
    "Description": "Gen. set, diesel or semidiesel engine, output >75=<375kVA,CKD for assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8502129000",
    "Description": "---  Other",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8502131000",
    "Description": "Generating sets, diesel or semidiesel engines of an output >375 kVA, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8502139000",
    "Description": "---  Other",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8502201000",
    "Description": "---- presented completely knocked down ( CKD) or unassembled for the assemblee undustry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8502209000",
    "Description": "--  Other",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8502310000",
    "Description": "Windpowered generator",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8502391000",
    "Description": "Solarpowered generator",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8502392000",
    "Description": "Gaspowered generator",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8502399000",
    "Description": "Other generating sets not elsewhere specified or included in this sub-heading",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "30/08/2019"
  },
  {
    "CET Code": "8502400000",
    "Description": "Electric rotary converters",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8503000000",
    "Description": "Parts suitable for use solely/principally with the machines of heading 85.01 or 85.02.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8504100000",
    "Description": "Ballasts for discharge lamps or tubes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8504210000",
    "Description": "Liquid dielectric transformers, having a power handling capacity not exceeding 650 kVA",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "26/06/2021"
  },
  {
    "CET Code": "8504220000",
    "Description": "Liquid dielectric transformer with power handling capacity >650 kVA but not > 10,000 kVA",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "26/06/2021"
  },
  {
    "CET Code": "8504230000",
    "Description": "Liquid dielectric transformers, having a power handling capacity exceeding 10,000 kVA",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8504310000",
    "Description": "Other transformer not specified, having a power handling capacity not exceeding 1 kVA",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8504320000",
    "Description": "Transformer not specified, with power handling capacity > 1 kVA but not > 16 kVA",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8504330000",
    "Description": "Transformer not specified, with power handling capacity > 16 kVA but not > 500 kVA",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "26/06/2021"
  },
  {
    "CET Code": "8504340000",
    "Description": "Other transformer not specified, having a power handling capacity exceeding 500 kVA",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "20/07/2019"
  },
  {
    "CET Code": "8504401000",
    "Description": "Uninterruptible power supply (UPS)",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8504402000",
    "Description": "Battery chargers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8504409000",
    "Description": "Other static converters not specified",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8504501000",
    "Description": "Choke coil (chokes)",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8504509000",
    "Description": "Other inductors excl. choke coil",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8504900000",
    "Description": "Parts of Electrical transformers, static converters (eg, rectifiers) and inductors.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8505110000",
    "Description": "Permanent magnets and articles intended to become permanent magnets of metal",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8505190000",
    "Description": "Perm. magnets and article intended to become perm. magnets of any material not specified",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8505201000",
    "Description": "Electromagnetic couplings, clutches and brakes for motor vehicles",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8505209000",
    "Description": "Electromagnetic couplings, clutches and brakes not for motor vehicles",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8505900000",
    "Description": "Other Magnets and Electromagnets; including parts",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8506101000",
    "Description": "R20 (Flashlight and radio batteries)",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8506109000",
    "Description": "Other Manganese dioxide primary cells, not specified",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8506300000",
    "Description": "Primary cells and primary batteries made of Mercuric oxide",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8506400000",
    "Description": "Primary cells and primary batteries made of Silver oxide",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8506500000",
    "Description": "Primary cells and primary batteries made of Lithium",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8506600000",
    "Description": "Primary cells and primary batteries made of Air-zinc",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8506800000",
    "Description": "Other primary cells and primary batteries not specifies",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8506900000",
    "Description": "Parts of Primary cells and primary batteries.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8507100000",
    "Description": "Leadacid, of a kind used for starting piston engines",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 40,
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8507200000",
    "Description": "Other lead-acid accumulators not specified",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8507300000",
    "Description": "Nickel-cadmium accumulators",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8507500000",
    "Description": "Nickel-metal hydride accumulators",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8507600000",
    "Description": "Lithium-ion accumulators",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8507800000",
    "Description": "Other accumulators not specified",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8507900000",
    "Description": "Parts of electric accumulators",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8508110000",
    "Description": "Vacuum cleaners of a power not exceeding 1,500 W and having a dust bag capacity= <20l",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8508190000",
    "Description": "Other vacuum cleaners with selfcontained electric motor not specified or included.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8508600000",
    "Description": "Other vacuum cleaners of any type not specified",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8508700000",
    "Description": "Parts Vacuum cleaners.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8509400000",
    "Description": "Food grinders and mixers; fruit or vegetable juice extractors",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8509800000",
    "Description": "Other Electromechanical domestic appliance",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8509900000",
    "Description": "Parts of Electromechanical domestic appliances, with selfcontained electric motor",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8510100000",
    "Description": "Shavers",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8510200000",
    "Description": "Hair clippers",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8510300000",
    "Description": "Hair-removing appliances",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8510900000",
    "Description": "Parts of Shavers, hair clippers... with selfcontained electric motor",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8511100000",
    "Description": "Sparking plugs",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8511200000",
    "Description": "Ignition magnetos; magneto-dynamos; magnetic flywheels",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8511300000",
    "Description": "Distributors; ignition coils",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8511400000",
    "Description": "Starter motors and dual purpose starte-rgenerators",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8511500000",
    "Description": "Othergenerators (for example, dynamos, alternators)",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8511800000",
    "Description": "Other equipment (for example, cutout)",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8511900000",
    "Description": "Parts of of article of 85.11",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8512100000",
    "Description": "Lighting or visual signalling equipment of a kind used on bicycles",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8512200000",
    "Description": "Other lighting or visual signalling equipment",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8512300000",
    "Description": "Sound signalling equipment",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8512400000",
    "Description": "Windscreen wipers, defrosters and demisters",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8512900000",
    "Description": "Parts of Electrical lighting or signalling equipment used for cycles or motor vehicles.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8513100000",
    "Description": "Lamps designed to function by their own source of energy",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8513900000",
    "Description": "Parts of article of heading 85.13",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8514110000",
    "Description": "-- Hot isostatic presses",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8514190000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8514200000",
    "Description": "Furnaces and ovens functioning by induction or dielectric loss",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8514310000",
    "Description": "-- Electron beam furnaces",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8514320000",
    "Description": "-- Plasma and vacuum arc furnaces",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8514390000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8514400000",
    "Description": "Other equipment for the heat treatment of materials by induction or dielectric loss",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8514900000",
    "Description": "Parts of article of heading 85.14",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8515110000",
    "Description": "Soldering irons and guns",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8515190000",
    "Description": "Brazing machines and apparatus",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8515210000",
    "Description": "Fully or partly automatic machines and apparatus for resistance welding of metal",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8515290000",
    "Description": "Other non automatic machines and apparatus for resistance welding of metal",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8515310000",
    "Description": "Fully or partly automatic machines and apparatus for arc welding of metals",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8515390000",
    "Description": "Non machines and apparatus for arc (including plasma arc) welding of metals",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8515800000",
    "Description": "Other machines and apparatus for welding of metals",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8515900000",
    "Description": "Parts of article of heading 85.15",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8516100000",
    "Description": "Electric instantaneous or storage water heaters and immersion heaters",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516210000",
    "Description": "Storage heating radiators",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516290000",
    "Description": "Electric space heating and soil heating appar. excl. Storage heating radiators",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516310000",
    "Description": "Hair dryers",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516320000",
    "Description": "Other hair-dressing apparatus",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516330000",
    "Description": "Hand-drying apparatus",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516400000",
    "Description": "Electric smoothing irons",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516500000",
    "Description": "Microwave ovens",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516601000",
    "Description": "Ovens; cookers, cooking plates, boiling rings, grillers and roasters: Presented CKD",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8516609000",
    "Description": "Ovens; cookers, cooking plates, boiling rings, grillers and roasters:fully built",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516710000",
    "Description": "Coffee or tea makers",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516720000",
    "Description": "Toasters",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516791000",
    "Description": "Electric Kettle",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516792000",
    "Description": "Electric Perfume or incense heaters, and heaters for diffusing insecticides",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516799000",
    "Description": "Other electrothermic appliances not specified in this heading.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516800000",
    "Description": "Electric heating resistors",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8516900000",
    "Description": "Parts of article of 85.16",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8517110000",
    "Description": "Line telephone sets with cordless handsets",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8517130000",
    "Description": "-- Smartphones",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8517140000",
    "Description": "-- Other telephones for cellular networks or for other wireless networks",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8517180000",
    "Description": "Other telephone sets not specified.",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8517610000",
    "Description": "Base stations",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8517620000",
    "Description": "Machines for reception, conversion and transmission ... of voice, images or data.",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8517690000",
    "Description": "Other apparatus of subheading 8517.60 not specified",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8517710000",
    "Description": "-- Aerials and aerial reflectors of all kinds; parts suitable for use therewith",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8517790000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8518100000",
    "Description": "Microphones and stands therefor",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8518210000",
    "Description": "Single loudspeakers, mounted in their enclosures",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8518220000",
    "Description": "Multiple loudspeakers, mounted in the same enclosure",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8518290000",
    "Description": "Other types of loudspeakers not specified in 85.18",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8518300000",
    "Description": "Headphone and earphone, and sets consisting of a microphone and one or more loudspeakers",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8518400000",
    "Description": "Audio frequency electric amplifiers",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8518500000",
    "Description": "Electric sound amplifier sets",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8518900000",
    "Description": "Parts of aticle of heading 85.18",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8519200000",
    "Description": "Apparatus operated by coins, banknotes, bank cards, tokens or by other means of payment",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8519300000",
    "Description": "Turntables (record-decks)",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8519811000",
    "Description": "Portable multimedia player",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8519819000",
    "Description": "ther Sound recording or reproducing apparatus not specified in this heading.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8519890000",
    "Description": "Other Sound recording or reproducing apparatus not specified.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8521100000",
    "Description": "Magnetic tape-type Video recording or reproducing apparatus",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8521901000",
    "Description": "DVD player/recorder",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8521909000",
    "Description": "Other Video recording or reproducing apparatus not specified",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8522100000",
    "Description": "Pick-up cartridges",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8522900000",
    "Description": "Parts and accessories for use with apparatus of 85.19 or 85.21. excl. Pickup cartridges",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8523211000",
    "Description": "Recorded Cards incorporating a magnetic stripe",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8523219000",
    "Description": "Unrecorded Cards incorporating a magnetic stripe",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8523291000",
    "Description": "Other Magnetic media of a width not exceeding 4mm",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8523299000",
    "Description": "Other Magnetic media not specified in this heading.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8523410000",
    "Description": "Unrecorded Optical media",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8523490000",
    "Description": "Recorded Optical media",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8523510000",
    "Description": "Solidstate nonvolatile storage devices",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8523520000",
    "Description": "\"Smart cards\"",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 35,
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8523590000",
    "Description": "Other, Semiconductor media not specified.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8523800000",
    "Description": "Other items of 85.23 not specified",
    "SU": "U",
    "ID": 20,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8524110000",
    "Description": "-- Of liquid crystals",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8524120000",
    "Description": "-- Of organic light-emitting diodes (OLED)",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8524190000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8524910000",
    "Description": "-- Of liquid crystals",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8524920000",
    "Description": "-- Of organic light-emitting diodes (OLED)",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8524990000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8525500000",
    "Description": "Transmission apparatus",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8525600000",
    "Description": "Transmission apparatus incorporating reception apparatus",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8525810000",
    "Description": "-- High-speed goods as specified in Subheading Note 1 to this Chapter",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8525820000",
    "Description": "-- Other, radiation-hardened or radiation-tolerant goods as specified in Subheading Note",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8525830000",
    "Description": "-- Other, night vision goods as specified in Subheading Note 3 to this Chapter",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8525890000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8526100000",
    "Description": "Radar apparatus",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8526910000",
    "Description": "Radio navigational aid apparatus",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8526920000",
    "Description": "Radio remote control apparatus",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8527120000",
    "Description": "Pocketsize radio cassetteplayers",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8527130000",
    "Description": "Other apparatus combined with sound recording or reproducing apparatus",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8527191000",
    "Description": "Radiobroadcast receivers capable of operating without an external source of power; CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8527192000",
    "Description": "Radio receiving sets",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8527199000",
    "Description": "Other Radiobroadcast/receivers, operating without an external source of power not spec.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8527210000",
    "Description": "Radiobroadcast receiver, used in motor vehicle, Combined sound record./reprod. apparatus",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8527290000",
    "Description": "Radiobroadcast receiver, used in motor vehicle,Not Combined",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8527910000",
    "Description": "Radiobroadcast receivers NS#,Combined recording/reproducing",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8527920000",
    "Description": "Radiobroadcast receivers NS#, not Combined",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8527990000",
    "Description": "Other radiobroadcast receivers not specified.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8528420000",
    "Description": "-- Capable of directly connecting to and designed for use with ADP machines of   ",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "22/06/2020"
  },
  {
    "CET Code": "8528490000",
    "Description": "CRT monitor : not used in an automatic data processing system of 84.71 not specified",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8528520000",
    "Description": "Capable of directly connecting to and designed for use with ADP machine of heading 8471",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/04/2020"
  },
  {
    "CET Code": "8528590000",
    "Description": "CRT monitor not Specified : not used in an automatic data processing system of 84.71",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8528620000",
    "Description": "Capable of directly connecting to and designed for use with an ADP machine of 84.71",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/04/2020"
  },
  {
    "CET Code": "8528690000",
    "Description": "Projectors of a kind not used in an automatic data processing system of heading 84.71",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8528711000",
    "Description": "Reception apparatus for television, with no video display/screen, Presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8528719000",
    "Description": "Reception apparatus for television, with no video display/screen, Presented fully built",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "8528721000",
    "Description": "Reception apparatus for television, coloured, Presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8528729000",
    "Description": "Reception apparatus for television, coloured, Presented fully buit",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8528731000",
    "Description": "Reception apparatus for television, monochrome:, Presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8528739000",
    "Description": "Reception apparatus for television, monochrome:, Presented FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "08/12/2021"
  },
  {
    "CET Code": "8529100000",
    "Description": "Aerials and aerial reflectors of all kinds; parts suitable for use therewith",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8529900000",
    "Description": "Other Parts suitable for use with the apparatus of headings 85.25 to 85.28 not specified",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8530100000",
    "Description": "Electrical signalling equipment for railways or tramways",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8530800000",
    "Description": "Electrical signalling equipment for roads, inland waterways... or airfields excl. 86.08",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8530900000",
    "Description": "Parts of article of heading 85.30",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8531100000",
    "Description": "Burglar or fire alarms and similar apparatus",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8531200000",
    "Description": "Indicator panels incorporating LCD or LED",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8531800000",
    "Description": "Other Electric sound or visual signalling apparatus NS# excl.  85.12 or 85.30",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8531900000",
    "Description": "Parts of article of heading 85.31",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8532100000",
    "Description": "Fixed capacitors used in 50/60 Hz circuits,having power handling capacity => 0.5 kvar",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8532210000",
    "Description": "Other fixed capacitors made of Tantalum",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8532220000",
    "Description": "Other fixed capacitors made of Aluminium electrolytic",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8532230000",
    "Description": "Other fixed capacitors made of Ceramic dielectric, single layer",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8532240000",
    "Description": "Other fixed capacitors made of Ceramic dielectric, multilayer",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8532250000",
    "Description": "Other fixed capacitors made of Dielectric of paper or plastics",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8532290000",
    "Description": "Other fixed capacitors made of material not specified",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8532300000",
    "Description": "Variable or adjustable (preset) capacitors",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8532900000",
    "Description": "Parts of article of heading 8532",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8533100000",
    "Description": "Fixed carbon resistors, composition or film types",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8533210000",
    "Description": "Other fixed resistors for a power handling capacity not exceeding 20 W",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8533290000",
    "Description": "Other fixed resistors for a power handling capacity exceeding 20 W",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8533310000",
    "Description": "Wirewound variable resistors for a power handling capacity not exceeding 20 W",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8533390000",
    "Description": "Wirewound variable resistors for a power handling capacity exceeding 20 W",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8533400000",
    "Description": "Other variable resistors, including rheostats and potentiometers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8533900000",
    "Description": "Parts of article ofheading 85.33",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8534000000",
    "Description": "Printed circuits.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8535100000",
    "Description": "Fuses",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8535210000",
    "Description": "Automatic circuit breakers for a voltage of less than 72.5 kV",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8535290000",
    "Description": "Automatic circuit breakers for a voltage of more than 72.5 kV",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8535300000",
    "Description": "Isolating switches and make-and-break switches",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8535400000",
    "Description": "Lightning arresters, voltage limiters and surge suppressors",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8535900000",
    "Description": "Other elect. apparatus for switching, protecting circuits, connections... not specified",
    "SU": "KG",
    "ID": 10,
    "LVY": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8536100000",
    "Description": "Fuses",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8536200000",
    "Description": "Automatic circuit breakers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8536300000",
    "Description": "Other apparatus for protecting electrical circuits",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8536410000",
    "Description": "Relays for a voltage not exceeding 60 V",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8536490000",
    "Description": "Relays for a voltage exceeding 60 V",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8536500000",
    "Description": "Other electrical switches",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8536610000",
    "Description": "Lampholders",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8536690000",
    "Description": "Electrical plugs and sockets",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8536700000",
    "Description": "Connectors for optical fibres, optical fibre bundles or cables",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8536900000",
    "Description": "Other apparatus of heading 85.36 not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8537100000",
    "Description": "Boards, panels, consoles... for electric control/distribution. For a voltage =< 1,000 V",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8537200000",
    "Description": "Boards, panels, consoles... for electric control/distribution. For a voltage> 1,000 V",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8538100000",
    "Description": "Parts suitable for Boards, panels, consoles, desk...,not equipped with their apparatus",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8538900000",
    "Description": "Parts suitable for Boards, panels, consoles, desk... equipped with their apparatus",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8539100000",
    "Description": "Sealed beam lamp units",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8539210000",
    "Description": "Tungsten halogen",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8539220000",
    "Description": "Other filament lamps, of a power not exceeding 200 W and for a voltage exceeding 100 V",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8539290000",
    "Description": "Other filament lamps, excluding ultraviolet or infrared lamps not specified.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8539311000",
    "Description": "Energy saving compact fluorescent lamps (CFL)",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8539319000",
    "Description": "Other Fluorescent, hot cathode not specified",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8539320000",
    "Description": "Mercury or sodium vapour lamps; metal halide lamps",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8539390000",
    "Description": "Other Discharge lamps, other than ultraviolet lamps not specified.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8539410000",
    "Description": "Arc-lamps",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8539490000",
    "Description": "Other Ultraviolet or infrared lamps; arclamps not specified.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8539510000",
    "Description": "-- Light-emitting diode (LED) modules",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8539520000",
    "Description": "-- Light-emitting diode (LED) lamps",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8539900000",
    "Description": "Parts of article of heading 85.39",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8540110000",
    "Description": "Cathoderay television picture tubes, including video monitor cathoderay tubes; Colour",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8540120000",
    "Description": "Cathoderay television picture tubes, incl. video monitor cathoderay tubes: Monochrome",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8540200000",
    "Description": "Television camera tubes; image converters and intensifiers; other photocathode tubes",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8540400000",
    "Description": "Data/graphic display tubes, monochrome/colour, with a phosphor dot screen pitch <0.4 mm",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8540600000",
    "Description": "Other cathoderay tubes",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8540710000",
    "Description": "Magnetrons",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8540790000",
    "Description": "Microwave tubes excluding gridcontrolled tubes not specified",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8540810000",
    "Description": "Receiver or amplifier valves and tubes",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8540890000",
    "Description": "Other valves and tube not specified",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8540910000",
    "Description": "Parts of cathoderay tubes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8540990000",
    "Description": "Parts of articles of heading 8540",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8541100000",
    "Description": "Diodes, other than photosensitive or light emitting diodes",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8541210000",
    "Description": "Transistors, other than photosensitive transistors with a dissipation rate of < 1 W",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8541290000",
    "Description": "Transistors, other than photosensitive transistors with a dissipation rate of> 1 W",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8541300000",
    "Description": "Thyristors, diacs and triacs, other than photosensitive devices",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8541410000",
    "Description": "--  Light-emitting diodes (LED)",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8541420000",
    "Description": "--  Photovoltaic cells not assembled in modules or made up into panels",
    "SU": "U",
    "ID": 0,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8541430000",
    "Description": "-- Photovoltaic cells assembled in modules or made up into panels",
    "SU": "U",
    "ID": 0,
    "VAT": "0",
    "LVY": 0,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8541490000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8541510000",
    "Description": "-- Semiconductor-based transducers",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8541590000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8541600000",
    "Description": "Mounted piezoelectric crystals",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8541900000",
    "Description": "Parts of articles of 85.41",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8542310000",
    "Description": "Processors and controllers, converters. or other circuits",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8542320000",
    "Description": "Electronic integrated circuits; Memories",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8542330000",
    "Description": "Electronic integrated circuits Amplifiers",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8542390000",
    "Description": "Other Electronic integrated circuits  not specified.",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8542900000",
    "Description": "Parts of article of heading 85.42",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8543100000",
    "Description": "Particle accelerators",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8543200000",
    "Description": "Signal generators",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8543300000",
    "Description": "Machines and apparatus for electroplating, electrolysis or electrophoresis",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8543401000",
    "Description": "--    Electronic cigarettes",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8543409000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8543700000",
    "Description": "Electrical machines and apparatus, having individual functions, not specified",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8543900000",
    "Description": "Parts of article of heading 85.43",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8544110000",
    "Description": "Winding wire of copper",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8544190000",
    "Description": "Other winding wire excl. wire of copper",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8544200000",
    "Description": "Coaxial cable and other coaxial electric conductors",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8544300000",
    "Description": "Ignition wiring sets and other wiring sets of a kind used in vehicles, aircraft or ship",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8544420000",
    "Description": "Other electric conductors, for a voltage not exceeding 1,000 V, fitted with connectors",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8544491000",
    "Description": "Almenec insulated cables, metallic part made of 7 strands of diameter 3.15mm and 3.55mm",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8544499000",
    "Description": "Other electric conductors, for a voltage not exceeding 1000v not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8544600000",
    "Description": "Other electric conductors, for a voltage exceeding 1,000 V",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8544700000",
    "Description": "Optical fibre cables",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8545110000",
    "Description": "Electrodes of a kind used for furnaces",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8545190000",
    "Description": "Electrodes of a king whose use is not specified in this heading",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8545200000",
    "Description": "Carbon Brushes",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8545901000",
    "Description": "Other electrodes Intended for the battery manufacturing industry",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8545909000",
    "Description": "Other electrodes",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8546100000",
    "Description": "Electrical insulators of glass",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8546200000",
    "Description": "Electrical insulators of ceramics",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8546900000",
    "Description": "Other Electrical insulators of any material other than glass and ceramics.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8547100000",
    "Description": "Insulating fittings of ceramics",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8547200000",
    "Description": "Insulating fittings of plastics",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8547900000",
    "Description": "Other Insulating fittings of any material other than ceramics and plastics.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8548000000",
    "Description": "Electrical parts of machinery or apparatus, not specified or included elsewhere in this",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8549110000",
    "Description": "-- Waste and scrap of lead-acid accumulators; spent lead-acid accumulators",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8549120000",
    "Description": "-- Other, containing lead, cadmium or mercury",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8549130000",
    "Description": "-- Sorted by chemical type and not containing lead, cadmium or mercury",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8549140000",
    "Description": "-- Unsorted and not containing lead, cadmium or mercury",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8549190000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8549210000",
    "Description": "-- Containing primary cells, primary batteries, electric accumulators, mercury-switches,",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8549290000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8549310000",
    "Description": "-- Containing primary cells, primary batteries, electric accumulators, mercury-switches,",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8549390000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8549910000",
    "Description": "-- Containing primary cells, primary batteries, electric accumulators, mercury-switches,",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8549990000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8601100000",
    "Description": "Rail locomotives powered from an external source of electricity",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8601200000",
    "Description": "Rail locomotives powered by electric accumulators",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8602100000",
    "Description": "Diesel-electric rail locomotives",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8602900000",
    "Description": "Other rail locomotives; locomotive tenders not specified in 8601.",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8603100000",
    "Description": "Railway or tramway coaches... excl. 86.04 Powered from ext. source of electricity",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8603900000",
    "Description": "Railway or tramway coaches... excl. 86.04 not Powered from ext. source of electricity",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8604000000",
    "Description": "Railway or tramway maintenance or service vehicles (workshops, cranes, ballast tampers,)",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8605000000",
    "Description": "Railway or tramway passenger coaches, not selfpropelled ( luggage vans,..)excl. 8604",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8606100000",
    "Description": "Railway or tramway Tank wagons and the like",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8606300000",
    "Description": "Railway or tramway Selfdischarging vans and wagons, excl. subheading 8606.10",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8606910000",
    "Description": "Tank wagons and the like excl.selfdischarging vans and wagons, Covered and closed",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8606920000",
    "Description": "Tank wagons and the like excl.selfdischarging van..., Open, with sides height > 60 cm",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8606990000",
    "Description": "Other railway or tramway goods vans and wagons, not selfpropelled, NES",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8607110000",
    "Description": "Driving bogies and bisselbogies for railway or tramway locomotives or rollingstock",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8607120000",
    "Description": "Other bogies and bisselbogies railway or tramway locomotives or rollingstock",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8607190000",
    "Description": "Other parts of railway or tramway locomotives or rollingstock",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8607210000",
    "Description": "Air brakes and parts thereof of railway or tramway locomotives or rollingstock",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8607290000",
    "Description": "Other brakes and parts thereof of railway or tramway locomotives... excl. air brake",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8607300000",
    "Description": "Hooks and other coupling devices, buffers, and parts thereof for railways/locomotives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8607910000",
    "Description": "Parts of locomotives not specified in 8607",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8607990000",
    "Description": "Parts of railway or rollingstock. Not specified in 8607",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8608000000",
    "Description": "Railway or tramway track fixtures and fittings. Mechanical, signalling,safety",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8609000000",
    "Description": "Containers and fliuds tanker designed for one or more modes of transport.",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/02/2020"
  },
  {
    "CET Code": "8701101000",
    "Description": "New single axle tractors",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8701102000",
    "Description": "Used single axle tractors",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8701211000",
    "Description": "---  New",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701212000",
    "Description": "---  Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701221000",
    "Description": "--- New",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701222000",
    "Description": "--- Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701231000",
    "Description": "--- New",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701232000",
    "Description": "--- Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701241000",
    "Description": "--- New",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701242000",
    "Description": "--- Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701291000",
    "Description": "--- New",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701292000",
    "Description": "--- Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701301000",
    "Description": "New track-laying tractors",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8701302000",
    "Description": "Used track-laying tractors",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8701911100",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8701911900",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701919000",
    "Description": "Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8701921100",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8701929000",
    "Description": "Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8701931100",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8701931900",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701939000",
    "Description": "New",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8701941100",
    "Description": "resented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8701941900",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701949000",
    "Description": "Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8701951100",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8701951900",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "08/04/2022"
  },
  {
    "CET Code": "8701959000",
    "Description": "Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702101110",
    "Description": "Motor vehicles for transport of>10=<22 persons,diesel or semidiesel engine, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702101190",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702101210",
    "Description": "Motor vehicles for transport of 23 to 30 persons,diesel or semidiesel engine, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702101290",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702101310",
    "Description": "Motor vehicles for transport of more than 30 persons,diesel or semidiesel engine, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702101390",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702102100",
    "Description": "For 10 to 22 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702102200",
    "Description": "For 23 to 30 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702102300",
    "Description": "For more than 30 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702201110",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702201190",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702201210",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702201290",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702201310",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702201390",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702202100",
    "Description": "For 10 to 22 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702202200",
    "Description": "For 23 to 30 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702202300",
    "Description": "For more than 30 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702301110",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702301190",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702301210",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702301290",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702301310",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702301390",
    "Description": "Other Fully Built Unit (FBU)",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702302100",
    "Description": "For 10 to 22 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702302200",
    "Description": "For 23 to 30 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702302300",
    "Description": "For more than 30 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702401110",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702401190",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702401210",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702401290",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702401310",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702401390",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702402100",
    "Description": "For 10 to 22 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702402200",
    "Description": "For 23 to 30 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702402300",
    "Description": "For more than 30 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702901110",
    "Description": "Motor vehicles for transport of>10=<22 persons,Petrol fuel engine, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702901190",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702901210",
    "Description": "Motor vehicles for transport of>10=<30 persons,Petrol fuel engine, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702901290",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702901310",
    "Description": "Motor vehicles for transport of>30 persons,Petrol fuel engine, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8702901390",
    "Description": "----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8702902100",
    "Description": "For 10 to 22 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702902200",
    "Description": "For 23 to 30 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8702902300",
    "Description": "For more than 30 persons, including the driver",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8703100000",
    "Description": "Vehicles specially designed for travelling on snow; golf cars and similar vehicles",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703211100",
    "Description": "Vehicles, with petrol fuel engine, of cylinder capacity=<1,000 cc, presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8703211900",
    "Description": "----  Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703212000",
    "Description": "Used Vehicles, with petrol fuel engine, of cylinder capacity=<1,000 cc",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703221100",
    "Description": "Vehicles, with petrol fuel engine, of cylinder capacity>1,000=<1500cc, presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8703221900",
    "Description": "----  Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703222000",
    "Description": "Used Vehicles, with petrol fuel engine, of cylinder capacity>1,000<1500cc",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703231100",
    "Description": "Vehicles, with petrol fuel engine, of cylinder capacity>1,500=<3000cc, presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8703231900",
    "Description": "----  Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703232000",
    "Description": "Used Vehicles, with petrol fuel engine, of cylinder capacity>1,500=<3000cc",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703241100",
    "Description": "Vehicles, with petrol fuel engine, of cylinder capacity >3000cc, presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8703241900",
    "Description": "----  Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703242000",
    "Description": "Used Vehicles, with petrol fuel engine, of cylinder capacity >3000cc",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703311100",
    "Description": "Vehicles, with diesel or semidiesel engine, of cylinder capacity >1500cc, presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8703311900",
    "Description": "----  Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703312000",
    "Description": "Used Vehicles, with diesel or semidiesel engine, of cylinder capacity <=1500cc",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703321100",
    "Description": "Vehicles, with diesel or semidiesel engine, of cylinder capacity >1500=<2500cc, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8703321900",
    "Description": "----  Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703322000",
    "Description": "Used Vehicles, with diesel or semidiesel engine, of cylinder capacity >1500=<2500cc",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703331100",
    "Description": "Vehicles, with diesel or semidiesel engine, of cylinder capacity >2500cc, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8703331900",
    "Description": "----  Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703332000",
    "Description": "Used Vehicles, with diesel or semidiesel engine, of cylinder capacity >2500cc",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703401100",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8703401900",
    "Description": "---  Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703402000",
    "Description": "Used",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703501100",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8703501900",
    "Description": "---  Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703502000",
    "Description": "Used",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703601100",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8703601900",
    "Description": "---  Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703602000",
    "Description": "Used",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703701100",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8703701900",
    "Description": "---  Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703702000",
    "Description": "Used",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703801100",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8703801900",
    "Description": "---  Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703802000",
    "Description": "Used",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 15,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8703900000",
    "Description": "Other Motor cars and other motor vehicles principally des",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 20,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8704101000",
    "Description": "Dumpers designed for offhighway use, Presented in CKD for assemblers",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8704109000",
    "Description": "--  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8704211110",
    "Description": "Dumpers for transport of goods,with diesel/semidiesel engine,g.v.w.=<5tonne, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8704211190",
    "Description": "-----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8704211910",
    "Description": "Vehicles for goods transport, diesel/semidiesel engine,g.v.w.=<5tonne,excl. dumpers, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8704211990",
    "Description": "-----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8704212000",
    "Description": "Used Vehicles for goods transport, diesel/semidiesel engine,g.v.w.=<5tonne,excl. dumpers",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8704221110",
    "Description": "Dumpers for transport of goods,with diesel/semidiesel engine,g.v.w.>5=<20tonne, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8704221190",
    "Description": "-----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8704221910",
    "Description": "Vehicles for goods transport, diesel/semidiesel eng.,g.v.w.>5=<20tonne,excl. dumpers,CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8704221990",
    "Description": "-----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8704222000",
    "Description": "Used Vehicle for goods transport, diesel/semidiesel eng.,g.v.w.>5=<20tonne,excl. dumpers",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 0,
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8704231110",
    "Description": "Dumpers for transport of goods,with diesel/semidiesel engine,g.v.w.>20tonne, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8704231190",
    "Description": "Dumpers for transport of goods,with diesel/semidiesel engine,g.v.w.>20tonne, FBU",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8704231910",
    "Description": "Vehicles for goods transport, diesel/semidiesel eng.,g.v.w.>20tonne,excl. dumpers,CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8704231990",
    "Description": "-----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8704232000",
    "Description": "Used Vehicles for goods transport, diesel/semidiesel eng.,g.v.w.>20tonne,excl. dumpers",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 0,
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8704311110",
    "Description": "Dumpers for transport of goods,with petrol fuel engine,g.v.w.=<5tonne, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8704311190",
    "Description": "-----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8704311910",
    "Description": "Vehicles for goods transport, petrol fuel eng.,g.v.w.=<5tonne,excl. dumpers,CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8704311990",
    "Description": "-----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8704312000",
    "Description": "Used Vehicles for goods transport, petrol fuel eng.,g.v.w.=<5tonne,excl. dumpers",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8704321110",
    "Description": "Dumpers for transport of goods,with petrol fuel engine,g.v.w.>5tonne, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8704321190",
    "Description": "-----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8704321910",
    "Description": "Vehicles for goods transport, petrol fuel eng.,g.v.w.>5tonne,excl. dumpers,CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8704321990",
    "Description": "-----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8704322000",
    "Description": "Used Vehicles for goods transport, petrol fuel eng.,g.v.w.>5tonne,excl. dumpers",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "18/02/2021"
  },
  {
    "CET Code": "8704411110",
    "Description": "-----  Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704411190",
    "Description": "----- Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704411910",
    "Description": "-----  Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704411990",
    "Description": "----- Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704412000",
    "Description": "--- Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704421110",
    "Description": "-----  Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704421190",
    "Description": "----- Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704421910",
    "Description": "-----  Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704421990",
    "Description": "-----   Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704422000",
    "Description": "--- Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704431110",
    "Description": "----- Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704431190",
    "Description": "----- Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704431910",
    "Description": "----- Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704431990",
    "Description": "----- Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704432000",
    "Description": "--- Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704511110",
    "Description": "-----  Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704511190",
    "Description": "-----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704511910",
    "Description": "-----  Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704511990",
    "Description": "-----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704512000",
    "Description": "---  Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704521110",
    "Description": "-----  Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704521190",
    "Description": "-----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704521910",
    "Description": "-----  Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704521990",
    "Description": "-----  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704522000",
    "Description": "---  Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704601100",
    "Description": "---  Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704601900",
    "Description": "---  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704602000",
    "Description": "--  Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8704901000",
    "Description": "Other types of vehicles for goods transport, not specified in 8704, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8704909000",
    "Description": "--  Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "05/01/2022"
  },
  {
    "CET Code": "8705100000",
    "Description": "Crane lorries",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8705200000",
    "Description": "Mobile drilling derricks",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8705300000",
    "Description": "Fire fighting vehicles",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8705400000",
    "Description": "Concretemixer lorries",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8705900000",
    "Description": "Other Special purpose motor vehicles, not specified in 8705",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8706001100",
    "Description": "Chassis fitted with engine,for assembly plant of vehicles under subheading 8701.20.10.00",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "LVY": 0,
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8706001200",
    "Description": "Chassis fitted with engine,for assembly plant of vehicles under heading 87.02",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8706001300",
    "Description": "Chassis fitted with engine,for assembly plant of vehicles under heading 87.03",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8706001400",
    "Description": "Chassis fitted with engine,for assembly plant of vehicles under heading 87.04",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8706009000",
    "Description": "Other Chassis fitted with engines, not for assembly plant",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8707101000",
    "Description": "Bodies (including cabs), for the motor vehicles of 8703, for assembly plant",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/07/2020"
  },
  {
    "CET Code": "8707109000",
    "Description": "Bodies (including cabs), for the motor vehicles of 8703,not for assembly plant",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/07/2020"
  },
  {
    "CET Code": "8707901100",
    "Description": "Bodies (including cabs), for the motor vehicles 8701.20.10.00, for assembly plant",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/07/2020"
  },
  {
    "CET Code": "8707901200",
    "Description": "Bodies (including cabs), for the motor vehicles of 8702, for assembly plant",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/07/2020"
  },
  {
    "CET Code": "8707901300",
    "Description": "Bodies (including cabs), for the motor vehicles of 8704, for assembly plant",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/07/2020"
  },
  {
    "CET Code": "8707909000",
    "Description": "Bodies (including cabs), for the motor vehicles not specified in 8707,for assembly plant",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/07/2020"
  },
  {
    "CET Code": "8708100000",
    "Description": "Bumpers and parts thereof of the motor vehicles",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8708210000",
    "Description": "Safety seat belts of the motor vehicles",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8708220000",
    "Description": "-- Front windscreens (windshields), rear windows and other windows specified in Subheadi",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8708290000",
    "Description": "Parts and accessories of the motor vehicles not specified in 8707",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8708300000",
    "Description": "Brakes and servobrakes; parts thereof of the motor vehicles",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8708400000",
    "Description": "Gear boxes and parts thereof of the motor vehicles",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8708500000",
    "Description": "Driveaxles with differential and nondriving axles; parts thereof of motor vehicle",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8708700000",
    "Description": "Road wheels and parts and accessories thereof of motor vehicle",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8708800000",
    "Description": "Suspension systems and parts thereof (including shockabsorbers) of motor vehicle",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8708910000",
    "Description": "Radiators and parts thereof of motor vehicle",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8708920000",
    "Description": "Silencers (mufflers) and exhaust pipes; parts thereof of motor vehicle",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8708930000",
    "Description": "Clutches and parts thereof of motor vehicle",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8708940000",
    "Description": "Steering wheels, steering columns and steering boxes; parts thereof of motor vehicle",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8708950000",
    "Description": "Safety airbags with inflater system; parts thereof of motor vehicle",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8708990000",
    "Description": "other parts of motor vehicle not specified in 8708",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8709110000",
    "Description": "Electrical Works trucks of the type used in factories, dock area, selfpropelled",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8709190000",
    "Description": "Non-electrical Works trucks of the type used in factories, dock area, selfpropelled",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8709900000",
    "Description": "Parts of worktruck of the type used in factories, warehouses, dock areas or airports",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8710000000",
    "Description": "Tanks and other armoured fighting vehicles, motorised, whet",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8711101000",
    "Description": "Motorcycles and cycles fitted with auxiliary motor,petrol fuel, capacity =<50cc,ckd",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "16/09/2020"
  },
  {
    "CET Code": "8711109000",
    "Description": "Motorcycles and cycles fitted with auxiliary motor,petrol fuel, capacity =<50cc, fbu",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8711201000",
    "Description": "Motorcycles and cycles fitted with auxiliary motor,petrol fuel, capacity >50<250cc, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "16/09/2020"
  },
  {
    "CET Code": "8711209100",
    "Description": "Motorcycles and cycles fitted with auxiliary motor,petrol fuel, capacity >50=<80cc, FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8711209900",
    "Description": "Motorcycles and cycles fitted with auxiliary motor,petrol fuel, capacity >80=<250cc, FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8711301000",
    "Description": "Motorcycles and cycles fitted with auxiliary motor,petrol fuel, capacity >250=<500cc,CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "16/09/2020"
  },
  {
    "CET Code": "8711309000",
    "Description": "Motorcycles and cycles fitted with auxiliary motor,petrol fuel, capacity >250=<500cc,FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8711401000",
    "Description": "Motorcycles and cycles fitted with auxiliary motor,petrol fuel, capacity >500=<800cc,CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "16/09/2020"
  },
  {
    "CET Code": "8711409000",
    "Description": "Motorcycles and cycles fitted with auxiliary motor,petrol fuel, capacity >500=<800cc,FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8711501000",
    "Description": "Motorcycles and cycles fitted with auxiliary motor,petrol fuel, capacity >800cc,CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "16/09/2020"
  },
  {
    "CET Code": "8711509000",
    "Description": "Motorcycles and cycles fitted with auxiliary motor,petrol fuel, capacity >800cc,FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8711601000",
    "Description": "Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8711609000",
    "Description": "Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8711901000",
    "Description": "Motorcycles and cycles fitted with auxiliary motor,not specified in 8711, CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "16/09/2020"
  },
  {
    "CET Code": "8711909000",
    "Description": "Motorcycles and cycles fitted with auxiliary motor,not specified in 8711, FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8712001000",
    "Description": "Bicycles and other cycles (including delivery tricycles), not motorised, Presented CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8712009000",
    "Description": "Bicycles and other cycles (including delivery tricycles), not motorised, Presented FBU",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8713100000",
    "Description": "Carriages for disabled persons, Not mechanically propelled",
    "SU": "U",
    "ID": 0,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8713900000",
    "Description": "Carriages for disabled persons, mechanically propelled.",
    "SU": "U",
    "ID": 0,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8714101000",
    "Description": "Parts and accessories of motorcycles (including mopeds) for assembly industry",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8714109000",
    "Description": "Parts and accessories of motorcycles (including mopeds) not for assembly industry, FBU",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8714200000",
    "Description": "Parts and accessories of carriages for disabled persons",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "20/08/2020"
  },
  {
    "CET Code": "8714911000",
    "Description": "Frames and forks, and parts thereof for assembly industry",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8714919000",
    "Description": "Frames and forks, and parts thereof not for assembly industry",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8714921000",
    "Description": "Wheel rims and spokes for assembly industry",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8714929000",
    "Description": "Wheel rims and spokes not for assembly industry",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8714931000",
    "Description": "Hubs, excl. coaster braking hubs...and freewheel sprocketwheels for assembly industry",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8714939000",
    "Description": "Hubs, excl. coaster braking... and freewheel sprocketwheel not for assembly industry",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8714941000",
    "Description": "Brakes, including coaster braking hubs and hub brakes, and parts for assembly industry",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8714949000",
    "Description": "Brakes, incl. coaster braking hub and hub brake, and parts thereof not for assembly ind.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8714951000",
    "Description": "Saddles for assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8714959000",
    "Description": "Saddles not for assembly industry",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8714961000",
    "Description": "Pedals and crankgear, and parts thereof for assembly industry",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8714969000",
    "Description": "Pedals and crankgear, and parts thereof not for assembly industry",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8714991000",
    "Description": "Other parts not specified for assembly industry",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8714999000",
    "Description": "Other parts not specified not for assembly industry",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8715000000",
    "Description": "Baby carriages and parts thereof.",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8716100000",
    "Description": "Trailers and semitrailers of the caravan type, for housing or camping",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8716200000",
    "Description": "Selfloading or selfunloading trailers and semitrailers for agricultural purposes",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8716310000",
    "Description": "Tanker trailers and tanker semitrailers",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8716391000",
    "Description": "Trailers and semitrailers for the haulage of timber",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8716392100",
    "Description": "Other tipping trailers =< 6 cubic mtres and weight <1600kg",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8716392200",
    "Description": "Tipping trailers with a carrying capacity of =<6 cubic metres and a weight of =>1,600 kg",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8716392300",
    "Description": "Other tipping trailers with a carrying capacity of more than 6 cubic metres",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8716399000",
    "Description": "Other tipping trailers and semitrailers not specified",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8716400000",
    "Description": "Other trailers and semitrailers not specified",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "LVY": 25,
    "DATE OF VALIDITY": "09/04/2022"
  },
  {
    "CET Code": "8716801000",
    "Description": "Vehicles drawn by animals",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/02/2020"
  },
  {
    "CET Code": "8716802100",
    "Description": "Wheel barrows",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/02/2020"
  },
  {
    "CET Code": "8716802900",
    "Description": "Other hand propelled vehicles not specified in this subheading.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/02/2020"
  },
  {
    "CET Code": "8716809000",
    "Description": "Other vehicles not specified.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/02/2020"
  },
  {
    "CET Code": "8716901000",
    "Description": "Parts of trailers and semitrailers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8716902000",
    "Description": "Parts of vehicles drawn by animals",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8716909000",
    "Description": "Other parts of vehicles not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "8801000000",
    "Description": "Balloons and dirigibles; gliders, hang gliders and other nonpowered aircraft.",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/02/2020"
  },
  {
    "CET Code": "8802110000",
    "Description": "Helicopters of an unladen weight not exceeding 2,000 kg",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "8802120000",
    "Description": "Helicopters of an unladen weight exceeding 2,000 kg",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "8802200000",
    "Description": "Aeroplanes and other aircraft, of an unladen weight not exceeding 2,000 kg",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8802300000",
    "Description": "Aeroplanes and other aircraft, of an unladen weight >2,000 kg < 15,000 kg",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8802400000",
    "Description": "Aeroplanes and other aircraft, of an unladen weight exceeding 15,000 kg",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "8802600000",
    "Description": "Spacecraft (including satellites) and suborbital and spacecraft launch vehicles",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/02/2020"
  },
  {
    "CET Code": "8804000000",
    "Description": "Parachutes (including dirigible parachutes and paragliders)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "8805100000",
    "Description": "Aircraft launching gear and parts thereof; deckarrestor /similar gear and parts thereof",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "8805210000",
    "Description": "Air combat simulators and parts thereof",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "8805290000",
    "Description": "Other Ground flying trainers and parts thereof not specified.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "8806100000",
    "Description": "- Designed for the carriage of passengers",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8806210000",
    "Description": "-- With maximum take-off weight not more than 250 g",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8806220000",
    "Description": "-- With maximum take-off weight more than 250 g but not more than 7 kg",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8806230000",
    "Description": "-- With maximum take-off weight more than 7 kg but not more than 25 kg",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8806240000",
    "Description": "-- With maximum take-off weight more than 25 kg but not more than 150 kg",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8806290000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8806910000",
    "Description": "-- With maximum take-off weight not more than 250 g",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8806920000",
    "Description": "-- With maximum take-off weight more than 250 g but not more than 7 kg",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8806930000",
    "Description": "-- With maximum take-off weight more than 7 kg but not more than 25 kg",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8806940000",
    "Description": "-- With maximum take-off weight more than 25 kg but not more than 150 kg",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8806990000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8807100000",
    "Description": "- Propellers and rotors and parts thereof",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "8807200000",
    "Description": "- Under-carriages and parts thereof",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "8807300000",
    "Description": "- Other parts of aeroplanes, helicopters or unmanned aircraft",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "8807900000",
    "Description": "- Other",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "8901101100",
    "Description": "Passenger, cruise ships, excursion boats mechanically propelled, =< 500 tonnes",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/02/2020"
  },
  {
    "CET Code": "8901101200",
    "Description": "Passenger, cruise ships, excursion boats mechanically propelled, >500 tonnes",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/02/2020"
  },
  {
    "CET Code": "8901109000",
    "Description": "Other Mechanically propelled passenger carrying vessels not specified in 8901",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/02/2020"
  },
  {
    "CET Code": "8901200000",
    "Description": "Tankers vessels",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/02/2020"
  },
  {
    "CET Code": "8901300000",
    "Description": "Refrigerated vessels, other than those of subheading 8901.20",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/02/2020"
  },
  {
    "CET Code": "8901901100",
    "Description": "Mechanically propelled vessels for the transport of goods, gross tonnage=< 500 tonnes",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/02/2020"
  },
  {
    "CET Code": "8901901200",
    "Description": "Mechanically propelled vessels for the transport of goods, gross tonnage> 500 tonnes",
    "SU": "U",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "8901909000",
    "Description": "Mech. propelled vessels for the transport of goods, gross tonnage not specified in 8901",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/02/2020"
  },
  {
    "CET Code": "8902001000",
    "Description": "Vessels for fishing, processing or preserving fishery products of a tonnage=<10 tonnes",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8902002000",
    "Description": "Vessels for fishing, processing/preserving fishery products of a tonnage > 10 and =< 40",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8902003100",
    "Description": "Fishing vessels equiped with a freezer or freezing unit, capacity >40<=300 Tonnes",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8902003900",
    "Description": "Fishing vessels not equiped with a freezer or freezing unit, capacity >40 <=300 Tonnes",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8902004100",
    "Description": "Fishing vessels equiped with a freezer or freezing unit, capacity >300 Tonnes",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8902004900",
    "Description": "Fishing vessels not equiped with a freezer or freezing unit, capacity>300 Tonnes",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8903110000",
    "Description": "-- Fitted or designed to be fitted with a motor, unladen (net) weight (excluding the mot",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8903120000",
    "Description": "-- Not designed for use with a motor and unladen (net) weight not exceeding 100 kg",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8903190000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8903210000",
    "Description": "-- Of a length not exceeding 7.5 m",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8903220000",
    "Description": "-- Of a length exceeding 7.5 m but not exceeding 24 m",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8903230000",
    "Description": "-- Of a length exceeding 24 m",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8903310000",
    "Description": "-- Of a length not exceeding 7.5 m",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8903320000",
    "Description": "-- Of a length exceeding 7.5 m but not exceeding 24 m",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8903330000",
    "Description": "-- Of a length exceeding 24 m",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8903930000",
    "Description": "-- Of a length not exceeding 7.5 m",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "8903990000",
    "Description": "Yachts and other vessels for pleasure",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 50,
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8904000000",
    "Description": "Tugs and pusher craft.",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8905100000",
    "Description": "Dredgers",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8905200000",
    "Description": "Floating or submersible drilling or production platforms",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8905900000",
    "Description": "Lightvessels, firefloats, floating cranes, and other vessels not specified in 8905",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8906100000",
    "Description": "Warships",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8906900000",
    "Description": "Lifeboats other than rowing boats.",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8907100000",
    "Description": "Inflatable rafts",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8907900000",
    "Description": "Other floating structures (eg rafts, tanks, cofferdams...) excl. Inflatable rafts",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "8908000000",
    "Description": "Vessels and other floating structures for breaking up.",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9001100000",
    "Description": "Optical fibres, optical fibre bundles and cables",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9001200000",
    "Description": "Sheets and plates of polarising material",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9001300000",
    "Description": "Contact lenses",
    "SU": "U",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "9001401000",
    "Description": "Ophthalmic lenses",
    "SU": "U",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "9001409000",
    "Description": "Sheets and plates of polarising material not specified in 90.01",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9001501000",
    "Description": "Ophthalmic lenses",
    "SU": "U",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "9001509000",
    "Description": "Spectacle lenses of other materials escl. Ophthalmic lenses",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9001900000",
    "Description": "Prisms, mirrors and other optical elements, of any material.",
    "SU": "KG",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "9002110000",
    "Description": "Objective lenses for cameras, projectors or photographic enlargers or reducers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9002190000",
    "Description": "Objective lenses excl. used for cameras, projectors or photographic enlargers/reducers",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9002200000",
    "Description": "Filters",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "9002900000",
    "Description": "Prisms, mirrors..of any material, mounted, being parts of or fittings for instruments.",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "9003110000",
    "Description": "Frames and mountings of plastics",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9003190000",
    "Description": "Frames and mountings of other materials",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9003900000",
    "Description": "Parts of Frames and mountings for spectacles, goggles or the like",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9004100000",
    "Description": "Sunglasses",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9004901000",
    "Description": "For correcting vision",
    "SU": "U",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "20/06/2020"
  },
  {
    "CET Code": "9004909000",
    "Description": "Spectacles, goggles not for correcting vision",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9005100000",
    "Description": "Binoculars",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9005800000",
    "Description": "Monoculars, optical telescopes, astronomical excl. radioastronomy instruments.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9005900000",
    "Description": "Parts and accessories of Binoculars, monoculars, other optical telescopes",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9006300000",
    "Description": "Cameras specially designed for underwater, aerial survey or medical/surgical examination",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9006400000",
    "Description": "Instant print cameras",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9006530000",
    "Description": "Cameras with a throughthelens viewfinder, for roll film of a width =35 mm",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9006590000",
    "Description": "Camera with a throughthelens viewfinder, for roll film of a width not specified in 90.06",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9006610000",
    "Description": "Discharge lamp (\"electronic\") flashlight apparatus",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9006690000",
    "Description": "flashbulbs",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9006910000",
    "Description": "Parts and accessorie for cameras",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9006990000",
    "Description": "Parts and accessorie for photographic flashlight apparatus/flashbulbs excl. lamps 85.39",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9007100000",
    "Description": "Cinematographic Cameras",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9007200000",
    "Description": "Cinematographic Projectors",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9007910000",
    "Description": "Parts and accessories for cameras",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9007920000",
    "Description": "Parts and accessories for projectors",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9008500000",
    "Description": "Image Projectors, enlargers and reducers excl. cinematographic",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9008900000",
    "Description": "Parts and accessories of projectors.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9010100000",
    "Description": "Apparatus and equipment for automatically developing photographic film or paper in rolls",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9010500000",
    "Description": "Other apparatus and equipment for photographic laboratories; negatoscopes",
    "SU": "U",
    "ID": 20,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9010600000",
    "Description": "Projection screens",
    "SU": "U",
    "ID": 20,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9010900000",
    "Description": "Parts and accessories of equipment for photographic, negatoscopes; projection screens.",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9011100000",
    "Description": "Stereoscopic microscopes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9011200000",
    "Description": "Other microscopes, for photomicrography, cinephotomicrography or microprojection",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9011800000",
    "Description": "Other microscopes not specified in 90.11",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9011900000",
    "Description": "Parts and accessories of comp. microscopes, photomicrography... or microprojection.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9012100000",
    "Description": "Microscopes other than optical microscopes; diffraction apparatus",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9012900000",
    "Description": "Parts and accessories of Microscopes excl. optical microscopes; diffraction apparatus.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9013100000",
    "Description": "Telescopic sights for fitting to arms, periscope, telescopes, parts of machines applNES",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9013200000",
    "Description": "Lasers, other than laser diodes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9013800000",
    "Description": "Other optical devices, appliances and instruments NES",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9013900000",
    "Description": "Parts and accessories of optical appliances and instruments of 9013",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9014100000",
    "Description": "Direction finding compasses",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9014200000",
    "Description": "Instruments and appliances for aeronautical or space navigation (other than compasses)",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9014800000",
    "Description": "Other navigational instruments and appliances not specified",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9014900000",
    "Description": "Parts and accessories of navigational instruments and appliances.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9015100000",
    "Description": "Rangefinders",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9015200000",
    "Description": "Theodolites and tachymeters (tacheometers)",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9015300000",
    "Description": "Levels",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9015400000",
    "Description": "Photogrammetrical surveying instruments and appliances",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9015800000",
    "Description": "Other instruments and appliances for Surveying not specified in 9015",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9015900000",
    "Description": "Parts and accessories of Surveying, hydrographic... excluding compasses; rangefinders.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9016000000",
    "Description": "Balances of a sensitivity of 5 cg or better, with or without weights.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9017100000",
    "Description": "Drafting tables and machines, whether or not automatic",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "9017200000",
    "Description": "Other drawing, markingout or mathematical calculating instruments",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "9017300000",
    "Description": "Micrometers, callipers and gauges",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "9017800000",
    "Description": "Other Drawing, markingout or mathematical calculating instruments not specified in 90.17",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9017900000",
    "Description": "Parts and accessories of Drawing, markingout or mathematical calculating instruments",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9018110000",
    "Description": "Electro-cardiographs",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9018120000",
    "Description": "Ultrasonic scanning apparatus",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9018130000",
    "Description": "Magnetic resonance imaging apparatus",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9018140000",
    "Description": "Scintigraphic apparatus",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9018190000",
    "Description": "Other electrodiagnostic apparatus not specified in 90.18",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9018200000",
    "Description": "Ultraviolet or infrared ray apparatus",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9018310000",
    "Description": "Syringes, with or without needles",
    "SU": "U",
    "ID": 5,
    "LVY": 65,
    "DATE OF VALIDITY": "18/08/2018"
  },
  {
    "CET Code": "9018320000",
    "Description": "Tubular metal needles and needles for sutures",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9018390000",
    "Description": "Catheters, cannulae and the like",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9018410000",
    "Description": "Dental drill engines.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9018490000",
    "Description": "Other instruments and appliances used in dental sciences not specified in 9018",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9018500000",
    "Description": "Other ophthalmic instruments and appliances NES",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9018900000",
    "Description": "Instruments and appliances used medical, surgical, denta. sciences not specified 90.18",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9019101000",
    "Description": "\"Jacuzzi\" and similar hydromassage apparatus",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9019109000",
    "Description": "Other Mechano-therapy appliance; massage, psychological apparatus not specified in 90.19",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9019200000",
    "Description": "Ozone, oxygen, aerosol therapy and other therapeutic respiration apparatus",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9020000000",
    "Description": "Other breathing appliances/gas masks, excl. protective masks having mechanical parts..",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "29/09/2021"
  },
  {
    "CET Code": "9021100000",
    "Description": "Orthopaedic or fracture appliances",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9021210000",
    "Description": "Artificial teeth",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9021290000",
    "Description": "Other orthopaedic or fracture appliances not specified in 9021",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9021310000",
    "Description": "Artificial body joints",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9021390000",
    "Description": "Other artificial parts of the body not specified",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9021400000",
    "Description": "Hearing aids, excluding parts and accessories",
    "SU": "U",
    "ID": 0,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9021500000",
    "Description": "Pacemakers for stimulating heart muscles, excluding parts and accessories",
    "SU": "U",
    "ID": 0,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9021900000",
    "Description": "Other orthopaedic appliances not specified in 9021",
    "SU": "KG",
    "ID": 0,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9022120000",
    "Description": "Computed tomography apparatus",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9022130000",
    "Description": "Other,apparatus based on the use of X-rays or of alpha, betaused for dental uses",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9022140000",
    "Description": "Apparatus based on the use of X-rays or of alpha, betaused for medical, surgical .",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9022190000",
    "Description": "Apparatus based on the use of X-rays or of alpha, beta for other uses not specified 9022",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9022210000",
    "Description": "Apparatus based on the use of alpha, beta...for medical, surgical, dental or vet uses",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9022290000",
    "Description": "Apparatus based on the use of alpha, beta...for other uses not specified in 90.22",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9022300000",
    "Description": "Xray tubes",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9022900000",
    "Description": "Other, Apparatus based on x ray, alpha, beta..a including parts and accessories",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9023000000",
    "Description": "Instruments, apparatus and models, for demonstrational purposes not for other uses.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9024100000",
    "Description": "Machines and appliances for testing metals",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9024800000",
    "Description": "Other Machines and appliances for testing hardness, strength..of materials excl. metals",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9024900000",
    "Description": "Parts and accessories of Machines and appliances for testing materials",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9025110000",
    "Description": "Liquid-filled, Thermometers and pyrometer, not combined for direct reading.",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9025190000",
    "Description": "Non Liquid-filled, Thermometers and pyrometer, not combined for direct reading.",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9025800000",
    "Description": "Hydrometers and similar instruments, barometers... and combination of these instruments.",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9025900000",
    "Description": "Parts and accessories of thermometers, pyrometers.. and psychrometers",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9026100000",
    "Description": "Instruments and apparatus for measuring or checking the flow or level of liquids",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9026200000",
    "Description": "Instruments and apparatus for measuring or checking pressure",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9026800000",
    "Description": "Other instruments or apparatus for measuring/checking variables of liquids or gases NES",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9026900000",
    "Description": "Parts and accessoriesof instrument or apparatus for measuring/ checking liquid or gas",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9027100000",
    "Description": "Gas or smoke analysis apparatus",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9027200000",
    "Description": "Chromatographs and electrophoresis instruments",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9027300000",
    "Description": "Spectrometers, spectrophotometers and spectrographs using optical radiations",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9027500000",
    "Description": "Other instruments and apparatus using optical radiations (UV, visible, IR) NES",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9027810000",
    "Description": "-- Mass spectrometers",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9027890000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9027900000",
    "Description": "Microtomes; parts and accessories",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9028100000",
    "Description": "Gas meters",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9028200000",
    "Description": "Liquid meters",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9028300000",
    "Description": "Electricity meters",
    "SU": "U",
    "ID": 10,
    "LVY": 35,
    "DATE OF VALIDITY": "09/09/2020"
  },
  {
    "CET Code": "9028900000",
    "Description": "Parts and accessories Gas, liquid or electricity supply or production meters",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9029100000",
    "Description": "Revolution counters, production counters, taximeters, mileometers... and the like",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9029200000",
    "Description": "Speed indicators and tachometers; stroboscopes",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9029900000",
    "Description": "Parts and accessories of articles of heading 90.29",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9030100000",
    "Description": "Instruments and apparatus for measuring or detecting ionising radiations",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9030200000",
    "Description": "Oscilloscopes and oscillographs",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9030310000",
    "Description": "Multimeters without a recording device",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9030320000",
    "Description": "Multimeters with a recording device",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9030330000",
    "Description": "Other measuring instrument without a recording device excl. multimeter",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9030390000",
    "Description": "Other measuring instrument with a recording device excl. multimeter",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9030400000",
    "Description": "Other Instruments/apparatus, designed for telecommunications (eg crosstalk meters..)",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9030820000",
    "Description": "Instruments and apparatus for measuring or checking semiconductor wafers or devices",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9030840000",
    "Description": "Instruments/apparatus, designed for telecommunications, with a recording device",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9030890000",
    "Description": "Instruments/apparatus, designed for telecommunications not specified in 9030",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9030900000",
    "Description": "Parts and accessories of Instruments/apparatus, designed for telecommunications",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9031100000",
    "Description": "Machines for balancing mechanical parts",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9031200000",
    "Description": "Test benches",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9031410000",
    "Description": "Instruments for inspecting semiconductor wafers/reticles used in manuf. of semiconductor",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9031490000",
    "Description": "Other optical Measuring or checking instruments not specified in 9031",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9031800000",
    "Description": "Other measuring or checking instruments, appliances and machines, not specified",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9031900000",
    "Description": "Parts and accessories of measuring or checking instruments, appliances and machines",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9032100000",
    "Description": "Thermostats",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9032200000",
    "Description": "Manostats",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9032810000",
    "Description": "Other instruments and apparatus for Hydraulic or pneumatic",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9032890000",
    "Description": "Other instruments and apparatus for purposes not specified in 9032",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "08/06/2015"
  },
  {
    "CET Code": "9032900000",
    "Description": "Parts and accessories of Automatic regulating or controlling instruments and apparatus",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9033000000",
    "Description": "Parts and accessories (not specified in this Chapter) for machines... of Chapter 90.",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9101110000",
    "Description": "Electronics Wristwatches, pocketwatches and other watches with mechanical display only",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9101190000",
    "Description": "Electronics Wristwatches, pocketwatches and other watches without mechanical display",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9101210000",
    "Description": "Wristwatches, pocketwatches and other watches with automatic winding",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9101290000",
    "Description": "Wristwatches, pocketwatches and other watches without automatic winding",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9101910000",
    "Description": "Other non specified Electrically operated Wristwatches, pocketwatches and other watches",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9101990000",
    "Description": "Other Wristwatches, pocketwatches not specified in 91.01 excl. 9101.9100.00",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9102110000",
    "Description": "Wristwatches...including stopwatches, excl. 91.01 with mechanical display only",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9102120000",
    "Description": "Wristwatches...including stopwatches, excl. 91.01 with optoelectronic display only",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9102190000",
    "Description": "Wristwatches...including stopwatches, excl. 91.01 Not elsewhere specified.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9102210000",
    "Description": "wristwatches, whether or not incorporating a stopwatch facility with automatic winding",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9102290000",
    "Description": "Wristwatches, whether or not incorporating stopwatch facility without automatic winding",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9102910000",
    "Description": "Other unspecified wristwatches Electrically operated",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9102990000",
    "Description": "Other wristwatches, pocketwatches not specified in 91.02",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9103100000",
    "Description": "Clocks with watch movements, excluding clocks of heading 91.04, electrically operated",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9103900000",
    "Description": "Clocks with watch movements, excluding clock of heading 91.04. Non electrically operated",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9104000000",
    "Description": "Instrument panel clocks and clocks of a similar type for vehicles, aircraft",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9105110000",
    "Description": "Other clocks, Electrically operated",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9105190000",
    "Description": "Other clocks,not electrically operated (Alarm clocks)",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9105210000",
    "Description": "Wall clocks Electrically operated",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9105290000",
    "Description": "Wall clocks not electrically operated",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9105910000",
    "Description": "Other clocks not specified in 91.05, Electrically operated",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9105990000",
    "Description": "Other clocks not specified in 91.05 not electrically operated",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9106100000",
    "Description": "Timeregisters; timerecorders",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9106900000",
    "Description": "Time of day recording apparatus and apparatus... excl. Timeregisters; timerecorders",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9107000000",
    "Description": "Time switches with clock or watch movement or with synchronous motor.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9108110000",
    "Description": "Watch movements, electronics, with mechanical display only or mechanical display adapter",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9108120000",
    "Description": "Watch movements, electronics, With optoelectronic display only",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9108190000",
    "Description": "Other electrically operated watch movement not specified.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9108200000",
    "Description": "Watch movements with automatic winding",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9108900000",
    "Description": "Watch movements without automatic winding",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9109100000",
    "Description": "Clock movements, complete and assembled electrically operated",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9109900000",
    "Description": "Other clock movements, complete and assembled not specified in this heading.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9110110000",
    "Description": "Complete watch movements, unassembled or partly assembled",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9110120000",
    "Description": "incomplete watch movements, unassembled or partly assembled",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9110190000",
    "Description": "Complete watch Rough movements, unassembled or partly assembled",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9110900000",
    "Description": "Other clock movements, not specified in 91.10",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9111100000",
    "Description": "Watch Cases of precious metal or of metal clad with precious metal",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9111200000",
    "Description": "Watch Cases of base metal, whether or not gold or silverplated",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9111800000",
    "Description": "Other Watch cases",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9111900000",
    "Description": "Parts of watch cases",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9112200000",
    "Description": "Clock Cases",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9112900000",
    "Description": "Parts of Clock Cases",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9113100000",
    "Description": "Watch straps, bands and bracelet of precious metal or of metal clad with precious metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9113200000",
    "Description": "Watch straps, bands and bracelet of base metal, whether or not gold or silverplated",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9113900000",
    "Description": "Other Watch straps, not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9114300000",
    "Description": "Dials of watches",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9114400000",
    "Description": "Plates and bridges of watches",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9114900000",
    "Description": "Other clock or watch parts not specified in this heading.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9201100000",
    "Description": "Upright pianos",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9201200000",
    "Description": "Grand pianos",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9201900000",
    "Description": "Other Pianos,not specified in 92.01",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9202100000",
    "Description": "C Musical instruments Played with a bow",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9202900000",
    "Description": "Autres Musical instruments not Played with a bow",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9205100000",
    "Description": "Brass-wind musical instruments",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9205900000",
    "Description": "Wind musical instruments excl. Brasswind instruments",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9206000000",
    "Description": "Percussion musical instruments (for example, drums, xylophones, cymbals..).",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9207100000",
    "Description": "Keyboard instruments, other than accordions",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9207900000",
    "Description": "Musical instruments, the sound of which must be amplified, not specified in 92.07",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9208100000",
    "Description": "Musical boxes",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9208900000",
    "Description": "Fairground organs...and other musical instru. not falling within any other heading",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9209300000",
    "Description": "Parts of strings Musical instrument",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9209910000",
    "Description": "Parts and accessories for pianos",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9209920000",
    "Description": "Parts and accessories for the musical instruments of heading 92.02",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9209940000",
    "Description": "Parts and accessories for the musical instruments of heading 92.07",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9209990000",
    "Description": "Parts (of other musical instrument not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9301100000",
    "Description": "Artillery weapons (for example, guns, howitzers and mortars) ",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9301200000",
    "Description": "Rocket launchers; flamethrowers; grenade launchers; torpedo tubes and similar projectors",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9301909000",
    "Description": "Other Military weapons, other than revolvers, pistols anot specified in chapter 93",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9302001000",
    "Description": "Revolvers",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9302002100",
    "Description": "Semiautomatic Singlebarrelled pistols",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9302002900",
    "Description": "Other Singlebarrelled pistols not specified in this 9302.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9302003000",
    "Description": "Multibarrelled pistols",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9303100000",
    "Description": "Muzzle-loading firearms",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9303201100",
    "Description": "Pump action weapons for sporting, hunting or targetshooting",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9303201200",
    "Description": "Semiautomatic firearms sporting, hunting or targetshooting",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9303201900",
    "Description": "Other Rifles and carabines for sporting, hunting... not specified in 9303.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9303202000",
    "Description": "Multibarrelled rifles and carabines, including combined rifles for sporting..",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9303301000",
    "Description": "One shop weapons with breeches for sporting, hunting or targetshooting",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9303302000",
    "Description": "Semiautomatic weapons for sporting, hunting or targetshooting",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9303309000",
    "Description": "Autres",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9303900000",
    "Description": "Other firearms and similar article not specified in 93.03",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9304000000",
    "Description": "Other arms (eg spring, air or gas guns...), excluding those of heading 93.07.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9305101000",
    "Description": "Firing mechanisms of revolvers or pistols",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305102000",
    "Description": "Shells of revolvers or pistols",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305103000",
    "Description": "Barrels of revolvers or pistols",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305104000",
    "Description": "Pistols latch hooks and gas struts of revolvers or pistols",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305105000",
    "Description": "Loaders and their parts of revolvers or pistols",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305106000",
    "Description": "Silencers (sound absorbers) and their parts of revolvers or pistols",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305107000",
    "Description": "Butts butt pads of revolvers or pistols",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305108000",
    "Description": "Slides (for pistols) and drums (for revolvers)",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305109000",
    "Description": "Other parts and accessories of revolvers or pistols not specified in 93.05",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305201000",
    "Description": "Rifled barrels of shotguns or rifles of heading 93.03",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305202000",
    "Description": "Firing mechanism of shotguns or rifles of heading 93.03",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305203000",
    "Description": "Shells of shotguns or rifles of heading 93.03",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305204000",
    "Description": "Pistols, latch fingers and gas struts of shotguns or rifles of heading 93.03",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305205000",
    "Description": "Loaders and their parts of shotguns or rifles of heading 93.03",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305206000",
    "Description": "Silencers (sound absorbers) and their parts of shotguns or rifles of heading 93.03",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305207000",
    "Description": "Flash suppressors and their parts of shotguns or rifles of heading 93.03",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305208000",
    "Description": "Breeches, locks and breech boxes of shotguns or rifles of heading 93.03",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305209000",
    "Description": "Other parts and accessories not specified of shotguns or rifles of heading 93.03",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305911100",
    "Description": "Firing mechanism of guns not specifified in chapter 93",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305911200",
    "Description": "Shells of guns not specifified in chapter 93",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305911300",
    "Description": "Rifled barrels of guns not specifified in chapter 93",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305911400",
    "Description": "Pistols, latch fingers and gas struts of guns not specifified in chapter 93",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305911500",
    "Description": "Loaders and their parts of guns not specifified in chapter 93",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305911600",
    "Description": "Silencers (sound absorbers) and their parts of guns not specifified in chapter 93",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305911700",
    "Description": "Flash suppressors and their parts of guns not specifified in chapter 93",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305911800",
    "Description": "Breeches, locks and breech boxes of guns not specifified in chapter 93",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305911900",
    "Description": "Other parts and accessories of machine guns/ submachine guns not specified in chapter 93",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305919000",
    "Description": "Other parts and accessories of military weapons of heading 93.01.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9305990000",
    "Description": "Parts and accessories of articles of hea Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9306210000",
    "Description": "Shortgun Cartridges",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9306291000",
    "Description": "Parts of Shortgun Cartridges",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9306299000",
    "Description": "Other shotgun cartridges and parts thereof not specified",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9306301000",
    "Description": "Cartridges not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9306309000",
    "Description": "Parts of other Cartridges not specified.",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9306900000",
    "Description": "Other parts of Bombs, grenades, torpedoes, mines, missile",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9307000000",
    "Description": "Swords, cutlasses... and similar arms and parts and scabbards and sheaths therefor.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9401100000",
    "Description": "Seats of a kind used for aircraft",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9401200000",
    "Description": "Seats of a kind used for motor vehicles",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9401310000",
    "Description": "-- Of wood",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9401390000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9401410000",
    "Description": "-- Of wood",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9401490000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9401520000",
    "Description": "Seats of bamboo",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9401530000",
    "Description": "Seats of rattan",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9401590000",
    "Description": "Other Seats of cane, osier or similar materials not specified in 94.01",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9401610000",
    "Description": "Upholstered seats, with wooden frames",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9401690000",
    "Description": "seats with wooden frames, not upholstered",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9401710000",
    "Description": "Upholstered seats, with metal frames",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9401790000",
    "Description": "Seats with metal frames, not upholstered",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9401800000",
    "Description": "Other seats forms not specified in 9401 and 9402",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9401910000",
    "Description": "---  Upholstered",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9401919000",
    "Description": "---  Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9401991000",
    "Description": "---  Upholstered",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9401999000",
    "Description": "---  Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9402101000",
    "Description": "Dentists' chairs and parts thereof",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9402109000",
    "Description": "Barbers' or similar chairs and parts thereof",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9402900000",
    "Description": "Medical, surgical, veterinary furniture (for example, operating, examination tables",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9403100000",
    "Description": "Metal furniture of a kind used in offices",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9403200000",
    "Description": "other metal furniture not used in offices",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9403300000",
    "Description": "Wooden furniture of a kind used in offices",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9403400000",
    "Description": "Wooden furniture of a kind used in the kitchen",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9403500000",
    "Description": "Wooden furniture of a kind used in the bedroom",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9403600000",
    "Description": "Other wooden furniture not specified in 94.03",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9403701000",
    "Description": "Baby walker",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9403709000",
    "Description": "Other Furniture of plastics (excl. Baby walker)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9403820000",
    "Description": "Furniture of bamboo",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9403830000",
    "Description": "Furniture of rattan",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9403890000",
    "Description": "Furniture made of cane, osier, similar materials ",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9403910000",
    "Description": "-- Of wood",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9403990000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9404100000",
    "Description": "Mattress supports",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9404210000",
    "Description": "Mattresses of cellular rubber or plastics, whether or not covered",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9404290000",
    "Description": "Mattresses of other materials not specified in 94.04",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9404300000",
    "Description": "Sleeping bags",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9404400000",
    "Description": "- Quilts, bedspreads, eiderdowns and duvets (comforters)",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9404900000",
    "Description": "Articles of bedding and similar furnishing not specified in 94.04",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9405110000",
    "Description": "-- Designed for use solely with light-emitting diode (LED) light sources",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9405190000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9405210000",
    "Description": "-- Designed for use solely with light-emitting diode (LED) light sources",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9405290000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9405310000",
    "Description": "-- Designed for use solely with light-emitting diode (LED) light sources",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9405390000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9405410000",
    "Description": "-- Photovoltaic, designed for use solely with light-emitting diode (LED) light sources",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9405420000",
    "Description": "-- Other, designed for use solely with light-emitting diode (LED) light sources",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9405490000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9405501000",
    "Description": "Hurricane lanterns",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9405502000",
    "Description": "Kerosene pressure lanterns",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9405509000",
    "Description": "Other Nonelectrical lamps and lighting fittings not specified in this 94.05.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9405610000",
    "Description": "-- Designed for use solely with light-emitting diode (LED) light sources",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9405690000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9405910000",
    "Description": "Parts of lamps and lighting fittings made of glass",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9405920000",
    "Description": "Parts of lamps and lighting fittings made of plastics",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9405990000",
    "Description": "Parts of lamps and lighting fittings made of material not specified in 94.05",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9406100000",
    "Description": "- Of wood",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9406200000",
    "Description": "- Modular building units, of steel",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9406900000",
    "Description": "Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9503000000",
    "Description": "Tricycles, scooters...; dolls' carriages.., reducedsize (\"scale\") models, working or not",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9504200000",
    "Description": "Articles and accessories for billiards of all kinds",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9504300000",
    "Description": "Games, operated by coins... or any means of payment, excl. automatic bowling equipment",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9504400000",
    "Description": "Playing cards",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9504500000",
    "Description": "Video game consoles and machines, other than those of subheading 9504.30",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9504900000",
    "Description": "Other Video game consoles and machines, not specified in 95.04",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9505100000",
    "Description": "Articles for Christmas festivities",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9505900000",
    "Description": "Other Festive or other entertainment articles, excl. articles for Christmas festivities",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9506110000",
    "Description": "Skis",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9506120000",
    "Description": "Skifastenings (skibindings)",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9506190000",
    "Description": "Other Snowskis and other snowski equipment not specified in 95.06",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9506210000",
    "Description": "Sailboards",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9506290000",
    "Description": "Waterskis, surfboards, and other watersport equipment",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9506310000",
    "Description": "Golf Clubs, complete",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9506320000",
    "Description": "Gulf Balls",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9506390000",
    "Description": "Other Golf equipment not specified in 95.06",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9506400000",
    "Description": "Articles and equipment for tabletennis",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9506510000",
    "Description": "Lawntennis rackets, whether or not strung",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9506590000",
    "Description": "Badminton or similar rackets, whether or not strung",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9506610000",
    "Description": "Lawntennis balls",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9506620000",
    "Description": "Inflatable Balls, other than golf balls and tabletennis balls",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9506690000",
    "Description": "Articles anequipment for general physi Other (Balls, other than golf bal",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9506700000",
    "Description": "Ice skates and roller skates, including skating boots with skates attached",
    "SU": "2U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9506910000",
    "Description": "Articles and equipment for general physical exercise, gymnastics or athletics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9506990000",
    "Description": "Articles and equipment for general physi Other (Other)",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9507100000",
    "Description": "Fishing rods",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9507200000",
    "Description": "Fishhooks, whether or not snelled",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9507300000",
    "Description": "Fishing reels",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9507900000",
    "Description": "Fishing rod, fishhook..; fish landing net... decoy \"birds\" (excl heading 92.08 or 97.05)",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9508100000",
    "Description": "Travelling circuses and travelling menageries",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9508210000",
    "Description": "-- Roller coasters",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9508220000",
    "Description": "-- Carousels, swings and roundabouts",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9508230000",
    "Description": "-- Dodge’em cars",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9508240000",
    "Description": "-- Motion simulators and moving theatres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9508250000",
    "Description": "-- Water rides",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9508260000",
    "Description": "-- Water park amusements",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9508290000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9508300000",
    "Description": "- Fairground amusements",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9508400000",
    "Description": "- Travelling theatres",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9601100000",
    "Description": "Worked ivory and articles of ivory",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9601900000",
    "Description": "Bone, tortoiseshell...and other animal carving material, and articles of these materials",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9602000000",
    "Description": "Worked vegetable/mineral carving, material and articles of wax..and unhardened gelatin.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "01/04/2022"
  },
  {
    "CET Code": "9603100000",
    "Description": "Brooms/brushes, consisting of twigs or other vegetable materials bound together.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9603210000",
    "Description": "Tooth brushes, including dentalplate brushes",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9603290000",
    "Description": "Shaving, hair brushes...and toilet brushes use on the person and parts of appliances",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9603301000",
    "Description": "Artists' brushes and writing brushes",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9603309000",
    "Description": "Brushes for the application of cosmetics:",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9603400000",
    "Description": "Paint, distemper, varnish or similar brushes (not 9603.30); paint pads and rollers",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9603500000",
    "Description": "Other brushes constituting parts of machines, appliances or vehicles",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9603900000",
    "Description": "Other brushes not specified in 96.03",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9604000000",
    "Description": "Hand sieves and hand riddles.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9605000000",
    "Description": "Travel sets for personal toilet, sewing or shoe or clothes cleaning.",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9606100000",
    "Description": "Pressfasteners, snapfasteners and pressstuds and parts therefor",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9606210000",
    "Description": "Buttons of plastics, not covered with textile material",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9606220000",
    "Description": "Buttons of base metal, not covered with textile material",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9606290000",
    "Description": "Buttons of other materials not specified in 96.06",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9606300000",
    "Description": "Button moulds and other parts of buttons; button blanks",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9607110000",
    "Description": "Slide fasteners Fitted with chain scoops of base metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9607190000",
    "Description": "Slide fasteners not fitted with chain scoops of base metal",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9607200000",
    "Description": "Parts of Slide fasteners",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9608100000",
    "Description": "Ball point pens",
    "SU": "U",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9608200000",
    "Description": "Felt tipped and other poroustipped pens and markers",
    "SU": "U",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9608300000",
    "Description": "Fountain pens, stylograph pens and other pens",
    "SU": "U",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9608400000",
    "Description": "Propelling or sliding pencils",
    "SU": "U",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9608500000",
    "Description": "Sets of articles from two or more of subheadings 9608.10.00.00 to 9608.50.00",
    "SU": "U",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9608600000",
    "Description": "Refills for ball point pens, comprising the ball point and inkreservoir",
    "SU": "U",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9608910000",
    "Description": "Pen nibs and nib points",
    "SU": "U",
    "ID": 10,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9608991000",
    "Description": "Ball points",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9608999000",
    "Description": "Other pen not specified in 96.08",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9609100000",
    "Description": "Pencils and crayons, with leads encased in a rigid sheath",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9609200000",
    "Description": "Pencil leads, black or coloured",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9609900000",
    "Description": "pastels, drawing charcoals, writing or drawing chalks and tailors' chalks.",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9610000000",
    "Description": "Slates and boards, with writing or drawing surfaces, whether or not framed.",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9611000000",
    "Description": "Date or numbering stamps, for operating in the hand; handoperated composing sticks..",
    "SU": "KG",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9612100000",
    "Description": "Ribbonsu",
    "SU": "U",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9612200000",
    "Description": "Inkpads",
    "SU": "U",
    "ID": 20,
    "VAT": "0",
    "DATE OF VALIDITY": "15/12/2020"
  },
  {
    "CET Code": "9613100000",
    "Description": "Pocket lighters, gas fuelled, nonrefillable",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9613200000",
    "Description": "Pocket lighters, gas fuelled, refillable",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9613800000",
    "Description": "Other lighters",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/02/2020"
  },
  {
    "CET Code": "9613900000",
    "Description": "Parts of Cigarette lighters and other lighters",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9614000000",
    "Description": "Smoking pipes (including pipe bowls) and cigar or cigarette holders, and parts thereof.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9615110000",
    "Description": "Combs, hairslides and the like of hard rubber or plastics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9615190000",
    "Description": "Combs, hairslides and the like other than of rubber or plastics",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9615900000",
    "Description": "Hairpins, curling pins... and the like, escl. 85.16, and parts thereof.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9616100000",
    "Description": "Scent sprays and similar toilet sprays, and mounts and heads therefor",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9616200000",
    "Description": "Powderpuffs and pads for the application of cosmetics or toilet preparations",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9617000000",
    "Description": "Vacuum flasks and vacuum vessels, complete with cases; parts other than glass inners.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9618000000",
    "Description": "Tailors' dummies and other lay figures; other displays used for shop window dressing.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9619001000",
    "Description": "Sanitary towels (pads) and tampons including including similar articles",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/05/2022"
  },
  {
    "CET Code": "9619002100",
    "Description": "Napkin and napkin liners for babies",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9619002200",
    "Description": "Incontinence pad for adult use",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/05/2022"
  },
  {
    "CET Code": "9619002900",
    "Description": "Other sanitary materials not specified.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/05/2022"
  },
  {
    "CET Code": "9620000000",
    "Description": "Monopods, bipods, tripods and similar articles",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9701210000",
    "Description": "-- Paintings, drawings and pastels",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9701220000",
    "Description": "-- Mosaics",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9701290000",
    "Description": "-- Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9701910000",
    "Description": "-- Paintings, drawings and pastels",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9701920000",
    "Description": "-- Mosaics",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9701990000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9702100000",
    "Description": "- Of an age exceeding 100 years",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9702900000",
    "Description": "- Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9703100000",
    "Description": "- Of an age exceeding 100 years",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9703900000",
    "Description": "- Other",
    "SU": "U",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9704000000",
    "Description": "Postage/revenue stamps, firstday covers...and the like, not of heading 49.07.",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "14/04/2020"
  },
  {
    "CET Code": "9705100000",
    "Description": "- Collections and collectors' pieces of archaeological, ethnographic or historical inter",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9705210000",
    "Description": "-- Human specimens and parts thereof",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9705220000",
    "Description": "-- Extinct or endangered species and parts thereof",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9705290000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9705310000",
    "Description": "-- Of an age exceeding 100 years",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9705390000",
    "Description": "-- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9706100000",
    "Description": "- Of an age exceeding 250 years",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9706900000",
    "Description": "- Other",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "DATE OF VALIDITY": "07/01/2022"
  },
  {
    "CET Code": "9801100001",
    "Description": "--Tractors CKD for the assembly industry",
    "SU": "U",
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100002",
    "Description": "--Semi Knocked Down (SKD) for the assembly industry",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100003",
    "Description": "--FBU imported by MV assemblers and manufacturers",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100004",
    "Description": "--Other New",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100005",
    "Description": "--Other Used",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100006",
    "Description": "--Fully Built Unit (FBU) imported by others",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100007",
    "Description": "--Agricultural Tractor",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "21/01/2022"
  },
  {
    "CET Code": "9801100008",
    "Description": "--CKD for Motor Vehicles for the transport of ten or more persons including the driver",
    "SU": "U",
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100009",
    "Description": "--Semi knocked down (SKD) Motor Vehicles for the transport of ten or more persons includ",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100010",
    "Description": "--CKD for Motor cars and other motor vehicles designed for the transport of persons.",
    "SU": "U",
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100011",
    "Description": "--Semi knocked down (SKD) for Motor cars and other motor vehicles designed for transport",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100012",
    "Description": "--Fully Built Unit for the assembly Industry",
    "SU": "U",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100013",
    "Description": "--Other Fully Built",
    "SU": "U",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100014",
    "Description": "--Used",
    "SU": "U",
    "ID": 35,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100015",
    "Description": "--CKD for Motor vehicles for the transport of goods.",
    "SU": "U",
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100016",
    "Description": "--Semi knocked down (SKD) for Motor vehicles for the transport of goods.",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100017",
    "Description": "--CKD for Trailers and semi-trailers",
    "SU": "U",
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9801100018",
    "Description": "--Semi knocked down (SKD for Trailers and semi-trailers",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100001",
    "Description": "--Pure-bred breeding animals",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100002",
    "Description": "--Tallow",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9901100003",
    "Description": "--Other fats of bovine animals, sheep or goats, other than those of heading 15.03",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9901100004",
    "Description": "--Malted beverage powder in bulk of not less than 25 kg",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9901100005",
    "Description": "--Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9901100006",
    "Description": "--Salt for human consumption (bulk, ship-load)",
    "SU": "KG",
    "ID": 10,
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9901100007",
    "Description": "--Other lubricating oils meant to be mixed further",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100008",
    "Description": "--Waste oil containing polychlorinated biphenyls (PCBs), polychlorinated terphenyls (PCT",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100009",
    "Description": "--Hypochlorites; commercial calcium hypochlorite; chlorites; hypobromites",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100010",
    "Description": "--Pigments and preparations based thereon",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100011",
    "Description": "--Prepared driers.",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100012",
    "Description": "--Pigments (including metallic powders and flakes) dispersed in non-aqueous media",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100013",
    "Description": "--Mixtures of odoriferous substances of a kind used in perfumery industry",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100014",
    "Description": "--Mixtures of odoriferous substances of a kind used in other industries",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100015",
    "Description": "--Containing petroleum oils or oils obtained from bituminous minerals: Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100016",
    "Description": "--Peptones and their derivatives; other protein substances and their derivatives, not el",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100017",
    "Description": "--Adhesives based on polymers of headings 39.01 to 39.13 or on rubber: Other",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100018",
    "Description": "--Prepared glues and other prepared adhesives, not elsewhere specified or included; Othe",
    "SU": "KG",
    "ID": 5,
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100019",
    "Description": "--Enzymes; prepared enzymes not elsewhere specified or included; Other",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100020",
    "Description": "--Other plates and film, with any side exceeding 255 mm",
    "SU": "M2",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100021",
    "Description": "--Chemical preparations for photographic uses (other than varnishes, glues, adhesives",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100022",
    "Description": "--Activated carbon; activated natural mineral products; animal black, including spent",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100023",
    "Description": "--Pickling preparations for metal surfaces; soldering, brazing or welding powders",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100024",
    "Description": "--Additives for lubricating oils: Containing petroleum oils or oils obtained from bitumi",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100025",
    "Description": "--Containing methane, ethane or propane chlorofluorocarbons (CFCs), whether or not cont",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100026",
    "Description": "--Mixed alkylbenzenes and mixed alkylnaphthalenes, other than those of heading 27.07",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100027",
    "Description": "--Industrial monocarboxylic fatty acids; acid oils from refining: Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100028",
    "Description": "--Sorbitol other than that of subheading 2905.44",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100029",
    "Description": "--Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100030",
    "Description": "--Amorphous PET Chips",
    "SU": "KG",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100031",
    "Description": "--Artificial guts (sausage casings) of hardened protein or of cellulosic materials",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100032",
    "Description": "--Self-adhesive plates, sheets, film, foil, tape, strip and other flat shapes, of plasti",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100033",
    "Description": "--Of polycarbonates, alkyd resins, polyallyl esters or other polyester; of polycarbonate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100034",
    "Description": "--Of poly (ethylene terephthalate)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100035",
    "Description": "--Of other polyesters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100036",
    "Description": "--Of other plastics; of poly (vinyl butyral)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100037",
    "Description": "--Of other plastics",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100038",
    "Description": "--Stoppers, lids and caps; other",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100039",
    "Description": "--Other articles of plastics and articles of other materials of headings 39.01 to 39.14;",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100040",
    "Description": "--Articles of apparel and clothing accessories (including gloves, mittens and mitts)",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100041",
    "Description": "-Of cellular rubber; Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100042",
    "Description": "--Toilet or facial tissue stock, towel or napkin stock and similar paper of a kind used",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "14/01/2022"
  },
  {
    "CET Code": "9901100043",
    "Description": "--Paper and paperboard of a kind used for writing, printing or other graphic purposes",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100044",
    "Description": "--Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100045",
    "Description": "--In rolls of a width not exceeding 150mm or in rectangular (including square) sheets",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100046",
    "Description": "--Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100047",
    "Description": "--Of a kind used for winding textile yarn",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100048",
    "Description": "--Filter paper and paperboard",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100049",
    "Description": "--Yarn of jute or of other textile bast fibres of heading 53.03; Multiple (folded)",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100050",
    "Description": "--Other yarn, single: Of cellulose acetate",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100051",
    "Description": "--Polypropylene fabrics, of the type used as carpet backing",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100052",
    "Description": "--Synthetic filament tow; Of polyesters",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100053",
    "Description": "--Acrylic or modacrylic",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100054",
    "Description": "--Synthetic staple fibres, not carded, combed or otherwise processed for spinning;",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100055",
    "Description": "- -Acrylic or modacrylic",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100056",
    "Description": "--Synthetic staple fibres, carded, combed or otherwise processed for spinning;",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100057",
    "Description": "--Acrylic or modacrylic",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100058",
    "Description": "--Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100059",
    "Description": "--Single yarn",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100060",
    "Description": "--Weighing more than 150 g/m²",
    "SU": "KG",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "14/01/2022"
  },
  {
    "CET Code": "9901100061",
    "Description": "--Of polypropylene, of the type used as carpet backing",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100062",
    "Description": "--Sorted: Mutilated rags",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100063",
    "Description": "--Head-bands, linings, covers, hat foundations, hat frames, peaks and chinstraps",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100064",
    "Description": "--Ferrous products obtained by direct reduction of iron ore",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9901100065",
    "Description": "--Other",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9901100066",
    "Description": "--In coils, not further worked than hot-rolled, with patterns in relief",
    "SU": "KG",
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/01/2022"
  },
  {
    "CET Code": "9901100067",
    "Description": "--Of a thickness of 3 mm or more but less than 4.75 mm",
    "SU": "KG",
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/01/2022"
  },
  {
    "CET Code": "9901100068",
    "Description": "--Of a thickness of less than 3 mm",
    "SU": "KG",
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/01/2022"
  },
  {
    "CET Code": "9901100069",
    "Description": "--Of a thickness of 4.75 mm or more but not exceeding 10 mm",
    "SU": "KG",
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/01/2022"
  },
  {
    "CET Code": "9901100070",
    "Description": "--Of a thickness of 3 mm or more but less than 4.75 mm",
    "SU": "KG",
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/01/2022"
  },
  {
    "CET Code": "9901100071",
    "Description": "--Of a thickness of less than 3 mm",
    "SU": "KG",
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/01/2022"
  },
  {
    "CET Code": "9901100072",
    "Description": "--Not in coils, not further worked than hot-rolled, with patterns in relief",
    "SU": "KG",
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/01/2022"
  },
  {
    "CET Code": "9901100074",
    "Description": "--Of a thickness of 3 mm or more but less than 4.75 mm",
    "SU": "KG",
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/01/2022"
  },
  {
    "CET Code": "9901100075",
    "Description": "--Of a thickness of less than 3 mm",
    "SU": "KG",
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/01/2022"
  },
  {
    "CET Code": "9901100076",
    "Description": "--Other",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/01/2022"
  },
  {
    "CET Code": "9901100079",
    "Description": "--Threaded articles: Other screws and bolts, whether or not with their nuts or washers",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100080",
    "Description": "--Non-threaded articles: Rivets",
    "SU": "KG",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100081",
    "Description": "--Forged or stamped, but not further worked: Other",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100082",
    "Description": "--Of refined copper: Other",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100083",
    "Description": "--Aluminium, not alloyed",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100084",
    "Description": "--Aluminium alloys",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100085",
    "Description": "--Aluminium waste and scrap.",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100086",
    "Description": "--Backed: Printed",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100087",
    "Description": "--Collapsible tubular containers",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100088",
    "Description": "--Zinc, not alloyed: Containing by weight 99.99 % or more of zinc",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100089",
    "Description": "--Fuses",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100090",
    "Description": "--Automatic circuit breakers",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100091",
    "Description": "--Lamp-holders",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100092",
    "Description": "--Other apparatus",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100093",
    "Description": "--Mechanically propelled vessels for the transport of goods by inland navigation",
    "SU": "U",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100094",
    "Description": "--Other breathing appliances and gas masks, excluding protective masks having neither me",
    "SU": "U",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100095",
    "Description": "--Greenhouse",
    "SU": "KG",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901100096",
    "Description": "--Worked vegetable or mineral carving material and articles of these materials; moulded",
    "SU": "KG",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "11/01/2022"
  },
  {
    "CET Code": "9901100097",
    "Description": "--Modular Surgical Operating Theatre (MSOT)",
    "SU": "U",
    "ID": 5,
    "VAT": "0",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9901107300",
    "Description": "--Of a thickness of 4.75 mm or more but not exceeding 10 mm",
    "VAT": "7.5",
    "DATE OF VALIDITY": "28/01/2022"
  },
  {
    "CET Code": "9902100001",
    "Description": "--Polymer Substrate for NSPMC",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9902100002",
    "Description": "--Varnishes for Banknotes for NSPMC",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9902100003",
    "Description": "--Varnishes for NSPMC",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9902100004",
    "Description": "--Stamping foil for NSPMC",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9902100005",
    "Description": "--Black ink for NSPMC",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9902100006",
    "Description": "--Offset inks for paper and polymer substrates for NSPMC",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9902100007",
    "Description": "--Pigments in paste forms; clear intaglio for -polymer for NSPMC",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9902100008",
    "Description": "--Other printing inks, for NSPMC",
    "SU": "KG",
    "ID": 0,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9903100001",
    "Description": "Mayonnaise",
    "SU": "KG",
    "ID": 20,
    "VAT": "7.5",
    "LVY": 30,
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9903100002",
    "Description": "--Cooking appliances and plate warmers: Presented completely knocked down (CKD)",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/01/2022"
  },
  {
    "CET Code": "9903100003",
    "Description": "--Presented completely knocked down (CKD) or unassembled for the assembly industry",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "12/01/2022"
  },
  {
    "CET Code": "9903100004",
    "Description": "-- Mobile Telephones",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/04/2022"
  },
  {
    "CET Code": "9903100005",
    "Description": "Set top boxes CKD",
    "SU": "U",
    "ID": 5,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/04/2022"
  },
  {
    "CET Code": "9903100006",
    "Description": "Set top boxes SKD",
    "SU": "U",
    "ID": 10,
    "VAT": "7.5",
    "DATE OF VALIDITY": "13/04/2022"
  },
  {
    "CET Code": "9903100007",
    "Description": "--Electricity Meters CKD",
    "SU": "U",
    "ID": 5,
    "DATE OF VALIDITY": "13/04/2022"
  },
  {
    "CET Code": "9903100008",
    "Description": "--Electricity Meters SKD",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "13/04/2022"
  },
  {
    "CET Code": "84713010",
    "Description": "--Personal Computers",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "06/08/2023"
  },
  {
    "CET Code": "85171300",
    "Description": "--Smartphones, wireless, networks.",
    "SU": "U",
    "ID": 10,
    "DATE OF VALIDITY": "06/08/2023"
  }
]

export default hscodes.sort((a, b) => a.Description?.localeCompare(b.Description))