export default function TransactionsIcon ({ variant = 'secondary', ...restProps }) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.5 9.25H25M20 3L26.25 9.25L20 15.5M27.5 21.75H5M10 15.5L3.75 21.75L10 28'
        stroke={variant === 'primary' ? '#fe6802' : '#2E3034'}
        stroke-width='2'
      />
    </svg>
  )
}
