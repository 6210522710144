import Modal from "react-modal";
import { ReactComponent as Times } from "../../assets/times.svg";
import { ReactComponent as Line } from "../../assets/line.svg";
import { ReactComponent as Copy } from "../../assets/copyPrimary.svg";
import { CircularProgress } from "@mui/material";
import { formatAmount } from "../../utils";

const styles = {
  content: {
    maxWidth: "500px",
    margin: "auto",
    height: "fit-content",
    maxHeight: "95%",
    overflow: "auto",
  },
  overlay: {
    backgroundColor: "#0000004f",
    zIndex: 60,
  },
};

export const SalesforceModal = ({
  salesforceModal,
  setSalesforceModal,
  markAsPaidSalesforce,
  loading,
  amount,
  salesForceAgent,
  salesForceId
}) => {
  

  return (
    <>
      <Modal
        isOpen={salesforceModal}
        onRequestClose={() => setSalesforceModal(false)}
        style={styles}
        contentLabel="Example Modal"
      >
        <div className="px-4">
          <div className="w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer">
            <Times onClick={() => setSalesforceModal(false)}/>
          </div>
          <div className="text-center font-bold">Payment Confirmation</div>
          <div className="text-center text-sm mt-6">
            Would you like to pay for the commission earned by {salesForceAgent?.first_name} {salesForceAgent?.last_name}? If
            so, please make a transfer of N{amount} to the account number provided
            below. After making the transfer, click on "I have sent It" to
            confirm payment.
          </div>
          <div className="flex justify-center w-full mt-4">
            <Line />
          </div>
          <div className="flex justify-center w-full mt-4 text-sm">
            <div className="w-[320px] h-[140px] bg-[#F5F5F5] p-6 space-y-2">
              <div>
                Account Name: <strong>{salesForceAgent?.bank_details?.account_name}</strong>
              </div>
              <div className="flex">
                Account Number: <strong>{salesForceAgent?.bank_details?.account_number}</strong>{" "}
                <Copy className="text-main-primary ml-3" />
              </div>
              <div>
                Bank: <strong>{salesForceAgent?.bank_details?.bank}</strong>
              </div>
              <div>
                Amount: <strong>₦ {formatAmount(amount?.toFixed(2))}</strong>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <button
              className="w-full py-3 bg-main-primary rounded-md text-white font-bold"
              onClick={() => {
                const details = {ids: salesForceId};
                markAsPaidSalesforce(2, details);
              }}
            >
              I have sent it!{" "}
              {loading && <CircularProgress size={13} color="inherit" />}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
