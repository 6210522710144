import axios from 'axios'
import cookies from '../../utils/cookies'

const orgClient = axios.create({
  baseURL: process.env.REACT_APP_MANAGEMENT_API_BASE_URL,
  timeout: process.env.REACT_APP_API_TIMEOUT
})

orgClient.interceptors.request.use(
  function (config) {
    const accessToken = cookies.get('accessToken')
    if (!accessToken) return config

    config.headers.Authorization = 'Bearer ' + accessToken
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

orgClient.interceptors.response.use(
  response => {
    response.ok = true
    return response
  },
  error => {
    error.ok = false
    return Promise.reject(error)
  }
)

export default orgClient
