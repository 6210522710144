export default function TotalCard ({ title, total }) {
  return (
    <div className='w-full rounded-xl shadow-md-right h-full bg-white'>
      {/* <div className='p-3 text-white bg-main-primary text-sm font-medium capitalize text-center  rounded-t-xl'>
        {title}
      </div> */}
      <div className='p-3 text-white bg-main-primary text-sm font-medium capitalize text-center rounded-t-xl  flex items-center justify-center'>
        {title}
      </div>
      <div className='p-4 text-center font-bold text-lg text-black'>
        {total?.toLocaleString()}
      </div>
    </div>
  )
}
