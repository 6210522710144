import React, { useEffect, useContext, useMemo } from 'react'
import { useSearchParams, Navigate, useLocation } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import { AddressBook } from '../../components'
import { urlQueryStringToObject } from '../../utils'
import { CreateOrderContext } from '../../containers/CreateOrderLayout'

const CustomerInfo = ({ metaTitle }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const orderId = searchParams.get('ord')
  const quoteId = searchParams.get('qt')
  const customerType = searchParams.get('ctm')

  const header = customerType === 'receiver' ? 'Receiver' : 'Sender'
  const location = useLocation()

  const { updateActiveStep, order, resolvePathname } =
    useContext(CreateOrderContext)

  const backRoute = useMemo(() => {
    if (header === 'Sender') {
      const params = urlQueryStringToObject(location.search)
      delete params.ctm
      const search = new URLSearchParams(params).toString()
      return resolvePathname(
        `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}${
          search ? search : ''
        }`
      )
    }

    const params = urlQueryStringToObject(location.search)
    params.ctm = 'sender'
    return `?${new URLSearchParams(params).toString()}`

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [header, location.search])

  useEffect(() => {
    if (!customerType) {
      searchParams.set('ctm', 'sender')
      setSearchParams(searchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateActiveStep({
      stepId: header === 'Sender' ? 1 : 2,
      metaTitle,
      back: backRoute
    })
  }, [backRoute, header, metaTitle, updateActiveStep])

  if (!order.payload.type) {
    return (
      <Navigate
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}`
        )}
        replace={true}
      />
    )
  }

  return (
    <div>
      <h2 className='py-4 md:pt-0 font-bold text-2xl text-center'>{header}</h2>
      <AddressBook />
    </div>
  )
}

export default CustomerInfo
