import local from '../../assets/local-shipment.svg'
import international from '../../assets/international-shipment.svg'

const Item = ({ handleOnClick, image, text }) => {
  return (
    <button
      className='bg-[#f2f2f2] px-10 py-16 gap-4 rounded-lg hover:shadow-lg hover:-translate-y-1 transition-all w-full max-w-64 flex flex-col items-center text-center'
      onClick={handleOnClick}
    >
      <img
        style={{ width: '128px', height: '128px', objectFit: 'contain' }}
        src={image}
        alt=''
        className='mb-4'
      />
      <p className='uppercase text-xl font-semibold'>{text}</p>
    </button>
  )
}

export default function QuoteType ({ onShipmentType }) {
  const handleNavigate = type => {
    onShipmentType(type)
  }

  const types = [
    {
      name: 'International',
      id: 'IN',
      image: international
    },
    {
      name: 'Local',
      id: 'LC',
      image: local
    }
  ]

  return (
    <div>
      <h2 className='py-4 md:pt-0 font-bold text-2xl text-center'>
        Type of Shipment
      </h2>
      <div className='bg-white border rounded-lg w-full p-4 lg:pt-6 lg:pb-12 max-w-3xl mx-auto'>
        <div className='w-full flex flex-row flex-wrap justify-center gap-5 mt-5 lg:mt-10'>
          {types.map(type => (
            <Item
              key={type.id}
              handleOnClick={() => handleNavigate(type.id)}
              image={type.image}
              text={type.name}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
