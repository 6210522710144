import { useContext } from 'react'
import { AuthLayoutContext } from '../../containers/AuthLayout'

export default function BookingsCard ({
  type = 'customer',
  data,
  isBranchSpecific
}) {
  const { userRole } = useContext(AuthLayoutContext)

  return (
    <div className='py-4 px-4 shadow-md-right bg-white rounded-xl h-full'>
      <h6 className='font-semibold text-base mb-4'>
        {type === 'customer' ? 'Customer' : 'Partner'} Bookings{' '}
        {userRole?.id !== 19 && (
          <span className='font-normal text-[13px] text-[#333333B2] '>
            (
            {data.total_bookings?.toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })}
            )
          </span>
        )}
      </h6>
      <div className='flex flex-col items-start w-full gap-6 text-sm text-black'>
        <div className='py-1.5 flex items-center justify-between w-full border-b border-g-400'>
          <span className='opacity-80'>
            {isBranchSpecific ? 'Branch bookings' : 'Bookings'}
          </span>
          <span className='font-medium'>
            {isBranchSpecific
              ? data.branch_bookings?.toLocaleString()
              : data?.bookings?.toLocaleString()}
          </span>
        </div>
        {isBranchSpecific && (
          <div className='py-1.5 flex items-center justify-between w-full border-b border-g-400'>
            <span className='opacity-80'>My bookings</span>
            <span className='font-medium'>
              {data?.employee_bookings?.toLocaleString()}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
