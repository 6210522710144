import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts'

const useStyles = makeStyles(() => ({
  bar: {
    fill: '#FF4D00',
    width: 14
  }
}))

const CurvedBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width='100%' height={300}>
      <BarChart width={500} height={300} data={data}>
        <CartesianGrid strokeDasharray='3' />
        <XAxis dataKey='month' />
        <YAxis type='number' domain={[0, 100]} />
        <Tooltip />
        <Bar dataKey='shipments' fill='#FF4D00' shape={<CustomBar />} />
      </BarChart>
    </ResponsiveContainer>
  )
}

const CustomBar = props => {
  const { x, y, width, height } = props
  const classes = useStyles()

  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      className={classes.bar}
      rx={10}
      ry={10}
    />
  )
}

export default function ShipmentsBarChart ({ data }) {
  const { total_shipments, monthly_shipments } = data

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const chartsData = months.map((month, id) => {
    return {
      month,
      shipments: monthly_shipments[`${id + 1}`]
    }
  })
  const LocalBarLegend = ({ total }) => {
    return (
      <div className='flex items-center gap-2'>
        <svg
          width='20'
          height='8'
          viewBox='0 0 20 8'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect y='3.5' width='20' height='1' rx='0.5' fill='#A6A6A699' />
          <circle cx='10.5' cy='4' r='3.5' fill='#A6A6A699' />
        </svg>

        <span className='text-xs'>
          Quantity sold ({total?.toLocaleString()})
        </span>
      </div>
    )
  }

  const InternationalBarLegend = ({ total }) => {
    return (
      <div className='flex items-center gap-2'>
        <svg
          width='20'
          height='8'
          viewBox='0 0 20 8'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect y='3.5' width='20' height='1' rx='0.5' fill='#F2901E' />
          <circle cx='10.5' cy='4' r='3.5' fill='#F2901E' />
        </svg>

        <span className='text-xs'>
          Quantity available ({total?.toLocaleString()})
        </span>
      </div>
    )
  }

  const BarLegend = ({ data }) => {
    return data.map(item => {
      return (
        <div className='flex items-center justify-center gap-4 w-full'>
          <LocalBarLegend total={item.Sold} />
          <InternationalBarLegend total={item.Available} />
        </div>
      )
    })
  }

  const barLegendData = [
    { name: 'Quantity sold ', Sold: 3000 },
    { name: 'Quantity available ', Available: 200 }
  ]

  return (
    <div className='py-4 px-4 shadow-md-right bg-white rounded-xl h-full'>
      <div>
        <h5 className='font-semibold text-base mb-6'>
          Total Inventories: {total_shipments.toLocaleString()}
        </h5>
        {/* <BarLegend data={barLegendData} /> */}
      </div>
      <div>
        <CurvedBarChart data={chartsData} />
      </div>
    </div>
  )
}
