import axios from 'axios'
import cookies from '../../utils/cookies'

const bookingClient = axios.create({
  baseURL: `${process.env.REACT_APP_BOOKING_API_BASE_URL}`,
  timeout: process.env.REACT_APP_API_TIMEOUT
})

bookingClient.interceptors.request.use(
  config => {
    const authToken = cookies.get('authToken')
    if (!authToken) return config

    config.headers.Authorization = 'Token ' + authToken
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

bookingClient.interceptors.response.use(
  response => {
    response.ok = true
    return response
  },
  error => {
    error.ok = false
    return Promise.reject(error)
  }
)

export default bookingClient
