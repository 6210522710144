import React from 'react'
import './loader.css'

const Loader = ({ page = true }) => {
  return (
    <div
      className={`flex justify-center items-center m-auto ${
        page ? 'h-screen max-h-[500px]' : 'h-fit'
      }`}
    >
      <div className='lds-spinner'>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default Loader
